<template>
  <svg
    id="Слой_1"
    data-name="Слой 1"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      class="mm-cls-1"
      height="55"
      rx="12.37"
      width="55"
      x="2.5"
      y="2.5"
    />
    <circle
      class="mm-cls-2"
      cx="22.69"
      cy="20.82"
      r="3.61"
    />
    <circle
      class="mm-cls-2"
      cx="37.34"
      cy="20.82"
      r="3.61"
    />
    <path
      class="mm-cls-2"
      d="M48.55,38.42l-4.37-7.49a1.93,1.93,0,0,0-2.64-.69,1.91,1.91,0,0,0-.69,2.62L41.49,34a17.38,17.38,0,0,1-23.09.22l.76-1.32a1.92,1.92,0,0,0-.69-2.63,2,2,0,0,0-2.65.69L11.45,38.4a1.92,1.92,0,0,0,.7,2.63,2,2,0,0,0,1,.25,1.91,1.91,0,0,0,1.66-1L16.16,38a21.53,21.53,0,0,0,13.66,4.83,21.81,21.81,0,0,0,13.89-5l1.52,2.59a1.92,1.92,0,0,0,1.66.95,2,2,0,0,0,1-.25,1.94,1.94,0,0,0,.69-2.63Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.mm-cls-1 {
  fill: #168de2;
}

.mm-cls-2 {
  fill: #fff;
}
</style>
