<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    fill="none"
    height="20"
    viewBox="0 0 16 20"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Exclude"
      clip-rule="evenodd"
      d="M8.54023 19.4704C10.3371 17.6738 16 11.7171 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34314 2.34315C0.842854 3.84344 0 5.87827 0 8C0 11.7171 5.66287 17.6738 7.45977 19.4704C7.76043 19.771 8.23956 19.771 8.54023 19.4704ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>

  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 14.418 12 22 12 22C12 22 4 14.418 4 10C4 7.87827 4.84285 5.84344 6.34314 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10Z"
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
