<i18n>
ru:
  surprise: Сюрприз
ua:
  surprise: Сюрприз
us:
  surprise: Surprise
</i18n>

<template>
  <div
    class="v-w-100 v-header-easter-egg v-pointer"
    @click="callForPopup"
  >
    <arora-nuxt-image
      v-if="
        appConfig.VueSettingsPreRun.HeaderEasterEggType === 'custom' &&
        imageExists(appConfig.VueSettingsPreRun.HeaderEasterEggImage)
      "
      :alt="translate('headerEasterEgg.surprise')"
      :image="appConfig.VueSettingsPreRun.HeaderEasterEggImage"
    />
    <icon-easter-egg-burger v-else-if="appConfig.VueSettingsPreRun.HeaderEasterEggType === 'burger'" />
    <icon-easter-egg-pizza v-else-if="appConfig.VueSettingsPreRun.HeaderEasterEggType === 'pizza'" />
    <icon-easter-egg-sushi v-else-if="appConfig.VueSettingsPreRun.HeaderEasterEggType === 'sushi'" />
  </div>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'

const appConfig = useAppConfig()
const popupStore = usePopupStore()
const clientStore = useClientStore()

const { stringIsNullOrWhitespace } = useCommon()

const { imageExists } = useImageInfo()
const { translate } = useI18nSanitized()

async function callForPopup(): Promise<void> {
  if (stringIsNullOrWhitespace(clientStore.ClientState.data?.EasterEggPromo)) return

  await popupStore.openPopup({
    popupName: 'easterEggPopup',
    popupClosable: true
  })
}
</script>

<style lang="scss">
.v-header-easter-egg {
  overflow: hidden;
  max-width: 60px;
  max-height: 60px;

  svg {
    width: 40px;
    height: 40px;
  }
}
</style>
