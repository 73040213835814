import { Guid } from '~api/consts'
import MakeRequest from '~api/request'

import type {
  ClientState,
  ExtraProduct,
  LimitOrderPeriod,
  OrderInfo,
  OrderInfoStatus,
  OrderProcessing,
  Slot,
  StateChangeAnswer
} from '~types/clientStore'
import type { SourceType } from '~types/common'

import { type GUID, RequestMethod } from '@arora/common'

export default {
  async getTimeRestrictions(terminalId: GUID, orderType: number): Promise<LimitOrderPeriod> {
    const restrictions = await MakeRequest<LimitOrderPeriod>(
      '/api/json/limitOrderPeriod',
      new Map<string, number | string>([
        ['orderType', orderType],
        ['terminal', terminalId.toString()]
      ])
    )
    // convert unix time in seconds to milliseconds
    for (const dateInfoKey in restrictions.Scheduled?.Schedule.Dates) {
      const dateInfo = restrictions.Scheduled.Schedule.Dates[dateInfoKey]
      dateInfo.Date *= 1000

      for (const period of dateInfo.RestrictedPeriods) {
        period.Start *= 1000
        period.End *= 1000
      }

      for (const period of dateInfo.OriginalPeriods) {
        period.Start *= 1000
        period.End *= 1000
      }
    }

    return restrictions
  },

  // Обработка выбранной пользователем акции
  async setOfferAfterUserChoice(categoryId: GUID, offerId: GUID): Promise<StateChangeAnswer> {
    const url = '/cart/chooseOffer'

    return MakeRequest<StateChangeAnswer>(
      url,
      new Map([
        ['categoryID', categoryId],
        ['offerID', offerId]
      ])
    )
  },

  // Получение списка акций, для которых нужно сделать выбор
  async getOffersToChoice() {
    const url = '/api/offer/userChoice'

    return MakeRequest(url)
  },

  // Обработка выбора пользователя - "Отменить действие акций"
  async setCancelOffer(categoryId: GUID): Promise<StateChangeAnswer> {
    const url = '/cart/cancelOffer'

    return MakeRequest<StateChangeAnswer>(
      url,
      new Map([
        ['categoryID', categoryId],
        ['isCanceled', 'true']
      ])
    )
  },

  async getClientState(): Promise<ClientState> {
    const clientState: ClientState = await MakeRequest<ClientState>('/client/state')

    // transform client state to simplify operations on it

    // fill selected option and modifiers for cart items to avoid searching every time
    const items = clientState.Cart?.Content

    for (const item of items ?? []) {
      if (!Guid.IsNullOrEmpty(item.OptionID)) {
        item.SelectedOption = {
          ...item.Product.Options[item.OptionID]
        }
      }

      // fill combine slots
      if (item.Slots && item.Product.Slots) {
        item.SelectedSlots = item.Slots.map((itemSlot: Slot) => {
          const productSlot = item.Product.Slots.find((slot: Slot) => {
            return slot.ID === itemSlot.ID
          })

          return {
            ...productSlot,
            ...itemSlot
          }
        })
      }
    }

    return clientState
  },

  async getExtraProducts(): Promise<ExtraProduct[]> {
    return MakeRequest('/api/json/additional-product')
  },

  async changeOrderType(orderType: number) {
    return await MakeRequest(
      '/cart/orderType',
      new Map<string, number | string>([['orderType', orderType]])
    )
  },

  async addressChange(
    terminalId: GUID,
    isDelivery: boolean,
    isInHall: boolean,
    cityId?: GUID | null,
    districtId?: GUID | null,
    departmentId?: GUID | null,
    geoRegion?: string | null,
    geoCity?: string | null,
    geoDistrict?: string | null,
    street?: string | null,
    house?: string | null,
    addressString?: string | null,
    coords?: number[] | null,
    apartment?: string | null,
    corpus?: string | null,
    building?: string | null,
    doorway?: string | null,
    intercom?: string | null,
    floor?: string | null,
    orderDeliveryType?: number | null,
    zipCode?: string | null,
    tableNumber?: number | null
  ) {
    const data = new FormData()

    data.append('isDelivery', `${isDelivery}`)
    data.append('isInHall', `${isInHall}`)
    data.append('tableNumber', tableNumber?.toString() ?? '')
    data.append('cityID', cityId ?? '')
    data.append('districtID', districtId ?? '')
    data.append('departmentID', departmentId ?? '')
    data.append('terminalID', terminalId)
    data.append('geoRegion', geoRegion ?? '')
    data.append('geoCity', geoCity ?? '')
    data.append('geoDistrict', geoDistrict ?? '')
    data.append('street', street ?? '')
    data.append('house', house ?? '')
    data.append(
      'addressString',
      addressString ??
        [geoRegion, geoCity, geoDistrict, street, house]
          .filter((addressPart) => addressPart && addressPart.trim().length > 0)
          .join(', ')
    )
    data.append('apartment', apartment ?? '')
    data.append('corpus', corpus ?? '')
    data.append('building', building ?? '')
    data.append('doorway', doorway ?? '')
    data.append('intercom', intercom ?? '')
    data.append('floor', floor ?? '')
    data.append('orderDeliveryType', orderDeliveryType?.toString() ?? '')
    data.append('zipCode', zipCode ?? '')
    if (coords && coords.length === 2) {
      data.append('latitude', `${coords[0]}`)
      data.append('longitude', `${coords[1]}`)
    }

    return await MakeRequest('/cart/addressChange', null, RequestMethod.POST, data)
  },

  async stateOrderDataChange(
    source: SourceType,
    name: string | null,
    phone: string | null,
    email: string | null,
    change: string | null,
    personCount: number | null,
    paymentType: number | null,
    gratuity: number | null,
    deliveryTime: number | null,
    deliverRightNow: boolean | null,
    captchaResponse: string | null,
    captchaResponseV3: string | null,
    receiptSendType: number | null,
    receiptRecipient: string | null,
    comment: string | null
  ) {
    const getParameters: Map<string, number | string> = new Map()

    if (name) getParameters.set('name', encodeURIComponent(name.trim()))
    if (phone) getParameters.set('phone', encodeURIComponent(phone))
    if (email) getParameters.set('email', encodeURIComponent(email.trim()))
    if (change) getParameters.set('change', encodeURIComponent(change))
    if (personCount !== null) getParameters.set('personCount', encodeURIComponent(personCount))
    if (paymentType !== null) getParameters.set('paymentType', encodeURIComponent(paymentType))
    if (gratuity !== null) getParameters.set('gratuity', encodeURIComponent(gratuity))
    if (deliveryTime !== null) getParameters.set('deliveryTime', encodeURIComponent(deliveryTime))
    if (deliverRightNow !== null)
      getParameters.set('deliverRightNow', deliverRightNow ? 'true' : 'false')
    if (captchaResponse) getParameters.set('captchaResponse', encodeURIComponent(captchaResponse))
    if (captchaResponseV3) getParameters.set('captchaResponseV3', encodeURIComponent(captchaResponseV3))
    if (receiptSendType) getParameters.set('receiptSendType', encodeURIComponent(receiptSendType))
    if (receiptRecipient) getParameters.set('receiptRecipient', encodeURIComponent(receiptRecipient))
    if (comment) getParameters.set('comment', encodeURIComponent(comment.trim()))
    getParameters.set('source', source)

    return await MakeRequest('/cart/setOrderData', getParameters)
  },

  async loadOrderProcessing(restaurantId: GUID, id: GUID) {
    return await MakeRequest<OrderProcessing>(
      '/api/json/processing',
      new Map([
        ['id', id],
        ['RestaurantID', restaurantId]
      ])
    )
  },

  async loadOrderInfo(id: GUID) {
    return await MakeRequest<OrderInfo>('/api/json/order', new Map([['id', id]]))
  },

  async repeatOrder(id: GUID): Promise<StateChangeAnswer> {
    return await MakeRequest<StateChangeAnswer>('/api/json/repeatOrder', new Map([['order', id]]))
  },

  async refreshOrderInfo(id: GUID) {
    return MakeRequest<OrderInfoStatus>('/ajax/checkOrder', new Map([['orderID', id]]))
  },

  async getOrdersForCancel(): Promise<OrderInfo[]> {
    return await MakeRequest<OrderInfo[]>('/api/json/getOrdersForCancel')
  },

  async cancelOrder(orderId: GUID) {
    return await MakeRequest<string>(
      '/api/orderCancel',
      new Map([['orderId', orderId]]),
      RequestMethod.PATCH
    )
  }
}
