<template>
  <svg
    id="Слой_1"
    data-name="Слой 1"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="facebook_grad"
        gradientTransform="translate(45552 -648.45) scale(40 39.78)"
        gradientUnits="userSpaceOnUse"
        x1="-1138.05"
        x2="-1138.05"
        y1="17.7"
        y2="16.36"
      >
        <stop
          offset="0"
          stop-color="#0062e0"
        />
        <stop
          offset="1"
          stop-color="#19afff"
        />
      </linearGradient>
    </defs>
    <path
      class="facebook-cls-1"
      d="M25.46,57.22A27.55,27.55,0,0,1,30,2.5a27.55,27.55,0,0,1,4.54,54.72L33,56H27Z"
    />
    <path
      class="facebook-cls-2"
      d="M40.72,37.7,42,30H34.67V24.64c0-2.2.83-3.85,4.13-3.85h3.57v-7a45.65,45.65,0,0,0-6-.55C30,13.22,25.6,17.07,25.6,24V30H18.72v7.7H25.6V57.09a25.29,25.29,0,0,0,9.07,0V37.7Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.facebook-cls-1 {
  fill: url(#facebook_grad);
}

.facebook-cls-2 {
  fill: #fff;
}
</style>
