<template>
  <template v-if="locale === 'en'">
    <svg
      id="Слой_1"
      style="enable-background: new 0 0 155 45.9"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <path
        id="AppGallery"
        class="appgallery-st0"
        d="M55.5,28.4l-2.3-6.3l-2.3,6.3C50.9,28.4,55.5,28.4,55.5,28.4z M47.3,33.1l4.9-12.7h2L59,33.1
	h-1.8L56,29.8h-5.7l-1.1,3.3H47.3z M62.3,31.7v5.1h-1.6V23.5h1.6V25c0.3-0.5,0.7-1,1.3-1.3s1.2-0.4,1.8-0.4c1.1,0,2.2,0.5,2.8,1.4
	c0.8,1,1.2,2.3,1.1,3.6c0,1.3-0.4,2.6-1.1,3.6c-0.7,0.9-1.7,1.4-2.8,1.4c-0.6,0-1.2-0.1-1.8-0.4S62.6,32.2,62.3,31.7z M67.6,28.3
	c0.1-1-0.2-1.9-0.7-2.7c-0.4-0.6-1.2-1-2-1s-1.5,0.3-2,1c-0.5,0.8-0.8,1.8-0.7,2.7c-0.1,1,0.2,1.9,0.7,2.7c0.4,0.6,1.2,1,2,1
	s1.5-0.3,2-1C67.4,30.2,67.7,29.3,67.6,28.3z M73.4,31.7v5.1h-1.6V23.5h1.6V25c0.3-0.5,0.7-1,1.3-1.3c0.6-0.3,1.2-0.4,1.8-0.4
	c1.1,0,2.1,0.5,2.8,1.4c0.8,1,1.2,2.3,1.1,3.6c0,1.3-0.4,2.6-1.1,3.6c-0.7,0.9-1.7,1.4-2.8,1.4c-0.6,0-1.2-0.1-1.8-0.4
	S73.7,32.2,73.4,31.7L73.4,31.7z M78.7,28.3c0.1-1-0.2-1.9-0.7-2.7c-0.4-0.6-1.2-1-1.9-1c-0.8,0-1.5,0.3-2,1
	c-0.5,0.8-0.8,1.8-0.7,2.7c-0.1,1,0.2,1.9,0.7,2.7c0.4,0.6,1.2,1,2,1s1.5-0.3,1.9-1C78.5,30.3,78.8,29.3,78.7,28.3L78.7,28.3z
	 M91.6,31.3v-3.4h-2.8v-1.4h4.5V32c-0.7,0.5-1.4,0.8-2.2,1.1c-0.8,0.2-1.7,0.4-2.6,0.4c-1.7,0.1-3.4-0.5-4.7-1.7
	c-1.2-1.3-1.8-3.1-1.7-4.9c-0.1-1.8,0.5-3.5,1.7-4.9c1.2-1.2,2.9-1.9,4.7-1.8c0.8,0,1.5,0.1,2.3,0.3c0.7,0.2,1.4,0.5,2.1,0.9v1.8
	c-0.6-0.5-1.3-0.9-2-1.2c-0.7-0.3-1.5-0.4-2.3-0.4c-1.3-0.1-2.6,0.4-3.5,1.3c-0.8,1.1-1.3,2.5-1.1,3.9c-0.1,1.4,0.3,2.8,1.1,3.9
	c0.9,0.9,2.2,1.4,3.5,1.3c0.6,0,1.1,0,1.6-0.1C90.8,31.7,91.2,31.5,91.6,31.3L91.6,31.3z M102.3,28.3h-1.5c-0.9-0.1-1.8,0.1-2.6,0.4
	c-0.5,0.3-0.8,0.9-0.7,1.5c0,0.5,0.2,1,0.6,1.3s1,0.5,1.5,0.5c0.8,0,1.6-0.3,2.1-0.9c0.5-0.7,0.8-1.5,0.8-2.4L102.3,28.3L102.3,28.3
	z M103.8,27.6v5.5h-1.6v-1.4c-0.3,0.5-0.8,1-1.3,1.3c-0.6,0.3-1.3,0.4-2,0.4c-0.8,0-1.7-0.3-2.3-0.8c-1.2-1.3-1.1-3.4,0.2-4.6
	c1-0.6,2.1-0.9,3.2-0.8h2.2V27c0-0.6-0.2-1.3-0.7-1.7c-0.6-0.4-1.3-0.6-2-0.6c-0.5,0-1.1,0.1-1.6,0.2s-1,0.3-1.5,0.6V24
	c0.5-0.2,1.1-0.4,1.7-0.5c0.5-0.1,1-0.2,1.6-0.2c1.1-0.1,2.3,0.3,3.1,1.1C103.6,25.3,104,26.5,103.8,27.6L103.8,27.6z M107.1,33.1
	V19.8h1.6v13.3H107.1z M112,33.1V19.8h1.6v13.3H112z M124.9,27.9v0.8h-7.2c0,0.9,0.4,1.8,1,2.5c0.7,0.6,1.5,0.9,2.4,0.8
	c0.6,0,1.2-0.1,1.7-0.2c0.6-0.2,1.2-0.4,1.7-0.7v1.5c-0.6,0.2-1.1,0.4-1.7,0.6c-0.6,0.1-1.2,0.2-1.8,0.2c-1.3,0.1-2.6-0.4-3.6-1.3
	c-0.9-1-1.4-2.3-1.3-3.6c-0.1-1.4,0.4-2.7,1.3-3.7s2.1-1.5,3.4-1.4c1.2-0.1,2.3,0.4,3,1.3C124.6,25.5,125,26.7,124.9,27.9
	L124.9,27.9z M117.8,27.5h5.6c0-0.8-0.2-1.5-0.7-2.1c-0.5-0.5-1.2-0.8-1.9-0.8c-0.8,0-1.5,0.2-2.1,0.7
	C118.1,25.9,117.8,26.7,117.8,27.5L117.8,27.5z M133,23.4V25c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4-0.1-0.7-0.1
	c-0.8,0-1.5,0.3-2.1,0.9c-0.5,0.7-0.8,1.6-0.7,2.5v5h-1.5v-9.6h1.6V25c0.3-0.5,0.7-1,1.3-1.3s1.3-0.4,1.9-0.4h0.4
	C132.7,23.3,132.9,23.4,133,23.4L133,23.4z M142.8,23.5L138.6,34c-0.3,0.8-0.7,1.6-1.3,2.2c-0.5,0.4-1.1,0.5-1.7,0.5h-1.3v-1.3h0.9
	c0.4,0,0.7-0.1,1-0.3c0.4-0.4,0.6-0.9,0.8-1.4l0.3-0.7l-3.9-9.4h1.7l3,7.5l3-7.5h1.7V23.5z"
      />
      <path
        id="EXPLORE_IT_ON"
        class="appgallery-st0"
        d="M47.2,15.9V9.3h4.2V10h-3.3v2h3.1v0.7h-3.1v2.4h3.4v0.7L47.2,15.9L47.2,15.9z M54.6,12.4
	l-2-3.1h1l1.6,2.4l1.6-2.4h1l-2.1,3.2l2.3,3.4h-1l-1.8-2.8l-1.9,2.8h-1L54.6,12.4z M61.1,10.1H60v2.5h1.1c0.3,0,0.7-0.1,1-0.3
	c0.2-0.3,0.4-0.6,0.3-0.9c0-0.4-0.1-0.7-0.4-1C61.7,10.1,61.4,10,61.1,10.1L61.1,10.1z M59.1,15.9V9.3h2c0.6,0,1.2,0.1,1.7,0.5
	c0.4,0.4,0.6,0.9,0.6,1.5c0,0.5-0.2,1.1-0.6,1.5c-0.5,0.4-1.1,0.5-1.7,0.5H60v2.6H59.1z M64.5,15.9V9.3h0.9v5.8h3.2v0.8L64.5,15.9z
	 M71.9,9.9c-0.6,0-1.2,0.2-1.6,0.7c-0.4,0.6-0.6,1.3-0.6,2s0.2,1.4,0.6,2c0.4,0.5,0.9,0.7,1.5,0.7s1.2-0.2,1.5-0.7
	c0.4-0.6,0.6-1.3,0.6-2s-0.2-1.4-0.6-2C73.1,10.2,72.5,9.9,71.9,9.9L71.9,9.9z M71.9,9.2c0.8,0,1.6,0.3,2.2,0.9
	c0.6,0.7,0.9,1.6,0.8,2.5c0,0.9-0.2,1.8-0.8,2.5c-0.6,0.6-1.4,1-2.2,0.9c-0.8,0-1.7-0.3-2.2-0.9c-0.6-0.7-0.8-1.6-0.8-2.5
	s0.2-1.8,0.8-2.5C70.3,9.5,71.1,9.2,71.9,9.2L71.9,9.2z M79.4,12.8c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.3,0.4,0.6,0.6,0.9l0.9,1.8h-1
	l-0.8-1.7c-0.1-0.3-0.4-0.6-0.6-0.9c-0.2-0.2-0.5-0.2-0.8-0.2h-1v2.8h-0.9V9.3h2c0.6,0,1.2,0.1,1.7,0.5c0.4,0.4,0.6,0.9,0.6,1.4
	c0,0.4-0.1,0.7-0.3,1C80.1,12.5,79.8,12.7,79.4,12.8L79.4,12.8z M78.3,10.1h-1.1v2.3h1.1c0.3,0,0.7-0.1,1-0.3c0.4-0.5,0.4-1.2,0-1.7
	C79,10.1,78.7,10,78.3,10.1L78.3,10.1z M82.5,15.9V9.3h4.2V10h-3.2v2h3.1v0.7h-3.1v2.4h3.3v0.7L82.5,15.9z M91.1,15.9V9.3H92v6.6
	H91.1z M92.9,10.1V9.3h5.6V10h-2.3v5.8h-0.8V10L92.9,10.1z M104.8,9.9c-0.6,0-1.2,0.2-1.5,0.7c-0.4,0.6-0.6,1.3-0.5,2
	c0,0.7,0.2,1.4,0.6,2c0.7,0.8,2,1,2.8,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.6,0.6-1.3,0.6-2s-0.2-1.4-0.6-2
	C106.1,10.2,105.5,9.9,104.8,9.9L104.8,9.9z M104.8,9.2c0.8,0,1.7,0.3,2.2,0.9c0.6,0.7,0.9,1.6,0.9,2.5s-0.2,1.8-0.8,2.5
	c-1.2,1.2-3.2,1.2-4.4,0c-0.6-0.7-0.9-1.6-0.8-2.5c0-0.9,0.3-1.8,0.8-2.5C103.2,9.5,104,9.2,104.8,9.2L104.8,9.2z M109.3,15.9V9.3
	h1.1l2.9,5.5V9.3h0.9v6.6H113l-2.8-5.5v5.5H109.3z"
      />
      <path
        id="Path_5"
        class="appgallery-st1"
        d="M31.4,9.3H19.7c-5.5,0-7.5,2-7.5,7.5v11.7c0,5.5,2,7.6,7.5,7.6h11.7c5.5,0,7.5-2,7.5-7.6V16.9
	C38.9,11.3,36.9,9.3,31.4,9.3z"
      />
      <path
        id="Path_6"
        class="appgallery-st2"
        d="M23.6,25.5l-0.3,0.7h-0.7l1.5-3.4h0.6l1.5,3.4h-0.7l-0.3-0.7H23.6z M23.8,24.9h1.1l-0.5-1.3
	L23.8,24.9z M35.3,22.7H36v3.4h-0.7V22.7z M32.5,25.6h2v0.6h-2.7v-3.4h2.6v0.7h-1.9v0.7h1.3v0.7h-1.3C32.5,24.9,32.5,25.6,32.5,25.6
	z M30.4,22.7h0.7L30,26.2h-0.6l-0.8-2.3l-0.8,2.3h-0.6L26,22.7h0.8l0.8,2.4l0.8-2.4H29l0.8,2.4L30.4,22.7z M21.5,22.7h0.7v2
	c0.1,0.8-0.5,1.5-1.2,1.6c-0.1,0-0.2,0-0.3,0c-0.8,0.1-1.4-0.5-1.5-1.3c0-0.1,0-0.2,0-0.3v-2h0.7v2c0,0.6,0.3,0.9,0.8,0.9
	s0.8-0.3,0.8-0.9V22.7z M17.5,22.7h0.7v3.5h-0.7v-1.4h-1.6v1.4h-0.7v-3.5h0.7v1.4h1.6V22.7z M25.5,18.3c-2.4,0-4.4-2-4.4-4.5h0.6
	c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8H30C30,16.2,28,18.2,25.5,18.3C25.6,18.3,25.6,18.3,25.5,18.3L25.5,18.3z"
      />
    </svg>
  </template>

  <template v-else-if="locale === 'uk'">
    <svg
      id="Слой_1"
      style="enable-background: new 0 0 155 45.9"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <path
        id="AppGallery"
        class="appgallery-st0"
        d="M55.5,28.4l-2.3-6.3l-2.3,6.3H55.5z M47.3,33.1l4.9-12.7h2L59,33.1h-1.8L56,29.8h-5.7l-1.1,3.3
	H47.3z M62.3,31.7v5.1h-1.6V23.5h1.6V25c0.3-0.5,0.7-1,1.3-1.3c0.6-0.3,1.2-0.4,1.8-0.4c1.1,0,2.2,0.5,2.8,1.4
	c0.8,1,1.2,2.3,1.1,3.6c0,1.3-0.4,2.6-1.1,3.6c-0.7,0.9-1.7,1.4-2.8,1.4c-0.6,0-1.2-0.1-1.8-0.4C63,32.6,62.6,32.2,62.3,31.7z
	 M67.6,28.3c0.1-1-0.2-1.9-0.7-2.7c-0.4-0.6-1.2-1-2-1c-0.8,0-1.5,0.3-2,1c-0.5,0.8-0.8,1.8-0.7,2.7c-0.1,1,0.2,1.9,0.7,2.7
	c0.4,0.6,1.2,1,2,1c0.8,0,1.5-0.3,2-1C67.4,30.2,67.7,29.3,67.6,28.3z M73.4,31.7v5.1h-1.6V23.5h1.6V25c0.3-0.5,0.7-1,1.3-1.3
	c0.6-0.3,1.2-0.4,1.8-0.4c1.1,0,2.1,0.5,2.8,1.4c0.8,1,1.2,2.3,1.1,3.6c0,1.3-0.4,2.6-1.1,3.6c-0.7,0.9-1.7,1.4-2.8,1.4
	c-0.6,0-1.2-0.1-1.8-0.4C74.1,32.6,73.7,32.2,73.4,31.7L73.4,31.7z M78.7,28.3c0.1-1-0.2-1.9-0.7-2.7c-0.4-0.6-1.2-1-1.9-1
	c-0.8,0-1.5,0.3-2,1c-0.5,0.8-0.8,1.8-0.7,2.7c-0.1,1,0.2,1.9,0.7,2.7c0.4,0.6,1.2,1,2,1c0.8,0,1.5-0.3,1.9-1
	C78.5,30.3,78.8,29.3,78.7,28.3L78.7,28.3z M91.6,31.3v-3.4h-2.8v-1.4h4.5v5.5c-0.7,0.5-1.4,0.8-2.2,1.1c-0.8,0.2-1.7,0.4-2.6,0.4
	c-1.7,0.1-3.4-0.5-4.7-1.7c-1.2-1.3-1.8-3.1-1.7-4.9c-0.1-1.8,0.5-3.5,1.7-4.9c1.2-1.2,2.9-1.9,4.7-1.8c0.8,0,1.5,0.1,2.3,0.3
	c0.7,0.2,1.4,0.5,2.1,0.9v1.8c-0.6-0.5-1.3-0.9-2-1.2c-0.7-0.3-1.5-0.4-2.3-0.4c-1.3-0.1-2.6,0.4-3.5,1.3c-0.8,1.1-1.3,2.5-1.1,3.9
	c-0.1,1.4,0.3,2.8,1.1,3.9c0.9,0.9,2.2,1.4,3.5,1.3c0.6,0,1.1,0,1.6-0.1C90.8,31.7,91.2,31.5,91.6,31.3L91.6,31.3z M102.3,28.3h-1.5
	c-0.9-0.1-1.8,0.1-2.6,0.4c-0.5,0.3-0.8,0.9-0.7,1.5c0,0.5,0.2,1,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5c0.8,0,1.6-0.3,2.1-0.9
	c0.5-0.7,0.8-1.5,0.8-2.4L102.3,28.3L102.3,28.3z M103.8,27.6v5.5h-1.6v-1.4c-0.3,0.5-0.8,1-1.3,1.3c-0.6,0.3-1.3,0.4-2,0.4
	c-0.8,0-1.7-0.3-2.3-0.8c-1.2-1.3-1.1-3.4,0.2-4.6c1-0.6,2.1-0.9,3.2-0.8h2.2V27c0-0.6-0.2-1.3-0.7-1.7c-0.6-0.4-1.3-0.6-2-0.6
	c-0.5,0-1.1,0.1-1.6,0.2c-0.5,0.1-1,0.3-1.5,0.6V24c0.5-0.2,1.1-0.4,1.7-0.5c0.5-0.1,1-0.2,1.6-0.2c1.1-0.1,2.3,0.3,3.1,1.1
	C103.6,25.3,104,26.5,103.8,27.6L103.8,27.6z M107.1,33.1V19.8h1.6v13.3L107.1,33.1z M112,33.1V19.8h1.6v13.3L112,33.1z M124.9,27.9
	v0.8h-7.2c0,0.9,0.4,1.8,1,2.5c0.7,0.6,1.5,0.9,2.4,0.8c0.6,0,1.2-0.1,1.7-0.2c0.6-0.2,1.2-0.4,1.7-0.7v1.5
	c-0.6,0.2-1.1,0.4-1.7,0.6c-0.6,0.1-1.2,0.2-1.8,0.2c-1.3,0.1-2.6-0.4-3.6-1.3c-0.9-1-1.4-2.3-1.3-3.6c-0.1-1.4,0.4-2.7,1.3-3.7
	c0.9-1,2.1-1.5,3.4-1.4c1.2-0.1,2.3,0.4,3,1.3C124.6,25.5,125,26.7,124.9,27.9L124.9,27.9z M117.8,27.5h5.6c0-0.8-0.2-1.5-0.7-2.1
	c-0.5-0.5-1.2-0.8-1.9-0.8c-0.8,0-1.5,0.2-2.1,0.7C118.1,25.9,117.8,26.7,117.8,27.5L117.8,27.5z M133,23.4V25
	c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4-0.1-0.7-0.1c-0.8,0-1.5,0.3-2.1,0.9c-0.5,0.7-0.8,1.6-0.7,2.5v5h-1.5v-9.6h1.6V25
	c0.3-0.5,0.7-1,1.3-1.3c0.6-0.3,1.3-0.4,1.9-0.4h0.4C132.7,23.3,132.9,23.4,133,23.4L133,23.4z M142.8,23.5L138.6,34
	c-0.3,0.8-0.7,1.6-1.3,2.2c-0.5,0.4-1.1,0.5-1.7,0.5h-1.3v-1.3h0.9c0.4,0,0.7-0.1,1-0.3c0.4-0.4,0.6-0.9,0.8-1.4l0.3-0.7l-3.9-9.4
	h1.7l3,7.5l3-7.5H142.8z"
      />
      <path
        id="Path_5"
        class="appgallery-st1"
        d="M31.4,9.3H19.7c-5.5,0-7.5,2-7.5,7.5v11.7c0,5.5,2,7.6,7.5,7.6h11.7c5.5,0,7.5-2,7.5-7.6V16.9
	C38.9,11.3,36.9,9.3,31.4,9.3z"
      />
      <path
        id="Path_6"
        class="appgallery-st2"
        d="M23.6,25.5l-0.3,0.7h-0.7l1.5-3.4h0.6l1.5,3.4h-0.7l-0.3-0.7H23.6z M23.8,24.9h1.1l-0.5-1.3
	L23.8,24.9z M35.3,22.7H36v3.4h-0.7V22.7z M32.5,25.6h2v0.6h-2.7v-3.4h2.6v0.7h-1.9v0.7h1.3v0.7h-1.3V25.6z M30.4,22.7h0.7L30,26.2
	h-0.6l-0.8-2.3l-0.8,2.3h-0.6L26,22.7h0.8l0.8,2.4l0.8-2.4h0.6l0.8,2.4L30.4,22.7z M21.5,22.7h0.7v2c0.1,0.8-0.5,1.5-1.2,1.6
	c-0.1,0-0.2,0-0.3,0c-0.8,0.1-1.4-0.5-1.5-1.3c0-0.1,0-0.2,0-0.3v-2h0.7v2c0,0.6,0.3,0.9,0.8,0.9c0.5,0,0.8-0.3,0.8-0.9L21.5,22.7z
	 M17.5,22.7h0.7v3.5h-0.7v-1.4h-1.6v1.4h-0.7v-3.5h0.7v1.4h1.6V22.7z M25.5,18.3c-2.4,0-4.4-2-4.4-4.5h0.6c0,2.1,1.7,3.8,3.8,3.8
	c2.1,0,3.8-1.7,3.8-3.8H30C30,16.2,28,18.2,25.5,18.3C25.6,18.3,25.6,18.3,25.5,18.3L25.5,18.3z"
      />
      <path
        class="appgallery-st0"
        d="M50.9,11.1c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.6,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.3
	c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.2-0.4,0.3-0.7,0.4
	c-0.3,0.1-0.6,0.2-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.3l0.3-0.5
	l0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1l0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.1c0.3,0,0.6-0.1,0.8-0.2
	c0.2-0.2,0.3-0.4,0.3-0.7c0-0.1,0-0.2-0.1-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1h-0.8V12
	h0.8c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.3-0.3,0.3-0.6c0-0.2-0.1-0.4-0.3-0.5c-0.2-0.2-0.5-0.2-0.8-0.2c-0.2,0-0.3,0-0.5,0.1L48,10.7
	l-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1l-0.3-0.6c0.2-0.2,0.5-0.4,0.8-0.4c0.3-0.1,0.7-0.2,1-0.2
	c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.3,0.3,0.4,0.5C50.9,10.7,50.9,10.9,50.9,11.1z"
      />
      <path
        class="appgallery-st0"
        d="M57.3,15.4h-0.8c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2L55.6,14h-2.4l-0.4,1.1c0,0.1-0.1,0.1-0.1,0.2
	c-0.1,0-0.1,0.1-0.2,0.1h-0.8l2.3-5.8H55L57.3,15.4z M53.5,13.2h1.8l-0.7-1.9c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.4L54.3,11
	l-0.1,0.3L53.5,13.2z"
      />
      <path
        class="appgallery-st0"
        d="M58.2,15.4V9.6h2c0.3,0,0.7,0,1,0.1c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0.1,0.6
	c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.5,0.2c0.8,0.2,1.1,0.6,1.1,1.3
	c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.5c-0.2,0.2-0.4,0.3-0.7,0.4c-0.3,0.1-0.6,0.1-0.9,0.1L58.2,15.4z M59.3,12.1h0.9
	c0.3,0,0.6-0.1,0.8-0.2c0.4-0.4,0.4-0.9,0-1.3c-0.2-0.1-0.5-0.2-0.8-0.2h-0.9L59.3,12.1z M59.3,12.9v1.7h1.1c0.2,0,0.3,0,0.5-0.1
	c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.3
	C61.1,13,61,13,60.9,12.9c-0.2,0-0.3-0.1-0.5-0.1L59.3,12.9z"
      />
      <path
        class="appgallery-st0"
        d="M68.7,15.4h-0.8c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2L67.1,14h-2.4l-0.4,1.1c0,0.1-0.1,0.1-0.1,0.2
	c-0.1,0-0.1,0.1-0.2,0.1H63l2.3-5.8h1.1L68.7,15.4z M64.9,13.2h1.8l-0.7-1.9c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.4
	c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2-0.1,0.3L64.9,13.2z"
      />
      <path
        class="appgallery-st0"
        d="M74.5,15.4h-1.1v-2.5h-2.7v2.5h-1.1V9.6h1.1v2.5h2.7V9.6h1.1V15.4z"
      />
      <path
        class="appgallery-st0"
        d="M80.2,9.6v0.9h-1.8v4.9h-1.1v-4.9h-1.8V9.6H80.2z"
      />
      <path
        class="appgallery-st0"
        d="M85.7,15.4h-0.8c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2L84,14h-2.4l-0.4,1.1c0,0.1-0.1,0.1-0.1,0.2
	c-0.1,0-0.1,0.1-0.2,0.1H80l2.3-5.8h1.1L85.7,15.4z M81.9,13.2h1.9l-0.7-1.9c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.4L82.8,11
	l-0.1,0.3L81.9,13.2z"
      />
      <path
        class="appgallery-st0"
        d="M87,9.6c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2l1.1,1.8c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1
	h0.5V9.6h1v2.4h0.5c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.2-0.1,0.2-0.2l1.1-1.8c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1h0.8
	l-1.5,2.2c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.2c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.4l1.5,2.5h-0.7
	c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3l-1.1-1.9c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.1h-0.6v2.5h-1v-2.5h-0.6
	c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.1-0.2,0.3l-1.1,1.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0.1H86l1.5-2.5
	c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.4-0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3l-1.5-2.2H87z"
      />
      <path
        class="appgallery-st0"
        d="M99.1,10c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0h0.8v5.8h-1.1v-4.1
	c0-0.1,0-0.3,0-0.5c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.2,0.3l-2.2,3.4c0,0-0.1,0.1-0.1,0.1l-0.1,0.1l-0.1,0.1h-0.1h-0.8V9.6
	h1.1v3.6c0,0.1,0,0.3,0,0.4c0,0.1,0,0.3,0,0.5c0.1-0.2,0.1-0.3,0.2-0.4l0.2-0.3L99.1,10z"
      />
      <path
        class="appgallery-st0"
        d="M106.1,9.6v0.9h-1.7v4.9h-1.1v-4.9h-1.7V9.6H106.1z"
      />
      <path
        class="appgallery-st0"
        d="M110.8,10l0.1-0.1c0,0,0.1-0.1,0.1-0.1l0.1-0.1c0,0,0.1,0,0.1,0h0.8v5.8H111v-3.7c0-0.1,0-0.3,0-0.4
	s0-0.3,0-0.5c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.1,0.2-0.2,0.3l-2.2,3.4l-0.1,0.1l-0.1,0.1l-0.1,0.1h-0.1h-0.8V9.6h1.1v3.6
	c0,0.1,0,0.3,0,0.4c0,0.2,0,0.3,0,0.5c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.2,0.2-0.3L110.8,10z"
      />
      <path
        class="appgallery-st0"
        d="M119.5,11.1c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.4-0.6,0.5c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3
	c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6c-0.2,0.2-0.4,0.3-0.7,0.4
	c-0.3,0.1-0.6,0.2-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.3l0.3-0.5
	c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0.1l0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.4,0.1,0.6,0.1
	c0.3,0,0.6-0.1,0.8-0.2c0.3-0.3,0.4-0.7,0.2-1.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.4-0.2c-0.2,0-0.3-0.1-0.5-0.1h-0.8V12
	h0.7c0.3,0,0.5-0.1,0.8-0.2c0.3-0.2,0.4-0.6,0.2-1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.2-0.5-0.2-0.8-0.2c-0.2,0-0.3,0-0.5,0.1
	l-0.4,0.1l-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1l-0.3-0.5c0.2-0.2,0.5-0.4,0.8-0.4
	c0.3-0.1,0.7-0.2,1-0.2c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.3,0.3,0.4,0.5C119.4,10.7,119.5,10.9,119.5,11.1z"
      />
    </svg>
  </template>

  <template v-else-if="locale === 'ru'">
    <svg
      id="Слой_1"
      style="enable-background: new 0 0 155 45.9"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <g id="g80">
        <path
          id="path82"
          class="appgallery-st0"
          d="M51.7,14.8c0.3-0.2,0.5-0.5,0.7-0.8c0.1-0.4,0.2-0.8,0.2-1.1c0-0.4-0.1-0.8-0.2-1.1
		c-0.1-0.3-0.3-0.6-0.6-0.8c-0.7-0.4-1.5-0.4-2.2,0c-0.3,0.2-0.5,0.5-0.7,0.8c-0.1,0.4-0.2,0.8-0.2,1.1c0,0.4,0.1,0.8,0.2,1.1
		c0.4,1,1.6,1.4,2.5,1C51.6,14.9,51.6,14.8,51.7,14.8 M49,15.5c-0.4-0.2-0.8-0.6-1-1.1c-0.2-0.5-0.4-1.1-0.4-1.6
		c0-0.6,0.1-1.2,0.4-1.7c0.2-0.5,0.6-0.9,1.1-1.1c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.1,0.1,1.6,0.4c0.4,0.2,0.8,0.6,1,1.1
		c0.2,0.5,0.4,1,0.3,1.6c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.5-0.6,0.9-1.1,1.1c-0.5,0.3-1.1,0.4-1.6,0.4C50,15.9,49.5,15.8,49,15.5"
        />
      </g>
      <g id="g84">
        <path
          id="path86"
          class="appgallery-st0"
          d="M55.8,10.6H54l0.1-0.9h4.7l-0.1,0.9h-1.8v5.2h-1V10.6z"
        />
      </g>
      <g id="g88">
        <path
          id="path90"
          class="appgallery-st0"
          d="M64.4,15.8h-1.1l-1.8-2.7h-0.7v2.7h-1V9.7h1v2.5h0.6l1.3-1.8c0.1-0.2,0.3-0.4,0.6-0.6
		c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4,0,0.6,0.1l-0.2,0.8c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.2-0.3,0.3
		l-1.1,1.6L64.4,15.8z"
        />
      </g>
      <g id="g92">
        <path
          id="path94"
          class="appgallery-st0"
          d="M67.8,12.5c0.2-0.2,0.4-0.5,0.3-0.9c0-0.3-0.1-0.6-0.3-0.8c-0.3-0.2-0.6-0.3-1-0.3h-0.6v2.2
		c0.2,0,0.4,0.1,0.6,0.1C67.2,12.8,67.5,12.7,67.8,12.5 M65.2,9.7h1.6c0.6,0,1.2,0.1,1.8,0.5c0.4,0.3,0.6,0.8,0.6,1.4
		c0,0.4-0.1,0.7-0.3,1.1c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.3-1.1,0.2c-0.3,0-0.5,0-0.8-0.1v2.3h-1L65.2,9.7z"
        />
      </g>
      <g id="g96">
        <path
          id="path98"
          class="appgallery-st0"
          d="M73.9,14.8c0.3-0.2,0.5-0.5,0.7-0.8c0.1-0.4,0.2-0.8,0.2-1.1c0-0.4-0.1-0.8-0.2-1.1
		c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.7-0.3-1.1-0.3c-0.4,0-0.8,0.1-1.1,0.3c-0.3,0.2-0.5,0.5-0.7,0.8c-0.2,0.4-0.2,0.8-0.2,1.1
		c0,0.4,0.1,0.8,0.2,1.1c0.4,1,1.6,1.4,2.5,1C73.7,14.9,73.8,14.8,73.9,14.8 M71.2,15.5c-0.4-0.2-0.8-0.6-1-1.1
		c-0.2-0.5-0.4-1.1-0.3-1.6c0-0.6,0.1-1.2,0.4-1.7c0.2-0.5,0.6-0.9,1.1-1.1c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.1,0.1,1.6,0.4
		c0.4,0.2,0.8,0.6,1,1.1c0.2,0.5,0.4,1,0.3,1.6c0,0.6-0.1,1.2-0.4,1.7c-0.2,0.5-0.6,0.9-1.1,1.1c-0.5,0.3-1.1,0.4-1.7,0.4
		C72.2,15.9,71.7,15.8,71.2,15.5"
        />
      </g>
      <g id="g100">
        <path
          id="path102"
          class="appgallery-st0"
          d="M78.8,9.2c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.2-0.2-0.4-0.2-0.6h0.8c0.1,0.4,0.4,0.7,0.9,0.7
		c0.4,0,0.6-0.3,0.7-0.7h0.8c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.4C80,9.4,79.4,9.4,78.8,9.2 M81.2,15.8v-3.3
		c0-0.4,0-0.9,0-1.4l-3,4.8H77v-6h1.1v3.4c0,0.4,0,0.8,0,1.2l3-4.7h1.1v6.1L81.2,15.8z"
        />
      </g>
      <g id="g104">
        <path
          id="path106"
          class="appgallery-st0"
          d="M85,10.6h-1.9l0.1-0.9h4.7l-0.1,0.9H86v5.2h-1V10.6z"
        />
      </g>
      <g id="g108">
        <path
          id="path110"
          class="appgallery-st0"
          d="M88.8,9.7h3.6l-0.1,0.9h-2.4v1.7h2.3v0.8h-2.3v1.8h2.5l-0.1,0.9h-3.4L88.8,9.7z"
        />
      </g>
      <g id="g112">
        <path
          id="path114"
          class="appgallery-st0"
          d="M99,14.8c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.6-0.4-0.8c-0.3-0.2-0.7-0.3-1-0.3h-0.7v2H98
		C98.4,15.1,98.7,15,99,14.8 M97.2,12.2h0.6c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.5
		c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.1-0.5-0.2-0.8-0.2h-0.7L97.2,12.2z M99.6,12.8c0.2,0.1,0.4,0.3,0.5,0.5c0.1,0.2,0.2,0.5,0.2,0.8
		c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.4,0.5-0.7,0.6c-0.4,0.1-0.8,0.2-1.1,0.2h-2V9.7h1.9c0.5,0,1,0.1,1.4,0.4c0.3,0.2,0.5,0.6,0.5,1
		c0,0.3-0.1,0.7-0.3,0.9c-0.2,0.2-0.5,0.4-0.8,0.5C99.2,12.6,99.4,12.7,99.6,12.8"
        />
      </g>
      <path
        id="AppGallery"
        class="appgallery-st0"
        d="M55.5,28.4l-2.3-6.3l-2.3,6.3H55.5z M47.3,33.1l4.9-12.7h2L59,33.1h-1.8L56,29.8h-5.7l-1.1,3.3
	H47.3z M62.3,31.7v5.1h-1.6V23.5h1.6V25c0.3-0.5,0.7-1,1.3-1.3c0.6-0.3,1.2-0.4,1.8-0.4c1.1,0,2.2,0.5,2.8,1.4
	c0.8,1,1.2,2.3,1.1,3.6c0,1.3-0.4,2.6-1.1,3.6c-0.7,0.9-1.7,1.4-2.8,1.4c-0.6,0-1.2-0.1-1.8-0.4C63,32.6,62.6,32.2,62.3,31.7z
	 M67.6,28.3c0.1-1-0.2-1.9-0.7-2.7c-0.4-0.6-1.2-1-2-1c-0.8,0-1.5,0.3-2,1c-0.5,0.8-0.8,1.8-0.7,2.7c-0.1,1,0.2,1.9,0.7,2.7
	c0.4,0.6,1.2,1,2,1c0.8,0,1.5-0.3,2-1C67.4,30.2,67.7,29.3,67.6,28.3z M73.4,31.7v5.1h-1.6V23.5h1.6V25c0.3-0.5,0.7-1,1.3-1.3
	c0.6-0.3,1.2-0.4,1.8-0.4c1.1,0,2.1,0.5,2.8,1.4c0.8,1,1.2,2.3,1.1,3.6c0,1.3-0.4,2.6-1.1,3.6c-0.7,0.9-1.7,1.4-2.8,1.4
	c-0.6,0-1.2-0.1-1.8-0.4C74.1,32.6,73.7,32.2,73.4,31.7L73.4,31.7z M78.7,28.3c0.1-1-0.2-1.9-0.7-2.7c-0.4-0.6-1.2-1-1.9-1
	c-0.8,0-1.5,0.3-2,1c-0.5,0.8-0.8,1.8-0.7,2.7c-0.1,1,0.2,1.9,0.7,2.7c0.4,0.6,1.2,1,2,1c0.8,0,1.5-0.3,1.9-1
	C78.5,30.3,78.8,29.3,78.7,28.3L78.7,28.3z M91.6,31.3v-3.4h-2.8v-1.4h4.5v5.5c-0.7,0.5-1.4,0.8-2.2,1.1c-0.8,0.2-1.7,0.4-2.6,0.4
	c-1.7,0.1-3.4-0.5-4.7-1.7c-1.2-1.3-1.8-3.1-1.7-4.9c-0.1-1.8,0.5-3.5,1.7-4.9c1.2-1.2,2.9-1.9,4.7-1.8c0.8,0,1.5,0.1,2.3,0.3
	c0.7,0.2,1.4,0.5,2.1,0.9v1.8c-0.6-0.5-1.3-0.9-2-1.2c-0.7-0.3-1.5-0.4-2.3-0.4c-1.3-0.1-2.6,0.4-3.5,1.3c-0.8,1.1-1.3,2.5-1.1,3.9
	c-0.1,1.4,0.3,2.8,1.1,3.9c0.9,0.9,2.2,1.4,3.5,1.3c0.6,0,1.1,0,1.6-0.1C90.8,31.7,91.2,31.5,91.6,31.3L91.6,31.3z M102.3,28.3h-1.5
	c-0.9-0.1-1.8,0.1-2.6,0.4c-0.5,0.3-0.8,0.9-0.7,1.5c0,0.5,0.2,1,0.6,1.3c0.4,0.3,1,0.5,1.5,0.5c0.8,0,1.6-0.3,2.1-0.9
	c0.5-0.7,0.8-1.5,0.8-2.4L102.3,28.3L102.3,28.3z M103.8,27.6v5.5h-1.6v-1.4c-0.3,0.5-0.8,1-1.3,1.3c-0.6,0.3-1.3,0.4-2,0.4
	c-0.8,0-1.7-0.3-2.3-0.8c-1.2-1.3-1.1-3.4,0.2-4.6c1-0.6,2.1-0.9,3.2-0.8h2.2V27c0-0.6-0.2-1.3-0.7-1.7c-0.6-0.4-1.3-0.6-2-0.6
	c-0.5,0-1.1,0.1-1.6,0.2c-0.5,0.1-1,0.3-1.5,0.6V24c0.5-0.2,1.1-0.4,1.7-0.5c0.5-0.1,1-0.2,1.6-0.2c1.1-0.1,2.3,0.3,3.1,1.1
	C103.6,25.3,104,26.5,103.8,27.6L103.8,27.6z M107.1,33.1V19.8h1.6v13.3L107.1,33.1z M112,33.1V19.8h1.6v13.3L112,33.1z M124.9,27.9
	v0.8h-7.2c0,0.9,0.4,1.8,1,2.5c0.7,0.6,1.5,0.9,2.4,0.8c0.6,0,1.2-0.1,1.7-0.2c0.6-0.2,1.2-0.4,1.7-0.7v1.5
	c-0.6,0.2-1.1,0.4-1.7,0.6c-0.6,0.1-1.2,0.2-1.8,0.2c-1.3,0.1-2.6-0.4-3.6-1.3c-0.9-1-1.4-2.3-1.3-3.6c-0.1-1.4,0.4-2.7,1.3-3.7
	c0.9-1,2.1-1.5,3.4-1.4c1.2-0.1,2.3,0.4,3,1.3C124.6,25.5,125,26.7,124.9,27.9L124.9,27.9z M117.8,27.5h5.6c0-0.8-0.2-1.5-0.7-2.1
	c-0.5-0.5-1.2-0.8-1.9-0.8c-0.8,0-1.5,0.2-2.1,0.7C118.1,25.9,117.8,26.7,117.8,27.5L117.8,27.5z M133,23.4V25
	c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.4-0.1-0.7-0.1c-0.8,0-1.5,0.3-2.1,0.9c-0.5,0.7-0.8,1.6-0.7,2.5v5h-1.5v-9.6h1.6V25
	c0.3-0.5,0.7-1,1.3-1.3c0.6-0.3,1.3-0.4,1.9-0.4h0.4C132.7,23.3,132.9,23.4,133,23.4L133,23.4z M142.8,23.5L138.6,34
	c-0.3,0.8-0.7,1.6-1.3,2.2c-0.5,0.4-1.1,0.5-1.7,0.5h-1.3v-1.3h0.9c0.4,0,0.7-0.1,1-0.3c0.4-0.4,0.6-0.9,0.8-1.4l0.3-0.7l-3.9-9.4
	h1.7l3,7.5l3-7.5H142.8z"
      />
      <path
        id="Path_5"
        class="appgallery-st1"
        d="M31.4,9.3H19.7c-5.5,0-7.5,2-7.5,7.5v11.7c0,5.5,2,7.6,7.5,7.6h11.7c5.5,0,7.5-2,7.5-7.6V16.9
	C38.9,11.3,36.9,9.3,31.4,9.3z"
      />
      <path
        id="Path_6"
        class="appgallery-st2"
        d="M23.6,25.5l-0.3,0.7h-0.7l1.5-3.4h0.6l1.5,3.4h-0.7l-0.3-0.7H23.6z M23.8,24.9h1.1l-0.5-1.3
	L23.8,24.9z M35.3,22.7H36v3.4h-0.7V22.7z M32.5,25.6h2v0.6h-2.7v-3.4h2.6v0.7h-1.9v0.7h1.3v0.7h-1.3V25.6z M30.4,22.7h0.7L30,26.2
	h-0.6l-0.8-2.3l-0.8,2.3h-0.6L26,22.7h0.8l0.8,2.4l0.8-2.4h0.6l0.8,2.4L30.4,22.7z M21.5,22.7h0.7v2c0.1,0.8-0.5,1.5-1.2,1.6
	c-0.1,0-0.2,0-0.3,0c-0.8,0.1-1.4-0.5-1.5-1.3c0-0.1,0-0.2,0-0.3v-2h0.7v2c0,0.6,0.3,0.9,0.8,0.9c0.5,0,0.8-0.3,0.8-0.9L21.5,22.7z
	 M17.5,22.7h0.7v3.5h-0.7v-1.4h-1.6v1.4h-0.7v-3.5h0.7v1.4h1.6V22.7z M25.5,18.3c-2.4,0-4.4-2-4.4-4.5h0.6c0,2.1,1.7,3.8,3.8,3.8
	c2.1,0,3.8-1.7,3.8-3.8H30C30,16.2,28,18.2,25.5,18.3C25.6,18.3,25.6,18.3,25.5,18.3L25.5,18.3z"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
</script>

<style lang="scss" scoped>
.appgallery-st0 {
  fill: #ffffff;
}

.appgallery-st1 {
  fill: #c7192f;
}

.appgallery-st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
</style>
