<template>
  <v-button
    v-if="appConfig.VueSettingsPreRun.ElementButtonEffect === 'EmynArnen'"
    :id="id"
    :class-name="classNameComputed"
    :data-test-id="dataTestId"
    :disabled="disabled"
    :inert="disabled"
    :label="label.toString()"
    @click="() => emit('click')"
  >
    <slot>
      {{ label }}
    </slot>
  </v-button>
  <template v-else>
    <!--eslint-disable-next-line vue/no-restricted-html-elements-->
    <button
      :id="id"
      :aria-label="label.toString()"
      :class="classNameComputed"
      :data-test-id="dataTestId"
      :disabled="disabled"
      :inert="disabled"
      @click="() => emit('click')"
    >
      <slot>
        {{ label }}
      </slot>
    </button>
  </template>
</template>

<script setup lang="ts">
import { VButton, type VElement } from '@arora/common'

const { label, dataTestId, ignoreSettings, className } = defineProps<
  VElement & {
    ignoreSettings?: boolean
  }
>()

const emit = defineEmits({
  click: null
})

const appConfig = useAppConfig()

const classNameComputed = computed<string>(() => {
  const localClass = className ?? ''

  if (ignoreSettings) {
    return localClass
  }

  switch (appConfig.VueSettingsPreRun.ElementButtonStyle) {
    case 'Entwash':
      return `v-btn v-btn-primary ${localClass}`
    case 'EmynDuir':
      return `v-btn v-btn-primary-variant-light ${localClass}`
    case 'EmynMuil':
      return `v-btn v-btn-primary-variant-dark ${localClass}`
    case 'Enedwaith':
      return `v-btn v-btn-border ${localClass}`
    default:
      return `v-btn ${localClass}`
  }
})

onBeforeMount(() => {
  if (!label) {
    if (dataTestId) {
      console.error(`Button with data-test-id: ${dataTestId} doesn't have aria-label`)
    } else {
      console.error(`Button with class: ${classNameComputed.value} doesn't have aria-label`)
    }
  }
})
</script>
