type urlComposable = {
  buildUTM: () => string
  getParameters: ComputedRef<Map<string, string>>
  redirect: (href: string | null, externalLink?: boolean) => Promise<void>
  reload: () => void
  origin: Readonly<string>
  pathname: Readonly<string>
  host: Readonly<string>
  isLinkContains: (link: string) => boolean
}
export default function useUrl(): urlComposable {
  const { clean } = useI18nSanitized()

  /**
   * A computed function that retrieves and parses URL parameters.
   *
   * @returns {Object<string, string>}
   */
  const getParameters = computed<Map<string, string>>(() => {
    if (import.meta.client) {
      return new Map(
        window.location.search
          .substring(1)
          .split('&')
          .map((value) => {
            const split = value.split('=')

            return [split[0], split[1]]
          })
      )
    }
    return new Map<string, string>()
  })

  function buildUTM(): string {
    const result: string[] = []

    if (getParameters.value.has('utm_source'))
      result.push(`utm_source=${getParameters.value.get('utm_source')}`)
    if (getParameters.value.has('utm_medium'))
      result.push(`utm_medium=${getParameters.value.get('utm_medium')}`)
    if (getParameters.value.has('utm_content'))
      result.push(`utm_content=${getParameters.value.get('utm_content')}`)
    if (getParameters.value.has('utm_term'))
      result.push(`utm_term=${getParameters.value.get('utm_term')}`)
    if (getParameters.value.has('utm_campaign'))
      result.push(`utm_campaign=${getParameters.value.get('utm_campaign')}`)

    return result.length > 0 ? `?${result.join('&')}` : ''
  }

  /**
   * Redirects the user to a given URL either by using client-side routing or by changing the window location.
   * @async
   *
   * @param href
   * @param {boolean} [externalLink=false] - If `true`, the URL will always be opened in a new window.
   *
   * @returns {Promise<void>} - A Promise that resolves when the redirection is complete.
   */
  async function redirect(href: string | null, externalLink = false): Promise<void> {
    if (!href || !import.meta.client) return

    const appConfig = useAppConfig()

    const utm = appConfig.RestaurantSettingsPreRun.DoNotSaveUTMOnRedirects ? '' : buildUTM()

    const hrefLocal = utm.length === 0 ? clean(href) : `${clean(href)}?${utm}`

    if (externalLink) {
      window.location.href = hrefLocal
    } else {
      navigateTo(hrefLocal)
    }
  }

  function reload(): void {
    if (import.meta.client) {
      window.location.reload()
    }
  }

  const origin: Readonly<string> = import.meta.client ? clean(decodeURI(window.location.origin)) : ''
  const pathname: Readonly<string> = import.meta.client ? clean(decodeURI(window.location.pathname)) : ''
  const host: Readonly<string> = import.meta.client
    ? window.location.hostname.replaceAll('www.', '')
    : ''

  function isLinkContains(link: string): boolean {
    return import.meta.client ? pathname.includes(link) : false
  }

  return {
    buildUTM,
    getParameters,
    host,
    isLinkContains,
    origin,
    pathname,
    redirect,
    reload
  }
}
