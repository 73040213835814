<template>
  <svg
    id="Слой_1"
    data-name="Слой 1"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        gradientUnits="userSpaceOnUse"
        x1="4.24"
        x2="59.23"
        y1="8.24"
        y2="54.69"
      >
        <stop
          offset="0"
          stop-color="#ffe185"
        />
        <stop
          offset="0.21"
          stop-color="#ffbb36"
        />
        <stop
          offset="0.38"
          stop-color="#ff5176"
        />
        <stop
          offset="0.52"
          stop-color="#f63395"
        />
        <stop
          offset="0.74"
          stop-color="#a436d2"
        />
        <stop
          offset="1"
          stop-color="#5f4eed"
        />
      </linearGradient>
    </defs>
    <circle
      class="instagram-cls-1"
      cx="30"
      cy="30"
      r="27.5"
    />
    <path
      class="instagram-cls-2"
      d="M38.54,19.6a1.89,1.89,0,1,0,1.89,1.89A1.88,1.88,0,0,0,38.54,19.6Z"
    />
    <path
      class="instagram-cls-2"
      d="M30.13,22A7.94,7.94,0,1,0,38.07,30,7.95,7.95,0,0,0,30.13,22Zm0,13A5.09,5.09,0,1,1,35.22,30,5.09,5.09,0,0,1,30.13,35.05Z"
    />
    <path
      class="instagram-cls-2"
      d="M36.44,46.08H23.56a9.69,9.69,0,0,1-9.68-9.68V23.52a9.69,9.69,0,0,1,9.68-9.68H36.44a9.69,9.69,0,0,1,9.68,9.68V36.4A9.69,9.69,0,0,1,36.44,46.08ZM23.56,16.87a6.67,6.67,0,0,0-6.65,6.65V36.4a6.66,6.66,0,0,0,6.65,6.65H36.44a6.66,6.66,0,0,0,6.65-6.65V23.52a6.67,6.67,0,0,0-6.65-6.65Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.instagram-cls-1 {
  fill: url(#linear-gradient);
}

.instagram-cls-2 {
  fill: #fff;
}
</style>
