<template>
  <svg
    id="Слой_1"
    data-name="Слой 1"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="rutube-cls-1"
      cx="30"
      cy="30"
      r="27.5"
    />
    <path
      class="rutube-cls-2"
      d="M42.61,42.17l-.56-.76-4-5.39c-.41-.56-.4-.55.29-.7a4.27,4.27,0,0,0,3-2,6,6,0,0,0,.71-2.2,29.77,29.77,0,0,0,.08-4.53,6.62,6.62,0,0,0-.38-2.12,4.56,4.56,0,0,0-3.21-2.95,8.36,8.36,0,0,0-2.23-.27H17.26c-.53,0-.54,0-.54.56v10q0,5.06,0,10.11c0,.37.12.49.49.49,1.38,0,2.77,0,4.15,0,.59,0,.6,0,.6-.6,0-1.89,0-3.78,0-5.68,0-.66,0-.67.65-.67h9.23a.61.61,0,0,1,.6.24c.41.63.85,1.24,1.27,1.86,1,1.5,2,3,3,4.5a.71.71,0,0,0,.66.35c1.51,0,3,0,4.54,0h.61C42.68,42.41,42.73,42.33,42.61,42.17ZM37,29.43a1.31,1.31,0,0,1-1.35,1.44,5.34,5.34,0,0,1-.69.05H22.64c-.67,0-.68,0-.68-.68,0-1.3,0-2.59,0-3.89,0-.54,0-.56.56-.56H34.91a5.69,5.69,0,0,1,.72,0A1.32,1.32,0,0,1,37,27.26,19.64,19.64,0,0,1,37,29.43Z"
    />
    <circle
      class="rutube-cls-3"
      cx="44"
      cy="18.29"
      r="2.75"
    />
  </svg>
</template>

<style scoped lang="scss">
.rutube-cls-1 {
  fill: #071620;
}

.rutube-cls-2 {
  fill: #fff;
}

.rutube-cls-3 {
  fill: #f41240;
}
</style>
