<i18n>
ru:
  buttonContent: '> {amount}'
  popupTitle: Вам доступен подарок!
  popupText: Не забудьте добавить его в корзину в шкале подарков
  popupCancel: Отменить
  popupConfirm: Забрать свой подарок
ua:
  buttonContent: '> {amount}'
  popupTitle: Вам доступний подарунок!
  popupText: Не забудьте додати його до кошика в шкалі подарунків
  popupCancel: Скасувати
  popupConfirm: Забрати свій подарунок
us:
  buttonContent: '> {amount}'
  popupTitle: You have gift available!
  popupText: Don’t forget to add it to your cart in the gift scale
  popupCancel: Cancel
  popupConfirm: Claim your gift
</i18n>

<template>
  <transition
    v-if="appConfig.VueSettingsPreRun.GiftsRangedLayout === 'Shire'"
    appear
    mode="out-in"
    name="bounce"
  >
    <div
      v-if="
        appConfig.VueSettingsPreRun.GiftsRangedShowInMenu &&
        !appConfig.VueSettingsPreRun.GiftsRangedShowInMenuAsInCart &&
        (menuStore.GiftsRanged.data?.Gifts?.length ?? 0) > 0 &&
        isInMenu
      "
      class="v-fab-gifts"
      @click="callForPopup"
    >
      <common-progress-bar-radial
        :circle-size="isSmall ? 5 : 7"
        :milestones="milestones"
        :size="isSmall ? 30 : 50"
        :value="cartToPayWithMoney"
      >
        <div class="v-fab-gifts-inner v-pointer">
          <common-badge
            invert-colors
            :content="currentMilestone === milestones[0] ? 0 : 1"
          >
            <div class="v-elements-overlay">
              <div class="v-overlay-element">
                <icon-menu-ranged-present />
              </div>
              <div
                :id="uid"
                class="v-overlay-element"
              >
                <icon-menu-ranged-present-lid />
              </div>
            </div>
          </common-badge>
          <div
            v-if="!isSmall"
            class="v-align-currency-center v-fab-gifts-currency"
          >
            <i18n-t
              keypath="giftsRangedFloating.buttonContent"
              scope="global"
            >
              <template #amount>
                <common-currency :amount="currentMilestone" />
              </template>
            </i18n-t>
          </div>
        </div>
      </common-progress-bar-radial>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { Guid } from '~api/consts'

import { useCommon, useWindowSize } from '@arora/common'
import gsap from 'gsap'

const menuStore = useMenuStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { translate } = useI18nSanitized()
const { getRandomInt } = useCommon()
const { isSmall } = useWindowSize()
const appConfig = useAppConfig()
const { isLinkContains } = useUrl()

onMounted(() => {
  clientStore.initClientState()
  menuStore.initGiftsRanged()
})

const milestones = computed<number[]>(() =>
  menuStore.GiftsRanged.data?.Gifts
    ? [...new Set(menuStore.GiftsRanged.data?.Gifts.map((item) => item.AvailableFromCartSum))].sort(
        (a, b) => {
          return a - b
        }
      )
    : []
)

const cartToPayWithMoney = computed<number>(
  () => clientStore.ClientState.data?.Cart?.ToPayWithMoneyWithoutExcludedItems ?? 0
)

const currentMilestone = computed<number>(() => {
  let result = 0
  for (const milestone of milestones.value) {
    result = milestone

    if (milestone > cartToPayWithMoney.value) break
  }

  return result
})

/*   Check if the page is in the menu. If we have CurrentGroupId then we are in group. Otherwise, check for url
     @returns {boolean} - True if the page is in the menu, false otherwise.
 */
const isInMenu = computed<boolean>(() => {
  if (!Guid.IsNullOrEmpty(menuStore.CurrentGroupId)) return true

  return (
    isLinkContains(appConfig.VueSettingsPreRun.Links.ConstructorLink) ||
    isLinkContains(appConfig.VueSettingsPreRun.Links.ProductLink)
  )
})

const uid = useId()

function callForPopup(): void {
  popupStore.openPopup({
    popupName: 'menuGiftsPopup',
    popupClosable: true,
    popupProperties: new Map<string, unknown>([
      ['allowMultiple', menuStore.GiftsRanged.data?.AllowMultipleGifts],
      ['gifts', menuStore.GiftsRanged.data?.Gifts],
      ['type', 'ranged']
    ])
  })
}
watch(
  () => cartToPayWithMoney.value,
  (newState: number, oldState: number) => {
    if (
      newState !== oldState &&
      milestones.value.some((milestone) => milestone <= cartToPayWithMoney.value)
    ) {
      if (
        newState >= milestones.value[0] &&
        oldState <= milestones.value[0] &&
        appConfig.VueSettingsPreRun.GiftsRangedShowInfoPopup
      ) {
        popupStore.showConfirm(
          {
            type: 'success',
            title: translate('giftsRangedFloating.popupTitle'),
            message: translate('giftsRangedFloating.popupText'),
            yesText: translate('giftsRangedFloating.popupConfirm'),
            noText: translate('giftsRangedFloating.popupCancel'),
            yesOrNo: true,
            replaceCurrentPopup: false,
            yesFunction: () => {
              callForPopup()
            }
          },
          true,
          'confirm-popup-gifts-ranged'
        )
      }

      if (document.getElementById(uid)) {
        gsap.to(`#${uid}`, {
          duration: 0.15,
          ease: 'sine',
          rotation: getRandomInt(35),
          repeat: 4,
          y: -8,
          onRepeat: () => {
            gsap.to(`#${uid}`, {
              duration: 0.15,
              ease: 'sine',
              y: -8 - getRandomInt(3),
              rotation: getRandomInt(-35)
            })
          },
          onComplete: () => {
            gsap.to(`#${uid}`, {
              duration: 0.1,
              ease: 'sine',
              rotation: 0,
              y: 0
            })
          }
        })
      }
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-fab-gifts {
  overflow: hidden;
  box-shadow: variables.$FloatShadow;
  max-width: 85px;
  max-height: 85px;

  background: variables.$BodyElementsBackground;
  border-radius: 50%;

  z-index: 10;

  @include mixins.sm {
    max-width: 60px;
    max-height: 60px;
  }

  .v-fab-gifts-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    font-size: 10px; //must be fixed or it will overflow

    .v-fab-gifts-currency {
      padding-bottom: 0.5rem;
    }

    .v-ranged-present {
      width: 40px;
      height: 40px;
      padding-bottom: 5px;

      @include mixins.md {
        max-height: 35px;
        max-width: 35px;
      }
    }

    .v-currency-wrapper {
      cursor: pointer;
    }

    .v-overlay-element {
      max-height: 40px;
      max-width: 40px;
    }
  }
}
</style>
