import type { NumericType } from '~types/common'

// eslint-disable-next-line no-restricted-imports
import sanitizeHtml, { type IOptions } from 'sanitize-html'
import { useI18n } from 'vue-i18n'

const sanitizeOptions: IOptions = {
  allowedTags: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'li',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'b',
    'br',
    'cite',
    'code',
    'data',
    'em',
    'i',
    's',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr'
  ],
  nonBooleanAttributes: [
    'abbr',
    'accept',
    'accept-charset',
    'accesskey',
    'action',
    'allow',
    'alt',
    'as',
    'autocapitalize',
    'autocomplete',
    'blocking',
    'charset',
    'cite',
    'class',
    'color',
    'cols',
    'colspan',
    'content',
    'contenteditable',
    'coords',
    'crossorigin',
    'data',
    'datetime',
    'decoding',
    'dir',
    'dirname',
    'download',
    'draggable',
    'enctype',
    'enterkeyhint',
    'fetchpriority',
    'for',
    'form',
    'formaction',
    'formenctype',
    'formmethod',
    'formtarget',
    'headers',
    'height',
    'hidden',
    'high',
    'href',
    'hreflang',
    'http-equiv',
    'id',
    'imagesizes',
    'imagesrcset',
    'inputmode',
    'integrity',
    'is',
    'itemid',
    'itemprop',
    'itemref',
    'itemtype',
    'kind',
    'label',
    'lang',
    'list',
    'loading',
    'low',
    'max',
    'maxlength',
    'media',
    'method',
    'min',
    'minlength',
    'name',
    'nonce',
    'optimum',
    'pattern',
    'ping',
    'placeholder',
    'popover',
    'popovertarget',
    'popovertargetaction',
    'poster',
    'preload',
    'referrerpolicy',
    'rel',
    'rows',
    'rowspan',
    'sandbox',
    'scope',
    'shape',
    'size',
    'sizes',
    'slot',
    'span',
    'spellcheck',
    'src',
    'srcdoc',
    'srclang',
    'srcset',
    'start',
    'step',
    'style',
    'tabindex',
    'target',
    'title',
    'translate',
    'type',
    'usemap',
    'value',
    'width',
    'wrap'
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    '*': ['class', 'style'],
    a: ['href', 'name', 'target'],
    // We don't currently allow img itself by default, but
    // these attributes would make sense if we did.
    img: ['src', 'srcset', 'alt', 'title', 'width', 'height']
  },
  // Lots of these won't come up by default because we don't allow them
  selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
  // URL schemes we permit
  allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
  parseStyleAttributes: true
}
const cleanSanitizeOptions: IOptions = {
  allowedTags: [],
  disallowedTagsMode: 'discard',
  allowedAttributes: {},
  // Lots of these won't come up by default because we don't allow them
  selfClosing: [],
  // URL schemes we permit
  allowedSchemes: [],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: [],
  nonBooleanAttributes: [],
  allowProtocolRelative: true,
  enforceHtmlBoundary: false,
  parseStyleAttributes: false
}

type i18nSanitizedComposable = {
  dateTime: (
    date: number,
    format?: 'date' | 'long' | 'orderDate' | 'orderTime' | 'short' | 'shortDate' | 'time'
  ) => string
  numeric: (value: number, type?: NumericType) => string
  clean: (dirty: string | null | undefined) => string
  translate: (
    key: string,
    object?: Record<string, number | string> | null,
    countForPlural?: number | undefined
  ) => string
  sanitize: (dirty: string | null | undefined) => string
}

export default function useI18nSanitized(): i18nSanitizedComposable {
  const { t, n, d } = useI18n()

  const sanitize = (dirty: string | null | undefined): string => {
    if (!dirty || dirty.trim().length === 0) return ''

    const dirtyLocal = dirty.trim().endsWith('<br>')
      ? dirty.substring(0, dirty.length - 4)
      : dirty.trim()

    return sanitizeHtml(
      dirtyLocal.replaceAll(String.raw`\n`, '<br>').replaceAll('\n', '<br>'),
      sanitizeOptions
    )
      .replaceAll('&amp;', '&')
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
  }

  const clean = (dirty: string | null | undefined): string => {
    if (!dirty || dirty.toString().trim().length === 0) return ''

    return sanitizeHtml(
      dirty
        .toString()
        .replaceAll(String.raw`\n`, ' ')
        .replaceAll('\n', ' ')
        .replaceAll('"', '')
        .replaceAll("'", ''),
      cleanSanitizeOptions
    )
      .replaceAll('&amp;', '&')
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
  }

  const translate = (
    key: string,
    object: Record<string, number | string> | null = null,
    countForPlural: number | undefined = undefined
  ): string => {
    if (countForPlural) return sanitize(object ? t(key, countForPlural, object) : t(key, countForPlural))
    return sanitize(object ? t(key, object) : t(key))
  }

  const numeric = (value: number, type: NumericType = 'int'): string => {
    let result = ''
    if (type === 'nutritional') {
      result =
        value > 0 && Math.floor(value) === 0
          ? n(value, 'nutritionalFixed') //nutritional info cannot be left zero when it's more than zero
          : n(value, 'nutritional')
    } else if (type === 'currency') {
      result = value > 99999 ? n(value, 'currencyCompact') : n(value, 'currency')
    } else {
      result = n(value, type)
    }

    return sanitize(result.replaceAll('-', '−'))
  }

  const dateTime = (
    date: number,
    format: 'date' | 'long' | 'orderDate' | 'orderTime' | 'short' | 'shortDate' | 'time' = 'long'
  ): string => sanitize(d(date, format))

  return {
    sanitize,
    translate,
    numeric,
    dateTime,
    clean
  }
}
