<template>
  <svg
    v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'"
    class="v-lhun"
    fill="none"
    height="20"
    viewBox="0 0 16 20"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M8.54023 19.4704C10.3371 17.6738 16 11.7171 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34314 2.34315C0.842854 3.84344 0 5.87827 0 8C0 11.7171 5.66287 17.6738 7.45977 19.4704C7.76043 19.771 8.23956 19.771 8.54023 19.4704ZM8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
      fill="#FF9F10"
      fill-rule="evenodd"
    />
  </svg>
  <svg
    v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'"
    class="v-poros"
    fill="none"
    height="22"
    viewBox="0 0 18 22"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 9C17 13.418 9 21 9 21C9 21 1 13.418 1 9C1 6.87827 1.84285 4.84344 3.34314 3.34315C4.84344 1.84285 6.87827 1 9 1C11.1217 1 13.1566 1.84285 14.6569 3.34315C16.1571 4.84344 17 6.87827 17 9Z"
      stroke="#FF9F10"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
