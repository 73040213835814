<template>
  <svg
    id="Слой_1"
    data-name="Слой 1"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="yt-cls-1"
      cx="30"
      cy="30"
      r="27.5"
    />
    <path
      class="yt-cls-2"
      d="M44.72,26.07a6.59,6.59,0,0,0-6.6-6.6H21.23a6.6,6.6,0,0,0-6.6,6.6v7.86a6.6,6.6,0,0,0,6.6,6.6H38.12a6.59,6.59,0,0,0,6.6-6.6Zm-9.93,4.52-7.57,3.74c-.3.17-1.31,0-1.31-.39V26.25c0-.34,1-.55,1.32-.38l7.25,3.94C34.78,30,35.1,30.42,34.79,30.59Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.yt-cls-1 {
  fill: red;
}

.yt-cls-2 {
  fill: #fff;
}
</style>
