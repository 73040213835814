<template>
  <svg
    fill="none"
    height="28"
    viewBox="0 0 28 28"
    width="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="14"
      cy="14"
      r="12"
      stroke="red"
      stroke-width="1.5"
    />
    <path
      d="M5 22C6.0316 18.5495 9.66995 16 14 16C18.3301 16 21.9684 18.5495 23 22"
      stroke="red"
      stroke-width="1.5"
    />
    <circle
      cx="14"
      cy="9"
      r="3"
      stroke="red"
      stroke-width="1.5"
    />
  </svg>
</template>
