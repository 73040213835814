<template>
  <template v-if="locale === 'en'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <path
        class="playmarket-st1"
        d="M12.1,8.5c-0.4,0.5-0.6,1-0.5,1.6v25.7c0,0.6,0.2,1.2,0.5,1.6l0.1,0.1l14.4-14.4v-0.3L12.1,8.5L12.1,8.5z"
      />
      <path
        class="playmarket-st2"
        d="M31.4,27.9l-4.8-4.8v-0.3l4.8-4.8l0.1,0.1l5.7,3.2c1.6,0.9,1.6,2.4,0,3.4l-5.7,3.2L31.4,27.9z"
      />
      <path
        class="playmarket-st3"
        d="M31.6,27.9L26.6,23L12.1,37.5c0.7,0.6,1.7,0.6,2.4,0.1L31.6,27.9"
      />
      <path
        class="playmarket-st4"
        d="M31.6,18l-17-9.7c-0.7-0.6-1.7-0.5-2.4,0.1L26.6,23L31.6,18z"
      />
      <path
        class="playmarket-st5"
        d="M31.4,27.8l-16.9,9.6c-0.7,0.5-1.6,0.5-2.3,0l0,0l-0.1,0.1l0,0l0.1,0.1l0,0c0.7,0.5,1.6,0.5,2.3,0l17-9.7
	L31.4,27.8z"
      />
      <path
        class="playmarket-st6"
        d="M12.1,37.3c-0.4-0.5-0.6-1-0.5-1.6v0.2c0,0.6,0.2,1.2,0.5,1.6V37.3L12.1,37.3z"
      />
      <path
        class="playmarket-st7"
        d="M37.2,24.5l-5.8,3.3l0.1,0.1l5.7-3.2c0.7-0.3,1.1-0.9,1.2-1.7l0,0C38.3,23.6,37.9,24.2,37.2,24.5z"
      />
      <path
        class="playmarket-st8"
        d="M14.5,8.6l22.7,12.9c0.6,0.3,1.1,0.8,1.2,1.5l0,0c-0.1-0.7-0.5-1.4-1.2-1.7L14.5,8.4c-1.6-0.9-3-0.2-3,1.7v0.2
	C11.6,8.4,12.9,7.6,14.5,8.6z"
      />
      <path
        class="playmarket-st0"
        d="M122.8,34.4h2.1V20.1h-2.1V34.4z M142.1,25.3l-2.5,6.2h-0.1l-2.5-6.2h-2.3l3.8,8.7l-2.2,4.8h2.2l5.9-13.5H142.1
	z M129.9,32.8c-0.7,0-1.7-0.4-1.7-1.2c0-1.1,1.2-1.5,2.3-1.5c0.7,0,1.4,0.1,2,0.5C132.4,31.8,131.3,32.8,129.9,32.8L129.9,32.8z
	 M130.2,24.9c-1.6-0.1-3.1,0.8-3.8,2.2l1.9,0.8c0.4-0.7,1.2-1.1,2-1c1.1-0.1,2.2,0.7,2.3,1.8c0,0,0,0,0,0v0.1
	c-0.7-0.4-1.5-0.6-2.2-0.6c-2,0-4.1,1.1-4.1,3.2c0.1,1.8,1.6,3.2,3.5,3.2c0,0,0.1,0,0.1,0c1.1,0,2.1-0.5,2.7-1.4h0.1v1.1h2.1v-5.5
	C134.5,26.4,132.6,24.9,130.2,24.9z M116.9,27h-3v-4.9h3c1.4,0,2.5,1.1,2.5,2.5C119.4,25.9,118.3,27,116.9,27
	C116.9,27,116.9,27,116.9,27z M116.9,20.1h-5.1v14.3h2.1V29h3c2.5,0.2,4.6-1.7,4.8-4.1s-1.7-4.6-4.1-4.8
	C117.4,20.1,117.2,20.1,116.9,20.1L116.9,20.1z M88.9,32.8c-1.6-0.1-2.9-1.5-2.8-3.1c0.1-1.5,1.3-2.7,2.8-2.8
	c1.5,0.1,2.7,1.3,2.6,2.9c0,0,0,0.1,0,0.1c0.1,1.5-1,2.8-2.5,2.9C89,32.8,88.9,32.8,88.9,32.8L88.9,32.8z M91.4,26.1L91.4,26.1
	c-0.7-0.7-1.7-1.2-2.7-1.1C86,25,83.9,27.3,84.1,30c0.1,2.5,2.1,4.5,4.6,4.6c1,0,1.9-0.4,2.6-1.1h0.1v0.7c0,1.9-1,2.9-2.6,2.9
	c-1.1,0-2.1-0.7-2.5-1.7l-1.9,0.8c0.7,1.8,2.4,2.9,4.3,2.9c2.5,0,4.6-1.5,4.6-5.1v-8.7h-2V26.1L91.4,26.1z M94.9,34.4H97V20.1h-2.1
	V34.4z M100.2,29.7c-0.1-1.5,1-2.8,2.5-2.9c0,0,0,0,0.1,0c0.8,0,1.5,0.4,1.8,1L100.2,29.7z M106.8,28.1c-0.6-1.8-2.3-3-4.2-3.1
	c-2.5,0-4.6,2-4.6,4.6c0,0.1,0,0.2,0,0.3c0,2.7,2.1,4.9,4.8,4.9c1.6,0,3.2-0.8,4.1-2.2l-1.7-1.1c-0.5,0.8-1.4,1.4-2.4,1.3
	c-1,0-2-0.5-2.4-1.5l6.5-2.7L106.8,28.1L106.8,28.1z M54.8,26.5v2.1h5c-0.1,1-0.5,1.9-1.1,2.6c-1,1-2.4,1.6-3.8,1.5
	c-3,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5c1.4,0,2.8,0.5,3.8,1.5l1.5-1.5c-1.4-1.4-3.3-2.1-5.2-2.1c-4.2-0.2-7.7,3.1-7.9,7.3
	c-0.2,4.2,3.1,7.7,7.3,7.9c0.2,0,0.4,0,0.6,0c2,0.1,3.9-0.7,5.3-2.1c1.2-1.3,1.8-3.1,1.8-4.9c0-0.4,0-0.9-0.1-1.3H54.8z M67.5,32.8
	c-1.6,0.1-3-1.1-3.2-2.7c-0.1-1.6,1.1-3,2.7-3.2c1.6-0.1,3,1.1,3.2,2.7c0,0.1,0,0.1,0,0.2C70.4,31.4,69.2,32.7,67.5,32.8
	C67.6,32.8,67.6,32.8,67.5,32.8z M67.5,25c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9
	c0-2.7-2.1-4.8-4.7-4.9C67.6,25,67.6,25,67.5,25z M78.2,32.8c-1.6,0.1-3-1.1-3.2-2.7c-0.1-1.6,1.1-3,2.7-3.2c1.6-0.1,3,1.1,3.2,2.7
	c0,0.1,0,0.1,0,0.2C81.1,31.4,79.9,32.7,78.2,32.8C78.3,32.8,78.3,32.8,78.2,32.8z M78.2,25c-2.7,0-4.9,2.2-4.9,4.9
	c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.1-4.8-4.7-4.9C78.3,25,78.3,25,78.2,25z"
      />
      <path
        class="playmarket-st0"
        d="M54.5,11.2L54.5,11.2l-3.6-0.1v1.1h2.5c0,0.4-0.2,0.9-0.5,1.2c-1,1-2.7,1-3.7,0c-0.5-0.5-0.8-1.2-0.7-1.9
	c0-0.7,0.2-1.4,0.8-1.9c0.5-0.5,1.1-0.7,1.8-0.7c0.7,0,1.3,0.3,1.8,0.8l0.1,0.1L53.6,9l0.1-0.1l-0.1-0.1c-0.3-0.4-0.7-0.6-1.1-0.8
	c-1.4-0.6-3-0.3-4.1,0.8c-1.5,1.4-1.5,3.8,0,5.2c0.7,0.7,1.7,1.1,2.7,1.1c1,0,1.9-0.3,2.6-1l0,0c0.6-0.6,0.9-1.5,0.9-2.4
	C54.5,11.6,54.5,11.4,54.5,11.2z"
      />
      <polygon
        class="playmarket-st0"
        points="55.6,15 59.9,15 59.9,14.1 59.9,14 56.7,14 56.7,12 59.5,12 59.5,11.1 59.5,11 56.7,11 56.7,9 59.9,9
	59.9,8.9 59.9,8 59.9,7.9 55.6,7.9 "
      />
      <polygon
        class="playmarket-st0"
        points="60.5,8.9 60.5,9 62.5,9 62.5,15 63.5,15 63.6,15 63.6,14.9 63.6,9 65.5,9 65.5,8 65.5,7.9 60.5,7.9 "
      />
      <polygon
        class="playmarket-st0"
        points="68.7,7.9 68.7,15 68.8,15 69.7,15 69.8,15 69.8,7.9 68.8,7.9 "
      />
      <polygon
        class="playmarket-st0"
        points="70.7,8.9 70.7,9 72.6,9 72.6,15 73.6,15 73.7,15 73.7,14.9 73.7,9 75.7,9 75.7,8 75.7,7.9 70.7,7.9 "
      />
      <polygon
        class="playmarket-st0"
        points="91.2,7.9 91.1,7.9 91.1,12.1 91.1,13 87.9,7.9 86.8,7.9 86.7,7.9 86.7,15 86.8,15 87.7,15 87.8,15
	87.8,10.6 87.7,9.7 91.1,15 92.1,15 92.2,15 92.2,7.9 "
      />
      <path
        class="playmarket-st0"
        d="M82,7.8c-1,0-1.9,0.4-2.6,1.1c-1.4,1.5-1.4,3.8,0,5.3l0,0c1.4,1.4,3.8,1.4,5.2,0c0,0,0,0,0,0
	c0.7-0.7,1.1-1.6,1-2.6c0-1-0.4-1.9-1-2.6C83.9,8.1,83,7.7,82,7.8z M83.8,13.4c-1,1-2.6,1-3.6,0c0,0,0,0,0,0c-1-1.1-1-2.7,0-3.8
	c1-1,2.6-1,3.6,0c0,0,0,0,0,0l0,0C84.8,10.7,84.8,12.3,83.8,13.4z"
      />
    </svg>
  </template>
  <template v-else-if="locale === 'uk'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <path
        class="playmarket-st0"
        d="M122.8,34.4h2.1V20.1h-2.1V34.4z M142.1,25.3l-2.5,6.2h-0.1l-2.5-6.2h-2.3l3.8,8.7l-2.2,4.8h2.2l5.9-13.5H142.1
	z M129.9,32.8c-0.7,0-1.7-0.4-1.7-1.2c0-1.1,1.2-1.5,2.3-1.5c0.7,0,1.4,0.1,2,0.5C132.4,31.8,131.3,32.8,129.9,32.8L129.9,32.8z
	 M130.2,24.9c-1.6-0.1-3.1,0.8-3.8,2.2l1.9,0.8c0.4-0.7,1.2-1.1,2-1c1.1-0.1,2.2,0.7,2.3,1.8c0,0,0,0,0,0v0.1
	c-0.7-0.4-1.5-0.6-2.2-0.6c-2,0-4.1,1.1-4.1,3.2c0.1,1.8,1.6,3.2,3.5,3.2c0,0,0.1,0,0.1,0c1.1,0,2.1-0.5,2.7-1.4h0.1v1.1h2.1v-5.5
	C134.5,26.4,132.6,24.9,130.2,24.9z M116.9,27h-3v-4.9h3c1.4,0,2.5,1.1,2.5,2.5C119.4,25.9,118.3,27,116.9,27
	C116.9,27,116.9,27,116.9,27z M116.9,20.1h-5.1v14.3h2.1V29h3c2.5,0.2,4.6-1.7,4.8-4.1s-1.7-4.6-4.1-4.8
	C117.4,20.1,117.2,20.1,116.9,20.1L116.9,20.1z M88.9,32.8c-1.6-0.1-2.9-1.5-2.8-3.1c0.1-1.5,1.3-2.7,2.8-2.8
	c1.5,0.1,2.7,1.3,2.6,2.9c0,0,0,0.1,0,0.1c0.1,1.5-1,2.8-2.5,2.9C89,32.8,88.9,32.8,88.9,32.8L88.9,32.8z M91.4,26.1L91.4,26.1
	c-0.7-0.7-1.7-1.2-2.7-1.1C86,25,83.9,27.3,84.1,30c0.1,2.5,2.1,4.5,4.6,4.6c1,0,1.9-0.4,2.6-1.1h0.1v0.7c0,1.9-1,2.9-2.6,2.9
	c-1.1,0-2.1-0.7-2.5-1.7l-1.9,0.8c0.7,1.8,2.4,2.9,4.3,2.9c2.5,0,4.6-1.5,4.6-5.1v-8.7h-2V26.1L91.4,26.1z M94.9,34.4H97V20.1h-2.1
	V34.4z M100.2,29.7c-0.1-1.5,1-2.8,2.5-2.9c0,0,0,0,0.1,0c0.8,0,1.5,0.4,1.8,1L100.2,29.7z M106.8,28.1c-0.6-1.8-2.3-3-4.2-3.1
	c-2.5,0-4.6,2-4.6,4.6c0,0.1,0,0.2,0,0.3c0,2.7,2.1,4.9,4.8,4.9c1.6,0,3.2-0.8,4.1-2.2l-1.7-1.1c-0.5,0.8-1.4,1.4-2.4,1.3
	c-1,0-2-0.5-2.4-1.5l6.5-2.7L106.8,28.1L106.8,28.1z M54.8,26.5v2.1h5c-0.1,1-0.5,1.9-1.1,2.6c-1,1-2.4,1.6-3.8,1.5
	c-3,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5c1.4,0,2.8,0.5,3.8,1.5l1.5-1.5c-1.4-1.4-3.3-2.1-5.2-2.1c-4.2-0.2-7.7,3.1-7.9,7.3
	c-0.2,4.2,3.1,7.7,7.3,7.9c0.2,0,0.4,0,0.6,0c2,0.1,3.9-0.7,5.3-2.1c1.2-1.3,1.8-3.1,1.8-4.9c0-0.4,0-0.9-0.1-1.3H54.8z M67.5,32.8
	c-1.6,0.1-3-1.1-3.2-2.7c-0.1-1.6,1.1-3,2.7-3.2c1.6-0.1,3,1.1,3.2,2.7c0,0.1,0,0.1,0,0.2C70.4,31.4,69.2,32.7,67.5,32.8
	C67.6,32.8,67.6,32.8,67.5,32.8z M67.5,25c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9
	c0-2.7-2.1-4.8-4.7-4.9C67.6,25,67.6,25,67.5,25z M78.2,32.8c-1.6,0.1-3-1.1-3.2-2.7c-0.1-1.6,1.1-3,2.7-3.2c1.6-0.1,3,1.1,3.2,2.7
	c0,0.1,0,0.1,0,0.2C81.1,31.4,79.9,32.7,78.2,32.8C78.3,32.8,78.3,32.8,78.2,32.8z M78.2,25c-2.7,0-4.9,2.2-4.9,4.9
	c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.1-4.8-4.7-4.9C78.3,25,78.3,25,78.2,25z"
      />
      <path
        class="playmarket-st0"
        d="M51.1,11.3c0.5-0.3,0.9-0.9,0.9-1.5c0-0.6-0.2-1.1-0.7-1.4l0,0c-0.5-0.4-1-0.6-1.6-0.6c-0.5,0-1,0.1-1.4,0.5
	l0,0c-0.3,0.3-0.6,0.6-0.7,1v0.1l1,0.4h0.1V9.7c0.1-0.5,0.6-0.8,1.1-0.8c0.3,0,0.6,0.1,0.8,0.3l0,0c0.2,0.2,0.3,0.4,0.3,0.6
	c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.8,0.3H49v1.1h0.7c0.3,0,0.7,0.1,0.9,0.3c0.4,0.3,0.5,1,0.2,1.4c0,0.1-0.1,0.1-0.2,0.2
	c-0.2,0.2-0.6,0.3-0.9,0.3c-0.3,0-0.6-0.1-0.8-0.3l0,0c-0.2-0.2-0.4-0.5-0.5-0.8v-0.1l-1,0.4h-0.1v0.1c0.1,0.6,0.5,1,1,1.3
	c0.4,0.3,0.9,0.5,1.4,0.5c0.6,0,1.2-0.2,1.7-0.6c0.5-0.4,0.7-1,0.7-1.6c0-0.4-0.1-0.8-0.4-1.1C51.7,11.6,51.4,11.4,51.1,11.3z"
      />
      <polygon
        class="playmarket-st0"
        points="51.3,8.4 51.3,8.4 51.3,8.4 "
      />
      <path
        class="playmarket-st0"
        d="M55.3,7.6L55.3,7.6l-2.9,7.4h1.3l0.7-2h2.9l0.7,2h1.3l-2.8-7.4H55.3z M56.9,12h-2.1l1-2.9L56.9,12z"
      />
      <polygon
        class="playmarket-st0"
        points="64.2,8.2 64.2,8.2 64.2,8.2 "
      />
      <path
        class="playmarket-st0"
        d="M63.9,11.2c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.3,0.3-0.6,0.3-1c0-0.6-0.2-1.1-0.7-1.5c-0.4-0.4-1-0.6-1.5-0.6h-2.7
	v7.4h2.9c0.6,0,1.2-0.2,1.6-0.6l0,0c0.4-0.4,0.7-1,0.7-1.6c0-0.4-0.1-0.8-0.4-1.1C64.5,11.6,64.2,11.4,63.9,11.2z M63.4,9.1
	c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.5,0.3-0.7,0.3h-1.6v-2h1.6C62.9,8.7,63.2,8.9,63.4,9.1z M63.6,13.7
	L63.6,13.7c-0.2,0.2-0.5,0.3-0.8,0.3h-1.7v-2.2h1.7c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.7C63.9,13.2,63.8,13.4,63.6,13.7
	L63.6,13.7z"
      />
      <path
        class="playmarket-st0"
        d="M68.1,7.6L68.1,7.6l-2.9,7.4h1.3l0.7-2H70l0.7,2H72l-2.8-7.4H68.1z M69.7,12h-2.1l1-2.9L69.7,12z"
      />
      <polygon
        class="playmarket-st0"
        points="77.2,7.6 77.2,10.7 73.8,10.7 73.8,7.6 72.8,7.6 72.7,7.6 72.7,15.1 72.8,15.1 73.7,15.1 73.8,15.1
	73.8,11.8 77.2,11.8 77.2,15.1 78.2,15.1 78.4,15.1 78.4,7.6 77.3,7.6 "
      />
      <polygon
        class="playmarket-st0"
        points="84.3,7.8 84.3,7.6 79.1,7.6 79.1,8.6 79.1,8.8 81.1,8.8 81.1,15.1 82.2,15.1 82.3,15.1 82.3,15
	82.3,8.8 84.3,8.8 "
      />
      <path
        class="playmarket-st0"
        d="M86.7,7.6L86.7,7.6l-2.8,7.4h1.3l0.7-2h2.9l0.7,2h1.3l-2.8-7.4H86.7z M88.2,12h-2.1l1-2.9L88.2,12z"
      />
      <polygon
        class="playmarket-st0"
        points="100,7.8 100,7.8 100,7.6 98.7,7.6 98.7,7.6 96,11 96,7.6 95,7.6 94.9,7.6 94.9,11 92.3,7.6 91.1,7.6
	91,7.6 91,7.8 91,7.8 93.8,11.4 90.9,14.9 90.9,15 90.9,15.1 92.2,15.1 92.2,15.1 94.9,11.9 94.9,15.1 95.9,15.1 96,15.1 96,11.9
	98.7,15.1 100,15.1 100.1,15.1 100.1,14.9 100.1,14.9 97.1,11.4 "
      />
      <polygon
        class="playmarket-st0"
        points="105.3,7.6 105.2,7.6 101.9,13 101.9,12 101.9,12 101.9,7.6 100.9,7.6 100.7,7.6 100.7,15.1 101.8,15.1
	101.9,15.1 105.4,9.4 105.4,10.5 105.4,10.5 105.4,15.1 106.4,15.1 106.5,15.1 106.5,7.6 106.4,7.6 "
      />
      <polygon
        class="playmarket-st0"
        points="107.3,8.6 107.3,8.8 109.3,8.8 109.3,15.1 110.3,15.1 110.4,15.1 110.4,15 110.4,8.8 112.4,8.8
	112.4,7.8 112.4,7.6 107.3,7.6 "
      />
      <polygon
        class="playmarket-st0"
        points="117.8,7.6 117.7,7.6 114.4,13 114.4,12 114.4,12 114.4,7.6 113.4,7.6 113.2,7.6 113.2,15.1 114.3,15.1
	114.4,15.1 117.9,9.4 117.9,10.5 117.9,10.5 117.9,15.1 118.9,15.1 119,15.1 119,7.6 118.9,7.6 "
      />
      <path
        class="playmarket-st0"
        d="M126.5,11.9c-0.2-0.3-0.4-0.5-0.7-0.6c0.5-0.3,0.9-0.9,0.9-1.5c0-0.6-0.2-1.1-0.7-1.4l0,0
	c-0.5-0.4-1-0.6-1.6-0.6c-0.5,0-1,0.1-1.4,0.5l0,0c-0.3,0.3-0.6,0.6-0.7,1v0.1l1,0.4h0.1V9.7c0.1-0.5,0.6-0.8,1.1-0.8
	c0.3,0,0.6,0.1,0.8,0.3l0,0c0.2,0.2,0.3,0.4,0.3,0.6c0,0.3-0.1,0.5-0.3,0.7l0,0c-0.2,0.2-0.5,0.3-0.8,0.3h-0.6v1.1h0.7
	c0.3,0,0.7,0.1,0.9,0.3c0.4,0.4,0.5,1,0.1,1.4c0,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.6,0.3-0.9,0.3c-0.3,0-0.6-0.1-0.8-0.3l0,0
	c-0.2-0.2-0.4-0.5-0.5-0.8v-0.1l-1,0.4h-0.1v0.1c0.1,0.6,0.5,1,1,1.3c0.4,0.3,0.9,0.5,1.4,0.5c0.6,0,1.2-0.2,1.7-0.6
	c0.5-0.4,0.7-1,0.7-1.6C126.9,12.6,126.8,12.2,126.5,11.9z"
      />
      <path
        class="playmarket-st1"
        d="M12.1,8.5c-0.4,0.5-0.6,1-0.5,1.6v25.7c0,0.6,0.2,1.2,0.5,1.6l0.1,0.1l14.4-14.4v-0.3L12.1,8.5L12.1,8.5z"
      />
      <path
        class="playmarket-st2"
        d="M31.4,27.9l-4.8-4.8v-0.3l4.8-4.8l0.1,0.1l5.7,3.2c1.6,0.9,1.6,2.4,0,3.4l-5.7,3.2L31.4,27.9z"
      />
      <path
        class="playmarket-st3"
        d="M31.6,27.9L26.6,23L12.1,37.5c0.7,0.6,1.7,0.6,2.4,0.1L31.6,27.9"
      />
      <path
        class="playmarket-st4"
        d="M31.6,18l-17-9.7c-0.7-0.6-1.7-0.5-2.4,0.1L26.6,23L31.6,18z"
      />
      <path
        class="playmarket-st5"
        d="M31.4,27.8l-16.9,9.6c-0.7,0.5-1.6,0.5-2.3,0l0,0l-0.1,0.1l0,0l0.1,0.1l0,0c0.7,0.5,1.6,0.5,2.3,0l17-9.7
	L31.4,27.8z"
      />
      <path
        class="playmarket-st6"
        d="M12.1,37.3c-0.4-0.5-0.6-1-0.5-1.6v0.2c0,0.6,0.2,1.2,0.5,1.6V37.3L12.1,37.3z"
      />
      <path
        class="playmarket-st7"
        d="M37.2,24.5l-5.8,3.3l0.1,0.1l5.7-3.2c0.7-0.3,1.1-0.9,1.2-1.7l0,0C38.3,23.6,37.9,24.2,37.2,24.5z"
      />
      <path
        class="playmarket-st8"
        d="M14.5,8.6l22.7,12.9c0.6,0.3,1.1,0.8,1.2,1.5l0,0c-0.1-0.7-0.5-1.4-1.2-1.7L14.5,8.4c-1.6-0.9-3-0.2-3,1.7v0.2
	C11.6,8.4,12.9,7.6,14.5,8.6z"
      />
    </svg>
  </template>
  <template v-else-if="locale === 'ru'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <path
        class="playmarket-st1"
        d="M12.1,8.5c-0.4,0.5-0.6,1-0.5,1.6v25.7c0,0.6,0.2,1.2,0.5,1.6l0.1,0.1l14.4-14.4v-0.3L12.1,8.5L12.1,8.5z"
      />
      <path
        class="playmarket-st2"
        d="M31.4,27.9l-4.8-4.8v-0.3l4.8-4.8l0.1,0.1l5.7,3.2c1.6,0.9,1.6,2.4,0,3.4l-5.7,3.2L31.4,27.9z"
      />
      <path
        class="playmarket-st3"
        d="M31.6,27.9L26.6,23L12.1,37.5c0.7,0.6,1.7,0.6,2.4,0.1L31.6,27.9"
      />
      <path
        class="playmarket-st4"
        d="M31.6,18l-17-9.7c-0.7-0.6-1.7-0.5-2.4,0.1L26.6,23L31.6,18z"
      />
      <path
        class="playmarket-st5"
        d="M31.4,27.8l-16.9,9.6c-0.7,0.5-1.6,0.5-2.3,0l0,0l-0.1,0.1l0,0l0.1,0.1l0,0c0.7,0.5,1.6,0.5,2.3,0l17-9.7
	L31.4,27.8z"
      />
      <path
        class="playmarket-st6"
        d="M12.1,37.3c-0.4-0.5-0.6-1-0.5-1.6v0.2c0,0.6,0.2,1.2,0.5,1.6V37.3L12.1,37.3z"
      />
      <path
        class="playmarket-st7"
        d="M37.2,24.5l-5.8,3.3l0.1,0.1l5.7-3.2c0.7-0.3,1.1-0.9,1.2-1.7l0,0C38.3,23.6,37.9,24.2,37.2,24.5z"
      />
      <path
        class="playmarket-st8"
        d="M14.5,8.6l22.7,12.9c0.6,0.3,1.1,0.8,1.2,1.5l0,0c-0.1-0.7-0.5-1.4-1.2-1.7L14.5,8.4c-1.6-0.9-3-0.2-3,1.7v0.2
	C11.6,8.4,12.9,7.6,14.5,8.6z"
      />
      <path
        class="playmarket-st0"
        d="M122.8,34.4h2.1V20.1h-2.1V34.4z M142.1,25.3l-2.5,6.2h-0.1l-2.5-6.2h-2.3l3.8,8.7l-2.2,4.8h2.2l5.9-13.5H142.1
	z M129.9,32.8c-0.7,0-1.7-0.4-1.7-1.2c0-1.1,1.2-1.5,2.3-1.5c0.7,0,1.4,0.1,2,0.5C132.4,31.8,131.3,32.8,129.9,32.8L129.9,32.8z
	 M130.2,24.9c-1.6-0.1-3.1,0.8-3.8,2.2l1.9,0.8c0.4-0.7,1.2-1.1,2-1c1.1-0.1,2.2,0.7,2.3,1.8c0,0,0,0,0,0v0.1
	c-0.7-0.4-1.5-0.6-2.2-0.6c-2,0-4.1,1.1-4.1,3.2c0.1,1.8,1.6,3.2,3.5,3.2c0,0,0.1,0,0.1,0c1.1,0,2.1-0.5,2.7-1.4h0.1v1.1h2.1v-5.5
	C134.5,26.4,132.6,24.9,130.2,24.9z M116.9,27h-3v-4.9h3c1.4,0,2.5,1.1,2.5,2.5C119.4,25.9,118.3,27,116.9,27
	C116.9,27,116.9,27,116.9,27z M116.9,20.1h-5.1v14.3h2.1V29h3c2.5,0.2,4.6-1.7,4.8-4.1s-1.7-4.6-4.1-4.8
	C117.4,20.1,117.2,20.1,116.9,20.1L116.9,20.1z M88.9,32.8c-1.6-0.1-2.9-1.5-2.8-3.1c0.1-1.5,1.3-2.7,2.8-2.8
	c1.5,0.1,2.7,1.3,2.6,2.9c0,0,0,0.1,0,0.1c0.1,1.5-1,2.8-2.5,2.9C89,32.8,88.9,32.8,88.9,32.8L88.9,32.8z M91.4,26.1L91.4,26.1
	c-0.7-0.7-1.7-1.2-2.7-1.1C86,25,83.9,27.3,84.1,30c0.1,2.5,2.1,4.5,4.6,4.6c1,0,1.9-0.4,2.6-1.1h0.1v0.7c0,1.9-1,2.9-2.6,2.9
	c-1.1,0-2.1-0.7-2.5-1.7l-1.9,0.8c0.7,1.8,2.4,2.9,4.3,2.9c2.5,0,4.6-1.5,4.6-5.1v-8.7h-2V26.1L91.4,26.1z M94.9,34.4H97V20.1h-2.1
	V34.4z M100.2,29.7c-0.1-1.5,1-2.8,2.5-2.9c0,0,0,0,0.1,0c0.8,0,1.5,0.4,1.8,1L100.2,29.7z M106.8,28.1c-0.6-1.8-2.3-3-4.2-3.1
	c-2.5,0-4.6,2-4.6,4.6c0,0.1,0,0.2,0,0.3c0,2.7,2.1,4.9,4.8,4.9c1.6,0,3.2-0.8,4.1-2.2l-1.7-1.1c-0.5,0.8-1.4,1.4-2.4,1.3
	c-1,0-2-0.5-2.4-1.5l6.5-2.7L106.8,28.1L106.8,28.1z M54.8,26.5v2.1h5c-0.1,1-0.5,1.9-1.1,2.6c-1,1-2.4,1.6-3.8,1.5
	c-3,0-5.5-2.5-5.5-5.5s2.5-5.5,5.5-5.5c1.4,0,2.8,0.5,3.8,1.5l1.5-1.5c-1.4-1.4-3.3-2.1-5.2-2.1c-4.2-0.2-7.7,3.1-7.9,7.3
	c-0.2,4.2,3.1,7.7,7.3,7.9c0.2,0,0.4,0,0.6,0c2,0.1,3.9-0.7,5.3-2.1c1.2-1.3,1.8-3.1,1.8-4.9c0-0.4,0-0.9-0.1-1.3H54.8z M67.5,32.8
	c-1.6,0.1-3-1.1-3.2-2.7c-0.1-1.6,1.1-3,2.7-3.2c1.6-0.1,3,1.1,3.2,2.7c0,0.1,0,0.1,0,0.2C70.4,31.4,69.2,32.7,67.5,32.8
	C67.6,32.8,67.6,32.8,67.5,32.8z M67.5,25c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9
	c0-2.7-2.1-4.8-4.7-4.9C67.6,25,67.6,25,67.5,25z M78.2,32.8c-1.6,0.1-3-1.1-3.2-2.7c-0.1-1.6,1.1-3,2.7-3.2c1.6-0.1,3,1.1,3.2,2.7
	c0,0.1,0,0.1,0,0.2C81.1,31.4,79.9,32.7,78.2,32.8C78.3,32.8,78.3,32.8,78.2,32.8z M78.2,25c-2.7,0-4.9,2.2-4.9,4.9
	c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9c0-2.7-2.1-4.8-4.7-4.9C78.3,25,78.3,25,78.2,25z"
      />
      <path
        class="playmarket-st0"
        d="M53.8,7.9h-4.8v3.6l0,0l0,0c0.1,0.9-0.2,1.8-0.8,2.4h-0.7v2.5h1.1V15h5v1.5h1.1V14h-0.8V7.9z M50.1,11.5V9h2.6
	v5h-3.2C49.9,13.2,50.1,12.4,50.1,11.5z"
      />
      <polygon
        class="playmarket-st0"
        points="56.2,14.1 56.2,14.1 56.2,14.1 "
      />
      <path
        class="playmarket-st0"
        d="M58.8,7.8c-1,0-1.9,0.4-2.6,1.1l0,0c-1.4,1.5-1.4,3.8,0,5.3c1.4,1.4,3.8,1.4,5.2,0L61.3,14l0.1,0.1
	c1.4-1.5,1.4-3.8,0-5.3C60.7,8.1,59.8,7.7,58.8,7.8z M56.3,11.5c0-0.7,0.2-1.4,0.7-1.9c1-1,2.6-1,3.6,0c0,0,0,0,0,0l0,0
	c1,1.1,1,2.7,0,3.8c-1,1-2.6,1-3.6,0c0,0,0,0,0,0l0,0C56.5,12.9,56.2,12.2,56.3,11.5z"
      />
      <path
        class="playmarket-st0"
        d="M68.9,13.1L68.9,13.1c-0.6,0.7-1.3,1.1-2.1,1.1c-0.7,0-1.3-0.2-1.8-0.7l0,0c-0.5-0.5-0.7-1.2-0.7-1.9
	c0-0.7,0.2-1.4,0.7-1.9l0,0c0.5-0.5,1.1-0.8,1.8-0.7c0.7,0,1.4,0.3,1.8,0.8l0.1,0.1l0.7-0.7L69.5,9l-0.1-0.1
	c-0.7-0.8-1.6-1.2-2.6-1.1c-1,0-1.9,0.4-2.6,1.1c-1.5,1.4-1.5,3.8-0.1,5.2c0.7,0.7,1.7,1.1,2.7,1.1c0.5,0,1.1-0.1,1.6-0.3
	c0.5-0.2,0.9-0.5,1.2-0.9v-0.1L69,13.2L68.9,13.1z"
      />
      <polygon
        class="playmarket-st0"
        points="70.2,8.9 70.2,9 72.1,9 72.1,15 73.1,15 73.2,15 73.2,14.9 73.2,9 75.1,9 75.1,8 75.1,7.9 70.2,7.9 "
      />
      <path
        class="playmarket-st0"
        d="M81.1,7.9H80l-1.6,3.8l-1.6-3.8h-1.3l2.4,5.2l-0.2,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.1-0.4,0.1
	c-0.1,0-0.3,0-0.4-0.1l-0.1-0.1l-0.3,1V15h0.1c0.2,0.1,0.5,0.2,0.7,0.2c0.8,0,1.5-0.5,1.7-1.3l2.7-6L81.1,7.9z"
      />
      <polygon
        class="playmarket-st0"
        points="78.6,13.9 78.6,13.9 78.6,13.9 "
      />
      <polygon
        class="playmarket-st0"
        points="82,15 82.1,15 83,15 83.1,15 83.1,9 86.1,9 86.1,15 87.1,15 87.2,15 87.2,7.9 82,7.9 "
      />
      <polygon
        class="playmarket-st0"
        points="92.9,7.9 92.9,10.9 89.7,10.9 89.7,7.9 88.7,7.9 88.6,7.9 88.6,15 88.7,15 89.6,15 89.7,15 89.7,11.9
	92.9,11.9 92.9,15 93.9,15 94,15 94,7.9 93,7.9 "
      />
      <path
        class="playmarket-st0"
        d="M98.6,7.8c-1,0-1.9,0.4-2.6,1.1l0,0c-1.4,1.5-1.4,3.8,0,5.3l0,0c1.4,1.4,3.8,1.4,5.2,0c0,0,0,0,0,0
	c0.7-0.7,1-1.7,1-2.6c0-1-0.4-1.9-1-2.6C100.5,8.1,99.5,7.8,98.6,7.8z M96.8,9.6L96.8,9.6c1-1,2.6-1,3.6,0c0,0,0,0,0,0l0,0
	c1,1.1,1,2.7,0,3.8c-1,1-2.6,1-3.6,0c0,0,0,0,0,0l0,0C95.8,12.3,95.8,10.7,96.8,9.6z"
      />
      <path
        class="playmarket-st0"
        d="M110.2,11.9L110.2,11.9c-0.2-0.2-0.4-0.4-0.7-0.6c0.2-0.1,0.4-0.3,0.6-0.5l0,0c0.2-0.3,0.3-0.6,0.3-0.9
	c0-0.5-0.2-1.1-0.6-1.4l0,0c-0.4-0.4-0.9-0.6-1.5-0.6h-2.6V15h2.7c0.6,0,1.1-0.2,1.5-0.6c0.4-0.4,0.7-0.9,0.7-1.5
	C110.6,12.6,110.5,12.2,110.2,11.9z M109.2,9.9c0,0.2-0.1,0.5-0.3,0.7l0,0c-0.2,0.2-0.5,0.3-0.7,0.3h-1.5V9h1.5
	c0.3,0,0.5,0.1,0.7,0.3l0,0C109.2,9.5,109.2,9.7,109.2,9.9L109.2,9.9z M109.2,13.7L109.2,13.7c-0.2,0.2-0.5,0.3-0.7,0.3h-1.6v-2h1.6
	c0.3,0,0.6,0.1,0.8,0.3l0,0c0.2,0.2,0.3,0.4,0.3,0.7C109.5,13.2,109.3,13.5,109.2,13.7L109.2,13.7z"
      />
      <polygon
        class="playmarket-st0"
        points="109.7,8.5 109.7,8.5 109.7,8.5 "
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
</script>

<style lang="scss" scoped>
.playmarket-st0 {
  fill: #ffffff;
}

.playmarket-st1 {
  fill: #00ceff;
}

.playmarket-st2 {
  fill: #ffd000;
}

.playmarket-st3 {
  fill: #f53349;
}

.playmarket-st4 {
  fill: #00f076;
}

.playmarket-st5 {
  opacity: 0.2;
  enable-background: new;
}

.playmarket-st6 {
  opacity: 0.12;
  enable-background: new;
}

.playmarket-st7 {
  opacity: 0.3;
  enable-background: new;
}

.playmarket-st8 {
  opacity: 0.25;
  fill: #ffffff;
  enable-background: new;
}
</style>
