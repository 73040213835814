<template>
  <ClientOnly>
    <div
      v-if="appsLength > 0"
      class="v-apps"
      :class="[isExtraSmall ? 'v-justify-content-start' : 'v-justify-content-end']"
    >
      <div
        v-if="appStoreLink"
        class="v-apps--link"
      >
        <arora-nuxt-link
          class-name="v-app-icon"
          external-link
          open-in-new-tab
          :href="appStoreLink"
          label="AppStore"
        >
          <icon-mobile-stores-app-store />
        </arora-nuxt-link>
      </div>
      <div
        v-if="googlePlayLinkWithUTM"
        class="v-apps--link"
      >
        <arora-nuxt-link
          class-name="v-app-icon"
          external-link
          open-in-new-tab
          :href="googlePlayLinkWithUTM"
          label="Google Play"
        >
          <icon-mobile-stores-play-market />
        </arora-nuxt-link>
      </div>
      <div
        v-if="ruStoreLink"
        class="v-apps--link"
      >
        <arora-nuxt-link
          class-name="v-app-icon"
          external-link
          open-in-new-tab
          :href="ruStoreLink"
          label="RuStore"
        >
          <icon-mobile-stores-ru-store />
        </arora-nuxt-link>
      </div>
      <div
        v-if="huaweiAppGalleryLinkWithUTM"
        class="v-apps--link"
      >
        <arora-nuxt-link
          class-name="v-app-icon"
          external-link
          open-in-new-tab
          :href="huaweiAppGalleryLinkWithUTM"
          label="Huawei AppGallery"
        >
          <icon-mobile-stores-app-gallery />
        </arora-nuxt-link>
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { useWindowSize } from '@arora/common'

const { isExtraSmall } = useWindowSize()

const { appStoreLink, ruStoreLink, appsLength, googlePlayLinkWithUTM, huaweiAppGalleryLinkWithUTM } =
  useAppsLinks()
</script>

<style lang="scss">
.v-apps {
  display: flex;
  &--link {
    flex: 0 0 calc(50% - 0.55rem);
    max-width: calc(50% - 0.55rem);
  }
}
.v-apps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.1rem;
}
</style>
