<template>
  <svg
    class="v-chat-icon"
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.85355 23.1464C1.53857 23.4614 1 23.2383 1 22.7929V3.3C1 2.6675 1.2254 2.12585 1.6762 1.67505C2.127 1.22425 2.66827 0.999233 3.3 1H21.7C22.3325 1 22.8741 1.2254 23.3249 1.6762C23.7757 2.127 24.0008 2.66827 24 3.3V17.1C24 17.7325 23.7746 18.2741 23.3238 18.7249C22.873 19.1757 22.3317 19.4008 21.7 19.4H6.01421C5.749 19.4 5.49464 19.5054 5.30711 19.6929L1.85355 23.1464Z"
      fill="red"
    />
    <rect
      fill="#000000"
      height="2"
      rx="1"
      width="14"
      x="6"
      y="5"
    />
    <rect
      fill="#000000"
      height="2"
      rx="1"
      width="14"
      x="6"
      y="9"
    />
    <rect
      fill="#000000"
      height="2"
      rx="1"
      width="14"
      x="6"
      y="13"
    />
  </svg>
</template>
