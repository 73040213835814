import type { ProviderGetImage } from '@nuxt/image'

export const getImage: ProviderGetImage = (source, { modifiers = {} } = {}) => {
  const pathParts = source.split('.')
  if (pathParts.length === 0) {
    //that should not happen, but if it has, return whatever that was back
    return {
      url: source
    }
  }

  const format = pathParts.slice(-1)[0].toUpperCase()
  const baseSource = `${modifiers.domain ?? ''}${source}`

  if (
    modifiers.preload ||
    !modifiers.width ||
    !modifiers.height ||
    modifiers.height === 0 ||
    modifiers.width === 0 ||
    format === 'SVG'
  ) {
    return {
      url: baseSource
    }
  }

  const getResizeParametersArray = [
    `scale=${modifiers.scaleType}`,
    `width=${Math.round(
      modifiers.width * modifiers.scale * modifiers.imageQualityMultiplier * modifiers.devicePixelRatio
    )}`,
    `height=${Math.round(
      modifiers.height * modifiers.scale * modifiers.imageQualityMultiplier * modifiers.devicePixelRatio
    )}`
  ]
  if (modifiers.useProgressive) getResizeParametersArray.unshift('webp')

  return {
    url: `${baseSource}?${getResizeParametersArray.join('&')}`
  }
}
