<template>
  <svg
    class="v-ranged-present"
    fill="none"
    height="38"
    viewBox="0 0 42 38"
    width="42"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_215_194)">
      <path
        d="M16.1113 1.21693V1.21691L16.1051 1.21688C14.2193 1.2069 12.3783 2.10332 11.2055 3.56364C10.2549 4.74743 9.74949 6.29636 9.99738 8.0002H5.99984C4.85057 8.0002 3.74837 8.45674 2.93571 9.2694C2.12305 10.0821 1.6665 11.1843 1.6665 12.3335V15.6669C1.6665 16.3741 1.94746 17.0524 2.44755 17.5525C2.94765 18.0526 3.62593 18.3335 4.33317 18.3335H19.3332H20.3332V17.3335V13.3335H21.6665V17.3335V18.3335H22.6665H37.6665C38.3737 18.3335 39.052 18.0526 39.5521 17.5525C40.0522 17.0524 40.3332 16.3741 40.3332 15.6669V12.3335C40.3332 11.1843 39.8766 10.0821 39.064 9.2694C38.2513 8.45674 37.1491 8.0002 35.9998 8.0002H31.9905C32.7957 2.20245 24.9215 -1.42426 21.1442 3.80769L21.0103 3.98628L20.8615 3.78271C19.6225 2.03647 17.8723 1.23717 16.1113 1.21693ZM15.9998 6.66687C16.5969 6.66687 16.8928 7.39299 16.4761 7.80976C16.0593 8.22652 15.3332 7.93059 15.3332 7.33353C15.3332 7.15672 15.4034 6.98715 15.5284 6.86213C15.6535 6.7371 15.823 6.66687 15.9998 6.66687ZM25.9998 6.66687C26.5969 6.66687 26.8928 7.39299 26.4761 7.80976C26.0593 8.22652 25.3332 7.93059 25.3332 7.33353C25.3332 7.15672 25.4034 6.98715 25.5284 6.86213C25.6535 6.7371 25.823 6.66687 25.9998 6.66687Z"
        fill="#DD6430"
        stroke="white"
        stroke-width="2"
      />
    </g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@use '~/assets/variables';

.v-ranged-present {
  fill: variables.$PrimaryBackgroundColor;

  path,
  circle {
    fill: variables.$PrimaryBackgroundColor;
    stroke: variables.$BodyElementsBackground;
  }
}
</style>
