import type { Settings } from '~types/settings'
import type { ThemeSingle } from '~types/settingsTheme'

import { FontSettings, useShadeBlendColor } from '@arora/common'

export default function createGlobalTheme(settings: Settings, useAltTheme: boolean): string {
  const { colorDark, colorLight, colorNotGrey, darken, getContrastColor, lighten, opaque } =
    useShadeBlendColor()

  const currentTheme: ThemeSingle = useAltTheme
    ? settings.Theme.AlternativeTheme
    : settings.Theme.DefaultTheme

  const fontProperties = FontSettings.get(settings.Theme.FontFamilyText)
  const fontHeadingProperties = FontSettings.get(settings.Theme.FontFamilyHeading)
  const fontMenuGroupsProperties = FontSettings.get(settings.Theme.FontFamilyMenuGroups)

  let mainFont = "-apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Tahoma, sans-serif"

  if (fontProperties) {
    const defaultFont =
      fontProperties.Type === 'sans-serif'
        ? "-apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', Tahoma"
        : 'Times, Times New Roman, Noto Serif, DejaVu Serif'

    mainFont = `${fontProperties.Value}, ${defaultFont}, ${fontProperties.Type}`
  }

  let styles = ''

  styles += tryAddThemeValue('FontFamilyMain', mainFont)

  styles += tryAddThemeValue(
    'FontFamilyHeading',
    fontHeadingProperties ? `${fontHeadingProperties.Value}, ${fontHeadingProperties.Type}` : mainFont
  )

  styles += tryAddThemeValue(
    'FontFamilyMenuGroups',
    fontMenuGroupsProperties
      ? `${fontMenuGroupsProperties.Value}, ${fontMenuGroupsProperties.Type}`
      : mainFont
  )

  styles += `color-scheme: ${currentTheme.DarkTheme ? 'dark' : 'light'};`

  if (Number.parseInt(settings.Theme.BorderRadiusInput.replaceAll('px', '')) > 25) {
    settings.Theme.BorderRadiusInput = '25px'
  }

  for (const [key, value] of Object.entries(currentTheme)) {
    if (typeof value === 'string') {
      if ((key === 'BorderColor' || key === 'BorderColorLight') && colorNotGrey(value, 50)) continue

      styles += tryAddThemeValue(key, value)
    }
  }

  for (const [key, value] of Object.entries(settings.Theme)) {
    if (typeof value === 'string' && !key.includes('Font')) {
      styles += tryAddThemeValue(key, value)
    }
  }
  //color variations
  styles += tryAddThemeValue(
    'BodyElementsBackground',
    lighten(currentTheme.BodyBackground, currentTheme.DarkTheme ? 3 : 50)
  )
  styles += tryAddThemeValue('BodyBackgroundDarken', darken(currentTheme.BodyBackground, 10))
  styles += tryAddThemeValue('BodyBackgroundDarker', darken(currentTheme.BodyBackground, 15))
  styles += tryAddThemeValue('BodyElementsBackgroundOpaq25', opaque(currentTheme.BodyBackground, '.25'))
  styles += tryAddThemeValue('BodyElementsBackgroundOpaq50', opaque(currentTheme.BodyBackground, '.5'))
  styles += tryAddThemeValue('BodyElementsBackgroundOpaq75', opaque(currentTheme.BodyBackground, '.75'))
  styles += tryAddThemeValue('BodyElementsBackgroundOpaq90', opaque(currentTheme.BodyBackground, '.9'))
  styles += tryAddThemeValue('PrimaryColorOpaq50', opaque(settings.Theme.PrimaryColor, '.5'))
  styles += tryAddThemeValue(
    'SecondaryBackgroundColorOpaq50',
    opaque(settings.Theme.SecondaryBackgroundColor, '.5')
  )
  styles += tryAddThemeValue(
    'PrimaryBackgroundColorOpaq25',
    opaque(settings.Theme.PrimaryBackgroundColor, '.25')
  )
  styles += tryAddThemeValue('FormBackgroundNoOpaque', opaque(currentTheme.FormBackground, '1'))
  styles += tryAddThemeValue('PopupBackgroundOpaque', opaque(currentTheme.PopupBackground, '0'))
  styles += tryAddThemeValue('PopupBackgroundLighten', lighten(currentTheme.PopupBackground, 5))
  styles += tryAddThemeValue('PopupBackgroundDarken', darken(currentTheme.PopupBackground, 5))
  styles += tryAddThemeValue(
    'SecondaryBackgroundColorDarken',
    darken(settings.Theme.SecondaryBackgroundColor, 35)
  )
  styles += tryAddThemeValue(
    'PrimaryBackgroundColorDarken',
    darken(settings.Theme.PrimaryBackgroundColor, 35)
  )

  const primaryBackgroundColorDarkest = darken(settings.Theme.PrimaryBackgroundColor, 70)
  styles += tryAddThemeValue('PrimaryBackgroundColorDarkest', primaryBackgroundColorDarkest)
  styles += tryAddThemeValue(
    'PrimaryColorDarkest',
    getContrastColor(primaryBackgroundColorDarkest, currentTheme.DarkTheme)
  )
  styles += tryAddThemeValue(
    'PrimaryBackgroundColorLighten',
    lighten(settings.Theme.PrimaryBackgroundColor, 35)
  )
  styles += tryAddThemeValue(
    'PrimaryBackgroundColorLightest',
    lighten(settings.Theme.PrimaryBackgroundColor, 70)
  )
  styles += tryAddThemeValue(
    'SecondaryBackgroundColorLighten',
    lighten(settings.Theme.SecondaryBackgroundColor, 35)
  )
  styles += tryAddThemeValue(
    'SecondaryBackgroundColorLightest',
    lighten(settings.Theme.SecondaryBackgroundColor, 70)
  )
  styles += tryAddThemeValue(
    'DiscountTimerBackgroundColorDarken',
    darken(currentTheme.DiscountTimerBackgroundColor, 35)
  )
  styles += tryAddThemeValue('DiscountTimerColorDarken', darken(currentTheme.DiscountTimerColor, 35))
  styles += tryAddThemeValue(
    'DiscountTimerWrapperColor',
    getContrastColor(currentTheme.DiscountTimerWrapperBackgroundColor, currentTheme.DarkTheme)
  )
  styles += tryAddThemeValue(
    'SmallBasketColor',
    getContrastColor(currentTheme.SmallBasketBackgroundColor, currentTheme.DarkTheme)
  )
  styles += tryAddThemeValue(
    'SmallBasketBackgroundColorVariant',
    darken(currentTheme.SmallBasketBackgroundColor, 10)
  )

  const productCardColor = getContrastColor(currentTheme.ProductCardBackground, currentTheme.DarkTheme)
  styles += tryAddThemeValue('ProductCardColor', productCardColor)
  if (productCardColor === colorLight) {
    //dark card
    styles += tryAddThemeValue('ProductCardColorBright', productCardColor)
    styles += tryAddThemeValue('ProductCardOptionBackground', opaque(productCardColor, '.8'))
    styles += tryAddThemeValue('ProductCardOptionColor', colorDark)
    styles += tryAddThemeValue('ProductCardOptionBorder', opaque(productCardColor, '.3'))
  } else {
    //light card
    styles += tryAddThemeValue('ProductCardColorBright', settings.Theme.PrimaryBackgroundColor)
    styles += tryAddThemeValue('ProductCardOptionBackground', opaque(productCardColor, '.1'))
    styles += tryAddThemeValue('ProductCardOptionColor', colorDark)
    styles += tryAddThemeValue('ProductCardOptionBorder', opaque(productCardColor, '.1'))
  }

  const popupColor = getContrastColor(currentTheme.PopupBackground, currentTheme.DarkTheme)
  styles += tryAddThemeValue('PopupColor', popupColor)

  styles += tryAddThemeValue('TextSizeH1', `${settings.Theme.TextSizeH1}px`)
  styles += tryAddThemeValue('TextSizeH2', `${settings.Theme.TextSizeH1 * 0.75}px`)
  styles += tryAddThemeValue('TextSizeH3', `${settings.Theme.TextSizeMain * 1.45}px`)
  styles += tryAddThemeValue('TextSizeMain', `${settings.Theme.TextSizeMain}px`)
  styles += tryAddThemeValue('TextSizeLabel', `${settings.Theme.TextSizeMain * 0.75}px`)
  styles += tryAddThemeValue('MenuItemDescriptionLines', settings.MenuItemDescriptionLines.toString())
  styles += tryAddThemeValue(
    'MenuItemDescriptionFullLines',
    settings.MenuItemDescriptionFullLines.toString()
  )

  styles += tryAddThemeValue('ContainerWidth', `${settings.Theme.ContainerWidth}px`)

  styles += tryAddThemeValue(
    'ErrorForeColor',
    getContrastColor(settings.Theme.ErrorColor, currentTheme.DarkTheme)
  )
  styles += tryAddThemeValue(
    'WarningForeColor',
    getContrastColor(settings.Theme.WarningColor, currentTheme.DarkTheme)
  )
  styles += tryAddThemeValue(
    'SuccessForeColor',
    getContrastColor(settings.Theme.SuccessColor, currentTheme.DarkTheme)
  )

  const footerColor = getContrastColor(currentTheme.FooterBackgroundColor, currentTheme.DarkTheme)
  styles += tryAddThemeValue('FooterColor', footerColor)
  styles += tryAddThemeValue('FooterColorOpaq50', opaque(footerColor, '.5'))

  const optionsRollerBackground = lighten(currentTheme.BodyBackground, 20)
  styles += tryAddThemeValue('OptionsRollerBackground', optionsRollerBackground)
  styles += tryAddThemeValue(
    'OptionsRollerColor',
    getContrastColor(optionsRollerBackground, currentTheme.DarkTheme)
  )

  const contrastHeaderColor = getContrastColor(
    currentTheme.HeaderBackgroundColor,
    currentTheme.DarkTheme
  )
  styles += tryAddThemeValue('HeaderColor', contrastHeaderColor)
  styles += tryAddThemeValue('HeaderColorOpaq50', opaque(contrastHeaderColor, '.5'))

  styles += tryAddThemeValue(
    'SearchHighlightColor',
    getContrastColor(settings.Theme.SearchHighlightBackgroundColor, currentTheme.DarkTheme)
  )

  let headerBackgroundColorVariant: string | null =
    contrastHeaderColor === colorLight
      ? lighten(currentTheme.HeaderBackgroundColor, 5)
      : darken(currentTheme.HeaderBackgroundColor, 5)

  if (headerBackgroundColorVariant === currentTheme.HeaderBackgroundColor) {
    headerBackgroundColorVariant =
      contrastHeaderColor === colorDark
        ? lighten(currentTheme.HeaderBackgroundColor, 5)
        : darken(currentTheme.HeaderBackgroundColor, 5)
  }

  styles += tryAddThemeValue('HeaderBackgroundColorContrast5', headerBackgroundColorVariant)

  let headerBackgroundColorContrast: string | null =
    contrastHeaderColor === colorLight
      ? lighten(currentTheme.HeaderBackgroundColor, 10)
      : darken(currentTheme.HeaderBackgroundColor, 10)

  if (headerBackgroundColorContrast === currentTheme.HeaderBackgroundColor) {
    headerBackgroundColorContrast =
      contrastHeaderColor === colorDark
        ? lighten(currentTheme.HeaderBackgroundColor, 10)
        : darken(currentTheme.HeaderBackgroundColor, 10)
  }

  styles += tryAddThemeValue('HeaderBackgroundColorContrast10', headerBackgroundColorContrast)

  const optionsBackground = currentTheme.DarkTheme ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.05)'

  const optionsColor = currentTheme.DarkTheme ? '#eee' : '#222'

  styles += tryAddThemeValue('OptionsBackground', optionsBackground)
  styles += tryAddThemeValue('OptionsColor', optionsColor)

  styles += tryAddThemeValue('ScrollColor', currentTheme.DarkTheme ? '#1313117F' : '#C7C7C77F')
  styles += tryAddThemeValue('ScrollBackgroundColor', currentTheme.DarkTheme ? '#2A2A2A7F' : '#F5F4F47F')

  styles += tryAddThemeValue(
    'FloatShadow',
    opaque(getContrastColor(currentTheme.BodyBackground, currentTheme.DarkTheme), '.1')
  )

  const popupOverlayBackground = currentTheme.DarkTheme ? 'rgba(255,255,255,0.35)' : 'rgba(0,0,0,0.35)'

  styles += tryAddThemeValue('PopupOverlayBackground', popupOverlayBackground)
  styles += tryAddThemeValue(
    'PopupOverlayColor',
    getContrastColor(popupOverlayBackground, currentTheme.DarkTheme)
  )

  //triangle for select
  const triangle =
    `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox` +
    `%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22${encodeURIComponent(
      settings.Theme.PrimaryBackgroundColor
    )}%22%20d%3D%22M31.3%20192h257.3c17.8%200%2026.7%2021.5%2014.1%2034.1L174.1%20354.8c-7.8%207.8-20.5%207.8-28.3%200L17.` +
    `2%20226.1C4.6%20213.5%2013.5%20192%2031.3%20192z%22%2F%3E%3C%2Fsvg%3E')`

  //triangle for select
  const triangleRotated =
    `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox` +
    `%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22${encodeURIComponent(
      settings.Theme.PrimaryBackgroundColor
    )}%22%20d%3D%22M288.662%20352H31.338c-17.818%200-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81%2020.474-` +
    `7.81%2028.284%200l128.662%20128.662c12.6%2012.599%203.676%2034.142-14.142%2034.142z%22%2F%3E%3C%2Fsvg%3E')`

  styles += tryAddThemeValue('SvgTriangle', triangle)
  styles += tryAddThemeValue('SvgTriangleRotated', triangleRotated)

  const arrowPrevious = `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%226%22%20height%3D%2214%22%20viewBox%3D%220%200%206%2014%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M5%2013L1%207L5%201%22%20stroke%3D%22${encodeURIComponent(
    settings.Theme.SecondaryColor
  )}%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E')`

  const arrowNext = `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%226%22%20height%3D%2214%22%20viewBox%3D%220%200%206%2014%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M1%201L5%207L1%2013%22%20stroke%3D%22${encodeURIComponent(
    settings.Theme.SecondaryColor
  )}%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%20%20%20%20%3C%2Fsvg%3E')`

  const arrowFilledPrevious = `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20cx%3D%2214%22%20cy%3D%2214%22%20r%3D%2214%22%20fill%3D%22${encodeURIComponent(settings.Theme.SecondaryBackgroundColor)}%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M15%2020L11%2014L15%208%22%20stroke%3D%22${encodeURIComponent(
    settings.Theme.SecondaryColor
  )}%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%20%20%3C%2Fsvg%3E')`

  const arrowFilledNext = `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2228%22%20height%3D%2228%22%20viewBox%3D%220%200%2028%2028%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cellipse%20cx%3D%2214%22%20cy%3D%2214%22%20rx%3D%2214%22%20ry%3D%2214%22%20transform%3D%22rotate%28-180%2014%2014%29%22%20fill%3D%22${encodeURIComponent(
    settings.Theme.SecondaryBackgroundColor
  )}%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M13%208L17%2014L13%2020%22%20stroke%3D%22${encodeURIComponent(
    settings.Theme.SecondaryColor
  )}%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%20%20%3C%2Fsvg%3E')`

  const arrowFilledBigPrevious = `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2244%22%20height%3D%2244%22%20viewBox%3D%220%200%2044%2044%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cellipse%20cx%3D%2220%22%20cy%3D%2220%22%20rx%3D%2220%22%20ry%3D%2220%22%20fill%3D%22${encodeURIComponent(settings.Theme.SecondaryBackgroundColor)}%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M22%2028L16%2020L22%2012%22%20stroke%3D%22${encodeURIComponent(
    settings.Theme.SecondaryColor
  )}%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%20%20%3C%2Fsvg%3E')`

  const arrowFilledBigNext = `url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2244%22%20height%3D%2244%22%20viewBox%3D%220%200%2044%2044%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cellipse%20cx%3D%2220%22%20cy%3D%2220%22%20rx%3D%2220%22%20ry%3D%2220%22%20fill%3D%22${encodeURIComponent(settings.Theme.SecondaryBackgroundColor)}%22%2F%3E%0A%20%20%20%20%3Cpath%20d%3D%22M18%2012L24%2020L18%2028%22%20stroke%3D%22${encodeURIComponent(
    settings.Theme.SecondaryColor
  )}%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%20%20%3C%2Fsvg%3E')`

  styles += tryAddThemeValue('ArrowPrev', arrowPrevious)
  styles += tryAddThemeValue('ArrowNext', arrowNext)
  styles += tryAddThemeValue('ArrowFilledPrev', arrowFilledPrevious)
  styles += tryAddThemeValue('ArrowFilledNext', arrowFilledNext)
  styles += tryAddThemeValue('ArrowFilledBigPrev', arrowFilledBigPrevious)
  styles += tryAddThemeValue('ArrowFilledBigNext', arrowFilledBigNext)
  styles += tryAddThemeValue(
    'HeaderBackgroundGradientLeft',
    `linear-gradient(90deg, ${currentTheme.HeaderBackgroundColor} 37.25%, ${opaque(currentTheme.HeaderBackgroundColor, '.0')} 100%)`
  )
  styles += tryAddThemeValue(
    'HeaderBackgroundGradientRight',
    `linear-gradient(270deg, ${currentTheme.HeaderBackgroundColor} 37.25%, ${opaque(currentTheme.HeaderBackgroundColor, '.0')} 100%)`
  )

  //wave for discounttime
  const wave = `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 36.5 32.7' style='enable-background:new 0 0 36.5 32.7;' xml:space='preserve'%3e%3cpath fill='${encodeURIComponent(
    currentTheme.DiscountTimerWrapperBackgroundColor
  )}' d='M28.9,28.9c-2.6-3.1-4.1-7.1-6-10.8c-1.6-3.1-2.9-6.3-4.6-9.3c-2.7-5.2-8-8.6-13.9-8.8H0v32.7h36.3 C33.5,32.4,30.8,31.1,28.9,28.9z'/%3e%3c/svg%3e")`

  styles += tryAddThemeValue('SvgWave', wave)

  return styles
}

function tryAddThemeValue(colorName: string, colorValue: string | null | undefined): string {
  if (!colorValue) return ''

  return `--${colorName}: ${colorValue};`
}
