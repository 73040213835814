<template>
  <svg
    fill="none"
    height="88"
    viewBox="0 0 158 88"
    width="158"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      fill="currentColor"
      height="10.0992"
      rx="5.0496"
      width="157.8"
      x="0.000976562"
      y="74.061"
    />
    <path
      clip-rule="evenodd"
      d="M86.8807 7.57464C86.8807 8.00357 86.8431 8.42416 86.771 8.83363C119.782 12.3577 145.39 37.7746 145.39 68.6058C145.39 68.7435 145.383 68.8786 145.37 69.0112H145.368C145.381 68.8786 145.388 68.7435 145.388 68.6057C145.388 35.364 115.62 8.41631 78.8993 8.41631C76.2378 8.41631 73.6128 8.55788 71.033 8.83314C70.9609 8.42383 70.9234 8.0034 70.9234 7.57464C70.9234 3.39142 74.4956 0.000244141 78.902 0.000244141C83.3085 0.000244141 86.8807 3.39142 86.8807 7.57464ZM138.381 74.0608H138.383C134.893 74.8795 130.23 75.2977 124.692 75.4827C130.229 75.2977 134.892 74.8793 138.381 74.0608ZM15.8132 69.0112H142.388C142.438 68.8794 142.455 68.7477 142.455 68.6057C142.455 37.0565 114.154 11.2216 78.8993 11.2216C43.8094 11.2216 15.6085 36.8157 15.3457 68.1638C15.4622 68.4569 15.6149 68.7395 15.8132 69.0112ZM52.4786 75.4662L52.4792 75.4662H52.4795H52.4797H52.4799H52.4802C59.4973 75.2871 67.3125 75.0877 75.8323 75.0877C77.2392 75.0877 78.6405 75.0924 80.0345 75.101C78.64 75.0924 77.2381 75.0876 75.8306 75.0876C67.3112 75.0876 59.4964 75.287 52.4795 75.4661H52.4791H52.4787L52.4778 75.4661L52.477 75.4661L52.4693 75.4663C48.5173 75.5671 44.8184 75.6616 41.3894 75.7102C44.8211 75.6616 48.5231 75.5671 52.4786 75.4662ZM72.6936 16.8319C64.4681 21.0316 46.3393 36.337 39.6274 63.9615H24.8216C27.4538 50.7403 40.7131 22.8048 72.6936 16.8319ZM95.877 75.3225C96.713 75.3383 97.5434 75.3542 98.3678 75.3699L98.3673 75.3699H98.3667L98.3661 75.3699C97.5422 75.3541 96.7124 75.3383 95.877 75.3225Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
