<i18n>
ru:
  delivery: Доставка курьером
  inHall: В зале
  selectAddress: Укажите ваш адрес
  selectAddressFull: 'Выберите, каким способом хотите получить ваш заказ.'
  selfService: Самовывоз
ua:
  delivery: Доставка кур`єром
  inHall: В залі
  selectAddress: Вкажіть вашу адресу
  selectAddressFull: 'Виберіть, яким способом хочете отримати ваше замовлення.'
  selfService: Самовивіз
us:
  delivery: Courier delivery
  inHall: In restaurant hall
  selectAddress: Choose your address
  selectAddressFull: Choose how you want to receive your order.
  selfService: Self-service
</i18n>

<template>
  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="loaded"
      class="v-address-selector"
      :class="{
        'v-address-selector__compact': compact,
        'v-mb-xxs':
          appConfig.VueSettingsPreRun.HeaderLayout === 'Umbar' &&
          !compact &&
          appConfig.VueSettingsPreRun.HeaderEnableAddresses &&
          externalLinksContacts.length > 0
      }"
    >
      <div
        v-if="!compact"
        class="v-address-selector--icon"
      >
        <icon-address-mark-light />
      </div>
      <div
        v-if="compact"
        class="v-address-selector--delivery v-d-flex v-align-items-center"
      >
        <arora-button
          ignore-settings
          :class-name="
            compact
              ? 'v-btn v-btn-link-secondary v-btn-text v-no-underline v-text-left'
              : 'v-btn v-btn-text v-no-underline v-text-left'
          "
          :label="orderType"
          data-test-id="choice-address"
          @click="async () => await changeAddress()"
        >
          <div class="v-d-flex v-align-items-center">
            <span
              class="v-mr-xs"
              v-html="orderType"
            />
            <div
              v-if="
                appConfig.VueSettingsPreRun.AverageTimeDisplayInHeader &&
                clientStore.averageTimeWithDelayMs > 0
              "
              class="v-d-flex v-align-items-center"
            >
              <header-parts-average-time-base />
            </div>
            <icon-general-chevron-right />
          </div>
        </arora-button>
      </div>
      <div
        v-else
        class="v-address-selector--city v-pointer"
        @click="async () => await changeAddress()"
        v-html="sanitize(cityName ?? '')"
      />
      <div class="v-address-selector--address">
        <arora-button
          v-if="noAddress"
          ignore-settings
          :class-name="
            compact
              ? 'v-btn v-btn-text v-no-underline v-text-left'
              : 'v-btn v-btn-link-secondary v-btn-text v-no-underline v-text-left'
          "
          :label="translate('addressSelector.selectAddress')"
          data-test-id="choice-address"
          @click="async () => await changeAddress()"
        />

        <arora-button
          v-else
          class-name="v-btn v-btn-link v-btn-text v-no-underline v-text-left"
          ignore-settings
          :label="addressText"
          data-test-id="choice-address"
          @click="async () => await changeAddress()"
        />
      </div>
    </div>
    <common-skeleton v-else />
  </transition>

  <transition
    appear
    mode="out-in"
    name="fade"
  >
    <div
      v-if="
        loaded &&
        !compact &&
        appConfig.VueSettingsPreRun.HeaderLayout === 'Umbar' &&
        appConfig.VueSettingsPreRun.HeaderEnableAddresses &&
        externalLinksContacts.length > 0
      "
      class="v-additional-header-info v-d-flex v-flex-column"
    >
      <address
        v-if="!stringIsNullOrWhitespace(externalLinksContacts[0].Url)"
        class="v-d-flex v-flex-column v-address-mark-link--phone"
      >
        <arora-nuxt-link
          v-for="(phone, index) in externalLinksContacts[0].Url.split(',')"
          :key="`header-wold-phone-${index}`"
          class-name="v-mb-xxs v-pointer v-font-weight-600"
          external-link
          :href="`tel:+${phoneBeautified(sanitize(phone)).replace(/\D+/g, '')}`"
          :label="phoneBeautified(sanitize(phone), 'header')"
        />
      </address>
      <span
        v-if="!stringIsNullOrWhitespace(externalLinksContacts[0].Additional)"
        class="v-font-weight-500 v-opacity-50"
        v-html="sanitize(externalLinksContacts[0].Additional)"
      />
    </div>
  </transition>
</template>

<script setup lang="ts">
import { OrderType } from '~api/consts'

import type { Terminal } from '~types/addressStore'
import type { CartItem, ClientState, SelectedDeliveryAddress } from '~types/clientStore'
import type { ExternalLink } from '~types/settingsVariants'

import { type GUID, useCommon } from '@arora/common'

const { compact } = defineProps<{
  externalLinksContacts: ExternalLink[]
  compact?: boolean
}>()

let once = true

const addressStore = useAddressStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { isLinkContains, pathname } = useUrl()
const { translate, sanitize } = useI18nSanitized()
const { stringIsNullOrWhitespace } = useCommon()
const { phoneBeautified } = usePhoneNumber()
const appConfig = useAppConfig()

const loaded = computed<boolean>(
  () => clientStore.ClientState?.data !== null && addressStore.Terminals?.data !== null
)
const orderType = computed<string>(() => {
  let result: string
  switch (clientStore.ClientState.data?.OrderType) {
    case OrderType.NoShipment: {
      result = translate('addressSelector.selfService')
      break
    }
    case OrderType.InHall: {
      result = translate('addressSelector.inHall')
      break
    }
    case OrderType.CourierDelivery:
    default: {
      result = translate('addressSelector.delivery')
      break
    }
  }
  return result
})

onMounted(async () => {
  await addressStore.initRestaurant()
  await addressStore.initTerminals()
  await clientStore.initClientState()
})

const { noAddress, cityName } = useAddressSelector()

const initialTerminalId = ref<GUID | null>(null)
const initialDeliveryAddress = ref<SelectedDeliveryAddress | undefined>()
const initialOrderType = ref<number>(-1)

const fromCart = computed<boolean>(
  () =>
    isLinkContains(appConfig.VueSettingsPreRun.Links.CartFirstStep) ||
    isLinkContains(appConfig.VueSettingsPreRun.Links.CartSecondStep)
)

const addressText = computed<string>(() => {
  const clientState: ClientState | null = clientStore.ClientState?.data

  if (!clientState) return ''

  if (clientStore.selfService || clientStore.inHall) {
    const terminals = addressStore.Terminals?.data
    if (clientStore.selectedTerminalId && terminals) {
      const terminal = terminals.find((terminal: Terminal) => {
        return terminal.ID === clientStore.selectedTerminalId
      })

      if (terminal) return terminal.Address
    }
  } else {
    const address = clientState?.SelectedDeliveryAddress
    if (address?.Street) {
      let result = `${address.Street} `
      result += translate('addressDelivery.houseShort', { house: address.House })

      return result
    }
  }

  return ''
})

async function saveInitialState(): Promise<void> {
  const clientState: ClientState | null = clientStore.ClientState?.data
  if (!clientState) return
  initialTerminalId.value = clientStore.selectedTerminalId ?? null
  if (clientState.SelectedDeliveryAddress)
    initialDeliveryAddress.value = {
      AddressCoordinates: { ...clientState.SelectedDeliveryAddress.AddressCoordinates },
      AddressString: clientState.SelectedDeliveryAddress.AddressString,
      Apartment: clientState.SelectedDeliveryAddress.Apartment,
      Building: clientState.SelectedDeliveryAddress.Building,
      CityID: clientState.SelectedDeliveryAddress.CityID,
      Corpus: clientState.SelectedDeliveryAddress.Corpus,
      DepartmentID: clientState.SelectedDeliveryAddress.DepartmentID,
      DistrictID: clientState.SelectedDeliveryAddress.DistrictID,
      Doorway: clientState.SelectedDeliveryAddress.Doorway,
      Floor: clientState.SelectedDeliveryAddress.Floor,
      House: clientState.SelectedDeliveryAddress.House,
      Intercom: clientState.SelectedDeliveryAddress.Intercom,
      GeoRegion: clientState.SelectedDeliveryAddress.GeoRegion,
      GeoCity: clientState.SelectedDeliveryAddress.GeoCity,
      GeoDistrict: clientState.SelectedDeliveryAddress.GeoDistrict,
      Street: clientState.SelectedDeliveryAddress.Street,
      TerminalID: clientState.SelectedDeliveryAddress.TerminalID,
      OrderDeliveryType: clientState.SelectedDeliveryAddress.OrderDeliveryType,
      ZipCode: clientState.SelectedDeliveryAddress.ZipCode
    }
  initialOrderType.value = clientState?.OrderType ?? 0
}

async function reject(): Promise<void> {
  switch (initialOrderType.value) {
    case OrderType.CourierDelivery: {
      await clientStore.updateDeliveryAddressWithAnotherAddress(initialDeliveryAddress.value!)
      break
    }
    case OrderType.NoShipment:
    case OrderType.InHall: {
      if (initialTerminalId.value) {
        await clientStore.updateSelfServiceTerminal({
          terminalId: initialTerminalId.value
        })
      }
      break
    }
  }

  await clientStore.selectOrderType({
    orderType: initialOrderType.value
  })

  //no need to refresh page - data was restored to previous values
}

async function changeAddress(): Promise<void> {
  await popupStore.openPopup({
    popupName: 'addressSelectorPopup',
    popupClosable: true,
    onClosePopup: reject,
    onOpenPopup: saveInitialState,
    popupProperties: new Map<string, unknown>([
      ['fromCart', fromCart.value],
      ['onEntrance', false],
      ['onProductAdd', false]
    ])
  })
}

watch(
  () => loaded.value,
  async (newState: boolean) => {
    if (!newState) return // in case of client state update error
    //restore previously set address

    const clientState: ClientState | null = clientStore.ClientState?.data

    if (!clientState) return

    if (once) {
      once = false

      if (!fromCart.value && !compact) {
        await addressStore.showPopupAtEntrance(noAddress.value)
      }
    }
  }
)

watch(
  () => clientStore.ClientState.data?.Cart?.Content ?? null,
  async (newContent: CartItem[] | null | undefined, oldContent: CartItem[] | null | undefined) => {
    if (!oldContent) return // after initialization oldContent is null
    if (!newContent) return // in case of client state update error

    const oldContentFiltered = oldContent.filter((item) => !item.Removed).map((item) => item.Count) ?? []

    const oldAmount =
      oldContentFiltered.length > 0
        ? oldContentFiltered.reduce((item1, item2) => {
            return item1 + item2
          })
        : 0

    const newContentFiltered = newContent.filter((item) => !item.Removed).map((item) => item.Count) ?? []

    const newAmount =
      newContentFiltered.length > 0
        ? newContentFiltered.reduce((item1, item2) => {
            return item1 + item2
          })
        : 0

    if (newAmount > oldAmount) {
      if (
        pathname !== appConfig.VueSettingsPreRun.Links.CartFirstStep &&
        pathname !== appConfig.VueSettingsPreRun.Links.CartSecondStep
      ) {
        await addressStore.onProductAdd(noAddress.value)
      }
    }
  },
  { immediate: true, deep: true }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-address-selector {
  display: grid;
  grid-template-columns: 1.2rem 1fr;
  grid-template-rows: 1.5rem auto;
  gap: 0 0.35rem;
  grid-template-areas:
    'icon city'
    '. address';

  &__compact {
    grid-template-columns: 1fr;
    grid-template-areas:
      'delivery'
      'address';
  }

  &--icon {
    grid-area: icon;
    width: auto;
    height: 1.2rem;
    overflow: visible;

    svg {
      max-width: 100%;

      g {
        opacity: 1;
      }

      &.v-poros {
        path {
          stroke: variables.$SecondaryBackgroundColor;
        }
      }

      &.v-lhun {
        path {
          fill: variables.$SecondaryBackgroundColor;
        }
      }
    }
  }

  &--delivery {
    grid-area: delivery;
    font-size: variables.$TextSizeMain;
    margin: auto 0 4px;
  }

  &--city {
    @include mixins.trans;

    grid-area: city;
    font-size: 1.2rem;

    &:hover {
      color: variables.$SecondaryBackgroundColor;
    }
  }
  &--address {
    grid-area: address;
    color: variables.$HeaderColor;
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }
}

.v-address-mark-link--phone {
  a,
  a:link,
  a:active,
  a:visited {
    max-width: fit-content;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
