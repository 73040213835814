<i18n>
ru:
  yourPoints: 'Ваши баллы: '
ua:
  yourPoints: 'Ваші бали: '
us:
  yourPoints: 'Your points: '
</i18n>

<template>
  <template
    v-if="appConfig.RestaurantSettingsPreRun.BonusSystemEnabled && accountStore.PointsBalance >= 0"
  >
    <template v-if="balanceOnly">
      <common-currency
        show-points
        :amount="accountStore.PointsBalance"
      />
    </template>
    <div
      v-else
      class="v-points-balance-mobile v-font-weight-600 v-w-100"
    >
      <div
        class="v-points-balance-mobile--title"
        v-html="translate('pointsBalance.yourPoints')"
      />
      <common-currency
        class="v-points-balance-mobile--value"
        show-points
        :amount="accountStore.PointsBalance"
      />
      <icon-general-coins class="v-points-balance-mobile--coins v-mr-xs v-mb-xs" />
    </div>
  </template>
</template>

<script setup lang="ts">
defineProps<{
  balanceOnly?: boolean
}>()

const accountStore = useAccountStore()
const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
</script>

<style lang="scss">
@use '~/assets/variables';

.v-points-balance {
  color: variables.$BodyTextColorLight;
  font-size: 0.9rem;

  .v-link {
    font-size: 0.85rem;
    color: variables.$BodyTextColorLight;
    text-decoration: underline;
  }
}

.v-points-balance-mobile {
  position: relative;
  padding: 1rem;
  background: variables.$BodyElementsBackground;
  color: variables.$BodyTextColor;
  box-shadow: variables.$CardShadow;
  border-radius: variables.$BorderRadius;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 75px;
    height: 75px;
    background: variables.$SecondaryBackgroundColor;
    border-radius: 50%;
    right: calc(-75px / 3);
    bottom: calc(-75px / 3);
  }

  &--title {
    font-size: 1.2rem;
  }

  &--value {
    font-size: 1.4rem;
    color: variables.$SecondaryBackgroundColor;
  }

  &--coins {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0;
    bottom: 0;
    fill: none;
    color: variables.$BodyElementsBackground;
  }
}
</style>
