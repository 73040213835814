<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-lhun"
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="v-fill-none"
        d="M19 13V8.5C19 6.01472 16.9853 4 14.5 4V4C12.0147 4 10 6.01472 10 8.5V13"
        stroke="#5B5B5B"
        stroke-linecap="round"
        stroke-width="1.5"
      />
      <path
        clip-rule="evenodd"
        d="M8 9H5C4.44772 9 4 9.44772 4 10V22C4 24.7614 6.23858 27 9 27H20C22.7614 27 25 24.7614 25 22V10C25 9.44772 24.5523 9 24 9H22.5H21V13C21 14.1046 20.1046 15 19 15C17.8954 15 17 14.1046 17 13V9H12V13C12 14.1046 11.1046 15 10 15C8.89543 15 8 14.1046 8 13V9Z"
        fill="#5B5B5B"
        fill-rule="evenodd"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-poros"
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.47076 9H5C4.44772 9 4 9.44772 4 10V20C4 22.7614 6.23858 25 9 25H20C22.7614 25 25 22.7614 25 20V10C25 9.44772 24.5523 9 24 9H22.5"
        stroke="#F4F4F4"
        stroke-linecap="round"
        stroke-width="1.5"
      />
      <path
        d="M19 12V8.5C19 6.01472 16.9853 4 14.5 4V4C12.0147 4 10 6.01472 10 8.5V12"
        stroke="#F4F4F4"
        stroke-linecap="round"
        stroke-width="1.5"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
