<template>
  <svg
    id="Слой_1"
    style="enable-background: new 0 0 600 600"
    viewBox="0 0 600 600"
    x="0px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    y="0px"
  >
    <!--подложка-->
    <ellipse
      class="sushi-st0"
      cx="287.3"
      cy="436.4"
      rx="272.5"
      ry="54"
    />
    <!--	тело-->
    <path
      class="sushi-st1"
      d="M189.6,233.3c-7.8,0.6-9.8,0.3-11.8-0.2c-5.5-1.4-5.3-3.5-9.5-4.2c-5.4-0.9-7.3,2.3-16,3.8
			c-4.7,0.8-7.4,0.5-9.9,0.6c-13.1,0.5-24.3,13.6-29.3,19.4c-5.6,6.5-19,22.1-17.1,40.3c0.7,6.5,3,10.6,2.9,20.9
			c0,1.9-0.1,1.8-1.7,16.4c-0.4,3.5-0.9,8.8-1.9,19.4c-0.8,8.4-1,10.8-0.4,14.5c0.6,3.5,1.3,4.5,1.7,8.8c0.5,5.5-0.4,6.5,0.6,9.5
			c1.3,4.1,3.8,4.6,6.1,8.2c3.5,5.5,1.1,9.3,2.5,14.8c3.1,12.2,20.9,17.9,29.1,20.6c7.9,2.5,14.9,3.2,20.2,3.6
			c7.4,0.7,11.2,1,16.2,0.2c3.5-0.5,5.1-1.2,8.4-1c4.2,0.3,5.6,1.7,10.3,2.3c2.6,0.3,4.4,0.1,8-0.2c9.3-0.9,10.3-2.7,15.4-2.5
			c6.2,0.3,5.4,3.1,11.6,3.4c5.1,0.3,6.3-1.6,16.7-3.2c3.8-0.6,5.7-0.9,8-0.8c6.6,0.4,7.9,3.1,13.3,4.2c8.6,1.8,10.6-3.9,20.2-2.5
			c6.4,1,5.8,3.5,11.8,4.2c8.7,0.9,10.8-4.4,19.4-3.6c6.8,0.6,6.6,4.1,13.3,4.4c5.8,0.3,5.9-2.3,16.6-3.8c5.9-0.8,9.6-0.6,12.6,0
			c3.3,0.6,3.2,1.1,5.7,1.5c6.7,1.1,10-2,15.6-2.5c6-0.5,8.8,2.4,14.7,4.4c9,3.1,16.8,1,30.6-2.7c11.2-3,23.9-6.3,36.3-17.1
			c6.2-5.3,16.8-14.5,19.4-28.5c0.3-1.5-0.1-0.1,1-19.4c0.5-9.1,0.8-12.8,0.2-18.5c-0.7-7.1-1.1-9.7-1.1-9.7
			c-0.8-4.6-1.5-6.6-1.1-9.9c0.3-3.3,1.3-5.4,1.9-6.9c2.6-6.6,0.6-12.4-1.1-23c-2.5-15.7,0.1-14-2.1-22.3
			c-3.9-14.9-13.4-24.4-17.9-28.7c-6.3-6.1-13.1-12.8-20.7-12.9c-1.4,0-4.8,0.1-9.3-1c-4.5-1.1-4.3-1.9-8-2.7
			c-5.3-1-10.1-0.1-13.1,0.6c-2.4,0.5-2.9,1.1-4.9,1.3c-3.3,0.3-4.9-0.6-8-1.1c-1.2-0.2-7.3-1.3-12.9,1.1c-4.1,1.8-4.5,4-7.8,4.2
			c-2.3,0.1-3.4-0.9-6.7-2.1c-3-1.1-5.7-1.5-11-2.3c-5.6-0.8-10.2-1.4-16-0.8c-5,0.6-5.2,1.5-12.9,2.7c-4.3,0.6-6.5,1-9.5,1
			c-6.4,0-8.2-1.6-15.2-2.3c-3.5-0.3-2.9,0.1-10.8,0c-13.8-0.1-13.4-1.3-19.6-0.6c-6.9,0.8-8.3,2.4-13.1,1.5
			c-3.2-0.6-4.2-1.6-8.4-2.1c-3.9-0.5-7-0.1-8.9,0.2c-5.5,0.7-5.6,1.8-9.3,2.1c-4.5,0.4-5.9-1.2-12-2.3c-4.5-0.8-7.7-0.7-12.9-0.6
			C200.7,231.8,199.9,232.6,189.6,233.3z"
    />
    <!--	глаза и нос-->
    <g>
      <!--			глаз левый-->
      <path
        class="sushi-st7"
        d="M208.5,324.8c-6.9-1.5-12.9,0.5-14.7,1.1c-2.1,0.7-8.6,3.1-13.3,9.7c-6.1,8.4-4.9,17.7-4.6,20.9
				c0.4,3.1,1.6,12.3,9.5,19.6c1.7,1.6,9.5,8.7,20.7,7.8c13.4-1.1,20.3-12.7,21.3-14.5c4.3-7.6,3.9-14.9,3.6-17.7
				C229.8,339.6,220.7,327.4,208.5,324.8z"
      />
      <!--			глаз левый / зрачек-->
      <path
        class="sushi-st8 eye"
        d="M200.1,330.5c-4-0.1-6.9,1.8-8.2,2.7c-1.5,1-5.2,3.4-6.4,8.1c-0.3,1.1-1.3,5.3,1.2,8.5
				c2.2,2.8,5.8,3.1,7.1,3.2c6.5,0.5,11.2-4.5,12.7-6.2c2.3-2.5,5.6-5.9,4.7-9.7C210.3,332.7,204.3,330.5,200.1,330.5z"
      />
      <!--			глаз правый-->
      <path
        class="sushi-st7"
        d="M379.9,324.8c-6.9-1.5-12.9,0.5-14.7,1.1c-2.1,0.7-8.6,3.1-13.3,9.7c-6.1,8.4-4.9,17.7-4.6,20.9
				c0.4,3.1,1.6,12.3,9.5,19.6c1.7,1.6,9.5,8.7,20.7,7.8c13.4-1.1,20.3-12.7,21.3-14.5c4.3-7.6,3.9-14.9,3.6-17.7
				C401.2,339.6,392.2,327.4,379.9,324.8z"
      />
      <!--			глаз правый/ зрачек-->
      <g>
        <path
          class="sushi-st8 eye1"
          d="M371.6,330.5c-4-0.1-6.9,1.8-8.2,2.7c-1.5,1-5.2,3.4-6.4,8.1c-0.3,1.1-1.3,5.3,1.2,8.5
				c2.2,2.8,5.8,3.1,7.1,3.2c6.5,0.5,11.2-4.5,12.7-6.2c2.3-2.5,5.6-5.9,4.7-9.7C381.7,332.7,375.7,330.5,371.6,330.5z"
        />
      </g>
      <circle
        class="sushi-st9"
        cx="291.5"
        cy="372.4"
        r="14.8"
      />
    </g>
    <!--	шапочка-->
    <g class="head animate__bounce">
      <path
        class="sushi-st2"
        d="M190.7,87.3c-5.7,1.6-33.5,7.2-66,25.4c-42.6,23.8-65.4,53.1-90.3,84.2c0,0-13.9,17.6-31,59.4
				c-1.9,4.8-5,12.5-2.5,21.3c3.3,11.8,14.7,18.1,19.8,20.8c19.5,10.4,39.8,10.2,92.4,1.8c173-27.7,279.8-30.3,358.8-9.1
				c21.1,5.6,20.4,5.8,44.4,10.9c31.7,6.7,25.5,2.9,40.6,7.1c3.3,0.9,9.3,2.7,17.3,2c4.7-0.4,12.7-1.1,18.8-7.1
				c6.2-6.1,6.9-14.2,7.1-17.3c0.7-9.8-3.3-17.4-9.6-27.7c-15.3-25.1-23.6-34.8-23.6-34.8c-21.6-25.3-35.5-41.5-59.4-60.6
				c-8-6.4-28.9-22.6-59.1-38.3c-34.8-18.1-64.3-26.5-76.4-29.7C334.9,86,239.3,73.5,190.7,87.3z"
      />
      <path
        class="sushi-st3"
        d="M582.5,274.8c-2.4,2.5-7.6,5.7-13.3,7.2c-9.9,2.7-18.5-0.2-25.9-2.7c-59.2-19.8-44.5-15.6-103.9-33.5
				c-163.6-49.2-267.2-17.1-267.2-17.1c-16.3,5-99.3,32.6-117.2,41.5c-0.1,0.1-7.2,2.7-21.3,8c-6.5,2.4-9.9,3.7-14.1,2.7
				c-7.1-1.7-10.6-7.8-11.8-9.9c-3.3-5.7-3.7-12.1-2.9-18.3c16.4-39.2,29.5-55.9,29.5-55.9c24.9-31.1,47.7-60.4,90.3-84.2
				c32.5-18.2,60.2-23.8,66-25.4c48.7-13.8,144.2-1.3,181.2,8.4c12.1,3.2,41.6,11.5,76.4,29.7c30.2,15.8,51.1,31.9,59.1,38.3
				c23.9,19.1,37.8,35.4,59.4,60.6c0,0,6.7,7.9,19.3,27.9C587.6,262.4,586.6,270.5,582.5,274.8z"
      />
      <g class="sushi-st4">
        <g>
          <path
            class="sushi-st5"
            d="M185.1,88.7c-4,2.4-9.5,5.5-16,9c-11.6,6.4-17.4,9.5-28.9,14.8C116.1,123.7,83.8,152.8,63,189
						c-1.4,2.4-18.4,32.6-20.9,67c-0.8,10.8,0,20,4.9,28.5c4.9,8.5,12.3,13.3,18.6,17.5c2,1.3,4,2.5,5.9,3.6
						c-6.6,0.5-12.4,0.8-17.7,0.7c-3.9-2.4-6.5-4.7-7.6-5.8c-2.2-2-6.4-5.9-9.9-12.2c-0.9-1.6-4.9-9-5.7-20.6
						c-1.9-25.6,11-53.7,11-53.7c3.5-7.6,15.4-32.3,46.1-67c9.4-10.7,17.6-19.9,25.6-27.8c3.6-2.3,7.4-4.5,11.3-6.8
						C151,97.9,174.2,91.5,185.1,88.7z"
          />
          <path
            class="sushi-st5"
            d="M109.4,212.2c-0.4,6.7-1.5,29.8,12.6,52.1c8.8,14,20.9,23.3,32.9,29.4c-6.3,0.9-12.6,1.8-19.1,2.8
						c-0.5-0.3-1.1-0.7-1.7-1c-3.8-2.1-4.9-1.6-9.5-3.8c-8.9-4.2-14.2-10.3-17.1-13.7c-13.7-15.8-16.1-40.5-16.4-43
						c-2.7-30.9,11.4-54.5,17.9-65.5c14.7-24.7,34.6-39,49.5-48.3c33.7-21.1,70.5-33.2,101.4-39.9c0,0,0,0,0,0
						c8.8,0.2,17.6,0.7,26.4,1.4c-28,6.4-45.3,10.5-63.9,17.2c-30.4,11-57.5,20.8-81.1,46.4C131.6,157,111.5,178.9,109.4,212.2z"
          />
          <path
            class="sushi-st5"
            d="M375.6,96.7c-8.1,0.6-15.6,1.2-22.6,1.8c-28.7,2.5-49.7,5.2-74.6,15.2c-18.8,7.6-37.5,15.4-50.6,35
						c-14,20.9-13.6,42.9-13.3,50.2c1.3,38.6,27.2,63.9,35,71.6c4,3.9,8.2,7.5,12.5,10.6c-7.7,0.7-15.7,1.4-23.8,2.2c0,0,0,0,0,0
						c-4.3-1.6-4.9-1.8-7-2.9c-24.2-13.2-35.6-37.9-41.5-56.3c0,0-8.3-31.6,5.3-65.1c15.2-37.3,73.6-61.8,154.8-68.1c0,0,0,0,0,0
						c8.6,1.6,16,3.2,22,4.8C372.9,95.9,374.2,96.3,375.6,96.7z"
          />
          <path
            class="sushi-st5"
            d="M476,141.4c-30.6-9-41.2-10-46.2-9.8c-10.3,0.4-32.7,1.2-51.8,17.9c-15,13.1-19.9,29.2-22.1,36.5
						c-1.2,4.2-7,24.9,0.4,49.5c5.9,19.7,17.6,33.5,27.8,42.5c-9-0.5-18.3-0.8-28-0.9c-4.4-3.3-9.5-7.6-14.3-13
						c-13-15.2-16.4-32-18.3-41.9c-2.7-14.2-9.7-51.4,11.4-76.9c18-21.7,46.8-23.5,67.4-24.7c14.2-0.9,27.7,0,40.5,2.1c0,0,0,0,0,0
						c1.7,0.9,3.5,1.8,5.2,2.7C458.6,130.8,467.9,136.3,476,141.4z"
          />
          <path
            class="sushi-st5"
            d="M557.1,213.2c-5.9-3.2-10.1-4.8-15.2-4.9c-2.1,0-6.2,0-10.9,1.8c-14.3,5.5-19.4,22.2-21.3,28.7
						c-0.8,2.7-4.6,15.8-0.8,32.5c3.7,16.1,12.4,27,18.1,32.9c-3.1-0.6-6.7-1.3-11-2.2c-7.3-1.5-12.3-2.6-16.3-3.6
						c-3.7-2.7-6.9-4.9-9.5-6.6c-1.3-0.8-3-2-5.2-3.8c-9.5-8.2-12.7-18.6-14.2-23.3c-6.1-19.7-4.3-53.6,16.2-71.8
						c2.5-2.2,7.6-6.6,15.5-8.9c11.6-3.3,21.6,0.1,27.4,2c1.2,0.4,2.4,0.9,3.6,1.3C541.5,195.3,548.9,203.6,557.1,213.2z"
          />
        </g>
      </g>
      <path
        class="sushi-st6"
        d="M116.7,178c-0.2-3.6,3.4-6.6,4.6-7.6c1.7-1.4,49.3-39.2,142.1-38.8c33.3,0.1,61.5,5.2,72.8,7.4
				c1.6,0.3,6.3,1,6.3,2.6c0,1.6-20.5,1.9-44,4.6c-30,3.6-51.7,7.5-77.6,12.2c-20.1,3.6-31.2,6.1-40,8.3c-4,1-7.8,2.3-11.6,3.8
				c-6.1,2.4-17.4,6.7-28.7,9.3c-7.9,1.8-21.3,2.7-23,0.6C116.8,179.5,116.7,178.4,116.7,178z"
      />
    </g>
  </svg>
</template>

<style lang="scss">
.sushi-st0 {
  opacity: 0.3;
  fill: #030405;
}
.sushi-st1 {
  fill: #fcfcfc;
}
.sushi-st2 {
  fill: #fb7d01;
}
.sushi-st3 {
  fill: #f89c01;
}
.sushi-st4 {
  opacity: 0.31;
}
.sushi-st5 {
  fill: #ffe66a;
}
.sushi-st6 {
  opacity: 0.3;
  fill: #ffffff;
}
.sushi-st7 {
  fill: #030405;
}
.sushi-st8 {
  fill: #fffeff;
}
.sushi-st9 {
  fill: #d95f96;
}
@keyframes bounce {
  from,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(0, -30px, 0) scaleY(1.1);
    transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(0, -15px, 0) scaleY(1.05);
    transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
    -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    -webkit-transform: translate3d(0, 0, 0) scaleY(0.95);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0) scaleY(1.02);
    transform: translate3d(0, -4px, 0) scaleY(1.02);
  }
}

.animate__bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  animation: 3s bounce ease-out 1s both;
  animation-iteration-count: infinite;
}
.eye {
  -webkit-animation-name: eye;
  animation-name: eye;
  -webkit-transform-origin: center bottom;
  transform-origin: center center;
  animation: 2s eye ease-out 1s both;
  animation-iteration-count: infinite;
  transform-box: fill-box;
}
@keyframes eye {
  from,
  to {
    transform: translate(7px, 7px) scale(1);
  }
  50% {
    transform: translate(0, 0) scale(1.3);
  }
}

.eye1 {
  -webkit-transform-origin: center bottom;
  transform-origin: center center;
  animation: 2s eye1 ease-out 1s both;
  animation-iteration-count: infinite;
  transform-box: fill-box;
}
@keyframes eye1 {
  from,
  to {
    transform: translate(7px, 7px) scale(1);
  }
  50% {
    transform: translate(0, 0) scale(1.3);
  }
}
</style>
