import type { ExternalEvent } from '~api/consts'
import MakeRequest from '~api/request'

import type { QR } from '~types/common'

export default {
  getTimeCorrection(): Promise<number> {
    const ms = new Date().getTime()
    let startedReceivingData: number | null = null

    const request = new XMLHttpRequest()
    request.open('GET', `/api/json/timediff?user_t_now=${ms}`, true)

    const promise = new Promise<number>((resolve, reject) => {
      request.onreadystatechange = function () {
        if (request.readyState === 2) {
          startedReceivingData = new Date().getTime() - ms
        }
      }
      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          const data = JSON.parse(request.responseText)

          if (data.error.code !== 0) {
            const message = `User time diff not loaded because of error: ${data.error.message}`
            console.error(message)

            return reject(data.error)
          }

          let receivedDiff: number = data.answer as number
          receivedDiff -= startedReceivingData ?? 0 // remove latency

          return resolve(receivedDiff)
        }

        // We reached our target server, but it returned an error
        console.error('User time diff not loaded because of server error.')
        reject(0)
      }

      request.onerror = function () {
        console.error('User time diff not loaded because of network error.')
        reject(0)
      }
    })

    request.send()

    return promise
  },

  async externalEvent(externalEvent: ExternalEvent): Promise<boolean> {
    const result = await MakeRequest<string>(
      '/ajax/externalEvent',
      new Map([['externalEventType', externalEvent]])
    )

    return result === 'ok'
  },

  async generateQR(link: string, pixelsPerModule = 20): Promise<QR> {
    return await MakeRequest<QR>(
      '/ajax/qrGenerator',
      new Map<string, number | string>([
        ['info', encodeURIComponent(link)],
        ['pixelsPerModule', pixelsPerModule]
      ])
    )
  }
}
