<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17.28C0 9.13091 0 5.05964 2.53309 2.53309C5.06618 0.00654554 9.13091 0 17.28 0H18.72C26.8691 0 30.9404 0 33.4669 2.53309C35.9935 5.06618 36 9.13091 36 17.28V18.72C36 26.8691 36 30.9404 33.4669 33.4669C30.9338 35.9935 26.8691 36 18.72 36H17.28C9.13091 36 5.05964 36 2.53309 33.4669C0.00654554 30.9338 0 26.8691 0 18.72V17.28Z"
        fill="#2F2F2F"
      />
      <path
        d="M7.09091 8.18182H30V27.8182H7.09091V8.18182Z"
        fill="#ACACAC"
      />
      <path
        d="M28.8052 3.81818H7.19481C5.33293 3.81818 3.81818 5.33293 3.81818 7.19481V28.8052C3.81818 30.6671 5.33293 32.1818 7.19481 32.1818H28.8052C30.6671 32.1818 32.1818 30.6671 32.1818 28.8052V7.19481C32.1818 5.33293 30.6671 3.81818 28.8052 3.81818ZM26.1079 16.1922C25.9547 16.2063 25.7993 16.2158 25.642 16.2158C23.8706 16.2158 22.314 15.3048 21.4084 13.9278C21.4084 17.5401 21.4084 21.6501 21.4084 21.719C21.4084 24.8991 18.83 27.4775 15.6499 27.4775C12.4698 27.4775 9.89138 24.8991 9.89138 21.719C9.89138 18.5389 12.4698 15.9605 15.6499 15.9605C15.7701 15.9605 15.8876 15.9713 16.0058 15.9788V18.8165C15.8876 18.8023 15.7714 18.7807 15.6499 18.7807C14.0264 18.7807 12.7109 20.0962 12.7109 21.7197C12.7109 23.3432 14.0264 24.6587 15.6499 24.6587C17.2734 24.6587 18.7071 23.3796 18.7071 21.7562C18.7071 21.692 18.7354 8.52384 18.7354 8.52384H21.4475C21.7028 10.9489 23.6606 12.8628 26.1079 13.0384V16.1922Z"
        fill="#2F2F2F"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-if="enableBorder"
        d="M1 18.28C1 10.1309 1 6.05964 3.53309 3.53309C6.06618 1.00655 10.1309 1 18.28 1H19.72C27.8691 1 31.9404 1 34.4669 3.53309C36.9935 6.06618 37 10.1309 37 18.28V19.72C37 27.8691 37 31.9404 34.4669 34.4669C31.9338 36.9935 27.8691 37 19.72 37H18.28C10.1309 37 6.05964 37 3.53309 34.4669C1.00655 31.9338 1 27.8691 1 19.72V18.28Z"
        stroke="currentColor"
      />
      <path
        d="M27.2166 17.6683C27.0633 17.6825 26.9079 17.6919 26.7506 17.6919C24.9792 17.6919 23.4226 16.7809 22.517 15.4039C22.517 19.0163 22.517 23.1263 22.517 23.1952C22.517 26.3753 19.9386 28.9537 16.7585 28.9537C13.5784 28.9537 11 26.3753 11 23.1952C11 20.0151 13.5784 17.4367 16.7585 17.4367C16.8787 17.4367 16.9962 17.4475 17.1144 17.4549V20.2926C16.9962 20.2784 16.8801 20.2568 16.7585 20.2568C15.135 20.2568 13.8195 21.5724 13.8195 23.1958C13.8195 24.8193 15.135 26.1349 16.7585 26.1349C18.382 26.1349 19.8157 24.8558 19.8157 23.2323C19.8157 23.1682 19.8441 10 19.8441 10H22.5562C22.8114 12.4251 24.7692 14.339 27.2166 14.5145V17.6683Z"
        fill="currentColor"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import type { SocialNetworkIcon } from '~types/props'

defineProps<SocialNetworkIcon>()
const appConfig = useAppConfig()
</script>
