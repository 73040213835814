<template>
  <template v-if="appConfig.SocialNetworksPreRun.length > 0">
    <div
      v-if="!stringIsNullOrWhitespace(text)"
      class="v-social-text-about v-d-flex v-mb-xs v-mr-xs"
      v-html="text"
    />
    <div class="v-social-networks">
      <div
        v-for="socialNetwork in appConfig.SocialNetworksPreRun"
        :key="socialNetwork.SocialNetworkName"
        class="v-social-network-divider"
      >
        <arora-nuxt-link
          class-name="v-social-network-link"
          external-link
          open-in-new-tab
          :href="socialNetwork.Link"
          :label="socialNetwork.SocialNetworkName"
        >
          <icon-social-vk
            v-if="socialNetwork.SocialNetworkName === 'Vk'"
            :enable-border="enableBorder"
          />
          <icon-social-facebook
            v-if="socialNetwork.SocialNetworkName === 'Facebook'"
            :enable-border="enableBorder"
          />
          <icon-social-instagram
            v-if="socialNetwork.SocialNetworkName === 'Instagram'"
            :enable-border="enableBorder"
          />
          <icon-social-mm
            v-if="socialNetwork.SocialNetworkName === 'MailRu'"
            :enable-border="enableBorder"
          />
          <icon-social-ok
            v-if="socialNetwork.SocialNetworkName === 'Ok'"
            :enable-border="enableBorder"
          />
          <icon-social-twitter
            v-if="socialNetwork.SocialNetworkName === 'Twitter'"
            :enable-border="enableBorder"
          />
          <icon-social-telegram
            v-if="socialNetwork.SocialNetworkName === 'Telegram'"
            :enable-border="enableBorder"
          />
          <icon-social-tiktok
            v-if="socialNetwork.SocialNetworkName === 'TikTok'"
            :enable-border="enableBorder"
          />
          <icon-social-youtube
            v-if="socialNetwork.SocialNetworkName === 'YouTube'"
            :enable-border="enableBorder"
          />
          <icon-social-rutube
            v-if="socialNetwork.SocialNetworkName === 'Rutube'"
            :enable-border="enableBorder"
          />
          <icon-social-whatsapp
            v-if="socialNetwork.SocialNetworkName === 'WhatsApp'"
            :enable-border="enableBorder"
          />
          <icon-social-zen
            v-if="socialNetwork.SocialNetworkName === 'YandexZen'"
            :enable-border="enableBorder"
          />
        </arora-nuxt-link>
      </div>
    </div>
  </template>
</template>

<script setup lang="ts">
import type { SocialNetworkIcon } from '~types/props'

import { useCommon } from '@arora/common'

withDefaults(
  defineProps<
    SocialNetworkIcon & {
      text?: string | undefined
    }
  >(),
  {
    text: undefined,
    enableBorder: true
  }
)
const { stringIsNullOrWhitespace } = useCommon()
const appConfig = useAppConfig()
</script>

<style lang="scss">
.v-social-networks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px 16px;
}

.v-social-network-divider {
  max-width: 36px;
}
</style>
