<template>
  <header id="Wold">
    <header-parts-additional-logos-block />
    <div class="v-header v-container">
      <div class="v-row v-align-items-center v-py-sm">
        <div
          :class="[
            appConfig.VueSettingsPreRun.AverageTimeDisplayInHeader ||
            headerEasterEggAvailable ||
            (appConfig.VueSettingsPreRun.HeaderEnableSocial && appConfig.SocialNetworksPreRun.length > 0)
              ? 'v-col-12 v-col-sm-4 v-col-md-4 v-col-lg-3'
              : 'v-col-12 v-col-sm-4 v-col-md-8 v-col-lg-9'
          ]"
        >
          <address-selector
            v-if="
              appConfig.RestaurantSettingsPreRun.OneStageCart &&
              (appConfig.RestaurantSettingsPreRun.AddressSelectOptions
                .ShowAddressSelectBeforeProductAdded ||
                appConfig.RestaurantSettingsPreRun.AddressSelectOptions.ShowAddressSelectAtEntrance)
            "
            :external-links-contacts="externalLinksContacts"
          />
          <address-city-selector
            v-else
            :external-links-contacts="externalLinksContacts"
          />
        </div>
        <div
          v-if="
            appConfig.VueSettingsPreRun.AverageTimeDisplayInHeader ||
            headerEasterEggAvailable ||
            (appConfig.VueSettingsPreRun.HeaderEnableSocial && appConfig.SocialNetworksPreRun.length > 0)
          "
          class="v-col-12 v-col-sm-4 v-col-md-5 v-col-lg-6"
        >
          <div class="v-row">
            <div
              class="v-col-12 v-col-xxl-7 v-d-flex v-flex-row v-align-items-center"
              :class="{
                'v-mb-xs':
                  appConfig.VueSettingsPreRun.HeaderEnableSocial &&
                  appConfig.SocialNetworksPreRun.length > 0
              }"
            >
              <header-parts-average-time />

              <div
                v-if="headerEasterEggAvailable"
                class="v-header-wold-easter-egg"
              >
                <header-parts-easter-egg />
              </div>
            </div>
            <div
              v-if="
                appConfig.VueSettingsPreRun.HeaderEnableSocial &&
                appConfig.SocialNetworksPreRun.length > 0
              "
              class="v-col-12 v-col-xxl-5 v-d-flex v-flex-row v-align-items-center v-header-wold-social"
              :class="[isDesktopVersion ? 'v-flex-end' : 'v-flex-start']"
            >
              <common-social-networks
                :enable-border="false"
                :text="translate('headerPage.titleSocialNetworksLinks')"
              />
            </div>
          </div>
        </div>
        <div class="v-col-12 v-col-sm-4 v-col-md-3 v-col-lg-3 v-d-flex v-flex-row v-flex-end">
          <header-parts-qr-code-button
            v-if="appConfig.VueSettingsPreRun.HeaderEnableAppQRCode && isDesktopVersion"
          />
          <account-login-button-page v-else />
        </div>
      </div>
    </div>
    <div class="v-header v-container">
      <div class="v-row v-align-items-center v-py-sm">
        <div class="v-col-12 v-col-sm-5 v-col-md-3 v-col-lg-2 v-header-logo">
          <arora-nuxt-link
            :href="appConfig.VueSettingsPreRun.Links.MainLink"
            :label="translate('headerPage.homePage')"
          >
            <arora-nuxt-image
              preload
              :alt="translate('headerPage.homePage')"
              :image="appConfig.VueSettingsPreRun.HeaderLogo"
            />
          </arora-nuxt-link>
        </div>
        <div
          :class="[
            appConfig.VueSettingsPreRun.HeaderEnableAppQRCode && isDesktopVersion
              ? 'v-col-12 v-col-sm-7 v-col-md-7 v-col-lg-8'
              : 'v-col-12 v-col-sm-7 v-col-md-9 v-col-lg-10'
          ]"
        >
          <arora-swiper-slider
            v-if="appConfig.VueSettingsPreRun.HeaderEnableAddresses && externalLinksContacts.length > 0"
            class-name="v-header-address-slider"
            enable-navigation
            show-part-of-next-slide
            :items="externalLinksContacts"
            :max-items="appConfig.VueSettingsPreRun.HeaderAddressesInRow"
            :min-items="isDesktopVersion ? 3 : 1"
            :space-between="32"
            pagination-type="none"
          >
            <template #item="contact: ExternalLink">
              <div class="v-d-flex v-h-100 v-address-mark-link">
                <icon-address-mark class="v-address-mark v-mr-xs" />
                <span
                  class="v-cursor-default v-address-mark-link--title"
                  v-html="sanitize(contact.Name)"
                />
                <address
                  v-if="!stringIsNullOrWhitespace(contact.Url)"
                  class="v-d-flex v-flex-column v-address-mark-link--phone"
                >
                  <arora-nuxt-link
                    v-for="(phone, index) in contact.Url.split(',')"
                    :key="`header-wold-phone-${index}`"
                    class-name="v-mb-xxs v-pointer v-font-weight-600"
                    external-link
                    :href="`tel:+${phoneBeautified(sanitize(phone)).replace(/\D+/g, '')}`"
                    :label="phoneBeautified(sanitize(phone), 'header')"
                  />
                </address>
                <span
                  v-if="!stringIsNullOrWhitespace(contact.Additional)"
                  class="v-address-mark-link--additional"
                  v-html="sanitize(contact.Additional)"
                />
              </div>
            </template>
          </arora-swiper-slider>
        </div>

        <div
          v-if="appConfig.VueSettingsPreRun.HeaderEnableAppQRCode && isDesktopVersion"
          class="v-col-12 v-col-md-3 v-col-lg-2 v-d-flex v-flex-row v-flex-end"
        >
          <account-login-button-page />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import type { ExternalLink } from '~types/settingsVariants'

import { useCommon, useWindowSize } from '@arora/common'

defineProps<{
  headerEasterEggAvailable: boolean
  externalLinksContacts: ExternalLink[]
}>()

const appConfig = useAppConfig()
const { stringIsNullOrWhitespace } = useCommon()
const { translate, sanitize } = useI18nSanitized()
const { phoneBeautified } = usePhoneNumber()
const { isDesktopVersion } = useWindowSize()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

#Wold {
  .v-address-mark-link {
    display: grid;
    grid-template-columns: 16px 1fr;
    grid-template-rows: auto fit-content(50%) auto;
    gap: 0.25rem 0.5rem;
    grid-template-areas:
      'mark title'
      '. phone'
      '. additional';

    .v-address-mark {
      grid-area: mark;
    }
    &--title {
      @include mixins.trans;
      grid-area: title;

      &:hover {
        color: variables.$PrimaryBackgroundColor;
      }
    }
    &--phone {
      height: 100%;
      grid-area: phone;
    }
    &--additional {
      grid-area: additional;
      color: variables.$HeaderColorOpaq50;
      font-size: 0.85rem;
      font-weight: 400;
      cursor: default;
      display: flex;
      flex-direction: column;
    }

    a,
    a:link,
    a:active,
    a:visited {
      color: variables.$HeaderColor;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .v-header-wold-easter-egg {
    margin-left: 44px;

    @include mixins.md {
      margin: 0;
    }
  }

  .v-address-mark {
    width: 1.15rem;
    height: 1.15rem;
    flex: 0 0 1.15rem;

    path {
      fill: variables.$SecondaryBackgroundColor;
    }
  }

  .v-header-address-slider {
    @include mixins.swiper-button-header;
  }

  .swiper-slide {
    width: fit-content;
    height: auto;
  }

  .v-header-wold-social {
    .v-social-text-about {
      margin-bottom: 0;
    }
    .v-social-network-icon {
      color: variables.$SecondaryBackgroundColor;
      box-shadow: variables.$InputShadow;
      width: 24px;
      height: 24px;

      @include mixins.trans;

      &:hover {
        background: variables.$SecondaryBackgroundColor;
        color: variables.$SecondaryColor;
      }
    }
  }
}
</style>
