<template>
  <svg
    class="v-svg-arrow-prev"
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1L4.49193 5.50202C4.20436 5.87107 4.21111 6.39019 4.50819 6.75163L8 11"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1.5"
    />
  </svg>
</template>

<style lang="scss">
.v-svg-arrow-prev {
  fill: none;
}
</style>
