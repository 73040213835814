import accountAPI from '~api/account'
import { AuthVariant, ErrorCode } from '~api/consts'

import type {
  AccountStore,
  BirthdayData,
  ChangePasswordPayload,
  CurrentRank,
  LoginPayload,
  MapsSavedAddress,
  OrderQuizStatusInfo,
  PartialPayState,
  PointsHistoryStat,
  Profile,
  RanksStat,
  ReferralCodeStat,
  RegisterPayload,
  ResetPayload,
  TwoStepsSavedAddress
} from '~types/accountStore'
import type { OrderInfo } from '~types/clientStore'
import type { StoreState } from '~types/common'
import type { ProductInList } from '~types/menuStore'

import type { GUID, ServerError } from '@arora/common'

import { computed, ref } from 'vue'

import { defineStore } from 'pinia'

export const useAccountStore = defineStore('accountStore', (): AccountStore => {
  const BirthdayGifts = ref<StoreState<BirthdayData>>({
    error: null,
    state: null,
    data: null
  })
  const MapsSavedAddresses = ref<StoreState<MapsSavedAddress[]>>({
    state: null,
    data: null,
    error: null
  })
  const OrderHistory = ref<Record<number, OrderInfo[]>>({})
  const PartialPay = ref<StoreState<PartialPayState>>({
    state: null,
    data: null,
    error: null
  })
  const Profile = ref<StoreState<Profile>>({
    state: null,
    data: null,
    error: null
  })
  const TwoStepsSavedAddresses = ref<StoreState<TwoStepsSavedAddress[]>>({
    state: null,
    data: null,
    error: null
  })
  const RanksStat = ref<StoreState<RanksStat>>({
    state: null,
    data: null,
    error: null
  })
  const ReferralCodeInfo = ref<StoreState<ReferralCodeStat>>({
    state: null,
    data: null,
    error: null
  })
  const PointsBalance = ref<number>(-1)
  const PointsHistoryInfo = ref<StoreState<PointsHistoryStat>>({
    state: null,
    data: {
      HistoryLoaded: false,
      PointsTransactions: {}
    },
    error: null
  })
  const CurrentRank = ref<CurrentRank>({
    Sum: 0,
    SumWOAdditional: 0,
    RankLoaded: false
  })
  const OrderQuizStatus = ref<Record<GUID, OrderQuizStatusInfo>>({})

  const isLoggedIn = computed<boolean>(
    () => Profile.value.state === 'success' && Profile.value.data !== null
  )

  const loadOrdersPerPage = 4

  async function loadOrderHistory(page: number): Promise<boolean> {
    if (!OrderHistory.value[page]) {
      try {
        OrderHistory.value[page] = await accountAPI.loadOrderHistory(page, loadOrdersPerPage)
      } catch (error) {
        console.error('store request error', error)

        return false
      }
    }

    return OrderHistory.value[page].length >= loadOrdersPerPage
  }

  async function initSavedAddresses(isOneStageCart: boolean): Promise<void> {
    if (isOneStageCart) {
      if (MapsSavedAddresses.value.state !== 'success' && MapsSavedAddresses.value.state !== 'loading') {
        await loadSavedAddresses(isOneStageCart)
      }
    } else if (
      TwoStepsSavedAddresses.value.state !== 'success' &&
      TwoStepsSavedAddresses.value.state !== 'loading'
    ) {
      await loadSavedAddresses(isOneStageCart)
    }
  }

  async function loadSavedAddresses(isOneStageCart: boolean): Promise<void> {
    if (isOneStageCart) {
      MapsSavedAddresses.value.state = 'loading'

      try {
        const addresses = await accountAPI.getMapsSavedAddresses()
        MapsSavedAddresses.value.state = 'success'
        MapsSavedAddresses.value.data = addresses
      } catch (error) {
        if (error.code === null || error.code !== ErrorCode.NotLoggedIn) {
          console.error('loadSavedAddresses failed', error)
        }

        MapsSavedAddresses.value.state = 'error'
        MapsSavedAddresses.value.error = error
      }
    } else {
      TwoStepsSavedAddresses.value.state = 'loading'

      try {
        const addresses = await accountAPI.getTwoStepsSavedAddresses()
        TwoStepsSavedAddresses.value.state = 'success'
        TwoStepsSavedAddresses.value.data = addresses
      } catch (error) {
        if (error.code === null || error.code !== ErrorCode.NotLoggedIn) {
          console.error('loadSavedAddresses failed', error)
        }

        TwoStepsSavedAddresses.value.state = 'error'
        TwoStepsSavedAddresses.value.error = error
      }
    }
  }

  async function addSavedAddress(
    address: MapsSavedAddress | TwoStepsSavedAddress,
    isOneStageCart: boolean
  ): Promise<TwoStepsSavedAddress[] | number> {
    const { CurrentRestaurantId } = useAppConfig()

    if (isOneStageCart) {
      await accountAPI.addMapsAddress(CurrentRestaurantId, address as MapsSavedAddress)
      await loadSavedAddresses(isOneStageCart)

      return 0
    }

    const id = await accountAPI.addTwoStepsAddress(CurrentRestaurantId, address as TwoStepsSavedAddress)
    await loadSavedAddresses(isOneStageCart)

    return id
  }

  async function changeSavedAddress(
    address: MapsSavedAddress | TwoStepsSavedAddress,
    isOneStageCart: boolean
  ): Promise<number | string> {
    if (isOneStageCart) {
      try {
        const { CurrentRestaurantId } = useAppConfig()
        await accountAPI.changeMapsAddress(CurrentRestaurantId, address as MapsSavedAddress)
        await loadSavedAddresses(isOneStageCart)

        return 0
      } catch (error) {
        MapsSavedAddresses.value.state = 'error'
        MapsSavedAddresses.value.error = error
        throw error
      }
    } else {
      try {
        const { CurrentRestaurantId } = useAppConfig()
        const id = await accountAPI.changeTwoStepsAddress(
          CurrentRestaurantId,
          address as TwoStepsSavedAddress
        )
        await loadSavedAddresses(isOneStageCart)

        return id
      } catch (error) {
        TwoStepsSavedAddresses.value.state = 'error'
        TwoStepsSavedAddresses.value.error = error
        throw error
      }
    }
  }

  async function deleteSavedAddress(id: GUID, isOneStageCart: boolean): Promise<number> {
    const { CurrentRestaurantId } = useAppConfig()

    if (isOneStageCart) {
      try {
        await accountAPI.deleteMapsSavedAddresses({ id: id, restaurant: CurrentRestaurantId })
        await loadSavedAddresses(isOneStageCart)

        return 0
      } catch (error) {
        return error.code
      }
    } else {
      try {
        await accountAPI.deleteTwoStepsSavedAddresses(id)
        await loadSavedAddresses(isOneStageCart)

        return 0
      } catch (error) {
        return error.code
      }
    }
  }

  async function initBirthdayGifts(): Promise<void> {
    if (BirthdayGifts.value.state !== 'success' && BirthdayGifts.value.state !== 'loading') {
      await loadBirthdayGifts()
    }
  }

  async function loadBirthdayGifts(): Promise<void> {
    BirthdayGifts.value.state = 'loading'

    try {
      const gifts = await accountAPI.getBirthdayGifts()
      BirthdayGifts.value.state = 'success'
      BirthdayGifts.value.data = gifts
    } catch (error) {
      switch (error?.code) {
        case ErrorCode.ThirdPartAPI:
          if (error.message) {
            const popupStore = usePopupStore()
            const { translate } = useI18nSanitized()
            const { reload } = useUrl()

            await popupStore.showConfirm({
              type: 'error',
              message: error.message,
              yesText: translate('confirm.logoutTimeoutYes'),
              noText: translate('confirm.logoutTimeoutNo'),
              yesFunction: () => {
                logout()
                reload()
              },
              noFunction: () => {
                reload()
              }
            })
          }
          break
        case ErrorCode.NotLoggedIn:
          //no action needed - user simply didn't log in
          break
        default:
          console.error('loadProfile failed', error)
      }

      BirthdayGifts.value.state = 'error'
      BirthdayGifts.value.error = error
    }
  }

  async function initPartialPay(): Promise<void> {
    if (PartialPay.value.state !== 'success' && PartialPay.value.state !== 'loading') {
      await loadPartialPay()
    }
  }

  async function loadPartialPay(): Promise<void> {
    PartialPay.value.state = 'loading'

    try {
      PartialPay.value.data = await accountAPI.getPartialPay()
      PartialPay.value.state = 'success'
    } catch (error) {
      PartialPay.value.error = error
      PartialPay.value.state = 'error'
    }
  }

  async function initRanks(): Promise<void> {
    if (RanksStat.value.state !== 'success' && RanksStat.value.state !== 'loading') {
      await loadRanks()
    }
  }

  async function loadRanks(): Promise<void> {
    RanksStat.value.state = 'loading'

    try {
      RanksStat.value.data = await accountAPI.loadRanks()
      RanksStat.value.state = 'success'
    } catch (error) {
      RanksStat.value.error = error
      RanksStat.value.state = 'error'
    }
  }

  async function updatePartialPay(value: number): Promise<void> {
    await accountAPI.partialPayValueChange(value.toString())

    const clientStore = useClientStore()

    await clientStore.loadClientState()
  }

  async function initProfile(): Promise<void> {
    if (Profile.value.state !== 'success' && Profile.value.state !== 'loading') {
      await loadProfile()
    }
  }

  async function loadProfile(): Promise<void> {
    Profile.value.state = 'loading'
    try {
      Profile.value.state = 'success'
      Profile.value.data = await accountAPI.getProfile()
    } catch (error) {
      switch (error?.code) {
        case ErrorCode.ThirdPartAPI:
          if (error.message) {
            const popupStore = usePopupStore()
            const { translate } = useI18nSanitized()
            const { reload } = useUrl()

            await popupStore.showConfirm({
              type: 'error',
              message: error.message,
              yesText: translate('confirm.logoutTimeoutYes'),
              noText: translate('confirm.logoutTimeoutNo'),
              yesFunction: () => {
                logout()
                reload()
              },
              noFunction: () => {
                reload()
              }
            })
          }
          break
        case ErrorCode.NotLoggedIn:
          //no action needed - user simply didn't log in
          break
        default:
          console.error('loadProfile failed', error)
      }

      Profile.value.state = 'error'
      Profile.value.error = error
    }
  }

  async function saveProfile(profile: Profile, bday: string | null = null): Promise<ServerError> {
    try {
      Profile.value.data = await accountAPI.saveProfile(profile, bday)

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function login(loginFields: LoginPayload): Promise<ServerError> {
    try {
      await accountAPI.login(loginFields)

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function logout(): Promise<ServerError> {
    try {
      await accountAPI.logout()

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function verifyMainContact(
    someFields: RegisterPayload | ResetPayload,
    authVariant: AuthVariant
  ): Promise<ServerError> {
    try {
      const { CurrentRestaurantId } = useAppConfig()
      const answer = await accountAPI.verifyMainContact(CurrentRestaurantId, authVariant, someFields)

      someFields.codeMaxLength = answer.CodeLength

      if (authVariant === AuthVariant.Telegram) {
        if (answer.TelegramBotRedirectionUri) {
          window.open(answer.TelegramBotRedirectionUri, '_blank')
        }
      }

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function changeMainContact(fields: ResetPayload): Promise<ServerError> {
    try {
      const { CurrentRestaurantId } = useAppConfig()
      await accountAPI.changeMainContact(CurrentRestaurantId, fields)

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function register(registerFields: RegisterPayload): Promise<ServerError> {
    try {
      await accountAPI.register(registerFields)

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function resetPassword(resetFields: ResetPayload): Promise<ServerError> {
    try {
      await accountAPI.resetPassword(resetFields)

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function changePassword(changePasswordFields: ChangePasswordPayload): Promise<ServerError> {
    try {
      await accountAPI.changePassword(changePasswordFields)

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function deleteAccount(deleteReason: string): Promise<ServerError> {
    try {
      await accountAPI.deleteAccount(deleteReason)

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function addOrRemoveFromFavorites(product: ProductInList | null): Promise<boolean> {
    if (!product) return false

    const newValue = !product.IsFavorite
    product.IsFavorite = newValue
    const menuStore = useMenuStore()
    menuStore.refreshFavorite(product.ID, newValue)

    return await accountAPI.addOrRemoveFromFavorites(product.ID, !newValue)
  }

  async function initReferralCodeInfo(): Promise<void> {
    if (ReferralCodeInfo.value.state !== 'success' && ReferralCodeInfo.value.state !== 'loading') {
      await loadReferralCodeInfo()
    }
  }

  async function loadReferralCodeInfo(): Promise<void> {
    ReferralCodeInfo.value.state = 'loading'

    try {
      const codes = await accountAPI.loadReferralCodeInfo()
      ReferralCodeInfo.value.state = 'success'
      ReferralCodeInfo.value.data = codes
    } catch (error) {
      ReferralCodeInfo.value.state = 'error'
      ReferralCodeInfo.value.error = error
    }
  }

  async function saveReferralCodeInfo(code: string): Promise<ServerError> {
    try {
      ReferralCodeInfo.value.data = await accountAPI.saveReferralCodeInfo(code)

      return { code: 0, message: '' }
    } catch (error) {
      return error as ServerError
    }
  }

  async function initPointsHistory(page = 0, count = 5): Promise<void> {
    if (PointsHistoryInfo.value.state !== 'success' && PointsHistoryInfo.value.state !== 'loading') {
      await loadPointsHistory(page, count)
    }
  }

  async function loadPointsHistory(page: number, count = 5): Promise<void> {
    PointsHistoryInfo.value.state = 'loading'

    if (!PointsHistoryInfo.value.data?.PointsTransactions[page]) {
      try {
        const history = await accountAPI.loadPointsHistory(page, count)

        if (history.HistoryLoaded) {
          PointsHistoryInfo.value.state = 'success'
          PointsHistoryInfo.value.data!.HistoryLoaded = history.HistoryLoaded
          PointsHistoryInfo.value.data!.PointsTransactions[page] = history.PointsTransactions
        } else {
          PointsHistoryInfo.value.data!.HistoryLoaded = history.HistoryLoaded
          setTimeout(() => loadPointsHistory(page, count), 5000)
        }
      } catch (error) {
        PointsHistoryInfo.value.state = 'error'
        PointsHistoryInfo.value.error = error
      }
    }
  }

  async function loadCurrentRank(): Promise<void> {
    try {
      CurrentRank.value = await accountAPI.loadCurrentRank()
    } catch (error) {
      switch (error.code) {
        case ErrorCode.NotLoggedIn:
        case ErrorCode.WrongArgument:
          //if user is not logged in or bonus system is absent
          break
        default:
          console.error('store request error', error)
      }
    }
  }

  async function loadBalance(): Promise<void> {
    try {
      const balance = await accountAPI.loadBalance()

      if (balance.BalanceLoaded) {
        PointsBalance.value = balance.Balance
      } else {
        setTimeout(loadBalance, 5000)
      }
    } catch (error) {
      switch (error.code) {
        case ErrorCode.NotLoggedIn:
        case ErrorCode.WrongArgument:
          //if user is not logged in or bonus system is absent
          break
        default:
          console.error('store request error', error)
      }
    }
  }

  async function getQuizStatusFromAsker(orderIds: GUID[]): Promise<void> {
    try {
      const statusInfos = await accountAPI.getQuizStatusFromAsker(orderIds)

      for (const orderInfo of statusInfos) {
        OrderQuizStatus.value[orderInfo.OrderID] = orderInfo
      }
    } catch (error) {
      console.error('store request error', error)
    }
  }

  return {
    PartialPay,
    BirthdayGifts,
    Profile,
    OrderHistory,
    TwoStepsSavedAddresses,
    MapsSavedAddresses,
    PointsBalance,
    PointsHistoryInfo,
    CurrentRank,
    RanksStat,
    ReferralCodeInfo,
    OrderQuizStatus,
    isLoggedIn,
    initSavedAddresses,
    initPointsHistory,
    loadSavedAddresses,
    addSavedAddress,
    changeSavedAddress,
    deleteSavedAddress,
    initBirthdayGifts,
    initPartialPay,
    updatePartialPay,
    initProfile,
    saveProfile,
    loadBirthdayGifts,
    loadOrderHistory,
    loadPartialPay,
    login,
    logout,
    verifyMainContact,
    changeMainContact,
    register,
    resetPassword,
    changePassword,
    deleteAccount,
    addOrRemoveFromFavorites,
    initReferralCodeInfo,
    saveReferralCodeInfo,
    loadBalance,
    loadCurrentRank,
    loadPointsHistory,
    initRanks,
    getQuizStatusFromAsker
  }
})
