<template>
  <svg
    id="Слой_1"
    style="enable-background: new 0 0 600 600"
    viewBox="0 0 600 600"
    x="0px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g class="rotate">
      <path
        class="pizza-st1"
        d="M558.3,450c31.5-69.7,59.7-169.4,27.3-230.8C536.2,125.6,468.7,60,414.9,37.8c0,0-127.2-73-241.3-10.2
				C59.4,90.3-1.2,158.5,0,284.4c1.2,125.9,45.8,249.3,214.2,297.7c60.9,17.5,113.1,18.8,156.4,12C370.6,594,501.7,546.7,558.3,450z
				"
      />
      <path
        class="pizza-st2"
        d="M509.2,457.8c16.6-14.6,48.2-54.3,54-75.8c7.1-26.1,18.1-52.8,18.5-79.8c0.2-16.7,0.5-33.6-3.1-49.9
				c-3.8-17.5-11.7-33.7-19.6-49.7c-9.7-19.7-19.5-39.6-33.3-56.7c-12.5-15.5-27.9-28.3-43.2-41c-28-23.3-56.9-47-91.3-59
				c-23.9-8.4-49.4-10.6-74.7-12.9c-20.2-1.8-40.8-3.5-60.4,1.5c-11.1,2.8-21.5,7.8-31.3,13.7c-9.2,5.5-19.3,8-28.8,12.7
				c-16.5,8-35.6,2.3-53.1,8c-10.5,3.4-18.6,11.9-25.4,20.6c-14.6,18.6-26.4,34.3-43,51.2c-6.9,7.1-14.1,18.9-19.9,26.9
				c-10.8,14.9-13.2,34-15.3,52.3c-1.8,15.4-1.3,33.4-5.6,48.3c-4,14.1-8.4,24.3-6.4,39.5c1.7,13.2,5.8,26,8.2,39.1
				c5.6,30.6,1.8,62.8,11.6,92.4c12,36.5,43.4,63.9,78.3,79.9c34.9,15.9,74,23.7,112,29.2c27.1,3.2,52.2,11.2,87,17.9
				c17.6,3.4,31.5-0.5,40-3.6C364.6,562.3,451.1,537.2,509.2,457.8z"
      />
      <path
        class="pizza-st3"
        d="M497.6,449.5c27.5-27.9,47.1-62.9,55.1-101.3c2.2-10.6,3.4-20.6,3.3-29.7c0,0,15.4-130-76.2-201.8
				C388.2,45,311,19.1,208.1,64.2C105.1,109.4,25,164.2,44.2,319.5c19.2,155.3,70.5,188.9,105.5,202.6
				c22.2,8.7,125.4,29.1,212.1,25.3c0,0,46.1-14.1,79-43C457.3,489.8,479.6,467.8,497.6,449.5z"
      />

      <g class="pizza-st4">
        <path
          class="pizza-st5"
          d="M496.2,430.3c9.1-31.5,12.8-46.2,27.5-63.2c11.9-13.7,8.3-36.1-3.7-49.6c-12-13.6-30.2-19.9-47.9-23.5
			c-17.8-3.6-36.1-5.1-53.1-11.3c-17-6.3-33.1-18.5-38-36c-2.2-8-1.3-17.8,5.3-22.7c6.3-4.6,15.1-3.1,22.8-1.3
			c14,3.2,28,6.5,42,9.7c10.5,2.4,24.4,3.2,28.8-6.6c1.5-3.4,1.3-7.3,1-10.9c-1.9-24.9-7.7-50.7-24.1-69.6
			c-16.4-18.8-45.8-28.1-67.3-15.3c-9.5,5.7-16.8,15-27,19.2c-10.3,4.2-25.7-1-25.2-12.1c0.4-9.2,11-14,16.6-21.4
			c8.6-11.1,2.4-29.5-10.1-34.7l4.7,1.4c-10.4-8.3-21.1-16.7-33.6-21.1c-12.5-4.4-27.5-4.2-38,4c-6.5,5-11.2,12.8-19,15.3
			c-7,2.2-14.7-0.7-21.9,0.8c-17.3,3.4-20.9,26.2-31.6,40.3c-13.6,18-39.2,21-61.8,20.7c-22.6-0.3-47-1.8-65.6,11
			c-17.9,12.4-25.6,36.4-22.1,57.9c3.5,21.5,16.7,40.5,32.9,55c6.1,5.4,13,10.8,15.2,18.7c4.4,15.9-12.2,28.8-22.3,41.8
			c-15.1,19.4-16.9,48.2-4.2,69.3c12.7,21.1,38.9,33.1,63.2,28.8c6.9-1.2,13.7-3.6,20.7-3.8c7-0.2,14.7,2.4,18,8.6
			c3.4,6.5,0.9,14.3,0.1,21.6c-2.7,23.6,13.8,46,34.4,57.6c20.7,11.6,44.9,14.7,68.4,17.5c14.3,1.7,30.9,2.7,41-7.6
			c8.9-9,9-23.7,4.7-35.6c0,0-1.5-11.9,12.4-23.8C353.3,447.3,461.1,489.3,496.2,430.3z"
        />
      </g>
      <g class="pizza-st6">
        <path
          class="pizza-st5"
          d="M504.1,346.8c-0.1,1.2-0.3,2.2-0.7,2.6c-17.8,20.6-23,38.6-31.6,68.5l-0.3,1
			c-15.1,20.8-52.5,18.3-92.8,13.6c-26.1-3.1-43.4-5.1-56.8,6.3c-17.8,15.2-21.3,32-21.8,40.7c-0.2,3.1,0,5.5,0.2,7l0.4,2.9l1,2.8
			c0.8,2.3,1.2,4.7,1.1,6.7c0,0.4-0.1,0.7-0.1,0.9c-1.9,0.6-6.6,1.1-18.2-0.3c-20.7-2.5-42.2-5.1-58.5-14.3
			c-12.7-7.2-21.6-19.8-21-30c0-0.3,0-0.7,0.1-1c0.1-1.2,0.3-2.4,0.5-3.6c0.5-2.8,1-6.2,1.2-10c0.5-8.8-1-16.5-4.7-23.5
			c-7.7-14.8-24.1-23.6-42.6-23c-7.3,0.2-13.7,1.7-18.8,3c-2.1,0.5-4,1-5.7,1.2c-13.3,2.3-28.5-4.6-35.5-16.2
			c-3.3-5.5-4.9-12.5-4.5-19.7c0.4-7.2,2.9-14.1,6.9-19.3c1.7-2.1,3.8-4.6,6.1-7.2c9.1-10.2,21.5-24.1,22.5-43.2
			c0.3-5.1-0.3-10.2-1.6-15.2c-4.1-14.8-14.6-24-21.6-30.1l-1.7-1.5c-13.5-12-22.1-25.9-24.3-39.2c-0.4-2.7-0.6-5.4-0.4-8.2
			c0.6-9.9,4.9-18.9,11.3-23.3c10-6.9,26.7-6.6,44.4-6.3c1.9,0,3.7,0.1,5.5,0.1c59.2,0.7,77.5-23.4,83.5-31.4
			c5.1-6.7,8.6-13.8,11.6-20c1.5-3.1,3.7-7.5,5.3-9.9c0.5,0,1,0.1,1.4,0.1c5.2,0.4,13,0.9,21.7-1.7c11.6-3.6,19-11.2,24-16.2
			c1.3-1.3,2.8-2.9,3.6-3.5c1.8-1.4,7.2-1.8,12.6,0.1c7.8,2.7,15.9,9,23.9,15.3c-0.2,0.2-0.4,0.4-0.6,0.5
			c-6.2,5.5-17.8,15.8-18.8,33l0,0.4c-0.6,13.2,5.4,25.3,16.6,33.2c12.9,9.1,30.9,11.1,45.6,5c9.8-4,17.1-10.1,22.9-15.1
			c2.8-2.4,5.5-4.7,7.7-5.9c8.7-5.2,24.2-0.5,33.3,9.9c8.3,9.5,13.7,23.4,16.3,42.3l-37.7-8.7c-8.4-1.9-28-6.5-44.7,5.8
			c-10.4,7.6-16.6,19.5-17.4,33.5c-0.3,6,0.4,12.2,2,18.1c6.7,24,26.6,43.7,54.6,54c14.1,5.2,28,7.5,41.4,9.7
			c5.5,0.9,10.6,1.7,15.6,2.8c16.3,3.3,27.2,8.2,33.2,15C502.6,338.3,504.3,343.1,504.1,346.8L504.1,346.8z M333.5,105.8
			c-2.6-1.1-2.5-2.1-2.5-2.6c0-0.1,0-0.2,0-0.3c0.2,0.2,0.5,0.4,0.7,0.6l8.1-15.6L333.5,105.8z"
        />
      </g>
      <path
        class="pizza-st7"
        d="M438.9,265.4c-2.5,5.3-8.8,7.5-14,5.1c-5.3-2.5-7.5-8.8-5.1-14c2.5-5.3,8.8-7.5,14-5.1
		C439.2,253.8,441.4,260.1,438.9,265.4z"
      />
      <path
        class="pizza-st8"
        d="M27.8,263.5c-1.2,2.5-4.1,3.5-6.6,2.4c-2.5-1.2-3.5-4.1-2.4-6.6c1.2-2.5,4.1-3.5,6.6-2.4
		C27.9,258,28.9,261,27.8,263.5z"
      />

      <path
        class="pizza-st8"
        d="M255.1,566.6c-1.2,2.5-4.1,3.5-6.6,2.4c-2.5-1.2-3.5-4.1-2.4-6.6c1.2-2.5,4.1-3.5,6.6-2.4
		C255.2,561.1,256.3,564.1,255.1,566.6z"
      />
      <path
        class="pizza-st8"
        d="M211.2,42.2c-0.7,1.6-2.6,2.2-4.2,1.5c-1.6-0.7-2.2-2.6-1.5-4.2c0.7-1.6,2.6-2.2,4.2-1.5
		C211.3,38.7,212,40.6,211.2,42.2z"
      />
      <path
        class="pizza-st4"
        d="M74.1,273.6c3.1-1.1,6.6,0.5,7.7,3.6c1.1,3.1-0.5,6.6-3.6,7.7c-3.1,1.1-6.6-0.5-7.7-3.6
		C69.4,278.1,71,274.7,74.1,273.6z"
      />
      <g class="pizza-st4">
        <path
          class="pizza-st5"
          d="M145.6,542.7c51.2,27.5,109.1,42.3,167.2,42.6c-14.1-13.8-32.7-22.3-51.9-26.9
			c-38.6-9.4-80.2-4.5-117.7-17.8L145.6,542.7z"
        />
      </g>
      <path
        class="pizza-st7"
        d="M198.1,50.3c0,0,57-32.9,106-28.3s102.9,13.5,126.2,36.5c0,0-31.5-38-124.9-48
	C212.1,0.5,198.1,50.3,198.1,50.3z"
      />
      <path
        class="pizza-st9"
        d="M219.7,306.8c2.7,0,5.2-2.3,5.1-5.1c-0.1-2.8-2.2-5.1-5.1-5.1c-2.7,0-5.2,2.3-5.1,5.1
					C214.8,304.5,216.9,306.8,219.7,306.8L219.7,306.8z"
      />
      <!--	моццарелка-->
      <path
        class="pizza-st5"
        d="M243.5,213.5c-13.7,29.1-48.4,41.6-77.5,27.9c-29.1-13.7-41.6-48.4-27.9-77.5c13.7-29.1,47.1-36.7,76.2-23
	C243.4,154.6,257.2,184.4,243.5,213.5z"
      />
      <!--	моццарелка-->
      <!--	моццарелка-->
      <path
        class="pizza-st5"
        d="M473.5,191.2c1.5,32.1-23.3,59.4-55.4,61c-32.1,1.5-59.4-23.3-61-55.4c-1.5-32.1,24.4-54.5,56.5-56.1
		C445.8,139.2,471.9,159.1,473.5,191.2z"
      />
      <!--	моццарелка-->
      <!--	моццарелка-->
      <path
        class="pizza-st5"
        d="M265.9,369c1.5,32.1-23.3,59.4-55.4,61c-32.1,1.5-59.4-23.3-61-55.4c-1.5-32.1,24.4-54.5,56.5-56.1
		C238.2,317,264.3,336.8,265.9,369z"
      />
      <!--	моццарелка-->

      <!--	моццарелка-->
      <path
        class="pizza-st5"
        d="M443.3,404.1c1.5,32.1-23.3,59.4-55.4,61c-32.1,1.5-59.4-23.3-61-55.4s24.4-54.5,56.5-56.1
		C415.6,352.1,441.8,372,443.3,404.1z"
      />
      <!--	моццарелка-->

      <!--помидорка-->
      <g class="tomato-1 tomato">
        <path
          class="pizza-st10"
          d="M185.7,307.8c-18.5,20.4-50.2,21.7-70.8,3c-20.6-18.7-22.3-50.4-3.8-70.7s50.2-21.7,70.8-3
				C202.5,255.8,204.2,287.5,185.7,307.8z"
        />
        <g class="pizza-st4">
          <path
            d="M146.1,256.1c-0.8,6.2-1.4,9.5-2.3,14c-1.5,7.6-2.1,14.6-3.6,22.2c-0.7,3.7-1.6,7.5-4.1,10.3c-3.3,3.7-9.1,4.7-13.7,3.1
					c-4.6-1.6-8.3-5.3-10.9-9.5c-6-9.8-6.8-22.7-1.8-33.3c4.9-10.6,10.7-24.3,26.3-26.1c0,0,9.9-2.6,10.3,5.7
					S146.1,256.1,146.1,256.1z"
          />
        </g>
        <g class="pizza-st4">
          <path
            d="M158.8,260.9c-1.8,5.7-2.6,8.7-3.6,13c-1.7,7.1-4.1,13.4-5.8,20.6c-0.8,3.4-1.6,7.1-0.6,10.5c1.4,4.5,6,7.5,10.7,8
					c4.7,0.4,9.3-1.3,13.2-3.9c9.2-6.1,15-16.9,15-28c0-11.1-1.1-25.5-11.7-32.3c0,0-10.7-7.5-13.3,0
					C160.6,254.5,158.8,260.9,158.8,260.9z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M137.9,258.4c1.4-0.3,2-1.9,1.2-3.1c-1-1.5-3-3.2-4.1-4.2c-1.6-1.4-3.6-2.5-5.8-2.7
					c-0.9-0.1-1.7,0-2.5,0.3c-0.8,0.4-1.4,1-1.7,1.8c-0.3,1,0.1,2,0.8,2.8c0.7,0.8,1.5,1.3,2.4,1.8c1.6,1,3.3,1.9,5.1,2.4
					C134.5,258.1,136.4,258.7,137.9,258.4z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M132.7,271.1c1.2-0.8,1.1-2.5-0.1-3.3c-1.5-1-4-1.9-5.4-2.4c-2-0.7-4.3-0.9-6.4-0.3
					c-0.8,0.3-1.6,0.7-2.2,1.3c-0.6,0.6-1,1.5-0.8,2.3c0.1,1,0.9,1.8,1.8,2.3c0.9,0.5,1.9,0.6,2.9,0.8c1.9,0.3,3.8,0.5,5.6,0.3
					C129.4,272,131.4,271.9,132.7,271.1z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M133.8,286.1c0.6-1.1-0.2-2.5-1.4-2.6c-1.6-0.2-3.9,0.1-5.3,0.3c-1.9,0.3-3.8,1-5.2,2.4
					c-0.6,0.5-1,1.2-1.2,1.9c-0.2,0.7-0.1,1.6,0.3,2.2c0.5,0.7,1.5,1.1,2.4,1.1c0.9,0,1.8-0.3,2.6-0.6c1.6-0.6,3.2-1.2,4.7-2.1
					C131.6,288.2,133.1,287.3,133.8,286.1z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M158.6,292.7c-1.4,0.1-2.2,1.6-1.6,2.9c0.8,1.6,2.5,3.6,3.4,4.8c1.4,1.6,3.2,3,5.3,3.5
					c0.8,0.2,1.7,0.3,2.5,0c0.8-0.2,1.6-0.8,1.9-1.6c0.4-0.9,0.1-2-0.4-2.9c-0.5-0.9-1.3-1.5-2.1-2.1c-1.5-1.2-3-2.3-4.7-3.1
					C161.9,293.5,160.1,292.6,158.6,292.7z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M165.6,280.9c-1.3,0.6-1.4,2.3-0.4,3.2c1.3,1.2,3.7,2.4,5,3.1c1.9,1,4.1,1.5,6.2,1.2
					c0.8-0.1,1.7-0.4,2.4-0.9c0.7-0.5,1.2-1.3,1.2-2.2c0-1-0.6-1.9-1.4-2.5c-0.8-0.6-1.8-0.9-2.8-1.2c-1.8-0.5-3.6-1-5.5-1.1
					C169,280.4,167,280.3,165.6,280.9z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M166.7,265.9c-0.8,1-0.2,2.5,1,2.8c1.6,0.4,3.9,0.4,5.3,0.4c1.9,0,3.9-0.5,5.5-1.6c0.6-0.5,1.2-1,1.5-1.7
					c0.3-0.7,0.4-1.6,0-2.2c-0.4-0.8-1.3-1.3-2.2-1.4c-0.9-0.1-1.8,0-2.7,0.2c-1.7,0.3-3.3,0.7-4.9,1.4
					C169.2,264.1,167.5,264.8,166.7,265.9z"
          />
        </g>
      </g>
      <!--помидорка-->

      <!--помидорка-->
      <g class="tomato-2 tomato">
        <path
          class="pizza-st11"
          d="M134.7,228.5c0.6-1.1,2-1.5,3.1-0.9c1.1,0.6,1.5,2,0.9,3.1c-0.6,1.1-2,1.5-3.1,0.9
				C134.5,231,134.1,229.6,134.7,228.5z"
        />
        <ellipse
          class="pizza-st10"
          cx="452.4"
          cy="355.1"
          rx="49.8"
          ry="50.4"
          transform="matrix(0.9989 -4.791907e-02 4.791907e-02 0.9989 -16.4941 22.0856)"
        />
        <g class="pizza-st4">
          <path
            d="M463.4,340.8c-4.9,3.9-7.7,5.8-11.5,8.3c-6.4,4.3-11.8,8.9-18.3,13.2c-3.1,2.1-6.4,4.2-10.2,4.4
					c-5,0.3-9.7-3.1-11.9-7.5c-2.2-4.4-2.1-9.6-1-14.4c2.6-11.2,11.3-20.8,22.2-24.9c11-4,24.7-9.6,37,0.2c0,0,8.9,5.2,3.2,11.3
					C467.5,337.6,463.4,340.8,463.4,340.8z"
          />
        </g>
        <g class="pizza-st4">
          <path
            d="M468.9,353.1c-5.3,2.7-8.1,4.3-11.7,6.6c-6.2,3.8-12.4,6.6-18.6,10.4c-3,1.8-6.2,3.8-7.8,7c-2.2,4.2-1.1,9.6,1.9,13.2
					c3,3.6,7.5,5.7,12.1,6.6c10.8,2.2,22.6-1.4,30.4-9.2c7.8-7.8,17.3-18.9,14.6-31.1c0,0-2.3-12.9-9.4-9.4
					C474.7,349.9,468.9,353.1,468.9,353.1z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M456,336.6c1.1,0.8,2.7,0,3-1.3c0.3-1.7,0.2-4.4,0.1-5.9c-0.1-2.2-0.8-4.3-2.2-6c-0.5-0.7-1.2-1.2-2-1.5
					c-0.8-0.3-1.7-0.3-2.5,0.1c-0.9,0.5-1.3,1.5-1.4,2.5c-0.1,1,0.2,2,0.4,3c0.5,1.8,1,3.7,1.9,5.3C453.8,334,454.7,335.8,456,336.6
					z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M443.3,341.9c1.4,0.3,2.6-1,2.3-2.4c-0.4-1.7-1.5-4.1-2.1-5.5c-0.9-1.9-2.4-3.7-4.3-4.7
					c-0.8-0.4-1.6-0.7-2.5-0.7c-0.9,0-1.7,0.4-2.2,1.1c-0.6,0.8-0.7,1.9-0.3,2.9c0.3,1,0.9,1.8,1.5,2.6c1.1,1.5,2.3,3,3.8,4.2
					C440.3,340.2,441.8,341.6,443.3,341.9z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M433.5,353.3c1.2-0.3,1.6-1.9,0.8-2.8c-1-1.2-2.9-2.7-4-3.5c-1.6-1.2-3.4-2-5.4-2c-0.8,0-1.6,0.1-2.2,0.5
					c-0.7,0.4-1.2,1-1.4,1.8c-0.2,0.9,0.3,1.8,0.9,2.5c0.6,0.6,1.5,1.1,2.3,1.5c1.5,0.7,3.1,1.5,4.8,1.8
					C430.4,353.2,432.2,353.7,433.5,353.3z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M446.4,375.5c-1-0.9-2.7-0.4-3.1,0.9c-0.6,1.7-0.8,4.3-0.9,5.8c-0.2,2.1,0.2,4.4,1.3,6.2
					c0.4,0.7,1,1.4,1.8,1.8c0.7,0.4,1.7,0.5,2.5,0.2c0.9-0.4,1.5-1.3,1.8-2.3c0.2-1,0.1-2,0-3c-0.2-1.9-0.5-3.8-1.1-5.5
					C448.1,378.5,447.5,376.6,446.4,375.5z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M459.6,372.2c-1.3-0.5-2.7,0.6-2.6,2c0.1,1.8,0.9,4.3,1.3,5.7c0.7,2.1,1.8,4,3.6,5.3
					c0.7,0.5,1.5,0.9,2.3,1c0.9,0.1,1.8-0.1,2.4-0.7c0.7-0.7,0.9-1.8,0.8-2.8c-0.2-1-0.6-1.9-1.1-2.8c-0.9-1.7-1.9-3.3-3.1-4.7
					C462.4,374.2,461,372.7,459.6,372.2z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M471,362.3c-1.2,0.2-1.9,1.6-1.2,2.7c0.8,1.4,2.5,3.1,3.4,4c1.4,1.4,3.1,2.5,5,2.7
					c0.8,0.1,1.6,0.1,2.3-0.2c0.7-0.3,1.4-0.8,1.6-1.6c0.3-0.9,0-1.8-0.6-2.6c-0.5-0.7-1.3-1.3-2.1-1.8c-1.4-1-2.9-1.9-4.4-2.5
					C474,362.8,472.3,362.1,471,362.3z"
          />
        </g>
        <g class="pizza-st6">
          <path
            class="pizza-st5"
            d="M478.7,319.4c-1.2,2,1.9,4.3,3.9,5.5c5,3.1,6.9,6.8,9.7,11.8c2.6,4.6,3.5,10.7,3.3,16.2
					c0,0.3-0.1,1.9-0.2,3l-0.3,5.7c-0.2,4.9,2.3,2.7,2.3,2.7c2.7-7.2,2.5-15.3,0.7-22.1c-1.8-6.9-3.8-13-9.1-19.1
					c-1.4-1.7-3.3-3.2-5.2-4.2S479.8,317.5,478.7,319.4z"
          />
        </g>
        <path
          class="pizza-st11"
          d="M474.8,313.2c1.2-0.4,2.5,0.3,2.8,1.5c0.4,1.2-0.3,2.5-1.5,2.8c-1.2,0.4-2.5-0.3-2.8-1.5
				C472.9,314.8,473.6,313.6,474.8,313.2z"
        />
      </g>
      <!--помидорка-->

      <!--помидорка-->
      <g class="tomato-3">
        <path
          class="pizza-st10"
          d="M310.9,353.2c-27.5,1.3-50.8-20.1-52.1-48c-1.3-27.8,19.8-51.4,47.3-52.7c27.5-1.3,50.8,20.1,52.1,48
				C359.6,328.3,338.4,351.9,310.9,353.2z"
        />
        <g class="pizza-st4">
          <path
            d="M319.5,288.7c-4.9,3.9-7.7,5.8-11.5,8.3c-6.4,4.3-11.8,8.9-18.3,13.2c-3.1,2.1-6.4,4.2-10.2,4.4
					c-5,0.3-9.7-3.1-11.9-7.5c-2.2-4.4-2.1-9.6-1-14.4c2.6-11.2,11.3-20.8,22.2-24.9c11-4,24.7-9.6,37,0.2c0,0,8.9,5.2,3.2,11.3
					C323.6,285.4,319.5,288.7,319.5,288.7z"
          />
        </g>
        <g class="pizza-st4">
          <path
            d="M325.1,301c-5.3,2.7-8.1,4.3-11.7,6.6c-6.2,3.8-12.4,6.6-18.6,10.4c-3,1.8-6.2,3.8-7.8,7c-2.2,4.2-1.1,9.6,1.9,13.2
					c3,3.6,7.5,5.7,12.1,6.6c10.8,2.2,22.6-1.4,30.4-9.2c7.8-7.8,17.3-18.9,14.6-31.1c0,0-2.3-12.9-9.4-9.4
					C330.8,297.8,325.1,301,325.1,301z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M312.1,284.5c1.1,0.8,2.7,0,3-1.3c0.3-1.7,0.2-4.4,0.1-5.9c-0.1-2.2-0.8-4.3-2.2-6c-0.5-0.7-1.2-1.2-2-1.5
					c-0.8-0.3-1.7-0.3-2.5,0.1c-0.9,0.5-1.3,1.5-1.4,2.5c-0.1,1,0.2,2,0.4,3c0.5,1.8,1,3.7,1.9,5.3
					C309.9,281.8,310.8,283.6,312.1,284.5z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M299.4,289.7c1.4,0.3,2.6-1,2.3-2.4c-0.4-1.7-1.5-4.1-2.1-5.5c-0.9-1.9-2.4-3.7-4.3-4.7
					c-0.8-0.4-1.6-0.7-2.5-0.7c-0.9,0-1.7,0.4-2.2,1.1c-0.6,0.8-0.7,1.9-0.3,2.9c0.3,1,0.9,1.8,1.5,2.6c1.1,1.5,2.3,3,3.8,4.2
					C296.4,288.1,298,289.4,299.4,289.7z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M289.6,301.1c1.2-0.3,1.6-1.9,0.8-2.8c-1-1.2-2.9-2.7-4-3.5c-1.6-1.2-3.4-2-5.4-2c-0.8,0-1.6,0.1-2.2,0.5
					c-0.7,0.4-1.2,1-1.4,1.8c-0.2,0.9,0.3,1.8,0.9,2.5c0.6,0.6,1.5,1.1,2.3,1.5c1.5,0.7,3.1,1.5,4.8,1.8
					C286.5,301.1,288.3,301.5,289.6,301.1z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M302.5,323.4c-1-0.9-2.7-0.4-3.1,0.9c-0.6,1.7-0.8,4.3-0.9,5.8c-0.2,2.1,0.2,4.4,1.3,6.2
					c0.4,0.7,1,1.4,1.8,1.8c0.7,0.4,1.7,0.5,2.5,0.2c0.9-0.4,1.5-1.3,1.8-2.3c0.2-1,0.1-2,0-3c-0.2-1.9-0.5-3.8-1.1-5.5
					C304.2,326.3,303.6,324.4,302.5,323.4z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M315.7,320c-1.3-0.5-2.7,0.6-2.6,2c0.1,1.8,0.9,4.3,1.3,5.7c0.7,2.1,1.8,4,3.6,5.3c0.7,0.5,1.5,0.9,2.3,1
					c0.9,0.1,1.8-0.1,2.4-0.7c0.7-0.7,0.9-1.8,0.8-2.8c-0.2-1-0.6-1.9-1.1-2.8c-0.9-1.7-1.9-3.3-3.1-4.7
					C318.5,322,317.2,320.5,315.7,320z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M327.1,310.1c-1.2,0.2-1.9,1.6-1.2,2.7c0.8,1.4,2.5,3.1,3.4,4c1.4,1.4,3.1,2.5,5,2.7
					c0.8,0.1,1.6,0.1,2.3-0.2c0.7-0.3,1.4-0.8,1.6-1.6c0.3-0.9,0-1.8-0.6-2.6c-0.5-0.7-1.3-1.3-2.1-1.8c-1.4-1-2.9-1.9-4.4-2.5
					C330.1,310.6,328.5,309.9,327.1,310.1z"
          />
        </g>
        <g class="pizza-st6">
          <path
            class="pizza-st5"
            d="M334.8,267.2c-1.2,2,1.9,4.3,3.9,5.5c5,3.1,6.9,6.8,9.7,11.8c2.6,4.6,3.5,10.7,3.3,16.2
					c0,0.3-0.1,1.9-0.2,3l-0.3,5.7c-0.2,4.9,2.3,2.7,2.3,2.7c2.7-7.2,2.5-15.3,0.7-22.1c-1.8-6.9-3.8-13-9.1-19.1
					c-1.4-1.7-3.3-3.2-5.2-4.2S335.9,265.3,334.8,267.2z"
          />
        </g>
        <path
          class="pizza-st11"
          d="M330.9,261c1.2-0.4,2.5,0.3,2.8,1.5c0.4,1.2-0.3,2.5-1.5,2.8c-1.2,0.4-2.5-0.3-2.8-1.5
				C329.1,262.6,329.7,261.4,330.9,261z"
        />
      </g>
      <!--помидорка-->

      <!--помидорка-->
      <g class="tomato-4">
        <ellipse
          class="pizza-st10"
          cx="247.2"
          cy="459.2"
          rx="49.8"
          ry="50.4"
          transform="matrix(0.9988 -4.796475e-02 4.796475e-02 0.9988 -21.7417 12.3832)"
        />
        <g class="pizza-st4">
          <path
            d="M258.1,445c-4.9,3.9-7.7,5.8-11.5,8.3c-6.4,4.3-11.8,8.9-18.3,13.2c-3.1,2.1-6.4,4.2-10.2,4.4c-5,0.3-9.7-3.1-11.9-7.5
					c-2.2-4.4-2.1-9.6-1-14.4c2.6-11.2,11.3-20.8,22.2-24.9c11-4,24.7-9.6,37,0.2c0,0,8.9,5.2,3.2,11.3
					C262.2,441.8,258.1,445,258.1,445z"
          />
        </g>
        <g class="pizza-st4">
          <path
            d="M263.7,457.3c-5.3,2.7-8.1,4.3-11.7,6.6c-6.2,3.8-12.4,6.6-18.6,10.4c-3,1.8-6.2,3.8-7.8,7c-2.2,4.2-1.1,9.6,1.9,13.2
					c3,3.6,7.5,5.7,12.1,6.6c10.8,2.2,22.6-1.4,30.4-9.2c7.8-7.8,17.3-18.9,14.6-31.1c0,0-2.3-12.9-9.4-9.4
					C269.5,454.1,263.7,457.3,263.7,457.3z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M250.7,440.8c1.1,0.8,2.7,0,3-1.3c0.3-1.7,0.2-4.4,0.1-5.9c-0.1-2.2-0.8-4.3-2.2-6c-0.5-0.7-1.2-1.2-2-1.5
					c-0.8-0.3-1.7-0.3-2.5,0.1c-0.9,0.5-1.3,1.5-1.4,2.5c-0.1,1,0.2,2,0.4,3c0.5,1.8,1,3.7,1.9,5.3
					C248.6,438.1,249.5,440,250.7,440.8z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M238.1,446c1.4,0.3,2.6-1,2.3-2.4c-0.4-1.7-1.5-4.1-2.1-5.5c-0.9-1.9-2.4-3.7-4.3-4.7
					c-0.8-0.4-1.6-0.7-2.5-0.7c-0.9,0-1.7,0.4-2.2,1.1c-0.6,0.8-0.7,1.9-0.3,2.9c0.3,1,0.9,1.8,1.5,2.6c1.1,1.5,2.3,3,3.8,4.2
					C235.1,444.4,236.6,445.7,238.1,446z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M228.3,457.5c1.2-0.3,1.6-1.9,0.8-2.8c-1-1.2-2.9-2.7-4-3.5c-1.6-1.2-3.4-2-5.4-2c-0.8,0-1.6,0.1-2.2,0.5
					c-0.7,0.4-1.2,1-1.4,1.8c-0.2,0.9,0.3,1.8,0.9,2.5c0.6,0.6,1.5,1.1,2.3,1.5c1.5,0.7,3.1,1.5,4.8,1.8
					C225.2,457.4,226.9,457.8,228.3,457.5z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M241.1,479.7c-1-0.9-2.7-0.4-3.1,0.9c-0.6,1.7-0.8,4.3-0.9,5.8c-0.2,2.1,0.2,4.4,1.3,6.2
					c0.4,0.7,1,1.4,1.8,1.8c0.7,0.4,1.7,0.5,2.5,0.2c0.9-0.4,1.5-1.3,1.8-2.3c0.2-1,0.1-2,0-3c-0.2-1.9-0.5-3.8-1.1-5.5
					C242.9,482.6,242.3,480.7,241.1,479.7z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M254.4,476.3c-1.3-0.5-2.7,0.6-2.6,2c0.1,1.8,0.9,4.3,1.3,5.7c0.7,2.1,1.8,4,3.6,5.3
					c0.7,0.5,1.5,0.9,2.3,1c0.9,0.1,1.8-0.1,2.4-0.7c0.7-0.7,0.9-1.8,0.8-2.8c-0.2-1-0.6-1.9-1.1-2.8c-0.9-1.7-1.9-3.3-3.1-4.7
					C257.1,478.4,255.8,476.8,254.4,476.3z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M265.8,466.4c-1.2,0.2-1.9,1.6-1.2,2.7c0.8,1.4,2.5,3.1,3.4,4c1.4,1.4,3.1,2.5,5,2.7
					c0.8,0.1,1.6,0.1,2.3-0.2c0.7-0.3,1.4-0.8,1.6-1.6c0.3-0.9,0-1.8-0.6-2.6c-0.5-0.7-1.3-1.3-2.1-1.8c-1.4-1-2.9-1.9-4.4-2.5
					C268.8,466.9,267.1,466.2,265.8,466.4z"
          />
        </g>
        <g class="pizza-st6">
          <path
            class="pizza-st5"
            d="M273.4,423.5c-1.2,2,1.9,4.3,3.9,5.5c5,3.1,6.9,6.8,9.7,11.8c2.6,4.6,3.5,10.7,3.3,16.2
					c0,0.3-0.1,1.9-0.2,3l-0.3,5.7c-0.2,4.9,2.3,2.7,2.3,2.7c2.7-7.2,2.5-15.3,0.7-22.1c-1.8-6.9-3.8-13-9.1-19.1
					c-1.4-1.7-3.3-3.2-5.2-4.2C276.6,422,274.6,421.7,273.4,423.5z"
          />
        </g>
        <path
          class="pizza-st11"
          d="M269.6,417.4c1.2-0.4,2.5,0.3,2.8,1.5c0.4,1.2-0.3,2.5-1.5,2.8c-1.2,0.4-2.5-0.3-2.8-1.5
				C267.7,419,268.4,417.7,269.6,417.4z"
        />
      </g>
      <!--помидорка-->

      <!--помидорка-->
      <g class="tomato-5">
        <ellipse
          class="pizza-st10"
          cx="307.3"
          cy="132.6"
          rx="50.4"
          ry="49.8"
          transform="matrix(0.5089 -0.8608 0.8608 0.5089 36.7907 329.6277)"
        />
        <g class="pizza-st4">
          <path
            d="M290.3,138.4c6.3-0.5,9.6-0.6,14.2-0.6c7.7,0,14.8-0.9,22.5-1c3.7,0,7.7,0,10.9,1.9c4.3,2.5,6.4,7.9,5.8,12.8
					c-0.6,4.9-3.5,9.2-7.1,12.6c-8.3,7.9-20.9,11.2-32.2,8.5c-11.4-2.7-25.9-5.5-30.8-20.5c0,0-4.6-9.2,3.5-11.2
					C285.1,138.9,290.3,138.4,290.3,138.4z"
          />
        </g>
        <g class="pizza-st4">
          <path
            d="M292.4,125.1c5.9,0.7,9.1,0.8,13.4,1c7.3,0.2,14,1.3,21.3,1.5c3.5,0.1,7.3,0.2,10.4-1.5c4.1-2.3,6.2-7.4,5.7-12.1
					c-0.5-4.7-3.2-8.9-6.5-12.2c-7.8-7.8-19.6-11.2-30.4-9c-10.8,2.2-24.8,6.3-29.3,18c0,0-5.1,12,2.7,13.1
					C285.8,124.6,292.4,125.1,292.4,125.1z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M294.2,146c-0.5-1.3-2.3-1.5-3.2-0.5c-1.2,1.3-2.6,3.5-3.3,4.9c-1.1,1.9-1.7,4-1.5,6.2
					c0.1,0.9,0.3,1.7,0.8,2.4c0.5,0.7,1.3,1.2,2.1,1.3c1,0.1,2-0.5,2.6-1.3c0.6-0.8,1-1.8,1.3-2.7c0.6-1.8,1.2-3.6,1.4-5.5
					C294.5,149.4,294.8,147.4,294.2,146z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M307.6,148.6c-1-1-2.7-0.6-3.2,0.7c-0.6,1.7-1,4.3-1.2,5.7c-0.3,2.1-0.1,4.4,1,6.3c0.4,0.8,1,1.4,1.7,1.9
					c0.7,0.5,1.7,0.6,2.5,0.3c0.9-0.3,1.6-1.2,1.9-2.2c0.3-1,0.2-2,0.2-3c-0.1-1.9-0.3-3.8-0.8-5.6
					C309.2,151.6,308.7,149.6,307.6,148.6z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M322.1,144.4c-1.2-0.4-2.4,0.7-2.2,1.9c0.2,1.6,0.9,3.8,1.4,5.1c0.7,1.8,1.8,3.5,3.4,4.6
					c0.6,0.4,1.4,0.8,2.1,0.8c0.8,0.1,1.6-0.2,2.1-0.7c0.6-0.7,0.8-1.7,0.6-2.6c-0.2-0.9-0.6-1.7-1.1-2.5c-0.9-1.5-1.8-2.9-3-4.1
					C324.6,146.2,323.4,144.8,322.1,144.4z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M323.6,118.8c0.3,1.3,2,1.8,3.1,1c1.4-1.1,3-3.1,4-4.3c1.3-1.7,2.3-3.8,2.3-5.9c0-0.9-0.1-1.7-0.5-2.5
					c-0.4-0.8-1.1-1.4-1.9-1.5c-1-0.2-2,0.3-2.7,1c-0.7,0.7-1.2,1.6-1.7,2.5c-0.9,1.7-1.7,3.4-2.1,5.2
					C323.7,115.4,323.2,117.3,323.6,118.8z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M310.6,114.3c0.8,1.1,2.6,0.9,3.3-0.3c0.9-1.5,1.6-4.1,2-5.5c0.6-2.1,0.7-4.3-0.1-6.4
					c-0.3-0.8-0.7-1.6-1.4-2.1c-0.7-0.6-1.5-0.9-2.4-0.7c-1,0.2-1.8,1-2.2,1.9c-0.4,0.9-0.5,1.9-0.6,2.9c-0.2,1.9-0.3,3.8,0,5.6
					C309.5,111.1,309.7,113.1,310.6,114.3z"
          />
        </g>
        <g>
          <path
            class="pizza-st10"
            d="M295.7,116.3c1.1,0.5,2.5-0.3,2.5-1.6c0.1-1.6-0.4-3.9-0.7-5.3c-0.4-1.9-1.3-3.8-2.7-5.1
					c-0.6-0.5-1.2-0.9-2-1.1c-0.8-0.2-1.6,0-2.2,0.4c-0.7,0.6-1,1.5-0.9,2.4c0.1,0.9,0.4,1.8,0.7,2.6c0.7,1.6,1.4,3.1,2.3,4.5
					C293.4,114.2,294.5,115.7,295.7,116.3z"
          />
        </g>
        <g class="pizza-st6">
          <path
            class="pizza-st5"
            d="M265.7,147.9c2.1-1,0.8-4.6-0.2-6.7c-2.5-5.3-2-9.5-1.6-15.2c0.4-5.3,2.9-10.9,6.1-15.4
					c0.2-0.2,1.1-1.5,1.8-2.4l3.4-4.6c2.9-3.9-0.4-3.5-0.4-3.5c-6.2,4.5-10.4,11.4-12.7,18.1c-2.3,6.7-3.9,12.9-2.9,21
					c0.3,2.2,1,4.5,2,6.4C262.2,147.5,263.7,148.9,265.7,147.9z"
          />
        </g>
        <path
          class="pizza-st11"
          d="M265.5,155.2c-1.2-0.4-1.9-1.6-1.5-2.8c0.4-1.2,1.6-1.9,2.8-1.5c1.2,0.4,1.9,1.6,1.5,2.8
				C268,154.9,266.7,155.6,265.5,155.2z"
        />
      </g>
      <!--помидорка-->

      <!--листик-->
      <g class="basil-1">
        <path
          class="pizza-st12"
          d="M208,181.3c-1.7,2.5-0.7,5.9,0.4,8.8c4.3,10.9,8.8,21.9,15.6,31.4c6.8,9.6,15.9,17.8,27,21.6
		c11.1,3.8,27.8-0.2,36.8-7.6c4.3-17.3-5.8-36-20.7-45.7c-14.9-9.8-33.6-12.1-51.5-11.3C212.9,178.5,209.6,178.9,208,181.3z"
        />
        <path
          class="pizza-st6"
          d="M286,235.7c-0.1,0-0.2,0-0.2,0c-25.8-7.5-48.9-23.3-65.2-44.8c-0.3-0.4-0.2-0.9,0.2-1.2
		c0.4-0.3,0.9-0.2,1.2,0.2c16,21.1,38.8,36.8,64.3,44.1c0.4,0.1,0.7,0.6,0.6,1C286.7,235.4,286.4,235.7,286,235.7z"
        />
        <path
          class="pizza-st5"
          d="M249,237.4c-0.1,0-0.2,0-0.3-0.1c-9.1-3.3-17.1-10-21.9-18.4c-0.2-0.4-0.1-0.9,0.3-1.2
		c0.4-0.2,0.9-0.1,1.2,0.3c4.6,8.1,12.2,14.5,21,17.7c0.4,0.2,0.7,0.6,0.5,1.1C249.7,237.1,249.3,237.4,249,237.4z"
        />
      </g>
      <!--листик-->
      <!--листик-->
      <g class="basil-2">
        <path
          class="pizza-st12"
          d="M105.5,405.5c-2.3-0.9-3.2-3.6-3.8-6c-2.3-9.2-4.4-18.5-4.4-27.9c0-9.4,2.1-19.1,7.7-26.8
		c5.5-7.7,18.3-12.8,27.7-12.2c10.9,9.4,13,26.3,7.7,39.7c-5.2,13.4-16.4,23.7-28.5,31.4C110,405,107.7,406.3,105.5,405.5z"
        />
        <path
          class="pizza-st6"
          d="M110,394.1C110,394.1,110,394.1,110,394.1c-0.5,0-0.9-0.4-0.8-0.9c0.7-21.7,8.5-42.9,22-59.9
		c0.3-0.4,0.8-0.4,1.2-0.1c0.4,0.3,0.4,0.8,0.1,1.2c-13.3,16.7-20.9,37.6-21.6,58.9C110.8,393.8,110.4,394.1,110,394.1z"
        />
        <path
          class="pizza-st5"
          d="M101,372.7c-0.4,0-0.8-0.3-0.8-0.8c-0.8-7.8,1.3-16,5.8-22.4c0.3-0.4,0.8-0.5,1.2-0.2
		c0.4,0.3,0.5,0.8,0.2,1.2c-4.2,6.1-6.2,13.9-5.5,21.3C101.9,372.3,101.6,372.7,101,372.7C101.1,372.7,101,372.7,101,372.7z"
        />
      </g>
      <!--листик-->
      <!--листик-->
      <g class="basil-3">
        <path
          class="pizza-st12"
          d="M327.5,503.8c-2.2-1.1-2.8-3.9-3.2-6.3c-1.4-9.3-2.5-18.8-1.6-28.2s4.1-18.8,10.4-25.9
		c6.3-7,19.5-10.9,28.8-9.3c9.9,10.4,10.2,27.5,3.7,40.3c-6.6,12.8-18.7,21.9-31.6,28.4C332,503.9,329.6,504.9,327.5,503.8z"
        />
        <path
          class="pizza-st6"
          d="M333.2,493c0,0-0.1,0-0.1,0c-0.5-0.1-0.8-0.5-0.7-1c2.9-21.5,12.8-41.8,27.9-57.3c0.3-0.3,0.9-0.3,1.2,0
		c0.3,0.3,0.3,0.9,0,1.2c-14.9,15.2-24.7,35.3-27.5,56.4C333.9,492.7,333.6,493,333.2,493z"
        />
      </g>
      <!--листик-->
      <!--листик-->
      <g class="basil-4">
        <path
          class="pizza-st12"
          d="M483.4,216.6c2.1-1.2,4.8-0.2,7,0.9c8.5,4.1,17,8.4,24.3,14.4c7.3,6,13.4,13.9,15.7,23
		c2.4,9.1-1.8,22.3-8.3,29.1c-14.2,2.4-28.5-6.8-35.5-19.4c-7-12.6-7.7-27.7-6-42C480.9,220.4,481.4,217.8,483.4,216.6z"
        />
        <path
          class="pizza-st6"
          d="M521.8,283.3c-0.4,0-0.7-0.3-0.8-0.7c-4.4-20.9-15.6-40.1-31.6-54.2c-0.4-0.3-0.4-0.8-0.1-1.2
		c0.3-0.4,0.8-0.4,1.2-0.1c16.3,14.3,27.7,33.9,32.1,55.1c0.1,0.5-0.2,0.9-0.7,1C521.9,283.3,521.9,283.3,521.8,283.3z"
        />
      </g>
      <!--листик-->

      <!--		оливка-->
      <path
        class="pizza-st9"
        d="M313.7,421.1c-0.2,0-0.4,0-0.6,0c-3-0.2-5.7-1.5-7.7-3.7c-2-2.2-3-5.1-2.8-8.1c0.2-3,1.5-5.7,3.7-7.7
c2.2-2,5.1-3,8.1-2.8l0,0c3,0.2,5.7,1.5,7.7,3.7c2,2.2,3,5.1,2.8,8.1c-0.2,3-1.5,5.7-3.7,7.7C319,420.1,316.4,421.1,313.7,421.1z
M313.6,403.8c-1.5,0-2.9,0.5-4.1,1.6c-1.2,1.1-1.9,2.6-2,4.2c-0.1,1.6,0.5,3.2,1.5,4.4c1.1,1.2,2.6,1.9,4.2,2
c1.6,0.1,3.2-0.5,4.4-1.5c1.2-1.1,1.9-2.6,2-4.2c0.1-1.6-0.5-3.2-1.5-4.4c-1.1-1.2-2.6-1.9-4.2-2
C313.9,403.8,313.8,403.8,313.6,403.8z"
      />
      <path
        class="pizza-st9"
        d="M238.5,289.6c-0.2,0-0.4,0-0.6,0c-6.2-0.3-10.9-5.6-10.5-11.8c0.3-6.2,5.7-10.9,11.8-10.5l0,0
c6.2,0.3,10.9,5.6,10.5,11.8c-0.2,3-1.5,5.7-3.7,7.7C243.8,288.6,241.2,289.6,238.5,289.6z M238.5,272.3c-3.2,0-5.9,2.5-6.1,5.8
c-0.1,1.6,0.5,3.2,1.5,4.4c1.1,1.2,2.6,1.9,4.2,2c1.6,0.1,3.2-0.5,4.4-1.5c1.2-1.1,1.9-2.6,2-4.2c0.2-3.4-2.4-6.3-5.8-6.4
C238.7,272.3,238.6,272.3,238.5,272.3z"
      />
      <path
        class="pizza-st9"
        d="M169.7,462c-0.2,0-0.4,0-0.6,0c-3-0.2-5.7-1.5-7.7-3.7c-2-2.2-3-5.1-2.8-8.1c0.2-3,1.5-5.7,3.7-7.7
c2.2-2,5.1-3,8.1-2.8l0,0c6.2,0.3,10.9,5.6,10.5,11.8C180.5,457.4,175.5,462,169.7,462z M169.6,444.7c-1.5,0-2.9,0.5-4.1,1.6
c-1.2,1.1-1.9,2.6-2,4.2c-0.1,1.6,0.5,3.2,1.5,4.4c1.1,1.2,2.6,1.9,4.2,2c3.3,0.2,6.3-2.4,6.4-5.8c0.2-3.4-2.4-6.3-5.8-6.5
C169.9,444.7,169.8,444.7,169.6,444.7z"
      />
      <path
        class="pizza-st9"
        d="M427.9,295.1c-0.2,0-0.4,0-0.6,0c-3-0.2-5.7-1.5-7.7-3.7c-2-2.2-3-5.1-2.8-8.1c0.3-6.2,5.7-10.9,11.8-10.5
c3,0.2,5.7,1.5,7.7,3.7s3,5.1,2.8,8.1C438.8,290.4,433.8,295.1,427.9,295.1z M427.9,277.8c-3.2,0-5.9,2.5-6.1,5.8
c-0.1,1.6,0.5,3.2,1.5,4.4c1.1,1.2,2.6,1.9,4.2,2c3.4,0.2,6.3-2.4,6.5-5.8c0.1-1.6-0.5-3.2-1.5-4.4c-1.1-1.2-2.6-1.9-4.2-2
C428.2,277.8,428,277.8,427.9,277.8z"
      />
      <!--		оливка-->
    </g>
  </svg>
</template>

<style lang="scss">
.pizza-st0 {
  fill: #c73d1f;
}
.pizza-st1 {
  fill: #e39945;
}
.pizza-st2 {
  opacity: 0.2;
  fill: #b94d3c;
}
.pizza-st3 {
  fill: #ffc510;
}
.pizza-st4 {
  opacity: 0.2;
}
.pizza-st5 {
  fill: #ffffff;
}
.pizza-st6 {
  opacity: 0.3;
}
.pizza-st7 {
  opacity: 0.2;
  fill: #ffffff;
}
.pizza-st8 {
  fill: #b94d3c;
}
.pizza-st9 {
  fill: #1a2e35;
}
.pizza-st10 {
  fill: #f74f4c;
}
.pizza-st11 {
  opacity: 0.3;
  fill: #ffffff;
}
.pizza-st12 {
  fill: #329b2f;
}

.tomato-1 {
  transform-origin: center center;
  animation: 2s tomato1 ease-out 1s both;
  animation-iteration-count: infinite;
  transform-box: fill-box;
}
@keyframes tomato1 {
  from,
  to {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.tomato-2 {
  transform-origin: center center;
  animation: 4s tomato2 ease-out 1s both;
  animation-iteration-count: infinite;
  transform-box: fill-box;
}
@keyframes tomato2 {
  from,
  to {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.8);
  }
}

.tomato-3 {
  transform-origin: center center;
  animation: 3s tomato3 ease-out 1s both;
  animation-iteration-count: infinite;
  transform-box: fill-box;
}
@keyframes tomato3 {
  from,
  to {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
}

.tomato-4 {
  transform-origin: center center;
  animation: 6s tomato4 ease-out 1s both;
  animation-iteration-count: infinite;
  transform-box: fill-box;
}
@keyframes tomato4 {
  from,
  to {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
}

.rotate {
  animation-name: rotation;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  transform-origin: center center;
  transform-box: fill-box;
  -webkit-transition: -webkit-transform 2s ease-in-out;
  transition: transform 9s ease-in-out;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
