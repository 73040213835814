import type {
  popupChangedProductsProps,
  PopupComponent,
  popupConfirmProps,
  popupInputProps,
  PopupPayload,
  PopupStore
} from '~types/popupStore'

import type { ServerError } from '@arora/common'

import { computed, ref } from 'vue'

import gsap from 'gsap'
import { defineStore } from 'pinia'

export const usePopupStore = defineStore('popupStore', (): PopupStore => {
  const popupVisible = ref<boolean>(false)
  const popupClosable = ref<boolean>(true)
  const onOpenPopup = ref<() => void>()
  const onClosePopup = ref<() => void>()
  const popupComponent = ref<PopupComponent | null>(null)
  const popupQueue = ref<PopupPayload[]>([])
  const popupProperties = ref<Map<string, any>>(new Map<string, any>())
  const shake = ref<boolean>(false)
  const dataTestId = ref<string | undefined>('')

  const currentPopup = computed<PopupPayload | null>(() =>
    popupQueue.value.length > 0 ? popupQueue.value[0] : null
  )

  const popupAnimationSpeed = 0.4

  function gsapPopupAnimation(): void {
    gsap.to('#v-popup-wrapper', {
      immediateRender: true,
      duration: popupAnimationSpeed,
      ease: 'circ.out',
      opacity: 1,
      scaleX: 1,
      scaleY: 1
    })

    gsap.to('#v-popup', {
      delay: popupAnimationSpeed / 2,
      duration: popupAnimationSpeed,
      ease: 'circ.out',
      opacity: 1
    })
  }

  function showPopup(payload: PopupPayload): void {
    document.documentElement.style.scrollbarGutter = 'stable'
    document.body.classList.add('v-open-overflow')
    popupComponent.value = payload.popupName
    popupProperties.value = payload.popupProperties ?? new Map<string, unknown>()
    popupClosable.value = payload.popupClosable
    onClosePopup.value = payload.onClosePopup
    onOpenPopup.value = payload.onOpenPopup
    dataTestId.value = payload.dataTestId
    popupVisible.value = true

    if (document.getElementById(`v-popup-wrapper`)) gsapPopupAnimation()
    else setTimeout(gsapPopupAnimation, 750)
  }

  function addPopupToQueue(payload: PopupPayload): void {
    popupQueue.value.push(payload)
  }

  function replacePopupInQueue(payload: PopupPayload): void {
    popupVisible.value = false
    popupComponent.value = payload.popupName
    popupProperties.value = payload.popupProperties ?? new Map<string, unknown>()
    popupVisible.value = true
    popupClosable.value = payload.popupClosable
    onClosePopup.value = payload.onClosePopup
    onOpenPopup.value = payload.onOpenPopup
    dataTestId.value = payload.dataTestId
    document.body.classList.add('v-open-overflow')
    document.documentElement.style.scrollbarGutter = 'stable'
  }

  async function showConfirm(
    payload: popupConfirmProps,
    popupClosable = true,
    dataTestId = ''
  ): Promise<void> {
    return await openPopup({
      popupName: 'confirmPopup',
      popupProperties: new Map<string, unknown>([
        ['message', payload.message],
        ['noFunction', payload.noFunction],
        ['noText', payload.noText],
        ['replaceCurrentPopup', payload.replaceCurrentPopup],
        ['title', payload.title],
        ['type', payload.type],
        ['yesFunction', payload.yesFunction],
        ['yesOrNo', payload.yesOrNo],
        ['yesText', payload.yesText]
      ]),
      popupClosable: popupClosable,
      dataTestId
    })
  }

  async function showSuccess(
    message: string,
    title: string | null = null,
    icon?: NonNullable<unknown>
  ): Promise<void> {
    return await openPopup({
      popupName: 'messagePopup',
      popupProperties: new Map<string, unknown>([
        ['icon', icon],
        ['message', message],
        ['title', title],
        ['type', 'success']
      ]),
      openPopupOutQueue: true,
      popupClosable: true
    })
  }

  async function showWarning(
    message: string,
    title: string | null = null,
    icon?: NonNullable<unknown>
  ): Promise<void> {
    return await openPopup({
      popupName: 'messagePopup',
      popupProperties: new Map<string, unknown>([
        ['icon', icon],
        ['message', message],
        ['title', title],
        ['type', 'warning']
      ]),
      openPopupOutQueue: true,
      popupClosable: true
    })
  }

  async function showError(
    message: string,
    dataTestId: string | null = null,
    icon?: NonNullable<unknown>
  ): Promise<void> {
    return await openPopup({
      popupName: 'messagePopup',
      popupProperties: new Map<string, unknown>([
        ['dataTestId', dataTestId],
        ['icon', icon],
        ['message', message],
        ['type', 'error']
      ]),
      openPopupOutQueue: true,
      popupClosable: true
    })
  }

  async function showException(error: ServerError): Promise<void> {
    if (error.code !== 0 && error.message) {
      return await openPopup({
        popupName: 'errorPopup',
        popupProperties: new Map<string, unknown>([
          ['message', error.message],
          ['type', error.message.includes(':line') ? 'exception' : 'error']
        ]),
        openPopupOutQueue: true,
        popupClosable: true
      })
    }
    await showWarning(error?.message ?? '')
  }

  async function showInternalError(error: Error): Promise<void> {
    return await openPopup({
      popupName: 'errorPopup',
      popupProperties: new Map<string, unknown>([
        ['message', `${error.message}\n\n${error.stack}`],
        ['type', 'internal']
      ]),
      openPopupOutQueue: true,
      popupClosable: true
    })
  }

  async function showInput(payload: popupInputProps): Promise<void> {
    return await openPopup({
      popupName: 'inputPopup',
      popupProperties: new Map<string, unknown>([
        ['dataTestId', payload.dataTestId],
        ['initialText', payload.initialText],
        ['onConfirm', payload.onConfirm],
        ['title', payload.title]
      ]),
      openPopupOutQueue: true,
      popupClosable: true
    })
  }

  async function showChangedProducts(payload: popupChangedProductsProps): Promise<void> {
    return await openPopup({
      popupName: 'cartChangedProductsPopup',
      popupProperties: new Map<string, unknown>([
        ['content', payload.content],
        ['okFunction', payload.okFunction]
      ]),
      openPopupOutQueue: true,
      popupClosable: true
    })
  }

  async function showCartCleanup(): Promise<void> {
    return await openPopup({
      popupName: 'cartCleanupPopup',
      openPopupOutQueue: true,
      popupClosable: true
    })
  }

  // Открытие модального окна
  async function openPopup(payload: PopupPayload): Promise<void> {
    if (payload.onOpenPopup) {
      await payload.onOpenPopup()
    }

    if (
      payload.popupProperties &&
      payload.popupProperties.has('replaceCurrentPopup') &&
      payload.popupProperties.get('replaceCurrentPopup')
    ) {
      replacePopupInQueue(payload)
    } else {
      addPopupToQueue(payload)
    }

    if (popupQueue.value.length === 1) {
      showPopup(payload)
    }
  }

  // Закрытие текущего модального окна
  async function closePopup(): Promise<gsap.core.Tween> {
    if (currentPopup.value && typeof currentPopup.value?.onClosePopup === 'function') {
      currentPopup.value?.onClosePopup()
    }

    gsap.to('#v-popup-wrapper', {
      immediateRender: true,
      duration: popupAnimationSpeed,
      ease: 'circ.out',
      scaleY: 0.8,
      scaleX: 0.8,
      opacity: 0
    })

    return gsap.to('#v-popup', {
      duration: popupAnimationSpeed,
      delay: popupAnimationSpeed / 2,
      ease: 'circ.out',
      opacity: 0,
      onComplete: () => {
        document.body.classList.remove('v-open-overflow')
        document.documentElement.style.scrollbarGutter = ''
        popupVisible.value = false
        popupQueue.value.shift()

        if (popupQueue.value.length > 0) {
          showPopup(popupQueue.value[0])
        }
      }
    })
  }

  function closePopupWithShake(): void {
    if (popupClosable.value) {
      closePopup()
    } else {
      shake.value = true
      setTimeout(() => (shake.value = false), 550)
    }
  }

  return {
    popupVisible,
    popupClosable,
    popupComponent,
    popupQueue,
    popupProperties,
    currentPopup,
    shake,
    dataTestId,
    closePopup,
    showInput,
    showError,
    showWarning,
    showSuccess,
    showConfirm,
    showException,
    showInternalError,
    showChangedProducts,
    showCartCleanup,
    openPopup,
    onClosePopup,
    closePopupWithShake
  }
})
