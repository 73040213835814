<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#2F2F2F"
        height="36"
        rx="8"
        width="36"
      />
      <path
        d="M18.9858 23.9738C11.4767 23.9738 7.19177 19.4804 7 12H10.7636C10.8894 17.4926 13.6641 19.8152 15.8575 20.2964V12H19.4053V16.7341C21.5748 16.5301 23.8521 14.3749 24.6192 12.0262H28.179C27.889 13.2573 27.3108 14.423 26.4806 15.45C25.6505 16.4771 24.5863 17.3434 23.3547 17.9948C24.7289 18.5901 25.9427 19.4332 26.9158 20.4684C27.889 21.5036 28.5994 22.7074 29 24H25.1226C24.2836 21.7245 22.198 19.9616 19.4413 19.721V24L18.9858 23.9738Z"
        fill="#ACACAC"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        v-if="enableBorder"
        height="36"
        rx="8"
        stroke="currentColor"
        width="36"
        x="1"
        y="1"
      />
      <path
        d="M19.9858 24.9738C12.4767 24.9738 8.19177 20.4804 8 13H11.7636C11.8894 18.4926 14.6641 20.8152 16.8575 21.2964V13H20.4053V17.7341C22.5748 17.5301 24.8521 15.3749 25.6192 13.0262H29.179C28.889 14.2573 28.3108 15.423 27.4806 16.45C26.6505 17.4771 25.5863 18.3434 24.3547 18.9948C25.7289 19.5901 26.9427 20.4332 27.9158 21.4684C28.889 22.5036 29.5994 23.7074 30 25H26.1226C25.2836 22.7245 23.198 20.9616 20.4413 20.721V25L19.9858 24.9738Z"
        fill="currentColor"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import type { SocialNetworkIcon } from '~types/props'

defineProps<SocialNetworkIcon>()

const appConfig = useAppConfig()
</script>
