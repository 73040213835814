<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-lhun"
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 11.5C16.7614 11.5 19 9.26142 19 6.5C19 3.73858 16.7614 1.5 14 1.5C11.2386 1.5 9 3.73858 9 6.5C9 9.26142 11.2386 11.5 14 11.5Z"
        fill="#5B5B5B"
      />
      <path
        d="M24.9997 25.9998H3.0003C2.71235 26.0039 2.42718 25.9383 2.16555 25.808C1.90392 25.6776 1.6724 25.4857 1.48784 25.2462C1.08161 24.7202 0.91786 24.0018 1.03911 23.2753C1.56659 20.1052 3.2128 17.4423 5.80023 15.5729C8.09891 13.9134 11.0107 13.5 14 13.5C16.9893 13.5 19.9011 13.9141 22.1998 15.5729C24.7872 17.4416 26.4334 20.1045 26.9609 23.2746C27.0821 24.0011 26.9184 24.7195 26.5122 25.2456C26.3277 25.4852 26.0962 25.6772 25.8345 25.8077C25.5729 25.9382 25.2877 26.0038 24.9997 25.9998Z"
        fill="#5B5B5B"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-poros"
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6667 11.8333C16.244 11.8333 18.3333 9.744 18.3333 7.16667C18.3333 4.58934 16.244 2.5 13.6667 2.5C11.0893 2.5 9 4.58934 9 7.16667C9 9.744 11.0893 11.8333 13.6667 11.8333Z"
        stroke="#F4F4F4"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        d="M12.5 25.834H26.5V25.834C26.5 20.035 21.799 15.334 16 15.334H12.5C6.70101 15.334 2 20.035 2 25.834V25.834H4.33333"
        stroke="#F4F4F4"
        stroke-linecap="round"
        stroke-width="1.5"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
