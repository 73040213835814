<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17.28C0 9.13091 0 5.05964 2.53309 2.53309C5.06618 0.00654554 9.13091 0 17.28 0H18.72C26.8691 0 30.9404 0 33.4669 2.53309C35.9935 5.06618 36 9.13091 36 17.28V18.72C36 26.8691 36 30.9404 33.4669 33.4669C30.9338 35.9935 26.8691 36 18.72 36H17.28C9.13091 36 5.05964 36 2.53309 33.4669C0.00654554 30.9338 0 26.8691 0 18.72V17.28Z"
        fill="#2F2F2F"
      />
      <path
        d="M28.5091 16.4224H28.5144C28.6483 16.4224 28.776 16.3662 28.8667 16.2678C28.9589 16.168 29.0045 16.0341 28.9925 15.8987C28.5106 10.5981 24.3139 6.39997 19.0128 5.91709C18.8784 5.90653 18.743 5.95021 18.6432 6.04285C18.5434 6.13501 18.4877 6.26509 18.4891 6.40093C18.5726 13.4454 19.5019 16.324 28.5091 16.4224ZM28.5091 17.3958C19.5019 17.4942 18.5731 20.3728 18.4896 27.4172C18.4882 27.5531 18.5438 27.6832 18.6437 27.7753C18.733 27.8574 18.8496 27.903 18.9696 27.903C18.984 27.903 18.9989 27.9025 19.0133 27.9011C24.3144 27.4182 28.511 23.2206 28.9925 17.9195C29.0045 17.7841 28.9594 17.6502 28.8667 17.5504C28.7741 17.4505 28.645 17.4011 28.5091 17.3958ZM16.955 5.91949C11.6707 6.41629 7.48752 10.6134 7.008 15.8996C6.996 16.035 7.04112 16.1689 7.13376 16.2688C7.22448 16.3672 7.35264 16.4229 7.48608 16.4229H7.49184C16.4693 16.3197 17.3962 13.4425 17.4806 6.40285C17.4821 6.26701 17.4259 6.13645 17.3261 6.04429C17.2253 5.95213 17.0904 5.90701 16.955 5.91949ZM7.49136 17.3953C7.34208 17.3785 7.22496 17.4496 7.13328 17.5494C7.04112 17.6493 6.99552 17.7832 7.00752 17.9185C7.48704 23.2048 11.6707 27.4019 16.955 27.8987C16.9699 27.9001 16.9853 27.9006 17.0002 27.9006C17.1202 27.9006 17.2363 27.8555 17.3256 27.7734C17.4254 27.6812 17.4816 27.5507 17.4802 27.4149C17.3957 20.3757 16.4693 17.4985 7.49136 17.3953Z"
        fill="#ACACAC"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-if="enableBorder"
        d="M1 18.28C1 10.1309 1 6.05964 3.53309 3.53309C6.06618 1.00655 10.1309 1 18.28 1H19.72C27.8691 1 31.9404 1 34.4669 3.53309C36.9935 6.06618 37 10.1309 37 18.28V19.72C37 27.8691 37 31.9404 34.4669 34.4669C31.9338 36.9935 27.8691 37 19.72 37H18.28C10.1309 37 6.05964 37 3.53309 34.4669C1.00655 31.9338 1 27.8691 1 19.72V18.28Z"
        stroke="currentColor"
      />
      <path
        d="M29.5093 17.4224H29.5146C29.6485 17.4224 29.7762 17.3662 29.8669 17.2678C29.9591 17.168 30.0047 17.034 29.9927 16.8987C29.5108 11.598 25.3141 7.39995 20.013 6.91707C19.8786 6.90651 19.7433 6.95019 19.6434 7.04283C19.5436 7.13499 19.4879 7.26507 19.4893 7.40091C19.5729 14.4454 20.5021 17.324 29.5093 17.4224ZM29.5093 18.3958C20.5021 18.4942 19.5733 21.3728 19.4898 28.4172C19.4884 28.5531 19.5441 28.6832 19.6439 28.7753C19.7332 28.8574 19.8498 28.903 19.9698 28.903C19.9842 28.903 19.9991 28.9025 20.0135 28.9011C25.3146 28.4182 29.5113 24.2206 29.9927 18.9195C30.0047 18.7841 29.9596 18.6502 29.8669 18.5504C29.7743 18.4505 29.6452 18.4011 29.5093 18.3958ZM17.9553 6.91947C12.6709 7.41627 8.48775 11.6134 8.00823 16.8996C7.99623 17.035 8.04135 17.1689 8.13399 17.2688C8.22471 17.3672 8.35287 17.4228 8.48631 17.4228H8.49207C17.4695 17.3196 18.3964 14.4425 18.4809 7.40283C18.4823 7.26699 18.4261 7.13643 18.3263 7.04427C18.2255 6.95211 18.0906 6.90699 17.9553 6.91947ZM8.49159 18.3953C8.34231 18.3785 8.22519 18.4496 8.13351 18.5494C8.04135 18.6492 7.99575 18.7832 8.00775 18.9185C8.48727 24.2048 12.6709 28.4019 17.9553 28.8987C17.9701 28.9001 17.9855 28.9006 18.0004 28.9006C18.1204 28.9006 18.2365 28.8555 18.3258 28.7734C18.4257 28.6812 18.4818 28.5507 18.4804 28.4148C18.3959 21.3756 17.4695 18.4985 8.49159 18.3953Z"
        fill="currentColor"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import type { SocialNetworkIcon } from '~types/props'

defineProps<SocialNetworkIcon>()
const appConfig = useAppConfig()
</script>
