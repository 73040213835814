<i18n>
ru:
  captionDelivery: Время доставки
  captionDeliveryMobile: Время доставки
  captionHall: Время приготовления
  captionHallMobile: Время приготовления
  captionPickup: Время приготовления
  captionPickupMobile: Время приготовления
ua:
  captionDelivery: Час доставки
  captionDeliveryMobile: Час доставки
  captionHall: Час приготування
  captionHallMobile: Час приготування
  captionPickup: Час приготування
  captionPickupMobile: Час приготування
us:
  captionDelivery: Delivery time
  captionDeliveryMobile: Delivery time
  captionHall: Cooking time
  captionHallMobile: Cooking time
  captionPickup: Cooking time
  captionPickupMobile: Cooking time
</i18n>

<template>
  <lazy-header-parts-average-time-umbar v-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Umbar'" />
  <lazy-header-parts-average-time-wold v-else-if="appConfig.VueSettingsPreRun.HeaderLayout === 'Wold'" />
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
