import api from '~api/general'

import type { HasCaptcha, SourceType, StoreState } from '~types/common'
import type { CaptchaState, IMetric, RestaurantStore } from '~types/restaurantStore'

import type { RegionalSettingSingle } from '@arora/common'

import { computed, ref } from 'vue'

import { defineStore } from 'pinia'

export const useRestaurantStore = defineStore('restaurantStore', (): RestaurantStore => {
  const appConfig = useAppConfig()

  const captcha = ref<CaptchaState>({
    GoogleToken: null,
    GoogleUseV2: false,
    YandexToken: null
  })
  const TimeCorrection = ref<StoreState<number>>({
    data: null,
    error: null,
    state: null
  })
  const Metrics = ref<IMetric | undefined>()
  const SSID = ref<string | undefined>()
  const Source = ref<SourceType | undefined>()
  const CurrencySettings = ref<RegionalSettingSingle | undefined>()

  const CurrencySymbol = computed<string>(() =>
    CurrencySettings.value
      ? (CurrencySettings.value.CurrencyUnicode ?? CurrencySettings.value.CurrencyISO.toString())
      : '?'
  )

  const reCAPTCHAv3 = computed<string>(() => {
    return appConfig.RestaurantSettingsPreRun.ReCaptchaV3SiteKey ?? ''
  })
  const reCAPTCHAv2 = computed<string>(() => {
    return appConfig.RestaurantSettingsPreRun.ReCaptchaV2SiteKey ?? ''
  })

  function CheckReCAPTCHAV3(key: string): Promise<string> {
    return new Promise((resolve) => {
      grecaptcha.ready(() => {
        resolve(
          grecaptcha.execute(key, { action: 'submit' }).then((token: string) => {
            return token
          })
        )
      })
    })
  }

  async function validateCaptcha<T extends HasCaptcha>(fields: T): Promise<T> {
    if (
      appConfig.RestaurantSettingsPreRun.CaptchaType === 'yandex' &&
      appConfig.RestaurantSettingsPreRun.YandexCaptchaInvisible
    ) {
      window.smartCaptcha.execute()
      await new Promise((r) => {
        setTimeout(r, 1250)
      })
    }
    switch (appConfig.RestaurantSettingsPreRun.CaptchaType) {
      case 'google':
        if (!reCAPTCHAv3.value && !reCAPTCHAv2.value) {
          fields.passedV3 = true
          fields.recaptcha = ''

          return fields
        }

        if (
          reCAPTCHAv3.value &&
          appConfig.RestaurantSettingsPreRun.ReCaptchaForceV2 &&
          fields.passedV3 &&
          !captcha.value.GoogleUseV2
        ) {
          fields.recaptcha = await CheckReCAPTCHAV3(reCAPTCHAv3.value)
        }

        if (!fields.recaptcha || captcha.value.GoogleUseV2) {
          fields.passedV3 = false
          fields.recaptcha = captcha.value.GoogleToken
        }

        return fields
      case 'yandex':
        if (appConfig.RestaurantSettingsPreRun.YandexCaptchaClientKey) {
          fields.smartcaptcha = captcha.value.YandexToken
        }

        return fields
    }
  }

  async function saveYandexCaptcha(token: string): Promise<void> {
    captcha.value.YandexToken = token
  }

  async function saveGoogleCaptcha(token: string): Promise<void> {
    captcha.value.GoogleToken = token
  }

  async function setGoogleCaptchaV2(useV2: boolean): Promise<void> {
    captcha.value.GoogleUseV2 = useV2
  }

  async function loadTimeCorrection(): Promise<void> {
    TimeCorrection.value.state = 'loading'

    try {
      TimeCorrection.value.data = await api.getTimeCorrection()
      TimeCorrection.value.state = 'success'
    } catch (error) {
      TimeCorrection.value.error = error
      TimeCorrection.value.state = 'error'
    }
  }

  return {
    TimeCorrection,
    Metrics,
    SSID,
    Source,
    CurrencySettings,
    CurrencySymbol,

    captcha,
    loadTimeCorrection,
    saveGoogleCaptcha,
    saveYandexCaptcha,
    setGoogleCaptchaV2,
    validateCaptcha
  }
})
