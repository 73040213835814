<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 17.28C0 9.13091 0 5.05964 2.53309 2.53309C5.06618 0.00654554 9.13091 0 17.28 0H18.72C26.8691 0 30.9404 0 33.4669 2.53309C35.9935 5.06618 36 9.13091 36 17.28V18.72C36 26.8691 36 30.9404 33.4669 33.4669C30.9338 35.9935 26.8691 36 18.72 36H17.28C9.13091 36 5.05964 36 2.53309 33.4669C0.00654554 30.9338 0 26.8691 0 18.72V17.28Z"
        fill="#2F2F2F"
      />
      <path
        d="M9 7H28V29H9V7Z"
        fill="#ACACAC"
      />
      <path
        d="M7.33333 3.9231C5.49333 3.9231 4 5.41643 4 7.25643V28.5898C4 30.4298 5.49333 31.9231 7.33333 31.9231H28.6667C30.5067 31.9231 32 30.4298 32 28.5898V7.25643C32 5.41643 30.5067 3.9231 28.6667 3.9231H7.33333ZM18 7.25643C20.9467 7.25643 23.3333 9.6431 23.3333 12.5898C23.3333 15.5364 20.9467 17.9231 18 17.9231C15.0533 17.9231 12.6667 15.5364 12.6667 12.5898C12.6667 9.6431 15.0533 7.25643 18 7.25643ZM18 9.9231C17.2928 9.9231 16.6145 10.204 16.1144 10.7041C15.6143 11.2042 15.3333 11.8825 15.3333 12.5898C15.3333 13.297 15.6143 13.9753 16.1144 14.4754C16.6145 14.9755 17.2928 15.2564 18 15.2564C18.7072 15.2564 19.3855 14.9755 19.8856 14.4754C20.3857 13.9753 20.6667 13.297 20.6667 12.5898C20.6667 11.8825 20.3857 11.2042 19.8856 10.7041C19.3855 10.204 18.7072 9.9231 18 9.9231ZM23.3333 18.9166C23.7533 18.9166 24.1671 19.1162 24.4271 19.4895C24.8471 20.0895 24.7004 20.9237 24.0938 21.3437C23.0938 22.0437 21.9933 22.5497 20.8333 22.8697L24.2734 26.3163C24.8001 26.8363 24.8001 27.6765 24.2734 28.1965C24.0134 28.4565 23.6733 28.5898 23.3333 28.5898C22.9933 28.5898 22.6532 28.4565 22.3932 28.1965L18 23.8098L13.6068 28.1965C13.3468 28.4565 13.0067 28.5898 12.6667 28.5898C12.3267 28.5898 11.9866 28.4565 11.7266 28.1965C11.1999 27.6765 11.1999 26.8363 11.7266 26.3163L15.1667 22.8697C14.0133 22.5497 12.9128 22.0502 11.9128 21.3502C11.3128 20.9302 11.1594 20.1027 11.5794 19.496C11.8394 19.1227 12.253 18.9231 12.6797 18.9231C12.9397 18.9231 13.2068 19.0027 13.4401 19.1627C14.7801 20.096 16.36 20.5898 18 20.5898C19.6467 20.5898 21.2262 20.0962 22.5729 19.1562C22.7996 18.9962 23.0667 18.9166 23.3333 18.9166Z"
        fill="#2F2F2F"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-if="enableBorder"
        d="M1 18.28C1 10.1309 1 6.05964 3.53309 3.53309C6.06618 1.00655 10.1309 1 18.28 1H19.72C27.8691 1 31.9404 1 34.4669 3.53309C36.9935 6.06618 37 10.1309 37 18.28V19.72C37 27.8691 37 31.9404 34.4669 34.4669C31.9338 36.9935 27.8691 37 19.72 37H18.28C10.1309 37 6.05964 37 3.53309 34.4669C1.00655 31.9338 1 27.8691 1 19.72V18.28Z"
        stroke="currentColor"
      />
      <path
        d="M18.6684 8C21.6151 8 24.0018 10.3867 24.0018 13.3333C24.0018 16.28 21.6151 18.6667 18.6684 18.6667C15.7218 18.6667 13.3351 16.28 13.3351 13.3333C13.3351 10.3867 15.7218 8 18.6684 8ZM18.6684 10.6667C17.9612 10.6667 17.2829 10.9476 16.7828 11.4477C16.2827 11.9478 16.0018 12.6261 16.0018 13.3333C16.0018 14.0406 16.2827 14.7189 16.7828 15.219C17.2829 15.719 17.9612 16 18.6684 16C19.3757 16 20.054 15.719 20.5541 15.219C21.0542 14.7189 21.3351 14.0406 21.3351 13.3333C21.3351 12.6261 21.0542 11.9478 20.5541 11.4477C20.054 10.9476 19.3757 10.6667 18.6684 10.6667ZM24.0018 19.6602C24.4218 19.6602 24.8355 19.8597 25.0955 20.2331C25.5155 20.8331 25.3689 21.6672 24.7622 22.0872C23.7622 22.7872 22.6618 23.2933 21.5018 23.6133L24.9419 27.0599C25.4685 27.5799 25.4685 28.4201 24.9419 28.9401C24.6819 29.2001 24.3418 29.3333 24.0018 29.3333C23.6618 29.3333 23.3217 29.2001 23.0617 28.9401L18.6684 24.5534L14.2752 28.9401C14.0152 29.2001 13.6751 29.3333 13.3351 29.3333C12.9951 29.3333 12.655 29.2001 12.395 28.9401C11.8683 28.4201 11.8683 27.5799 12.395 27.0599L15.8351 23.6133C14.6818 23.2933 13.5812 22.7937 12.5812 22.0938C11.9812 21.6737 11.8279 20.8463 12.2479 20.2396C12.5079 19.8663 12.9215 19.6667 13.3481 19.6667C13.6081 19.6667 13.8752 19.7463 14.1085 19.9062C15.4485 20.8396 17.0284 21.3333 18.6684 21.3333C20.3151 21.3333 21.8947 20.8397 23.2414 19.8997C23.468 19.7397 23.7351 19.6602 24.0018 19.6602Z"
        fill="currentColor"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import type { SocialNetworkIcon } from '~types/props'

defineProps<SocialNetworkIcon>()

const appConfig = useAppConfig()
</script>
