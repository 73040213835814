<i18n>
ru:
  more: Ещё
ua:
  more: Ще
us:
  more: More
</i18n>

<template>
  <ul
    :id="uid"
    class="v-elements-wrapper"
  >
    <template v-if="optionsShown.length > 0">
      <li
        v-for="(option, index) in optionsShown"
        :id="`${uid}-${option.ID}`"
        :key="sanitize(option.ID)"
        class="v-element"
      >
        <slot
          name="item"
          v-bind="{ option: option, index: index }"
        />
      </li>
    </template>
    <template v-if="optionsHidden.length > 0">
      <common-popover-menu
        class="v-help-block"
        placement="bottom-end"
      >
        <template #hoverable>
          <div
            class="v-element--more v-d-flex v-align-items-baseline v-pointer v-mb-xs"
            data-test-id="menu-groups-more-button"
          >
            <span v-html="translate('overflowElements.more')" />
            <div class="v-element--more__icon">
              <icon-general-caret-down />
            </div>
          </div>
        </template>
        <template #content>
          <ul
            class="v-overflow-wrapper"
            data-test-id="menu-groups-more-links"
          >
            <li
              v-for="option in optionsHidden"
              :id="`${uid}-${option?.ID ?? ''}`"
              :key="sanitize(option?.ID ?? '')"
              class="v-overflow-element"
            >
              <slot
                name="itemOverflow"
                v-bind="option"
              />
            </li>
          </ul>
        </template>
      </common-popover-menu>
    </template>
  </ul>
</template>

<script setup lang="ts" generic="T extends HasID & HasSortWeight">
import type { HasSortWeight } from '~types/common'

import { type HasID, useCommon, useWindowSize } from '@arora/common'

const { options } = defineProps<{
  selected: T | undefined
  options: T[]
  shownOptionsAmount?: number
}>()

const emit = defineEmits(['update:selected', 'update:shownOptionsAmount'])

watch(
  () => options,
  (newValue: T[], oldValue: T[]) => {
    if (newValue.length !== oldValue.length) {
      updateTabs()
    }
  }
)

const optionsShown = ref<T[]>([]) as Ref<T[]>
const optionsHidden = ref<T[]>([]) as Ref<T[]>

const { getStyleWidth } = useCommon()
const { translate, sanitize } = useI18nSanitized()
const { windowSize } = useWindowSize()

const uid = useId()

onMounted(() => {
  updateTabs()
})

function updateTabs(): void {
  const optionsWrapper = document.querySelector<HTMLUListElement>(`#${uid}`)

  if (optionsWrapper) {
    const wrapperWidth = getStyleWidth(optionsWrapper.parentElement!)

    let optionsWidth = getStyleWidth(optionsWrapper)

    if (wrapperWidth > 0 && optionsWidth > 0) {
      if (optionsWidth > wrapperWidth) {
        optionsShown.value = [...options]
        optionsHidden.value = []

        while (optionsWidth > wrapperWidth) {
          if (optionsShown.value.length === 0) break

          const option = optionsShown.value.pop()
          if (option) {
            optionsHidden.value.push(option)

            const htmlElement = document.getElementById(`${uid}-${option.ID}`)
            if (htmlElement) optionsWidth -= getStyleWidth(htmlElement)
          }
        }
      }
    } else {
      optionsShown.value = [...options]
      optionsHidden.value = []

      setTimeout(() => updateTabs(), 200)
    }

    optionsShown.value.sort((a, b) => a.SortWeight - b.SortWeight)
    optionsHidden.value.sort((a, b) => a.SortWeight - b.SortWeight)
  }

  emit('update:shownOptionsAmount', optionsShown.value.length)
}

watch(
  () => windowSize.value,
  async (newState, oldState) => {
    if (newState !== oldState) {
      updateTabs()
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-elements-wrapper {
  width: max-content;
  white-space: nowrap;
  display: flex;
  align-items: baseline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.v-element {
  margin-right: 24px;

  @include mixins.sm {
    margin-right: 12px;
  }

  &--more {
    padding: 8px 16px;
    border-radius: variables.$BorderRadiusInput;
    background-color: variables.$FormBackground;
    color: variables.$BodyTextColor;

    &:hover {
      color: variables.$SecondaryBackgroundColor;

      .v-element--more__icon {
        svg {
          path {
            fill: variables.$SecondaryBackgroundColor;
          }
        }
      }
    }

    &__icon {
      padding: 4px;
      svg {
        path {
          fill: variables.$BodyTextColor;
        }
      }
    }
  }
}

.v-overflow-wrapper {
  padding: 12px;
  margin: 0;
  list-style: none;

  max-height: 75vh;
  overflow-y: auto;

  .v-overflow-element {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
