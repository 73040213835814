<template>
  <svg
    id="Слой_1"
    data-name="Слой 1"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="twitter-cls-1"
      cx="30"
      cy="30"
      r="27.5"
    />
    <path
      class="twitter-cls-2"
      d="M45.19,20.58a12.21,12.21,0,0,1-3,.86c.49-.08,1.2-1,1.49-1.32a5.61,5.61,0,0,0,1-1.84.11.11,0,0,0,0-.15.15.15,0,0,0-.16,0,16,16,0,0,1-3.59,1.38.24.24,0,0,1-.25-.07,3.22,3.22,0,0,0-.32-.32,6.38,6.38,0,0,0-1.74-1.07,6.08,6.08,0,0,0-2.67-.43,6.5,6.5,0,0,0-2.54.72,6.57,6.57,0,0,0-2,1.67,6.24,6.24,0,0,0-1.22,2.44,6.64,6.64,0,0,0-.07,2.59c0,.14,0,.16-.12.14-4.94-.74-9-2.5-12.36-6.29-.14-.16-.22-.16-.34,0-1.45,2.18-.75,5.69,1.07,7.41a8,8,0,0,0,.76.66,6.39,6.39,0,0,1-2.39-.66c-.14-.09-.21,0-.23.12a4.35,4.35,0,0,0,0,.71,6.34,6.34,0,0,0,3.91,5.05,3.14,3.14,0,0,0,.79.24,7.05,7.05,0,0,1-2.34.08c-.17,0-.23,0-.17.21a6.58,6.58,0,0,0,4.92,4.12,5.37,5.37,0,0,1,.67.09l0,0c-.56.84-2.47,1.46-3.36,1.78a12.1,12.1,0,0,1-5.1.65c-.27,0-.33,0-.4,0s0,.11.08.18c.34.23.7.43,1.06.63a16.1,16.1,0,0,0,3.4,1.36c6.13,1.69,13,.45,17.62-4.12C41.13,33.88,42.4,28.93,42.4,24c0-.2.23-.3.36-.41a11.81,11.81,0,0,0,2.4-2.49.74.74,0,0,0,.16-.48v0C45.32,20.5,45.32,20.52,45.19,20.58Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
.twitter-cls-1 {
  fill: #0cf;
}

.twitter-cls-2 {
  fill: #fff;
}
</style>
