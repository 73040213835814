import { ISO } from '@arora/common'
import { useI18n } from 'vue-i18n'

type keyboardCheckComposable = {
  convertLayout: (str: string) => string
  keyboardSearchCheck: (check: string, str: string | null | undefined) => boolean
}

export default function useKeyboardCheck(): keyboardCheckComposable {
  const { locale } = useI18n()

  const En = "qwertyuiop[]asdfghjkl;'zxcvbnm,.",
    Ru = 'йцукенгшщзхъфывапролджэячсмитьбю',
    Ua = 'йцукенгшщзхїфівапролджєячсмитьбю'

  const EnRu: Record<string, string> = [...En].reduce(
    (a, error, index) =>
      // @ts-expect-error they are all strings not any
      (a[error] = Ru[index]) && (a[error.toLowerCase()] = Ru[index].toLowerCase()) && a,
    {}
  )

  const EnUa: Record<string, string> = [...En].reduce(
    (a, error, index) =>
      // @ts-expect-error they are all strings not any
      (a[error] = Ua[index]) && (a[error.toLowerCase()] = Ua[index].toLowerCase()) && a,
    {}
  )

  function convertLayout(str: string): string {
    let result = ''
    for (let index = 0; index < str.length; index++) {
      const symbol = str.toLowerCase()[index]
      if (symbol === ' ') {
        result += symbol
      } else {
        let symbolConverted: string | null = null
        switch (locale.value.toUpperCase()) {
          case ISO.RussianFederation:
            symbolConverted = EnRu[symbol]
            break
          case ISO.Ukraine:
            symbolConverted = EnUa[symbol]
            break
        }

        if (symbolConverted) result += symbolConverted
      }
    }

    return result
  }

  function keyboardSearchCheck(check: string, str: string | null | undefined): boolean {
    if (!str) return true

    const converted = convertLayout(str)

    if (converted.trim().length > 0) {
      return (
        check.toLowerCase().includes(str.toLowerCase()) ||
        check.toLowerCase().includes(converted.toLowerCase())
      )
    }

    return check.toLowerCase().includes(str.toLowerCase())
  }

  return {
    convertLayout,
    keyboardSearchCheck
  }
}
