<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1712_200)">
        <path
          d="M0 17.28C0 9.13091 0 5.05964 2.53309 2.53309C5.06618 0.00654545 9.13091 0 17.28 0H18.72C26.8691 0 30.9404 0 33.4669 2.53309C35.9935 5.06618 36 9.13091 36 17.28V18.72C36 26.8691 36 30.9404 33.4669 33.4669C30.9338 35.9935 26.8691 36 18.72 36H17.28C9.13091 36 5.05964 36 2.53309 33.4669C0.00654545 30.9338 0 26.8691 0 18.72V17.28Z"
          fill="#2F2F2F"
        />
        <path
          clip-rule="evenodd"
          d="M17.9968 6C11.9327 6 7 10.9341 7 16.9999C7 19.4055 7.77579 21.6365 9.09459 23.4474L7.72398 27.5342L11.952 26.183C13.691 27.334 15.7663 28 18.0032 28C24.0673 28 29 23.0657 29 17.0001C29 10.9343 24.0673 6.00018 18.0032 6.00018L17.9968 6ZM14.9259 11.5875C14.7126 11.0766 14.5509 11.0573 14.2278 11.0441C14.1178 11.0378 13.9952 11.0314 13.8593 11.0314C13.4389 11.0314 12.9993 11.1542 12.7342 11.4258C12.4111 11.7556 11.6094 12.525 11.6094 14.103C11.6094 15.6809 12.7601 17.207 12.9152 17.4206C13.0769 17.6338 15.1587 20.9189 18.3911 22.2578C20.9188 23.3054 21.6689 23.2083 22.2442 23.0854C23.0846 22.9044 24.1385 22.2833 24.4036 21.5334C24.6687 20.7831 24.6687 20.1429 24.5909 20.0071C24.5134 19.8713 24.2999 19.7939 23.9768 19.632C23.6537 19.4703 22.0825 18.6943 21.7851 18.5908C21.4941 18.481 21.2162 18.5198 20.9966 18.8303C20.6862 19.2635 20.3824 19.7034 20.1367 19.9684C19.9427 20.1754 19.6258 20.2013 19.3608 20.0912C19.0052 19.9427 18.0098 19.5932 16.7813 18.5003C15.8309 17.6533 15.1844 16.5993 14.9971 16.2825C14.8095 15.9592 14.9777 15.7714 15.1262 15.597C15.2879 15.3964 15.443 15.2542 15.6046 15.0666C15.7663 14.8792 15.8568 14.7821 15.9602 14.5622C16.0703 14.3488 15.9925 14.1289 15.915 13.9672C15.8374 13.8055 15.191 12.2275 14.9259 11.5875Z"
          fill="#ACACAC"
          fill-rule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1712_200">
          <rect
            fill="white"
            height="36"
            width="36"
          />
        </clipPath>
      </defs>
    </svg>
  </template>

  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-if="enableBorder"
        d="M1 18.28C1 10.1309 1 6.05964 3.53309 3.53309C6.06618 1.00655 10.1309 1 18.28 1H19.72C27.8691 1 31.9404 1 34.4669 3.53309C36.9935 6.06618 37 10.1309 37 18.28V19.72C37 27.8691 37 31.9404 34.4669 34.4669C31.9338 36.9935 27.8691 37 19.72 37H18.28C10.1309 37 6.05964 37 3.53309 34.4669C1.00655 31.9338 1 27.8691 1 19.72V18.28Z"
        stroke="currentColor"
      />
      <path
        clip-rule="evenodd"
        d="M18.9968 7C12.9327 7 8 11.9341 8 17.9999C8 20.4055 8.77579 22.6365 10.0946 24.4474L8.72398 28.5342L12.952 27.183C14.691 28.334 16.7663 29 19.0032 29C25.0673 29 30 24.0657 30 18.0001C30 11.9343 25.0673 7.00018 19.0032 7.00018L18.9968 7ZM15.9259 12.5875C15.7126 12.0766 15.5509 12.0573 15.2278 12.0441C15.1178 12.0378 14.9952 12.0314 14.8593 12.0314C14.4389 12.0314 13.9993 12.1542 13.7342 12.4258C13.4111 12.7556 12.6094 13.525 12.6094 15.103C12.6094 16.6809 13.7601 18.207 13.9152 18.4206C14.0769 18.6338 16.1587 21.9189 19.3911 23.2578C21.9188 24.3054 22.6689 24.2083 23.2442 24.0854C24.0846 23.9044 25.1385 23.2833 25.4036 22.5334C25.6687 21.7831 25.6687 21.1429 25.5909 21.0071C25.5134 20.8713 25.2999 20.7939 24.9768 20.632C24.6537 20.4703 23.0825 19.6943 22.7851 19.5908C22.4941 19.481 22.2162 19.5198 21.9966 19.8303C21.6862 20.2635 21.3824 20.7034 21.1367 20.9684C20.9427 21.1754 20.6258 21.2013 20.3608 21.0912C20.0052 20.9427 19.0098 20.5932 17.7813 19.5003C16.8309 18.6533 16.1844 17.5993 15.9971 17.2825C15.8095 16.9592 15.9777 16.7714 16.1262 16.597C16.2879 16.3964 16.443 16.2542 16.6046 16.0666C16.7663 15.8792 16.8568 15.7821 16.9602 15.5622C17.0703 15.3488 16.9925 15.1289 16.915 14.9672C16.8374 14.8055 16.191 13.2275 15.9259 12.5875Z"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import type { SocialNetworkIcon } from '~types/props'

defineProps<SocialNetworkIcon>()
const appConfig = useAppConfig()
</script>
