<template>
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.84337 11.1756C8.65066 11.3577 8.34934 11.3577 8.15663 11.1756L4.6508 7.86345C4.322 7.55281 4.54184 7 4.99417 7L12.0058 7C12.4582 7 12.678 7.55281 12.3492 7.86345L8.84337 11.1756Z"
      fill="red"
    />
  </svg>
</template>
