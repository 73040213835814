<i18n>
ru:
  warningText: Извините, но в данный момент мы задерживаемся
ua:
  warningText: Вибачте, але зараз ми затримуємося
us:
  warningText: Sorry, but we are delayed at the moment
</i18n>

<template>
  <template v-if="isAddressSelectorPopupTime">
    <span
      class="v-font-weight-600 v-mb-xxs"
      :class="{
        'v-error-color':
          appConfig.VueSettingsPreRun.AverageTimeWarningWhenThereIsDelay &&
          clientStore.haveDelayInAverageTime
      }"
      v-html="`~ ${makeReadableTimeFromMs(clientStore.averageTimeWithDelayMs, true)}`"
    />
    <div
      v-if="
        appConfig.VueSettingsPreRun.AverageTimeWarningWhenThereIsDelay &&
        clientStore.haveDelayInAverageTime
      "
      class="v-d-flex v-align-items-center"
    >
      <div>
        <icon-general-information-circle class="v-error-color v-average-time__icon v-mr-xs" />
      </div>
      <div
        :class="{
          'v-error-color':
            appConfig.VueSettingsPreRun.AverageTimeWarningWhenThereIsDelay &&
            clientStore.haveDelayInAverageTime
        }"
        v-html="translate('averageTimeBase.warningText')"
      />
    </div>
  </template>
  <template v-else>
    <span
      v-if="isDesktopVersion"
      class="v-mr-xs v-font-weight-600"
      :class="{
        'v-error-color':
          appConfig.VueSettingsPreRun.AverageTimeWarningWhenThereIsDelay &&
          clientStore.haveDelayInAverageTime
      }"
      v-html="`~ ${makeReadableTimeFromMs(clientStore.averageTimeWithDelayMs, true)}`"
    />
    <span
      v-else
      class="v-mr-xs v-font-weight-600"
      :class="{
        'v-error-color':
          appConfig.VueSettingsPreRun.AverageTimeWarningWhenThereIsDelay &&
          clientStore.haveDelayInAverageTime
      }"
      v-html="`(~ ${makeReadableTimeFromMs(clientStore.averageTimeWithDelayMs, true)})`"
    />
    <common-popover
      v-if="
        appConfig.VueSettingsPreRun.AverageTimeWarningWhenThereIsDelay &&
        clientStore.haveDelayInAverageTime &&
        isDesktopVersion
      "
      :delay="100"
      placement="bottom-start"
    >
      <template #hoverable>
        <div class="v-average-time__icon">
          <icon-general-information-circle class="v-error-color v-average-time__icon" />
        </div>
      </template>
      <template #content>
        <div v-html="translate('averageTimeBase.warningText')" />
      </template>
    </common-popover>
  </template>
</template>

<script setup lang="ts">
import { useWindowSize } from '@arora/common'

withDefaults(
  defineProps<{
    isAddressSelectorPopupTime?: boolean
  }>(),
  {
    isAddressSelectorPopupTime: false
  }
)

const { makeReadableTimeFromMs } = useDateTime()
const { translate } = useI18nSanitized()
const { isDesktopVersion } = useWindowSize()

const appConfig = useAppConfig()
const clientStore = useClientStore()
</script>

<style scoped lang="scss">
.v-average-time__icon {
  width: 20px;
  height: 20px;
}
</style>
