<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="36"
      viewBox="0 0 36 36"
      width="36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#2F2F2F"
        height="36"
        rx="8"
        width="36"
      />
      <path
        d="M8.2862 17.8783C14.1305 15.2752 21.6656 12.1058 22.7359 11.6628C25.4726 10.5181 26.3179 10.7335 25.8983 13.2813C25.5942 15.1275 24.7246 21.1646 24.0373 24.937C23.6238 27.171 22.7055 27.4356 21.252 26.4694C20.5587 26.0017 17.0436 23.657 16.2591 23.097C15.4746 22.537 14.6049 21.9892 15.809 20.7954C16.2348 20.3707 19.0444 17.6568 21.2398 15.5398C21.2922 15.4811 21.3222 15.4054 21.3244 15.3263C21.3265 15.2472 21.3007 15.1699 21.2516 15.1084C21.2025 15.0468 21.1333 15.005 21.0565 14.9904C20.9796 14.9759 20.9002 14.9896 20.8324 15.029L13.2852 20.1184C12.8735 20.4257 12.3918 20.6229 11.8847 20.6918C11.3776 20.7606 10.8616 20.6989 10.3843 20.5123C9.36263 20.2169 8.35918 19.8599 7.95172 19.7246C6.46782 19.2015 6.82663 18.5307 8.2862 17.8783Z"
        fill="#ACACAC"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-social-network-icon"
      fill="none"
      height="38"
      viewBox="0 0 38 38"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        v-if="enableBorder"
        height="36"
        rx="8"
        stroke="currentColor"
        width="36"
        x="1"
        y="1"
      />
      <path
        d="M9.2862 18.8783C15.1305 16.2752 22.6656 13.1058 23.7359 12.6628C26.4726 11.5181 27.3179 11.7335 26.8983 14.2813C26.5942 16.1275 25.7246 22.1646 25.0373 25.937C24.6238 28.171 23.7055 28.4356 22.252 27.4694C21.5587 27.0017 18.0436 24.657 17.2591 24.097C16.4746 23.537 15.6049 22.9892 16.809 21.7954C17.2348 21.3707 20.0444 18.6568 22.2398 16.5398C22.2922 16.4811 22.3222 16.4054 22.3244 16.3263C22.3265 16.2472 22.3007 16.1699 22.2516 16.1084C22.2025 16.0468 22.1333 16.005 22.0565 15.9904C21.9796 15.9759 21.9002 15.9896 21.8324 16.029L14.2852 21.1184C13.8735 21.4257 13.3918 21.6229 12.8847 21.6918C12.3776 21.7606 11.8616 21.6989 11.3843 21.5123C10.3626 21.2169 9.35918 20.8599 8.95172 20.7246C7.46782 20.2015 7.82663 19.5307 9.2862 18.8783Z"
        fill="currentColor"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import type { SocialNetworkIcon } from '~types/props'

defineProps<SocialNetworkIcon>()
const appConfig = useAppConfig()
</script>
