<template>
  <svg
    fill="none"
    height="23"
    viewBox="0 0 33 23"
    width="33"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0743 0.49495C14.6407 0.490849 11.5919 1.25989 8.58408 2.82532C5.58105 4.39075 3.20078 6.44153 1.80351 8.44446C0.405566 10.4406 0.0275385 12.2999 0.715234 13.6261C1.40361 14.9454 3.14404 15.7042 5.58379 15.7042C8.02353 15.7111 11.0724 14.9386 14.0733 13.38C17.0812 11.8146 19.4601 9.75696 20.8546 7.76087C22.256 5.76477 22.6251 3.9054 21.9415 2.57923C21.2511 1.25989 19.5147 0.49495 17.0743 0.49495ZM23.3565 5.29993C23.1173 6.34583 22.5978 7.41907 21.8663 8.46497C20.3214 10.673 17.7989 12.8263 14.6407 14.4669C11.4825 16.1144 8.27646 16.9415 5.5831 16.9415C4.31162 16.9415 3.1331 16.7501 2.13984 16.3468L2.86377 17.7413C3.55283 19.0675 5.28848 19.8263 7.72959 19.8263C10.17 19.8263 13.2188 19.0607 16.2198 17.4952C19.2276 15.9367 21.6065 13.879 23.0011 11.8761C24.3956 9.88001 24.7784 8.02063 24.088 6.7013L23.3565 5.29993ZM25.3253 6.44837C26.0909 8.27356 25.4757 10.4952 24.0128 12.5802C22.7208 14.4327 20.7452 16.2306 18.2979 17.7345C19.0567 17.8165 19.8497 17.8575 20.6563 17.8575C24.0401 17.8575 27.1026 17.1329 29.2628 16.005C31.4298 14.8771 32.6192 13.4005 32.6192 11.9103C32.6192 10.42 31.4298 8.94349 29.2628 7.81556C28.1622 7.24134 26.8224 6.76966 25.3253 6.44837ZM32.6192 14.9796C31.922 15.7931 30.9649 16.5109 29.837 17.0988C27.4444 18.3429 24.2179 19.088 20.6563 19.088C19.2071 19.088 17.8126 18.965 16.5069 18.7325C14.921 19.5324 13.3282 20.1271 11.797 20.5167C11.879 20.5646 11.961 20.6056 12.0499 20.6534C14.2101 21.7814 17.2726 22.506 20.6563 22.506C24.0401 22.506 27.1026 21.7814 29.2628 20.6534C31.4298 19.5255 32.6192 18.049 32.6192 16.5587V14.9796Z"
      fill="currentColor"
    />
  </svg>
</template>
