import { default as aboutqnjfsKxADTMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/about.vue?macro=true";
import { default as cabinetL8raYAk0N2Meta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/account/cabinet.vue?macro=true";
import { default as _91id_93kl6hzNGPskMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/actions/[id].vue?macro=true";
import { default as listMS5NK5x9WfMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/actions/list.vue?macro=true";
import { default as _91id_93P6Lo914YKFMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/articles/[id].vue?macro=true";
import { default as listjw4RyOPSMhMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/articles/list.vue?macro=true";
import { default as finalStepNQATktEXuzMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/cart/finalStep.vue?macro=true";
import { default as firstStepQD7r0evTgLMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/cart/firstStep.vue?macro=true";
import { default as secondStepqO3sRg3r3zMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/cart/secondStep.vue?macro=true";
import { default as contacts1G7tbESKbIMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/contacts.vue?macro=true";
import { default as customDSMEEWaGKYMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/custom.vue?macro=true";
import { default as deliveryhS6qvz93WwMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/delivery.vue?macro=true";
import { default as feedbackMsBOEXIpEZMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/feedback.vue?macro=true";
import { default as indexciRFrpvRMbMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/index.vue?macro=true";
import { default as legalgpnPb9fna2Meta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/legal.vue?macro=true";
import { default as _91id_93MsAtzHiwmCMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue?macro=true";
import { default as _91id_93bFvQjUH6oaMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue?macro=true";
import { default as partnershiptmrqi7YWREMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/partnership.vue?macro=true";
import { default as _91id_93iDpghFRiEQMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/restaurants/[id].vue?macro=true";
import { default as listzxXj68HsZDMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/restaurants/list.vue?macro=true";
import { default as off0vH3lRe3iIMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/system/off.vue?macro=true";
import { default as vacancyRi1mk213RiMeta } from "D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/vacancy.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: {"MetaTags":[{"Name":"description","Content":"На данной странице можно ознакомиться с историей и описанием кафе-ресторана TriSousa"}]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/about.vue")
  },
  {
    name: "account-cabinet",
    path: "/cabinet",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/account/cabinet.vue")
  },
  {
    name: "actions-id",
    path: "/action/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/actions/[id].vue")
  },
  {
    name: "actions-list",
    path: "/actions",
    meta: {"MetaTags":[{"Name":"description","Content":"На странице представлена вся информация об акциях, проходящих на данный момент в ресторане."}]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/actions/list.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/articles/[id].vue")
  },
  {
    name: "articles-list",
    path: "/articles",
    meta: {"MetaTags":[{"Name":"description","Content":"На данной странице размещены интересные статьи и полезные материалы из кафе-ресторана TriSousa"}]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/articles/list.vue")
  },
  {
    name: "cart-finalStep",
    path: "/order/complete/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/cart/finalStep.vue")
  },
  {
    name: "cart-firstStep",
    path: "/order",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/cart/firstStep.vue")
  },
  {
    name: "cart-secondStep",
    path: "/order/delivery",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/cart/secondStep.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: {"MetaTags":[{"Name":"description","Content":"На этой странице указаны все контакты TriSousa в Мурманске: адрес ресторана, контактные телефоны, e-mail, часы работы."}]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/contacts.vue")
  },
  {
    name: "custom",
    path: "/custom",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/custom.vue")
  },
  {
    name: "delivery",
    path: "/delivery",
    meta: {"MetaTags":[{"Name":"description","Content":"На странице размещена вся информация, касаемо доставки: зоны обслуживания, минимальная сумма заказа."}]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/delivery.vue")
  },
  {
    name: "feedback",
    path: "/feedback/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    meta: {"MetaTags":[{"Name":"description","Content":"Доставка еды от ресторана «TriSousa», в нашем разннобразном меню вы найдете шаурму, бургеры, обеды и многое другое. Заходите на наш сайт и закажите вкусную еду онлай!"}]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/legal.vue")
  },
  {
    name: "menu-group-id",
    path: "/menu/group/:id()",
    meta: { ...(_91id_93MsAtzHiwmCMeta || {}), ...{"MetaTags":[]} },
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "menu-product-id",
    path: "/menu/product/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "partnership",
    path: "/partners",
    meta: {"MetaTags":[{"Name":"description","Content":"Вся информация о партнерстве с TriSousa. Форма обратной связи по вопросам партнерства."}]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/partnership.vue")
  },
  {
    name: "restaurants-id",
    path: "/restaurants-details/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/restaurants/[id].vue")
  },
  {
    name: "restaurants-list",
    path: "/rest",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/restaurants/list.vue")
  },
  {
    name: "system-off",
    path: "/system/off",
    meta: {"HideHeader":true,"HideFooter":true},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/system/off.vue")
  },
  {
    name: "vacancy",
    path: "/vacancy",
    meta: {"MetaTags":[{"Name":"description","Content":"Ознакомьтесь с вакансиями, открытыми на данный момент в TriSousa (Мурманск). Вы можете отправить свое резюме через специальную форму."}]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/vacancy.vue")
  },
  {
    path: "/feedback",
    name: "feedback-default",
    redirect: "/feedback/1"
  },
  {
    name: "custom-page-0199b23f-35fe-4b9c-ad93-b1f800e9e657",
    path: "/bavaria",
    meta: {"ID":"0199b23f-35fe-4b9c-ad93-b1f800e9e657","HideHeader":false,"HideFooter":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/custom.vue")
  },
  {
    name: "group|2e4c4f9e-4ea3-49dc-b9e8-6726b7080501",
    path: "/menu/2e4c4f9e-4ea3-49dc-b9e8-6726b7080501",
    meta: {"ID":"2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","GroupID":"2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/2e4c4f9e-4ea3-49dc-b9e8-6726b7080501/all",
    name: "group|2e4c4f9e-4ea3-49dc-b9e8-6726b7080501|redirect",
    redirect: "/menu/2e4c4f9e-4ea3-49dc-b9e8-6726b7080501"
  },
  {
    name: "group|cbda5402-ca07-4583-bfa3-410e380f3e08",
    path: "/menu/cbda5402-ca07-4583-bfa3-410e380f3e08/all",
    meta: {"ID":"cbda5402-ca07-4583-bfa3-410e380f3e08","GroupID":"cbda5402-ca07-4583-bfa3-410e380f3e08","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|cbda5402-ca07-4583-bfa3-410e380f3e08",
    path: "/menu/cbda5402-ca07-4583-bfa3-410e380f3e08/all",
    meta: {"ID":"cbda5402-ca07-4583-bfa3-410e380f3e08","GroupID":"cbda5402-ca07-4583-bfa3-410e380f3e08","Title":"","IsCommonGroup":true,"MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/544108e9-c5b9-46e2-b148-42c3b70c298d",
    name: "group|544108e9-c5b9-46e2-b148-42c3b70c298d|redirect",
    redirect: "/product/74672abf-94bc-4faa-8043-f706023781e9"
  },
  {
    name: "group|64599942-da85-4d3c-9234-5d5319216105",
    path: "/menu/64599942-da85-4d3c-9234-5d5319216105",
    meta: {"ID":"64599942-da85-4d3c-9234-5d5319216105","GroupID":"64599942-da85-4d3c-9234-5d5319216105","Title":"","IsCommonGroup":false,"MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|7801b5bb-054e-4ce0-942d-d7831c24ac40",
    path: "/menu/7801b5bb-054e-4ce0-942d-d7831c24ac40",
    meta: {"ID":"7801b5bb-054e-4ce0-942d-d7831c24ac40","GroupID":"7801b5bb-054e-4ce0-942d-d7831c24ac40","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/7801b5bb-054e-4ce0-942d-d7831c24ac40/all",
    name: "group|7801b5bb-054e-4ce0-942d-d7831c24ac40|redirect",
    redirect: "/menu/7801b5bb-054e-4ce0-942d-d7831c24ac40"
  },
  {
    name: "group|73d8e7e8-a7d6-4cde-900b-feb2938a1fcb",
    path: "/menu/73d8e7e8-a7d6-4cde-900b-feb2938a1fcb",
    meta: {"ID":"73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","GroupID":"73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/73d8e7e8-a7d6-4cde-900b-feb2938a1fcb/all",
    name: "group|73d8e7e8-a7d6-4cde-900b-feb2938a1fcb|redirect",
    redirect: "/menu/73d8e7e8-a7d6-4cde-900b-feb2938a1fcb"
  },
  {
    name: "group|6dea82f5-01a7-4aa3-814d-92d580dd2885",
    path: "/menu/6dea82f5-01a7-4aa3-814d-92d580dd2885/all",
    meta: {"ID":"6dea82f5-01a7-4aa3-814d-92d580dd2885","GroupID":"6dea82f5-01a7-4aa3-814d-92d580dd2885","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|6dea82f5-01a7-4aa3-814d-92d580dd2885",
    path: "/menu/6dea82f5-01a7-4aa3-814d-92d580dd2885/all",
    meta: {"ID":"6dea82f5-01a7-4aa3-814d-92d580dd2885","GroupID":"6dea82f5-01a7-4aa3-814d-92d580dd2885","Title":"","IsCommonGroup":true,"MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/96375b6e-e482-4ef6-b721-a0402c1fada2",
    name: "group|96375b6e-e482-4ef6-b721-a0402c1fada2|redirect",
    redirect: "/product/91e45f4d-c1bd-4a30-ba46-bfa36dacb64c"
  },
  {
    name: "group|a44e5f09-3d43-467a-82a1-f1788946110c",
    path: "/menu/a44e5f09-3d43-467a-82a1-f1788946110c",
    meta: {"ID":"a44e5f09-3d43-467a-82a1-f1788946110c","GroupID":"a44e5f09-3d43-467a-82a1-f1788946110c","Title":"","IsCommonGroup":false,"MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|654b3dbf-24ce-4acd-8343-54dbb128223f",
    path: "/menu/654b3dbf-24ce-4acd-8343-54dbb128223f",
    meta: {"ID":"654b3dbf-24ce-4acd-8343-54dbb128223f","GroupID":"654b3dbf-24ce-4acd-8343-54dbb128223f","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/654b3dbf-24ce-4acd-8343-54dbb128223f/all",
    name: "group|654b3dbf-24ce-4acd-8343-54dbb128223f|redirect",
    redirect: "/menu/654b3dbf-24ce-4acd-8343-54dbb128223f"
  },
  {
    name: "group|5e7a99e0-ee8a-4d70-bf0c-1a7aeed4f00b",
    path: "/menu/5e7a99e0-ee8a-4d70-bf0c-1a7aeed4f00b/all",
    meta: {"ID":"5e7a99e0-ee8a-4d70-bf0c-1a7aeed4f00b","GroupID":"5e7a99e0-ee8a-4d70-bf0c-1a7aeed4f00b","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|5e7a99e0-ee8a-4d70-bf0c-1a7aeed4f00b",
    path: "/menu/5e7a99e0-ee8a-4d70-bf0c-1a7aeed4f00b/all",
    meta: {"ID":"5e7a99e0-ee8a-4d70-bf0c-1a7aeed4f00b","GroupID":"5e7a99e0-ee8a-4d70-bf0c-1a7aeed4f00b","Title":"","IsCommonGroup":true,"MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|33e8f98d-49a3-4d75-8076-e016770f1878",
    path: "/menu/33e8f98d-49a3-4d75-8076-e016770f1878",
    meta: {"ID":"33e8f98d-49a3-4d75-8076-e016770f1878","GroupID":"33e8f98d-49a3-4d75-8076-e016770f1878","Title":"","IsCommonGroup":false,"MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|a29506ab-edab-4619-811c-1df7731a48ba",
    path: "/menu/a29506ab-edab-4619-811c-1df7731a48ba",
    meta: {"ID":"a29506ab-edab-4619-811c-1df7731a48ba","GroupID":"a29506ab-edab-4619-811c-1df7731a48ba","Title":"","IsCommonGroup":false,"MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b",
    path: "/menu/f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b",
    meta: {"ID":"f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","GroupID":"f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","Title":"","IsCommonGroup":false,"MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    name: "group|5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3",
    path: "/menu/5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3",
    meta: {"ID":"5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","GroupID":"5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3/all",
    name: "group|5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3|redirect",
    redirect: "/menu/5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3"
  },
  {
    name: "group|53b9bd6b-ecab-409e-b3e8-22b81c702f83",
    path: "/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83",
    meta: {"ID":"53b9bd6b-ecab-409e-b3e8-22b81c702f83","GroupID":"53b9bd6b-ecab-409e-b3e8-22b81c702f83","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83/all",
    name: "group|53b9bd6b-ecab-409e-b3e8-22b81c702f83|redirect",
    redirect: "/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83"
  },
  {
    name: "group|35ffc5db-5f3c-44a5-bdfa-0922bfd20da6",
    path: "/menu/35ffc5db-5f3c-44a5-bdfa-0922bfd20da6",
    meta: {"ID":"35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","GroupID":"35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","Title":"","IsCommonGroup":false,"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/group/[id].vue")
  },
  {
    path: "/menu/35ffc5db-5f3c-44a5-bdfa-0922bfd20da6/all",
    name: "group|35ffc5db-5f3c-44a5-bdfa-0922bfd20da6|redirect",
    redirect: "/menu/35ffc5db-5f3c-44a5-bdfa-0922bfd20da6"
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0000a205-9e87-479b-988a-7f282fdf5a8c",
    path: "/product/0000a205-9e87-479b-988a-7f282fdf5a8c",
    meta: {"ID":"0000a205-9e87-479b-988a-7f282fdf5a8c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|db514044-cc74-4cb4-88c1-e0d3608fecb6|002a90a0-bf73-4d21-99ef-a2f7dd6371d1",
    path: "/product/002a90a0-bf73-4d21-99ef-a2f7dd6371d1",
    meta: {"ID":"002a90a0-bf73-4d21-99ef-a2f7dd6371d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|00332a2a-f36f-457b-b8b3-7552a79ec93f",
    path: "/product/00332a2a-f36f-457b-b8b3-7552a79ec93f",
    meta: {"ID":"00332a2a-f36f-457b-b8b3-7552a79ec93f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|004110fa-bcf2-4243-b21c-0c1cf92584c6",
    path: "/product/004110fa-bcf2-4243-b21c-0c1cf92584c6",
    meta: {"ID":"004110fa-bcf2-4243-b21c-0c1cf92584c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|004e6b0e-e9cb-470b-afc8-4252a312feaa",
    path: "/product/004e6b0e-e9cb-470b-afc8-4252a312feaa",
    meta: {"ID":"004e6b0e-e9cb-470b-afc8-4252a312feaa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|00614d89-5971-4c95-9eec-9c2bf9fae159",
    path: "/product/00614d89-5971-4c95-9eec-9c2bf9fae159",
    meta: {"ID":"00614d89-5971-4c95-9eec-9c2bf9fae159","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|00acfbb6-45b5-49f0-a4ba-62ee44d8c949",
    path: "/product/00acfbb6-45b5-49f0-a4ba-62ee44d8c949",
    meta: {"ID":"00acfbb6-45b5-49f0-a4ba-62ee44d8c949","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|00bad241-4f95-4a24-b5aa-97860146b529",
    path: "/product/00bad241-4f95-4a24-b5aa-97860146b529",
    meta: {"ID":"00bad241-4f95-4a24-b5aa-97860146b529","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|00d0a9aa-dcf7-4194-bb8a-fec6f5236add",
    path: "/product/00d0a9aa-dcf7-4194-bb8a-fec6f5236add",
    meta: {"ID":"00d0a9aa-dcf7-4194-bb8a-fec6f5236add","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|00d2ff0e-b25a-465c-8bad-332a1877038d",
    path: "/product/00d2ff0e-b25a-465c-8bad-332a1877038d",
    meta: {"ID":"00d2ff0e-b25a-465c-8bad-332a1877038d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|00e9bbe9-e01b-488c-bbc7-0d57e1733bb8",
    path: "/product/00e9bbe9-e01b-488c-bbc7-0d57e1733bb8",
    meta: {"ID":"00e9bbe9-e01b-488c-bbc7-0d57e1733bb8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|011824f0-be0b-40e7-b052-c16afbc299b7",
    path: "/product/011824f0-be0b-40e7-b052-c16afbc299b7",
    meta: {"ID":"011824f0-be0b-40e7-b052-c16afbc299b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|011aaada-a932-47a0-be76-7beecc294355",
    path: "/product/011aaada-a932-47a0-be76-7beecc294355",
    meta: {"ID":"011aaada-a932-47a0-be76-7beecc294355","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|011b7931-279c-4616-aa75-008d9ebd5380",
    path: "/product/011b7931-279c-4616-aa75-008d9ebd5380",
    meta: {"ID":"011b7931-279c-4616-aa75-008d9ebd5380","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|af1ac0fd-a494-4b35-ae24-edc1fe10a7e4|011c5c9c-1af3-478f-9cfe-4e0029040d0f",
    path: "/product/011c5c9c-1af3-478f-9cfe-4e0029040d0f",
    meta: {"ID":"011c5c9c-1af3-478f-9cfe-4e0029040d0f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|0161491d-8a05-4df7-87e5-cafdbde5c730",
    path: "/product/0161491d-8a05-4df7-87e5-cafdbde5c730",
    meta: {"ID":"0161491d-8a05-4df7-87e5-cafdbde5c730","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|01739989-1b7c-44d7-9bd2-f032f11843b5",
    path: "/product/01739989-1b7c-44d7-9bd2-f032f11843b5",
    meta: {"ID":"01739989-1b7c-44d7-9bd2-f032f11843b5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|0199b256-3382-4a44-bb41-6f7476b7a4bf",
    path: "/product/0199b256-3382-4a44-bb41-6f7476b7a4bf",
    meta: {"ID":"0199b256-3382-4a44-bb41-6f7476b7a4bf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|01bff5b7-400a-4565-9ec2-a563c182cfd0",
    path: "/product/01bff5b7-400a-4565-9ec2-a563c182cfd0",
    meta: {"ID":"01bff5b7-400a-4565-9ec2-a563c182cfd0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|01e8e901-a51b-4488-887f-f3a31c70ac77",
    path: "/product/01e8e901-a51b-4488-887f-f3a31c70ac77",
    meta: {"ID":"01e8e901-a51b-4488-887f-f3a31c70ac77","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|01f24015-d744-4437-862b-ae6b21d85e89",
    path: "/product/01f24015-d744-4437-862b-ae6b21d85e89",
    meta: {"ID":"01f24015-d744-4437-862b-ae6b21d85e89","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|01f26e6e-b208-4533-abc9-0cb6e34ce9d0",
    path: "/product/01f26e6e-b208-4533-abc9-0cb6e34ce9d0",
    meta: {"ID":"01f26e6e-b208-4533-abc9-0cb6e34ce9d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|021e1ebb-ebe0-40c9-b63e-dfdafa8cb2e4",
    path: "/product/021e1ebb-ebe0-40c9-b63e-dfdafa8cb2e4",
    meta: {"ID":"021e1ebb-ebe0-40c9-b63e-dfdafa8cb2e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|022f4283-0f95-415d-a0c2-29b839f6c52f",
    path: "/product/022f4283-0f95-415d-a0c2-29b839f6c52f",
    meta: {"ID":"022f4283-0f95-415d-a0c2-29b839f6c52f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|02440702-00b3-4ac1-af10-e652a7bc99e3",
    path: "/product/02440702-00b3-4ac1-af10-e652a7bc99e3",
    meta: {"ID":"02440702-00b3-4ac1-af10-e652a7bc99e3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|02467f83-aa34-47f7-9aa4-067fbbd80165",
    path: "/product/02467f83-aa34-47f7-9aa4-067fbbd80165",
    meta: {"ID":"02467f83-aa34-47f7-9aa4-067fbbd80165","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|026de347-8ee8-4ea1-99d0-8e8a9cc367da",
    path: "/product/026de347-8ee8-4ea1-99d0-8e8a9cc367da",
    meta: {"ID":"026de347-8ee8-4ea1-99d0-8e8a9cc367da","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|027cf2a3-0de0-4ce9-950e-d3d22b0c5fe2",
    path: "/product/027cf2a3-0de0-4ce9-950e-d3d22b0c5fe2",
    meta: {"ID":"027cf2a3-0de0-4ce9-950e-d3d22b0c5fe2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|027d79aa-a8a8-4e45-8f86-bb0d44066e37",
    path: "/product/027d79aa-a8a8-4e45-8f86-bb0d44066e37",
    meta: {"ID":"027d79aa-a8a8-4e45-8f86-bb0d44066e37","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|02a111c4-5dde-47ae-88c4-ec48f08ca1a1",
    path: "/product/02a111c4-5dde-47ae-88c4-ec48f08ca1a1",
    meta: {"ID":"02a111c4-5dde-47ae-88c4-ec48f08ca1a1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|02a907db-4ecc-4bd4-876e-4a6622cab4e7",
    path: "/product/02a907db-4ecc-4bd4-876e-4a6622cab4e7",
    meta: {"ID":"02a907db-4ecc-4bd4-876e-4a6622cab4e7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|02af8d9c-a460-4da7-8019-5dced9c6036f",
    path: "/product/02af8d9c-a460-4da7-8019-5dced9c6036f",
    meta: {"ID":"02af8d9c-a460-4da7-8019-5dced9c6036f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|02b106c2-97e9-4b05-9111-0eb9c6834a31",
    path: "/product/02b106c2-97e9-4b05-9111-0eb9c6834a31",
    meta: {"ID":"02b106c2-97e9-4b05-9111-0eb9c6834a31","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|02c9473c-412d-4356-b961-96b636c0fedd",
    path: "/product/02c9473c-412d-4356-b961-96b636c0fedd",
    meta: {"ID":"02c9473c-412d-4356-b961-96b636c0fedd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|02df8724-25b7-4469-8be5-db7c8b5a3385",
    path: "/product/02df8724-25b7-4469-8be5-db7c8b5a3385",
    meta: {"ID":"02df8724-25b7-4469-8be5-db7c8b5a3385","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|02e5212d-678e-4ce8-8624-67b465779de2",
    path: "/product/02e5212d-678e-4ce8-8624-67b465779de2",
    meta: {"ID":"02e5212d-678e-4ce8-8624-67b465779de2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|02fbefa9-be93-4abf-8eba-5b942a3c6495",
    path: "/product/02fbefa9-be93-4abf-8eba-5b942a3c6495",
    meta: {"ID":"02fbefa9-be93-4abf-8eba-5b942a3c6495","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|02fd1699-edf8-42ab-84bf-05324b80acae",
    path: "/product/02fd1699-edf8-42ab-84bf-05324b80acae",
    meta: {"ID":"02fd1699-edf8-42ab-84bf-05324b80acae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0313bb26-b088-4649-b8be-d74d044e2f27",
    path: "/product/0313bb26-b088-4649-b8be-d74d044e2f27",
    meta: {"ID":"0313bb26-b088-4649-b8be-d74d044e2f27","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4349f183-f876-4668-9110-f50347c57ef2|03672701-e838-4297-952a-794cd07fe1ef",
    path: "/product/03672701-e838-4297-952a-794cd07fe1ef",
    meta: {"ID":"03672701-e838-4297-952a-794cd07fe1ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|036977fd-dc31-4143-99a5-4b51eefa9f45",
    path: "/product/036977fd-dc31-4143-99a5-4b51eefa9f45",
    meta: {"ID":"036977fd-dc31-4143-99a5-4b51eefa9f45","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|036ccb2c-6e7f-4568-a024-71c577b2390a",
    path: "/product/036ccb2c-6e7f-4568-a024-71c577b2390a",
    meta: {"ID":"036ccb2c-6e7f-4568-a024-71c577b2390a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|036eef48-dd6a-43ca-8e4f-ece1a9e63c69",
    path: "/product/036eef48-dd6a-43ca-8e4f-ece1a9e63c69",
    meta: {"ID":"036eef48-dd6a-43ca-8e4f-ece1a9e63c69","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|037c27a9-ace2-4ae0-bf2f-4674f7bf9963",
    path: "/product/037c27a9-ace2-4ae0-bf2f-4674f7bf9963",
    meta: {"ID":"037c27a9-ace2-4ae0-bf2f-4674f7bf9963","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|038748bf-f608-401c-a05e-9495fe5c30d7",
    path: "/product/038748bf-f608-401c-a05e-9495fe5c30d7",
    meta: {"ID":"038748bf-f608-401c-a05e-9495fe5c30d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|038a2e9a-7272-46fe-adcc-bcdb66bcc575",
    path: "/product/038a2e9a-7272-46fe-adcc-bcdb66bcc575",
    meta: {"ID":"038a2e9a-7272-46fe-adcc-bcdb66bcc575","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a29506ab-edab-4619-811c-1df7731a48ba|03a25a51-1512-4c97-a5a9-cffebbb781f7",
    path: "/product/03a25a51-1512-4c97-a5a9-cffebbb781f7",
    meta: {"ID":"03a25a51-1512-4c97-a5a9-cffebbb781f7","Title":"","GroupID":"a29506ab-edab-4619-811c-1df7731a48ba","GroupTitle":"Суши","GroupLink":"/menu/a29506ab-edab-4619-811c-1df7731a48ba","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|03b22e21-e453-4fa8-ace7-56cd60c27f11",
    path: "/product/03b22e21-e453-4fa8-ace7-56cd60c27f11",
    meta: {"ID":"03b22e21-e453-4fa8-ace7-56cd60c27f11","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|69d265a0-61df-4c8b-9631-e727b9d9fbe4|03ef33a8-3ba3-464e-970e-2d52a350634e",
    path: "/product/03ef33a8-3ba3-464e-970e-2d52a350634e",
    meta: {"ID":"03ef33a8-3ba3-464e-970e-2d52a350634e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|03fb843c-a5a1-48f0-bdb6-fc6f9ca50fcf",
    path: "/product/03fb843c-a5a1-48f0-bdb6-fc6f9ca50fcf",
    meta: {"ID":"03fb843c-a5a1-48f0-bdb6-fc6f9ca50fcf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|04165f6e-2a17-4abb-9afd-415ed3209903",
    path: "/product/04165f6e-2a17-4abb-9afd-415ed3209903",
    meta: {"ID":"04165f6e-2a17-4abb-9afd-415ed3209903","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|041878c0-5838-4f98-aaf3-7796b75856cb",
    path: "/product/041878c0-5838-4f98-aaf3-7796b75856cb",
    meta: {"ID":"041878c0-5838-4f98-aaf3-7796b75856cb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0457528c-d2a1-464f-8dbb-2c5b8b99762a",
    path: "/product/0457528c-d2a1-464f-8dbb-2c5b8b99762a",
    meta: {"ID":"0457528c-d2a1-464f-8dbb-2c5b8b99762a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|04693214-4529-4cd5-b35d-7d0fff5187a3",
    path: "/product/04693214-4529-4cd5-b35d-7d0fff5187a3",
    meta: {"ID":"04693214-4529-4cd5-b35d-7d0fff5187a3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a44e5f09-3d43-467a-82a1-f1788946110c|04798107-91a7-4892-9e57-707b26ca346f",
    path: "/product/04798107-91a7-4892-9e57-707b26ca346f",
    meta: {"ID":"04798107-91a7-4892-9e57-707b26ca346f","Title":"","GroupID":"a44e5f09-3d43-467a-82a1-f1788946110c","GroupTitle":"Вода","GroupLink":"/menu/a44e5f09-3d43-467a-82a1-f1788946110c","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|048f3774-a6b4-407a-8786-baf589c79cca",
    path: "/product/048f3774-a6b4-407a-8786-baf589c79cca",
    meta: {"ID":"048f3774-a6b4-407a-8786-baf589c79cca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|0497e93f-cfe1-48ee-bb81-1863dcb9344a",
    path: "/product/0497e93f-cfe1-48ee-bb81-1863dcb9344a",
    meta: {"ID":"0497e93f-cfe1-48ee-bb81-1863dcb9344a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|04a400c3-a0f3-49e3-a800-01a89aa3e07e",
    path: "/product/04a400c3-a0f3-49e3-a800-01a89aa3e07e",
    meta: {"ID":"04a400c3-a0f3-49e3-a800-01a89aa3e07e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|04b7ba8b-df68-424c-b839-67b7e326da5c",
    path: "/product/04b7ba8b-df68-424c-b839-67b7e326da5c",
    meta: {"ID":"04b7ba8b-df68-424c-b839-67b7e326da5c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|04cbed6f-9260-4265-be9c-9947de3fe817",
    path: "/product/04cbed6f-9260-4265-be9c-9947de3fe817",
    meta: {"ID":"04cbed6f-9260-4265-be9c-9947de3fe817","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|04de07df-a879-443c-8c55-2ef9e414f4f3",
    path: "/product/04de07df-a879-443c-8c55-2ef9e414f4f3",
    meta: {"ID":"04de07df-a879-443c-8c55-2ef9e414f4f3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|04e79a0a-259d-4a68-a498-1377fa98f301",
    path: "/product/04e79a0a-259d-4a68-a498-1377fa98f301",
    meta: {"ID":"04e79a0a-259d-4a68-a498-1377fa98f301","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|04f692f1-b356-4914-9f60-7dbadf91d9f1",
    path: "/product/04f692f1-b356-4914-9f60-7dbadf91d9f1",
    meta: {"ID":"04f692f1-b356-4914-9f60-7dbadf91d9f1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|04fa84eb-989e-4342-aab0-124f25cdf53e",
    path: "/product/04fa84eb-989e-4342-aab0-124f25cdf53e",
    meta: {"ID":"04fa84eb-989e-4342-aab0-124f25cdf53e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0506cea9-72f3-474e-80d8-1e7dcf18c097",
    path: "/product/0506cea9-72f3-474e-80d8-1e7dcf18c097",
    meta: {"ID":"0506cea9-72f3-474e-80d8-1e7dcf18c097","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0515fdad-b389-4738-9213-6c9fd560000f",
    path: "/product/0515fdad-b389-4738-9213-6c9fd560000f",
    meta: {"ID":"0515fdad-b389-4738-9213-6c9fd560000f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|051946b3-c3bc-4335-935a-7596fe54ea9a",
    path: "/product/051946b3-c3bc-4335-935a-7596fe54ea9a",
    meta: {"ID":"051946b3-c3bc-4335-935a-7596fe54ea9a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|053aa0f1-beaa-468c-9375-6aa603e7f9da",
    path: "/product/053aa0f1-beaa-468c-9375-6aa603e7f9da",
    meta: {"ID":"053aa0f1-beaa-468c-9375-6aa603e7f9da","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0557bdc7-3cab-49c6-9c14-b10e37e1d7b0",
    path: "/product/0557bdc7-3cab-49c6-9c14-b10e37e1d7b0",
    meta: {"ID":"0557bdc7-3cab-49c6-9c14-b10e37e1d7b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0569d84f-eb07-4b5a-814c-cfe688397311",
    path: "/product/0569d84f-eb07-4b5a-814c-cfe688397311",
    meta: {"ID":"0569d84f-eb07-4b5a-814c-cfe688397311","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|056ccd98-8d54-4e63-adcb-6e7f3d2d2ad3",
    path: "/product/056ccd98-8d54-4e63-adcb-6e7f3d2d2ad3",
    meta: {"ID":"056ccd98-8d54-4e63-adcb-6e7f3d2d2ad3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|057f6cf3-62f2-4736-b699-89c6e5ede1d0",
    path: "/product/057f6cf3-62f2-4736-b699-89c6e5ede1d0",
    meta: {"ID":"057f6cf3-62f2-4736-b699-89c6e5ede1d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|05968bf8-3563-4a03-91cc-744f6245ebfb",
    path: "/product/05968bf8-3563-4a03-91cc-744f6245ebfb",
    meta: {"ID":"05968bf8-3563-4a03-91cc-744f6245ebfb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|05ad5488-e7d9-4377-a044-22847ab80a4a",
    path: "/product/05ad5488-e7d9-4377-a044-22847ab80a4a",
    meta: {"ID":"05ad5488-e7d9-4377-a044-22847ab80a4a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|05b8f56b-0529-4f4b-89a7-c729e1ec589b",
    path: "/product/05b8f56b-0529-4f4b-89a7-c729e1ec589b",
    meta: {"ID":"05b8f56b-0529-4f4b-89a7-c729e1ec589b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|05c0b6a4-77bc-4d07-bf54-3d00326d5911",
    path: "/product/05c0b6a4-77bc-4d07-bf54-3d00326d5911",
    meta: {"ID":"05c0b6a4-77bc-4d07-bf54-3d00326d5911","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|05c1faf9-1992-4002-ae4b-dbca695671aa",
    path: "/product/05c1faf9-1992-4002-ae4b-dbca695671aa",
    meta: {"ID":"05c1faf9-1992-4002-ae4b-dbca695671aa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|05cb4b5f-2e22-4b53-8259-3c42f650242b",
    path: "/product/05cb4b5f-2e22-4b53-8259-3c42f650242b",
    meta: {"ID":"05cb4b5f-2e22-4b53-8259-3c42f650242b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|05d614a2-ba23-4f18-9319-c2630169dc2e",
    path: "/product/05d614a2-ba23-4f18-9319-c2630169dc2e",
    meta: {"ID":"05d614a2-ba23-4f18-9319-c2630169dc2e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|05e651aa-ed56-4c88-82bb-d34cab86e1c6",
    path: "/product/05e651aa-ed56-4c88-82bb-d34cab86e1c6",
    meta: {"ID":"05e651aa-ed56-4c88-82bb-d34cab86e1c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|68b13f59-5b5e-4f71-90ae-eadb72690750|060ad126-7255-4e1f-ac23-4fc3791379bd",
    path: "/product/060ad126-7255-4e1f-ac23-4fc3791379bd",
    meta: {"ID":"060ad126-7255-4e1f-ac23-4fc3791379bd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|06192241-75ad-4bb8-bc0e-c344623dcb92",
    path: "/product/06192241-75ad-4bb8-bc0e-c344623dcb92",
    meta: {"ID":"06192241-75ad-4bb8-bc0e-c344623dcb92","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|06371af8-8ad0-4434-a39d-7bafd19833b7",
    path: "/product/06371af8-8ad0-4434-a39d-7bafd19833b7",
    meta: {"ID":"06371af8-8ad0-4434-a39d-7bafd19833b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|0642787e-2980-414e-bb26-053c0d69f336",
    path: "/product/0642787e-2980-414e-bb26-053c0d69f336",
    meta: {"ID":"0642787e-2980-414e-bb26-053c0d69f336","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|066db165-134c-4d4c-abe8-43ff08e3ab7f",
    path: "/product/066db165-134c-4d4c-abe8-43ff08e3ab7f",
    meta: {"ID":"066db165-134c-4d4c-abe8-43ff08e3ab7f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0671ff73-7801-4be6-9bde-d35ed5965863",
    path: "/product/0671ff73-7801-4be6-9bde-d35ed5965863",
    meta: {"ID":"0671ff73-7801-4be6-9bde-d35ed5965863","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|067355b7-4730-43d9-8035-6c23f9a18108",
    path: "/product/067355b7-4730-43d9-8035-6c23f9a18108",
    meta: {"ID":"067355b7-4730-43d9-8035-6c23f9a18108","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|06add97c-3879-4e11-9f50-bff877bbded1",
    path: "/product/06add97c-3879-4e11-9f50-bff877bbded1",
    meta: {"ID":"06add97c-3879-4e11-9f50-bff877bbded1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7bdadf21-f387-4551-8ae2-d2b5ae64736e|06d382b0-30db-454b-af49-cc2055834a74",
    path: "/product/06d382b0-30db-454b-af49-cc2055834a74",
    meta: {"ID":"06d382b0-30db-454b-af49-cc2055834a74","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|06d44447-6c9c-4037-abb0-302250aa1d85",
    path: "/product/06d44447-6c9c-4037-abb0-302250aa1d85",
    meta: {"ID":"06d44447-6c9c-4037-abb0-302250aa1d85","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|06e18745-e16f-469b-82cb-a13017e92613",
    path: "/product/06e18745-e16f-469b-82cb-a13017e92613",
    meta: {"ID":"06e18745-e16f-469b-82cb-a13017e92613","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|967d3966-c5f3-487e-a085-8e9a8fe858bf|06f47e80-bf5c-42e2-bef0-c57a82248a94",
    path: "/product/06f47e80-bf5c-42e2-bef0-c57a82248a94",
    meta: {"ID":"06f47e80-bf5c-42e2-bef0-c57a82248a94","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0709ea37-2553-4085-9bbc-1e627b2e23df",
    path: "/product/0709ea37-2553-4085-9bbc-1e627b2e23df",
    meta: {"ID":"0709ea37-2553-4085-9bbc-1e627b2e23df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|07135730-6a18-4090-9e7b-73cf07ebb341",
    path: "/product/07135730-6a18-4090-9e7b-73cf07ebb341",
    meta: {"ID":"07135730-6a18-4090-9e7b-73cf07ebb341","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0719e04b-d4db-4908-9e42-90364892d4c2",
    path: "/product/0719e04b-d4db-4908-9e42-90364892d4c2",
    meta: {"ID":"0719e04b-d4db-4908-9e42-90364892d4c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|07264e57-bac2-4821-ac7c-62961760ecb9",
    path: "/product/07264e57-bac2-4821-ac7c-62961760ecb9",
    meta: {"ID":"07264e57-bac2-4821-ac7c-62961760ecb9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|07339ccb-7003-4ba4-8dd8-7fc08e6e31cf",
    path: "/product/07339ccb-7003-4ba4-8dd8-7fc08e6e31cf",
    meta: {"ID":"07339ccb-7003-4ba4-8dd8-7fc08e6e31cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|0751b170-8a77-4e78-813f-16feb6514f9b",
    path: "/product/0751b170-8a77-4e78-813f-16feb6514f9b",
    meta: {"ID":"0751b170-8a77-4e78-813f-16feb6514f9b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6b95603-7a93-4283-b39d-ece699ed1469|075dcb54-1777-4a9d-9b6b-f6dfb1e3044a",
    path: "/product/075dcb54-1777-4a9d-9b6b-f6dfb1e3044a",
    meta: {"ID":"075dcb54-1777-4a9d-9b6b-f6dfb1e3044a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0763f119-64e7-4df6-a36e-d18951fa501a",
    path: "/product/0763f119-64e7-4df6-a36e-d18951fa501a",
    meta: {"ID":"0763f119-64e7-4df6-a36e-d18951fa501a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|07845bb9-a3c0-4523-81f8-52cc83776330",
    path: "/product/07845bb9-a3c0-4523-81f8-52cc83776330",
    meta: {"ID":"07845bb9-a3c0-4523-81f8-52cc83776330","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|07a52f1f-fe59-45ef-bbcf-f4101e734d0d",
    path: "/product/07a52f1f-fe59-45ef-bbcf-f4101e734d0d",
    meta: {"ID":"07a52f1f-fe59-45ef-bbcf-f4101e734d0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|07b8a6df-7771-4f53-935b-c0d69a67e0ee",
    path: "/product/07b8a6df-7771-4f53-935b-c0d69a67e0ee",
    meta: {"ID":"07b8a6df-7771-4f53-935b-c0d69a67e0ee","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|07c30023-7244-4ddc-86c1-c328d8a5df80",
    path: "/product/07c30023-7244-4ddc-86c1-c328d8a5df80",
    meta: {"ID":"07c30023-7244-4ddc-86c1-c328d8a5df80","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|07f559cb-e5d0-451d-8762-93b092ef943d",
    path: "/product/07f559cb-e5d0-451d-8762-93b092ef943d",
    meta: {"ID":"07f559cb-e5d0-451d-8762-93b092ef943d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|07f9e496-5d67-4b0d-b43b-11eff6483a44",
    path: "/product/07f9e496-5d67-4b0d-b43b-11eff6483a44",
    meta: {"ID":"07f9e496-5d67-4b0d-b43b-11eff6483a44","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|07fb102c-a614-4603-b937-c702185cbb59",
    path: "/product/07fb102c-a614-4603-b937-c702185cbb59",
    meta: {"ID":"07fb102c-a614-4603-b937-c702185cbb59","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|654b3dbf-24ce-4acd-8343-54dbb128223f|080bab63-0c64-40ce-ae05-ff6c4306f90e",
    path: "/product/080bab63-0c64-40ce-ae05-ff6c4306f90e",
    meta: {"ID":"080bab63-0c64-40ce-ae05-ff6c4306f90e","Title":"","GroupID":"654b3dbf-24ce-4acd-8343-54dbb128223f","GroupTitle":"Супы","GroupLink":"/menu/654b3dbf-24ce-4acd-8343-54dbb128223f","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|0829736e-02ba-4075-ae7b-ecc9b09f1b06",
    path: "/product/0829736e-02ba-4075-ae7b-ecc9b09f1b06",
    meta: {"ID":"0829736e-02ba-4075-ae7b-ecc9b09f1b06","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|08383e3d-506d-4876-b04e-2b4a93717597",
    path: "/product/08383e3d-506d-4876-b04e-2b4a93717597",
    meta: {"ID":"08383e3d-506d-4876-b04e-2b4a93717597","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b|0841997e-19f8-43fb-b939-66e9d32447c0",
    path: "/product/0841997e-19f8-43fb-b939-66e9d32447c0",
    meta: {"ID":"0841997e-19f8-43fb-b939-66e9d32447c0","Title":"","GroupID":"f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","GroupTitle":"Роллы","GroupLink":"/menu/f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|087df3d1-a1b0-402d-84fc-8f916cc9b20b",
    path: "/product/087df3d1-a1b0-402d-84fc-8f916cc9b20b",
    meta: {"ID":"087df3d1-a1b0-402d-84fc-8f916cc9b20b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0893b756-f2ce-4fbf-aa44-e832810e9382",
    path: "/product/0893b756-f2ce-4fbf-aa44-e832810e9382",
    meta: {"ID":"0893b756-f2ce-4fbf-aa44-e832810e9382","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|08a55095-a37d-454b-a6d4-4fb49eac5bd3",
    path: "/product/08a55095-a37d-454b-a6d4-4fb49eac5bd3",
    meta: {"ID":"08a55095-a37d-454b-a6d4-4fb49eac5bd3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|08abf9a5-0759-4c7d-a6c1-4ba2bb919186",
    path: "/product/08abf9a5-0759-4c7d-a6c1-4ba2bb919186",
    meta: {"ID":"08abf9a5-0759-4c7d-a6c1-4ba2bb919186","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|08b7af84-3415-4bf9-9a7b-31dd81a652c2",
    path: "/product/08b7af84-3415-4bf9-9a7b-31dd81a652c2",
    meta: {"ID":"08b7af84-3415-4bf9-9a7b-31dd81a652c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|08ce59fd-1ae5-4be0-a912-149a57cacdb5",
    path: "/product/08ce59fd-1ae5-4be0-a912-149a57cacdb5",
    meta: {"ID":"08ce59fd-1ae5-4be0-a912-149a57cacdb5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|08f01c67-7116-47c5-8212-d4915dd27104",
    path: "/product/08f01c67-7116-47c5-8212-d4915dd27104",
    meta: {"ID":"08f01c67-7116-47c5-8212-d4915dd27104","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|08f6fc6a-cfa2-42f0-ba4d-13d3701acf5b",
    path: "/product/08f6fc6a-cfa2-42f0-ba4d-13d3701acf5b",
    meta: {"ID":"08f6fc6a-cfa2-42f0-ba4d-13d3701acf5b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0913b54f-8fc2-45f7-88b0-c33087f38e3f",
    path: "/product/0913b54f-8fc2-45f7-88b0-c33087f38e3f",
    meta: {"ID":"0913b54f-8fc2-45f7-88b0-c33087f38e3f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|092fa3a1-d457-4080-ab0a-fadc838b163b",
    path: "/product/092fa3a1-d457-4080-ab0a-fadc838b163b",
    meta: {"ID":"092fa3a1-d457-4080-ab0a-fadc838b163b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0947d816-0d0f-413f-8569-a2cee93e6213",
    path: "/product/0947d816-0d0f-413f-8569-a2cee93e6213",
    meta: {"ID":"0947d816-0d0f-413f-8569-a2cee93e6213","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|094865ba-a6b7-4fee-931d-ad6c9bfb68fd",
    path: "/product/094865ba-a6b7-4fee-931d-ad6c9bfb68fd",
    meta: {"ID":"094865ba-a6b7-4fee-931d-ad6c9bfb68fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|0955dde6-a2e4-4894-adbe-1516e806a96b",
    path: "/product/0955dde6-a2e4-4894-adbe-1516e806a96b",
    meta: {"ID":"0955dde6-a2e4-4894-adbe-1516e806a96b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|096eb59d-c23c-4415-a3ee-29cfecaa9474",
    path: "/product/096eb59d-c23c-4415-a3ee-29cfecaa9474",
    meta: {"ID":"096eb59d-c23c-4415-a3ee-29cfecaa9474","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0982cc49-cd80-4440-adc3-d8a67a3c60d0",
    path: "/product/0982cc49-cd80-4440-adc3-d8a67a3c60d0",
    meta: {"ID":"0982cc49-cd80-4440-adc3-d8a67a3c60d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|098eb547-fef5-4edb-9a7f-e570b5a333c3",
    path: "/product/098eb547-fef5-4edb-9a7f-e570b5a333c3",
    meta: {"ID":"098eb547-fef5-4edb-9a7f-e570b5a333c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|09c68778-ad8f-402f-a2b9-78681be69fd4",
    path: "/product/09c68778-ad8f-402f-a2b9-78681be69fd4",
    meta: {"ID":"09c68778-ad8f-402f-a2b9-78681be69fd4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|09d9c44f-9d9f-4b21-ab74-f4c0b61f8723",
    path: "/product/09d9c44f-9d9f-4b21-ab74-f4c0b61f8723",
    meta: {"ID":"09d9c44f-9d9f-4b21-ab74-f4c0b61f8723","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|09f41546-2aaa-4877-94de-25a54a91364e",
    path: "/product/09f41546-2aaa-4877-94de-25a54a91364e",
    meta: {"ID":"09f41546-2aaa-4877-94de-25a54a91364e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|0a017d06-6de7-4b30-93fa-783c4bda60df",
    path: "/product/0a017d06-6de7-4b30-93fa-783c4bda60df",
    meta: {"ID":"0a017d06-6de7-4b30-93fa-783c4bda60df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0a040db1-ca46-4c3f-8cac-3df514b376b0",
    path: "/product/0a040db1-ca46-4c3f-8cac-3df514b376b0",
    meta: {"ID":"0a040db1-ca46-4c3f-8cac-3df514b376b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0a20dd32-823b-4240-9b18-e6cda44f959f",
    path: "/product/0a20dd32-823b-4240-9b18-e6cda44f959f",
    meta: {"ID":"0a20dd32-823b-4240-9b18-e6cda44f959f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0a220ef5-3e7c-4fb2-bab3-dc4af4c2f316",
    path: "/product/0a220ef5-3e7c-4fb2-bab3-dc4af4c2f316",
    meta: {"ID":"0a220ef5-3e7c-4fb2-bab3-dc4af4c2f316","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|0ab02d80-4bae-40b8-9e8a-76be473fc650",
    path: "/product/0ab02d80-4bae-40b8-9e8a-76be473fc650",
    meta: {"ID":"0ab02d80-4bae-40b8-9e8a-76be473fc650","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|0ab1553e-836d-4a12-a1a6-49b220f47b20",
    path: "/product/0ab1553e-836d-4a12-a1a6-49b220f47b20",
    meta: {"ID":"0ab1553e-836d-4a12-a1a6-49b220f47b20","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0abe741c-308a-4b61-b9aa-b8f062e816c4",
    path: "/product/0abe741c-308a-4b61-b9aa-b8f062e816c4",
    meta: {"ID":"0abe741c-308a-4b61-b9aa-b8f062e816c4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0ada7929-bddf-4aac-9055-fcbac0654023",
    path: "/product/0ada7929-bddf-4aac-9055-fcbac0654023",
    meta: {"ID":"0ada7929-bddf-4aac-9055-fcbac0654023","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|0ae10374-465c-4419-9008-af6cdc565f18",
    path: "/product/0ae10374-465c-4419-9008-af6cdc565f18",
    meta: {"ID":"0ae10374-465c-4419-9008-af6cdc565f18","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0b1b0f9e-29af-49cb-9a26-15be426c3d01",
    path: "/product/0b1b0f9e-29af-49cb-9a26-15be426c3d01",
    meta: {"ID":"0b1b0f9e-29af-49cb-9a26-15be426c3d01","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|0b1bc986-8fb7-4c2c-a64c-85b81518f894",
    path: "/product/0b1bc986-8fb7-4c2c-a64c-85b81518f894",
    meta: {"ID":"0b1bc986-8fb7-4c2c-a64c-85b81518f894","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|0b206dec-7ca1-4258-9352-5dd8ee68e804",
    path: "/product/0b206dec-7ca1-4258-9352-5dd8ee68e804",
    meta: {"ID":"0b206dec-7ca1-4258-9352-5dd8ee68e804","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|0b247a75-eff2-4806-871e-415f571bcd53",
    path: "/product/0b247a75-eff2-4806-871e-415f571bcd53",
    meta: {"ID":"0b247a75-eff2-4806-871e-415f571bcd53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0b2afdba-b11e-453f-9951-a77c2174e655",
    path: "/product/0b2afdba-b11e-453f-9951-a77c2174e655",
    meta: {"ID":"0b2afdba-b11e-453f-9951-a77c2174e655","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0b931e03-f929-40e0-820c-ec91631c3914",
    path: "/product/0b931e03-f929-40e0-820c-ec91631c3914",
    meta: {"ID":"0b931e03-f929-40e0-820c-ec91631c3914","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a04f5a3-7f1f-4863-9c6a-170e7e97ef1b|0bc5a21a-6265-4361-b5ce-9160b7437f16",
    path: "/product/0bc5a21a-6265-4361-b5ce-9160b7437f16",
    meta: {"ID":"0bc5a21a-6265-4361-b5ce-9160b7437f16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0bd029fa-499c-4f86-9a99-a5c5807d5564",
    path: "/product/0bd029fa-499c-4f86-9a99-a5c5807d5564",
    meta: {"ID":"0bd029fa-499c-4f86-9a99-a5c5807d5564","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0c0b79e3-767c-48e0-a8f4-7fa67ef0c042",
    path: "/product/0c0b79e3-767c-48e0-a8f4-7fa67ef0c042",
    meta: {"ID":"0c0b79e3-767c-48e0-a8f4-7fa67ef0c042","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|0c18fa07-2da3-4442-b787-9adb21882322",
    path: "/product/0c18fa07-2da3-4442-b787-9adb21882322",
    meta: {"ID":"0c18fa07-2da3-4442-b787-9adb21882322","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0c42ebfc-c1d0-4978-80d6-3921eaf66b80",
    path: "/product/0c42ebfc-c1d0-4978-80d6-3921eaf66b80",
    meta: {"ID":"0c42ebfc-c1d0-4978-80d6-3921eaf66b80","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|0c4ec82a-7273-4e49-9b4f-a2caf87d4678",
    path: "/product/0c4ec82a-7273-4e49-9b4f-a2caf87d4678",
    meta: {"ID":"0c4ec82a-7273-4e49-9b4f-a2caf87d4678","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|762d5eb6-8211-47c9-922f-0c67a6b9fcff|0c506608-5155-4970-a0ac-906e4959fb7b",
    path: "/product/0c506608-5155-4970-a0ac-906e4959fb7b",
    meta: {"ID":"0c506608-5155-4970-a0ac-906e4959fb7b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0c783d9c-5423-4350-974d-7c11099d54b4",
    path: "/product/0c783d9c-5423-4350-974d-7c11099d54b4",
    meta: {"ID":"0c783d9c-5423-4350-974d-7c11099d54b4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|0ca6b94f-cf98-4072-af09-980e231c937c",
    path: "/product/0ca6b94f-cf98-4072-af09-980e231c937c",
    meta: {"ID":"0ca6b94f-cf98-4072-af09-980e231c937c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0cc34502-69d6-4f6b-8dd4-d73325094a56",
    path: "/product/0cc34502-69d6-4f6b-8dd4-d73325094a56",
    meta: {"ID":"0cc34502-69d6-4f6b-8dd4-d73325094a56","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|0cdaf5c7-8138-4b04-a90c-26ec710234e5",
    path: "/product/0cdaf5c7-8138-4b04-a90c-26ec710234e5",
    meta: {"ID":"0cdaf5c7-8138-4b04-a90c-26ec710234e5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0ceea898-66fe-40a2-9577-aa62b9ab530b",
    path: "/product/0ceea898-66fe-40a2-9577-aa62b9ab530b",
    meta: {"ID":"0ceea898-66fe-40a2-9577-aa62b9ab530b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|0d27a5fc-fe59-4e50-b674-8b6c3f714361",
    path: "/product/0d27a5fc-fe59-4e50-b674-8b6c3f714361",
    meta: {"ID":"0d27a5fc-fe59-4e50-b674-8b6c3f714361","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0d2864c0-192f-4a95-b283-2482fc0e823f",
    path: "/product/0d2864c0-192f-4a95-b283-2482fc0e823f",
    meta: {"ID":"0d2864c0-192f-4a95-b283-2482fc0e823f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|0d2f068c-8a83-44d4-a394-2d4242e46e07",
    path: "/product/0d2f068c-8a83-44d4-a394-2d4242e46e07",
    meta: {"ID":"0d2f068c-8a83-44d4-a394-2d4242e46e07","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|0d33442f-f423-4874-aa64-e5bb1ffefaaa",
    path: "/product/0d33442f-f423-4874-aa64-e5bb1ffefaaa",
    meta: {"ID":"0d33442f-f423-4874-aa64-e5bb1ffefaaa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0d53a67b-854f-4060-8726-36f941d7fc7f",
    path: "/product/0d53a67b-854f-4060-8726-36f941d7fc7f",
    meta: {"ID":"0d53a67b-854f-4060-8726-36f941d7fc7f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|0d700dc2-1672-46a3-a2a7-cb3e1ac93b22",
    path: "/product/0d700dc2-1672-46a3-a2a7-cb3e1ac93b22",
    meta: {"ID":"0d700dc2-1672-46a3-a2a7-cb3e1ac93b22","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|0d9a4761-df34-4dab-bd6b-ff5d0f44abcf",
    path: "/product/0d9a4761-df34-4dab-bd6b-ff5d0f44abcf",
    meta: {"ID":"0d9a4761-df34-4dab-bd6b-ff5d0f44abcf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|0dc06c32-4167-4d11-ae9e-2bb20f9c4862",
    path: "/product/0dc06c32-4167-4d11-ae9e-2bb20f9c4862",
    meta: {"ID":"0dc06c32-4167-4d11-ae9e-2bb20f9c4862","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|0de22b28-ed4b-4987-b039-fcda89159e58",
    path: "/product/0de22b28-ed4b-4987-b039-fcda89159e58",
    meta: {"ID":"0de22b28-ed4b-4987-b039-fcda89159e58","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|0de3813d-910f-4c65-82cd-33d324488f5e",
    path: "/product/0de3813d-910f-4c65-82cd-33d324488f5e",
    meta: {"ID":"0de3813d-910f-4c65-82cd-33d324488f5e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0dea6aba-1b2f-4f77-8ef3-cad9fb3bcd71",
    path: "/product/0dea6aba-1b2f-4f77-8ef3-cad9fb3bcd71",
    meta: {"ID":"0dea6aba-1b2f-4f77-8ef3-cad9fb3bcd71","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0e0025dc-ff05-4454-a768-9fd8245c4b1d",
    path: "/product/0e0025dc-ff05-4454-a768-9fd8245c4b1d",
    meta: {"ID":"0e0025dc-ff05-4454-a768-9fd8245c4b1d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|57b1574f-a331-4e33-aedc-684887d5530e|0e0864e2-83f7-4678-8269-0c6eb4607d17",
    path: "/product/0e0864e2-83f7-4678-8269-0c6eb4607d17",
    meta: {"ID":"0e0864e2-83f7-4678-8269-0c6eb4607d17","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0e23c5e2-75bb-40b2-97cb-63552fd2062d",
    path: "/product/0e23c5e2-75bb-40b2-97cb-63552fd2062d",
    meta: {"ID":"0e23c5e2-75bb-40b2-97cb-63552fd2062d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0e477664-f96c-4237-93b7-8deb2f4b6df3",
    path: "/product/0e477664-f96c-4237-93b7-8deb2f4b6df3",
    meta: {"ID":"0e477664-f96c-4237-93b7-8deb2f4b6df3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|0e7aaefa-cbd2-4f13-a5fc-8d9add066ce1",
    path: "/product/0e7aaefa-cbd2-4f13-a5fc-8d9add066ce1",
    meta: {"ID":"0e7aaefa-cbd2-4f13-a5fc-8d9add066ce1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0e8512dc-3e12-47f4-b51f-751d59e6fd35",
    path: "/product/0e8512dc-3e12-47f4-b51f-751d59e6fd35",
    meta: {"ID":"0e8512dc-3e12-47f4-b51f-751d59e6fd35","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0eb4f2df-1df2-40fa-ac22-6956980c4cad",
    path: "/product/0eb4f2df-1df2-40fa-ac22-6956980c4cad",
    meta: {"ID":"0eb4f2df-1df2-40fa-ac22-6956980c4cad","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|68b13f59-5b5e-4f71-90ae-eadb72690750|0eb9552d-fcc6-4b0f-b2c2-6fa7b4f662cf",
    path: "/product/0eb9552d-fcc6-4b0f-b2c2-6fa7b4f662cf",
    meta: {"ID":"0eb9552d-fcc6-4b0f-b2c2-6fa7b4f662cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|0ed384a6-ac80-4e37-a64a-e657bf182960",
    path: "/product/0ed384a6-ac80-4e37-a64a-e657bf182960",
    meta: {"ID":"0ed384a6-ac80-4e37-a64a-e657bf182960","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|0eebc9ba-1f7f-4726-8ace-a4501c8248cf",
    path: "/product/0eebc9ba-1f7f-4726-8ace-a4501c8248cf",
    meta: {"ID":"0eebc9ba-1f7f-4726-8ace-a4501c8248cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|0eec0625-a82f-48f4-a7ce-823bcbce3cbb",
    path: "/product/0eec0625-a82f-48f4-a7ce-823bcbce3cbb",
    meta: {"ID":"0eec0625-a82f-48f4-a7ce-823bcbce3cbb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|0ef90958-ffe6-4172-8e9b-c18446064c96",
    path: "/product/0ef90958-ffe6-4172-8e9b-c18446064c96",
    meta: {"ID":"0ef90958-ffe6-4172-8e9b-c18446064c96","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|af1ac0fd-a494-4b35-ae24-edc1fe10a7e4|0ef95a91-3be2-49ae-b925-4368c0dee62c",
    path: "/product/0ef95a91-3be2-49ae-b925-4368c0dee62c",
    meta: {"ID":"0ef95a91-3be2-49ae-b925-4368c0dee62c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6fa548ca-4b6a-4806-83da-74148d7bfb94|0f03b1e4-ac55-4a8b-9226-25049235a98a",
    path: "/product/0f03b1e4-ac55-4a8b-9226-25049235a98a",
    meta: {"ID":"0f03b1e4-ac55-4a8b-9226-25049235a98a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0f14a198-0dad-45de-8027-75d001b8003f",
    path: "/product/0f14a198-0dad-45de-8027-75d001b8003f",
    meta: {"ID":"0f14a198-0dad-45de-8027-75d001b8003f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|0f18026f-f6dc-4002-ad9e-f78320c9ef35",
    path: "/product/0f18026f-f6dc-4002-ad9e-f78320c9ef35",
    meta: {"ID":"0f18026f-f6dc-4002-ad9e-f78320c9ef35","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|0f1e5255-8628-4aa1-92bc-b8a32cd59ac6",
    path: "/product/0f1e5255-8628-4aa1-92bc-b8a32cd59ac6",
    meta: {"ID":"0f1e5255-8628-4aa1-92bc-b8a32cd59ac6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|0f1f7c1a-4be1-44c6-86e1-37c9e0cb0eee",
    path: "/product/0f1f7c1a-4be1-44c6-86e1-37c9e0cb0eee",
    meta: {"ID":"0f1f7c1a-4be1-44c6-86e1-37c9e0cb0eee","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|0f57b89c-9920-4a55-89f9-394b938de68c",
    path: "/product/0f57b89c-9920-4a55-89f9-394b938de68c",
    meta: {"ID":"0f57b89c-9920-4a55-89f9-394b938de68c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|0f5a9abd-c90e-4f63-805d-b43998537024",
    path: "/product/0f5a9abd-c90e-4f63-805d-b43998537024",
    meta: {"ID":"0f5a9abd-c90e-4f63-805d-b43998537024","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|0f61c331-8a05-4358-ab62-1c8afbcfc620",
    path: "/product/0f61c331-8a05-4358-ab62-1c8afbcfc620",
    meta: {"ID":"0f61c331-8a05-4358-ab62-1c8afbcfc620","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|0f78a56c-c4e7-4b09-a1b7-dbd205698fb6",
    path: "/product/0f78a56c-c4e7-4b09-a1b7-dbd205698fb6",
    meta: {"ID":"0f78a56c-c4e7-4b09-a1b7-dbd205698fb6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0f7e15da-f194-4b84-a415-1c0c9f084c67",
    path: "/product/0f7e15da-f194-4b84-a415-1c0c9f084c67",
    meta: {"ID":"0f7e15da-f194-4b84-a415-1c0c9f084c67","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|0f9b46c3-132a-4bfa-aee0-14d41c25f7e8",
    path: "/product/0f9b46c3-132a-4bfa-aee0-14d41c25f7e8",
    meta: {"ID":"0f9b46c3-132a-4bfa-aee0-14d41c25f7e8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|0f9e5707-c18f-4bc0-bcf5-4f5430b18f43",
    path: "/product/0f9e5707-c18f-4bc0-bcf5-4f5430b18f43",
    meta: {"ID":"0f9e5707-c18f-4bc0-bcf5-4f5430b18f43","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|0fa13c15-6819-411f-8988-4576037220b1",
    path: "/product/0fa13c15-6819-411f-8988-4576037220b1",
    meta: {"ID":"0fa13c15-6819-411f-8988-4576037220b1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3|0fe3cfbd-b156-4264-a936-15f73bdae927",
    path: "/product/0fe3cfbd-b156-4264-a936-15f73bdae927",
    meta: {"ID":"0fe3cfbd-b156-4264-a936-15f73bdae927","Title":"","GroupID":"5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","GroupTitle":"Салаты","GroupLink":"/menu/5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|0fec74d0-8163-4dab-a645-2bf534f79ad8",
    path: "/product/0fec74d0-8163-4dab-a645-2bf534f79ad8",
    meta: {"ID":"0fec74d0-8163-4dab-a645-2bf534f79ad8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|100b21d3-5553-436d-9e00-cd591a86dc0b",
    path: "/product/100b21d3-5553-436d-9e00-cd591a86dc0b",
    meta: {"ID":"100b21d3-5553-436d-9e00-cd591a86dc0b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|101b65b7-30da-4a6b-91e9-b33e0629eadb",
    path: "/product/101b65b7-30da-4a6b-91e9-b33e0629eadb",
    meta: {"ID":"101b65b7-30da-4a6b-91e9-b33e0629eadb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|102028a0-95a3-4637-9906-81967249a09d",
    path: "/product/102028a0-95a3-4637-9906-81967249a09d",
    meta: {"ID":"102028a0-95a3-4637-9906-81967249a09d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|104348b9-4932-44b2-b0b5-291fe9ded565",
    path: "/product/104348b9-4932-44b2-b0b5-291fe9ded565",
    meta: {"ID":"104348b9-4932-44b2-b0b5-291fe9ded565","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|108afc04-8c74-4176-b0e1-fd4ecb444479",
    path: "/product/108afc04-8c74-4176-b0e1-fd4ecb444479",
    meta: {"ID":"108afc04-8c74-4176-b0e1-fd4ecb444479","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|109ab995-c823-4ae1-b185-44cd61f52981",
    path: "/product/109ab995-c823-4ae1-b185-44cd61f52981",
    meta: {"ID":"109ab995-c823-4ae1-b185-44cd61f52981","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|10a00867-dbd3-4b15-a166-210a71a7e2f7",
    path: "/product/10a00867-dbd3-4b15-a166-210a71a7e2f7",
    meta: {"ID":"10a00867-dbd3-4b15-a166-210a71a7e2f7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|10a7f535-7f7e-49af-b7f1-7a602db8d380",
    path: "/product/10a7f535-7f7e-49af-b7f1-7a602db8d380",
    meta: {"ID":"10a7f535-7f7e-49af-b7f1-7a602db8d380","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|10b3ea67-650a-4d47-9030-3182fc867e68",
    path: "/product/10b3ea67-650a-4d47-9030-3182fc867e68",
    meta: {"ID":"10b3ea67-650a-4d47-9030-3182fc867e68","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|10c7fc72-3d99-4704-ba20-2546f263f2b2",
    path: "/product/10c7fc72-3d99-4704-ba20-2546f263f2b2",
    meta: {"ID":"10c7fc72-3d99-4704-ba20-2546f263f2b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1103cc0c-4bbe-4117-b70a-064c2e822809",
    path: "/product/1103cc0c-4bbe-4117-b70a-064c2e822809",
    meta: {"ID":"1103cc0c-4bbe-4117-b70a-064c2e822809","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|11122391-4285-4e2d-b485-6107a1f277b1",
    path: "/product/11122391-4285-4e2d-b485-6107a1f277b1",
    meta: {"ID":"11122391-4285-4e2d-b485-6107a1f277b1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|111c0faf-0278-4b35-83aa-2fe115157d3a",
    path: "/product/111c0faf-0278-4b35-83aa-2fe115157d3a",
    meta: {"ID":"111c0faf-0278-4b35-83aa-2fe115157d3a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1144a08c-0ee6-4d57-b7b1-bfaf8dc30fb6",
    path: "/product/1144a08c-0ee6-4d57-b7b1-bfaf8dc30fb6",
    meta: {"ID":"1144a08c-0ee6-4d57-b7b1-bfaf8dc30fb6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1148e5e1-dae5-4afa-afc4-1cd1c9cb2ad4",
    path: "/product/1148e5e1-dae5-4afa-afc4-1cd1c9cb2ad4",
    meta: {"ID":"1148e5e1-dae5-4afa-afc4-1cd1c9cb2ad4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|116a3258-83cc-466a-80b9-2dd8f9b179c4",
    path: "/product/116a3258-83cc-466a-80b9-2dd8f9b179c4",
    meta: {"ID":"116a3258-83cc-466a-80b9-2dd8f9b179c4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|117ca3f9-398a-47cd-befc-25ef2b2e43af",
    path: "/product/117ca3f9-398a-47cd-befc-25ef2b2e43af",
    meta: {"ID":"117ca3f9-398a-47cd-befc-25ef2b2e43af","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|11800eb7-4a40-4009-b3a0-80ad5bbe9932",
    path: "/product/11800eb7-4a40-4009-b3a0-80ad5bbe9932",
    meta: {"ID":"11800eb7-4a40-4009-b3a0-80ad5bbe9932","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1187e03a-06c5-47dd-a2aa-09a380c9d234",
    path: "/product/1187e03a-06c5-47dd-a2aa-09a380c9d234",
    meta: {"ID":"1187e03a-06c5-47dd-a2aa-09a380c9d234","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|119358f6-e0ce-4774-ae5f-a30359bf69b4",
    path: "/product/119358f6-e0ce-4774-ae5f-a30359bf69b4",
    meta: {"ID":"119358f6-e0ce-4774-ae5f-a30359bf69b4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|119f00a4-91bf-4222-a063-470318208baa",
    path: "/product/119f00a4-91bf-4222-a063-470318208baa",
    meta: {"ID":"119f00a4-91bf-4222-a063-470318208baa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|11ea56e3-583b-4142-bf74-0ccdd3adc3bf",
    path: "/product/11ea56e3-583b-4142-bf74-0ccdd3adc3bf",
    meta: {"ID":"11ea56e3-583b-4142-bf74-0ccdd3adc3bf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|11fb17cc-4dae-4ec8-88aa-b68700b9de1f",
    path: "/product/11fb17cc-4dae-4ec8-88aa-b68700b9de1f",
    meta: {"ID":"11fb17cc-4dae-4ec8-88aa-b68700b9de1f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|12122714-486b-4b29-b810-b17bd5aa8275",
    path: "/product/12122714-486b-4b29-b810-b17bd5aa8275",
    meta: {"ID":"12122714-486b-4b29-b810-b17bd5aa8275","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4626112c-772c-43a4-83b9-fc5d6a1a2597|121a7e95-fb2e-4521-aec2-5cc64454473d",
    path: "/product/121a7e95-fb2e-4521-aec2-5cc64454473d",
    meta: {"ID":"121a7e95-fb2e-4521-aec2-5cc64454473d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|121d0062-40d6-40a8-8801-a90f6ad40e12",
    path: "/product/121d0062-40d6-40a8-8801-a90f6ad40e12",
    meta: {"ID":"121d0062-40d6-40a8-8801-a90f6ad40e12","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|123644b3-e6c4-49d4-a8b3-e2da461a5562",
    path: "/product/123644b3-e6c4-49d4-a8b3-e2da461a5562",
    meta: {"ID":"123644b3-e6c4-49d4-a8b3-e2da461a5562","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1279fd45-94ab-4005-8cfe-917544f1a7df",
    path: "/product/1279fd45-94ab-4005-8cfe-917544f1a7df",
    meta: {"ID":"1279fd45-94ab-4005-8cfe-917544f1a7df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1283b644-1c4a-48af-b154-e085ed6eda85",
    path: "/product/1283b644-1c4a-48af-b154-e085ed6eda85",
    meta: {"ID":"1283b644-1c4a-48af-b154-e085ed6eda85","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b|12930d47-33de-4e7b-b5de-dff1e27a0cc9",
    path: "/product/12930d47-33de-4e7b-b5de-dff1e27a0cc9",
    meta: {"ID":"12930d47-33de-4e7b-b5de-dff1e27a0cc9","Title":"","GroupID":"f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","GroupTitle":"Роллы","GroupLink":"/menu/f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|12958425-0d6d-432c-ad8e-96fc00b2445b",
    path: "/product/12958425-0d6d-432c-ad8e-96fc00b2445b",
    meta: {"ID":"12958425-0d6d-432c-ad8e-96fc00b2445b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|12c89574-2ecb-4a5a-899a-3c26414f664c",
    path: "/product/12c89574-2ecb-4a5a-899a-3c26414f664c",
    meta: {"ID":"12c89574-2ecb-4a5a-899a-3c26414f664c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|12d7674c-384f-4238-ac3d-483100586b3c",
    path: "/product/12d7674c-384f-4238-ac3d-483100586b3c",
    meta: {"ID":"12d7674c-384f-4238-ac3d-483100586b3c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|12f0e8f6-a2ea-431a-9528-64be0dc0be54",
    path: "/product/12f0e8f6-a2ea-431a-9528-64be0dc0be54",
    meta: {"ID":"12f0e8f6-a2ea-431a-9528-64be0dc0be54","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|12f1e4bf-6261-4b98-aa4d-06e547a51960",
    path: "/product/12f1e4bf-6261-4b98-aa4d-06e547a51960",
    meta: {"ID":"12f1e4bf-6261-4b98-aa4d-06e547a51960","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|130f3dc8-788e-407c-b656-8e4d8b22b044",
    path: "/product/130f3dc8-788e-407c-b656-8e4d8b22b044",
    meta: {"ID":"130f3dc8-788e-407c-b656-8e4d8b22b044","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|68b13f59-5b5e-4f71-90ae-eadb72690750|1310ae37-4fa6-4eea-a6de-af351af57dd4",
    path: "/product/1310ae37-4fa6-4eea-a6de-af351af57dd4",
    meta: {"ID":"1310ae37-4fa6-4eea-a6de-af351af57dd4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|133814be-46af-452e-919b-95947bf52ea8",
    path: "/product/133814be-46af-452e-919b-95947bf52ea8",
    meta: {"ID":"133814be-46af-452e-919b-95947bf52ea8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|13464c50-45d2-4f3e-85b9-75f4c2d4ba73",
    path: "/product/13464c50-45d2-4f3e-85b9-75f4c2d4ba73",
    meta: {"ID":"13464c50-45d2-4f3e-85b9-75f4c2d4ba73","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|1348ec26-1299-4b0e-b15b-f4b0274edf01",
    path: "/product/1348ec26-1299-4b0e-b15b-f4b0274edf01",
    meta: {"ID":"1348ec26-1299-4b0e-b15b-f4b0274edf01","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7b8f0d1b-6e98-4d6a-be01-03b913fcdb44|13494e8f-2d63-447e-9472-563793d65513",
    path: "/product/13494e8f-2d63-447e-9472-563793d65513",
    meta: {"ID":"13494e8f-2d63-447e-9472-563793d65513","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|13515e1e-803a-49d5-8145-24a40ca7d6b6",
    path: "/product/13515e1e-803a-49d5-8145-24a40ca7d6b6",
    meta: {"ID":"13515e1e-803a-49d5-8145-24a40ca7d6b6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|1354ac8a-bff5-401b-b9b4-ba86af51f42f",
    path: "/product/1354ac8a-bff5-401b-b9b4-ba86af51f42f",
    meta: {"ID":"1354ac8a-bff5-401b-b9b4-ba86af51f42f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1357d88e-9509-466b-bf8c-bbe8b9ff5714",
    path: "/product/1357d88e-9509-466b-bf8c-bbe8b9ff5714",
    meta: {"ID":"1357d88e-9509-466b-bf8c-bbe8b9ff5714","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|135cd422-6fe9-4aa1-830c-f26032d2940f",
    path: "/product/135cd422-6fe9-4aa1-830c-f26032d2940f",
    meta: {"ID":"135cd422-6fe9-4aa1-830c-f26032d2940f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1361c982-9a57-4218-b5a2-fb141e8f5cdc",
    path: "/product/1361c982-9a57-4218-b5a2-fb141e8f5cdc",
    meta: {"ID":"1361c982-9a57-4218-b5a2-fb141e8f5cdc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|13648b00-9d03-4ae8-8d63-4e3f4782f13b",
    path: "/product/13648b00-9d03-4ae8-8d63-4e3f4782f13b",
    meta: {"ID":"13648b00-9d03-4ae8-8d63-4e3f4782f13b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6650365f-b288-4159-8f19-1721fee02f23|136c2ffc-051d-4979-ba99-b8b037c50e19",
    path: "/product/136c2ffc-051d-4979-ba99-b8b037c50e19",
    meta: {"ID":"136c2ffc-051d-4979-ba99-b8b037c50e19","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|137cc479-0bcd-443e-831c-2708d7418350",
    path: "/product/137cc479-0bcd-443e-831c-2708d7418350",
    meta: {"ID":"137cc479-0bcd-443e-831c-2708d7418350","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|138baf38-746a-4a85-91e2-093c72a6211e",
    path: "/product/138baf38-746a-4a85-91e2-093c72a6211e",
    meta: {"ID":"138baf38-746a-4a85-91e2-093c72a6211e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0686e42-5664-44b8-b850-26f555406842|13a3f1d7-7613-4432-b985-695dac2b0786",
    path: "/product/13a3f1d7-7613-4432-b985-695dac2b0786",
    meta: {"ID":"13a3f1d7-7613-4432-b985-695dac2b0786","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|13b4c6ae-deb9-4c88-b43d-ec1a45d304c3",
    path: "/product/13b4c6ae-deb9-4c88-b43d-ec1a45d304c3",
    meta: {"ID":"13b4c6ae-deb9-4c88-b43d-ec1a45d304c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|13c863b2-a213-43ea-8006-76787dd15ca3",
    path: "/product/13c863b2-a213-43ea-8006-76787dd15ca3",
    meta: {"ID":"13c863b2-a213-43ea-8006-76787dd15ca3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|13ddf52a-7a1f-4336-9ae7-db4b52b45863",
    path: "/product/13ddf52a-7a1f-4336-9ae7-db4b52b45863",
    meta: {"ID":"13ddf52a-7a1f-4336-9ae7-db4b52b45863","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|13e1c877-cfbf-454a-a339-429edcd6c7cc",
    path: "/product/13e1c877-cfbf-454a-a339-429edcd6c7cc",
    meta: {"ID":"13e1c877-cfbf-454a-a339-429edcd6c7cc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|35ffc5db-5f3c-44a5-bdfa-0922bfd20da6|13f30115-93a1-4a55-bcee-fdae7558bbcc",
    path: "/product/13f30115-93a1-4a55-bcee-fdae7558bbcc",
    meta: {"ID":"13f30115-93a1-4a55-bcee-fdae7558bbcc","Title":"","GroupID":"35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","GroupTitle":"Поке и вок","GroupLink":"/menu/35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|13fbfb41-c7da-473b-8ecb-6696881e9029",
    path: "/product/13fbfb41-c7da-473b-8ecb-6696881e9029",
    meta: {"ID":"13fbfb41-c7da-473b-8ecb-6696881e9029","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|1457a0ad-4f79-418b-b49c-fcea3ab889d7",
    path: "/product/1457a0ad-4f79-418b-b49c-fcea3ab889d7",
    meta: {"ID":"1457a0ad-4f79-418b-b49c-fcea3ab889d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|14581b82-eb6d-4784-9d9e-1cfc8a37bcb3",
    path: "/product/14581b82-eb6d-4784-9d9e-1cfc8a37bcb3",
    meta: {"ID":"14581b82-eb6d-4784-9d9e-1cfc8a37bcb3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|145ab1fb-07c6-4ec4-9d24-125126d22d1e",
    path: "/product/145ab1fb-07c6-4ec4-9d24-125126d22d1e",
    meta: {"ID":"145ab1fb-07c6-4ec4-9d24-125126d22d1e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|14871f32-fc44-4f7f-a982-c25ce188a38e",
    path: "/product/14871f32-fc44-4f7f-a982-c25ce188a38e",
    meta: {"ID":"14871f32-fc44-4f7f-a982-c25ce188a38e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|14980c21-6e03-4e95-a3b5-398e29513b43",
    path: "/product/14980c21-6e03-4e95-a3b5-398e29513b43",
    meta: {"ID":"14980c21-6e03-4e95-a3b5-398e29513b43","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|149b41cc-b859-45b7-95a1-360fc2bbfcde",
    path: "/product/149b41cc-b859-45b7-95a1-360fc2bbfcde",
    meta: {"ID":"149b41cc-b859-45b7-95a1-360fc2bbfcde","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|149dc17f-4f4e-484e-bc74-46a934981300",
    path: "/product/149dc17f-4f4e-484e-bc74-46a934981300",
    meta: {"ID":"149dc17f-4f4e-484e-bc74-46a934981300","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|14b6918b-6d2a-4015-94f3-b6dc163fd99a",
    path: "/product/14b6918b-6d2a-4015-94f3-b6dc163fd99a",
    meta: {"ID":"14b6918b-6d2a-4015-94f3-b6dc163fd99a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|14c5863a-e13e-4b4b-bf63-8e76004ac5ab",
    path: "/product/14c5863a-e13e-4b4b-bf63-8e76004ac5ab",
    meta: {"ID":"14c5863a-e13e-4b4b-bf63-8e76004ac5ab","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|14e4abdc-6152-4060-a11c-db367148abd2",
    path: "/product/14e4abdc-6152-4060-a11c-db367148abd2",
    meta: {"ID":"14e4abdc-6152-4060-a11c-db367148abd2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|14ea6da1-fb3d-48e3-91f8-db1a020a8d30",
    path: "/product/14ea6da1-fb3d-48e3-91f8-db1a020a8d30",
    meta: {"ID":"14ea6da1-fb3d-48e3-91f8-db1a020a8d30","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|64599942-da85-4d3c-9234-5d5319216105|14eb7a2f-7969-4a74-8937-947dccf2b4da",
    path: "/product/14eb7a2f-7969-4a74-8937-947dccf2b4da",
    meta: {"ID":"14eb7a2f-7969-4a74-8937-947dccf2b4da","Title":"","GroupID":"64599942-da85-4d3c-9234-5d5319216105","GroupTitle":"Римская пицца","GroupLink":"/menu/64599942-da85-4d3c-9234-5d5319216105","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|14fab7f1-f298-4993-a090-c90f266cfbef",
    path: "/product/14fab7f1-f298-4993-a090-c90f266cfbef",
    meta: {"ID":"14fab7f1-f298-4993-a090-c90f266cfbef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6650365f-b288-4159-8f19-1721fee02f23|14fc186a-755c-4dfa-b663-1ca490de1db1",
    path: "/product/14fc186a-755c-4dfa-b663-1ca490de1db1",
    meta: {"ID":"14fc186a-755c-4dfa-b663-1ca490de1db1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1518fa3d-4051-4d9f-bf93-ef8adac65397",
    path: "/product/1518fa3d-4051-4d9f-bf93-ef8adac65397",
    meta: {"ID":"1518fa3d-4051-4d9f-bf93-ef8adac65397","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|151fb13e-2734-4c9f-84e9-9601885f9e69",
    path: "/product/151fb13e-2734-4c9f-84e9-9601885f9e69",
    meta: {"ID":"151fb13e-2734-4c9f-84e9-9601885f9e69","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1522f329-1438-465b-a935-7ef3bb0f31c2",
    path: "/product/1522f329-1438-465b-a935-7ef3bb0f31c2",
    meta: {"ID":"1522f329-1438-465b-a935-7ef3bb0f31c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|153f68e8-62ca-46c0-a0f9-2dfa090d9f88",
    path: "/product/153f68e8-62ca-46c0-a0f9-2dfa090d9f88",
    meta: {"ID":"153f68e8-62ca-46c0-a0f9-2dfa090d9f88","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|15426cc6-2a2b-41d1-8e23-a7f808042ff0",
    path: "/product/15426cc6-2a2b-41d1-8e23-a7f808042ff0",
    meta: {"ID":"15426cc6-2a2b-41d1-8e23-a7f808042ff0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|15497746-bf76-4bab-8d28-d7d4ab502192",
    path: "/product/15497746-bf76-4bab-8d28-d7d4ab502192",
    meta: {"ID":"15497746-bf76-4bab-8d28-d7d4ab502192","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|156b1fd2-1c03-4212-a4e4-261a24afd276",
    path: "/product/156b1fd2-1c03-4212-a4e4-261a24afd276",
    meta: {"ID":"156b1fd2-1c03-4212-a4e4-261a24afd276","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1588d7ed-ae25-4727-9665-2e9589d58fb8",
    path: "/product/1588d7ed-ae25-4727-9665-2e9589d58fb8",
    meta: {"ID":"1588d7ed-ae25-4727-9665-2e9589d58fb8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|15c175d4-103a-4329-adf8-0a75db4e47b5",
    path: "/product/15c175d4-103a-4329-adf8-0a75db4e47b5",
    meta: {"ID":"15c175d4-103a-4329-adf8-0a75db4e47b5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a29506ab-edab-4619-811c-1df7731a48ba|15ebe422-dc34-4a4c-9845-79f35ca99497",
    path: "/product/15ebe422-dc34-4a4c-9845-79f35ca99497",
    meta: {"ID":"15ebe422-dc34-4a4c-9845-79f35ca99497","Title":"","GroupID":"a29506ab-edab-4619-811c-1df7731a48ba","GroupTitle":"Суши","GroupLink":"/menu/a29506ab-edab-4619-811c-1df7731a48ba","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|15fb274c-4d90-4d10-b736-b73840e6105d",
    path: "/product/15fb274c-4d90-4d10-b736-b73840e6105d",
    meta: {"ID":"15fb274c-4d90-4d10-b736-b73840e6105d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6b95603-7a93-4283-b39d-ece699ed1469|16073af0-829d-4701-985d-6b7f37bbb6fa",
    path: "/product/16073af0-829d-4701-985d-6b7f37bbb6fa",
    meta: {"ID":"16073af0-829d-4701-985d-6b7f37bbb6fa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|16283d3a-947c-4072-ace3-33789af8cc57",
    path: "/product/16283d3a-947c-4072-ace3-33789af8cc57",
    meta: {"ID":"16283d3a-947c-4072-ace3-33789af8cc57","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|1635402a-e6ed-4ecd-80d1-2aaa0cf886ce",
    path: "/product/1635402a-e6ed-4ecd-80d1-2aaa0cf886ce",
    meta: {"ID":"1635402a-e6ed-4ecd-80d1-2aaa0cf886ce","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|163a5ade-5c8b-4f6d-829d-b9216e71638c",
    path: "/product/163a5ade-5c8b-4f6d-829d-b9216e71638c",
    meta: {"ID":"163a5ade-5c8b-4f6d-829d-b9216e71638c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|165ccec3-b079-4ba6-8da9-d40d52c92a41",
    path: "/product/165ccec3-b079-4ba6-8da9-d40d52c92a41",
    meta: {"ID":"165ccec3-b079-4ba6-8da9-d40d52c92a41","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|166b12f9-e7cf-476f-8594-be64e2923cd1",
    path: "/product/166b12f9-e7cf-476f-8594-be64e2923cd1",
    meta: {"ID":"166b12f9-e7cf-476f-8594-be64e2923cd1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|167a36d5-b6b4-4551-a6f5-34fd29a45c95",
    path: "/product/167a36d5-b6b4-4551-a6f5-34fd29a45c95",
    meta: {"ID":"167a36d5-b6b4-4551-a6f5-34fd29a45c95","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|167b270b-0a01-427a-89f8-1fdfe4a7c2bd",
    path: "/product/167b270b-0a01-427a-89f8-1fdfe4a7c2bd",
    meta: {"ID":"167b270b-0a01-427a-89f8-1fdfe4a7c2bd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|16818c39-5f5b-43f2-a4c8-a70a3d6d2561",
    path: "/product/16818c39-5f5b-43f2-a4c8-a70a3d6d2561",
    meta: {"ID":"16818c39-5f5b-43f2-a4c8-a70a3d6d2561","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|16e420eb-ca55-4b6e-b299-55757fb58863",
    path: "/product/16e420eb-ca55-4b6e-b299-55757fb58863",
    meta: {"ID":"16e420eb-ca55-4b6e-b299-55757fb58863","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|174fd59b-5733-4e56-9ec3-a1fd6dafd92d",
    path: "/product/174fd59b-5733-4e56-9ec3-a1fd6dafd92d",
    meta: {"ID":"174fd59b-5733-4e56-9ec3-a1fd6dafd92d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|176011a4-28f4-4a1d-b209-0e05f8a81853",
    path: "/product/176011a4-28f4-4a1d-b209-0e05f8a81853",
    meta: {"ID":"176011a4-28f4-4a1d-b209-0e05f8a81853","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1774c212-8a75-4c8c-aa41-1e4ed001a1de",
    path: "/product/1774c212-8a75-4c8c-aa41-1e4ed001a1de",
    meta: {"ID":"1774c212-8a75-4c8c-aa41-1e4ed001a1de","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|35ffc5db-5f3c-44a5-bdfa-0922bfd20da6|17849b0d-9f15-4cd7-903b-5b03fbc3fa31",
    path: "/product/17849b0d-9f15-4cd7-903b-5b03fbc3fa31",
    meta: {"ID":"17849b0d-9f15-4cd7-903b-5b03fbc3fa31","Title":"","GroupID":"35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","GroupTitle":"Поке и вок","GroupLink":"/menu/35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|178856af-9bee-44b3-b35a-81dbeb2e9cb6",
    path: "/product/178856af-9bee-44b3-b35a-81dbeb2e9cb6",
    meta: {"ID":"178856af-9bee-44b3-b35a-81dbeb2e9cb6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|178e41b5-27cc-456a-b06a-8a5d3c4e0a42",
    path: "/product/178e41b5-27cc-456a-b06a-8a5d3c4e0a42",
    meta: {"ID":"178e41b5-27cc-456a-b06a-8a5d3c4e0a42","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|17b383ca-45e4-4682-81a1-38a6efde3a83",
    path: "/product/17b383ca-45e4-4682-81a1-38a6efde3a83",
    meta: {"ID":"17b383ca-45e4-4682-81a1-38a6efde3a83","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|17c0617d-0ff4-432d-a072-cad91ec25f4b",
    path: "/product/17c0617d-0ff4-432d-a072-cad91ec25f4b",
    meta: {"ID":"17c0617d-0ff4-432d-a072-cad91ec25f4b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|17e10a2e-fc03-49c4-99d1-758d3ba7da97",
    path: "/product/17e10a2e-fc03-49c4-99d1-758d3ba7da97",
    meta: {"ID":"17e10a2e-fc03-49c4-99d1-758d3ba7da97","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|17f74e52-4143-406f-8165-6252e112c919",
    path: "/product/17f74e52-4143-406f-8165-6252e112c919",
    meta: {"ID":"17f74e52-4143-406f-8165-6252e112c919","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca1c41-f8fc-4cd3-9788-4fe61c89225d|180a7651-506b-485f-a51f-692bf37c9d07",
    path: "/product/180a7651-506b-485f-a51f-692bf37c9d07",
    meta: {"ID":"180a7651-506b-485f-a51f-692bf37c9d07","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|18120d2b-c4a8-45d4-aa91-54522f7c3157",
    path: "/product/18120d2b-c4a8-45d4-aa91-54522f7c3157",
    meta: {"ID":"18120d2b-c4a8-45d4-aa91-54522f7c3157","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3c06d685-8171-454b-920a-f3a76b0a4226|182a4551-875f-4b53-be40-95c5c424058a",
    path: "/product/182a4551-875f-4b53-be40-95c5c424058a",
    meta: {"ID":"182a4551-875f-4b53-be40-95c5c424058a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1836c414-276b-49dc-84cc-fb4ad58b24ab",
    path: "/product/1836c414-276b-49dc-84cc-fb4ad58b24ab",
    meta: {"ID":"1836c414-276b-49dc-84cc-fb4ad58b24ab","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|183e4710-b24e-4a85-a6b7-712f9b1f9bf0",
    path: "/product/183e4710-b24e-4a85-a6b7-712f9b1f9bf0",
    meta: {"ID":"183e4710-b24e-4a85-a6b7-712f9b1f9bf0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|184b17a5-c0a6-4f88-96d8-e68c80c64eea",
    path: "/product/184b17a5-c0a6-4f88-96d8-e68c80c64eea",
    meta: {"ID":"184b17a5-c0a6-4f88-96d8-e68c80c64eea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|185e8735-462a-409b-bcb6-6458c351ca56",
    path: "/product/185e8735-462a-409b-bcb6-6458c351ca56",
    meta: {"ID":"185e8735-462a-409b-bcb6-6458c351ca56","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|1863a2cc-2a8b-4cb8-9598-9c4048a766f9",
    path: "/product/1863a2cc-2a8b-4cb8-9598-9c4048a766f9",
    meta: {"ID":"1863a2cc-2a8b-4cb8-9598-9c4048a766f9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|18646fb5-3c0a-4db8-a04a-c37a213d4e29",
    path: "/product/18646fb5-3c0a-4db8-a04a-c37a213d4e29",
    meta: {"ID":"18646fb5-3c0a-4db8-a04a-c37a213d4e29","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|187d7b3b-c727-479d-8122-05eae003f33a",
    path: "/product/187d7b3b-c727-479d-8122-05eae003f33a",
    meta: {"ID":"187d7b3b-c727-479d-8122-05eae003f33a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|188437a7-57df-4543-b869-905916af0a8b",
    path: "/product/188437a7-57df-4543-b869-905916af0a8b",
    meta: {"ID":"188437a7-57df-4543-b869-905916af0a8b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2e4c4f9e-4ea3-49dc-b9e8-6726b7080501|18922c10-b5a3-491a-9a8d-06d930e359cd",
    path: "/product/18922c10-b5a3-491a-9a8d-06d930e359cd",
    meta: {"ID":"18922c10-b5a3-491a-9a8d-06d930e359cd","Title":"","GroupID":"2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","GroupTitle":"Детское меню","GroupLink":"/menu/2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|189b0f99-501e-47c4-9fbe-81581b75903b",
    path: "/product/189b0f99-501e-47c4-9fbe-81581b75903b",
    meta: {"ID":"189b0f99-501e-47c4-9fbe-81581b75903b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|18a31da4-6714-460b-9796-6708ded9b82b",
    path: "/product/18a31da4-6714-460b-9796-6708ded9b82b",
    meta: {"ID":"18a31da4-6714-460b-9796-6708ded9b82b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|18eb12d2-95b3-4f31-a2af-3011c3af513b",
    path: "/product/18eb12d2-95b3-4f31-a2af-3011c3af513b",
    meta: {"ID":"18eb12d2-95b3-4f31-a2af-3011c3af513b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|18ebd682-bc5c-44e3-8a4c-1d4c1baeac4c",
    path: "/product/18ebd682-bc5c-44e3-8a4c-1d4c1baeac4c",
    meta: {"ID":"18ebd682-bc5c-44e3-8a4c-1d4c1baeac4c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|18f1f1d4-ce73-45bb-8dd8-04057637afde",
    path: "/product/18f1f1d4-ce73-45bb-8dd8-04057637afde",
    meta: {"ID":"18f1f1d4-ce73-45bb-8dd8-04057637afde","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|190e8f65-ffd1-402d-bb77-8e12f2e4df40",
    path: "/product/190e8f65-ffd1-402d-bb77-8e12f2e4df40",
    meta: {"ID":"190e8f65-ffd1-402d-bb77-8e12f2e4df40","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|19330394-bc61-4e79-b0ed-40ac69aebf3f",
    path: "/product/19330394-bc61-4e79-b0ed-40ac69aebf3f",
    meta: {"ID":"19330394-bc61-4e79-b0ed-40ac69aebf3f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|197ba3cc-4540-4085-a599-5ca301004ada",
    path: "/product/197ba3cc-4540-4085-a599-5ca301004ada",
    meta: {"ID":"197ba3cc-4540-4085-a599-5ca301004ada","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|198612fd-f6e4-4e65-98a1-29cb4406710a",
    path: "/product/198612fd-f6e4-4e65-98a1-29cb4406710a",
    meta: {"ID":"198612fd-f6e4-4e65-98a1-29cb4406710a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|19bb3bee-171c-46c3-8891-b95b96dd6a08",
    path: "/product/19bb3bee-171c-46c3-8891-b95b96dd6a08",
    meta: {"ID":"19bb3bee-171c-46c3-8891-b95b96dd6a08","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|19d2644a-f937-4660-9deb-c2b559183ca0",
    path: "/product/19d2644a-f937-4660-9deb-c2b559183ca0",
    meta: {"ID":"19d2644a-f937-4660-9deb-c2b559183ca0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3|19f7225a-dc5e-416a-88b5-3b1bc282b79a",
    path: "/product/19f7225a-dc5e-416a-88b5-3b1bc282b79a",
    meta: {"ID":"19f7225a-dc5e-416a-88b5-3b1bc282b79a","Title":"","GroupID":"5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","GroupTitle":"Салаты","GroupLink":"/menu/5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1a2f4533-d452-472c-9a95-79a2f99a1f46",
    path: "/product/1a2f4533-d452-472c-9a95-79a2f99a1f46",
    meta: {"ID":"1a2f4533-d452-472c-9a95-79a2f99a1f46","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|1a4de971-8a09-4064-9245-f80bf930b825",
    path: "/product/1a4de971-8a09-4064-9245-f80bf930b825",
    meta: {"ID":"1a4de971-8a09-4064-9245-f80bf930b825","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|1a97645d-630a-492c-ad95-4efd8d9a7c10",
    path: "/product/1a97645d-630a-492c-ad95-4efd8d9a7c10",
    meta: {"ID":"1a97645d-630a-492c-ad95-4efd8d9a7c10","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1ab8fed5-0622-49bf-b3df-19790357e188",
    path: "/product/1ab8fed5-0622-49bf-b3df-19790357e188",
    meta: {"ID":"1ab8fed5-0622-49bf-b3df-19790357e188","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1ac23582-dfea-4b3d-bed9-779ec3682de0",
    path: "/product/1ac23582-dfea-4b3d-bed9-779ec3682de0",
    meta: {"ID":"1ac23582-dfea-4b3d-bed9-779ec3682de0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1adaa802-2b4b-4adb-896d-552bb48c4fe7",
    path: "/product/1adaa802-2b4b-4adb-896d-552bb48c4fe7",
    meta: {"ID":"1adaa802-2b4b-4adb-896d-552bb48c4fe7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1afd0467-2bd6-442a-bb37-941da4f12342",
    path: "/product/1afd0467-2bd6-442a-bb37-941da4f12342",
    meta: {"ID":"1afd0467-2bd6-442a-bb37-941da4f12342","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1b04581c-7c83-41c6-8342-c224c61bb653",
    path: "/product/1b04581c-7c83-41c6-8342-c224c61bb653",
    meta: {"ID":"1b04581c-7c83-41c6-8342-c224c61bb653","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1b0f9d44-70dc-4157-a901-d37f8d683fdd",
    path: "/product/1b0f9d44-70dc-4157-a901-d37f8d683fdd",
    meta: {"ID":"1b0f9d44-70dc-4157-a901-d37f8d683fdd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1b4c29d1-0fb2-4361-a757-01d255334588",
    path: "/product/1b4c29d1-0fb2-4361-a757-01d255334588",
    meta: {"ID":"1b4c29d1-0fb2-4361-a757-01d255334588","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|1b5461ef-8e30-4604-b9b8-78bcbff55aec",
    path: "/product/1b5461ef-8e30-4604-b9b8-78bcbff55aec",
    meta: {"ID":"1b5461ef-8e30-4604-b9b8-78bcbff55aec","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1b584cd5-9071-4a4e-92dc-9a12fc0d7cc2",
    path: "/product/1b584cd5-9071-4a4e-92dc-9a12fc0d7cc2",
    meta: {"ID":"1b584cd5-9071-4a4e-92dc-9a12fc0d7cc2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|1b6a7939-053f-4eec-83cb-495f56b15abb",
    path: "/product/1b6a7939-053f-4eec-83cb-495f56b15abb",
    meta: {"ID":"1b6a7939-053f-4eec-83cb-495f56b15abb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1b6bd6b6-f716-4dec-9200-13864831143c",
    path: "/product/1b6bd6b6-f716-4dec-9200-13864831143c",
    meta: {"ID":"1b6bd6b6-f716-4dec-9200-13864831143c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|1b71c0f3-c4bd-404a-99f9-dcd8ede2612b",
    path: "/product/1b71c0f3-c4bd-404a-99f9-dcd8ede2612b",
    meta: {"ID":"1b71c0f3-c4bd-404a-99f9-dcd8ede2612b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|1b998627-e031-4570-a5bc-9b9a0ea40127",
    path: "/product/1b998627-e031-4570-a5bc-9b9a0ea40127",
    meta: {"ID":"1b998627-e031-4570-a5bc-9b9a0ea40127","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1ba34642-06a9-40fe-869b-6dc673027ef1",
    path: "/product/1ba34642-06a9-40fe-869b-6dc673027ef1",
    meta: {"ID":"1ba34642-06a9-40fe-869b-6dc673027ef1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|1bb256ad-9cc4-4480-9e30-80516dfffaf3",
    path: "/product/1bb256ad-9cc4-4480-9e30-80516dfffaf3",
    meta: {"ID":"1bb256ad-9cc4-4480-9e30-80516dfffaf3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|1bb75599-f3d1-4496-b563-e5da3c4de721",
    path: "/product/1bb75599-f3d1-4496-b563-e5da3c4de721",
    meta: {"ID":"1bb75599-f3d1-4496-b563-e5da3c4de721","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1bc12b28-f5e4-462c-9d32-0d8f760688d9",
    path: "/product/1bc12b28-f5e4-462c-9d32-0d8f760688d9",
    meta: {"ID":"1bc12b28-f5e4-462c-9d32-0d8f760688d9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b|1bf7b287-dd96-49c8-869b-f676366d80d1",
    path: "/product/1bf7b287-dd96-49c8-869b-f676366d80d1",
    meta: {"ID":"1bf7b287-dd96-49c8-869b-f676366d80d1","Title":"","GroupID":"f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","GroupTitle":"Роллы","GroupLink":"/menu/f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1c1de6dd-3ff8-4595-8d19-ecaf9209f488",
    path: "/product/1c1de6dd-3ff8-4595-8d19-ecaf9209f488",
    meta: {"ID":"1c1de6dd-3ff8-4595-8d19-ecaf9209f488","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|1c396261-5639-4b50-b3b1-324f31bed2c8",
    path: "/product/1c396261-5639-4b50-b3b1-324f31bed2c8",
    meta: {"ID":"1c396261-5639-4b50-b3b1-324f31bed2c8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|535cfdf0-8f9a-46fd-9cdc-483647c40ce4|1c490e36-7510-4ac3-a18a-beb2b4f10a88",
    path: "/product/1c490e36-7510-4ac3-a18a-beb2b4f10a88",
    meta: {"ID":"1c490e36-7510-4ac3-a18a-beb2b4f10a88","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1c4f5c25-f7b5-4a43-8451-b4e0a862c921",
    path: "/product/1c4f5c25-f7b5-4a43-8451-b4e0a862c921",
    meta: {"ID":"1c4f5c25-f7b5-4a43-8451-b4e0a862c921","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1c58a16e-8fda-4bb2-a3f9-63f33a14cf10",
    path: "/product/1c58a16e-8fda-4bb2-a3f9-63f33a14cf10",
    meta: {"ID":"1c58a16e-8fda-4bb2-a3f9-63f33a14cf10","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|1c619343-2dc6-4715-8202-2a3edc61434f",
    path: "/product/1c619343-2dc6-4715-8202-2a3edc61434f",
    meta: {"ID":"1c619343-2dc6-4715-8202-2a3edc61434f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1519fe15-47e1-44ff-8747-6714ca96f7aa|1c7434d0-a5ad-4c40-a533-16de43fa99be",
    path: "/product/1c7434d0-a5ad-4c40-a533-16de43fa99be",
    meta: {"ID":"1c7434d0-a5ad-4c40-a533-16de43fa99be","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|1c74645a-bb01-4c79-9170-f3dbdb05e3aa",
    path: "/product/1c74645a-bb01-4c79-9170-f3dbdb05e3aa",
    meta: {"ID":"1c74645a-bb01-4c79-9170-f3dbdb05e3aa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|1c7cf2d4-91c3-4f17-81c2-629745d236f7",
    path: "/product/1c7cf2d4-91c3-4f17-81c2-629745d236f7",
    meta: {"ID":"1c7cf2d4-91c3-4f17-81c2-629745d236f7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|1c8a719e-44d5-4d59-9b6f-de3f12844ff6",
    path: "/product/1c8a719e-44d5-4d59-9b6f-de3f12844ff6",
    meta: {"ID":"1c8a719e-44d5-4d59-9b6f-de3f12844ff6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca23e9-81fa-4648-a2f2-397fc639baa0|1c956ad7-bf6f-45f3-a445-2c271c19bc19",
    path: "/product/1c956ad7-bf6f-45f3-a445-2c271c19bc19",
    meta: {"ID":"1c956ad7-bf6f-45f3-a445-2c271c19bc19","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2baa7814-309c-4d94-b87a-916c5430072b|1cb6bb1d-4a6c-4055-a916-6c54470b582b",
    path: "/product/1cb6bb1d-4a6c-4055-a916-6c54470b582b",
    meta: {"ID":"1cb6bb1d-4a6c-4055-a916-6c54470b582b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1cc1ee41-c412-47c5-b94f-8a11180ad564",
    path: "/product/1cc1ee41-c412-47c5-b94f-8a11180ad564",
    meta: {"ID":"1cc1ee41-c412-47c5-b94f-8a11180ad564","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1cc8358b-22b9-4f1f-b2c0-e620834df610",
    path: "/product/1cc8358b-22b9-4f1f-b2c0-e620834df610",
    meta: {"ID":"1cc8358b-22b9-4f1f-b2c0-e620834df610","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|1cf2a1b9-1b22-41c0-b134-892ab377443f",
    path: "/product/1cf2a1b9-1b22-41c0-b134-892ab377443f",
    meta: {"ID":"1cf2a1b9-1b22-41c0-b134-892ab377443f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1cf4dc5e-1653-44da-87c0-caf3dbffe2d0",
    path: "/product/1cf4dc5e-1653-44da-87c0-caf3dbffe2d0",
    meta: {"ID":"1cf4dc5e-1653-44da-87c0-caf3dbffe2d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1d116c24-b148-4181-a45e-b9d702fd3004",
    path: "/product/1d116c24-b148-4181-a45e-b9d702fd3004",
    meta: {"ID":"1d116c24-b148-4181-a45e-b9d702fd3004","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|1d2f98ce-dd7b-452b-a3f5-fdc672f853d3",
    path: "/product/1d2f98ce-dd7b-452b-a3f5-fdc672f853d3",
    meta: {"ID":"1d2f98ce-dd7b-452b-a3f5-fdc672f853d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1d35e3a2-a6d7-49b5-8c01-2a6ff1a0f593",
    path: "/product/1d35e3a2-a6d7-49b5-8c01-2a6ff1a0f593",
    meta: {"ID":"1d35e3a2-a6d7-49b5-8c01-2a6ff1a0f593","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|1d3ffba3-02ec-47a1-a231-6afed304f6bb",
    path: "/product/1d3ffba3-02ec-47a1-a231-6afed304f6bb",
    meta: {"ID":"1d3ffba3-02ec-47a1-a231-6afed304f6bb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|1d441de8-8044-42c6-93d4-e02b773f33cf",
    path: "/product/1d441de8-8044-42c6-93d4-e02b773f33cf",
    meta: {"ID":"1d441de8-8044-42c6-93d4-e02b773f33cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf8604ed-919e-4f96-8fa9-e0ed5a2f57dc|1d4ce838-8ee9-4034-9584-aa7c759022ae",
    path: "/product/1d4ce838-8ee9-4034-9584-aa7c759022ae",
    meta: {"ID":"1d4ce838-8ee9-4034-9584-aa7c759022ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|1d51ce19-a247-4a97-92a9-00b69bb3b56b",
    path: "/product/1d51ce19-a247-4a97-92a9-00b69bb3b56b",
    meta: {"ID":"1d51ce19-a247-4a97-92a9-00b69bb3b56b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1d594b75-3851-4251-addc-2367f45b1124",
    path: "/product/1d594b75-3851-4251-addc-2367f45b1124",
    meta: {"ID":"1d594b75-3851-4251-addc-2367f45b1124","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|1d659160-0b4f-4d47-b7c0-396f395add7a",
    path: "/product/1d659160-0b4f-4d47-b7c0-396f395add7a",
    meta: {"ID":"1d659160-0b4f-4d47-b7c0-396f395add7a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1d8cbdf9-9d84-4776-853e-cb5a9f3b1941",
    path: "/product/1d8cbdf9-9d84-4776-853e-cb5a9f3b1941",
    meta: {"ID":"1d8cbdf9-9d84-4776-853e-cb5a9f3b1941","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|1dac5ccb-8905-480c-9bcf-af9aa6a0b6d4",
    path: "/product/1dac5ccb-8905-480c-9bcf-af9aa6a0b6d4",
    meta: {"ID":"1dac5ccb-8905-480c-9bcf-af9aa6a0b6d4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|1daf869e-c998-4b71-ac50-afe7fc27709a",
    path: "/product/1daf869e-c998-4b71-ac50-afe7fc27709a",
    meta: {"ID":"1daf869e-c998-4b71-ac50-afe7fc27709a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1db176ab-e226-4578-a9db-9c545fad4c75",
    path: "/product/1db176ab-e226-4578-a9db-9c545fad4c75",
    meta: {"ID":"1db176ab-e226-4578-a9db-9c545fad4c75","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1dc3ad29-7b65-462b-996e-9684c3c3989b",
    path: "/product/1dc3ad29-7b65-462b-996e-9684c3c3989b",
    meta: {"ID":"1dc3ad29-7b65-462b-996e-9684c3c3989b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|1de017a2-bfa0-4297-b5d0-d644ccf06672",
    path: "/product/1de017a2-bfa0-4297-b5d0-d644ccf06672",
    meta: {"ID":"1de017a2-bfa0-4297-b5d0-d644ccf06672","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|1de54e9c-69c5-460f-a108-403367cd40fd",
    path: "/product/1de54e9c-69c5-460f-a108-403367cd40fd",
    meta: {"ID":"1de54e9c-69c5-460f-a108-403367cd40fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|1dfc05c0-c96e-4c1b-9e3d-ac2e852b024e",
    path: "/product/1dfc05c0-c96e-4c1b-9e3d-ac2e852b024e",
    meta: {"ID":"1dfc05c0-c96e-4c1b-9e3d-ac2e852b024e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1e15f053-dc6c-4cf3-bed8-deea762ea93f",
    path: "/product/1e15f053-dc6c-4cf3-bed8-deea762ea93f",
    meta: {"ID":"1e15f053-dc6c-4cf3-bed8-deea762ea93f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1e4d9d0c-89b6-47d0-90d7-e800b5038a08",
    path: "/product/1e4d9d0c-89b6-47d0-90d7-e800b5038a08",
    meta: {"ID":"1e4d9d0c-89b6-47d0-90d7-e800b5038a08","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|1e536bf6-4482-458b-b872-e88b1b1e429f",
    path: "/product/1e536bf6-4482-458b-b872-e88b1b1e429f",
    meta: {"ID":"1e536bf6-4482-458b-b872-e88b1b1e429f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1e67e83f-bba7-44e5-a47d-df3f0a68bdb4",
    path: "/product/1e67e83f-bba7-44e5-a47d-df3f0a68bdb4",
    meta: {"ID":"1e67e83f-bba7-44e5-a47d-df3f0a68bdb4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|1e7010e5-e144-4843-9113-9b312b4c2c9f",
    path: "/product/1e7010e5-e144-4843-9113-9b312b4c2c9f",
    meta: {"ID":"1e7010e5-e144-4843-9113-9b312b4c2c9f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|1e91c5e1-949b-4147-a2a1-1c8a08d18a1f",
    path: "/product/1e91c5e1-949b-4147-a2a1-1c8a08d18a1f",
    meta: {"ID":"1e91c5e1-949b-4147-a2a1-1c8a08d18a1f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1ea46b33-e038-4f98-915d-771a5987b11f",
    path: "/product/1ea46b33-e038-4f98-915d-771a5987b11f",
    meta: {"ID":"1ea46b33-e038-4f98-915d-771a5987b11f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|1ead8c92-b618-4567-9396-06b823c0117c",
    path: "/product/1ead8c92-b618-4567-9396-06b823c0117c",
    meta: {"ID":"1ead8c92-b618-4567-9396-06b823c0117c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1ec7fbff-d334-4ef2-a5c4-05ed681326a6",
    path: "/product/1ec7fbff-d334-4ef2-a5c4-05ed681326a6",
    meta: {"ID":"1ec7fbff-d334-4ef2-a5c4-05ed681326a6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|1ef5e954-634c-44ec-854d-22192b2fc013",
    path: "/product/1ef5e954-634c-44ec-854d-22192b2fc013",
    meta: {"ID":"1ef5e954-634c-44ec-854d-22192b2fc013","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1f0453ae-8453-4054-ad22-6e416ec9778e",
    path: "/product/1f0453ae-8453-4054-ad22-6e416ec9778e",
    meta: {"ID":"1f0453ae-8453-4054-ad22-6e416ec9778e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|1f12a490-13b9-4406-81f8-1890ded5d505",
    path: "/product/1f12a490-13b9-4406-81f8-1890ded5d505",
    meta: {"ID":"1f12a490-13b9-4406-81f8-1890ded5d505","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|1f160ee6-14ea-4e29-a79e-2fd209d8e54a",
    path: "/product/1f160ee6-14ea-4e29-a79e-2fd209d8e54a",
    meta: {"ID":"1f160ee6-14ea-4e29-a79e-2fd209d8e54a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73d8e7e8-a7d6-4cde-900b-feb2938a1fcb|1f1dbc53-6616-4e1e-8981-10a8c69b0431",
    path: "/product/1f1dbc53-6616-4e1e-8981-10a8c69b0431",
    meta: {"ID":"1f1dbc53-6616-4e1e-8981-10a8c69b0431","Title":"","GroupID":"73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","GroupTitle":"Подарочные сертификаты","GroupLink":"/menu/73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|1f3e55da-d5f5-42c4-97f1-78d34d57a400",
    path: "/product/1f3e55da-d5f5-42c4-97f1-78d34d57a400",
    meta: {"ID":"1f3e55da-d5f5-42c4-97f1-78d34d57a400","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|1f6345c3-5118-4b42-b1a7-36401b450cdb",
    path: "/product/1f6345c3-5118-4b42-b1a7-36401b450cdb",
    meta: {"ID":"1f6345c3-5118-4b42-b1a7-36401b450cdb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|1fac3be7-81a8-4c12-9858-4534e4cd8dac",
    path: "/product/1fac3be7-81a8-4c12-9858-4534e4cd8dac",
    meta: {"ID":"1fac3be7-81a8-4c12-9858-4534e4cd8dac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|1faf0862-adf9-4686-8be3-a46e2041ee45",
    path: "/product/1faf0862-adf9-4686-8be3-a46e2041ee45",
    meta: {"ID":"1faf0862-adf9-4686-8be3-a46e2041ee45","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|200c2e61-d958-4288-a0df-b79cbaf38373",
    path: "/product/200c2e61-d958-4288-a0df-b79cbaf38373",
    meta: {"ID":"200c2e61-d958-4288-a0df-b79cbaf38373","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|20229ceb-511d-410d-b046-1293f124fa72",
    path: "/product/20229ceb-511d-410d-b046-1293f124fa72",
    meta: {"ID":"20229ceb-511d-410d-b046-1293f124fa72","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|806b1c7a-ff06-45e9-ac72-7abe93c7a978|2031b980-6d6f-42f8-b624-40e32251d13d",
    path: "/product/2031b980-6d6f-42f8-b624-40e32251d13d",
    meta: {"ID":"2031b980-6d6f-42f8-b624-40e32251d13d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|20334753-86b2-4fb1-9d82-f5a9394e75f2",
    path: "/product/20334753-86b2-4fb1-9d82-f5a9394e75f2",
    meta: {"ID":"20334753-86b2-4fb1-9d82-f5a9394e75f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2037f6c8-3a66-4049-b1d8-c4632df0beea",
    path: "/product/2037f6c8-3a66-4049-b1d8-c4632df0beea",
    meta: {"ID":"2037f6c8-3a66-4049-b1d8-c4632df0beea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2066aefd-f59e-46e4-974b-1c01c6914f34",
    path: "/product/2066aefd-f59e-46e4-974b-1c01c6914f34",
    meta: {"ID":"2066aefd-f59e-46e4-974b-1c01c6914f34","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|206ac79a-42b1-4d49-8ae2-9b1adae39680",
    path: "/product/206ac79a-42b1-4d49-8ae2-9b1adae39680",
    meta: {"ID":"206ac79a-42b1-4d49-8ae2-9b1adae39680","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|2072d73e-64c1-4127-a6b0-f7e019479db7",
    path: "/product/2072d73e-64c1-4127-a6b0-f7e019479db7",
    meta: {"ID":"2072d73e-64c1-4127-a6b0-f7e019479db7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|654b3dbf-24ce-4acd-8343-54dbb128223f|2077aeb8-e2d0-430c-940c-5fce814171b1",
    path: "/product/2077aeb8-e2d0-430c-940c-5fce814171b1",
    meta: {"ID":"2077aeb8-e2d0-430c-940c-5fce814171b1","Title":"","GroupID":"654b3dbf-24ce-4acd-8343-54dbb128223f","GroupTitle":"Супы","GroupLink":"/menu/654b3dbf-24ce-4acd-8343-54dbb128223f","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|20a7d9c5-865f-468a-91d4-ca5cab08186a",
    path: "/product/20a7d9c5-865f-468a-91d4-ca5cab08186a",
    meta: {"ID":"20a7d9c5-865f-468a-91d4-ca5cab08186a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|20aca054-32b5-4817-a436-bb75225fbb44",
    path: "/product/20aca054-32b5-4817-a436-bb75225fbb44",
    meta: {"ID":"20aca054-32b5-4817-a436-bb75225fbb44","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4349f183-f876-4668-9110-f50347c57ef2|20ad3132-da8b-4c14-a668-c54d48ff21e5",
    path: "/product/20ad3132-da8b-4c14-a668-c54d48ff21e5",
    meta: {"ID":"20ad3132-da8b-4c14-a668-c54d48ff21e5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|20b6faa7-3dbf-4641-92c1-240b2b041abb",
    path: "/product/20b6faa7-3dbf-4641-92c1-240b2b041abb",
    meta: {"ID":"20b6faa7-3dbf-4641-92c1-240b2b041abb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|20f7b661-4ba7-4aab-b670-9a2c7daf34ae",
    path: "/product/20f7b661-4ba7-4aab-b670-9a2c7daf34ae",
    meta: {"ID":"20f7b661-4ba7-4aab-b670-9a2c7daf34ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ef0a0b3c-71b8-40c4-9f16-3c9038b9738d|2100f4b4-90d0-4592-9f0e-d87eb3977b30",
    path: "/product/2100f4b4-90d0-4592-9f0e-d87eb3977b30",
    meta: {"ID":"2100f4b4-90d0-4592-9f0e-d87eb3977b30","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2127f3c5-7900-46ae-a24f-f853179ccc33",
    path: "/product/2127f3c5-7900-46ae-a24f-f853179ccc33",
    meta: {"ID":"2127f3c5-7900-46ae-a24f-f853179ccc33","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|21426853-ddec-4a74-9a4d-3327748d3709",
    path: "/product/21426853-ddec-4a74-9a4d-3327748d3709",
    meta: {"ID":"21426853-ddec-4a74-9a4d-3327748d3709","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|217219a9-9c93-47ba-ba98-e13e555c3e18",
    path: "/product/217219a9-9c93-47ba-ba98-e13e555c3e18",
    meta: {"ID":"217219a9-9c93-47ba-ba98-e13e555c3e18","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|21ab3617-bc01-47b7-bb90-a4649436db84",
    path: "/product/21ab3617-bc01-47b7-bb90-a4649436db84",
    meta: {"ID":"21ab3617-bc01-47b7-bb90-a4649436db84","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|21ae55ac-cd5f-47f8-b2ec-0ac68040bd59",
    path: "/product/21ae55ac-cd5f-47f8-b2ec-0ac68040bd59",
    meta: {"ID":"21ae55ac-cd5f-47f8-b2ec-0ac68040bd59","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|21b429b6-317e-41c0-8503-c9b05539ca5a",
    path: "/product/21b429b6-317e-41c0-8503-c9b05539ca5a",
    meta: {"ID":"21b429b6-317e-41c0-8503-c9b05539ca5a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|21b839f3-1d12-4d86-855b-2fa64b52d510",
    path: "/product/21b839f3-1d12-4d86-855b-2fa64b52d510",
    meta: {"ID":"21b839f3-1d12-4d86-855b-2fa64b52d510","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|21be2e85-bde4-4d80-86a7-4351e0ee3772",
    path: "/product/21be2e85-bde4-4d80-86a7-4351e0ee3772",
    meta: {"ID":"21be2e85-bde4-4d80-86a7-4351e0ee3772","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b|21d3df88-5217-461e-93d4-e36861fec2de",
    path: "/product/21d3df88-5217-461e-93d4-e36861fec2de",
    meta: {"ID":"21d3df88-5217-461e-93d4-e36861fec2de","Title":"","GroupID":"f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","GroupTitle":"Роллы","GroupLink":"/menu/f9f40128-0aaf-4d90-a7d9-a2ab21a43b0b","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|21e3106a-5926-46f9-bcab-98d6b7b3ad10",
    path: "/product/21e3106a-5926-46f9-bcab-98d6b7b3ad10",
    meta: {"ID":"21e3106a-5926-46f9-bcab-98d6b7b3ad10","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|21f8a2c9-81b8-4203-9a8e-5d98a98668a3",
    path: "/product/21f8a2c9-81b8-4203-9a8e-5d98a98668a3",
    meta: {"ID":"21f8a2c9-81b8-4203-9a8e-5d98a98668a3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|220d5b76-5f6c-4026-9281-65a1b4d00fbb",
    path: "/product/220d5b76-5f6c-4026-9281-65a1b4d00fbb",
    meta: {"ID":"220d5b76-5f6c-4026-9281-65a1b4d00fbb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c20c30ec-cf97-4ef6-bc06-9e04305c93ad|222511a4-daa4-479b-aa50-9c9b36c6cc8c",
    path: "/product/222511a4-daa4-479b-aa50-9c9b36c6cc8c",
    meta: {"ID":"222511a4-daa4-479b-aa50-9c9b36c6cc8c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|22331ab6-fb7e-402c-99cb-387397d4873b",
    path: "/product/22331ab6-fb7e-402c-99cb-387397d4873b",
    meta: {"ID":"22331ab6-fb7e-402c-99cb-387397d4873b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|224cddb9-d44f-44b3-b2da-c4e639e26377",
    path: "/product/224cddb9-d44f-44b3-b2da-c4e639e26377",
    meta: {"ID":"224cddb9-d44f-44b3-b2da-c4e639e26377","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|225be778-54e5-4434-8a4a-530fc42cc8fd",
    path: "/product/225be778-54e5-4434-8a4a-530fc42cc8fd",
    meta: {"ID":"225be778-54e5-4434-8a4a-530fc42cc8fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|225d5f90-c5d9-42d4-96f0-0500092773d2",
    path: "/product/225d5f90-c5d9-42d4-96f0-0500092773d2",
    meta: {"ID":"225d5f90-c5d9-42d4-96f0-0500092773d2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|22627ae6-650b-45de-ab06-0b0e94b432a3",
    path: "/product/22627ae6-650b-45de-ab06-0b0e94b432a3",
    meta: {"ID":"22627ae6-650b-45de-ab06-0b0e94b432a3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|57b1574f-a331-4e33-aedc-684887d5530e|228174f9-7d7d-430d-8e62-5fb74639608c",
    path: "/product/228174f9-7d7d-430d-8e62-5fb74639608c",
    meta: {"ID":"228174f9-7d7d-430d-8e62-5fb74639608c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2286fb94-88a2-45fe-a3de-01b6ea7f8b49",
    path: "/product/2286fb94-88a2-45fe-a3de-01b6ea7f8b49",
    meta: {"ID":"2286fb94-88a2-45fe-a3de-01b6ea7f8b49","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|22f15c50-ed9e-4148-81a5-e9e90bae43b4",
    path: "/product/22f15c50-ed9e-4148-81a5-e9e90bae43b4",
    meta: {"ID":"22f15c50-ed9e-4148-81a5-e9e90bae43b4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|23043277-4bf2-4a3a-914a-0da685c116a5",
    path: "/product/23043277-4bf2-4a3a-914a-0da685c116a5",
    meta: {"ID":"23043277-4bf2-4a3a-914a-0da685c116a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|2336c0ea-7fdc-4251-b12b-bca7c2bec856",
    path: "/product/2336c0ea-7fdc-4251-b12b-bca7c2bec856",
    meta: {"ID":"2336c0ea-7fdc-4251-b12b-bca7c2bec856","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2353a262-84c4-4ca0-8ea4-2c1477a04cce",
    path: "/product/2353a262-84c4-4ca0-8ea4-2c1477a04cce",
    meta: {"ID":"2353a262-84c4-4ca0-8ea4-2c1477a04cce","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|23cea7a7-8054-4666-8976-a65d0396fadd",
    path: "/product/23cea7a7-8054-4666-8976-a65d0396fadd",
    meta: {"ID":"23cea7a7-8054-4666-8976-a65d0396fadd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|240bde6d-5e17-4619-9d60-781533c3cb52",
    path: "/product/240bde6d-5e17-4619-9d60-781533c3cb52",
    meta: {"ID":"240bde6d-5e17-4619-9d60-781533c3cb52","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2411ad3a-627d-44dc-b655-87976a8397ac",
    path: "/product/2411ad3a-627d-44dc-b655-87976a8397ac",
    meta: {"ID":"2411ad3a-627d-44dc-b655-87976a8397ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|241c002e-4995-4817-922c-6916f4ca6a63",
    path: "/product/241c002e-4995-4817-922c-6916f4ca6a63",
    meta: {"ID":"241c002e-4995-4817-922c-6916f4ca6a63","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|243f8d2e-073a-4c4e-a25a-0c19583f474e",
    path: "/product/243f8d2e-073a-4c4e-a25a-0c19583f474e",
    meta: {"ID":"243f8d2e-073a-4c4e-a25a-0c19583f474e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|24627db3-a3a9-4154-bf52-15213274b5c8",
    path: "/product/24627db3-a3a9-4154-bf52-15213274b5c8",
    meta: {"ID":"24627db3-a3a9-4154-bf52-15213274b5c8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|24683dba-fc76-49fb-aadf-fdb90bf3f24d",
    path: "/product/24683dba-fc76-49fb-aadf-fdb90bf3f24d",
    meta: {"ID":"24683dba-fc76-49fb-aadf-fdb90bf3f24d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|247c0125-e30b-4bac-8167-174f740ced5c",
    path: "/product/247c0125-e30b-4bac-8167-174f740ced5c",
    meta: {"ID":"247c0125-e30b-4bac-8167-174f740ced5c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|249507f1-5c25-437b-b9df-978dc5c58b63",
    path: "/product/249507f1-5c25-437b-b9df-978dc5c58b63",
    meta: {"ID":"249507f1-5c25-437b-b9df-978dc5c58b63","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|57b1574f-a331-4e33-aedc-684887d5530e|24c3cb87-bbff-481b-9365-90bc43b0ab73",
    path: "/product/24c3cb87-bbff-481b-9365-90bc43b0ab73",
    meta: {"ID":"24c3cb87-bbff-481b-9365-90bc43b0ab73","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|24d8efef-f54f-459b-b588-17de24f0d30d",
    path: "/product/24d8efef-f54f-459b-b588-17de24f0d30d",
    meta: {"ID":"24d8efef-f54f-459b-b588-17de24f0d30d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|24dfdb4e-9347-4eed-a6c1-5bf80901f71c",
    path: "/product/24dfdb4e-9347-4eed-a6c1-5bf80901f71c",
    meta: {"ID":"24dfdb4e-9347-4eed-a6c1-5bf80901f71c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|24ef6006-ba5d-4db9-be85-035d71ce1312",
    path: "/product/24ef6006-ba5d-4db9-be85-035d71ce1312",
    meta: {"ID":"24ef6006-ba5d-4db9-be85-035d71ce1312","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73cec509-3358-485d-8d8e-5484c8bb8f78|25097998-d2fb-43fe-a24a-6f8073f5dc97",
    path: "/product/25097998-d2fb-43fe-a24a-6f8073f5dc97",
    meta: {"ID":"25097998-d2fb-43fe-a24a-6f8073f5dc97","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|253e0bc7-f952-41e0-a6fc-c4a06d85c688",
    path: "/product/253e0bc7-f952-41e0-a6fc-c4a06d85c688",
    meta: {"ID":"253e0bc7-f952-41e0-a6fc-c4a06d85c688","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2549ff90-821d-4b96-918a-a26f0028f474",
    path: "/product/2549ff90-821d-4b96-918a-a26f0028f474",
    meta: {"ID":"2549ff90-821d-4b96-918a-a26f0028f474","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|255e4f42-fa71-4eea-926d-63fce123e395",
    path: "/product/255e4f42-fa71-4eea-926d-63fce123e395",
    meta: {"ID":"255e4f42-fa71-4eea-926d-63fce123e395","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2575199c-6363-44e9-bff6-3d5840c20a4e",
    path: "/product/2575199c-6363-44e9-bff6-3d5840c20a4e",
    meta: {"ID":"2575199c-6363-44e9-bff6-3d5840c20a4e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2586c16a-c34f-4970-9b46-6930e4686f26",
    path: "/product/2586c16a-c34f-4970-9b46-6930e4686f26",
    meta: {"ID":"2586c16a-c34f-4970-9b46-6930e4686f26","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|258eb332-f58d-4c78-b450-c7c0a16bed83",
    path: "/product/258eb332-f58d-4c78-b450-c7c0a16bed83",
    meta: {"ID":"258eb332-f58d-4c78-b450-c7c0a16bed83","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|25c6cabc-5ac0-4e46-9efe-34a1761df624",
    path: "/product/25c6cabc-5ac0-4e46-9efe-34a1761df624",
    meta: {"ID":"25c6cabc-5ac0-4e46-9efe-34a1761df624","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|25cf49ff-f238-44c9-b47b-d3b13d634db0",
    path: "/product/25cf49ff-f238-44c9-b47b-d3b13d634db0",
    meta: {"ID":"25cf49ff-f238-44c9-b47b-d3b13d634db0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|25f3cf5b-caab-4754-85af-ab0a3d9c68fd",
    path: "/product/25f3cf5b-caab-4754-85af-ab0a3d9c68fd",
    meta: {"ID":"25f3cf5b-caab-4754-85af-ab0a3d9c68fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|26161222-9e6b-42e3-9fd9-fbc3a6bfe03b",
    path: "/product/26161222-9e6b-42e3-9fd9-fbc3a6bfe03b",
    meta: {"ID":"26161222-9e6b-42e3-9fd9-fbc3a6bfe03b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|26260689-d2bc-457a-b54a-68260b690064",
    path: "/product/26260689-d2bc-457a-b54a-68260b690064",
    meta: {"ID":"26260689-d2bc-457a-b54a-68260b690064","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|26296835-57de-4bae-a55b-06f9296ab778",
    path: "/product/26296835-57de-4bae-a55b-06f9296ab778",
    meta: {"ID":"26296835-57de-4bae-a55b-06f9296ab778","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|264db092-b41b-417e-b6f2-048fa63b64cb",
    path: "/product/264db092-b41b-417e-b6f2-048fa63b64cb",
    meta: {"ID":"264db092-b41b-417e-b6f2-048fa63b64cb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|265db6b5-ab08-4d6c-9192-1b0bf631f2a2",
    path: "/product/265db6b5-ab08-4d6c-9192-1b0bf631f2a2",
    meta: {"ID":"265db6b5-ab08-4d6c-9192-1b0bf631f2a2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|266199b9-f039-42d1-b6b3-e251a34579c0",
    path: "/product/266199b9-f039-42d1-b6b3-e251a34579c0",
    meta: {"ID":"266199b9-f039-42d1-b6b3-e251a34579c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|26645f7a-fb73-4f7f-b457-eda415ff382a",
    path: "/product/26645f7a-fb73-4f7f-b457-eda415ff382a",
    meta: {"ID":"26645f7a-fb73-4f7f-b457-eda415ff382a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|26699330-7f17-4c29-b1d3-54591746dfff",
    path: "/product/26699330-7f17-4c29-b1d3-54591746dfff",
    meta: {"ID":"26699330-7f17-4c29-b1d3-54591746dfff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|26b1416d-9e56-4b8d-804d-7ad9a5c8e832",
    path: "/product/26b1416d-9e56-4b8d-804d-7ad9a5c8e832",
    meta: {"ID":"26b1416d-9e56-4b8d-804d-7ad9a5c8e832","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|26b5bfa6-789f-462b-addc-98f4ef22e7cb",
    path: "/product/26b5bfa6-789f-462b-addc-98f4ef22e7cb",
    meta: {"ID":"26b5bfa6-789f-462b-addc-98f4ef22e7cb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|26c4c490-afb3-4bea-a0ad-80967e9eb22b",
    path: "/product/26c4c490-afb3-4bea-a0ad-80967e9eb22b",
    meta: {"ID":"26c4c490-afb3-4bea-a0ad-80967e9eb22b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|26cbad97-8f45-4dbb-8f73-cdadc4f6793b",
    path: "/product/26cbad97-8f45-4dbb-8f73-cdadc4f6793b",
    meta: {"ID":"26cbad97-8f45-4dbb-8f73-cdadc4f6793b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|26e336db-d62e-4021-a837-b7c97a33e74e",
    path: "/product/26e336db-d62e-4021-a837-b7c97a33e74e",
    meta: {"ID":"26e336db-d62e-4021-a837-b7c97a33e74e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|26e86ddf-bad6-4f7c-8a5a-a3e146afe397",
    path: "/product/26e86ddf-bad6-4f7c-8a5a-a3e146afe397",
    meta: {"ID":"26e86ddf-bad6-4f7c-8a5a-a3e146afe397","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2706686e-4706-40be-b38a-9f78350547a7",
    path: "/product/2706686e-4706-40be-b38a-9f78350547a7",
    meta: {"ID":"2706686e-4706-40be-b38a-9f78350547a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|272416bc-12ee-4efe-9dbc-d75eac633200",
    path: "/product/272416bc-12ee-4efe-9dbc-d75eac633200",
    meta: {"ID":"272416bc-12ee-4efe-9dbc-d75eac633200","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2728bf0a-bfcf-4d10-b105-faf874dcdf25",
    path: "/product/2728bf0a-bfcf-4d10-b105-faf874dcdf25",
    meta: {"ID":"2728bf0a-bfcf-4d10-b105-faf874dcdf25","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|277105c0-8b80-4761-94e4-c4cf5bb811da",
    path: "/product/277105c0-8b80-4761-94e4-c4cf5bb811da",
    meta: {"ID":"277105c0-8b80-4761-94e4-c4cf5bb811da","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5cb1bd94-d83d-4b23-9469-0ddc318e5ee6|2773bcd5-a20b-4f16-a016-80cdda1fe6f8",
    path: "/product/2773bcd5-a20b-4f16-a016-80cdda1fe6f8",
    meta: {"ID":"2773bcd5-a20b-4f16-a016-80cdda1fe6f8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|278106af-d3d6-4097-afb0-f8bccdf40078",
    path: "/product/278106af-d3d6-4097-afb0-f8bccdf40078",
    meta: {"ID":"278106af-d3d6-4097-afb0-f8bccdf40078","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|2799ce8f-ff1f-4620-9dd3-d009a1bea2b9",
    path: "/product/2799ce8f-ff1f-4620-9dd3-d009a1bea2b9",
    meta: {"ID":"2799ce8f-ff1f-4620-9dd3-d009a1bea2b9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|27a6b6ae-6251-45eb-8f03-4462e64be30b",
    path: "/product/27a6b6ae-6251-45eb-8f03-4462e64be30b",
    meta: {"ID":"27a6b6ae-6251-45eb-8f03-4462e64be30b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|27aafb10-e16e-4180-9df8-156abb36bd4e",
    path: "/product/27aafb10-e16e-4180-9df8-156abb36bd4e",
    meta: {"ID":"27aafb10-e16e-4180-9df8-156abb36bd4e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|27b7c8e1-de91-4c80-8eef-9e1e2e99d507",
    path: "/product/27b7c8e1-de91-4c80-8eef-9e1e2e99d507",
    meta: {"ID":"27b7c8e1-de91-4c80-8eef-9e1e2e99d507","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|27e77749-c3c4-470d-9a0f-7c58c7929b7e",
    path: "/product/27e77749-c3c4-470d-9a0f-7c58c7929b7e",
    meta: {"ID":"27e77749-c3c4-470d-9a0f-7c58c7929b7e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|27e7ca3d-60f2-4a2b-8e86-707a4e33f4d9",
    path: "/product/27e7ca3d-60f2-4a2b-8e86-707a4e33f4d9",
    meta: {"ID":"27e7ca3d-60f2-4a2b-8e86-707a4e33f4d9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca1c41-f8fc-4cd3-9788-4fe61c89225d|27eedd36-c0e0-455b-a524-02c42cf71d55",
    path: "/product/27eedd36-c0e0-455b-a524-02c42cf71d55",
    meta: {"ID":"27eedd36-c0e0-455b-a524-02c42cf71d55","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|27ff5024-ea27-4405-baf2-a83f738c6d88",
    path: "/product/27ff5024-ea27-4405-baf2-a83f738c6d88",
    meta: {"ID":"27ff5024-ea27-4405-baf2-a83f738c6d88","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|28112acf-0774-40bb-9596-0be78022e502",
    path: "/product/28112acf-0774-40bb-9596-0be78022e502",
    meta: {"ID":"28112acf-0774-40bb-9596-0be78022e502","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|28318195-d99e-4f7c-8934-48670fd10453",
    path: "/product/28318195-d99e-4f7c-8934-48670fd10453",
    meta: {"ID":"28318195-d99e-4f7c-8934-48670fd10453","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6650365f-b288-4159-8f19-1721fee02f23|2837482e-cedf-4bde-9dcd-62b89e601675",
    path: "/product/2837482e-cedf-4bde-9dcd-62b89e601675",
    meta: {"ID":"2837482e-cedf-4bde-9dcd-62b89e601675","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|2855628e-dd7a-45ca-b215-512beed89235",
    path: "/product/2855628e-dd7a-45ca-b215-512beed89235",
    meta: {"ID":"2855628e-dd7a-45ca-b215-512beed89235","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|2859b5f2-35ec-4719-b524-d25c5a695777",
    path: "/product/2859b5f2-35ec-4719-b524-d25c5a695777",
    meta: {"ID":"2859b5f2-35ec-4719-b524-d25c5a695777","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|287cd4df-7429-4759-afef-26578e7fe8b0",
    path: "/product/287cd4df-7429-4759-afef-26578e7fe8b0",
    meta: {"ID":"287cd4df-7429-4759-afef-26578e7fe8b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|28b5372c-6e8b-42ed-8490-c7f680324344",
    path: "/product/28b5372c-6e8b-42ed-8490-c7f680324344",
    meta: {"ID":"28b5372c-6e8b-42ed-8490-c7f680324344","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|28c0b4cb-f15e-4ef6-8980-7bf611908796",
    path: "/product/28c0b4cb-f15e-4ef6-8980-7bf611908796",
    meta: {"ID":"28c0b4cb-f15e-4ef6-8980-7bf611908796","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|28c1cc4a-3c68-4fad-85a1-32d08a8e862a",
    path: "/product/28c1cc4a-3c68-4fad-85a1-32d08a8e862a",
    meta: {"ID":"28c1cc4a-3c68-4fad-85a1-32d08a8e862a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|28c69922-4713-46dd-8399-732ba81fa502",
    path: "/product/28c69922-4713-46dd-8399-732ba81fa502",
    meta: {"ID":"28c69922-4713-46dd-8399-732ba81fa502","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|28cf329e-a2ed-4e81-ba4f-674e1a77f968",
    path: "/product/28cf329e-a2ed-4e81-ba4f-674e1a77f968",
    meta: {"ID":"28cf329e-a2ed-4e81-ba4f-674e1a77f968","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|28e44769-65ab-4707-b0ae-956caa5c931f",
    path: "/product/28e44769-65ab-4707-b0ae-956caa5c931f",
    meta: {"ID":"28e44769-65ab-4707-b0ae-956caa5c931f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2907de5b-fa60-4059-bc5b-c90f4074e66b",
    path: "/product/2907de5b-fa60-4059-bc5b-c90f4074e66b",
    meta: {"ID":"2907de5b-fa60-4059-bc5b-c90f4074e66b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7b8f0d1b-6e98-4d6a-be01-03b913fcdb44|290f8064-0084-4b3a-9eba-d749d7bde38b",
    path: "/product/290f8064-0084-4b3a-9eba-d749d7bde38b",
    meta: {"ID":"290f8064-0084-4b3a-9eba-d749d7bde38b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a04f5a3-7f1f-4863-9c6a-170e7e97ef1b|294725b1-bdc2-4862-8ea3-52c4659b599a",
    path: "/product/294725b1-bdc2-4862-8ea3-52c4659b599a",
    meta: {"ID":"294725b1-bdc2-4862-8ea3-52c4659b599a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|29567507-8cc8-41d0-93f2-a5bddc5ea93f",
    path: "/product/29567507-8cc8-41d0-93f2-a5bddc5ea93f",
    meta: {"ID":"29567507-8cc8-41d0-93f2-a5bddc5ea93f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|298d43ae-c40d-4953-9898-16b2a6d32bd6",
    path: "/product/298d43ae-c40d-4953-9898-16b2a6d32bd6",
    meta: {"ID":"298d43ae-c40d-4953-9898-16b2a6d32bd6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73e4936d-d27c-44d6-85f2-bbd41d2ec26a|29a32bbb-8c95-4af3-9f8d-046193c9183a",
    path: "/product/29a32bbb-8c95-4af3-9f8d-046193c9183a",
    meta: {"ID":"29a32bbb-8c95-4af3-9f8d-046193c9183a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|29bb991f-aa24-442d-8aca-f0d5498d31e6",
    path: "/product/29bb991f-aa24-442d-8aca-f0d5498d31e6",
    meta: {"ID":"29bb991f-aa24-442d-8aca-f0d5498d31e6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|29d4f1a0-50a1-494c-824a-93b69fcd347a",
    path: "/product/29d4f1a0-50a1-494c-824a-93b69fcd347a",
    meta: {"ID":"29d4f1a0-50a1-494c-824a-93b69fcd347a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|2a2bccbf-b7bd-4618-8388-bb288e7ab700",
    path: "/product/2a2bccbf-b7bd-4618-8388-bb288e7ab700",
    meta: {"ID":"2a2bccbf-b7bd-4618-8388-bb288e7ab700","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2a323742-d70e-459f-bdee-a3f0c4a3ef3d",
    path: "/product/2a323742-d70e-459f-bdee-a3f0c4a3ef3d",
    meta: {"ID":"2a323742-d70e-459f-bdee-a3f0c4a3ef3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2a3defd8-a422-48c7-b69b-b068045de788",
    path: "/product/2a3defd8-a422-48c7-b69b-b068045de788",
    meta: {"ID":"2a3defd8-a422-48c7-b69b-b068045de788","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|2a4b134d-ffe1-4e57-953d-4013b56337ca",
    path: "/product/2a4b134d-ffe1-4e57-953d-4013b56337ca",
    meta: {"ID":"2a4b134d-ffe1-4e57-953d-4013b56337ca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|2a4f93c7-a2a2-4ed2-a683-9bc00dcf03a1",
    path: "/product/2a4f93c7-a2a2-4ed2-a683-9bc00dcf03a1",
    meta: {"ID":"2a4f93c7-a2a2-4ed2-a683-9bc00dcf03a1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2a77564e-5146-4a92-bc3a-8681f62fe530",
    path: "/product/2a77564e-5146-4a92-bc3a-8681f62fe530",
    meta: {"ID":"2a77564e-5146-4a92-bc3a-8681f62fe530","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|967d3966-c5f3-487e-a085-8e9a8fe858bf|2abe1bb8-b8a2-4880-bb13-21b03e4a4b91",
    path: "/product/2abe1bb8-b8a2-4880-bb13-21b03e4a4b91",
    meta: {"ID":"2abe1bb8-b8a2-4880-bb13-21b03e4a4b91","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2ad2ccb2-722b-4eb6-be9a-156c4f341543",
    path: "/product/2ad2ccb2-722b-4eb6-be9a-156c4f341543",
    meta: {"ID":"2ad2ccb2-722b-4eb6-be9a-156c4f341543","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|2ad94159-9ec7-41af-b70e-3650f53fa26a",
    path: "/product/2ad94159-9ec7-41af-b70e-3650f53fa26a",
    meta: {"ID":"2ad94159-9ec7-41af-b70e-3650f53fa26a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|2af719e3-c1aa-4786-9193-af5d80569ca6",
    path: "/product/2af719e3-c1aa-4786-9193-af5d80569ca6",
    meta: {"ID":"2af719e3-c1aa-4786-9193-af5d80569ca6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2b06092a-7d2a-4fd0-a378-1be6c9cce3bb",
    path: "/product/2b06092a-7d2a-4fd0-a378-1be6c9cce3bb",
    meta: {"ID":"2b06092a-7d2a-4fd0-a378-1be6c9cce3bb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2b264f22-2872-4cd7-9b00-407e466c8996",
    path: "/product/2b264f22-2872-4cd7-9b00-407e466c8996",
    meta: {"ID":"2b264f22-2872-4cd7-9b00-407e466c8996","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2b46e3b6-4486-42e0-bba9-547b83db583c",
    path: "/product/2b46e3b6-4486-42e0-bba9-547b83db583c",
    meta: {"ID":"2b46e3b6-4486-42e0-bba9-547b83db583c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|2b482d04-5295-48c1-b20c-80fccc1efa8e",
    path: "/product/2b482d04-5295-48c1-b20c-80fccc1efa8e",
    meta: {"ID":"2b482d04-5295-48c1-b20c-80fccc1efa8e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2b4904af-608e-442c-b714-02f504e2a6fe",
    path: "/product/2b4904af-608e-442c-b714-02f504e2a6fe",
    meta: {"ID":"2b4904af-608e-442c-b714-02f504e2a6fe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2b61bb2b-76ed-4005-86e5-7028ff9f6c7d",
    path: "/product/2b61bb2b-76ed-4005-86e5-7028ff9f6c7d",
    meta: {"ID":"2b61bb2b-76ed-4005-86e5-7028ff9f6c7d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2b686870-4393-453e-a777-020b80cf4ae6",
    path: "/product/2b686870-4393-453e-a777-020b80cf4ae6",
    meta: {"ID":"2b686870-4393-453e-a777-020b80cf4ae6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2b7861e3-beb9-4938-b143-5110ccf88fcd",
    path: "/product/2b7861e3-beb9-4938-b143-5110ccf88fcd",
    meta: {"ID":"2b7861e3-beb9-4938-b143-5110ccf88fcd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2b7bb4f2-3172-4282-9f03-7805144010a2",
    path: "/product/2b7bb4f2-3172-4282-9f03-7805144010a2",
    meta: {"ID":"2b7bb4f2-3172-4282-9f03-7805144010a2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4349f183-f876-4668-9110-f50347c57ef2|2ba6a125-bf5d-4b80-8dcc-7a679c398a92",
    path: "/product/2ba6a125-bf5d-4b80-8dcc-7a679c398a92",
    meta: {"ID":"2ba6a125-bf5d-4b80-8dcc-7a679c398a92","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2baef282-461d-4b97-b57d-1f88d158f777",
    path: "/product/2baef282-461d-4b97-b57d-1f88d158f777",
    meta: {"ID":"2baef282-461d-4b97-b57d-1f88d158f777","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2bb5aa54-9515-4568-aacd-8f4c716bfaba",
    path: "/product/2bb5aa54-9515-4568-aacd-8f4c716bfaba",
    meta: {"ID":"2bb5aa54-9515-4568-aacd-8f4c716bfaba","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|2bbeb56a-901f-4272-963e-a188c68e0fe0",
    path: "/product/2bbeb56a-901f-4272-963e-a188c68e0fe0",
    meta: {"ID":"2bbeb56a-901f-4272-963e-a188c68e0fe0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2bf73436-ba6e-43e8-916c-73dbf33f56b5",
    path: "/product/2bf73436-ba6e-43e8-916c-73dbf33f56b5",
    meta: {"ID":"2bf73436-ba6e-43e8-916c-73dbf33f56b5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2c39d285-eba5-4abd-9878-212db7252bf4",
    path: "/product/2c39d285-eba5-4abd-9878-212db7252bf4",
    meta: {"ID":"2c39d285-eba5-4abd-9878-212db7252bf4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|2c743714-6528-42bb-b391-133c375538ac",
    path: "/product/2c743714-6528-42bb-b391-133c375538ac",
    meta: {"ID":"2c743714-6528-42bb-b391-133c375538ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2c78f508-3d4a-4f73-a873-a8947f7a675a",
    path: "/product/2c78f508-3d4a-4f73-a873-a8947f7a675a",
    meta: {"ID":"2c78f508-3d4a-4f73-a873-a8947f7a675a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2c7ccdfa-9dc8-47b1-8a3a-60d6b98fdd4d",
    path: "/product/2c7ccdfa-9dc8-47b1-8a3a-60d6b98fdd4d",
    meta: {"ID":"2c7ccdfa-9dc8-47b1-8a3a-60d6b98fdd4d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2c91a4dc-5f95-49fa-b443-3276d99c14e8",
    path: "/product/2c91a4dc-5f95-49fa-b443-3276d99c14e8",
    meta: {"ID":"2c91a4dc-5f95-49fa-b443-3276d99c14e8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|2cae4e12-7aa5-48b9-90de-2fbedc2b585c",
    path: "/product/2cae4e12-7aa5-48b9-90de-2fbedc2b585c",
    meta: {"ID":"2cae4e12-7aa5-48b9-90de-2fbedc2b585c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2ccd4181-f02c-4bfd-ad04-a54f5590479e",
    path: "/product/2ccd4181-f02c-4bfd-ad04-a54f5590479e",
    meta: {"ID":"2ccd4181-f02c-4bfd-ad04-a54f5590479e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2cdea788-4daa-49f9-b50a-426f6a1d8108",
    path: "/product/2cdea788-4daa-49f9-b50a-426f6a1d8108",
    meta: {"ID":"2cdea788-4daa-49f9-b50a-426f6a1d8108","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|2ce966b4-5341-4dc6-ae85-e1fe291c52ba",
    path: "/product/2ce966b4-5341-4dc6-ae85-e1fe291c52ba",
    meta: {"ID":"2ce966b4-5341-4dc6-ae85-e1fe291c52ba","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2cecdf1d-3eda-493e-b9d5-0f9e0a67ddfa",
    path: "/product/2cecdf1d-3eda-493e-b9d5-0f9e0a67ddfa",
    meta: {"ID":"2cecdf1d-3eda-493e-b9d5-0f9e0a67ddfa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2cfc8273-e925-4d28-aa1e-36540fe463bc",
    path: "/product/2cfc8273-e925-4d28-aa1e-36540fe463bc",
    meta: {"ID":"2cfc8273-e925-4d28-aa1e-36540fe463bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2d0d7f29-4b8b-4e29-b614-3aaae09a98df",
    path: "/product/2d0d7f29-4b8b-4e29-b614-3aaae09a98df",
    meta: {"ID":"2d0d7f29-4b8b-4e29-b614-3aaae09a98df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|703bc4c8-03f6-4fb4-b63b-06742e85e924|2d0dcd5e-3884-4ebe-ab9a-31684d9547d8",
    path: "/product/2d0dcd5e-3884-4ebe-ab9a-31684d9547d8",
    meta: {"ID":"2d0dcd5e-3884-4ebe-ab9a-31684d9547d8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2d1cd86f-c22f-440a-8d7a-b04986e89460",
    path: "/product/2d1cd86f-c22f-440a-8d7a-b04986e89460",
    meta: {"ID":"2d1cd86f-c22f-440a-8d7a-b04986e89460","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2d39f11c-7699-45b8-87a9-7de16752bce6",
    path: "/product/2d39f11c-7699-45b8-87a9-7de16752bce6",
    meta: {"ID":"2d39f11c-7699-45b8-87a9-7de16752bce6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4089a20c-7c4e-410d-9a00-8bbac0d71cc8|2d52ef2f-49c7-49e1-8233-b245ed685608",
    path: "/product/2d52ef2f-49c7-49e1-8233-b245ed685608",
    meta: {"ID":"2d52ef2f-49c7-49e1-8233-b245ed685608","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|2d610882-d982-4637-b156-9e12f80bcae4",
    path: "/product/2d610882-d982-4637-b156-9e12f80bcae4",
    meta: {"ID":"2d610882-d982-4637-b156-9e12f80bcae4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6fa548ca-4b6a-4806-83da-74148d7bfb94|2d82e31b-78fd-4ef3-afad-864b61919bca",
    path: "/product/2d82e31b-78fd-4ef3-afad-864b61919bca",
    meta: {"ID":"2d82e31b-78fd-4ef3-afad-864b61919bca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2d8eb766-782e-4abf-84c2-5d6f5b5902d5",
    path: "/product/2d8eb766-782e-4abf-84c2-5d6f5b5902d5",
    meta: {"ID":"2d8eb766-782e-4abf-84c2-5d6f5b5902d5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2d9cb302-b5cb-46c5-a6c4-fb7422cdb2c5",
    path: "/product/2d9cb302-b5cb-46c5-a6c4-fb7422cdb2c5",
    meta: {"ID":"2d9cb302-b5cb-46c5-a6c4-fb7422cdb2c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2db4ccce-4841-409e-8de5-cfb89e55b916",
    path: "/product/2db4ccce-4841-409e-8de5-cfb89e55b916",
    meta: {"ID":"2db4ccce-4841-409e-8de5-cfb89e55b916","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|2dbc71a3-92e1-4aa4-be9d-da4081888e8e",
    path: "/product/2dbc71a3-92e1-4aa4-be9d-da4081888e8e",
    meta: {"ID":"2dbc71a3-92e1-4aa4-be9d-da4081888e8e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|2dbe411e-d68d-44f7-841e-4c5bfd41519e",
    path: "/product/2dbe411e-d68d-44f7-841e-4c5bfd41519e",
    meta: {"ID":"2dbe411e-d68d-44f7-841e-4c5bfd41519e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2dcf78a0-d646-40dd-90b9-875f71b3d52b",
    path: "/product/2dcf78a0-d646-40dd-90b9-875f71b3d52b",
    meta: {"ID":"2dcf78a0-d646-40dd-90b9-875f71b3d52b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2ddf2dbb-67e5-483b-b22a-63bff4ac6072",
    path: "/product/2ddf2dbb-67e5-483b-b22a-63bff4ac6072",
    meta: {"ID":"2ddf2dbb-67e5-483b-b22a-63bff4ac6072","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2deea9d6-03dd-449b-8475-392090011f39",
    path: "/product/2deea9d6-03dd-449b-8475-392090011f39",
    meta: {"ID":"2deea9d6-03dd-449b-8475-392090011f39","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|2df1fe22-6f78-4126-848b-bd8f59a5b46a",
    path: "/product/2df1fe22-6f78-4126-848b-bd8f59a5b46a",
    meta: {"ID":"2df1fe22-6f78-4126-848b-bd8f59a5b46a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2e012cb5-a513-4702-b9bf-9822e6a4cf70",
    path: "/product/2e012cb5-a513-4702-b9bf-9822e6a4cf70",
    meta: {"ID":"2e012cb5-a513-4702-b9bf-9822e6a4cf70","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|2e224c66-3e86-45c0-bae1-da26560dea72",
    path: "/product/2e224c66-3e86-45c0-bae1-da26560dea72",
    meta: {"ID":"2e224c66-3e86-45c0-bae1-da26560dea72","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2e279260-4d97-4c3f-a6a3-43e418391d5f",
    path: "/product/2e279260-4d97-4c3f-a6a3-43e418391d5f",
    meta: {"ID":"2e279260-4d97-4c3f-a6a3-43e418391d5f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2e464853-b6ef-481a-8549-6b543a28c23d",
    path: "/product/2e464853-b6ef-481a-8549-6b543a28c23d",
    meta: {"ID":"2e464853-b6ef-481a-8549-6b543a28c23d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2e587c54-db2a-45f9-a565-47f107c639ea",
    path: "/product/2e587c54-db2a-45f9-a565-47f107c639ea",
    meta: {"ID":"2e587c54-db2a-45f9-a565-47f107c639ea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|2e68da8d-df8a-4a54-83f6-f7ff52cac6da",
    path: "/product/2e68da8d-df8a-4a54-83f6-f7ff52cac6da",
    meta: {"ID":"2e68da8d-df8a-4a54-83f6-f7ff52cac6da","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2e8cc906-d02c-4c82-bea0-fd570d405e79",
    path: "/product/2e8cc906-d02c-4c82-bea0-fd570d405e79",
    meta: {"ID":"2e8cc906-d02c-4c82-bea0-fd570d405e79","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2e9fd660-c777-4572-ae57-0598a10a1fe7",
    path: "/product/2e9fd660-c777-4572-ae57-0598a10a1fe7",
    meta: {"ID":"2e9fd660-c777-4572-ae57-0598a10a1fe7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|2ea4130d-7c0c-4019-bf7e-26840316cb54",
    path: "/product/2ea4130d-7c0c-4019-bf7e-26840316cb54",
    meta: {"ID":"2ea4130d-7c0c-4019-bf7e-26840316cb54","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2ed0108c-eb44-433d-adfe-ba98525eeb73",
    path: "/product/2ed0108c-eb44-433d-adfe-ba98525eeb73",
    meta: {"ID":"2ed0108c-eb44-433d-adfe-ba98525eeb73","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|2ee740b8-4eba-4b45-999c-eb3771e37bc2",
    path: "/product/2ee740b8-4eba-4b45-999c-eb3771e37bc2",
    meta: {"ID":"2ee740b8-4eba-4b45-999c-eb3771e37bc2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2eeef4c4-ba87-4122-a1de-d7a2808ba6bc",
    path: "/product/2eeef4c4-ba87-4122-a1de-d7a2808ba6bc",
    meta: {"ID":"2eeef4c4-ba87-4122-a1de-d7a2808ba6bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|2efb2e5c-9b8b-4780-84ea-6a8436364e63",
    path: "/product/2efb2e5c-9b8b-4780-84ea-6a8436364e63",
    meta: {"ID":"2efb2e5c-9b8b-4780-84ea-6a8436364e63","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|42a5e113-75f4-40d2-a9ee-575b6e6c0b8d|2f0a3f7b-f150-4728-8b94-1689e0d72d84",
    path: "/product/2f0a3f7b-f150-4728-8b94-1689e0d72d84",
    meta: {"ID":"2f0a3f7b-f150-4728-8b94-1689e0d72d84","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|2f23556b-872b-4ea5-8e76-8746b7bcfd2a",
    path: "/product/2f23556b-872b-4ea5-8e76-8746b7bcfd2a",
    meta: {"ID":"2f23556b-872b-4ea5-8e76-8746b7bcfd2a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2f422365-3fb1-40c0-8c3a-707401f0200c",
    path: "/product/2f422365-3fb1-40c0-8c3a-707401f0200c",
    meta: {"ID":"2f422365-3fb1-40c0-8c3a-707401f0200c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2f5e3021-a0ff-4ef6-af4f-c1b85e096b7e",
    path: "/product/2f5e3021-a0ff-4ef6-af4f-c1b85e096b7e",
    meta: {"ID":"2f5e3021-a0ff-4ef6-af4f-c1b85e096b7e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|2f6388a6-112a-4ab8-a29c-adabc1ba0609",
    path: "/product/2f6388a6-112a-4ab8-a29c-adabc1ba0609",
    meta: {"ID":"2f6388a6-112a-4ab8-a29c-adabc1ba0609","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2f70b09d-1e92-4110-b2b9-aba99dc22c2c",
    path: "/product/2f70b09d-1e92-4110-b2b9-aba99dc22c2c",
    meta: {"ID":"2f70b09d-1e92-4110-b2b9-aba99dc22c2c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|2f916a49-81bf-41a6-afd8-36a360c4da76",
    path: "/product/2f916a49-81bf-41a6-afd8-36a360c4da76",
    meta: {"ID":"2f916a49-81bf-41a6-afd8-36a360c4da76","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|2f930181-6d21-4e1f-b111-455dda1ad15f",
    path: "/product/2f930181-6d21-4e1f-b111-455dda1ad15f",
    meta: {"ID":"2f930181-6d21-4e1f-b111-455dda1ad15f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|2faed33a-2871-41a1-beb9-0964f55c87e6",
    path: "/product/2faed33a-2871-41a1-beb9-0964f55c87e6",
    meta: {"ID":"2faed33a-2871-41a1-beb9-0964f55c87e6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|2fef3901-8f64-49dc-b9c6-18a5872858c6",
    path: "/product/2fef3901-8f64-49dc-b9c6-18a5872858c6",
    meta: {"ID":"2fef3901-8f64-49dc-b9c6-18a5872858c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|30042557-66d4-4679-ba31-3e06efb9c3d3",
    path: "/product/30042557-66d4-4679-ba31-3e06efb9c3d3",
    meta: {"ID":"30042557-66d4-4679-ba31-3e06efb9c3d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|302a8b57-aab3-465a-b8cd-b8f7d53bf2e9",
    path: "/product/302a8b57-aab3-465a-b8cd-b8f7d53bf2e9",
    meta: {"ID":"302a8b57-aab3-465a-b8cd-b8f7d53bf2e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|302f0100-5b77-4e95-a26f-53a0dc93e3f9",
    path: "/product/302f0100-5b77-4e95-a26f-53a0dc93e3f9",
    meta: {"ID":"302f0100-5b77-4e95-a26f-53a0dc93e3f9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|30324e08-bc5b-4850-ac1d-2dee025cbadf",
    path: "/product/30324e08-bc5b-4850-ac1d-2dee025cbadf",
    meta: {"ID":"30324e08-bc5b-4850-ac1d-2dee025cbadf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|30337d29-cd78-43f1-8ebb-96a3f0acae53",
    path: "/product/30337d29-cd78-43f1-8ebb-96a3f0acae53",
    meta: {"ID":"30337d29-cd78-43f1-8ebb-96a3f0acae53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3035e537-6402-421e-893f-3eb8aa434ff7",
    path: "/product/3035e537-6402-421e-893f-3eb8aa434ff7",
    meta: {"ID":"3035e537-6402-421e-893f-3eb8aa434ff7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|3067e467-10b4-4ec8-8604-0f1c55ad8568",
    path: "/product/3067e467-10b4-4ec8-8604-0f1c55ad8568",
    meta: {"ID":"3067e467-10b4-4ec8-8604-0f1c55ad8568","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|309a8171-9da0-4d08-ab22-6e4b2b728c71",
    path: "/product/309a8171-9da0-4d08-ab22-6e4b2b728c71",
    meta: {"ID":"309a8171-9da0-4d08-ab22-6e4b2b728c71","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|309affe3-9b0d-4d08-a569-7d69438ba580",
    path: "/product/309affe3-9b0d-4d08-a569-7d69438ba580",
    meta: {"ID":"309affe3-9b0d-4d08-a569-7d69438ba580","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|30c17e8d-1100-4925-a084-602baac38e16",
    path: "/product/30c17e8d-1100-4925-a084-602baac38e16",
    meta: {"ID":"30c17e8d-1100-4925-a084-602baac38e16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|57b1574f-a331-4e33-aedc-684887d5530e|30f5b2fa-3860-4f29-847c-7eeefa3da9b7",
    path: "/product/30f5b2fa-3860-4f29-847c-7eeefa3da9b7",
    meta: {"ID":"30f5b2fa-3860-4f29-847c-7eeefa3da9b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6cbf9c41-8e9c-4704-af3f-382f792c10c4|30fe29f9-5bab-4094-b00b-8052a9cfe2a8",
    path: "/product/30fe29f9-5bab-4094-b00b-8052a9cfe2a8",
    meta: {"ID":"30fe29f9-5bab-4094-b00b-8052a9cfe2a8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|3100be4d-bae6-4c92-bd15-d11a33c43245",
    path: "/product/3100be4d-bae6-4c92-bd15-d11a33c43245",
    meta: {"ID":"3100be4d-bae6-4c92-bd15-d11a33c43245","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1a496364-a63b-4683-aa31-12d5196add62|3100e96f-764a-4bd8-b729-aa42a10d5976",
    path: "/product/3100e96f-764a-4bd8-b729-aa42a10d5976",
    meta: {"ID":"3100e96f-764a-4bd8-b729-aa42a10d5976","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|31409c86-dc2f-4af8-9ceb-173eeb8dc6b3",
    path: "/product/31409c86-dc2f-4af8-9ceb-173eeb8dc6b3",
    meta: {"ID":"31409c86-dc2f-4af8-9ceb-173eeb8dc6b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3148b6d9-248e-46ec-9b6b-92ff64db14e9",
    path: "/product/3148b6d9-248e-46ec-9b6b-92ff64db14e9",
    meta: {"ID":"3148b6d9-248e-46ec-9b6b-92ff64db14e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|314e812a-b4f6-4c2e-a606-018c53aa3947",
    path: "/product/314e812a-b4f6-4c2e-a606-018c53aa3947",
    meta: {"ID":"314e812a-b4f6-4c2e-a606-018c53aa3947","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|3162fb71-821d-4d1b-b603-17dec2b8152b",
    path: "/product/3162fb71-821d-4d1b-b603-17dec2b8152b",
    meta: {"ID":"3162fb71-821d-4d1b-b603-17dec2b8152b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|317ba021-3246-441f-abe8-c795981aa257",
    path: "/product/317ba021-3246-441f-abe8-c795981aa257",
    meta: {"ID":"317ba021-3246-441f-abe8-c795981aa257","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|318d2149-16a7-4d33-9901-4be1a5b41ac7",
    path: "/product/318d2149-16a7-4d33-9901-4be1a5b41ac7",
    meta: {"ID":"318d2149-16a7-4d33-9901-4be1a5b41ac7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|318e7481-5e7e-4547-ac49-8eacd865330b",
    path: "/product/318e7481-5e7e-4547-ac49-8eacd865330b",
    meta: {"ID":"318e7481-5e7e-4547-ac49-8eacd865330b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|31b3ce86-b31e-4700-bed1-8d68f4e91cab",
    path: "/product/31b3ce86-b31e-4700-bed1-8d68f4e91cab",
    meta: {"ID":"31b3ce86-b31e-4700-bed1-8d68f4e91cab","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|31bc87e5-64f0-426f-8cb9-0f7786e7a25e",
    path: "/product/31bc87e5-64f0-426f-8cb9-0f7786e7a25e",
    meta: {"ID":"31bc87e5-64f0-426f-8cb9-0f7786e7a25e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|31c85c79-a237-444e-bc36-24ae1d80f9f3",
    path: "/product/31c85c79-a237-444e-bc36-24ae1d80f9f3",
    meta: {"ID":"31c85c79-a237-444e-bc36-24ae1d80f9f3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|31ccc128-bfcd-4f73-91d3-4381fb1455c5",
    path: "/product/31ccc128-bfcd-4f73-91d3-4381fb1455c5",
    meta: {"ID":"31ccc128-bfcd-4f73-91d3-4381fb1455c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|31ee15e8-816b-4908-8b30-bb8d1b6a4cca",
    path: "/product/31ee15e8-816b-4908-8b30-bb8d1b6a4cca",
    meta: {"ID":"31ee15e8-816b-4908-8b30-bb8d1b6a4cca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|31f571cc-fa17-43af-aa43-906eec20a769",
    path: "/product/31f571cc-fa17-43af-aa43-906eec20a769",
    meta: {"ID":"31f571cc-fa17-43af-aa43-906eec20a769","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|32053847-9f60-482b-9cf1-339d21a1855b",
    path: "/product/32053847-9f60-482b-9cf1-339d21a1855b",
    meta: {"ID":"32053847-9f60-482b-9cf1-339d21a1855b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|32094f2b-1661-435e-888e-d7fe3f2eac46",
    path: "/product/32094f2b-1661-435e-888e-d7fe3f2eac46",
    meta: {"ID":"32094f2b-1661-435e-888e-d7fe3f2eac46","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|321661e9-19e5-4366-a396-aa1dedadfe50",
    path: "/product/321661e9-19e5-4366-a396-aa1dedadfe50",
    meta: {"ID":"321661e9-19e5-4366-a396-aa1dedadfe50","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|32184f85-e7e5-4781-b704-0211b8445f5c",
    path: "/product/32184f85-e7e5-4781-b704-0211b8445f5c",
    meta: {"ID":"32184f85-e7e5-4781-b704-0211b8445f5c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3230b945-2ee3-465d-b5e7-3c6914ed9eed",
    path: "/product/3230b945-2ee3-465d-b5e7-3c6914ed9eed",
    meta: {"ID":"3230b945-2ee3-465d-b5e7-3c6914ed9eed","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3236946d-5672-442e-af70-86fecd5505d6",
    path: "/product/3236946d-5672-442e-af70-86fecd5505d6",
    meta: {"ID":"3236946d-5672-442e-af70-86fecd5505d6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|324729c2-8f77-4d3d-8b32-1ae953d8daf9",
    path: "/product/324729c2-8f77-4d3d-8b32-1ae953d8daf9",
    meta: {"ID":"324729c2-8f77-4d3d-8b32-1ae953d8daf9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|3247cab5-8665-4a25-b7b3-9db6e3f68d07",
    path: "/product/3247cab5-8665-4a25-b7b3-9db6e3f68d07",
    meta: {"ID":"3247cab5-8665-4a25-b7b3-9db6e3f68d07","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2baa7814-309c-4d94-b87a-916c5430072b|3258f31f-6532-4dde-9275-4a9a7ba60826",
    path: "/product/3258f31f-6532-4dde-9275-4a9a7ba60826",
    meta: {"ID":"3258f31f-6532-4dde-9275-4a9a7ba60826","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3264650c-3ba6-4b30-bc1f-2445a7094201",
    path: "/product/3264650c-3ba6-4b30-bc1f-2445a7094201",
    meta: {"ID":"3264650c-3ba6-4b30-bc1f-2445a7094201","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf8604ed-919e-4f96-8fa9-e0ed5a2f57dc|326959eb-949e-4c78-8627-2c72fa863f3e",
    path: "/product/326959eb-949e-4c78-8627-2c72fa863f3e",
    meta: {"ID":"326959eb-949e-4c78-8627-2c72fa863f3e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e2a1e7d9-7ca7-4450-84e5-be47dd4e74a5|326a3d7a-5b80-4f04-8f73-2a9621cedafd",
    path: "/product/326a3d7a-5b80-4f04-8f73-2a9621cedafd",
    meta: {"ID":"326a3d7a-5b80-4f04-8f73-2a9621cedafd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|326b7918-4591-4d19-a3f6-24e3d7775509",
    path: "/product/326b7918-4591-4d19-a3f6-24e3d7775509",
    meta: {"ID":"326b7918-4591-4d19-a3f6-24e3d7775509","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|326fca66-fb3c-4ed7-ab1c-db7e7d818009",
    path: "/product/326fca66-fb3c-4ed7-ab1c-db7e7d818009",
    meta: {"ID":"326fca66-fb3c-4ed7-ab1c-db7e7d818009","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3270eae5-382a-4653-943a-dcdad33c54bc",
    path: "/product/3270eae5-382a-4653-943a-dcdad33c54bc",
    meta: {"ID":"3270eae5-382a-4653-943a-dcdad33c54bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3273707d-e9b8-4171-b1a4-758178f570e2",
    path: "/product/3273707d-e9b8-4171-b1a4-758178f570e2",
    meta: {"ID":"3273707d-e9b8-4171-b1a4-758178f570e2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|328e0a42-163f-436b-8d7b-16c0cc2ffedf",
    path: "/product/328e0a42-163f-436b-8d7b-16c0cc2ffedf",
    meta: {"ID":"328e0a42-163f-436b-8d7b-16c0cc2ffedf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|32915f75-ab4d-4040-8f39-10e0700918ad",
    path: "/product/32915f75-ab4d-4040-8f39-10e0700918ad",
    meta: {"ID":"32915f75-ab4d-4040-8f39-10e0700918ad","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|32a99b94-da6c-457b-b7fc-b957decf201e",
    path: "/product/32a99b94-da6c-457b-b7fc-b957decf201e",
    meta: {"ID":"32a99b94-da6c-457b-b7fc-b957decf201e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|32d3bcd8-9e08-437e-978f-320abb291c7f",
    path: "/product/32d3bcd8-9e08-437e-978f-320abb291c7f",
    meta: {"ID":"32d3bcd8-9e08-437e-978f-320abb291c7f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|32e5e80d-c42a-4c20-a232-9949df015ff4",
    path: "/product/32e5e80d-c42a-4c20-a232-9949df015ff4",
    meta: {"ID":"32e5e80d-c42a-4c20-a232-9949df015ff4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|332d1ffe-5286-407e-8fbf-fa4b937f6479",
    path: "/product/332d1ffe-5286-407e-8fbf-fa4b937f6479",
    meta: {"ID":"332d1ffe-5286-407e-8fbf-fa4b937f6479","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|334fb822-373b-49a3-911c-86e16ecbc1f1",
    path: "/product/334fb822-373b-49a3-911c-86e16ecbc1f1",
    meta: {"ID":"334fb822-373b-49a3-911c-86e16ecbc1f1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|33528f2c-3350-4a75-a41d-03b6883e3f10",
    path: "/product/33528f2c-3350-4a75-a41d-03b6883e3f10",
    meta: {"ID":"33528f2c-3350-4a75-a41d-03b6883e3f10","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3379dce3-8ccc-4ac5-858c-fca4f99f1115",
    path: "/product/3379dce3-8ccc-4ac5-858c-fca4f99f1115",
    meta: {"ID":"3379dce3-8ccc-4ac5-858c-fca4f99f1115","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|59b73946-d0e9-47cf-82f0-0d5c22e3862b|337d065b-1380-440f-a20c-c98ed5d499cd",
    path: "/product/337d065b-1380-440f-a20c-c98ed5d499cd",
    meta: {"ID":"337d065b-1380-440f-a20c-c98ed5d499cd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|337e4d99-7aec-4ec6-957f-c8461082f4ef",
    path: "/product/337e4d99-7aec-4ec6-957f-c8461082f4ef",
    meta: {"ID":"337e4d99-7aec-4ec6-957f-c8461082f4ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|33878f55-a582-4069-8540-dda524f79c23",
    path: "/product/33878f55-a582-4069-8540-dda524f79c23",
    meta: {"ID":"33878f55-a582-4069-8540-dda524f79c23","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|33a90b03-08a1-41cb-a23d-1ee71754a492",
    path: "/product/33a90b03-08a1-41cb-a23d-1ee71754a492",
    meta: {"ID":"33a90b03-08a1-41cb-a23d-1ee71754a492","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|33ad00ba-b2bc-4c09-bbde-58715e890d90",
    path: "/product/33ad00ba-b2bc-4c09-bbde-58715e890d90",
    meta: {"ID":"33ad00ba-b2bc-4c09-bbde-58715e890d90","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|33b86c00-e61c-49d3-82f7-571aad7466e4",
    path: "/product/33b86c00-e61c-49d3-82f7-571aad7466e4",
    meta: {"ID":"33b86c00-e61c-49d3-82f7-571aad7466e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|33bdf94d-6203-486d-bd6e-2dbf76eab1b4",
    path: "/product/33bdf94d-6203-486d-bd6e-2dbf76eab1b4",
    meta: {"ID":"33bdf94d-6203-486d-bd6e-2dbf76eab1b4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|33c56b1f-dc8f-41c0-ad41-7973da71dea2",
    path: "/product/33c56b1f-dc8f-41c0-ad41-7973da71dea2",
    meta: {"ID":"33c56b1f-dc8f-41c0-ad41-7973da71dea2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|33d13f0a-2878-431f-a7e5-c9853b8195fb",
    path: "/product/33d13f0a-2878-431f-a7e5-c9853b8195fb",
    meta: {"ID":"33d13f0a-2878-431f-a7e5-c9853b8195fb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|33e54821-3642-413e-9847-b9eac180ad12",
    path: "/product/33e54821-3642-413e-9847-b9eac180ad12",
    meta: {"ID":"33e54821-3642-413e-9847-b9eac180ad12","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|33e75302-bad7-41ef-b8e3-67811cc442b3",
    path: "/product/33e75302-bad7-41ef-b8e3-67811cc442b3",
    meta: {"ID":"33e75302-bad7-41ef-b8e3-67811cc442b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|33ee79ab-acda-4082-86e2-26bcb9769a8d",
    path: "/product/33ee79ab-acda-4082-86e2-26bcb9769a8d",
    meta: {"ID":"33ee79ab-acda-4082-86e2-26bcb9769a8d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|33f8c0be-956a-434c-b6fb-96243699c116",
    path: "/product/33f8c0be-956a-434c-b6fb-96243699c116",
    meta: {"ID":"33f8c0be-956a-434c-b6fb-96243699c116","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|340bdbd3-ed05-4ff4-8bcb-e5cefaac083e",
    path: "/product/340bdbd3-ed05-4ff4-8bcb-e5cefaac083e",
    meta: {"ID":"340bdbd3-ed05-4ff4-8bcb-e5cefaac083e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|34317e6f-ad86-4f12-bd7d-e69d9c5b46bb",
    path: "/product/34317e6f-ad86-4f12-bd7d-e69d9c5b46bb",
    meta: {"ID":"34317e6f-ad86-4f12-bd7d-e69d9c5b46bb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|345ec325-67a9-4b3b-8d1d-6533dcf39f1b",
    path: "/product/345ec325-67a9-4b3b-8d1d-6533dcf39f1b",
    meta: {"ID":"345ec325-67a9-4b3b-8d1d-6533dcf39f1b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|346b8cf2-9d28-4a05-89f9-430886b072b0",
    path: "/product/346b8cf2-9d28-4a05-89f9-430886b072b0",
    meta: {"ID":"346b8cf2-9d28-4a05-89f9-430886b072b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|34908611-ef6e-4ee8-9781-1c1a0b3ed1d8",
    path: "/product/34908611-ef6e-4ee8-9781-1c1a0b3ed1d8",
    meta: {"ID":"34908611-ef6e-4ee8-9781-1c1a0b3ed1d8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|3491545c-ceae-43fb-b809-0c34d59ee60e",
    path: "/product/3491545c-ceae-43fb-b809-0c34d59ee60e",
    meta: {"ID":"3491545c-ceae-43fb-b809-0c34d59ee60e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3495ac65-f292-4925-98ca-93dbe24041e2",
    path: "/product/3495ac65-f292-4925-98ca-93dbe24041e2",
    meta: {"ID":"3495ac65-f292-4925-98ca-93dbe24041e2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|349cb795-1340-441a-b230-c726f95a1977",
    path: "/product/349cb795-1340-441a-b230-c726f95a1977",
    meta: {"ID":"349cb795-1340-441a-b230-c726f95a1977","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|68b13f59-5b5e-4f71-90ae-eadb72690750|34bbf9a6-f933-47f7-9317-5b686856ef28",
    path: "/product/34bbf9a6-f933-47f7-9317-5b686856ef28",
    meta: {"ID":"34bbf9a6-f933-47f7-9317-5b686856ef28","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|34d15184-5b88-456a-8e6b-85c052710a16",
    path: "/product/34d15184-5b88-456a-8e6b-85c052710a16",
    meta: {"ID":"34d15184-5b88-456a-8e6b-85c052710a16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|34d53907-f90c-4995-b061-074ae8377a76",
    path: "/product/34d53907-f90c-4995-b061-074ae8377a76",
    meta: {"ID":"34d53907-f90c-4995-b061-074ae8377a76","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|34d8bfaf-4261-42e6-9c52-09ba996154b5",
    path: "/product/34d8bfaf-4261-42e6-9c52-09ba996154b5",
    meta: {"ID":"34d8bfaf-4261-42e6-9c52-09ba996154b5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|34ebae76-7018-4d13-9131-b912561b7aec",
    path: "/product/34ebae76-7018-4d13-9131-b912561b7aec",
    meta: {"ID":"34ebae76-7018-4d13-9131-b912561b7aec","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|34f0aa41-f75d-436e-acb6-cf0b4ecec89a",
    path: "/product/34f0aa41-f75d-436e-acb6-cf0b4ecec89a",
    meta: {"ID":"34f0aa41-f75d-436e-acb6-cf0b4ecec89a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|34faf44a-0a77-4015-8b75-ec7833386463",
    path: "/product/34faf44a-0a77-4015-8b75-ec7833386463",
    meta: {"ID":"34faf44a-0a77-4015-8b75-ec7833386463","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|35067b06-c8d5-4527-bd22-88a77fad0616",
    path: "/product/35067b06-c8d5-4527-bd22-88a77fad0616",
    meta: {"ID":"35067b06-c8d5-4527-bd22-88a77fad0616","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|350f8065-60e7-41f8-8417-770701c54dfa",
    path: "/product/350f8065-60e7-41f8-8417-770701c54dfa",
    meta: {"ID":"350f8065-60e7-41f8-8417-770701c54dfa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|53b9bd6b-ecab-409e-b3e8-22b81c702f83|3515f7bf-0363-4dc0-a42f-f85b70cade4e",
    path: "/product/3515f7bf-0363-4dc0-a42f-f85b70cade4e",
    meta: {"ID":"3515f7bf-0363-4dc0-a42f-f85b70cade4e","Title":"","GroupID":"53b9bd6b-ecab-409e-b3e8-22b81c702f83","GroupTitle":"Паста и Ризотто","GroupLink":"/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|35a37ce8-79de-40a1-bdc7-ff0c62033e02",
    path: "/product/35a37ce8-79de-40a1-bdc7-ff0c62033e02",
    meta: {"ID":"35a37ce8-79de-40a1-bdc7-ff0c62033e02","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|35a3c4e1-2267-42ec-a720-2b1433007110",
    path: "/product/35a3c4e1-2267-42ec-a720-2b1433007110",
    meta: {"ID":"35a3c4e1-2267-42ec-a720-2b1433007110","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|35be9ea5-0c60-473c-a591-2faafd5fc9c3",
    path: "/product/35be9ea5-0c60-473c-a591-2faafd5fc9c3",
    meta: {"ID":"35be9ea5-0c60-473c-a591-2faafd5fc9c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|35cc9028-c565-42af-baf3-67887416283b",
    path: "/product/35cc9028-c565-42af-baf3-67887416283b",
    meta: {"ID":"35cc9028-c565-42af-baf3-67887416283b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|35d4cb00-6443-41e5-97ee-eb750ecaebcd",
    path: "/product/35d4cb00-6443-41e5-97ee-eb750ecaebcd",
    meta: {"ID":"35d4cb00-6443-41e5-97ee-eb750ecaebcd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3602b745-1deb-44fe-a290-f023c8c1cc17",
    path: "/product/3602b745-1deb-44fe-a290-f023c8c1cc17",
    meta: {"ID":"3602b745-1deb-44fe-a290-f023c8c1cc17","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|362ef84c-4c61-4cf8-bfa3-82a3849d38d1",
    path: "/product/362ef84c-4c61-4cf8-bfa3-82a3849d38d1",
    meta: {"ID":"362ef84c-4c61-4cf8-bfa3-82a3849d38d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|36456b6a-b598-4269-bd38-3efdb697bfc6",
    path: "/product/36456b6a-b598-4269-bd38-3efdb697bfc6",
    meta: {"ID":"36456b6a-b598-4269-bd38-3efdb697bfc6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|365f4945-1d4d-47fe-9057-496fc78e5494",
    path: "/product/365f4945-1d4d-47fe-9057-496fc78e5494",
    meta: {"ID":"365f4945-1d4d-47fe-9057-496fc78e5494","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3676d847-e93c-4335-a608-ade30d424ace",
    path: "/product/3676d847-e93c-4335-a608-ade30d424ace",
    meta: {"ID":"3676d847-e93c-4335-a608-ade30d424ace","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|36856c24-f3f8-4a6e-82b5-e6eb3dc73532",
    path: "/product/36856c24-f3f8-4a6e-82b5-e6eb3dc73532",
    meta: {"ID":"36856c24-f3f8-4a6e-82b5-e6eb3dc73532","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|36ae9a18-49d4-4f31-adab-d7a1012d3daf",
    path: "/product/36ae9a18-49d4-4f31-adab-d7a1012d3daf",
    meta: {"ID":"36ae9a18-49d4-4f31-adab-d7a1012d3daf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|36b82bec-6aae-4ec7-a2f2-4e4acb3f78d1",
    path: "/product/36b82bec-6aae-4ec7-a2f2-4e4acb3f78d1",
    meta: {"ID":"36b82bec-6aae-4ec7-a2f2-4e4acb3f78d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|36d26784-9e45-490c-a164-4ee80bb959e6",
    path: "/product/36d26784-9e45-490c-a164-4ee80bb959e6",
    meta: {"ID":"36d26784-9e45-490c-a164-4ee80bb959e6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|36e18b8f-72ad-4902-a158-d8d4a40873fb",
    path: "/product/36e18b8f-72ad-4902-a158-d8d4a40873fb",
    meta: {"ID":"36e18b8f-72ad-4902-a158-d8d4a40873fb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3761200c-5335-47c4-ba76-f58184d9f04c",
    path: "/product/3761200c-5335-47c4-ba76-f58184d9f04c",
    meta: {"ID":"3761200c-5335-47c4-ba76-f58184d9f04c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|37775246-8b02-4662-bee0-7705ec9c80ef",
    path: "/product/37775246-8b02-4662-bee0-7705ec9c80ef",
    meta: {"ID":"37775246-8b02-4662-bee0-7705ec9c80ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|377e1543-6cd1-45e0-b2af-1a9791efd84d",
    path: "/product/377e1543-6cd1-45e0-b2af-1a9791efd84d",
    meta: {"ID":"377e1543-6cd1-45e0-b2af-1a9791efd84d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|378d4117-f502-4d83-8ae6-17602f0b072d",
    path: "/product/378d4117-f502-4d83-8ae6-17602f0b072d",
    meta: {"ID":"378d4117-f502-4d83-8ae6-17602f0b072d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|37dbedbb-92f2-494d-a11f-1a6b2eb4ddac",
    path: "/product/37dbedbb-92f2-494d-a11f-1a6b2eb4ddac",
    meta: {"ID":"37dbedbb-92f2-494d-a11f-1a6b2eb4ddac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|37f49488-244f-4602-b29a-0df9b3e4ee0c",
    path: "/product/37f49488-244f-4602-b29a-0df9b3e4ee0c",
    meta: {"ID":"37f49488-244f-4602-b29a-0df9b3e4ee0c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|38430022-fed2-4db4-9f82-07e0249e01a7",
    path: "/product/38430022-fed2-4db4-9f82-07e0249e01a7",
    meta: {"ID":"38430022-fed2-4db4-9f82-07e0249e01a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|3853e4eb-a9da-4712-8bbe-f1788d3193c3",
    path: "/product/3853e4eb-a9da-4712-8bbe-f1788d3193c3",
    meta: {"ID":"3853e4eb-a9da-4712-8bbe-f1788d3193c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|385557be-fa8a-4083-b81d-d8c2ae9969ae",
    path: "/product/385557be-fa8a-4083-b81d-d8c2ae9969ae",
    meta: {"ID":"385557be-fa8a-4083-b81d-d8c2ae9969ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|82890741-f41f-4593-bd5b-e63c6af5f1ed|3855eb4b-15be-43e2-837e-b7e6cc6d3cd9",
    path: "/product/3855eb4b-15be-43e2-837e-b7e6cc6d3cd9",
    meta: {"ID":"3855eb4b-15be-43e2-837e-b7e6cc6d3cd9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|387324d6-f966-43c3-9005-d13d7497868a",
    path: "/product/387324d6-f966-43c3-9005-d13d7497868a",
    meta: {"ID":"387324d6-f966-43c3-9005-d13d7497868a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3|3879e884-2abb-4e9f-b478-e14514bec9af",
    path: "/product/3879e884-2abb-4e9f-b478-e14514bec9af",
    meta: {"ID":"3879e884-2abb-4e9f-b478-e14514bec9af","Title":"","GroupID":"5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","GroupTitle":"Салаты","GroupLink":"/menu/5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3891fd91-31b0-4d39-a06d-1cae69283bb4",
    path: "/product/3891fd91-31b0-4d39-a06d-1cae69283bb4",
    meta: {"ID":"3891fd91-31b0-4d39-a06d-1cae69283bb4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|38964371-5d08-41e0-982a-fb1f5e609e9d",
    path: "/product/38964371-5d08-41e0-982a-fb1f5e609e9d",
    meta: {"ID":"38964371-5d08-41e0-982a-fb1f5e609e9d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|38ca1876-8bdd-4210-8c3a-43b1a77a06eb",
    path: "/product/38ca1876-8bdd-4210-8c3a-43b1a77a06eb",
    meta: {"ID":"38ca1876-8bdd-4210-8c3a-43b1a77a06eb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|390684ba-cf90-4f67-9e09-2257e0e36e87",
    path: "/product/390684ba-cf90-4f67-9e09-2257e0e36e87",
    meta: {"ID":"390684ba-cf90-4f67-9e09-2257e0e36e87","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|391aba9b-7735-4df9-9c9e-fefc3a2b5575",
    path: "/product/391aba9b-7735-4df9-9c9e-fefc3a2b5575",
    meta: {"ID":"391aba9b-7735-4df9-9c9e-fefc3a2b5575","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|394a79dd-a8d3-4d72-bbbe-bfc2f50e1a79",
    path: "/product/394a79dd-a8d3-4d72-bbbe-bfc2f50e1a79",
    meta: {"ID":"394a79dd-a8d3-4d72-bbbe-bfc2f50e1a79","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|394fd068-838a-49fc-982a-3fc058ee49dc",
    path: "/product/394fd068-838a-49fc-982a-3fc058ee49dc",
    meta: {"ID":"394fd068-838a-49fc-982a-3fc058ee49dc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|3954562d-eda0-4719-8930-be858a14848e",
    path: "/product/3954562d-eda0-4719-8930-be858a14848e",
    meta: {"ID":"3954562d-eda0-4719-8930-be858a14848e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3967fcb1-9530-48ba-9bf9-b56d64fd2822",
    path: "/product/3967fcb1-9530-48ba-9bf9-b56d64fd2822",
    meta: {"ID":"3967fcb1-9530-48ba-9bf9-b56d64fd2822","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|396c3a79-bda7-430d-9b07-7cd91042a255",
    path: "/product/396c3a79-bda7-430d-9b07-7cd91042a255",
    meta: {"ID":"396c3a79-bda7-430d-9b07-7cd91042a255","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3973eb70-fe6c-45e2-8a1e-6faa97506934",
    path: "/product/3973eb70-fe6c-45e2-8a1e-6faa97506934",
    meta: {"ID":"3973eb70-fe6c-45e2-8a1e-6faa97506934","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c20c30ec-cf97-4ef6-bc06-9e04305c93ad|39878087-3637-4935-9d4c-b9209bddc5be",
    path: "/product/39878087-3637-4935-9d4c-b9209bddc5be",
    meta: {"ID":"39878087-3637-4935-9d4c-b9209bddc5be","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|399b47a5-d167-4c37-bc8e-b362a6994054",
    path: "/product/399b47a5-d167-4c37-bc8e-b362a6994054",
    meta: {"ID":"399b47a5-d167-4c37-bc8e-b362a6994054","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|39db3833-c5b8-4188-8d1f-ad704cf04739",
    path: "/product/39db3833-c5b8-4188-8d1f-ad704cf04739",
    meta: {"ID":"39db3833-c5b8-4188-8d1f-ad704cf04739","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|39ef54ed-3837-4bde-ba11-104e5001b6b7",
    path: "/product/39ef54ed-3837-4bde-ba11-104e5001b6b7",
    meta: {"ID":"39ef54ed-3837-4bde-ba11-104e5001b6b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7b8f0d1b-6e98-4d6a-be01-03b913fcdb44|39f44567-9684-48b2-8e1b-b7637ef70fe8",
    path: "/product/39f44567-9684-48b2-8e1b-b7637ef70fe8",
    meta: {"ID":"39f44567-9684-48b2-8e1b-b7637ef70fe8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|3a0a4bb6-963c-4e2d-87d9-2faf8b4b0cfa",
    path: "/product/3a0a4bb6-963c-4e2d-87d9-2faf8b4b0cfa",
    meta: {"ID":"3a0a4bb6-963c-4e2d-87d9-2faf8b4b0cfa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|3a1e9405-0259-4517-a111-8c4105b70c33",
    path: "/product/3a1e9405-0259-4517-a111-8c4105b70c33",
    meta: {"ID":"3a1e9405-0259-4517-a111-8c4105b70c33","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3a27e0d3-c021-4be0-bed2-e08292d57ec2",
    path: "/product/3a27e0d3-c021-4be0-bed2-e08292d57ec2",
    meta: {"ID":"3a27e0d3-c021-4be0-bed2-e08292d57ec2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3a280515-25bf-46a1-b80c-9dcfd9ef52e0",
    path: "/product/3a280515-25bf-46a1-b80c-9dcfd9ef52e0",
    meta: {"ID":"3a280515-25bf-46a1-b80c-9dcfd9ef52e0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3c06d685-8171-454b-920a-f3a76b0a4226|3a34c396-4ccd-4a12-b246-dff9946702b2",
    path: "/product/3a34c396-4ccd-4a12-b246-dff9946702b2",
    meta: {"ID":"3a34c396-4ccd-4a12-b246-dff9946702b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1a496364-a63b-4683-aa31-12d5196add62|3a357957-18a2-40f9-abbc-5e308f8494f7",
    path: "/product/3a357957-18a2-40f9-abbc-5e308f8494f7",
    meta: {"ID":"3a357957-18a2-40f9-abbc-5e308f8494f7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|59b73946-d0e9-47cf-82f0-0d5c22e3862b|3a683965-8af2-4f07-a8fa-84fafe1a227d",
    path: "/product/3a683965-8af2-4f07-a8fa-84fafe1a227d",
    meta: {"ID":"3a683965-8af2-4f07-a8fa-84fafe1a227d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3a6cc210-5de3-4e17-b266-a49f30ae2587",
    path: "/product/3a6cc210-5de3-4e17-b266-a49f30ae2587",
    meta: {"ID":"3a6cc210-5de3-4e17-b266-a49f30ae2587","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3a7306a4-20ef-48d5-ab3d-d8f4c269e90d",
    path: "/product/3a7306a4-20ef-48d5-ab3d-d8f4c269e90d",
    meta: {"ID":"3a7306a4-20ef-48d5-ab3d-d8f4c269e90d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|3a8390d6-a328-4af6-a4a3-3cb5613a9d99",
    path: "/product/3a8390d6-a328-4af6-a4a3-3cb5613a9d99",
    meta: {"ID":"3a8390d6-a328-4af6-a4a3-3cb5613a9d99","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|64599942-da85-4d3c-9234-5d5319216105|3a964d15-a3e8-4e42-8193-803956ce8afa",
    path: "/product/3a964d15-a3e8-4e42-8193-803956ce8afa",
    meta: {"ID":"3a964d15-a3e8-4e42-8193-803956ce8afa","Title":"","GroupID":"64599942-da85-4d3c-9234-5d5319216105","GroupTitle":"Римская пицца","GroupLink":"/menu/64599942-da85-4d3c-9234-5d5319216105","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|3a998a62-08ba-44cc-8481-92b1652c5764",
    path: "/product/3a998a62-08ba-44cc-8481-92b1652c5764",
    meta: {"ID":"3a998a62-08ba-44cc-8481-92b1652c5764","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|3aa7806a-5984-4daf-a995-8a1938804ef9",
    path: "/product/3aa7806a-5984-4daf-a995-8a1938804ef9",
    meta: {"ID":"3aa7806a-5984-4daf-a995-8a1938804ef9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3ae329f8-095a-49cd-b2b7-b227ffcf8df5",
    path: "/product/3ae329f8-095a-49cd-b2b7-b227ffcf8df5",
    meta: {"ID":"3ae329f8-095a-49cd-b2b7-b227ffcf8df5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|3aea6d76-613e-4597-b760-e1b0788a2b72",
    path: "/product/3aea6d76-613e-4597-b760-e1b0788a2b72",
    meta: {"ID":"3aea6d76-613e-4597-b760-e1b0788a2b72","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3af3c094-2f74-4d3e-854a-902842e1fe45",
    path: "/product/3af3c094-2f74-4d3e-854a-902842e1fe45",
    meta: {"ID":"3af3c094-2f74-4d3e-854a-902842e1fe45","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3b0b6c5f-e440-4af9-aefa-a33f05d42b75",
    path: "/product/3b0b6c5f-e440-4af9-aefa-a33f05d42b75",
    meta: {"ID":"3b0b6c5f-e440-4af9-aefa-a33f05d42b75","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3b29bca3-2c7d-4d31-844c-6a74e577c955",
    path: "/product/3b29bca3-2c7d-4d31-844c-6a74e577c955",
    meta: {"ID":"3b29bca3-2c7d-4d31-844c-6a74e577c955","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|3b443a8f-f3ed-48b8-967e-0caa4d553586",
    path: "/product/3b443a8f-f3ed-48b8-967e-0caa4d553586",
    meta: {"ID":"3b443a8f-f3ed-48b8-967e-0caa4d553586","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5cb1bd94-d83d-4b23-9469-0ddc318e5ee6|3b51245d-3099-400a-9e87-cbd8f7873f3f",
    path: "/product/3b51245d-3099-400a-9e87-cbd8f7873f3f",
    meta: {"ID":"3b51245d-3099-400a-9e87-cbd8f7873f3f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3b6ae378-980f-47d2-a1cf-4225f94ee108",
    path: "/product/3b6ae378-980f-47d2-a1cf-4225f94ee108",
    meta: {"ID":"3b6ae378-980f-47d2-a1cf-4225f94ee108","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|3b6e125e-0344-47d2-9f95-3d6c218fe9fe",
    path: "/product/3b6e125e-0344-47d2-9f95-3d6c218fe9fe",
    meta: {"ID":"3b6e125e-0344-47d2-9f95-3d6c218fe9fe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|35ffc5db-5f3c-44a5-bdfa-0922bfd20da6|3b80e554-12d7-4a99-b68f-be11920e2fd8",
    path: "/product/3b80e554-12d7-4a99-b68f-be11920e2fd8",
    meta: {"ID":"3b80e554-12d7-4a99-b68f-be11920e2fd8","Title":"","GroupID":"35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","GroupTitle":"Поке и вок","GroupLink":"/menu/35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3b8dc99a-8845-4875-8087-73925034e7e9",
    path: "/product/3b8dc99a-8845-4875-8087-73925034e7e9",
    meta: {"ID":"3b8dc99a-8845-4875-8087-73925034e7e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|3b9fcde7-c78b-4ab4-8d57-43d022568e83",
    path: "/product/3b9fcde7-c78b-4ab4-8d57-43d022568e83",
    meta: {"ID":"3b9fcde7-c78b-4ab4-8d57-43d022568e83","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ef0a0b3c-71b8-40c4-9f16-3c9038b9738d|3bbc1172-db53-4543-b0a1-4f98097d155d",
    path: "/product/3bbc1172-db53-4543-b0a1-4f98097d155d",
    meta: {"ID":"3bbc1172-db53-4543-b0a1-4f98097d155d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|3bc5ad1b-56a3-4909-b66b-c3aa7196a203",
    path: "/product/3bc5ad1b-56a3-4909-b66b-c3aa7196a203",
    meta: {"ID":"3bc5ad1b-56a3-4909-b66b-c3aa7196a203","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|3befe6ce-3704-44fc-bf2c-3aed33b27292",
    path: "/product/3befe6ce-3704-44fc-bf2c-3aed33b27292",
    meta: {"ID":"3befe6ce-3704-44fc-bf2c-3aed33b27292","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|3c1b59fb-6853-41ec-9b78-71c2a8b6432d",
    path: "/product/3c1b59fb-6853-41ec-9b78-71c2a8b6432d",
    meta: {"ID":"3c1b59fb-6853-41ec-9b78-71c2a8b6432d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3c28067b-1fd6-4d37-92a7-66e368bb6466",
    path: "/product/3c28067b-1fd6-4d37-92a7-66e368bb6466",
    meta: {"ID":"3c28067b-1fd6-4d37-92a7-66e368bb6466","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3c3bed85-f9d6-4c2b-bc5c-875fe3c69588",
    path: "/product/3c3bed85-f9d6-4c2b-bc5c-875fe3c69588",
    meta: {"ID":"3c3bed85-f9d6-4c2b-bc5c-875fe3c69588","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|61920873-b03c-43ac-aa78-5e5acd2bdfe1|3c4ffa5c-83ed-4f4b-a332-5252c6e59778",
    path: "/product/3c4ffa5c-83ed-4f4b-a332-5252c6e59778",
    meta: {"ID":"3c4ffa5c-83ed-4f4b-a332-5252c6e59778","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3c85dc9d-faa6-4a09-9dc1-4da90f3ea6b7",
    path: "/product/3c85dc9d-faa6-4a09-9dc1-4da90f3ea6b7",
    meta: {"ID":"3c85dc9d-faa6-4a09-9dc1-4da90f3ea6b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|3c8ab1c5-946e-458f-a63a-4aa5094342c1",
    path: "/product/3c8ab1c5-946e-458f-a63a-4aa5094342c1",
    meta: {"ID":"3c8ab1c5-946e-458f-a63a-4aa5094342c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3c966dbc-b1c6-4447-bb1f-065a626b2806",
    path: "/product/3c966dbc-b1c6-4447-bb1f-065a626b2806",
    meta: {"ID":"3c966dbc-b1c6-4447-bb1f-065a626b2806","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3c9bf1d0-70c7-4d0b-b12b-bd5fb010e1d5",
    path: "/product/3c9bf1d0-70c7-4d0b-b12b-bd5fb010e1d5",
    meta: {"ID":"3c9bf1d0-70c7-4d0b-b12b-bd5fb010e1d5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|3cc83202-84f2-41d8-980d-e443d682ab56",
    path: "/product/3cc83202-84f2-41d8-980d-e443d682ab56",
    meta: {"ID":"3cc83202-84f2-41d8-980d-e443d682ab56","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|703bc4c8-03f6-4fb4-b63b-06742e85e924|3cd09a63-9f96-4f47-9831-88cf297d118e",
    path: "/product/3cd09a63-9f96-4f47-9831-88cf297d118e",
    meta: {"ID":"3cd09a63-9f96-4f47-9831-88cf297d118e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|3ce339f4-49e6-4ea8-947b-b0a567e25853",
    path: "/product/3ce339f4-49e6-4ea8-947b-b0a567e25853",
    meta: {"ID":"3ce339f4-49e6-4ea8-947b-b0a567e25853","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3ce66bf3-10b8-446d-8e3c-03f1746de85a",
    path: "/product/3ce66bf3-10b8-446d-8e3c-03f1746de85a",
    meta: {"ID":"3ce66bf3-10b8-446d-8e3c-03f1746de85a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|3ce882b5-7a39-4b61-bcfc-dab9d5303984",
    path: "/product/3ce882b5-7a39-4b61-bcfc-dab9d5303984",
    meta: {"ID":"3ce882b5-7a39-4b61-bcfc-dab9d5303984","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2d9bf239-b509-4129-9d84-16287bf039d0|3cf8d251-139d-49b3-9802-dd9b1afaf3a5",
    path: "/product/3cf8d251-139d-49b3-9802-dd9b1afaf3a5",
    meta: {"ID":"3cf8d251-139d-49b3-9802-dd9b1afaf3a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|3d11d107-b639-4e46-b97d-5c76ad582ec2",
    path: "/product/3d11d107-b639-4e46-b97d-5c76ad582ec2",
    meta: {"ID":"3d11d107-b639-4e46-b97d-5c76ad582ec2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|3d2139e3-710b-4dcc-a008-bb4b575fb31c",
    path: "/product/3d2139e3-710b-4dcc-a008-bb4b575fb31c",
    meta: {"ID":"3d2139e3-710b-4dcc-a008-bb4b575fb31c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|3d27a9d2-a530-4cff-9d23-53c5022ef9a9",
    path: "/product/3d27a9d2-a530-4cff-9d23-53c5022ef9a9",
    meta: {"ID":"3d27a9d2-a530-4cff-9d23-53c5022ef9a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3d3ba7fe-7499-43ae-a5d8-9eb40a260fbc",
    path: "/product/3d3ba7fe-7499-43ae-a5d8-9eb40a260fbc",
    meta: {"ID":"3d3ba7fe-7499-43ae-a5d8-9eb40a260fbc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3d3fc22f-3057-4778-a404-db9667e8fb1b",
    path: "/product/3d3fc22f-3057-4778-a404-db9667e8fb1b",
    meta: {"ID":"3d3fc22f-3057-4778-a404-db9667e8fb1b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf8604ed-919e-4f96-8fa9-e0ed5a2f57dc|3d741066-9d73-4b99-9006-90404f010e98",
    path: "/product/3d741066-9d73-4b99-9006-90404f010e98",
    meta: {"ID":"3d741066-9d73-4b99-9006-90404f010e98","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3d7d9121-e98d-4817-85e9-5b3abb13b1f3",
    path: "/product/3d7d9121-e98d-4817-85e9-5b3abb13b1f3",
    meta: {"ID":"3d7d9121-e98d-4817-85e9-5b3abb13b1f3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3d7de2a1-7814-4cab-bac9-267075fc9de3",
    path: "/product/3d7de2a1-7814-4cab-bac9-267075fc9de3",
    meta: {"ID":"3d7de2a1-7814-4cab-bac9-267075fc9de3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3d84b296-9734-4261-a422-f2539156e029",
    path: "/product/3d84b296-9734-4261-a422-f2539156e029",
    meta: {"ID":"3d84b296-9734-4261-a422-f2539156e029","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|967d3966-c5f3-487e-a085-8e9a8fe858bf|3d8b6e60-c75a-4476-9b1a-0ab08298773a",
    path: "/product/3d8b6e60-c75a-4476-9b1a-0ab08298773a",
    meta: {"ID":"3d8b6e60-c75a-4476-9b1a-0ab08298773a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3d915c38-f028-4bc3-b22d-7f2fe4959d01",
    path: "/product/3d915c38-f028-4bc3-b22d-7f2fe4959d01",
    meta: {"ID":"3d915c38-f028-4bc3-b22d-7f2fe4959d01","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|3d96a538-1ba3-46ee-8a0c-10fb16f3529b",
    path: "/product/3d96a538-1ba3-46ee-8a0c-10fb16f3529b",
    meta: {"ID":"3d96a538-1ba3-46ee-8a0c-10fb16f3529b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|3dae0910-8e54-444a-855d-559c352c8e8b",
    path: "/product/3dae0910-8e54-444a-855d-559c352c8e8b",
    meta: {"ID":"3dae0910-8e54-444a-855d-559c352c8e8b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|3db9bf4f-03cd-4fb5-b4ec-42156e271c78",
    path: "/product/3db9bf4f-03cd-4fb5-b4ec-42156e271c78",
    meta: {"ID":"3db9bf4f-03cd-4fb5-b4ec-42156e271c78","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|3dbf0322-d5b9-467c-a485-3f469dc80142",
    path: "/product/3dbf0322-d5b9-467c-a485-3f469dc80142",
    meta: {"ID":"3dbf0322-d5b9-467c-a485-3f469dc80142","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3dd7f374-a0e3-4301-9182-f817b2915309",
    path: "/product/3dd7f374-a0e3-4301-9182-f817b2915309",
    meta: {"ID":"3dd7f374-a0e3-4301-9182-f817b2915309","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3dfd8135-8985-4d2b-9b5a-bb2e4d38c19c",
    path: "/product/3dfd8135-8985-4d2b-9b5a-bb2e4d38c19c",
    meta: {"ID":"3dfd8135-8985-4d2b-9b5a-bb2e4d38c19c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|3e0c4937-8cdb-4040-9627-d5ff59f49a3f",
    path: "/product/3e0c4937-8cdb-4040-9627-d5ff59f49a3f",
    meta: {"ID":"3e0c4937-8cdb-4040-9627-d5ff59f49a3f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3e1ef10f-f7c1-4470-87eb-83ef66361169",
    path: "/product/3e1ef10f-f7c1-4470-87eb-83ef66361169",
    meta: {"ID":"3e1ef10f-f7c1-4470-87eb-83ef66361169","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|3e31f0db-3b46-49a5-af93-9883470e5009",
    path: "/product/3e31f0db-3b46-49a5-af93-9883470e5009",
    meta: {"ID":"3e31f0db-3b46-49a5-af93-9883470e5009","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3e69e2dc-391d-4f7b-ac25-bf24ab4f54a4",
    path: "/product/3e69e2dc-391d-4f7b-ac25-bf24ab4f54a4",
    meta: {"ID":"3e69e2dc-391d-4f7b-ac25-bf24ab4f54a4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|3e86105e-f088-4c61-ac78-78807515202a",
    path: "/product/3e86105e-f088-4c61-ac78-78807515202a",
    meta: {"ID":"3e86105e-f088-4c61-ac78-78807515202a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|3e8e2f84-61e8-4a91-a35e-1336d24f340f",
    path: "/product/3e8e2f84-61e8-4a91-a35e-1336d24f340f",
    meta: {"ID":"3e8e2f84-61e8-4a91-a35e-1336d24f340f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3e8ea04f-fd20-4084-baa6-3b45abe1b0e5",
    path: "/product/3e8ea04f-fd20-4084-baa6-3b45abe1b0e5",
    meta: {"ID":"3e8ea04f-fd20-4084-baa6-3b45abe1b0e5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|3e9d034f-76a8-44e7-a025-65309b559706",
    path: "/product/3e9d034f-76a8-44e7-a025-65309b559706",
    meta: {"ID":"3e9d034f-76a8-44e7-a025-65309b559706","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c20c30ec-cf97-4ef6-bc06-9e04305c93ad|3ea2fe63-ce17-4f27-8a78-f59d8914340d",
    path: "/product/3ea2fe63-ce17-4f27-8a78-f59d8914340d",
    meta: {"ID":"3ea2fe63-ce17-4f27-8a78-f59d8914340d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|3ec376b4-fd6d-4157-a28e-271de56be55d",
    path: "/product/3ec376b4-fd6d-4157-a28e-271de56be55d",
    meta: {"ID":"3ec376b4-fd6d-4157-a28e-271de56be55d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3ef1654d-62a3-4907-abe9-9fa75998d968",
    path: "/product/3ef1654d-62a3-4907-abe9-9fa75998d968",
    meta: {"ID":"3ef1654d-62a3-4907-abe9-9fa75998d968","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3f1c4ea5-a40d-4e5b-b7e5-2a95086bfb2b",
    path: "/product/3f1c4ea5-a40d-4e5b-b7e5-2a95086bfb2b",
    meta: {"ID":"3f1c4ea5-a40d-4e5b-b7e5-2a95086bfb2b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|3f3226e2-6e8e-47d1-a713-9e7e8169b3aa",
    path: "/product/3f3226e2-6e8e-47d1-a713-9e7e8169b3aa",
    meta: {"ID":"3f3226e2-6e8e-47d1-a713-9e7e8169b3aa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|3f356eb7-6a2c-4e1c-832f-d4f760959d8d",
    path: "/product/3f356eb7-6a2c-4e1c-832f-d4f760959d8d",
    meta: {"ID":"3f356eb7-6a2c-4e1c-832f-d4f760959d8d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3f4cef98-2ce3-480f-8c6b-326982e5e1b3",
    path: "/product/3f4cef98-2ce3-480f-8c6b-326982e5e1b3",
    meta: {"ID":"3f4cef98-2ce3-480f-8c6b-326982e5e1b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|3f5118ec-5310-4bbb-9623-bb8eb33e4043",
    path: "/product/3f5118ec-5310-4bbb-9623-bb8eb33e4043",
    meta: {"ID":"3f5118ec-5310-4bbb-9623-bb8eb33e4043","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3f739fb2-d48f-4b60-94e9-ce4fa3c16457",
    path: "/product/3f739fb2-d48f-4b60-94e9-ce4fa3c16457",
    meta: {"ID":"3f739fb2-d48f-4b60-94e9-ce4fa3c16457","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|3f98b0a2-1e50-402e-8966-3b9d9e6294cf",
    path: "/product/3f98b0a2-1e50-402e-8966-3b9d9e6294cf",
    meta: {"ID":"3f98b0a2-1e50-402e-8966-3b9d9e6294cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|3fa12940-47bb-41ce-8f22-a03522c3a9d1",
    path: "/product/3fa12940-47bb-41ce-8f22-a03522c3a9d1",
    meta: {"ID":"3fa12940-47bb-41ce-8f22-a03522c3a9d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6b95603-7a93-4283-b39d-ece699ed1469|3fbe9096-4f4b-4194-92f3-88045e1f416a",
    path: "/product/3fbe9096-4f4b-4194-92f3-88045e1f416a",
    meta: {"ID":"3fbe9096-4f4b-4194-92f3-88045e1f416a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|3fbfb4de-61d9-4768-90dd-fa7c88cfe33b",
    path: "/product/3fbfb4de-61d9-4768-90dd-fa7c88cfe33b",
    meta: {"ID":"3fbfb4de-61d9-4768-90dd-fa7c88cfe33b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|0f33a02b-92fa-4922-9e4c-0b4cc4e13e79|3fc5a2e3-1ea2-4494-81c4-a4e115423d1d",
    path: "/product/3fc5a2e3-1ea2-4494-81c4-a4e115423d1d",
    meta: {"ID":"3fc5a2e3-1ea2-4494-81c4-a4e115423d1d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|3fece968-99b6-4397-a52b-5e92874ceb28",
    path: "/product/3fece968-99b6-4397-a52b-5e92874ceb28",
    meta: {"ID":"3fece968-99b6-4397-a52b-5e92874ceb28","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|401e294c-0545-4131-8dbd-b8a8169f20db",
    path: "/product/401e294c-0545-4131-8dbd-b8a8169f20db",
    meta: {"ID":"401e294c-0545-4131-8dbd-b8a8169f20db","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|61920873-b03c-43ac-aa78-5e5acd2bdfe1|402d6823-1154-444f-930b-5d890e627f1f",
    path: "/product/402d6823-1154-444f-930b-5d890e627f1f",
    meta: {"ID":"402d6823-1154-444f-930b-5d890e627f1f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|40354538-4c78-40ea-9c1a-496b0d60de80",
    path: "/product/40354538-4c78-40ea-9c1a-496b0d60de80",
    meta: {"ID":"40354538-4c78-40ea-9c1a-496b0d60de80","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|404920e2-6660-4339-8bc8-0650ed8881af",
    path: "/product/404920e2-6660-4339-8bc8-0650ed8881af",
    meta: {"ID":"404920e2-6660-4339-8bc8-0650ed8881af","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|40672216-47bd-4389-ae09-928213dd14e2",
    path: "/product/40672216-47bd-4389-ae09-928213dd14e2",
    meta: {"ID":"40672216-47bd-4389-ae09-928213dd14e2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|40c2cf31-d2d9-49f9-954f-2c781147d1fd",
    path: "/product/40c2cf31-d2d9-49f9-954f-2c781147d1fd",
    meta: {"ID":"40c2cf31-d2d9-49f9-954f-2c781147d1fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|40c6aae1-c836-403a-b64e-c95ffcc6d37a",
    path: "/product/40c6aae1-c836-403a-b64e-c95ffcc6d37a",
    meta: {"ID":"40c6aae1-c836-403a-b64e-c95ffcc6d37a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|40e722bd-d49a-4c38-ac7c-c12316649170",
    path: "/product/40e722bd-d49a-4c38-ac7c-c12316649170",
    meta: {"ID":"40e722bd-d49a-4c38-ac7c-c12316649170","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|82890741-f41f-4593-bd5b-e63c6af5f1ed|40eb1462-843b-4c6e-9aa6-653c5df1b59d",
    path: "/product/40eb1462-843b-4c6e-9aa6-653c5df1b59d",
    meta: {"ID":"40eb1462-843b-4c6e-9aa6-653c5df1b59d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|40f12019-9801-4265-819a-47f27dc59436",
    path: "/product/40f12019-9801-4265-819a-47f27dc59436",
    meta: {"ID":"40f12019-9801-4265-819a-47f27dc59436","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4349f183-f876-4668-9110-f50347c57ef2|41038a27-5a47-4c08-972b-130b4106bd56",
    path: "/product/41038a27-5a47-4c08-972b-130b4106bd56",
    meta: {"ID":"41038a27-5a47-4c08-972b-130b4106bd56","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|410f57db-7a26-4911-927d-df320ffebc25",
    path: "/product/410f57db-7a26-4911-927d-df320ffebc25",
    meta: {"ID":"410f57db-7a26-4911-927d-df320ffebc25","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|53b9bd6b-ecab-409e-b3e8-22b81c702f83|411663d2-e41e-4214-af30-2f957c64d7d7",
    path: "/product/411663d2-e41e-4214-af30-2f957c64d7d7",
    meta: {"ID":"411663d2-e41e-4214-af30-2f957c64d7d7","Title":"","GroupID":"53b9bd6b-ecab-409e-b3e8-22b81c702f83","GroupTitle":"Паста и Ризотто","GroupLink":"/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|411802c5-4dfe-4490-9530-bc3b7c9e8fd1",
    path: "/product/411802c5-4dfe-4490-9530-bc3b7c9e8fd1",
    meta: {"ID":"411802c5-4dfe-4490-9530-bc3b7c9e8fd1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|412bc69c-d14b-4aa7-844c-2dbd34611fb5",
    path: "/product/412bc69c-d14b-4aa7-844c-2dbd34611fb5",
    meta: {"ID":"412bc69c-d14b-4aa7-844c-2dbd34611fb5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4135c9b6-4717-4853-9f2d-df160a5ef5c1",
    path: "/product/4135c9b6-4717-4853-9f2d-df160a5ef5c1",
    meta: {"ID":"4135c9b6-4717-4853-9f2d-df160a5ef5c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|41610113-d2db-436f-8226-b59aa8647c0d",
    path: "/product/41610113-d2db-436f-8226-b59aa8647c0d",
    meta: {"ID":"41610113-d2db-436f-8226-b59aa8647c0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|417b84cf-d5b1-4576-b84f-c98ec96346c3",
    path: "/product/417b84cf-d5b1-4576-b84f-c98ec96346c3",
    meta: {"ID":"417b84cf-d5b1-4576-b84f-c98ec96346c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|41857fd3-d88e-4936-a4f2-b87eabe59025",
    path: "/product/41857fd3-d88e-4936-a4f2-b87eabe59025",
    meta: {"ID":"41857fd3-d88e-4936-a4f2-b87eabe59025","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|41a0ce51-9a4f-4c95-a67f-5ec48a421ca4",
    path: "/product/41a0ce51-9a4f-4c95-a67f-5ec48a421ca4",
    meta: {"ID":"41a0ce51-9a4f-4c95-a67f-5ec48a421ca4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|41baf5c6-3038-452a-b283-b3eac4badfbb",
    path: "/product/41baf5c6-3038-452a-b283-b3eac4badfbb",
    meta: {"ID":"41baf5c6-3038-452a-b283-b3eac4badfbb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|41dcfac9-9682-4b72-9168-3503e4846e9a",
    path: "/product/41dcfac9-9682-4b72-9168-3503e4846e9a",
    meta: {"ID":"41dcfac9-9682-4b72-9168-3503e4846e9a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|41f4b4b0-579b-4f31-947f-84206b8c1d65",
    path: "/product/41f4b4b0-579b-4f31-947f-84206b8c1d65",
    meta: {"ID":"41f4b4b0-579b-4f31-947f-84206b8c1d65","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|421e4a5e-6f90-4221-9336-d1eeb3d325d7",
    path: "/product/421e4a5e-6f90-4221-9336-d1eeb3d325d7",
    meta: {"ID":"421e4a5e-6f90-4221-9336-d1eeb3d325d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|422a7378-d60f-42f8-a56a-9fa1b359c833",
    path: "/product/422a7378-d60f-42f8-a56a-9fa1b359c833",
    meta: {"ID":"422a7378-d60f-42f8-a56a-9fa1b359c833","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|42325a7d-1da4-4e0d-a3a2-fe050bd3b648",
    path: "/product/42325a7d-1da4-4e0d-a3a2-fe050bd3b648",
    meta: {"ID":"42325a7d-1da4-4e0d-a3a2-fe050bd3b648","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|735f10a8-dcc4-47d5-9639-e55e0af97024|4233f02e-9a5c-4745-ad47-cdabbb1b3c87",
    path: "/product/4233f02e-9a5c-4745-ad47-cdabbb1b3c87",
    meta: {"ID":"4233f02e-9a5c-4745-ad47-cdabbb1b3c87","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|42553bbf-30f5-4264-9dd2-920d82105449",
    path: "/product/42553bbf-30f5-4264-9dd2-920d82105449",
    meta: {"ID":"42553bbf-30f5-4264-9dd2-920d82105449","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|426a349c-4acf-44cb-b145-cc251720cd7f",
    path: "/product/426a349c-4acf-44cb-b145-cc251720cd7f",
    meta: {"ID":"426a349c-4acf-44cb-b145-cc251720cd7f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7bdadf21-f387-4551-8ae2-d2b5ae64736e|427b854f-ea6d-4bb9-bb96-4d35899ba6dd",
    path: "/product/427b854f-ea6d-4bb9-bb96-4d35899ba6dd",
    meta: {"ID":"427b854f-ea6d-4bb9-bb96-4d35899ba6dd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|42a5d507-6628-45d3-a5d5-1fab8f4959fe",
    path: "/product/42a5d507-6628-45d3-a5d5-1fab8f4959fe",
    meta: {"ID":"42a5d507-6628-45d3-a5d5-1fab8f4959fe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|42acd34e-fb6c-4ed1-8b64-f603fc0ef36a",
    path: "/product/42acd34e-fb6c-4ed1-8b64-f603fc0ef36a",
    meta: {"ID":"42acd34e-fb6c-4ed1-8b64-f603fc0ef36a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|42c14773-3f42-4589-a204-43bfdb31f989",
    path: "/product/42c14773-3f42-4589-a204-43bfdb31f989",
    meta: {"ID":"42c14773-3f42-4589-a204-43bfdb31f989","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|42c707f1-5e9e-487b-a137-457c68951396",
    path: "/product/42c707f1-5e9e-487b-a137-457c68951396",
    meta: {"ID":"42c707f1-5e9e-487b-a137-457c68951396","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6fa548ca-4b6a-4806-83da-74148d7bfb94|42c78297-6c3b-4654-86ad-7d172803eaf9",
    path: "/product/42c78297-6c3b-4654-86ad-7d172803eaf9",
    meta: {"ID":"42c78297-6c3b-4654-86ad-7d172803eaf9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|42da75bc-cd22-4185-a6a5-e94bedd1ea3d",
    path: "/product/42da75bc-cd22-4185-a6a5-e94bedd1ea3d",
    meta: {"ID":"42da75bc-cd22-4185-a6a5-e94bedd1ea3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|42e7a4fd-13b4-47ed-8267-9f2a1f723dd4",
    path: "/product/42e7a4fd-13b4-47ed-8267-9f2a1f723dd4",
    meta: {"ID":"42e7a4fd-13b4-47ed-8267-9f2a1f723dd4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|42f73161-2159-480f-9bbc-f5007b11d76a",
    path: "/product/42f73161-2159-480f-9bbc-f5007b11d76a",
    meta: {"ID":"42f73161-2159-480f-9bbc-f5007b11d76a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|432abf60-f8a1-41ec-a89e-eff426835a0d",
    path: "/product/432abf60-f8a1-41ec-a89e-eff426835a0d",
    meta: {"ID":"432abf60-f8a1-41ec-a89e-eff426835a0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|434bae7e-ec56-4d83-9764-36e775ef2e2f",
    path: "/product/434bae7e-ec56-4d83-9764-36e775ef2e2f",
    meta: {"ID":"434bae7e-ec56-4d83-9764-36e775ef2e2f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4356bcf9-3ef5-4f4a-a9fd-fbef1f18dccf",
    path: "/product/4356bcf9-3ef5-4f4a-a9fd-fbef1f18dccf",
    meta: {"ID":"4356bcf9-3ef5-4f4a-a9fd-fbef1f18dccf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|967d3966-c5f3-487e-a085-8e9a8fe858bf|43692f75-e72c-4422-9e50-72294607857c",
    path: "/product/43692f75-e72c-4422-9e50-72294607857c",
    meta: {"ID":"43692f75-e72c-4422-9e50-72294607857c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|436dd2b2-91dd-4036-b4b5-0165eb24be34",
    path: "/product/436dd2b2-91dd-4036-b4b5-0165eb24be34",
    meta: {"ID":"436dd2b2-91dd-4036-b4b5-0165eb24be34","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|43725198-b13a-4cf0-a72f-8d7a204453e9",
    path: "/product/43725198-b13a-4cf0-a72f-8d7a204453e9",
    meta: {"ID":"43725198-b13a-4cf0-a72f-8d7a204453e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|437cb557-b223-47af-9969-e53ccedf22a1",
    path: "/product/437cb557-b223-47af-9969-e53ccedf22a1",
    meta: {"ID":"437cb557-b223-47af-9969-e53ccedf22a1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|43cd3e54-fd13-4a86-8d28-c156bcb4f27f",
    path: "/product/43cd3e54-fd13-4a86-8d28-c156bcb4f27f",
    meta: {"ID":"43cd3e54-fd13-4a86-8d28-c156bcb4f27f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|43d39dfd-dad6-4753-8973-a8f1954da97d",
    path: "/product/43d39dfd-dad6-4753-8973-a8f1954da97d",
    meta: {"ID":"43d39dfd-dad6-4753-8973-a8f1954da97d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|43d43685-6b4a-4422-8127-1c9742fb9a11",
    path: "/product/43d43685-6b4a-4422-8127-1c9742fb9a11",
    meta: {"ID":"43d43685-6b4a-4422-8127-1c9742fb9a11","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|43dca2fd-5d2d-43dd-ae58-e59e9325aae0",
    path: "/product/43dca2fd-5d2d-43dd-ae58-e59e9325aae0",
    meta: {"ID":"43dca2fd-5d2d-43dd-ae58-e59e9325aae0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|43f53287-abb7-4081-9433-8d161e12f92a",
    path: "/product/43f53287-abb7-4081-9433-8d161e12f92a",
    meta: {"ID":"43f53287-abb7-4081-9433-8d161e12f92a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|44042a6e-43b6-4cb6-9792-f5135ec2ffd2",
    path: "/product/44042a6e-43b6-4cb6-9792-f5135ec2ffd2",
    meta: {"ID":"44042a6e-43b6-4cb6-9792-f5135ec2ffd2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|441a46fc-0b2d-4d87-be08-8b7286f54327",
    path: "/product/441a46fc-0b2d-4d87-be08-8b7286f54327",
    meta: {"ID":"441a46fc-0b2d-4d87-be08-8b7286f54327","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4429b99f-f163-41a9-8b12-ceab5cd31f90",
    path: "/product/4429b99f-f163-41a9-8b12-ceab5cd31f90",
    meta: {"ID":"4429b99f-f163-41a9-8b12-ceab5cd31f90","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|af1ac0fd-a494-4b35-ae24-edc1fe10a7e4|442a51f2-dd34-4434-a22c-debc8a3a7eee",
    path: "/product/442a51f2-dd34-4434-a22c-debc8a3a7eee",
    meta: {"ID":"442a51f2-dd34-4434-a22c-debc8a3a7eee","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|447d247d-01ca-4649-9eb1-5b913bc9a40d",
    path: "/product/447d247d-01ca-4649-9eb1-5b913bc9a40d",
    meta: {"ID":"447d247d-01ca-4649-9eb1-5b913bc9a40d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|44920692-ee98-4a44-bd65-e937f9aee0ff",
    path: "/product/44920692-ee98-4a44-bd65-e937f9aee0ff",
    meta: {"ID":"44920692-ee98-4a44-bd65-e937f9aee0ff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|44b14bce-5938-43a4-bdff-16927cc4c3e0",
    path: "/product/44b14bce-5938-43a4-bdff-16927cc4c3e0",
    meta: {"ID":"44b14bce-5938-43a4-bdff-16927cc4c3e0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|44b57dcd-a36e-4b81-b35d-859fe192e387",
    path: "/product/44b57dcd-a36e-4b81-b35d-859fe192e387",
    meta: {"ID":"44b57dcd-a36e-4b81-b35d-859fe192e387","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|44dc4313-ac7d-4ab1-9a72-4470caff1ff6",
    path: "/product/44dc4313-ac7d-4ab1-9a72-4470caff1ff6",
    meta: {"ID":"44dc4313-ac7d-4ab1-9a72-4470caff1ff6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|44e0b270-3d78-45d7-8eb1-b50a5b4a4791",
    path: "/product/44e0b270-3d78-45d7-8eb1-b50a5b4a4791",
    meta: {"ID":"44e0b270-3d78-45d7-8eb1-b50a5b4a4791","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|44e8444d-5fe9-41ee-8fac-54b9b01de47b",
    path: "/product/44e8444d-5fe9-41ee-8fac-54b9b01de47b",
    meta: {"ID":"44e8444d-5fe9-41ee-8fac-54b9b01de47b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|450f3367-01f1-4def-92c8-1167aec97c4e",
    path: "/product/450f3367-01f1-4def-92c8-1167aec97c4e",
    meta: {"ID":"450f3367-01f1-4def-92c8-1167aec97c4e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|45138169-99ad-4e33-95f3-a1dfd2f336c0",
    path: "/product/45138169-99ad-4e33-95f3-a1dfd2f336c0",
    meta: {"ID":"45138169-99ad-4e33-95f3-a1dfd2f336c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|451e31b1-6758-4077-a623-9e8517772267",
    path: "/product/451e31b1-6758-4077-a623-9e8517772267",
    meta: {"ID":"451e31b1-6758-4077-a623-9e8517772267","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3|4520ecaf-9e5d-40e2-9336-59adb0505130",
    path: "/product/4520ecaf-9e5d-40e2-9336-59adb0505130",
    meta: {"ID":"4520ecaf-9e5d-40e2-9336-59adb0505130","Title":"","GroupID":"5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","GroupTitle":"Салаты","GroupLink":"/menu/5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|453fc798-6487-471e-9256-753927342121",
    path: "/product/453fc798-6487-471e-9256-753927342121",
    meta: {"ID":"453fc798-6487-471e-9256-753927342121","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|454ed895-24b3-4ab4-89c2-75baa93a0e10",
    path: "/product/454ed895-24b3-4ab4-89c2-75baa93a0e10",
    meta: {"ID":"454ed895-24b3-4ab4-89c2-75baa93a0e10","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|45a0512e-b2d8-48da-9b84-d9fafe9bc54a",
    path: "/product/45a0512e-b2d8-48da-9b84-d9fafe9bc54a",
    meta: {"ID":"45a0512e-b2d8-48da-9b84-d9fafe9bc54a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|45a2dc90-bce8-4c37-9182-331b86ecc291",
    path: "/product/45a2dc90-bce8-4c37-9182-331b86ecc291",
    meta: {"ID":"45a2dc90-bce8-4c37-9182-331b86ecc291","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|45a3bc40-7d4a-47bd-8104-e0e7f1009358",
    path: "/product/45a3bc40-7d4a-47bd-8104-e0e7f1009358",
    meta: {"ID":"45a3bc40-7d4a-47bd-8104-e0e7f1009358","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|45a4c4ec-753c-4391-ab1d-e9884f0a946d",
    path: "/product/45a4c4ec-753c-4391-ab1d-e9884f0a946d",
    meta: {"ID":"45a4c4ec-753c-4391-ab1d-e9884f0a946d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6cbf9c41-8e9c-4704-af3f-382f792c10c4|45a69be0-6518-4e8f-8a4c-15f3272e3817",
    path: "/product/45a69be0-6518-4e8f-8a4c-15f3272e3817",
    meta: {"ID":"45a69be0-6518-4e8f-8a4c-15f3272e3817","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|45c79d0d-991b-4642-af07-21311922a64d",
    path: "/product/45c79d0d-991b-4642-af07-21311922a64d",
    meta: {"ID":"45c79d0d-991b-4642-af07-21311922a64d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|45cd8c14-1f2f-45d8-95e9-5a55ec0afd98",
    path: "/product/45cd8c14-1f2f-45d8-95e9-5a55ec0afd98",
    meta: {"ID":"45cd8c14-1f2f-45d8-95e9-5a55ec0afd98","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|45ce1871-0003-495b-a82b-2ae4d7eddffe",
    path: "/product/45ce1871-0003-495b-a82b-2ae4d7eddffe",
    meta: {"ID":"45ce1871-0003-495b-a82b-2ae4d7eddffe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|45cf8358-1a51-4e22-ab9f-696123f16a7e",
    path: "/product/45cf8358-1a51-4e22-ab9f-696123f16a7e",
    meta: {"ID":"45cf8358-1a51-4e22-ab9f-696123f16a7e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|45d92cd5-516f-4006-9053-92fd3bf1e6a8",
    path: "/product/45d92cd5-516f-4006-9053-92fd3bf1e6a8",
    meta: {"ID":"45d92cd5-516f-4006-9053-92fd3bf1e6a8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|45de3df6-4134-47f2-be9d-edd8e9de5938",
    path: "/product/45de3df6-4134-47f2-be9d-edd8e9de5938",
    meta: {"ID":"45de3df6-4134-47f2-be9d-edd8e9de5938","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|45e89cd1-5394-4443-b8d6-cf8c705e4d85",
    path: "/product/45e89cd1-5394-4443-b8d6-cf8c705e4d85",
    meta: {"ID":"45e89cd1-5394-4443-b8d6-cf8c705e4d85","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|45eaeb90-09dd-447b-81a8-54ba8014b22d",
    path: "/product/45eaeb90-09dd-447b-81a8-54ba8014b22d",
    meta: {"ID":"45eaeb90-09dd-447b-81a8-54ba8014b22d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|45f4f06d-30d9-4539-a007-d90b282e459e",
    path: "/product/45f4f06d-30d9-4539-a007-d90b282e459e",
    meta: {"ID":"45f4f06d-30d9-4539-a007-d90b282e459e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|45f85384-f0ba-44eb-9454-98d9f3ff9df8",
    path: "/product/45f85384-f0ba-44eb-9454-98d9f3ff9df8",
    meta: {"ID":"45f85384-f0ba-44eb-9454-98d9f3ff9df8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|4610563c-4d61-4118-81b5-4756afae63a2",
    path: "/product/4610563c-4d61-4118-81b5-4756afae63a2",
    meta: {"ID":"4610563c-4d61-4118-81b5-4756afae63a2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|461c51d5-b552-429e-b76e-c143641cabb7",
    path: "/product/461c51d5-b552-429e-b76e-c143641cabb7",
    meta: {"ID":"461c51d5-b552-429e-b76e-c143641cabb7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|465cd56b-513e-4a2a-8bfa-aa9bd1db4e6e",
    path: "/product/465cd56b-513e-4a2a-8bfa-aa9bd1db4e6e",
    meta: {"ID":"465cd56b-513e-4a2a-8bfa-aa9bd1db4e6e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|46641517-2050-4991-bd24-356f6166de56",
    path: "/product/46641517-2050-4991-bd24-356f6166de56",
    meta: {"ID":"46641517-2050-4991-bd24-356f6166de56","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|4669d93c-30eb-4226-89ac-db45d1047bbc",
    path: "/product/4669d93c-30eb-4226-89ac-db45d1047bbc",
    meta: {"ID":"4669d93c-30eb-4226-89ac-db45d1047bbc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|46af162f-4ca6-40c3-acdb-0616e96cd0e4",
    path: "/product/46af162f-4ca6-40c3-acdb-0616e96cd0e4",
    meta: {"ID":"46af162f-4ca6-40c3-acdb-0616e96cd0e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|46c4e4b1-7a35-4172-b86c-2bd5edff7b26",
    path: "/product/46c4e4b1-7a35-4172-b86c-2bd5edff7b26",
    meta: {"ID":"46c4e4b1-7a35-4172-b86c-2bd5edff7b26","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|46e58c97-d4c6-4cae-ae1d-d17abaa8f069",
    path: "/product/46e58c97-d4c6-4cae-ae1d-d17abaa8f069",
    meta: {"ID":"46e58c97-d4c6-4cae-ae1d-d17abaa8f069","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|46f00f75-6500-4189-ba25-b438858d5231",
    path: "/product/46f00f75-6500-4189-ba25-b438858d5231",
    meta: {"ID":"46f00f75-6500-4189-ba25-b438858d5231","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|46fbf1b5-842b-4837-ac37-452e8dd95dd7",
    path: "/product/46fbf1b5-842b-4837-ac37-452e8dd95dd7",
    meta: {"ID":"46fbf1b5-842b-4837-ac37-452e8dd95dd7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|46fd11b2-b306-465c-bf05-92f91a939dd0",
    path: "/product/46fd11b2-b306-465c-bf05-92f91a939dd0",
    meta: {"ID":"46fd11b2-b306-465c-bf05-92f91a939dd0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|472a8249-e7e2-49f7-87f3-be15e893f2b3",
    path: "/product/472a8249-e7e2-49f7-87f3-be15e893f2b3",
    meta: {"ID":"472a8249-e7e2-49f7-87f3-be15e893f2b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|47309d71-0db3-4e17-8c24-4794477bf904",
    path: "/product/47309d71-0db3-4e17-8c24-4794477bf904",
    meta: {"ID":"47309d71-0db3-4e17-8c24-4794477bf904","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6cbf9c41-8e9c-4704-af3f-382f792c10c4|473bb7c6-cc1b-46e9-88ae-3c4701c04e7d",
    path: "/product/473bb7c6-cc1b-46e9-88ae-3c4701c04e7d",
    meta: {"ID":"473bb7c6-cc1b-46e9-88ae-3c4701c04e7d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0686e42-5664-44b8-b850-26f555406842|473cb50b-11c5-4880-b4e1-9e1aae3bd192",
    path: "/product/473cb50b-11c5-4880-b4e1-9e1aae3bd192",
    meta: {"ID":"473cb50b-11c5-4880-b4e1-9e1aae3bd192","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|476aa35f-559f-4aea-a60d-864ed8cd1bad",
    path: "/product/476aa35f-559f-4aea-a60d-864ed8cd1bad",
    meta: {"ID":"476aa35f-559f-4aea-a60d-864ed8cd1bad","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|476e1b08-d067-4d0c-b748-b904c2b2fd64",
    path: "/product/476e1b08-d067-4d0c-b748-b904c2b2fd64",
    meta: {"ID":"476e1b08-d067-4d0c-b748-b904c2b2fd64","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|47d6431a-b518-4572-b501-b7001936c967",
    path: "/product/47d6431a-b518-4572-b501-b7001936c967",
    meta: {"ID":"47d6431a-b518-4572-b501-b7001936c967","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|47dd2bf3-7cda-4223-8a3d-6f98fa9c886b",
    path: "/product/47dd2bf3-7cda-4223-8a3d-6f98fa9c886b",
    meta: {"ID":"47dd2bf3-7cda-4223-8a3d-6f98fa9c886b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|47ebec88-38e3-4e75-b3f4-156c395313d3",
    path: "/product/47ebec88-38e3-4e75-b3f4-156c395313d3",
    meta: {"ID":"47ebec88-38e3-4e75-b3f4-156c395313d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|48021b1d-05e8-417a-a95f-c1fb0c6514fb",
    path: "/product/48021b1d-05e8-417a-a95f-c1fb0c6514fb",
    meta: {"ID":"48021b1d-05e8-417a-a95f-c1fb0c6514fb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|48052e75-915e-4663-8233-917ce2f8aa6d",
    path: "/product/48052e75-915e-4663-8233-917ce2f8aa6d",
    meta: {"ID":"48052e75-915e-4663-8233-917ce2f8aa6d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca23e9-81fa-4648-a2f2-397fc639baa0|4828180e-ed90-48a6-8756-a8dcc11f129a",
    path: "/product/4828180e-ed90-48a6-8756-a8dcc11f129a",
    meta: {"ID":"4828180e-ed90-48a6-8756-a8dcc11f129a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4835604f-5c85-494a-a15c-a21132cc2867",
    path: "/product/4835604f-5c85-494a-a15c-a21132cc2867",
    meta: {"ID":"4835604f-5c85-494a-a15c-a21132cc2867","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|48697836-f7d5-460a-8f08-ed4e3bb3d447",
    path: "/product/48697836-f7d5-460a-8f08-ed4e3bb3d447",
    meta: {"ID":"48697836-f7d5-460a-8f08-ed4e3bb3d447","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|487a87fa-eba0-46a4-8616-e685cfbb0cfa",
    path: "/product/487a87fa-eba0-46a4-8616-e685cfbb0cfa",
    meta: {"ID":"487a87fa-eba0-46a4-8616-e685cfbb0cfa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73d8e7e8-a7d6-4cde-900b-feb2938a1fcb|4884d832-14ec-4d3a-baf3-59052947d9a9",
    path: "/product/4884d832-14ec-4d3a-baf3-59052947d9a9",
    meta: {"ID":"4884d832-14ec-4d3a-baf3-59052947d9a9","Title":"","GroupID":"73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","GroupTitle":"Подарочные сертификаты","GroupLink":"/menu/73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|488e5992-635a-4926-b836-a3fd5d846f03",
    path: "/product/488e5992-635a-4926-b836-a3fd5d846f03",
    meta: {"ID":"488e5992-635a-4926-b836-a3fd5d846f03","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|488ea8cd-bdf5-4a62-acac-85f50e0ec5e4",
    path: "/product/488ea8cd-bdf5-4a62-acac-85f50e0ec5e4",
    meta: {"ID":"488ea8cd-bdf5-4a62-acac-85f50e0ec5e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|489b619f-2d80-45fd-80a1-e3486e571ce1",
    path: "/product/489b619f-2d80-45fd-80a1-e3486e571ce1",
    meta: {"ID":"489b619f-2d80-45fd-80a1-e3486e571ce1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|48a1ec8d-4309-4308-a127-1d70ca10ed1a",
    path: "/product/48a1ec8d-4309-4308-a127-1d70ca10ed1a",
    meta: {"ID":"48a1ec8d-4309-4308-a127-1d70ca10ed1a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|48c8aa2e-7fcb-406f-8ae8-017810344a2e",
    path: "/product/48c8aa2e-7fcb-406f-8ae8-017810344a2e",
    meta: {"ID":"48c8aa2e-7fcb-406f-8ae8-017810344a2e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|654b3dbf-24ce-4acd-8343-54dbb128223f|48e48aeb-048d-45fb-943b-07bf2ab6d28b",
    path: "/product/48e48aeb-048d-45fb-943b-07bf2ab6d28b",
    meta: {"ID":"48e48aeb-048d-45fb-943b-07bf2ab6d28b","Title":"","GroupID":"654b3dbf-24ce-4acd-8343-54dbb128223f","GroupTitle":"Супы","GroupLink":"/menu/654b3dbf-24ce-4acd-8343-54dbb128223f","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|48ea5f3e-7d71-4cbc-9f71-0ecb25a2fa41",
    path: "/product/48ea5f3e-7d71-4cbc-9f71-0ecb25a2fa41",
    meta: {"ID":"48ea5f3e-7d71-4cbc-9f71-0ecb25a2fa41","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|48f3449e-8fbd-440d-816e-324d14b70caf",
    path: "/product/48f3449e-8fbd-440d-816e-324d14b70caf",
    meta: {"ID":"48f3449e-8fbd-440d-816e-324d14b70caf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|48fa508b-0a5f-45a7-aee7-51c4618cf3c9",
    path: "/product/48fa508b-0a5f-45a7-aee7-51c4618cf3c9",
    meta: {"ID":"48fa508b-0a5f-45a7-aee7-51c4618cf3c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|490bf7c9-412a-4502-b967-84cd11dc40e9",
    path: "/product/490bf7c9-412a-4502-b967-84cd11dc40e9",
    meta: {"ID":"490bf7c9-412a-4502-b967-84cd11dc40e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|4928fe4c-37dd-4ca7-9738-6b4ae56039e1",
    path: "/product/4928fe4c-37dd-4ca7-9738-6b4ae56039e1",
    meta: {"ID":"4928fe4c-37dd-4ca7-9738-6b4ae56039e1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|496640ff-6df1-4712-8920-360b11f811c9",
    path: "/product/496640ff-6df1-4712-8920-360b11f811c9",
    meta: {"ID":"496640ff-6df1-4712-8920-360b11f811c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|49884a0e-8a97-4090-8ef5-7158fe73f374",
    path: "/product/49884a0e-8a97-4090-8ef5-7158fe73f374",
    meta: {"ID":"49884a0e-8a97-4090-8ef5-7158fe73f374","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|4989b7ef-5365-480a-83cd-ec7937171a13",
    path: "/product/4989b7ef-5365-480a-83cd-ec7937171a13",
    meta: {"ID":"4989b7ef-5365-480a-83cd-ec7937171a13","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|498a1d08-f3b5-40d1-8199-6360c897386d",
    path: "/product/498a1d08-f3b5-40d1-8199-6360c897386d",
    meta: {"ID":"498a1d08-f3b5-40d1-8199-6360c897386d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|499c9169-0af9-4733-8b89-a76f998bdf46",
    path: "/product/499c9169-0af9-4733-8b89-a76f998bdf46",
    meta: {"ID":"499c9169-0af9-4733-8b89-a76f998bdf46","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|49bf7e35-9cc2-4116-bd30-45fb8a1bfa0b",
    path: "/product/49bf7e35-9cc2-4116-bd30-45fb8a1bfa0b",
    meta: {"ID":"49bf7e35-9cc2-4116-bd30-45fb8a1bfa0b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|49ffa79c-d5fb-4a82-bc38-f4958c7bab66",
    path: "/product/49ffa79c-d5fb-4a82-bc38-f4958c7bab66",
    meta: {"ID":"49ffa79c-d5fb-4a82-bc38-f4958c7bab66","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4a1ea959-4409-4a2b-948c-ac557b54c4f4",
    path: "/product/4a1ea959-4409-4a2b-948c-ac557b54c4f4",
    meta: {"ID":"4a1ea959-4409-4a2b-948c-ac557b54c4f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4a1f5791-1ece-4d60-a5c0-60965292d542",
    path: "/product/4a1f5791-1ece-4d60-a5c0-60965292d542",
    meta: {"ID":"4a1f5791-1ece-4d60-a5c0-60965292d542","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|967d3966-c5f3-487e-a085-8e9a8fe858bf|4a23ee42-78a8-4858-953d-d6589b24dd08",
    path: "/product/4a23ee42-78a8-4858-953d-d6589b24dd08",
    meta: {"ID":"4a23ee42-78a8-4858-953d-d6589b24dd08","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4a26b01b-5940-48cf-95ce-5e7fb8864c82",
    path: "/product/4a26b01b-5940-48cf-95ce-5e7fb8864c82",
    meta: {"ID":"4a26b01b-5940-48cf-95ce-5e7fb8864c82","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4a29ec5e-3aac-4fe6-90ec-41998b28ca65",
    path: "/product/4a29ec5e-3aac-4fe6-90ec-41998b28ca65",
    meta: {"ID":"4a29ec5e-3aac-4fe6-90ec-41998b28ca65","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|4a6d5a75-a4ec-4692-abd6-067452e5c26d",
    path: "/product/4a6d5a75-a4ec-4692-abd6-067452e5c26d",
    meta: {"ID":"4a6d5a75-a4ec-4692-abd6-067452e5c26d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|4a945fbc-b0bf-40d3-b8b7-ba1593e3d7d1",
    path: "/product/4a945fbc-b0bf-40d3-b8b7-ba1593e3d7d1",
    meta: {"ID":"4a945fbc-b0bf-40d3-b8b7-ba1593e3d7d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|4aac3297-85bb-4993-ab44-dd27f355a821",
    path: "/product/4aac3297-85bb-4993-ab44-dd27f355a821",
    meta: {"ID":"4aac3297-85bb-4993-ab44-dd27f355a821","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|4ac1ec74-144f-45f4-9fbb-99aec7753273",
    path: "/product/4ac1ec74-144f-45f4-9fbb-99aec7753273",
    meta: {"ID":"4ac1ec74-144f-45f4-9fbb-99aec7753273","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|4ae25310-a3c6-4e7d-ae9c-68dc276dc558",
    path: "/product/4ae25310-a3c6-4e7d-ae9c-68dc276dc558",
    meta: {"ID":"4ae25310-a3c6-4e7d-ae9c-68dc276dc558","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4ae44b5c-0d63-43ce-a9ea-0ced26731836",
    path: "/product/4ae44b5c-0d63-43ce-a9ea-0ced26731836",
    meta: {"ID":"4ae44b5c-0d63-43ce-a9ea-0ced26731836","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|4b043ef2-d3da-4584-811d-969eaae65fc0",
    path: "/product/4b043ef2-d3da-4584-811d-969eaae65fc0",
    meta: {"ID":"4b043ef2-d3da-4584-811d-969eaae65fc0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4b2ef27f-3c9c-4aac-b1ef-b400e94f8394",
    path: "/product/4b2ef27f-3c9c-4aac-b1ef-b400e94f8394",
    meta: {"ID":"4b2ef27f-3c9c-4aac-b1ef-b400e94f8394","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|4b59b764-15a3-4de0-8f3a-ec730253a429",
    path: "/product/4b59b764-15a3-4de0-8f3a-ec730253a429",
    meta: {"ID":"4b59b764-15a3-4de0-8f3a-ec730253a429","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4626112c-772c-43a4-83b9-fc5d6a1a2597|4b75240b-c5df-4615-b8fb-6edcb1ff8819",
    path: "/product/4b75240b-c5df-4615-b8fb-6edcb1ff8819",
    meta: {"ID":"4b75240b-c5df-4615-b8fb-6edcb1ff8819","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4b944c45-f8d9-4946-af32-d91bf96b932a",
    path: "/product/4b944c45-f8d9-4946-af32-d91bf96b932a",
    meta: {"ID":"4b944c45-f8d9-4946-af32-d91bf96b932a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4b9655bc-543d-4e9c-b5cc-9f3960a786a5",
    path: "/product/4b9655bc-543d-4e9c-b5cc-9f3960a786a5",
    meta: {"ID":"4b9655bc-543d-4e9c-b5cc-9f3960a786a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|4bd4dad7-1dce-474d-af21-fe3ddaa1dce9",
    path: "/product/4bd4dad7-1dce-474d-af21-fe3ddaa1dce9",
    meta: {"ID":"4bd4dad7-1dce-474d-af21-fe3ddaa1dce9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|4be9ce77-13be-475c-bc98-c32ab3367a7a",
    path: "/product/4be9ce77-13be-475c-bc98-c32ab3367a7a",
    meta: {"ID":"4be9ce77-13be-475c-bc98-c32ab3367a7a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4c211486-e8d4-4bfa-8477-5da054e93cc4",
    path: "/product/4c211486-e8d4-4bfa-8477-5da054e93cc4",
    meta: {"ID":"4c211486-e8d4-4bfa-8477-5da054e93cc4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|4c2d1a19-d471-4b99-a54d-d9a23ad35e78",
    path: "/product/4c2d1a19-d471-4b99-a54d-d9a23ad35e78",
    meta: {"ID":"4c2d1a19-d471-4b99-a54d-d9a23ad35e78","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4c325da8-52df-4665-a4ed-10bcfff01b91",
    path: "/product/4c325da8-52df-4665-a4ed-10bcfff01b91",
    meta: {"ID":"4c325da8-52df-4665-a4ed-10bcfff01b91","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|4c35755f-c90a-4b56-b537-1736632f1cf5",
    path: "/product/4c35755f-c90a-4b56-b537-1736632f1cf5",
    meta: {"ID":"4c35755f-c90a-4b56-b537-1736632f1cf5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4c45e47b-c274-482b-9ca6-f71b9ee29524",
    path: "/product/4c45e47b-c274-482b-9ca6-f71b9ee29524",
    meta: {"ID":"4c45e47b-c274-482b-9ca6-f71b9ee29524","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|4c53278e-86f7-4108-88b5-8db4998f6c2f",
    path: "/product/4c53278e-86f7-4108-88b5-8db4998f6c2f",
    meta: {"ID":"4c53278e-86f7-4108-88b5-8db4998f6c2f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4c6aa845-50ce-4d69-a640-76e128525a1a",
    path: "/product/4c6aa845-50ce-4d69-a640-76e128525a1a",
    meta: {"ID":"4c6aa845-50ce-4d69-a640-76e128525a1a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4c8431e8-4e80-4554-885f-a342b18e1919",
    path: "/product/4c8431e8-4e80-4554-885f-a342b18e1919",
    meta: {"ID":"4c8431e8-4e80-4554-885f-a342b18e1919","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|4ca0dd89-1a25-4a7f-8207-90c19b06a090",
    path: "/product/4ca0dd89-1a25-4a7f-8207-90c19b06a090",
    meta: {"ID":"4ca0dd89-1a25-4a7f-8207-90c19b06a090","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4cc19454-5def-413d-b7ba-74440cec9841",
    path: "/product/4cc19454-5def-413d-b7ba-74440cec9841",
    meta: {"ID":"4cc19454-5def-413d-b7ba-74440cec9841","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4ccf0e73-a593-47a4-b0ac-2744eee0edf4",
    path: "/product/4ccf0e73-a593-47a4-b0ac-2744eee0edf4",
    meta: {"ID":"4ccf0e73-a593-47a4-b0ac-2744eee0edf4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4cdc7af7-e955-47ae-ba5b-f72d4710f4d6",
    path: "/product/4cdc7af7-e955-47ae-ba5b-f72d4710f4d6",
    meta: {"ID":"4cdc7af7-e955-47ae-ba5b-f72d4710f4d6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4ce9241f-f2e9-494e-82ca-abc4259418a8",
    path: "/product/4ce9241f-f2e9-494e-82ca-abc4259418a8",
    meta: {"ID":"4ce9241f-f2e9-494e-82ca-abc4259418a8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4ceba7a8-65fc-4c9e-bba4-a8cbec2669c4",
    path: "/product/4ceba7a8-65fc-4c9e-bba4-a8cbec2669c4",
    meta: {"ID":"4ceba7a8-65fc-4c9e-bba4-a8cbec2669c4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|4cefa379-f730-497c-a951-9d4be32ce895",
    path: "/product/4cefa379-f730-497c-a951-9d4be32ce895",
    meta: {"ID":"4cefa379-f730-497c-a951-9d4be32ce895","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4d159cd5-334b-490c-ab92-63230621049d",
    path: "/product/4d159cd5-334b-490c-ab92-63230621049d",
    meta: {"ID":"4d159cd5-334b-490c-ab92-63230621049d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|4d3ef0e8-63a3-4c23-aa02-fc01f46d357e",
    path: "/product/4d3ef0e8-63a3-4c23-aa02-fc01f46d357e",
    meta: {"ID":"4d3ef0e8-63a3-4c23-aa02-fc01f46d357e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4d3ff90a-b0fa-4a2e-a11b-a2d7faa221d9",
    path: "/product/4d3ff90a-b0fa-4a2e-a11b-a2d7faa221d9",
    meta: {"ID":"4d3ff90a-b0fa-4a2e-a11b-a2d7faa221d9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|4d419a10-00d5-4e68-ad4b-9c77addbc965",
    path: "/product/4d419a10-00d5-4e68-ad4b-9c77addbc965",
    meta: {"ID":"4d419a10-00d5-4e68-ad4b-9c77addbc965","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73e4936d-d27c-44d6-85f2-bbd41d2ec26a|4d716886-b1f3-4992-b515-c568abd0f132",
    path: "/product/4d716886-b1f3-4992-b515-c568abd0f132",
    meta: {"ID":"4d716886-b1f3-4992-b515-c568abd0f132","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4d839bdf-e70c-49ff-98ae-f7f7859c2c7e",
    path: "/product/4d839bdf-e70c-49ff-98ae-f7f7859c2c7e",
    meta: {"ID":"4d839bdf-e70c-49ff-98ae-f7f7859c2c7e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e2a1e7d9-7ca7-4450-84e5-be47dd4e74a5|4db2000c-7fe1-43d6-809e-d95be4998c8f",
    path: "/product/4db2000c-7fe1-43d6-809e-d95be4998c8f",
    meta: {"ID":"4db2000c-7fe1-43d6-809e-d95be4998c8f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4dc42346-d516-4ac1-aec8-c12c31b6460b",
    path: "/product/4dc42346-d516-4ac1-aec8-c12c31b6460b",
    meta: {"ID":"4dc42346-d516-4ac1-aec8-c12c31b6460b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0686e42-5664-44b8-b850-26f555406842|4dee8278-ff4d-4a36-bfce-4e9e5f5d5f85",
    path: "/product/4dee8278-ff4d-4a36-bfce-4e9e5f5d5f85",
    meta: {"ID":"4dee8278-ff4d-4a36-bfce-4e9e5f5d5f85","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1519fe15-47e1-44ff-8747-6714ca96f7aa|4df9270d-f194-453c-9643-73048fa7ef26",
    path: "/product/4df9270d-f194-453c-9643-73048fa7ef26",
    meta: {"ID":"4df9270d-f194-453c-9643-73048fa7ef26","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4dfea47e-13b9-4608-8854-665fd3aa2138",
    path: "/product/4dfea47e-13b9-4608-8854-665fd3aa2138",
    meta: {"ID":"4dfea47e-13b9-4608-8854-665fd3aa2138","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4e196a9e-4da0-4caf-bf35-4a1c90889075",
    path: "/product/4e196a9e-4da0-4caf-bf35-4a1c90889075",
    meta: {"ID":"4e196a9e-4da0-4caf-bf35-4a1c90889075","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4e2208ee-03d8-4b79-a23c-6dcb7c2381d5",
    path: "/product/4e2208ee-03d8-4b79-a23c-6dcb7c2381d5",
    meta: {"ID":"4e2208ee-03d8-4b79-a23c-6dcb7c2381d5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4e282fbe-517a-4a73-a1e8-4430ab68ae54",
    path: "/product/4e282fbe-517a-4a73-a1e8-4430ab68ae54",
    meta: {"ID":"4e282fbe-517a-4a73-a1e8-4430ab68ae54","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|4e312c58-772b-4885-9e15-50a1784da9bc",
    path: "/product/4e312c58-772b-4885-9e15-50a1784da9bc",
    meta: {"ID":"4e312c58-772b-4885-9e15-50a1784da9bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|4e3e7b7f-728e-49c6-9b03-720a713113c9",
    path: "/product/4e3e7b7f-728e-49c6-9b03-720a713113c9",
    meta: {"ID":"4e3e7b7f-728e-49c6-9b03-720a713113c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4ea29cde-3de5-40f9-aa39-dc8800cc190f",
    path: "/product/4ea29cde-3de5-40f9-aa39-dc8800cc190f",
    meta: {"ID":"4ea29cde-3de5-40f9-aa39-dc8800cc190f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|4eb03731-0467-41e7-b974-9a348bde4627",
    path: "/product/4eb03731-0467-41e7-b974-9a348bde4627",
    meta: {"ID":"4eb03731-0467-41e7-b974-9a348bde4627","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|4ec17b2e-2e9b-402c-8201-220466105864",
    path: "/product/4ec17b2e-2e9b-402c-8201-220466105864",
    meta: {"ID":"4ec17b2e-2e9b-402c-8201-220466105864","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|4ee5f57c-7877-473f-be59-ec3ebc1ba04d",
    path: "/product/4ee5f57c-7877-473f-be59-ec3ebc1ba04d",
    meta: {"ID":"4ee5f57c-7877-473f-be59-ec3ebc1ba04d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4f06b2fb-d987-40c4-8036-e00918c18cf0",
    path: "/product/4f06b2fb-d987-40c4-8036-e00918c18cf0",
    meta: {"ID":"4f06b2fb-d987-40c4-8036-e00918c18cf0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4f42c6b5-adf7-464b-8153-d33e6300185c",
    path: "/product/4f42c6b5-adf7-464b-8153-d33e6300185c",
    meta: {"ID":"4f42c6b5-adf7-464b-8153-d33e6300185c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4089a20c-7c4e-410d-9a00-8bbac0d71cc8|4f527d4b-59f9-4ffa-8cfe-6eb640c17b4d",
    path: "/product/4f527d4b-59f9-4ffa-8cfe-6eb640c17b4d",
    meta: {"ID":"4f527d4b-59f9-4ffa-8cfe-6eb640c17b4d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4f54417f-53dc-497f-b6f1-eb6a4628a514",
    path: "/product/4f54417f-53dc-497f-b6f1-eb6a4628a514",
    meta: {"ID":"4f54417f-53dc-497f-b6f1-eb6a4628a514","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4f708c33-cf78-4dfb-af78-4038a43e6f0a",
    path: "/product/4f708c33-cf78-4dfb-af78-4038a43e6f0a",
    meta: {"ID":"4f708c33-cf78-4dfb-af78-4038a43e6f0a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|4f789337-48a9-4a55-b2d7-15619de5aefa",
    path: "/product/4f789337-48a9-4a55-b2d7-15619de5aefa",
    meta: {"ID":"4f789337-48a9-4a55-b2d7-15619de5aefa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|4f795e1b-8149-464d-bab5-34ef32dc13d0",
    path: "/product/4f795e1b-8149-464d-bab5-34ef32dc13d0",
    meta: {"ID":"4f795e1b-8149-464d-bab5-34ef32dc13d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|61920873-b03c-43ac-aa78-5e5acd2bdfe1|4f8adeb7-be95-4432-adbd-ac0861b88510",
    path: "/product/4f8adeb7-be95-4432-adbd-ac0861b88510",
    meta: {"ID":"4f8adeb7-be95-4432-adbd-ac0861b88510","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|4fafae51-f255-4879-8ccc-001d7c831e6e",
    path: "/product/4fafae51-f255-4879-8ccc-001d7c831e6e",
    meta: {"ID":"4fafae51-f255-4879-8ccc-001d7c831e6e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|4fc1fab9-c74b-4bfa-82e1-8d83cde4aa91",
    path: "/product/4fc1fab9-c74b-4bfa-82e1-8d83cde4aa91",
    meta: {"ID":"4fc1fab9-c74b-4bfa-82e1-8d83cde4aa91","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|4fca108b-722f-4ad1-a4af-ee4235a77957",
    path: "/product/4fca108b-722f-4ad1-a4af-ee4235a77957",
    meta: {"ID":"4fca108b-722f-4ad1-a4af-ee4235a77957","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|4fcf9d7f-472f-405f-aee5-863b07f6ca3a",
    path: "/product/4fcf9d7f-472f-405f-aee5-863b07f6ca3a",
    meta: {"ID":"4fcf9d7f-472f-405f-aee5-863b07f6ca3a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|4fe495d3-3e9b-4655-9d03-6d4c21b8e35f",
    path: "/product/4fe495d3-3e9b-4655-9d03-6d4c21b8e35f",
    meta: {"ID":"4fe495d3-3e9b-4655-9d03-6d4c21b8e35f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2e4c4f9e-4ea3-49dc-b9e8-6726b7080501|4febfe3a-26fd-4f98-9499-7bacb47071a8",
    path: "/product/4febfe3a-26fd-4f98-9499-7bacb47071a8",
    meta: {"ID":"4febfe3a-26fd-4f98-9499-7bacb47071a8","Title":"","GroupID":"2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","GroupTitle":"Детское меню","GroupLink":"/menu/2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|4ff07fd2-b7b5-4258-aef6-b72cb9dac481",
    path: "/product/4ff07fd2-b7b5-4258-aef6-b72cb9dac481",
    meta: {"ID":"4ff07fd2-b7b5-4258-aef6-b72cb9dac481","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|500308fc-056d-4f87-9c88-bd3e848698a6",
    path: "/product/500308fc-056d-4f87-9c88-bd3e848698a6",
    meta: {"ID":"500308fc-056d-4f87-9c88-bd3e848698a6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|502e7403-0f01-4dce-be4f-4e5e0907b643",
    path: "/product/502e7403-0f01-4dce-be4f-4e5e0907b643",
    meta: {"ID":"502e7403-0f01-4dce-be4f-4e5e0907b643","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|503fb13c-4b44-48ca-9e80-d42c08030982",
    path: "/product/503fb13c-4b44-48ca-9e80-d42c08030982",
    meta: {"ID":"503fb13c-4b44-48ca-9e80-d42c08030982","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5063f00e-1e20-408c-8506-6fb54d6ac71c",
    path: "/product/5063f00e-1e20-408c-8506-6fb54d6ac71c",
    meta: {"ID":"5063f00e-1e20-408c-8506-6fb54d6ac71c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5086b140-4c23-4170-a4c4-436690ffa62f",
    path: "/product/5086b140-4c23-4170-a4c4-436690ffa62f",
    meta: {"ID":"5086b140-4c23-4170-a4c4-436690ffa62f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|509e3846-4ad9-4004-81b0-e85c269a6fa9",
    path: "/product/509e3846-4ad9-4004-81b0-e85c269a6fa9",
    meta: {"ID":"509e3846-4ad9-4004-81b0-e85c269a6fa9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|50b02c31-41f2-4221-b11d-45c8e87a01ac",
    path: "/product/50b02c31-41f2-4221-b11d-45c8e87a01ac",
    meta: {"ID":"50b02c31-41f2-4221-b11d-45c8e87a01ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|50f88db6-c8e6-4620-ad50-62279713b81a",
    path: "/product/50f88db6-c8e6-4620-ad50-62279713b81a",
    meta: {"ID":"50f88db6-c8e6-4620-ad50-62279713b81a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|51597ba7-5e01-4aee-bbd2-3798e3a4bf9a",
    path: "/product/51597ba7-5e01-4aee-bbd2-3798e3a4bf9a",
    meta: {"ID":"51597ba7-5e01-4aee-bbd2-3798e3a4bf9a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|5160a0f3-7e30-4e8e-a5b7-3e483c4e1615",
    path: "/product/5160a0f3-7e30-4e8e-a5b7-3e483c4e1615",
    meta: {"ID":"5160a0f3-7e30-4e8e-a5b7-3e483c4e1615","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|51641e94-c329-464d-9041-1578173dac98",
    path: "/product/51641e94-c329-464d-9041-1578173dac98",
    meta: {"ID":"51641e94-c329-464d-9041-1578173dac98","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|516ef1cb-da43-4943-b728-30aa95f3caf3",
    path: "/product/516ef1cb-da43-4943-b728-30aa95f3caf3",
    meta: {"ID":"516ef1cb-da43-4943-b728-30aa95f3caf3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|5171facf-6779-4ed7-8a02-2d035566eb56",
    path: "/product/5171facf-6779-4ed7-8a02-2d035566eb56",
    meta: {"ID":"5171facf-6779-4ed7-8a02-2d035566eb56","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|5188524f-c6e7-4219-8f5d-8b8a13850f9e",
    path: "/product/5188524f-c6e7-4219-8f5d-8b8a13850f9e",
    meta: {"ID":"5188524f-c6e7-4219-8f5d-8b8a13850f9e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|51e3b3e1-a00d-4454-8e9f-3fa3439c49ae",
    path: "/product/51e3b3e1-a00d-4454-8e9f-3fa3439c49ae",
    meta: {"ID":"51e3b3e1-a00d-4454-8e9f-3fa3439c49ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|520efcf2-746e-4ea6-a395-537a0d854899",
    path: "/product/520efcf2-746e-4ea6-a395-537a0d854899",
    meta: {"ID":"520efcf2-746e-4ea6-a395-537a0d854899","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5261b258-da0f-442a-8e88-d726fed3cfee",
    path: "/product/5261b258-da0f-442a-8e88-d726fed3cfee",
    meta: {"ID":"5261b258-da0f-442a-8e88-d726fed3cfee","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|526b0d50-2a77-49a0-a192-39842cf55ff6",
    path: "/product/526b0d50-2a77-49a0-a192-39842cf55ff6",
    meta: {"ID":"526b0d50-2a77-49a0-a192-39842cf55ff6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ef0a0b3c-71b8-40c4-9f16-3c9038b9738d|52821796-2784-49a8-8aa5-296681f5615b",
    path: "/product/52821796-2784-49a8-8aa5-296681f5615b",
    meta: {"ID":"52821796-2784-49a8-8aa5-296681f5615b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7bdadf21-f387-4551-8ae2-d2b5ae64736e|528a2f9f-48ad-4bf5-9e20-7be97975f702",
    path: "/product/528a2f9f-48ad-4bf5-9e20-7be97975f702",
    meta: {"ID":"528a2f9f-48ad-4bf5-9e20-7be97975f702","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|528d4932-f579-4ac0-a5c8-191c1decbecc",
    path: "/product/528d4932-f579-4ac0-a5c8-191c1decbecc",
    meta: {"ID":"528d4932-f579-4ac0-a5c8-191c1decbecc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|528f0c40-e434-449a-a144-85cc020298c0",
    path: "/product/528f0c40-e434-449a-a144-85cc020298c0",
    meta: {"ID":"528f0c40-e434-449a-a144-85cc020298c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|529ab5b9-53b9-4de1-8c52-6333ac3eff27",
    path: "/product/529ab5b9-53b9-4de1-8c52-6333ac3eff27",
    meta: {"ID":"529ab5b9-53b9-4de1-8c52-6333ac3eff27","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|529cae76-d08b-4b77-9a76-bf60cf39284e",
    path: "/product/529cae76-d08b-4b77-9a76-bf60cf39284e",
    meta: {"ID":"529cae76-d08b-4b77-9a76-bf60cf39284e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|52bb874f-c5ff-4d26-905c-5bb449161397",
    path: "/product/52bb874f-c5ff-4d26-905c-5bb449161397",
    meta: {"ID":"52bb874f-c5ff-4d26-905c-5bb449161397","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|52c9a993-8f00-49c7-99e2-1bf476699597",
    path: "/product/52c9a993-8f00-49c7-99e2-1bf476699597",
    meta: {"ID":"52c9a993-8f00-49c7-99e2-1bf476699597","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|52deebcf-28ea-45d1-8354-919eb65ccade",
    path: "/product/52deebcf-28ea-45d1-8354-919eb65ccade",
    meta: {"ID":"52deebcf-28ea-45d1-8354-919eb65ccade","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|530b515c-b51a-4fdd-a9f5-19079c859e21",
    path: "/product/530b515c-b51a-4fdd-a9f5-19079c859e21",
    meta: {"ID":"530b515c-b51a-4fdd-a9f5-19079c859e21","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|531d9d01-0e6f-4cc6-b1d1-9bcd010f87e5",
    path: "/product/531d9d01-0e6f-4cc6-b1d1-9bcd010f87e5",
    meta: {"ID":"531d9d01-0e6f-4cc6-b1d1-9bcd010f87e5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|5376b118-8fd3-4fb4-9792-bcc8b8dc809b",
    path: "/product/5376b118-8fd3-4fb4-9792-bcc8b8dc809b",
    meta: {"ID":"5376b118-8fd3-4fb4-9792-bcc8b8dc809b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|5379997d-f1a5-4249-bc6f-25f30dfec90a",
    path: "/product/5379997d-f1a5-4249-bc6f-25f30dfec90a",
    meta: {"ID":"5379997d-f1a5-4249-bc6f-25f30dfec90a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|537ade3d-edfa-4610-ba34-013b704b938b",
    path: "/product/537ade3d-edfa-4610-ba34-013b704b938b",
    meta: {"ID":"537ade3d-edfa-4610-ba34-013b704b938b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|537d0d4f-7e19-4765-9384-3dd35b25c933",
    path: "/product/537d0d4f-7e19-4765-9384-3dd35b25c933",
    meta: {"ID":"537d0d4f-7e19-4765-9384-3dd35b25c933","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|53a51057-c154-4cde-a61e-b5a1c384f34a",
    path: "/product/53a51057-c154-4cde-a61e-b5a1c384f34a",
    meta: {"ID":"53a51057-c154-4cde-a61e-b5a1c384f34a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|53bfa5dc-214f-4625-83bf-e74fede183d0",
    path: "/product/53bfa5dc-214f-4625-83bf-e74fede183d0",
    meta: {"ID":"53bfa5dc-214f-4625-83bf-e74fede183d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|53c285b9-8c7f-4218-a275-c235a2a1c057",
    path: "/product/53c285b9-8c7f-4218-a275-c235a2a1c057",
    meta: {"ID":"53c285b9-8c7f-4218-a275-c235a2a1c057","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73e4936d-d27c-44d6-85f2-bbd41d2ec26a|53d86ea8-27ce-459c-a7c1-c917f43eada6",
    path: "/product/53d86ea8-27ce-459c-a7c1-c917f43eada6",
    meta: {"ID":"53d86ea8-27ce-459c-a7c1-c917f43eada6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|53da9856-8171-4dbb-9089-1c5130b9aef2",
    path: "/product/53da9856-8171-4dbb-9089-1c5130b9aef2",
    meta: {"ID":"53da9856-8171-4dbb-9089-1c5130b9aef2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|61920873-b03c-43ac-aa78-5e5acd2bdfe1|53e08e7d-6937-43d5-b987-7d47c4e3a89d",
    path: "/product/53e08e7d-6937-43d5-b987-7d47c4e3a89d",
    meta: {"ID":"53e08e7d-6937-43d5-b987-7d47c4e3a89d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|53e1fbdc-b357-4ecc-91bf-6cc67b6b75d7",
    path: "/product/53e1fbdc-b357-4ecc-91bf-6cc67b6b75d7",
    meta: {"ID":"53e1fbdc-b357-4ecc-91bf-6cc67b6b75d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|762d5eb6-8211-47c9-922f-0c67a6b9fcff|53e3273a-89e4-40c7-91e7-5149c277f6d5",
    path: "/product/53e3273a-89e4-40c7-91e7-5149c277f6d5",
    meta: {"ID":"53e3273a-89e4-40c7-91e7-5149c277f6d5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5403ac85-8e81-479e-be4a-e173b8f51a6b",
    path: "/product/5403ac85-8e81-479e-be4a-e173b8f51a6b",
    meta: {"ID":"5403ac85-8e81-479e-be4a-e173b8f51a6b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|5404e921-a0d1-4943-a82e-694a7a7e6b89",
    path: "/product/5404e921-a0d1-4943-a82e-694a7a7e6b89",
    meta: {"ID":"5404e921-a0d1-4943-a82e-694a7a7e6b89","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|540bdc62-82de-4a17-8654-302922f718e7",
    path: "/product/540bdc62-82de-4a17-8654-302922f718e7",
    meta: {"ID":"540bdc62-82de-4a17-8654-302922f718e7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|542468a5-a23e-4f85-8be1-3913a1dfc785",
    path: "/product/542468a5-a23e-4f85-8be1-3913a1dfc785",
    meta: {"ID":"542468a5-a23e-4f85-8be1-3913a1dfc785","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|542dd08f-d766-4eaa-b756-f48879520ca2",
    path: "/product/542dd08f-d766-4eaa-b756-f48879520ca2",
    meta: {"ID":"542dd08f-d766-4eaa-b756-f48879520ca2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7bdadf21-f387-4551-8ae2-d2b5ae64736e|5435fe7f-ac38-4f68-9ac4-16d983451f6b",
    path: "/product/5435fe7f-ac38-4f68-9ac4-16d983451f6b",
    meta: {"ID":"5435fe7f-ac38-4f68-9ac4-16d983451f6b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|5447b143-8e51-4abe-bc4e-426a088dcb30",
    path: "/product/5447b143-8e51-4abe-bc4e-426a088dcb30",
    meta: {"ID":"5447b143-8e51-4abe-bc4e-426a088dcb30","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7b8f0d1b-6e98-4d6a-be01-03b913fcdb44|5447c606-b7ac-4a9a-ac84-dae74e4ebbe2",
    path: "/product/5447c606-b7ac-4a9a-ac84-dae74e4ebbe2",
    meta: {"ID":"5447c606-b7ac-4a9a-ac84-dae74e4ebbe2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5472693c-bcc0-49e0-9735-eda8333314d0",
    path: "/product/5472693c-bcc0-49e0-9735-eda8333314d0",
    meta: {"ID":"5472693c-bcc0-49e0-9735-eda8333314d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|54a46108-1aba-4e14-9109-9ca3f42593e5",
    path: "/product/54a46108-1aba-4e14-9109-9ca3f42593e5",
    meta: {"ID":"54a46108-1aba-4e14-9109-9ca3f42593e5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|54aa832a-7138-4380-bc7f-42922f7a9b58",
    path: "/product/54aa832a-7138-4380-bc7f-42922f7a9b58",
    meta: {"ID":"54aa832a-7138-4380-bc7f-42922f7a9b58","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|54c9d5f5-f5de-491d-b225-0787a08abaf4",
    path: "/product/54c9d5f5-f5de-491d-b225-0787a08abaf4",
    meta: {"ID":"54c9d5f5-f5de-491d-b225-0787a08abaf4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|54cb4c95-17dd-4df9-ae3a-d60f2ca5893a",
    path: "/product/54cb4c95-17dd-4df9-ae3a-d60f2ca5893a",
    meta: {"ID":"54cb4c95-17dd-4df9-ae3a-d60f2ca5893a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|54e5cc8d-2191-44ad-9ccb-afa3aef107de",
    path: "/product/54e5cc8d-2191-44ad-9ccb-afa3aef107de",
    meta: {"ID":"54e5cc8d-2191-44ad-9ccb-afa3aef107de","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|55066b59-8d42-4efa-8733-678e4337e56d",
    path: "/product/55066b59-8d42-4efa-8733-678e4337e56d",
    meta: {"ID":"55066b59-8d42-4efa-8733-678e4337e56d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2d9bf239-b509-4129-9d84-16287bf039d0|5513a269-8c0c-423e-b925-619e4b82f4a8",
    path: "/product/5513a269-8c0c-423e-b925-619e4b82f4a8",
    meta: {"ID":"5513a269-8c0c-423e-b925-619e4b82f4a8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|554171c3-4887-4a1e-b526-b9171dd3e372",
    path: "/product/554171c3-4887-4a1e-b526-b9171dd3e372",
    meta: {"ID":"554171c3-4887-4a1e-b526-b9171dd3e372","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|55421d69-6b16-48ac-9588-e0fdb719d1f6",
    path: "/product/55421d69-6b16-48ac-9588-e0fdb719d1f6",
    meta: {"ID":"55421d69-6b16-48ac-9588-e0fdb719d1f6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|5551ebc6-78d2-4ab9-861c-8177f9da7e62",
    path: "/product/5551ebc6-78d2-4ab9-861c-8177f9da7e62",
    meta: {"ID":"5551ebc6-78d2-4ab9-861c-8177f9da7e62","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|5560edda-51b5-4276-895d-12e2c578ad67",
    path: "/product/5560edda-51b5-4276-895d-12e2c578ad67",
    meta: {"ID":"5560edda-51b5-4276-895d-12e2c578ad67","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|556d258c-5d1b-4a9e-96b0-f2af976d04d6",
    path: "/product/556d258c-5d1b-4a9e-96b0-f2af976d04d6",
    meta: {"ID":"556d258c-5d1b-4a9e-96b0-f2af976d04d6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|55872169-512a-4e5a-931f-920906176f75",
    path: "/product/55872169-512a-4e5a-931f-920906176f75",
    meta: {"ID":"55872169-512a-4e5a-931f-920906176f75","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|55a80098-1d2d-4bf1-ae08-b45551394952",
    path: "/product/55a80098-1d2d-4bf1-ae08-b45551394952",
    meta: {"ID":"55a80098-1d2d-4bf1-ae08-b45551394952","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|55b3c8f1-470b-4e26-986b-ab0c59933d4d",
    path: "/product/55b3c8f1-470b-4e26-986b-ab0c59933d4d",
    meta: {"ID":"55b3c8f1-470b-4e26-986b-ab0c59933d4d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73e4936d-d27c-44d6-85f2-bbd41d2ec26a|55f353c5-f72c-47ad-bdab-e1b45675fa3b",
    path: "/product/55f353c5-f72c-47ad-bdab-e1b45675fa3b",
    meta: {"ID":"55f353c5-f72c-47ad-bdab-e1b45675fa3b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|562a0c53-409e-4ec5-b37e-ef1e1fb3addf",
    path: "/product/562a0c53-409e-4ec5-b37e-ef1e1fb3addf",
    meta: {"ID":"562a0c53-409e-4ec5-b37e-ef1e1fb3addf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5632fae2-16e8-47dc-a9a5-6fbc38b783e3",
    path: "/product/5632fae2-16e8-47dc-a9a5-6fbc38b783e3",
    meta: {"ID":"5632fae2-16e8-47dc-a9a5-6fbc38b783e3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|563667f0-c79a-4cc0-8f7e-e4a9b42e7432",
    path: "/product/563667f0-c79a-4cc0-8f7e-e4a9b42e7432",
    meta: {"ID":"563667f0-c79a-4cc0-8f7e-e4a9b42e7432","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|5641c8c9-5d80-4467-9325-f0ff40d549c9",
    path: "/product/5641c8c9-5d80-4467-9325-f0ff40d549c9",
    meta: {"ID":"5641c8c9-5d80-4467-9325-f0ff40d549c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5676f1e8-770d-4925-b687-a011d26a7324",
    path: "/product/5676f1e8-770d-4925-b687-a011d26a7324",
    meta: {"ID":"5676f1e8-770d-4925-b687-a011d26a7324","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|567fb010-ff34-43d5-b34d-6e504d3bbafa",
    path: "/product/567fb010-ff34-43d5-b34d-6e504d3bbafa",
    meta: {"ID":"567fb010-ff34-43d5-b34d-6e504d3bbafa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|568d9a5b-1292-48d6-9056-055c8b77ee1f",
    path: "/product/568d9a5b-1292-48d6-9056-055c8b77ee1f",
    meta: {"ID":"568d9a5b-1292-48d6-9056-055c8b77ee1f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|568dd495-644a-415c-9fc4-868f15413a0d",
    path: "/product/568dd495-644a-415c-9fc4-868f15413a0d",
    meta: {"ID":"568dd495-644a-415c-9fc4-868f15413a0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|569cba34-adbe-4173-8726-fa844d19114e",
    path: "/product/569cba34-adbe-4173-8726-fa844d19114e",
    meta: {"ID":"569cba34-adbe-4173-8726-fa844d19114e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|d586fdfe-fc1e-4037-89d8-4ebed69e1496|56e7dc7f-34a6-4fed-98ed-5b746f9f66b2",
    path: "/product/56e7dc7f-34a6-4fed-98ed-5b746f9f66b2",
    meta: {"ID":"56e7dc7f-34a6-4fed-98ed-5b746f9f66b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|56f2d78c-b1df-4c8b-932f-e975293c5e7f",
    path: "/product/56f2d78c-b1df-4c8b-932f-e975293c5e7f",
    meta: {"ID":"56f2d78c-b1df-4c8b-932f-e975293c5e7f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|56f36ef4-1182-4fbf-9143-d57fd2da1a08",
    path: "/product/56f36ef4-1182-4fbf-9143-d57fd2da1a08",
    meta: {"ID":"56f36ef4-1182-4fbf-9143-d57fd2da1a08","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|56ff75be-95ae-4818-8089-e5540b318ef4",
    path: "/product/56ff75be-95ae-4818-8089-e5540b318ef4",
    meta: {"ID":"56ff75be-95ae-4818-8089-e5540b318ef4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|570b19a1-adbf-4e27-a469-5a5c54597662",
    path: "/product/570b19a1-adbf-4e27-a469-5a5c54597662",
    meta: {"ID":"570b19a1-adbf-4e27-a469-5a5c54597662","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|570cb682-74e7-4a4f-868b-2b5547033111",
    path: "/product/570cb682-74e7-4a4f-868b-2b5547033111",
    meta: {"ID":"570cb682-74e7-4a4f-868b-2b5547033111","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|570ea012-06ae-4634-ad3e-966bdedfe66b",
    path: "/product/570ea012-06ae-4634-ad3e-966bdedfe66b",
    meta: {"ID":"570ea012-06ae-4634-ad3e-966bdedfe66b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|571d1c92-7997-421e-842c-064178c2a9a3",
    path: "/product/571d1c92-7997-421e-842c-064178c2a9a3",
    meta: {"ID":"571d1c92-7997-421e-842c-064178c2a9a3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|572261b1-60ce-4c14-ad6d-50bd7dede566",
    path: "/product/572261b1-60ce-4c14-ad6d-50bd7dede566",
    meta: {"ID":"572261b1-60ce-4c14-ad6d-50bd7dede566","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5733acf2-8b73-4a61-bdf6-9928fc7ba77d",
    path: "/product/5733acf2-8b73-4a61-bdf6-9928fc7ba77d",
    meta: {"ID":"5733acf2-8b73-4a61-bdf6-9928fc7ba77d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5737165e-9851-4b03-bc06-39c97ad41a69",
    path: "/product/5737165e-9851-4b03-bc06-39c97ad41a69",
    meta: {"ID":"5737165e-9851-4b03-bc06-39c97ad41a69","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|577f665a-b98b-4271-93cb-343a7e6d1156",
    path: "/product/577f665a-b98b-4271-93cb-343a7e6d1156",
    meta: {"ID":"577f665a-b98b-4271-93cb-343a7e6d1156","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|578d9941-4223-481f-a8dd-67c1ce00e44c",
    path: "/product/578d9941-4223-481f-a8dd-67c1ce00e44c",
    meta: {"ID":"578d9941-4223-481f-a8dd-67c1ce00e44c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5796fd65-24a9-48c0-982b-1f08b79ff683",
    path: "/product/5796fd65-24a9-48c0-982b-1f08b79ff683",
    meta: {"ID":"5796fd65-24a9-48c0-982b-1f08b79ff683","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|579d503d-24ff-46d8-8ea1-2339bb7aa268",
    path: "/product/579d503d-24ff-46d8-8ea1-2339bb7aa268",
    meta: {"ID":"579d503d-24ff-46d8-8ea1-2339bb7aa268","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|57abb2e3-35c9-435c-ab31-41dcff540500",
    path: "/product/57abb2e3-35c9-435c-ab31-41dcff540500",
    meta: {"ID":"57abb2e3-35c9-435c-ab31-41dcff540500","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|57c27fc0-1da2-4b06-abb4-24d4624b7a54",
    path: "/product/57c27fc0-1da2-4b06-abb4-24d4624b7a54",
    meta: {"ID":"57c27fc0-1da2-4b06-abb4-24d4624b7a54","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|57d8c69c-6eda-414e-8de3-ef3486ba8094",
    path: "/product/57d8c69c-6eda-414e-8de3-ef3486ba8094",
    meta: {"ID":"57d8c69c-6eda-414e-8de3-ef3486ba8094","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|58045420-cae2-4f0a-834d-7ec048366fbd",
    path: "/product/58045420-cae2-4f0a-834d-7ec048366fbd",
    meta: {"ID":"58045420-cae2-4f0a-834d-7ec048366fbd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|580720aa-d754-4b87-a49a-3fe9629d0320",
    path: "/product/580720aa-d754-4b87-a49a-3fe9629d0320",
    meta: {"ID":"580720aa-d754-4b87-a49a-3fe9629d0320","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6cbf9c41-8e9c-4704-af3f-382f792c10c4|586069d6-8212-4f62-9b9c-40f7c2ac76a5",
    path: "/product/586069d6-8212-4f62-9b9c-40f7c2ac76a5",
    meta: {"ID":"586069d6-8212-4f62-9b9c-40f7c2ac76a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|588743bb-3188-43a2-ac6b-7209183c124c",
    path: "/product/588743bb-3188-43a2-ac6b-7209183c124c",
    meta: {"ID":"588743bb-3188-43a2-ac6b-7209183c124c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|42a5e113-75f4-40d2-a9ee-575b6e6c0b8d|58baa6b2-bd5c-46de-b336-ab3acaaf787f",
    path: "/product/58baa6b2-bd5c-46de-b336-ab3acaaf787f",
    meta: {"ID":"58baa6b2-bd5c-46de-b336-ab3acaaf787f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|db514044-cc74-4cb4-88c1-e0d3608fecb6|58bad2c4-b1d5-44bd-abf0-15d6279ba76a",
    path: "/product/58bad2c4-b1d5-44bd-abf0-15d6279ba76a",
    meta: {"ID":"58bad2c4-b1d5-44bd-abf0-15d6279ba76a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|58ccd5b3-d644-48be-9a37-56f84a345399",
    path: "/product/58ccd5b3-d644-48be-9a37-56f84a345399",
    meta: {"ID":"58ccd5b3-d644-48be-9a37-56f84a345399","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|58dbd0c6-b41f-44de-ab1f-ac08cc03f46b",
    path: "/product/58dbd0c6-b41f-44de-ab1f-ac08cc03f46b",
    meta: {"ID":"58dbd0c6-b41f-44de-ab1f-ac08cc03f46b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|58e5534f-268b-4796-8507-1b05b1f7e63f",
    path: "/product/58e5534f-268b-4796-8507-1b05b1f7e63f",
    meta: {"ID":"58e5534f-268b-4796-8507-1b05b1f7e63f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|58f3de5d-2937-482b-851b-488d56f2bc2c",
    path: "/product/58f3de5d-2937-482b-851b-488d56f2bc2c",
    meta: {"ID":"58f3de5d-2937-482b-851b-488d56f2bc2c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|59063ba7-3173-4cb1-bf39-036a3d1a843c",
    path: "/product/59063ba7-3173-4cb1-bf39-036a3d1a843c",
    meta: {"ID":"59063ba7-3173-4cb1-bf39-036a3d1a843c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5919fcd7-b934-4a63-9539-ded89fcf086d",
    path: "/product/5919fcd7-b934-4a63-9539-ded89fcf086d",
    meta: {"ID":"5919fcd7-b934-4a63-9539-ded89fcf086d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|5975772c-d0a5-41cb-b8d1-2333a8762988",
    path: "/product/5975772c-d0a5-41cb-b8d1-2333a8762988",
    meta: {"ID":"5975772c-d0a5-41cb-b8d1-2333a8762988","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|597f1dc0-3747-4e67-ad30-67ab76c2ba53",
    path: "/product/597f1dc0-3747-4e67-ad30-67ab76c2ba53",
    meta: {"ID":"597f1dc0-3747-4e67-ad30-67ab76c2ba53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|598cd790-592c-4f69-b953-238965d09c0d",
    path: "/product/598cd790-592c-4f69-b953-238965d09c0d",
    meta: {"ID":"598cd790-592c-4f69-b953-238965d09c0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|59a0ccc9-b6c3-4980-a012-016de17e2dd3",
    path: "/product/59a0ccc9-b6c3-4980-a012-016de17e2dd3",
    meta: {"ID":"59a0ccc9-b6c3-4980-a012-016de17e2dd3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|59aa350e-d931-4e51-8a4c-00544c174929",
    path: "/product/59aa350e-d931-4e51-8a4c-00544c174929",
    meta: {"ID":"59aa350e-d931-4e51-8a4c-00544c174929","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|59ab9453-a97a-45a0-ad76-11e8190443a9",
    path: "/product/59ab9453-a97a-45a0-ad76-11e8190443a9",
    meta: {"ID":"59ab9453-a97a-45a0-ad76-11e8190443a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|59bb9325-1679-455b-8001-ec71e41782bf",
    path: "/product/59bb9325-1679-455b-8001-ec71e41782bf",
    meta: {"ID":"59bb9325-1679-455b-8001-ec71e41782bf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|59ee48fd-241c-43b3-89fd-8a075c3a2326",
    path: "/product/59ee48fd-241c-43b3-89fd-8a075c3a2326",
    meta: {"ID":"59ee48fd-241c-43b3-89fd-8a075c3a2326","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|59f8fbed-b67b-4d5d-9350-a4ab4a57545d",
    path: "/product/59f8fbed-b67b-4d5d-9350-a4ab4a57545d",
    meta: {"ID":"59f8fbed-b67b-4d5d-9350-a4ab4a57545d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|806b1c7a-ff06-45e9-ac72-7abe93c7a978|59fb5778-6cfd-41a9-99b0-f1441a4d0d62",
    path: "/product/59fb5778-6cfd-41a9-99b0-f1441a4d0d62",
    meta: {"ID":"59fb5778-6cfd-41a9-99b0-f1441a4d0d62","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|5a0b01e4-7fcf-48fa-859c-d2c42fe57c82",
    path: "/product/5a0b01e4-7fcf-48fa-859c-d2c42fe57c82",
    meta: {"ID":"5a0b01e4-7fcf-48fa-859c-d2c42fe57c82","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5a10b458-b69b-4962-afa1-e14295fa0197",
    path: "/product/5a10b458-b69b-4962-afa1-e14295fa0197",
    meta: {"ID":"5a10b458-b69b-4962-afa1-e14295fa0197","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|5a231875-0ad9-4971-bef0-5362d9eb64c8",
    path: "/product/5a231875-0ad9-4971-bef0-5362d9eb64c8",
    meta: {"ID":"5a231875-0ad9-4971-bef0-5362d9eb64c8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|5a4189e0-bd61-467a-ba57-79c724d35deb",
    path: "/product/5a4189e0-bd61-467a-ba57-79c724d35deb",
    meta: {"ID":"5a4189e0-bd61-467a-ba57-79c724d35deb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|5a6d2887-4c0d-4823-90d0-6e639e38610b",
    path: "/product/5a6d2887-4c0d-4823-90d0-6e639e38610b",
    meta: {"ID":"5a6d2887-4c0d-4823-90d0-6e639e38610b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|0f33a02b-92fa-4922-9e4c-0b4cc4e13e79|5a815f3b-0572-4f82-af09-87079987a89e",
    path: "/product/5a815f3b-0572-4f82-af09-87079987a89e",
    meta: {"ID":"5a815f3b-0572-4f82-af09-87079987a89e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5aaf66ac-a061-4286-b4fc-1b164ee4de09",
    path: "/product/5aaf66ac-a061-4286-b4fc-1b164ee4de09",
    meta: {"ID":"5aaf66ac-a061-4286-b4fc-1b164ee4de09","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|5ab2767b-7829-4e3b-953d-3988e4c53cee",
    path: "/product/5ab2767b-7829-4e3b-953d-3988e4c53cee",
    meta: {"ID":"5ab2767b-7829-4e3b-953d-3988e4c53cee","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|5abc6f8c-c4bd-4972-a341-fd27d72af3b3",
    path: "/product/5abc6f8c-c4bd-4972-a341-fd27d72af3b3",
    meta: {"ID":"5abc6f8c-c4bd-4972-a341-fd27d72af3b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|5aca26cd-3fb7-4106-ac2a-ac2673670e1d",
    path: "/product/5aca26cd-3fb7-4106-ac2a-ac2673670e1d",
    meta: {"ID":"5aca26cd-3fb7-4106-ac2a-ac2673670e1d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5b0513d8-c797-4213-8ea4-5c2419e11bfe",
    path: "/product/5b0513d8-c797-4213-8ea4-5c2419e11bfe",
    meta: {"ID":"5b0513d8-c797-4213-8ea4-5c2419e11bfe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|5b23a58f-7d3b-4e73-924d-ec34e33ee44e",
    path: "/product/5b23a58f-7d3b-4e73-924d-ec34e33ee44e",
    meta: {"ID":"5b23a58f-7d3b-4e73-924d-ec34e33ee44e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|5b23cc76-1dd5-4d0e-b335-106e4210ba3d",
    path: "/product/5b23cc76-1dd5-4d0e-b335-106e4210ba3d",
    meta: {"ID":"5b23cc76-1dd5-4d0e-b335-106e4210ba3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5b6823d8-989b-48cf-a0ba-a73c73998441",
    path: "/product/5b6823d8-989b-48cf-a0ba-a73c73998441",
    meta: {"ID":"5b6823d8-989b-48cf-a0ba-a73c73998441","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|5b718244-c2b6-4363-9a97-a26bc71d15cf",
    path: "/product/5b718244-c2b6-4363-9a97-a26bc71d15cf",
    meta: {"ID":"5b718244-c2b6-4363-9a97-a26bc71d15cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|5b9b63dc-1ae0-48ad-9552-0ac3523d827c",
    path: "/product/5b9b63dc-1ae0-48ad-9552-0ac3523d827c",
    meta: {"ID":"5b9b63dc-1ae0-48ad-9552-0ac3523d827c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5bb1c9d3-6062-4207-8898-4225a5a1492c",
    path: "/product/5bb1c9d3-6062-4207-8898-4225a5a1492c",
    meta: {"ID":"5bb1c9d3-6062-4207-8898-4225a5a1492c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5bb49380-896f-419e-bd3e-c6f9cf7ea7c1",
    path: "/product/5bb49380-896f-419e-bd3e-c6f9cf7ea7c1",
    meta: {"ID":"5bb49380-896f-419e-bd3e-c6f9cf7ea7c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|5bb9089a-bf24-4c9a-bd5c-cdbfd937e904",
    path: "/product/5bb9089a-bf24-4c9a-bd5c-cdbfd937e904",
    meta: {"ID":"5bb9089a-bf24-4c9a-bd5c-cdbfd937e904","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a77b60ce-796c-4cfb-a81f-523c73aff39d|5bd5615c-df8c-49ea-8230-e59b43824cdc",
    path: "/product/5bd5615c-df8c-49ea-8230-e59b43824cdc",
    meta: {"ID":"5bd5615c-df8c-49ea-8230-e59b43824cdc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|5bde7256-bd51-4785-8189-831376845759",
    path: "/product/5bde7256-bd51-4785-8189-831376845759",
    meta: {"ID":"5bde7256-bd51-4785-8189-831376845759","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|5be227c9-f9ca-4e40-b35e-9bad35add4ff",
    path: "/product/5be227c9-f9ca-4e40-b35e-9bad35add4ff",
    meta: {"ID":"5be227c9-f9ca-4e40-b35e-9bad35add4ff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|5beb7c1a-698c-49ee-8ac4-e3abd3badadc",
    path: "/product/5beb7c1a-698c-49ee-8ac4-e3abd3badadc",
    meta: {"ID":"5beb7c1a-698c-49ee-8ac4-e3abd3badadc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5c03c288-33a3-471a-a8ab-2ad4cf91d171",
    path: "/product/5c03c288-33a3-471a-a8ab-2ad4cf91d171",
    meta: {"ID":"5c03c288-33a3-471a-a8ab-2ad4cf91d171","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|5c040303-3023-48f0-9edc-273f466c7464",
    path: "/product/5c040303-3023-48f0-9edc-273f466c7464",
    meta: {"ID":"5c040303-3023-48f0-9edc-273f466c7464","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|5c44fcde-43ae-4298-9af3-63fff92beb08",
    path: "/product/5c44fcde-43ae-4298-9af3-63fff92beb08",
    meta: {"ID":"5c44fcde-43ae-4298-9af3-63fff92beb08","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5c470138-306f-466c-817f-19ad18cc954b",
    path: "/product/5c470138-306f-466c-817f-19ad18cc954b",
    meta: {"ID":"5c470138-306f-466c-817f-19ad18cc954b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5c486c17-daf3-4ce5-9063-917bb446961e",
    path: "/product/5c486c17-daf3-4ce5-9063-917bb446961e",
    meta: {"ID":"5c486c17-daf3-4ce5-9063-917bb446961e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5c509789-f5d3-489b-9422-3a812fdebbe6",
    path: "/product/5c509789-f5d3-489b-9422-3a812fdebbe6",
    meta: {"ID":"5c509789-f5d3-489b-9422-3a812fdebbe6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5c585108-360f-4fb3-a1ca-d8ace199030c",
    path: "/product/5c585108-360f-4fb3-a1ca-d8ace199030c",
    meta: {"ID":"5c585108-360f-4fb3-a1ca-d8ace199030c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|5cb465d8-b2a6-4147-a964-759685e0adf5",
    path: "/product/5cb465d8-b2a6-4147-a964-759685e0adf5",
    meta: {"ID":"5cb465d8-b2a6-4147-a964-759685e0adf5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5cbe43ac-770f-4eae-ab55-3a9530006c66",
    path: "/product/5cbe43ac-770f-4eae-ab55-3a9530006c66",
    meta: {"ID":"5cbe43ac-770f-4eae-ab55-3a9530006c66","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|5cde3c4b-6757-4f57-8acd-a728bdd17338",
    path: "/product/5cde3c4b-6757-4f57-8acd-a728bdd17338",
    meta: {"ID":"5cde3c4b-6757-4f57-8acd-a728bdd17338","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|5ce1a4b2-f0bf-47f6-906c-ba7baaab35f4",
    path: "/product/5ce1a4b2-f0bf-47f6-906c-ba7baaab35f4",
    meta: {"ID":"5ce1a4b2-f0bf-47f6-906c-ba7baaab35f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|5d2056cd-4fa4-46d5-a89c-bac806d0e85d",
    path: "/product/5d2056cd-4fa4-46d5-a89c-bac806d0e85d",
    meta: {"ID":"5d2056cd-4fa4-46d5-a89c-bac806d0e85d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|5d2d32cd-debc-4771-80e5-f9d8c19ddd0c",
    path: "/product/5d2d32cd-debc-4771-80e5-f9d8c19ddd0c",
    meta: {"ID":"5d2d32cd-debc-4771-80e5-f9d8c19ddd0c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5d320a5e-6d0a-444f-9192-5891cc380fa8",
    path: "/product/5d320a5e-6d0a-444f-9192-5891cc380fa8",
    meta: {"ID":"5d320a5e-6d0a-444f-9192-5891cc380fa8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|5d42a26f-281c-47b0-a6e9-905efecb4f67",
    path: "/product/5d42a26f-281c-47b0-a6e9-905efecb4f67",
    meta: {"ID":"5d42a26f-281c-47b0-a6e9-905efecb4f67","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5d4b39e7-ae9a-4cc4-8128-53e9d0e7fd93",
    path: "/product/5d4b39e7-ae9a-4cc4-8128-53e9d0e7fd93",
    meta: {"ID":"5d4b39e7-ae9a-4cc4-8128-53e9d0e7fd93","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5d559841-ae7a-4051-b84b-0eac92ad3b3c",
    path: "/product/5d559841-ae7a-4051-b84b-0eac92ad3b3c",
    meta: {"ID":"5d559841-ae7a-4051-b84b-0eac92ad3b3c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5d954ec3-092f-44ed-8b54-6a81d8d17151",
    path: "/product/5d954ec3-092f-44ed-8b54-6a81d8d17151",
    meta: {"ID":"5d954ec3-092f-44ed-8b54-6a81d8d17151","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5dc25181-9f29-432a-a940-05814eecd697",
    path: "/product/5dc25181-9f29-432a-a940-05814eecd697",
    meta: {"ID":"5dc25181-9f29-432a-a940-05814eecd697","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|5dc98b72-5e14-4604-b150-f8bab6519488",
    path: "/product/5dc98b72-5e14-4604-b150-f8bab6519488",
    meta: {"ID":"5dc98b72-5e14-4604-b150-f8bab6519488","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|5dce1f23-a9fa-4271-99d9-e013ed13e065",
    path: "/product/5dce1f23-a9fa-4271-99d9-e013ed13e065",
    meta: {"ID":"5dce1f23-a9fa-4271-99d9-e013ed13e065","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5df223d6-5985-401b-941d-f8f6113eb08a",
    path: "/product/5df223d6-5985-401b-941d-f8f6113eb08a",
    meta: {"ID":"5df223d6-5985-401b-941d-f8f6113eb08a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5e0075a6-e2ac-44ca-929f-d0f7d6e33889",
    path: "/product/5e0075a6-e2ac-44ca-929f-d0f7d6e33889",
    meta: {"ID":"5e0075a6-e2ac-44ca-929f-d0f7d6e33889","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|33e8f98d-49a3-4d75-8076-e016770f1878|5e2746b5-dc94-4722-ac1b-c56d956a0960",
    path: "/product/5e2746b5-dc94-4722-ac1b-c56d956a0960",
    meta: {"ID":"5e2746b5-dc94-4722-ac1b-c56d956a0960","Title":"","GroupID":"33e8f98d-49a3-4d75-8076-e016770f1878","GroupTitle":"Дополнительно","GroupLink":"/menu/33e8f98d-49a3-4d75-8076-e016770f1878","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|5e426733-cab1-4523-be7e-374f7d199209",
    path: "/product/5e426733-cab1-4523-be7e-374f7d199209",
    meta: {"ID":"5e426733-cab1-4523-be7e-374f7d199209","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|5e4556be-79d1-4938-920b-0ca16bcb3a49",
    path: "/product/5e4556be-79d1-4938-920b-0ca16bcb3a49",
    meta: {"ID":"5e4556be-79d1-4938-920b-0ca16bcb3a49","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5e551629-7063-4da1-97e0-f55457d6d335",
    path: "/product/5e551629-7063-4da1-97e0-f55457d6d335",
    meta: {"ID":"5e551629-7063-4da1-97e0-f55457d6d335","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5e7e593d-0947-493e-a674-dd96f1ad4626",
    path: "/product/5e7e593d-0947-493e-a674-dd96f1ad4626",
    meta: {"ID":"5e7e593d-0947-493e-a674-dd96f1ad4626","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5eb04d0e-a3e6-45c4-9c0f-4b9331d3f3b8",
    path: "/product/5eb04d0e-a3e6-45c4-9c0f-4b9331d3f3b8",
    meta: {"ID":"5eb04d0e-a3e6-45c4-9c0f-4b9331d3f3b8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|5ebe6779-b1e7-48c1-914f-b6aa07c426d3",
    path: "/product/5ebe6779-b1e7-48c1-914f-b6aa07c426d3",
    meta: {"ID":"5ebe6779-b1e7-48c1-914f-b6aa07c426d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|5edd765e-8b82-4489-9e0c-2f85d6dcaa70",
    path: "/product/5edd765e-8b82-4489-9e0c-2f85d6dcaa70",
    meta: {"ID":"5edd765e-8b82-4489-9e0c-2f85d6dcaa70","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5ef13713-aea3-4dee-a036-0c4bbf7cca3f",
    path: "/product/5ef13713-aea3-4dee-a036-0c4bbf7cca3f",
    meta: {"ID":"5ef13713-aea3-4dee-a036-0c4bbf7cca3f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5f5414ce-2213-41fa-a4eb-7c93ebeb876e",
    path: "/product/5f5414ce-2213-41fa-a4eb-7c93ebeb876e",
    meta: {"ID":"5f5414ce-2213-41fa-a4eb-7c93ebeb876e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5f5cf75e-4779-475a-b58c-a9551219ad91",
    path: "/product/5f5cf75e-4779-475a-b58c-a9551219ad91",
    meta: {"ID":"5f5cf75e-4779-475a-b58c-a9551219ad91","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|5f75e13f-6326-46f8-92cf-04a4bb368130",
    path: "/product/5f75e13f-6326-46f8-92cf-04a4bb368130",
    meta: {"ID":"5f75e13f-6326-46f8-92cf-04a4bb368130","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|5fde7683-f5c3-49ed-aaf8-9d0876cc586f",
    path: "/product/5fde7683-f5c3-49ed-aaf8-9d0876cc586f",
    meta: {"ID":"5fde7683-f5c3-49ed-aaf8-9d0876cc586f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|5fedab84-27a1-4f4d-8421-362c09a9f2fb",
    path: "/product/5fedab84-27a1-4f4d-8421-362c09a9f2fb",
    meta: {"ID":"5fedab84-27a1-4f4d-8421-362c09a9f2fb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|6008bfa6-a03a-4cd6-86ee-d80f724ee2f5",
    path: "/product/6008bfa6-a03a-4cd6-86ee-d80f724ee2f5",
    meta: {"ID":"6008bfa6-a03a-4cd6-86ee-d80f724ee2f5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|600bb68b-be9e-4182-af89-e219d51399fc",
    path: "/product/600bb68b-be9e-4182-af89-e219d51399fc",
    meta: {"ID":"600bb68b-be9e-4182-af89-e219d51399fc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|60146650-1f10-4010-8498-cf0f8d653418",
    path: "/product/60146650-1f10-4010-8498-cf0f8d653418",
    meta: {"ID":"60146650-1f10-4010-8498-cf0f8d653418","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|60154244-63a2-4053-a090-8b13ed7ba25e",
    path: "/product/60154244-63a2-4053-a090-8b13ed7ba25e",
    meta: {"ID":"60154244-63a2-4053-a090-8b13ed7ba25e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|6018f632-7517-493d-831f-b5a3a8940ea3",
    path: "/product/6018f632-7517-493d-831f-b5a3a8940ea3",
    meta: {"ID":"6018f632-7517-493d-831f-b5a3a8940ea3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|604ae744-57dd-418c-8f73-8b9581027b67",
    path: "/product/604ae744-57dd-418c-8f73-8b9581027b67",
    meta: {"ID":"604ae744-57dd-418c-8f73-8b9581027b67","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|60529d5f-4ee5-4317-b8fa-3ede364e28a3",
    path: "/product/60529d5f-4ee5-4317-b8fa-3ede364e28a3",
    meta: {"ID":"60529d5f-4ee5-4317-b8fa-3ede364e28a3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|6062be0d-10c0-45f7-8269-faa355e5c0bc",
    path: "/product/6062be0d-10c0-45f7-8269-faa355e5c0bc",
    meta: {"ID":"6062be0d-10c0-45f7-8269-faa355e5c0bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|606a0744-3ce5-4598-b111-75d57d2e8bc0",
    path: "/product/606a0744-3ce5-4598-b111-75d57d2e8bc0",
    meta: {"ID":"606a0744-3ce5-4598-b111-75d57d2e8bc0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|60708c14-759a-41f2-9172-22de98c0c919",
    path: "/product/60708c14-759a-41f2-9172-22de98c0c919",
    meta: {"ID":"60708c14-759a-41f2-9172-22de98c0c919","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|607dfd7b-0f80-427c-a65f-1bc139d4cbcb",
    path: "/product/607dfd7b-0f80-427c-a65f-1bc139d4cbcb",
    meta: {"ID":"607dfd7b-0f80-427c-a65f-1bc139d4cbcb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|6096ea07-2972-4bca-984e-80aee03d3788",
    path: "/product/6096ea07-2972-4bca-984e-80aee03d3788",
    meta: {"ID":"6096ea07-2972-4bca-984e-80aee03d3788","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|60a26fb7-ce3c-4aa7-8b7c-61ae5d54b9bc",
    path: "/product/60a26fb7-ce3c-4aa7-8b7c-61ae5d54b9bc",
    meta: {"ID":"60a26fb7-ce3c-4aa7-8b7c-61ae5d54b9bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|60a9539e-077d-43d5-8116-4f1aa1e877cf",
    path: "/product/60a9539e-077d-43d5-8116-4f1aa1e877cf",
    meta: {"ID":"60a9539e-077d-43d5-8116-4f1aa1e877cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|60b30071-31b8-4488-bd62-66831a4597c7",
    path: "/product/60b30071-31b8-4488-bd62-66831a4597c7",
    meta: {"ID":"60b30071-31b8-4488-bd62-66831a4597c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|60e33a80-2fce-41dc-aa50-1134bc266d58",
    path: "/product/60e33a80-2fce-41dc-aa50-1134bc266d58",
    meta: {"ID":"60e33a80-2fce-41dc-aa50-1134bc266d58","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|610825b6-22ff-4be6-a868-aea791f72187",
    path: "/product/610825b6-22ff-4be6-a868-aea791f72187",
    meta: {"ID":"610825b6-22ff-4be6-a868-aea791f72187","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|61167aca-246b-41b0-aa08-92f9a020b9a7",
    path: "/product/61167aca-246b-41b0-aa08-92f9a020b9a7",
    meta: {"ID":"61167aca-246b-41b0-aa08-92f9a020b9a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|6119ad14-7c9f-4f93-ac33-e73ed99ca579",
    path: "/product/6119ad14-7c9f-4f93-ac33-e73ed99ca579",
    meta: {"ID":"6119ad14-7c9f-4f93-ac33-e73ed99ca579","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|613b0c7d-88a6-4804-a20a-c04ac84e2690",
    path: "/product/613b0c7d-88a6-4804-a20a-c04ac84e2690",
    meta: {"ID":"613b0c7d-88a6-4804-a20a-c04ac84e2690","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|614ee55c-ac17-47f6-9ce1-04ef9d4e49f4",
    path: "/product/614ee55c-ac17-47f6-9ce1-04ef9d4e49f4",
    meta: {"ID":"614ee55c-ac17-47f6-9ce1-04ef9d4e49f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|6164e93d-427d-4ce1-8b6d-fe3fcdc02291",
    path: "/product/6164e93d-427d-4ce1-8b6d-fe3fcdc02291",
    meta: {"ID":"6164e93d-427d-4ce1-8b6d-fe3fcdc02291","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6196e482-094e-4c5b-add1-17ecb7f468c9",
    path: "/product/6196e482-094e-4c5b-add1-17ecb7f468c9",
    meta: {"ID":"6196e482-094e-4c5b-add1-17ecb7f468c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|61a0ea51-496a-4be9-ac69-71d382dd4a47",
    path: "/product/61a0ea51-496a-4be9-ac69-71d382dd4a47",
    meta: {"ID":"61a0ea51-496a-4be9-ac69-71d382dd4a47","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|61abfa41-7d59-410c-9181-0b944a1a0057",
    path: "/product/61abfa41-7d59-410c-9181-0b944a1a0057",
    meta: {"ID":"61abfa41-7d59-410c-9181-0b944a1a0057","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|61b69e13-dd4a-409e-b3e1-a5618d0c5dff",
    path: "/product/61b69e13-dd4a-409e-b3e1-a5618d0c5dff",
    meta: {"ID":"61b69e13-dd4a-409e-b3e1-a5618d0c5dff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|d586fdfe-fc1e-4037-89d8-4ebed69e1496|61ca83fc-c475-4f92-906b-f56b78bb82a6",
    path: "/product/61ca83fc-c475-4f92-906b-f56b78bb82a6",
    meta: {"ID":"61ca83fc-c475-4f92-906b-f56b78bb82a6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|61dd226e-d96d-47dc-94c5-da3b7441425c",
    path: "/product/61dd226e-d96d-47dc-94c5-da3b7441425c",
    meta: {"ID":"61dd226e-d96d-47dc-94c5-da3b7441425c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|61e3a68b-dc80-41ce-babd-9449e2cb5ae3",
    path: "/product/61e3a68b-dc80-41ce-babd-9449e2cb5ae3",
    meta: {"ID":"61e3a68b-dc80-41ce-babd-9449e2cb5ae3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|61fb6b81-8310-46da-89ee-42c1b1a0663f",
    path: "/product/61fb6b81-8310-46da-89ee-42c1b1a0663f",
    meta: {"ID":"61fb6b81-8310-46da-89ee-42c1b1a0663f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|621810db-df08-4416-bd3b-33572c1e6b3b",
    path: "/product/621810db-df08-4416-bd3b-33572c1e6b3b",
    meta: {"ID":"621810db-df08-4416-bd3b-33572c1e6b3b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|621a2877-a118-4413-b68a-a3d47b764b11",
    path: "/product/621a2877-a118-4413-b68a-a3d47b764b11",
    meta: {"ID":"621a2877-a118-4413-b68a-a3d47b764b11","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|806b1c7a-ff06-45e9-ac72-7abe93c7a978|6253e27a-67ca-435b-8e05-b6873ed11402",
    path: "/product/6253e27a-67ca-435b-8e05-b6873ed11402",
    meta: {"ID":"6253e27a-67ca-435b-8e05-b6873ed11402","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|625461ca-3da8-4f5a-aa54-65503d4e7428",
    path: "/product/625461ca-3da8-4f5a-aa54-65503d4e7428",
    meta: {"ID":"625461ca-3da8-4f5a-aa54-65503d4e7428","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|627bd8de-6b6a-4af4-9c93-ecc3b5e85822",
    path: "/product/627bd8de-6b6a-4af4-9c93-ecc3b5e85822",
    meta: {"ID":"627bd8de-6b6a-4af4-9c93-ecc3b5e85822","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|628f15a9-0b0b-47a7-9620-9e00efd52a6d",
    path: "/product/628f15a9-0b0b-47a7-9620-9e00efd52a6d",
    meta: {"ID":"628f15a9-0b0b-47a7-9620-9e00efd52a6d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|629ab197-4f02-4b36-b274-220865059c91",
    path: "/product/629ab197-4f02-4b36-b274-220865059c91",
    meta: {"ID":"629ab197-4f02-4b36-b274-220865059c91","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|629ae129-188f-484c-937e-d16b1c336b57",
    path: "/product/629ae129-188f-484c-937e-d16b1c336b57",
    meta: {"ID":"629ae129-188f-484c-937e-d16b1c336b57","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|62a035af-4b29-4fe0-b9f8-02b5ca5b5d99",
    path: "/product/62a035af-4b29-4fe0-b9f8-02b5ca5b5d99",
    meta: {"ID":"62a035af-4b29-4fe0-b9f8-02b5ca5b5d99","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|62c0fcdb-21c9-4614-8634-5e4b417a7d4e",
    path: "/product/62c0fcdb-21c9-4614-8634-5e4b417a7d4e",
    meta: {"ID":"62c0fcdb-21c9-4614-8634-5e4b417a7d4e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|62d57125-7b23-47d5-a177-08b2d7669489",
    path: "/product/62d57125-7b23-47d5-a177-08b2d7669489",
    meta: {"ID":"62d57125-7b23-47d5-a177-08b2d7669489","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|62e0dc43-cb90-4794-a006-7b47c556c86c",
    path: "/product/62e0dc43-cb90-4794-a006-7b47c556c86c",
    meta: {"ID":"62e0dc43-cb90-4794-a006-7b47c556c86c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|62e5bd06-23fe-467f-b154-78f7ffac6835",
    path: "/product/62e5bd06-23fe-467f-b154-78f7ffac6835",
    meta: {"ID":"62e5bd06-23fe-467f-b154-78f7ffac6835","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|62e8fc9a-97b4-43f5-be00-48650e36d2c8",
    path: "/product/62e8fc9a-97b4-43f5-be00-48650e36d2c8",
    meta: {"ID":"62e8fc9a-97b4-43f5-be00-48650e36d2c8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|62eb692d-0e4a-45f9-96f9-4b83b3913e8d",
    path: "/product/62eb692d-0e4a-45f9-96f9-4b83b3913e8d",
    meta: {"ID":"62eb692d-0e4a-45f9-96f9-4b83b3913e8d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|82890741-f41f-4593-bd5b-e63c6af5f1ed|63159898-60ec-45f5-bf39-b64efeec90bd",
    path: "/product/63159898-60ec-45f5-bf39-b64efeec90bd",
    meta: {"ID":"63159898-60ec-45f5-bf39-b64efeec90bd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|6315dcf8-c8f0-4cde-b602-d6179ad6c87e",
    path: "/product/6315dcf8-c8f0-4cde-b602-d6179ad6c87e",
    meta: {"ID":"6315dcf8-c8f0-4cde-b602-d6179ad6c87e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|6328fd57-ca51-4ea9-b9bd-4c738ca713ae",
    path: "/product/6328fd57-ca51-4ea9-b9bd-4c738ca713ae",
    meta: {"ID":"6328fd57-ca51-4ea9-b9bd-4c738ca713ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|634b26e0-3398-49e3-818b-7f65327c37fa",
    path: "/product/634b26e0-3398-49e3-818b-7f65327c37fa",
    meta: {"ID":"634b26e0-3398-49e3-818b-7f65327c37fa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|635690ce-913a-4b78-98b2-49d18678b487",
    path: "/product/635690ce-913a-4b78-98b2-49d18678b487",
    meta: {"ID":"635690ce-913a-4b78-98b2-49d18678b487","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1519fe15-47e1-44ff-8747-6714ca96f7aa|638d3f6e-a3ba-43d9-acbe-4d99962f12b7",
    path: "/product/638d3f6e-a3ba-43d9-acbe-4d99962f12b7",
    meta: {"ID":"638d3f6e-a3ba-43d9-acbe-4d99962f12b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|63a7bc58-8bf4-4f02-9916-87d72187a2ee",
    path: "/product/63a7bc58-8bf4-4f02-9916-87d72187a2ee",
    meta: {"ID":"63a7bc58-8bf4-4f02-9916-87d72187a2ee","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|63afe3bc-da40-47f0-be7a-3f7eabb59442",
    path: "/product/63afe3bc-da40-47f0-be7a-3f7eabb59442",
    meta: {"ID":"63afe3bc-da40-47f0-be7a-3f7eabb59442","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|63c8d045-aed9-4a0b-818a-085978cd3f29",
    path: "/product/63c8d045-aed9-4a0b-818a-085978cd3f29",
    meta: {"ID":"63c8d045-aed9-4a0b-818a-085978cd3f29","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|63dcf8b3-1085-49eb-b8ce-12f4fa6db875",
    path: "/product/63dcf8b3-1085-49eb-b8ce-12f4fa6db875",
    meta: {"ID":"63dcf8b3-1085-49eb-b8ce-12f4fa6db875","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73cec509-3358-485d-8d8e-5484c8bb8f78|640c9a61-95cc-451e-881f-4bdf496dce84",
    path: "/product/640c9a61-95cc-451e-881f-4bdf496dce84",
    meta: {"ID":"640c9a61-95cc-451e-881f-4bdf496dce84","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|6427b5de-c99f-4300-a352-9b36d6216c72",
    path: "/product/6427b5de-c99f-4300-a352-9b36d6216c72",
    meta: {"ID":"6427b5de-c99f-4300-a352-9b36d6216c72","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|643c9702-a0a2-4754-b2e3-4193298894a7",
    path: "/product/643c9702-a0a2-4754-b2e3-4193298894a7",
    meta: {"ID":"643c9702-a0a2-4754-b2e3-4193298894a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3|6442cccc-d97b-4425-9b22-a93845720457",
    path: "/product/6442cccc-d97b-4425-9b22-a93845720457",
    meta: {"ID":"6442cccc-d97b-4425-9b22-a93845720457","Title":"","GroupID":"5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","GroupTitle":"Салаты","GroupLink":"/menu/5ba93e3d-45dd-4f5e-8a60-7b6af0eccba3","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|644d2a24-d0d0-4a23-9a60-1a540680300a",
    path: "/product/644d2a24-d0d0-4a23-9a60-1a540680300a",
    meta: {"ID":"644d2a24-d0d0-4a23-9a60-1a540680300a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|64612499-6679-46d7-b8fc-14599b420ade",
    path: "/product/64612499-6679-46d7-b8fc-14599b420ade",
    meta: {"ID":"64612499-6679-46d7-b8fc-14599b420ade","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|648caaae-24fc-4557-a994-136fce251577",
    path: "/product/648caaae-24fc-4557-a994-136fce251577",
    meta: {"ID":"648caaae-24fc-4557-a994-136fce251577","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|64ad03c3-4238-466d-87c8-c54936babcfb",
    path: "/product/64ad03c3-4238-466d-87c8-c54936babcfb",
    meta: {"ID":"64ad03c3-4238-466d-87c8-c54936babcfb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|64b45f0a-09cd-4e63-aac5-e317896678cf",
    path: "/product/64b45f0a-09cd-4e63-aac5-e317896678cf",
    meta: {"ID":"64b45f0a-09cd-4e63-aac5-e317896678cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|64c86a94-5d8a-4690-99d9-3665c61177d7",
    path: "/product/64c86a94-5d8a-4690-99d9-3665c61177d7",
    meta: {"ID":"64c86a94-5d8a-4690-99d9-3665c61177d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|64ca2858-b507-456b-9c8c-c37e323544cd",
    path: "/product/64ca2858-b507-456b-9c8c-c37e323544cd",
    meta: {"ID":"64ca2858-b507-456b-9c8c-c37e323544cd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|65406003-b1c8-47b9-b1ff-a1f56e3f18cd",
    path: "/product/65406003-b1c8-47b9-b1ff-a1f56e3f18cd",
    meta: {"ID":"65406003-b1c8-47b9-b1ff-a1f56e3f18cd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|65453d36-a5fe-42a8-9ec7-86af3b58a816",
    path: "/product/65453d36-a5fe-42a8-9ec7-86af3b58a816",
    meta: {"ID":"65453d36-a5fe-42a8-9ec7-86af3b58a816","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|65586b98-2921-4e0b-8792-f4f4e64bb63d",
    path: "/product/65586b98-2921-4e0b-8792-f4f4e64bb63d",
    meta: {"ID":"65586b98-2921-4e0b-8792-f4f4e64bb63d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6cbf9c41-8e9c-4704-af3f-382f792c10c4|6576fc5c-a462-4330-96a7-a9f23cfb45ef",
    path: "/product/6576fc5c-a462-4330-96a7-a9f23cfb45ef",
    meta: {"ID":"6576fc5c-a462-4330-96a7-a9f23cfb45ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6597d274-f232-4057-bcd1-1bca7383cb16",
    path: "/product/6597d274-f232-4057-bcd1-1bca7383cb16",
    meta: {"ID":"6597d274-f232-4057-bcd1-1bca7383cb16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|65ab8eea-b0f8-46b1-a28a-d9c2bbbbe604",
    path: "/product/65ab8eea-b0f8-46b1-a28a-d9c2bbbbe604",
    meta: {"ID":"65ab8eea-b0f8-46b1-a28a-d9c2bbbbe604","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|65b1600c-8443-4db3-82c8-1ed55718fe87",
    path: "/product/65b1600c-8443-4db3-82c8-1ed55718fe87",
    meta: {"ID":"65b1600c-8443-4db3-82c8-1ed55718fe87","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|65b8dae6-5d29-4c65-aa71-53e825dd62c3",
    path: "/product/65b8dae6-5d29-4c65-aa71-53e825dd62c3",
    meta: {"ID":"65b8dae6-5d29-4c65-aa71-53e825dd62c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|65b9d1ea-6750-4eae-82a6-be7f53b186fa",
    path: "/product/65b9d1ea-6750-4eae-82a6-be7f53b186fa",
    meta: {"ID":"65b9d1ea-6750-4eae-82a6-be7f53b186fa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|65d21793-1408-445d-9fc4-f7859f617dfb",
    path: "/product/65d21793-1408-445d-9fc4-f7859f617dfb",
    meta: {"ID":"65d21793-1408-445d-9fc4-f7859f617dfb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|65d82e80-46ce-4c5a-9e73-188f16bf7380",
    path: "/product/65d82e80-46ce-4c5a-9e73-188f16bf7380",
    meta: {"ID":"65d82e80-46ce-4c5a-9e73-188f16bf7380","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|65e75df0-59b8-47d2-8e98-f55edb889253",
    path: "/product/65e75df0-59b8-47d2-8e98-f55edb889253",
    meta: {"ID":"65e75df0-59b8-47d2-8e98-f55edb889253","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|65f5ba04-eae6-47fe-a162-8d7fc2aadf4b",
    path: "/product/65f5ba04-eae6-47fe-a162-8d7fc2aadf4b",
    meta: {"ID":"65f5ba04-eae6-47fe-a162-8d7fc2aadf4b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|65f9e1e2-608d-4ac6-a347-ee5f0a7308b0",
    path: "/product/65f9e1e2-608d-4ac6-a347-ee5f0a7308b0",
    meta: {"ID":"65f9e1e2-608d-4ac6-a347-ee5f0a7308b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|66000be9-62f9-4e1a-8589-3cbc38f3235c",
    path: "/product/66000be9-62f9-4e1a-8589-3cbc38f3235c",
    meta: {"ID":"66000be9-62f9-4e1a-8589-3cbc38f3235c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|661c2978-a7e9-4ff3-8c65-2660bbea58d9",
    path: "/product/661c2978-a7e9-4ff3-8c65-2660bbea58d9",
    meta: {"ID":"661c2978-a7e9-4ff3-8c65-2660bbea58d9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|662fd67b-3f26-466a-9ea1-4e8f72b59f25",
    path: "/product/662fd67b-3f26-466a-9ea1-4e8f72b59f25",
    meta: {"ID":"662fd67b-3f26-466a-9ea1-4e8f72b59f25","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|664a4b35-f49f-404e-a3bb-060324497379",
    path: "/product/664a4b35-f49f-404e-a3bb-060324497379",
    meta: {"ID":"664a4b35-f49f-404e-a3bb-060324497379","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf8604ed-919e-4f96-8fa9-e0ed5a2f57dc|66523d76-3c39-431c-af6b-d3dfc8093dd6",
    path: "/product/66523d76-3c39-431c-af6b-d3dfc8093dd6",
    meta: {"ID":"66523d76-3c39-431c-af6b-d3dfc8093dd6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|66677548-de6a-4f04-bedf-7772ffd378c1",
    path: "/product/66677548-de6a-4f04-bedf-7772ffd378c1",
    meta: {"ID":"66677548-de6a-4f04-bedf-7772ffd378c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|666d95c5-388e-484e-baf7-99a4c39339f1",
    path: "/product/666d95c5-388e-484e-baf7-99a4c39339f1",
    meta: {"ID":"666d95c5-388e-484e-baf7-99a4c39339f1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|66886e72-a2e2-4e2d-9a65-7e41784884d4",
    path: "/product/66886e72-a2e2-4e2d-9a65-7e41784884d4",
    meta: {"ID":"66886e72-a2e2-4e2d-9a65-7e41784884d4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|66930195-ef16-4250-ade3-a4905e6a7bc8",
    path: "/product/66930195-ef16-4250-ade3-a4905e6a7bc8",
    meta: {"ID":"66930195-ef16-4250-ade3-a4905e6a7bc8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|66b69799-a6c9-463a-9ce9-a033a262c3d3",
    path: "/product/66b69799-a6c9-463a-9ce9-a033a262c3d3",
    meta: {"ID":"66b69799-a6c9-463a-9ce9-a033a262c3d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|66b7dc68-e0ec-4c53-bb38-80a288671658",
    path: "/product/66b7dc68-e0ec-4c53-bb38-80a288671658",
    meta: {"ID":"66b7dc68-e0ec-4c53-bb38-80a288671658","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|66f0d581-7f96-4125-bf61-d61b94b90647",
    path: "/product/66f0d581-7f96-4125-bf61-d61b94b90647",
    meta: {"ID":"66f0d581-7f96-4125-bf61-d61b94b90647","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|66ff602d-a38a-4325-9570-2b0a75c856e6",
    path: "/product/66ff602d-a38a-4325-9570-2b0a75c856e6",
    meta: {"ID":"66ff602d-a38a-4325-9570-2b0a75c856e6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|670747a6-b949-4d3f-9e58-fc2ba66dce41",
    path: "/product/670747a6-b949-4d3f-9e58-fc2ba66dce41",
    meta: {"ID":"670747a6-b949-4d3f-9e58-fc2ba66dce41","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6718b341-4481-4c03-97dc-744ce5d41504",
    path: "/product/6718b341-4481-4c03-97dc-744ce5d41504",
    meta: {"ID":"6718b341-4481-4c03-97dc-744ce5d41504","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|671c7edd-471f-4454-ad89-888394306e07",
    path: "/product/671c7edd-471f-4454-ad89-888394306e07",
    meta: {"ID":"671c7edd-471f-4454-ad89-888394306e07","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|67265d2b-834c-4db9-bf2f-efbc43f14a51",
    path: "/product/67265d2b-834c-4db9-bf2f-efbc43f14a51",
    meta: {"ID":"67265d2b-834c-4db9-bf2f-efbc43f14a51","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6736d5a7-bf51-4557-8693-8ff54899fb9f",
    path: "/product/6736d5a7-bf51-4557-8693-8ff54899fb9f",
    meta: {"ID":"6736d5a7-bf51-4557-8693-8ff54899fb9f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4322a75-f23f-4323-9f26-5d0e5462e09e|6778da91-f276-4980-8ce0-bc23280a5466",
    path: "/product/6778da91-f276-4980-8ce0-bc23280a5466",
    meta: {"ID":"6778da91-f276-4980-8ce0-bc23280a5466","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|679688ac-061b-480a-b84e-7a1fc5e6b3fd",
    path: "/product/679688ac-061b-480a-b84e-7a1fc5e6b3fd",
    meta: {"ID":"679688ac-061b-480a-b84e-7a1fc5e6b3fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|679e196a-a0ce-46d2-9201-aaa54a0c3271",
    path: "/product/679e196a-a0ce-46d2-9201-aaa54a0c3271",
    meta: {"ID":"679e196a-a0ce-46d2-9201-aaa54a0c3271","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|67b1b2ef-21a8-42ff-8ab7-f6f1b779bfd7",
    path: "/product/67b1b2ef-21a8-42ff-8ab7-f6f1b779bfd7",
    meta: {"ID":"67b1b2ef-21a8-42ff-8ab7-f6f1b779bfd7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|68022751-20c8-4bbb-87b0-eec8d493f151",
    path: "/product/68022751-20c8-4bbb-87b0-eec8d493f151",
    meta: {"ID":"68022751-20c8-4bbb-87b0-eec8d493f151","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|680a37a4-97c3-43f2-b464-7cda0343ace7",
    path: "/product/680a37a4-97c3-43f2-b464-7cda0343ace7",
    meta: {"ID":"680a37a4-97c3-43f2-b464-7cda0343ace7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|68196133-9a6f-4c2e-a473-25998933343f",
    path: "/product/68196133-9a6f-4c2e-a473-25998933343f",
    meta: {"ID":"68196133-9a6f-4c2e-a473-25998933343f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|806b1c7a-ff06-45e9-ac72-7abe93c7a978|6830d252-df57-42f6-8396-297f6fc9df60",
    path: "/product/6830d252-df57-42f6-8396-297f6fc9df60",
    meta: {"ID":"6830d252-df57-42f6-8396-297f6fc9df60","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|683a40eb-08d4-44b5-b3ff-747198ac001a",
    path: "/product/683a40eb-08d4-44b5-b3ff-747198ac001a",
    meta: {"ID":"683a40eb-08d4-44b5-b3ff-747198ac001a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|68892918-e521-4fb3-9155-c63f1fde6d48",
    path: "/product/68892918-e521-4fb3-9155-c63f1fde6d48",
    meta: {"ID":"68892918-e521-4fb3-9155-c63f1fde6d48","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|689d2c7e-51dc-4076-ab1a-7fc1080f32b6",
    path: "/product/689d2c7e-51dc-4076-ab1a-7fc1080f32b6",
    meta: {"ID":"689d2c7e-51dc-4076-ab1a-7fc1080f32b6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|68c62d05-a3a0-44ed-913e-17dc64bfa6e9",
    path: "/product/68c62d05-a3a0-44ed-913e-17dc64bfa6e9",
    meta: {"ID":"68c62d05-a3a0-44ed-913e-17dc64bfa6e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|68c692b2-27c5-4de7-83f7-27b0421c6237",
    path: "/product/68c692b2-27c5-4de7-83f7-27b0421c6237",
    meta: {"ID":"68c692b2-27c5-4de7-83f7-27b0421c6237","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|68dd05c8-1fd4-4c5d-89bc-e7f4592bf9dd",
    path: "/product/68dd05c8-1fd4-4c5d-89bc-e7f4592bf9dd",
    meta: {"ID":"68dd05c8-1fd4-4c5d-89bc-e7f4592bf9dd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|68e2b503-4eb9-48a6-8654-0c97e0514df9",
    path: "/product/68e2b503-4eb9-48a6-8654-0c97e0514df9",
    meta: {"ID":"68e2b503-4eb9-48a6-8654-0c97e0514df9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|df6e1e38-ff3c-4a9e-94f2-baf987c30a9b|68edf7cf-3637-4f8e-a8e8-58fb51eb4735",
    path: "/product/68edf7cf-3637-4f8e-a8e8-58fb51eb4735",
    meta: {"ID":"68edf7cf-3637-4f8e-a8e8-58fb51eb4735","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|68f474a3-346f-4d8a-949a-d089004323b4",
    path: "/product/68f474a3-346f-4d8a-949a-d089004323b4",
    meta: {"ID":"68f474a3-346f-4d8a-949a-d089004323b4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|69145ab7-7c07-4eb0-bb50-605544818e58",
    path: "/product/69145ab7-7c07-4eb0-bb50-605544818e58",
    meta: {"ID":"69145ab7-7c07-4eb0-bb50-605544818e58","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|69186092-8f45-4ee4-ba25-e34872babaef",
    path: "/product/69186092-8f45-4ee4-ba25-e34872babaef",
    meta: {"ID":"69186092-8f45-4ee4-ba25-e34872babaef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ef0a0b3c-71b8-40c4-9f16-3c9038b9738d|69375133-d8e4-4128-99b1-7574937d3554",
    path: "/product/69375133-d8e4-4128-99b1-7574937d3554",
    meta: {"ID":"69375133-d8e4-4128-99b1-7574937d3554","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|693c34a4-d8b1-4c08-9e5d-e18623e334e5",
    path: "/product/693c34a4-d8b1-4c08-9e5d-e18623e334e5",
    meta: {"ID":"693c34a4-d8b1-4c08-9e5d-e18623e334e5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|6949e4ba-3ba5-4eb2-b151-f5d3a864f155",
    path: "/product/6949e4ba-3ba5-4eb2-b151-f5d3a864f155",
    meta: {"ID":"6949e4ba-3ba5-4eb2-b151-f5d3a864f155","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|6986b241-45bf-42c8-afe9-8db517c4ef91",
    path: "/product/6986b241-45bf-42c8-afe9-8db517c4ef91",
    meta: {"ID":"6986b241-45bf-42c8-afe9-8db517c4ef91","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|698b6d49-d368-4d86-b550-54540e0afbb0",
    path: "/product/698b6d49-d368-4d86-b550-54540e0afbb0",
    meta: {"ID":"698b6d49-d368-4d86-b550-54540e0afbb0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|69a31dcb-a88a-4b05-8783-9db1db09ac10",
    path: "/product/69a31dcb-a88a-4b05-8783-9db1db09ac10",
    meta: {"ID":"69a31dcb-a88a-4b05-8783-9db1db09ac10","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|69a58555-6c8a-4f5d-91ab-96082cd8abb0",
    path: "/product/69a58555-6c8a-4f5d-91ab-96082cd8abb0",
    meta: {"ID":"69a58555-6c8a-4f5d-91ab-96082cd8abb0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|69d84e04-515d-402c-b96a-f91f2a4a87c7",
    path: "/product/69d84e04-515d-402c-b96a-f91f2a4a87c7",
    meta: {"ID":"69d84e04-515d-402c-b96a-f91f2a4a87c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|69f6d08b-d623-4fa2-96f2-22449061e37d",
    path: "/product/69f6d08b-d623-4fa2-96f2-22449061e37d",
    meta: {"ID":"69f6d08b-d623-4fa2-96f2-22449061e37d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4089a20c-7c4e-410d-9a00-8bbac0d71cc8|6a224e35-3ab1-4a35-b370-7eefe0c140c5",
    path: "/product/6a224e35-3ab1-4a35-b370-7eefe0c140c5",
    meta: {"ID":"6a224e35-3ab1-4a35-b370-7eefe0c140c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6a69b13d-fc8f-47f6-a8ad-7c4c2f4ea010",
    path: "/product/6a69b13d-fc8f-47f6-a8ad-7c4c2f4ea010",
    meta: {"ID":"6a69b13d-fc8f-47f6-a8ad-7c4c2f4ea010","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|0f33a02b-92fa-4922-9e4c-0b4cc4e13e79|6a77c01a-732a-4d21-ab16-053176175ee9",
    path: "/product/6a77c01a-732a-4d21-ab16-053176175ee9",
    meta: {"ID":"6a77c01a-732a-4d21-ab16-053176175ee9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|6a82378a-09d8-4096-9465-8070b3a30e5f",
    path: "/product/6a82378a-09d8-4096-9465-8070b3a30e5f",
    meta: {"ID":"6a82378a-09d8-4096-9465-8070b3a30e5f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|6aaf60df-8a93-42f5-99b1-f52a974295ca",
    path: "/product/6aaf60df-8a93-42f5-99b1-f52a974295ca",
    meta: {"ID":"6aaf60df-8a93-42f5-99b1-f52a974295ca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|6ac0feca-4145-4bd4-8f01-635d23632a02",
    path: "/product/6ac0feca-4145-4bd4-8f01-635d23632a02",
    meta: {"ID":"6ac0feca-4145-4bd4-8f01-635d23632a02","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|6ac5b8d8-8356-44b9-8d43-53426bb42380",
    path: "/product/6ac5b8d8-8356-44b9-8d43-53426bb42380",
    meta: {"ID":"6ac5b8d8-8356-44b9-8d43-53426bb42380","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|6ac619b3-7086-406e-8d31-142350616b3e",
    path: "/product/6ac619b3-7086-406e-8d31-142350616b3e",
    meta: {"ID":"6ac619b3-7086-406e-8d31-142350616b3e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|6acd4952-1b02-4c59-af1a-a459c3d5e6fc",
    path: "/product/6acd4952-1b02-4c59-af1a-a459c3d5e6fc",
    meta: {"ID":"6acd4952-1b02-4c59-af1a-a459c3d5e6fc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|6adebdb4-4a01-494f-bc53-9b600d6e61f1",
    path: "/product/6adebdb4-4a01-494f-bc53-9b600d6e61f1",
    meta: {"ID":"6adebdb4-4a01-494f-bc53-9b600d6e61f1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6af61ed2-dd66-4a99-a485-9027f2e5db87",
    path: "/product/6af61ed2-dd66-4a99-a485-9027f2e5db87",
    meta: {"ID":"6af61ed2-dd66-4a99-a485-9027f2e5db87","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|6b1a5531-cad8-402c-9158-eb6a4d4dde67",
    path: "/product/6b1a5531-cad8-402c-9158-eb6a4d4dde67",
    meta: {"ID":"6b1a5531-cad8-402c-9158-eb6a4d4dde67","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|6b3aea0a-b4dc-4518-814f-2bca91b9f36e",
    path: "/product/6b3aea0a-b4dc-4518-814f-2bca91b9f36e",
    meta: {"ID":"6b3aea0a-b4dc-4518-814f-2bca91b9f36e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|6b3e9001-bef2-4276-9a4b-c6ed968a05c7",
    path: "/product/6b3e9001-bef2-4276-9a4b-c6ed968a05c7",
    meta: {"ID":"6b3e9001-bef2-4276-9a4b-c6ed968a05c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6b553bdc-4c89-4fb2-b03f-73b61649f79b",
    path: "/product/6b553bdc-4c89-4fb2-b03f-73b61649f79b",
    meta: {"ID":"6b553bdc-4c89-4fb2-b03f-73b61649f79b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|6b5b2301-51f9-42fa-b619-55f7d55e08e8",
    path: "/product/6b5b2301-51f9-42fa-b619-55f7d55e08e8",
    meta: {"ID":"6b5b2301-51f9-42fa-b619-55f7d55e08e8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6b5f680a-0ea4-46f0-b8a4-e98b623f10e4",
    path: "/product/6b5f680a-0ea4-46f0-b8a4-e98b623f10e4",
    meta: {"ID":"6b5f680a-0ea4-46f0-b8a4-e98b623f10e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|6b6d6396-151c-498b-a65b-21dbf477b8ca",
    path: "/product/6b6d6396-151c-498b-a65b-21dbf477b8ca",
    meta: {"ID":"6b6d6396-151c-498b-a65b-21dbf477b8ca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6b6e95b6-1580-4374-87e2-616bd6e5fd58",
    path: "/product/6b6e95b6-1580-4374-87e2-616bd6e5fd58",
    meta: {"ID":"6b6e95b6-1580-4374-87e2-616bd6e5fd58","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6b87a69d-d090-46e4-b7d8-6ddb1dd3385d",
    path: "/product/6b87a69d-d090-46e4-b7d8-6ddb1dd3385d",
    meta: {"ID":"6b87a69d-d090-46e4-b7d8-6ddb1dd3385d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|6b88bcc4-1a14-4847-98ca-5d65e4907945",
    path: "/product/6b88bcc4-1a14-4847-98ca-5d65e4907945",
    meta: {"ID":"6b88bcc4-1a14-4847-98ca-5d65e4907945","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6b8cf425-72a1-4586-b37d-d3fb6ea508af",
    path: "/product/6b8cf425-72a1-4586-b37d-d3fb6ea508af",
    meta: {"ID":"6b8cf425-72a1-4586-b37d-d3fb6ea508af","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6b984434-50cd-4c61-b2ac-bba93f8b1dcc",
    path: "/product/6b984434-50cd-4c61-b2ac-bba93f8b1dcc",
    meta: {"ID":"6b984434-50cd-4c61-b2ac-bba93f8b1dcc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|6bb186da-c795-41db-9659-4362537eb06c",
    path: "/product/6bb186da-c795-41db-9659-4362537eb06c",
    meta: {"ID":"6bb186da-c795-41db-9659-4362537eb06c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6bc86008-bdbe-4aa2-a5c2-0351eeb87e85",
    path: "/product/6bc86008-bdbe-4aa2-a5c2-0351eeb87e85",
    meta: {"ID":"6bc86008-bdbe-4aa2-a5c2-0351eeb87e85","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6bd35d24-c535-469e-af1f-a1695d18b28b",
    path: "/product/6bd35d24-c535-469e-af1f-a1695d18b28b",
    meta: {"ID":"6bd35d24-c535-469e-af1f-a1695d18b28b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6bd6967a-de41-4116-ba25-f0a1bc55a72b",
    path: "/product/6bd6967a-de41-4116-ba25-f0a1bc55a72b",
    meta: {"ID":"6bd6967a-de41-4116-ba25-f0a1bc55a72b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6bdd8735-2b58-4648-8bc2-352c0f6598d1",
    path: "/product/6bdd8735-2b58-4648-8bc2-352c0f6598d1",
    meta: {"ID":"6bdd8735-2b58-4648-8bc2-352c0f6598d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|57b1574f-a331-4e33-aedc-684887d5530e|6bdda836-ad7e-4690-8498-17ab7f77e574",
    path: "/product/6bdda836-ad7e-4690-8498-17ab7f77e574",
    meta: {"ID":"6bdda836-ad7e-4690-8498-17ab7f77e574","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|6beb8c42-e39a-452f-ad6f-5d8ed6e1b8ab",
    path: "/product/6beb8c42-e39a-452f-ad6f-5d8ed6e1b8ab",
    meta: {"ID":"6beb8c42-e39a-452f-ad6f-5d8ed6e1b8ab","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|6beb9608-e996-4161-bad6-c05198a677e0",
    path: "/product/6beb9608-e996-4161-bad6-c05198a677e0",
    meta: {"ID":"6beb9608-e996-4161-bad6-c05198a677e0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|6c1bb280-bd1a-4ec1-a20c-0b31845b335e",
    path: "/product/6c1bb280-bd1a-4ec1-a20c-0b31845b335e",
    meta: {"ID":"6c1bb280-bd1a-4ec1-a20c-0b31845b335e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6c204c12-897d-41c9-bd4e-30a68de873df",
    path: "/product/6c204c12-897d-41c9-bd4e-30a68de873df",
    meta: {"ID":"6c204c12-897d-41c9-bd4e-30a68de873df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6c20580d-3f2d-45b8-8b50-23556c637264",
    path: "/product/6c20580d-3f2d-45b8-8b50-23556c637264",
    meta: {"ID":"6c20580d-3f2d-45b8-8b50-23556c637264","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6c33fb55-0a24-4a05-adef-3e447bc1586c",
    path: "/product/6c33fb55-0a24-4a05-adef-3e447bc1586c",
    meta: {"ID":"6c33fb55-0a24-4a05-adef-3e447bc1586c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|6c40c320-0e43-4e6f-8b83-3fe5b60c5a24",
    path: "/product/6c40c320-0e43-4e6f-8b83-3fe5b60c5a24",
    meta: {"ID":"6c40c320-0e43-4e6f-8b83-3fe5b60c5a24","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|6c77b9b6-d313-4f7f-a3ba-43ceda864f13",
    path: "/product/6c77b9b6-d313-4f7f-a3ba-43ceda864f13",
    meta: {"ID":"6c77b9b6-d313-4f7f-a3ba-43ceda864f13","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|6c8f9c1f-7dbe-440a-b066-698c295e6d7a",
    path: "/product/6c8f9c1f-7dbe-440a-b066-698c295e6d7a",
    meta: {"ID":"6c8f9c1f-7dbe-440a-b066-698c295e6d7a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6cc5cee5-8e45-4aa2-ba71-36c4e9b8d267",
    path: "/product/6cc5cee5-8e45-4aa2-ba71-36c4e9b8d267",
    meta: {"ID":"6cc5cee5-8e45-4aa2-ba71-36c4e9b8d267","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|6cf0b2ab-cf54-409f-bd26-d803ac2722c7",
    path: "/product/6cf0b2ab-cf54-409f-bd26-d803ac2722c7",
    meta: {"ID":"6cf0b2ab-cf54-409f-bd26-d803ac2722c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|6d277667-3474-4b2b-9e62-4bed7b39855a",
    path: "/product/6d277667-3474-4b2b-9e62-4bed7b39855a",
    meta: {"ID":"6d277667-3474-4b2b-9e62-4bed7b39855a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6d2866db-c991-41ca-9d27-e7af1604ef33",
    path: "/product/6d2866db-c991-41ca-9d27-e7af1604ef33",
    meta: {"ID":"6d2866db-c991-41ca-9d27-e7af1604ef33","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73e4936d-d27c-44d6-85f2-bbd41d2ec26a|6d3775e7-b6f7-4180-89e2-67707da821a4",
    path: "/product/6d3775e7-b6f7-4180-89e2-67707da821a4",
    meta: {"ID":"6d3775e7-b6f7-4180-89e2-67707da821a4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73cec509-3358-485d-8d8e-5484c8bb8f78|6d3ae7d3-7246-4ed1-a949-4cb382a22557",
    path: "/product/6d3ae7d3-7246-4ed1-a949-4cb382a22557",
    meta: {"ID":"6d3ae7d3-7246-4ed1-a949-4cb382a22557","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6d43ea17-6424-4695-872c-2c53f28172bd",
    path: "/product/6d43ea17-6424-4695-872c-2c53f28172bd",
    meta: {"ID":"6d43ea17-6424-4695-872c-2c53f28172bd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6d448abf-1ac1-407f-8492-274319af6710",
    path: "/product/6d448abf-1ac1-407f-8492-274319af6710",
    meta: {"ID":"6d448abf-1ac1-407f-8492-274319af6710","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6d773f37-18d9-4a82-8fdc-a7da73731858",
    path: "/product/6d773f37-18d9-4a82-8fdc-a7da73731858",
    meta: {"ID":"6d773f37-18d9-4a82-8fdc-a7da73731858","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|6d89dffd-5c05-4fb8-8460-38090408cb21",
    path: "/product/6d89dffd-5c05-4fb8-8460-38090408cb21",
    meta: {"ID":"6d89dffd-5c05-4fb8-8460-38090408cb21","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6dc1685c-520c-4435-834a-fe3f34c1235d",
    path: "/product/6dc1685c-520c-4435-834a-fe3f34c1235d",
    meta: {"ID":"6dc1685c-520c-4435-834a-fe3f34c1235d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6dca0a35-8538-468f-ac98-989d214a952f",
    path: "/product/6dca0a35-8538-468f-ac98-989d214a952f",
    meta: {"ID":"6dca0a35-8538-468f-ac98-989d214a952f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6dd4705a-3eff-46e9-9022-849c43dd80df",
    path: "/product/6dd4705a-3eff-46e9-9022-849c43dd80df",
    meta: {"ID":"6dd4705a-3eff-46e9-9022-849c43dd80df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|68b13f59-5b5e-4f71-90ae-eadb72690750|6ddf4c7c-6dfe-4b2b-8817-de587a8ab6e9",
    path: "/product/6ddf4c7c-6dfe-4b2b-8817-de587a8ab6e9",
    meta: {"ID":"6ddf4c7c-6dfe-4b2b-8817-de587a8ab6e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|6e33fb09-c530-45b5-888a-ef3b3d01e31d",
    path: "/product/6e33fb09-c530-45b5-888a-ef3b3d01e31d",
    meta: {"ID":"6e33fb09-c530-45b5-888a-ef3b3d01e31d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6e4998b6-b4f9-44b1-bff1-36ee18b9c3c1",
    path: "/product/6e4998b6-b4f9-44b1-bff1-36ee18b9c3c1",
    meta: {"ID":"6e4998b6-b4f9-44b1-bff1-36ee18b9c3c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6e52a582-4d54-4288-a1a7-390543dd3414",
    path: "/product/6e52a582-4d54-4288-a1a7-390543dd3414",
    meta: {"ID":"6e52a582-4d54-4288-a1a7-390543dd3414","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6e6926ce-d589-469f-84f1-3f2caae5d9c5",
    path: "/product/6e6926ce-d589-469f-84f1-3f2caae5d9c5",
    meta: {"ID":"6e6926ce-d589-469f-84f1-3f2caae5d9c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6e6e77d6-9fd5-407f-9b2a-aea5030faa5e",
    path: "/product/6e6e77d6-9fd5-407f-9b2a-aea5030faa5e",
    meta: {"ID":"6e6e77d6-9fd5-407f-9b2a-aea5030faa5e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6e92ef99-59c2-4027-9248-999243cf2916",
    path: "/product/6e92ef99-59c2-4027-9248-999243cf2916",
    meta: {"ID":"6e92ef99-59c2-4027-9248-999243cf2916","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|6ea4602e-2d26-4109-9397-8d160f0fb19a",
    path: "/product/6ea4602e-2d26-4109-9397-8d160f0fb19a",
    meta: {"ID":"6ea4602e-2d26-4109-9397-8d160f0fb19a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|6ea599e7-8e7e-4b10-b1ad-b9401fbb3cc7",
    path: "/product/6ea599e7-8e7e-4b10-b1ad-b9401fbb3cc7",
    meta: {"ID":"6ea599e7-8e7e-4b10-b1ad-b9401fbb3cc7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6ec2c3a7-d59e-410a-ba5d-8b11cb9745d2",
    path: "/product/6ec2c3a7-d59e-410a-ba5d-8b11cb9745d2",
    meta: {"ID":"6ec2c3a7-d59e-410a-ba5d-8b11cb9745d2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6efa4d29-53c5-460f-9d56-95954a033dd6",
    path: "/product/6efa4d29-53c5-460f-9d56-95954a033dd6",
    meta: {"ID":"6efa4d29-53c5-460f-9d56-95954a033dd6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|6f32f290-40b3-4eb7-a10e-5076f31df0cf",
    path: "/product/6f32f290-40b3-4eb7-a10e-5076f31df0cf",
    meta: {"ID":"6f32f290-40b3-4eb7-a10e-5076f31df0cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6f40039f-09ae-40d0-8851-f0bd1970086d",
    path: "/product/6f40039f-09ae-40d0-8851-f0bd1970086d",
    meta: {"ID":"6f40039f-09ae-40d0-8851-f0bd1970086d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6f40ecb1-319d-450b-9230-9e5c11675be7",
    path: "/product/6f40ecb1-319d-450b-9230-9e5c11675be7",
    meta: {"ID":"6f40ecb1-319d-450b-9230-9e5c11675be7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|6f7ae819-e7b5-485a-8f6b-4ad5cd0dbafb",
    path: "/product/6f7ae819-e7b5-485a-8f6b-4ad5cd0dbafb",
    meta: {"ID":"6f7ae819-e7b5-485a-8f6b-4ad5cd0dbafb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|6f8108f3-0a7b-4747-8b5f-c64f62d26047",
    path: "/product/6f8108f3-0a7b-4747-8b5f-c64f62d26047",
    meta: {"ID":"6f8108f3-0a7b-4747-8b5f-c64f62d26047","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2e4c4f9e-4ea3-49dc-b9e8-6726b7080501|6f9b6867-31b6-4da0-b00e-1a6fe53536e0",
    path: "/product/6f9b6867-31b6-4da0-b00e-1a6fe53536e0",
    meta: {"ID":"6f9b6867-31b6-4da0-b00e-1a6fe53536e0","Title":"","GroupID":"2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","GroupTitle":"Детское меню","GroupLink":"/menu/2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|6fb0e3fe-7c9d-49d0-8692-9cda4f29d959",
    path: "/product/6fb0e3fe-7c9d-49d0-8692-9cda4f29d959",
    meta: {"ID":"6fb0e3fe-7c9d-49d0-8692-9cda4f29d959","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|6fc17bba-5afc-42f4-925b-c7d13d99be0b",
    path: "/product/6fc17bba-5afc-42f4-925b-c7d13d99be0b",
    meta: {"ID":"6fc17bba-5afc-42f4-925b-c7d13d99be0b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|70026975-c6bb-49f5-9748-d8b38e169752",
    path: "/product/70026975-c6bb-49f5-9748-d8b38e169752",
    meta: {"ID":"70026975-c6bb-49f5-9748-d8b38e169752","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|700d4848-aa32-4a13-9806-c8d0eb795332",
    path: "/product/700d4848-aa32-4a13-9806-c8d0eb795332",
    meta: {"ID":"700d4848-aa32-4a13-9806-c8d0eb795332","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|70130cc2-e2a0-4b0a-8161-9b61d1bbda13",
    path: "/product/70130cc2-e2a0-4b0a-8161-9b61d1bbda13",
    meta: {"ID":"70130cc2-e2a0-4b0a-8161-9b61d1bbda13","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7015ae45-f1d4-46f8-b8ae-71e4839b5db3",
    path: "/product/7015ae45-f1d4-46f8-b8ae-71e4839b5db3",
    meta: {"ID":"7015ae45-f1d4-46f8-b8ae-71e4839b5db3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|701f9028-357b-4325-a230-c2b5aa53e9c1",
    path: "/product/701f9028-357b-4325-a230-c2b5aa53e9c1",
    meta: {"ID":"701f9028-357b-4325-a230-c2b5aa53e9c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|702a8153-3c27-4704-a87b-84ab490f0d99",
    path: "/product/702a8153-3c27-4704-a87b-84ab490f0d99",
    meta: {"ID":"702a8153-3c27-4704-a87b-84ab490f0d99","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|703ddb60-38a1-4acc-8f94-f6dd79056d34",
    path: "/product/703ddb60-38a1-4acc-8f94-f6dd79056d34",
    meta: {"ID":"703ddb60-38a1-4acc-8f94-f6dd79056d34","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|7042287f-13c8-4d34-87f4-94ed0c4308c7",
    path: "/product/7042287f-13c8-4d34-87f4-94ed0c4308c7",
    meta: {"ID":"7042287f-13c8-4d34-87f4-94ed0c4308c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|704a23f2-0b4d-4510-aa91-24dc6f0b1391",
    path: "/product/704a23f2-0b4d-4510-aa91-24dc6f0b1391",
    meta: {"ID":"704a23f2-0b4d-4510-aa91-24dc6f0b1391","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|704eca0c-15dc-4ee2-be50-894961e1c27e",
    path: "/product/704eca0c-15dc-4ee2-be50-894961e1c27e",
    meta: {"ID":"704eca0c-15dc-4ee2-be50-894961e1c27e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4626112c-772c-43a4-83b9-fc5d6a1a2597|7062bcd5-8d74-4947-9238-6a5b1a1b2ca2",
    path: "/product/7062bcd5-8d74-4947-9238-6a5b1a1b2ca2",
    meta: {"ID":"7062bcd5-8d74-4947-9238-6a5b1a1b2ca2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7064091d-d835-47cf-bda3-c573bb502b6c",
    path: "/product/7064091d-d835-47cf-bda3-c573bb502b6c",
    meta: {"ID":"7064091d-d835-47cf-bda3-c573bb502b6c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|70644cb3-39c3-4340-aaa3-b3a0bd2f41d5",
    path: "/product/70644cb3-39c3-4340-aaa3-b3a0bd2f41d5",
    meta: {"ID":"70644cb3-39c3-4340-aaa3-b3a0bd2f41d5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|7082a28e-f990-4df6-bb99-b73b9b00606b",
    path: "/product/7082a28e-f990-4df6-bb99-b73b9b00606b",
    meta: {"ID":"7082a28e-f990-4df6-bb99-b73b9b00606b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|708b9333-cb0a-46bd-b9d4-332b995605da",
    path: "/product/708b9333-cb0a-46bd-b9d4-332b995605da",
    meta: {"ID":"708b9333-cb0a-46bd-b9d4-332b995605da","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|70af8ba0-f6f4-4128-984f-3b739f655be3",
    path: "/product/70af8ba0-f6f4-4128-984f-3b739f655be3",
    meta: {"ID":"70af8ba0-f6f4-4128-984f-3b739f655be3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|70b7e984-59b2-469d-a769-1b166fcc018e",
    path: "/product/70b7e984-59b2-469d-a769-1b166fcc018e",
    meta: {"ID":"70b7e984-59b2-469d-a769-1b166fcc018e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|70fc3e6f-6ead-4ec3-b9e1-e45d86be9eda",
    path: "/product/70fc3e6f-6ead-4ec3-b9e1-e45d86be9eda",
    meta: {"ID":"70fc3e6f-6ead-4ec3-b9e1-e45d86be9eda","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|71403a03-01dc-43e3-9064-6edf41920cab",
    path: "/product/71403a03-01dc-43e3-9064-6edf41920cab",
    meta: {"ID":"71403a03-01dc-43e3-9064-6edf41920cab","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|714477c3-3a56-446c-9195-30ab42d95e35",
    path: "/product/714477c3-3a56-446c-9195-30ab42d95e35",
    meta: {"ID":"714477c3-3a56-446c-9195-30ab42d95e35","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|715be09e-615a-4fc8-99eb-5955b040e13f",
    path: "/product/715be09e-615a-4fc8-99eb-5955b040e13f",
    meta: {"ID":"715be09e-615a-4fc8-99eb-5955b040e13f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|7167042f-5df1-437c-a666-60845549cd0e",
    path: "/product/7167042f-5df1-437c-a666-60845549cd0e",
    meta: {"ID":"7167042f-5df1-437c-a666-60845549cd0e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|71960597-6fc4-4aea-86e4-e1170e01e3f2",
    path: "/product/71960597-6fc4-4aea-86e4-e1170e01e3f2",
    meta: {"ID":"71960597-6fc4-4aea-86e4-e1170e01e3f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|71b3235a-db32-41e2-98dd-fa54434fdd50",
    path: "/product/71b3235a-db32-41e2-98dd-fa54434fdd50",
    meta: {"ID":"71b3235a-db32-41e2-98dd-fa54434fdd50","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|71e89fab-1c30-4de7-9b75-243f269e8990",
    path: "/product/71e89fab-1c30-4de7-9b75-243f269e8990",
    meta: {"ID":"71e89fab-1c30-4de7-9b75-243f269e8990","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|af1ac0fd-a494-4b35-ae24-edc1fe10a7e4|72080754-37d4-41e2-9b93-0125428bc4c5",
    path: "/product/72080754-37d4-41e2-9b93-0125428bc4c5",
    meta: {"ID":"72080754-37d4-41e2-9b93-0125428bc4c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|720d199f-bfe6-4f97-9e9a-92a88ecbce68",
    path: "/product/720d199f-bfe6-4f97-9e9a-92a88ecbce68",
    meta: {"ID":"720d199f-bfe6-4f97-9e9a-92a88ecbce68","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5cb1bd94-d83d-4b23-9469-0ddc318e5ee6|722ae4ba-41b1-4ebb-96c8-5032c74743bb",
    path: "/product/722ae4ba-41b1-4ebb-96c8-5032c74743bb",
    meta: {"ID":"722ae4ba-41b1-4ebb-96c8-5032c74743bb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|722ee50c-c207-4803-8e86-d6004197a2f6",
    path: "/product/722ee50c-c207-4803-8e86-d6004197a2f6",
    meta: {"ID":"722ee50c-c207-4803-8e86-d6004197a2f6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|72428ae6-39a2-4a8c-a3a5-16cb521f64d5",
    path: "/product/72428ae6-39a2-4a8c-a3a5-16cb521f64d5",
    meta: {"ID":"72428ae6-39a2-4a8c-a3a5-16cb521f64d5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|725480b3-fcfb-460e-acda-e21c7e9a82b6",
    path: "/product/725480b3-fcfb-460e-acda-e21c7e9a82b6",
    meta: {"ID":"725480b3-fcfb-460e-acda-e21c7e9a82b6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|7255ed7a-b039-4677-b23f-69d2f19f4613",
    path: "/product/7255ed7a-b039-4677-b23f-69d2f19f4613",
    meta: {"ID":"7255ed7a-b039-4677-b23f-69d2f19f4613","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7280eae1-284f-4494-b847-52b15126e9fc",
    path: "/product/7280eae1-284f-4494-b847-52b15126e9fc",
    meta: {"ID":"7280eae1-284f-4494-b847-52b15126e9fc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|72965eeb-6497-417d-8c6f-09278403e287",
    path: "/product/72965eeb-6497-417d-8c6f-09278403e287",
    meta: {"ID":"72965eeb-6497-417d-8c6f-09278403e287","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|7299ff48-0f03-4dad-b291-7b4ff201bd22",
    path: "/product/7299ff48-0f03-4dad-b291-7b4ff201bd22",
    meta: {"ID":"7299ff48-0f03-4dad-b291-7b4ff201bd22","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|654b3dbf-24ce-4acd-8343-54dbb128223f|72e54bed-d2e1-4670-bba9-0679e2e67844",
    path: "/product/72e54bed-d2e1-4670-bba9-0679e2e67844",
    meta: {"ID":"72e54bed-d2e1-4670-bba9-0679e2e67844","Title":"","GroupID":"654b3dbf-24ce-4acd-8343-54dbb128223f","GroupTitle":"Супы","GroupLink":"/menu/654b3dbf-24ce-4acd-8343-54dbb128223f","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|73069a5e-225a-4152-89ad-51013160a7c2",
    path: "/product/73069a5e-225a-4152-89ad-51013160a7c2",
    meta: {"ID":"73069a5e-225a-4152-89ad-51013160a7c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|730f9bdf-0139-4dda-b07a-0c6a187b394a",
    path: "/product/730f9bdf-0139-4dda-b07a-0c6a187b394a",
    meta: {"ID":"730f9bdf-0139-4dda-b07a-0c6a187b394a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|7319a8c2-d6ae-4a13-b59a-f16dfc3a2c20",
    path: "/product/7319a8c2-d6ae-4a13-b59a-f16dfc3a2c20",
    meta: {"ID":"7319a8c2-d6ae-4a13-b59a-f16dfc3a2c20","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|733efbd3-45b1-422e-9760-9e297611f472",
    path: "/product/733efbd3-45b1-422e-9760-9e297611f472",
    meta: {"ID":"733efbd3-45b1-422e-9760-9e297611f472","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|7345fd43-8694-4082-aedc-026f8976d997",
    path: "/product/7345fd43-8694-4082-aedc-026f8976d997",
    meta: {"ID":"7345fd43-8694-4082-aedc-026f8976d997","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7352d874-f0fc-4943-9782-d28a15026785",
    path: "/product/7352d874-f0fc-4943-9782-d28a15026785",
    meta: {"ID":"7352d874-f0fc-4943-9782-d28a15026785","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca23e9-81fa-4648-a2f2-397fc639baa0|7352eeb6-fc4e-4964-b613-c6067e531c0a",
    path: "/product/7352eeb6-fc4e-4964-b613-c6067e531c0a",
    meta: {"ID":"7352eeb6-fc4e-4964-b613-c6067e531c0a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|7388cb7a-a6f7-4c8a-9315-7a0c8803d77c",
    path: "/product/7388cb7a-a6f7-4c8a-9315-7a0c8803d77c",
    meta: {"ID":"7388cb7a-a6f7-4c8a-9315-7a0c8803d77c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|738f5fba-c5c3-4da4-9583-6755b50a3c59",
    path: "/product/738f5fba-c5c3-4da4-9583-6755b50a3c59",
    meta: {"ID":"738f5fba-c5c3-4da4-9583-6755b50a3c59","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7395dc12-1cb4-48a5-8e1a-7a8dc42a6650",
    path: "/product/7395dc12-1cb4-48a5-8e1a-7a8dc42a6650",
    meta: {"ID":"7395dc12-1cb4-48a5-8e1a-7a8dc42a6650","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|73e728e6-2d14-4851-a11f-b5e6b125c372",
    path: "/product/73e728e6-2d14-4851-a11f-b5e6b125c372",
    meta: {"ID":"73e728e6-2d14-4851-a11f-b5e6b125c372","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|73ea7afb-6dab-438d-9768-1f29e131036e",
    path: "/product/73ea7afb-6dab-438d-9768-1f29e131036e",
    meta: {"ID":"73ea7afb-6dab-438d-9768-1f29e131036e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|73f7fc2d-b6bb-418b-b56e-071deb12f4ef",
    path: "/product/73f7fc2d-b6bb-418b-b56e-071deb12f4ef",
    meta: {"ID":"73f7fc2d-b6bb-418b-b56e-071deb12f4ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|740141d5-bb18-4e46-927a-e68bf8d10b46",
    path: "/product/740141d5-bb18-4e46-927a-e68bf8d10b46",
    meta: {"ID":"740141d5-bb18-4e46-927a-e68bf8d10b46","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca1c41-f8fc-4cd3-9788-4fe61c89225d|741d2f61-2e1c-490f-b5ce-6c46178d4042",
    path: "/product/741d2f61-2e1c-490f-b5ce-6c46178d4042",
    meta: {"ID":"741d2f61-2e1c-490f-b5ce-6c46178d4042","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|741d5bd1-5c02-40d6-b958-e4132d16816f",
    path: "/product/741d5bd1-5c02-40d6-b958-e4132d16816f",
    meta: {"ID":"741d5bd1-5c02-40d6-b958-e4132d16816f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|74222020-1568-430f-bd54-26996f5d57bd",
    path: "/product/74222020-1568-430f-bd54-26996f5d57bd",
    meta: {"ID":"74222020-1568-430f-bd54-26996f5d57bd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|742c4112-0fa8-4803-8b35-7434a72f7cb6",
    path: "/product/742c4112-0fa8-4803-8b35-7434a72f7cb6",
    meta: {"ID":"742c4112-0fa8-4803-8b35-7434a72f7cb6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|74462512-6ca1-451a-bbc2-f156a97a4fd4",
    path: "/product/74462512-6ca1-451a-bbc2-f156a97a4fd4",
    meta: {"ID":"74462512-6ca1-451a-bbc2-f156a97a4fd4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|544108e9-c5b9-46e2-b148-42c3b70c298d|74672abf-94bc-4faa-8043-f706023781e9",
    path: "/product/74672abf-94bc-4faa-8043-f706023781e9",
    meta: {"ID":"74672abf-94bc-4faa-8043-f706023781e9","Title":"","GroupID":"544108e9-c5b9-46e2-b148-42c3b70c298d","GroupTitle":"Традиционная пицца","GroupLink":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|746dbd33-0eb0-4bc2-9959-0a49d887c498",
    path: "/product/746dbd33-0eb0-4bc2-9959-0a49d887c498",
    meta: {"ID":"746dbd33-0eb0-4bc2-9959-0a49d887c498","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|747b8429-e4e3-45b1-9014-2e1b65767951",
    path: "/product/747b8429-e4e3-45b1-9014-2e1b65767951",
    meta: {"ID":"747b8429-e4e3-45b1-9014-2e1b65767951","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|42a5e113-75f4-40d2-a9ee-575b6e6c0b8d|7484c191-4b35-465e-82c3-a4ee45817307",
    path: "/product/7484c191-4b35-465e-82c3-a4ee45817307",
    meta: {"ID":"7484c191-4b35-465e-82c3-a4ee45817307","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|74b38fe5-6c22-4b66-a2c8-1c9b8569e263",
    path: "/product/74b38fe5-6c22-4b66-a2c8-1c9b8569e263",
    meta: {"ID":"74b38fe5-6c22-4b66-a2c8-1c9b8569e263","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|74b7fae2-8e70-4b08-b197-3d11ac22dc6b",
    path: "/product/74b7fae2-8e70-4b08-b197-3d11ac22dc6b",
    meta: {"ID":"74b7fae2-8e70-4b08-b197-3d11ac22dc6b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|74d062c1-d763-4d05-a917-eda5080a9e4a",
    path: "/product/74d062c1-d763-4d05-a917-eda5080a9e4a",
    meta: {"ID":"74d062c1-d763-4d05-a917-eda5080a9e4a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|74d2905c-f93e-47a8-b069-d7bf01a8170f",
    path: "/product/74d2905c-f93e-47a8-b069-d7bf01a8170f",
    meta: {"ID":"74d2905c-f93e-47a8-b069-d7bf01a8170f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|74f16e01-6928-419f-8e2c-830df9616af5",
    path: "/product/74f16e01-6928-419f-8e2c-830df9616af5",
    meta: {"ID":"74f16e01-6928-419f-8e2c-830df9616af5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|74f60855-9ff6-4a2d-ab99-6119a254c40a",
    path: "/product/74f60855-9ff6-4a2d-ab99-6119a254c40a",
    meta: {"ID":"74f60855-9ff6-4a2d-ab99-6119a254c40a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|74f70065-b5d6-46f3-9d75-1c0f0a0adccb",
    path: "/product/74f70065-b5d6-46f3-9d75-1c0f0a0adccb",
    meta: {"ID":"74f70065-b5d6-46f3-9d75-1c0f0a0adccb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|74fd8fb8-9445-4b2d-8ced-661a7902eac2",
    path: "/product/74fd8fb8-9445-4b2d-8ced-661a7902eac2",
    meta: {"ID":"74fd8fb8-9445-4b2d-8ced-661a7902eac2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|7508f7e7-a806-4ba5-8ebc-1fbc2d4c3163",
    path: "/product/7508f7e7-a806-4ba5-8ebc-1fbc2d4c3163",
    meta: {"ID":"7508f7e7-a806-4ba5-8ebc-1fbc2d4c3163","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|751ede4f-0128-45e0-a3b6-5d8b9bef248d",
    path: "/product/751ede4f-0128-45e0-a3b6-5d8b9bef248d",
    meta: {"ID":"751ede4f-0128-45e0-a3b6-5d8b9bef248d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|752862e6-27f1-41a2-9bf7-dbbcac3ee6c8",
    path: "/product/752862e6-27f1-41a2-9bf7-dbbcac3ee6c8",
    meta: {"ID":"752862e6-27f1-41a2-9bf7-dbbcac3ee6c8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|756d8a61-5272-4141-ab9f-ab55f9a95922",
    path: "/product/756d8a61-5272-4141-ab9f-ab55f9a95922",
    meta: {"ID":"756d8a61-5272-4141-ab9f-ab55f9a95922","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|75737f2f-27ce-48ac-ad72-6a214ca3ef5b",
    path: "/product/75737f2f-27ce-48ac-ad72-6a214ca3ef5b",
    meta: {"ID":"75737f2f-27ce-48ac-ad72-6a214ca3ef5b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|758c5b2a-ad6a-406d-b147-d57957597f65",
    path: "/product/758c5b2a-ad6a-406d-b147-d57957597f65",
    meta: {"ID":"758c5b2a-ad6a-406d-b147-d57957597f65","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|75c19dcf-bdda-4aff-b4f3-4d4292547559",
    path: "/product/75c19dcf-bdda-4aff-b4f3-4d4292547559",
    meta: {"ID":"75c19dcf-bdda-4aff-b4f3-4d4292547559","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|75d25558-8860-47fb-bf63-48a155812d27",
    path: "/product/75d25558-8860-47fb-bf63-48a155812d27",
    meta: {"ID":"75d25558-8860-47fb-bf63-48a155812d27","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|75e10fba-07b8-4455-8843-6af2a7187980",
    path: "/product/75e10fba-07b8-4455-8843-6af2a7187980",
    meta: {"ID":"75e10fba-07b8-4455-8843-6af2a7187980","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73cec509-3358-485d-8d8e-5484c8bb8f78|75ffce8b-0b34-4282-974b-5c7458df983a",
    path: "/product/75ffce8b-0b34-4282-974b-5c7458df983a",
    meta: {"ID":"75ffce8b-0b34-4282-974b-5c7458df983a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|760fbd7f-ef22-4e34-ab6b-9e9606d9a716",
    path: "/product/760fbd7f-ef22-4e34-ab6b-9e9606d9a716",
    meta: {"ID":"760fbd7f-ef22-4e34-ab6b-9e9606d9a716","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|42a5e113-75f4-40d2-a9ee-575b6e6c0b8d|763bd8a7-d265-4148-9e8a-9ce8459aba12",
    path: "/product/763bd8a7-d265-4148-9e8a-9ce8459aba12",
    meta: {"ID":"763bd8a7-d265-4148-9e8a-9ce8459aba12","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|76473594-4298-45c3-bb73-a02d876c31c5",
    path: "/product/76473594-4298-45c3-bb73-a02d876c31c5",
    meta: {"ID":"76473594-4298-45c3-bb73-a02d876c31c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7665d244-7540-4bda-9c30-7c088568310b",
    path: "/product/7665d244-7540-4bda-9c30-7c088568310b",
    meta: {"ID":"7665d244-7540-4bda-9c30-7c088568310b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|768d3759-2361-4d08-9a44-f8bfe83ac36d",
    path: "/product/768d3759-2361-4d08-9a44-f8bfe83ac36d",
    meta: {"ID":"768d3759-2361-4d08-9a44-f8bfe83ac36d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|76a2197e-93ed-4713-8555-c638a857f6b5",
    path: "/product/76a2197e-93ed-4713-8555-c638a857f6b5",
    meta: {"ID":"76a2197e-93ed-4713-8555-c638a857f6b5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|76a5a974-fd34-4df7-904b-a2c1ed8e8b16",
    path: "/product/76a5a974-fd34-4df7-904b-a2c1ed8e8b16",
    meta: {"ID":"76a5a974-fd34-4df7-904b-a2c1ed8e8b16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|76c90ecf-19c6-4441-b544-c302f536a290",
    path: "/product/76c90ecf-19c6-4441-b544-c302f536a290",
    meta: {"ID":"76c90ecf-19c6-4441-b544-c302f536a290","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|76d7fae8-9150-4198-bef9-d4e69b2e63c4",
    path: "/product/76d7fae8-9150-4198-bef9-d4e69b2e63c4",
    meta: {"ID":"76d7fae8-9150-4198-bef9-d4e69b2e63c4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|76dea351-623a-4d3e-84c5-3aec29820645",
    path: "/product/76dea351-623a-4d3e-84c5-3aec29820645",
    meta: {"ID":"76dea351-623a-4d3e-84c5-3aec29820645","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|76ea9eef-29ff-4814-b014-90b31e258042",
    path: "/product/76ea9eef-29ff-4814-b014-90b31e258042",
    meta: {"ID":"76ea9eef-29ff-4814-b014-90b31e258042","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|76eead6a-0f6b-472b-b952-f6443d6f5ad9",
    path: "/product/76eead6a-0f6b-472b-b952-f6443d6f5ad9",
    meta: {"ID":"76eead6a-0f6b-472b-b952-f6443d6f5ad9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|772f92e4-0186-4206-bddd-b5b9bfdaacf9",
    path: "/product/772f92e4-0186-4206-bddd-b5b9bfdaacf9",
    meta: {"ID":"772f92e4-0186-4206-bddd-b5b9bfdaacf9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6fa548ca-4b6a-4806-83da-74148d7bfb94|7732e615-5438-4d04-9446-07adec7f982f",
    path: "/product/7732e615-5438-4d04-9446-07adec7f982f",
    meta: {"ID":"7732e615-5438-4d04-9446-07adec7f982f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|773865a8-efdb-4b03-bded-ff56b0f158ef",
    path: "/product/773865a8-efdb-4b03-bded-ff56b0f158ef",
    meta: {"ID":"773865a8-efdb-4b03-bded-ff56b0f158ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|64599942-da85-4d3c-9234-5d5319216105|773c0c12-6a8b-48bc-8385-7491218f0856",
    path: "/product/773c0c12-6a8b-48bc-8385-7491218f0856",
    meta: {"ID":"773c0c12-6a8b-48bc-8385-7491218f0856","Title":"","GroupID":"64599942-da85-4d3c-9234-5d5319216105","GroupTitle":"Римская пицца","GroupLink":"/menu/64599942-da85-4d3c-9234-5d5319216105","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|776c58f9-3c5c-4997-9e96-bf16de1d7845",
    path: "/product/776c58f9-3c5c-4997-9e96-bf16de1d7845",
    meta: {"ID":"776c58f9-3c5c-4997-9e96-bf16de1d7845","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|77752f26-fd9b-4e90-ab66-c7b265292078",
    path: "/product/77752f26-fd9b-4e90-ab66-c7b265292078",
    meta: {"ID":"77752f26-fd9b-4e90-ab66-c7b265292078","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7785adfc-0cf3-424e-9895-a86b0a858c74",
    path: "/product/7785adfc-0cf3-424e-9895-a86b0a858c74",
    meta: {"ID":"7785adfc-0cf3-424e-9895-a86b0a858c74","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|77903486-a2b8-4999-bd8e-16c8cf5038c2",
    path: "/product/77903486-a2b8-4999-bd8e-16c8cf5038c2",
    meta: {"ID":"77903486-a2b8-4999-bd8e-16c8cf5038c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|77a462fe-7322-4196-a9a6-dbe0a0ee6d2b",
    path: "/product/77a462fe-7322-4196-a9a6-dbe0a0ee6d2b",
    meta: {"ID":"77a462fe-7322-4196-a9a6-dbe0a0ee6d2b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|77c8491e-5507-4fa2-8d3c-e2509190a265",
    path: "/product/77c8491e-5507-4fa2-8d3c-e2509190a265",
    meta: {"ID":"77c8491e-5507-4fa2-8d3c-e2509190a265","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|77d178f9-1e14-439a-80e3-ed696d2b91c6",
    path: "/product/77d178f9-1e14-439a-80e3-ed696d2b91c6",
    meta: {"ID":"77d178f9-1e14-439a-80e3-ed696d2b91c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|77d88292-f2ad-4a01-9b5d-714e88d54478",
    path: "/product/77d88292-f2ad-4a01-9b5d-714e88d54478",
    meta: {"ID":"77d88292-f2ad-4a01-9b5d-714e88d54478","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|782075e9-0e6b-4ad4-9ab1-2a9bafa64708",
    path: "/product/782075e9-0e6b-4ad4-9ab1-2a9bafa64708",
    meta: {"ID":"782075e9-0e6b-4ad4-9ab1-2a9bafa64708","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|78369f7d-28b3-4830-b262-16f64e778939",
    path: "/product/78369f7d-28b3-4830-b262-16f64e778939",
    meta: {"ID":"78369f7d-28b3-4830-b262-16f64e778939","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|7837fa83-8096-41ae-8b93-171d86a1ae43",
    path: "/product/7837fa83-8096-41ae-8b93-171d86a1ae43",
    meta: {"ID":"7837fa83-8096-41ae-8b93-171d86a1ae43","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|785d72e4-29e9-46be-9432-900281056157",
    path: "/product/785d72e4-29e9-46be-9432-900281056157",
    meta: {"ID":"785d72e4-29e9-46be-9432-900281056157","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|db514044-cc74-4cb4-88c1-e0d3608fecb6|7861a58b-f648-4de4-a78a-21c41bd5e716",
    path: "/product/7861a58b-f648-4de4-a78a-21c41bd5e716",
    meta: {"ID":"7861a58b-f648-4de4-a78a-21c41bd5e716","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|78787fd2-bf50-4830-acec-a3f09c9f3ff6",
    path: "/product/78787fd2-bf50-4830-acec-a3f09c9f3ff6",
    meta: {"ID":"78787fd2-bf50-4830-acec-a3f09c9f3ff6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|78937599-9f39-4cff-bf23-9965b0dc2f42",
    path: "/product/78937599-9f39-4cff-bf23-9965b0dc2f42",
    meta: {"ID":"78937599-9f39-4cff-bf23-9965b0dc2f42","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|789628b1-3a4d-4bf4-92e2-a1419f6ad9ee",
    path: "/product/789628b1-3a4d-4bf4-92e2-a1419f6ad9ee",
    meta: {"ID":"789628b1-3a4d-4bf4-92e2-a1419f6ad9ee","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|789e73cb-8301-4573-b722-70bae458cf19",
    path: "/product/789e73cb-8301-4573-b722-70bae458cf19",
    meta: {"ID":"789e73cb-8301-4573-b722-70bae458cf19","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|654b3dbf-24ce-4acd-8343-54dbb128223f|78a0f01e-13cf-431c-9109-6097c1c93e93",
    path: "/product/78a0f01e-13cf-431c-9109-6097c1c93e93",
    meta: {"ID":"78a0f01e-13cf-431c-9109-6097c1c93e93","Title":"","GroupID":"654b3dbf-24ce-4acd-8343-54dbb128223f","GroupTitle":"Супы","GroupLink":"/menu/654b3dbf-24ce-4acd-8343-54dbb128223f","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|78a49a71-df6b-4f1b-844d-381f49672f68",
    path: "/product/78a49a71-df6b-4f1b-844d-381f49672f68",
    meta: {"ID":"78a49a71-df6b-4f1b-844d-381f49672f68","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|78aab850-db9d-4ba9-bb0a-e45507668eea",
    path: "/product/78aab850-db9d-4ba9-bb0a-e45507668eea",
    meta: {"ID":"78aab850-db9d-4ba9-bb0a-e45507668eea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|78b4b2b0-b0fb-4b43-8dcf-340b0a88ed9d",
    path: "/product/78b4b2b0-b0fb-4b43-8dcf-340b0a88ed9d",
    meta: {"ID":"78b4b2b0-b0fb-4b43-8dcf-340b0a88ed9d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|78bdc6bb-f722-409a-8f2d-671d53e3c8cb",
    path: "/product/78bdc6bb-f722-409a-8f2d-671d53e3c8cb",
    meta: {"ID":"78bdc6bb-f722-409a-8f2d-671d53e3c8cb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|78ce14a5-ba0a-488b-8431-702eb61b3ee7",
    path: "/product/78ce14a5-ba0a-488b-8431-702eb61b3ee7",
    meta: {"ID":"78ce14a5-ba0a-488b-8431-702eb61b3ee7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|78d72ee2-c42c-4c85-9a5b-163644cdf9f9",
    path: "/product/78d72ee2-c42c-4c85-9a5b-163644cdf9f9",
    meta: {"ID":"78d72ee2-c42c-4c85-9a5b-163644cdf9f9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|78dd4907-575e-480c-bcf1-639756eecc9d",
    path: "/product/78dd4907-575e-480c-bcf1-639756eecc9d",
    meta: {"ID":"78dd4907-575e-480c-bcf1-639756eecc9d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|78f9731d-1b24-41a1-95f9-7331b6067fbd",
    path: "/product/78f9731d-1b24-41a1-95f9-7331b6067fbd",
    meta: {"ID":"78f9731d-1b24-41a1-95f9-7331b6067fbd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7900a924-734a-417b-af6b-03b464f6dd7b",
    path: "/product/7900a924-734a-417b-af6b-03b464f6dd7b",
    meta: {"ID":"7900a924-734a-417b-af6b-03b464f6dd7b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|79013043-30dd-4af1-ae8d-9667b721daea",
    path: "/product/79013043-30dd-4af1-ae8d-9667b721daea",
    meta: {"ID":"79013043-30dd-4af1-ae8d-9667b721daea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|79044c47-aaf3-4150-b062-fe0653e6066b",
    path: "/product/79044c47-aaf3-4150-b062-fe0653e6066b",
    meta: {"ID":"79044c47-aaf3-4150-b062-fe0653e6066b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7911a7da-34d7-42e3-80ab-1e58e9b263f9",
    path: "/product/7911a7da-34d7-42e3-80ab-1e58e9b263f9",
    meta: {"ID":"7911a7da-34d7-42e3-80ab-1e58e9b263f9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|735f10a8-dcc4-47d5-9639-e55e0af97024|792d6cba-1e8d-4726-95e2-4b4d549a91ac",
    path: "/product/792d6cba-1e8d-4726-95e2-4b4d549a91ac",
    meta: {"ID":"792d6cba-1e8d-4726-95e2-4b4d549a91ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|79344bc5-1281-4da8-b715-ead7a80d4b18",
    path: "/product/79344bc5-1281-4da8-b715-ead7a80d4b18",
    meta: {"ID":"79344bc5-1281-4da8-b715-ead7a80d4b18","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7959b8ed-85a0-4dd7-894b-940283086426",
    path: "/product/7959b8ed-85a0-4dd7-894b-940283086426",
    meta: {"ID":"7959b8ed-85a0-4dd7-894b-940283086426","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|79602ece-0381-4a5c-93e5-64393306fea8",
    path: "/product/79602ece-0381-4a5c-93e5-64393306fea8",
    meta: {"ID":"79602ece-0381-4a5c-93e5-64393306fea8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|7965cdba-196d-4b43-9636-ac33273aca56",
    path: "/product/7965cdba-196d-4b43-9636-ac33273aca56",
    meta: {"ID":"7965cdba-196d-4b43-9636-ac33273aca56","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|79858769-9d67-4e22-a078-99f123bfab73",
    path: "/product/79858769-9d67-4e22-a078-99f123bfab73",
    meta: {"ID":"79858769-9d67-4e22-a078-99f123bfab73","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|79aea8c6-be8c-4a42-bbfe-833fd88a856a",
    path: "/product/79aea8c6-be8c-4a42-bbfe-833fd88a856a",
    meta: {"ID":"79aea8c6-be8c-4a42-bbfe-833fd88a856a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|79b49b45-eff3-4c8a-a2b5-79bdfc0aa46f",
    path: "/product/79b49b45-eff3-4c8a-a2b5-79bdfc0aa46f",
    meta: {"ID":"79b49b45-eff3-4c8a-a2b5-79bdfc0aa46f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|79b8af8d-5bbd-4a76-9e47-e2941ba61d3e",
    path: "/product/79b8af8d-5bbd-4a76-9e47-e2941ba61d3e",
    meta: {"ID":"79b8af8d-5bbd-4a76-9e47-e2941ba61d3e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|79c6d73b-ac61-4cfd-9624-ce72476a855d",
    path: "/product/79c6d73b-ac61-4cfd-9624-ce72476a855d",
    meta: {"ID":"79c6d73b-ac61-4cfd-9624-ce72476a855d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5cb1bd94-d83d-4b23-9469-0ddc318e5ee6|79f01369-8883-42d1-b218-11a144586a6c",
    path: "/product/79f01369-8883-42d1-b218-11a144586a6c",
    meta: {"ID":"79f01369-8883-42d1-b218-11a144586a6c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|7a225145-41e0-4392-a888-0e353af1da5e",
    path: "/product/7a225145-41e0-4392-a888-0e353af1da5e",
    meta: {"ID":"7a225145-41e0-4392-a888-0e353af1da5e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|7a2da494-1530-4a4d-89bd-b73fa50d4854",
    path: "/product/7a2da494-1530-4a4d-89bd-b73fa50d4854",
    meta: {"ID":"7a2da494-1530-4a4d-89bd-b73fa50d4854","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|7a3999ff-a2cd-41fd-9b1d-34d760cae36c",
    path: "/product/7a3999ff-a2cd-41fd-9b1d-34d760cae36c",
    meta: {"ID":"7a3999ff-a2cd-41fd-9b1d-34d760cae36c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7a44a66e-bbd2-4660-9b31-1f3c35c33f33",
    path: "/product/7a44a66e-bbd2-4660-9b31-1f3c35c33f33",
    meta: {"ID":"7a44a66e-bbd2-4660-9b31-1f3c35c33f33","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|df6e1e38-ff3c-4a9e-94f2-baf987c30a9b|7a759918-e37d-4fb0-8d26-8850ef031b4c",
    path: "/product/7a759918-e37d-4fb0-8d26-8850ef031b4c",
    meta: {"ID":"7a759918-e37d-4fb0-8d26-8850ef031b4c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7aabe974-ff6b-464e-a66f-3681f8edf553",
    path: "/product/7aabe974-ff6b-464e-a66f-3681f8edf553",
    meta: {"ID":"7aabe974-ff6b-464e-a66f-3681f8edf553","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|7ac797a5-ef4e-46d1-ae8c-6c08bc01c57b",
    path: "/product/7ac797a5-ef4e-46d1-ae8c-6c08bc01c57b",
    meta: {"ID":"7ac797a5-ef4e-46d1-ae8c-6c08bc01c57b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|7ade775f-3258-404c-9b48-eef868917894",
    path: "/product/7ade775f-3258-404c-9b48-eef868917894",
    meta: {"ID":"7ade775f-3258-404c-9b48-eef868917894","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|654b3dbf-24ce-4acd-8343-54dbb128223f|7b1463cd-7f2a-4817-b87e-64e97d8ef7fc",
    path: "/product/7b1463cd-7f2a-4817-b87e-64e97d8ef7fc",
    meta: {"ID":"7b1463cd-7f2a-4817-b87e-64e97d8ef7fc","Title":"","GroupID":"654b3dbf-24ce-4acd-8343-54dbb128223f","GroupTitle":"Супы","GroupLink":"/menu/654b3dbf-24ce-4acd-8343-54dbb128223f","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|7b1aaa5c-6fb5-4019-926c-fed665621595",
    path: "/product/7b1aaa5c-6fb5-4019-926c-fed665621595",
    meta: {"ID":"7b1aaa5c-6fb5-4019-926c-fed665621595","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|7b2ba1a1-a94f-4dbe-90a2-9145b385931f",
    path: "/product/7b2ba1a1-a94f-4dbe-90a2-9145b385931f",
    meta: {"ID":"7b2ba1a1-a94f-4dbe-90a2-9145b385931f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|7b5fd2ce-2f04-4545-9b0f-d02306765fa9",
    path: "/product/7b5fd2ce-2f04-4545-9b0f-d02306765fa9",
    meta: {"ID":"7b5fd2ce-2f04-4545-9b0f-d02306765fa9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7b603e63-0f25-45ad-86f0-9fda3733bd16",
    path: "/product/7b603e63-0f25-45ad-86f0-9fda3733bd16",
    meta: {"ID":"7b603e63-0f25-45ad-86f0-9fda3733bd16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7bcc2843-5d56-4fbd-a8d1-c82a3e3997ea",
    path: "/product/7bcc2843-5d56-4fbd-a8d1-c82a3e3997ea",
    meta: {"ID":"7bcc2843-5d56-4fbd-a8d1-c82a3e3997ea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7bd27a86-5c2e-4969-9391-5fa064675d09",
    path: "/product/7bd27a86-5c2e-4969-9391-5fa064675d09",
    meta: {"ID":"7bd27a86-5c2e-4969-9391-5fa064675d09","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7bdb1d83-060e-4a5f-acc8-eea21c3afea7",
    path: "/product/7bdb1d83-060e-4a5f-acc8-eea21c3afea7",
    meta: {"ID":"7bdb1d83-060e-4a5f-acc8-eea21c3afea7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|7be20b4c-cb01-47a4-bc16-2aa066ce7a01",
    path: "/product/7be20b4c-cb01-47a4-bc16-2aa066ce7a01",
    meta: {"ID":"7be20b4c-cb01-47a4-bc16-2aa066ce7a01","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7c05fd41-8af1-46b6-8992-c0e672536617",
    path: "/product/7c05fd41-8af1-46b6-8992-c0e672536617",
    meta: {"ID":"7c05fd41-8af1-46b6-8992-c0e672536617","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7c149483-59be-42a6-af2c-67fc83e7cb9a",
    path: "/product/7c149483-59be-42a6-af2c-67fc83e7cb9a",
    meta: {"ID":"7c149483-59be-42a6-af2c-67fc83e7cb9a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7c3ba142-2a44-4751-a8ef-a190eb0311f2",
    path: "/product/7c3ba142-2a44-4751-a8ef-a190eb0311f2",
    meta: {"ID":"7c3ba142-2a44-4751-a8ef-a190eb0311f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|7c73d350-0620-4f8d-aeba-d0fb32e16f88",
    path: "/product/7c73d350-0620-4f8d-aeba-d0fb32e16f88",
    meta: {"ID":"7c73d350-0620-4f8d-aeba-d0fb32e16f88","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7c77e3c3-cbac-4b5d-83aa-eb6935b9210a",
    path: "/product/7c77e3c3-cbac-4b5d-83aa-eb6935b9210a",
    meta: {"ID":"7c77e3c3-cbac-4b5d-83aa-eb6935b9210a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7c8ba0b8-80cc-467b-a6dc-4799754d8ca6",
    path: "/product/7c8ba0b8-80cc-467b-a6dc-4799754d8ca6",
    meta: {"ID":"7c8ba0b8-80cc-467b-a6dc-4799754d8ca6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7cc2abcc-2d99-4cc5-a09e-fc7cb7e1f680",
    path: "/product/7cc2abcc-2d99-4cc5-a09e-fc7cb7e1f680",
    meta: {"ID":"7cc2abcc-2d99-4cc5-a09e-fc7cb7e1f680","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|7ccf2a3f-8660-45ec-bbdc-738b1ff28953",
    path: "/product/7ccf2a3f-8660-45ec-bbdc-738b1ff28953",
    meta: {"ID":"7ccf2a3f-8660-45ec-bbdc-738b1ff28953","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7ce27810-1cae-411d-a859-447f17c5cb64",
    path: "/product/7ce27810-1cae-411d-a859-447f17c5cb64",
    meta: {"ID":"7ce27810-1cae-411d-a859-447f17c5cb64","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3c06d685-8171-454b-920a-f3a76b0a4226|7cf11ea7-178e-48a8-950e-427c56d758b2",
    path: "/product/7cf11ea7-178e-48a8-950e-427c56d758b2",
    meta: {"ID":"7cf11ea7-178e-48a8-950e-427c56d758b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7cf8a509-bc23-4b33-ba72-ae094a8968e0",
    path: "/product/7cf8a509-bc23-4b33-ba72-ae094a8968e0",
    meta: {"ID":"7cf8a509-bc23-4b33-ba72-ae094a8968e0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7d081779-d9d2-432f-87d8-c1690487d440",
    path: "/product/7d081779-d9d2-432f-87d8-c1690487d440",
    meta: {"ID":"7d081779-d9d2-432f-87d8-c1690487d440","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7d71d0db-bc18-4d6a-af9b-2ddeb5554e44",
    path: "/product/7d71d0db-bc18-4d6a-af9b-2ddeb5554e44",
    meta: {"ID":"7d71d0db-bc18-4d6a-af9b-2ddeb5554e44","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7d91e43e-f421-42bc-b1dd-3347196c36ec",
    path: "/product/7d91e43e-f421-42bc-b1dd-3347196c36ec",
    meta: {"ID":"7d91e43e-f421-42bc-b1dd-3347196c36ec","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7db28214-f8ea-49fc-8711-83dce06babf1",
    path: "/product/7db28214-f8ea-49fc-8711-83dce06babf1",
    meta: {"ID":"7db28214-f8ea-49fc-8711-83dce06babf1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|7dcecf25-ca27-46f0-a8d6-a7c43321d1cc",
    path: "/product/7dcecf25-ca27-46f0-a8d6-a7c43321d1cc",
    meta: {"ID":"7dcecf25-ca27-46f0-a8d6-a7c43321d1cc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|7e088e19-26d3-4d75-9f64-adfd2fd2b947",
    path: "/product/7e088e19-26d3-4d75-9f64-adfd2fd2b947",
    meta: {"ID":"7e088e19-26d3-4d75-9f64-adfd2fd2b947","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7e2a444a-ec97-4a51-beb5-be867b4b3ad8",
    path: "/product/7e2a444a-ec97-4a51-beb5-be867b4b3ad8",
    meta: {"ID":"7e2a444a-ec97-4a51-beb5-be867b4b3ad8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7e86fbba-6cdf-4b28-ae53-5c5f71b2f011",
    path: "/product/7e86fbba-6cdf-4b28-ae53-5c5f71b2f011",
    meta: {"ID":"7e86fbba-6cdf-4b28-ae53-5c5f71b2f011","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|af1ac0fd-a494-4b35-ae24-edc1fe10a7e4|7ed261f3-bc06-41dc-9ed4-553412f1f3b2",
    path: "/product/7ed261f3-bc06-41dc-9ed4-553412f1f3b2",
    meta: {"ID":"7ed261f3-bc06-41dc-9ed4-553412f1f3b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|7edde26c-a551-4fc4-aa48-19b249814f32",
    path: "/product/7edde26c-a551-4fc4-aa48-19b249814f32",
    meta: {"ID":"7edde26c-a551-4fc4-aa48-19b249814f32","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7ef3cb72-64ac-426c-960d-4b8bbb90d912",
    path: "/product/7ef3cb72-64ac-426c-960d-4b8bbb90d912",
    meta: {"ID":"7ef3cb72-64ac-426c-960d-4b8bbb90d912","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|7f250fc2-e2e7-4558-9bc1-d14c75908149",
    path: "/product/7f250fc2-e2e7-4558-9bc1-d14c75908149",
    meta: {"ID":"7f250fc2-e2e7-4558-9bc1-d14c75908149","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7f2d86f2-48ca-4a6b-b02f-45015544c71b",
    path: "/product/7f2d86f2-48ca-4a6b-b02f-45015544c71b",
    meta: {"ID":"7f2d86f2-48ca-4a6b-b02f-45015544c71b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|7f44eb90-60d9-45b4-b4ef-01176f372cd2",
    path: "/product/7f44eb90-60d9-45b4-b4ef-01176f372cd2",
    meta: {"ID":"7f44eb90-60d9-45b4-b4ef-01176f372cd2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7f689f05-138b-4843-af4f-5f9af9e83d81",
    path: "/product/7f689f05-138b-4843-af4f-5f9af9e83d81",
    meta: {"ID":"7f689f05-138b-4843-af4f-5f9af9e83d81","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7f699f94-4a86-4726-a806-92b1f7f61e8c",
    path: "/product/7f699f94-4a86-4726-a806-92b1f7f61e8c",
    meta: {"ID":"7f699f94-4a86-4726-a806-92b1f7f61e8c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|7f7bc05d-0db2-4794-9347-18aea4fd27ba",
    path: "/product/7f7bc05d-0db2-4794-9347-18aea4fd27ba",
    meta: {"ID":"7f7bc05d-0db2-4794-9347-18aea4fd27ba","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7f878e20-ec7a-4046-a5c0-41a82729e0fc",
    path: "/product/7f878e20-ec7a-4046-a5c0-41a82729e0fc",
    meta: {"ID":"7f878e20-ec7a-4046-a5c0-41a82729e0fc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1a496364-a63b-4683-aa31-12d5196add62|7f8a0694-da6c-4814-894a-6debfc0411a2",
    path: "/product/7f8a0694-da6c-4814-894a-6debfc0411a2",
    meta: {"ID":"7f8a0694-da6c-4814-894a-6debfc0411a2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4089a20c-7c4e-410d-9a00-8bbac0d71cc8|7f8bdedf-fef7-4d26-b671-c3b5e28b9862",
    path: "/product/7f8bdedf-fef7-4d26-b671-c3b5e28b9862",
    meta: {"ID":"7f8bdedf-fef7-4d26-b671-c3b5e28b9862","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7f991b79-3441-46d1-9b56-66f8aa1d3723",
    path: "/product/7f991b79-3441-46d1-9b56-66f8aa1d3723",
    meta: {"ID":"7f991b79-3441-46d1-9b56-66f8aa1d3723","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|7fbc0c78-ba7a-49f9-9e3e-5a613598e53b",
    path: "/product/7fbc0c78-ba7a-49f9-9e3e-5a613598e53b",
    meta: {"ID":"7fbc0c78-ba7a-49f9-9e3e-5a613598e53b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|7fbc53e6-5336-4346-b887-8a89cc8bddbd",
    path: "/product/7fbc53e6-5336-4346-b887-8a89cc8bddbd",
    meta: {"ID":"7fbc53e6-5336-4346-b887-8a89cc8bddbd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|7fc5dbb7-ab38-42a0-90d9-2d1e451d8a75",
    path: "/product/7fc5dbb7-ab38-42a0-90d9-2d1e451d8a75",
    meta: {"ID":"7fc5dbb7-ab38-42a0-90d9-2d1e451d8a75","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7fc85ede-53e5-4aeb-b395-4e179de1fbe6",
    path: "/product/7fc85ede-53e5-4aeb-b395-4e179de1fbe6",
    meta: {"ID":"7fc85ede-53e5-4aeb-b395-4e179de1fbe6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|7fd34724-334d-49bf-9f84-f642472f8dc3",
    path: "/product/7fd34724-334d-49bf-9f84-f642472f8dc3",
    meta: {"ID":"7fd34724-334d-49bf-9f84-f642472f8dc3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|7fd89b5c-88f4-4722-b211-72b2c9e26a76",
    path: "/product/7fd89b5c-88f4-4722-b211-72b2c9e26a76",
    meta: {"ID":"7fd89b5c-88f4-4722-b211-72b2c9e26a76","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|735f10a8-dcc4-47d5-9639-e55e0af97024|7feee6c8-d0b7-4d90-9fe7-9d2cac513902",
    path: "/product/7feee6c8-d0b7-4d90-9fe7-9d2cac513902",
    meta: {"ID":"7feee6c8-d0b7-4d90-9fe7-9d2cac513902","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7ff12f41-1c0d-4cd2-ae3e-27deada4a153",
    path: "/product/7ff12f41-1c0d-4cd2-ae3e-27deada4a153",
    meta: {"ID":"7ff12f41-1c0d-4cd2-ae3e-27deada4a153","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|7ff84a9c-97ae-4c32-a91f-a04593fa8826",
    path: "/product/7ff84a9c-97ae-4c32-a91f-a04593fa8826",
    meta: {"ID":"7ff84a9c-97ae-4c32-a91f-a04593fa8826","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8003db02-2046-4d83-845c-c4256f44a4bd",
    path: "/product/8003db02-2046-4d83-845c-c4256f44a4bd",
    meta: {"ID":"8003db02-2046-4d83-845c-c4256f44a4bd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|800d8738-d744-466f-8d2c-1fc3d46274fe",
    path: "/product/800d8738-d744-466f-8d2c-1fc3d46274fe",
    meta: {"ID":"800d8738-d744-466f-8d2c-1fc3d46274fe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8024dd85-ab50-41e4-ab4f-ef89206554f8",
    path: "/product/8024dd85-ab50-41e4-ab4f-ef89206554f8",
    meta: {"ID":"8024dd85-ab50-41e4-ab4f-ef89206554f8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|53b9bd6b-ecab-409e-b3e8-22b81c702f83|803fb015-36dc-41d3-83a0-7ce47d6586a0",
    path: "/product/803fb015-36dc-41d3-83a0-7ce47d6586a0",
    meta: {"ID":"803fb015-36dc-41d3-83a0-7ce47d6586a0","Title":"","GroupID":"53b9bd6b-ecab-409e-b3e8-22b81c702f83","GroupTitle":"Паста и Ризотто","GroupLink":"/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|8041715f-624e-4c32-9973-37364562c8d7",
    path: "/product/8041715f-624e-4c32-9973-37364562c8d7",
    meta: {"ID":"8041715f-624e-4c32-9973-37364562c8d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|8065d635-1857-475f-955a-94093fb639b3",
    path: "/product/8065d635-1857-475f-955a-94093fb639b3",
    meta: {"ID":"8065d635-1857-475f-955a-94093fb639b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|8076cd54-c008-42e3-9daa-346414422fb1",
    path: "/product/8076cd54-c008-42e3-9daa-346414422fb1",
    meta: {"ID":"8076cd54-c008-42e3-9daa-346414422fb1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|80792b5c-3e22-44ea-86ae-c8cc5cb754a5",
    path: "/product/80792b5c-3e22-44ea-86ae-c8cc5cb754a5",
    meta: {"ID":"80792b5c-3e22-44ea-86ae-c8cc5cb754a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|80c181e4-6a75-4aef-9330-cce521ee12f4",
    path: "/product/80c181e4-6a75-4aef-9330-cce521ee12f4",
    meta: {"ID":"80c181e4-6a75-4aef-9330-cce521ee12f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|80d179ff-80bb-454a-a0a1-884fdefc2d47",
    path: "/product/80d179ff-80bb-454a-a0a1-884fdefc2d47",
    meta: {"ID":"80d179ff-80bb-454a-a0a1-884fdefc2d47","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|80fcced9-39f3-4bb6-bf7f-5521b79539ca",
    path: "/product/80fcced9-39f3-4bb6-bf7f-5521b79539ca",
    meta: {"ID":"80fcced9-39f3-4bb6-bf7f-5521b79539ca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|80ffcfb0-27e6-4c31-9175-5e54eedd5701",
    path: "/product/80ffcfb0-27e6-4c31-9175-5e54eedd5701",
    meta: {"ID":"80ffcfb0-27e6-4c31-9175-5e54eedd5701","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|812464da-cf04-4142-bd1c-f6e188d995b4",
    path: "/product/812464da-cf04-4142-bd1c-f6e188d995b4",
    meta: {"ID":"812464da-cf04-4142-bd1c-f6e188d995b4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8132f562-8396-4871-963c-f719ad09de24",
    path: "/product/8132f562-8396-4871-963c-f719ad09de24",
    meta: {"ID":"8132f562-8396-4871-963c-f719ad09de24","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|81634354-3eb6-475b-9f31-afca36018557",
    path: "/product/81634354-3eb6-475b-9f31-afca36018557",
    meta: {"ID":"81634354-3eb6-475b-9f31-afca36018557","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|818acc2a-f4c2-4de3-9571-2dcc89275ba1",
    path: "/product/818acc2a-f4c2-4de3-9571-2dcc89275ba1",
    meta: {"ID":"818acc2a-f4c2-4de3-9571-2dcc89275ba1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|81a93551-31a6-4df7-8802-cda71b5c6435",
    path: "/product/81a93551-31a6-4df7-8802-cda71b5c6435",
    meta: {"ID":"81a93551-31a6-4df7-8802-cda71b5c6435","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|81cdec2c-d96b-441e-b3c7-f7e768e509d8",
    path: "/product/81cdec2c-d96b-441e-b3c7-f7e768e509d8",
    meta: {"ID":"81cdec2c-d96b-441e-b3c7-f7e768e509d8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|81da611e-2a75-43d0-806f-18d1c7cac715",
    path: "/product/81da611e-2a75-43d0-806f-18d1c7cac715",
    meta: {"ID":"81da611e-2a75-43d0-806f-18d1c7cac715","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|81e1c2ab-162c-47e0-953b-913bc5b9e477",
    path: "/product/81e1c2ab-162c-47e0-953b-913bc5b9e477",
    meta: {"ID":"81e1c2ab-162c-47e0-953b-913bc5b9e477","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca23e9-81fa-4648-a2f2-397fc639baa0|81f837c5-4a14-4472-a2c8-f6f6e4530c7c",
    path: "/product/81f837c5-4a14-4472-a2c8-f6f6e4530c7c",
    meta: {"ID":"81f837c5-4a14-4472-a2c8-f6f6e4530c7c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|81fb3dd7-a8bb-4782-bd85-371c901e7119",
    path: "/product/81fb3dd7-a8bb-4782-bd85-371c901e7119",
    meta: {"ID":"81fb3dd7-a8bb-4782-bd85-371c901e7119","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|821d0715-5c7c-46ab-a6a8-13666daef04c",
    path: "/product/821d0715-5c7c-46ab-a6a8-13666daef04c",
    meta: {"ID":"821d0715-5c7c-46ab-a6a8-13666daef04c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c5e2a83-ebb6-41d5-8b1b-ece7327e08df|82286272-81e1-40a8-90b5-64629818ddc8",
    path: "/product/82286272-81e1-40a8-90b5-64629818ddc8",
    meta: {"ID":"82286272-81e1-40a8-90b5-64629818ddc8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|823900d1-0cef-4c28-8146-acf8fcb810d3",
    path: "/product/823900d1-0cef-4c28-8146-acf8fcb810d3",
    meta: {"ID":"823900d1-0cef-4c28-8146-acf8fcb810d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1519fe15-47e1-44ff-8747-6714ca96f7aa|824cbe53-651a-47e4-af9b-d1b61506223f",
    path: "/product/824cbe53-651a-47e4-af9b-d1b61506223f",
    meta: {"ID":"824cbe53-651a-47e4-af9b-d1b61506223f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|8299868e-181a-49e8-8441-9608f0224ab2",
    path: "/product/8299868e-181a-49e8-8441-9608f0224ab2",
    meta: {"ID":"8299868e-181a-49e8-8441-9608f0224ab2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|82da4fe7-1a3b-4bab-96de-6e7a5ecf22af",
    path: "/product/82da4fe7-1a3b-4bab-96de-6e7a5ecf22af",
    meta: {"ID":"82da4fe7-1a3b-4bab-96de-6e7a5ecf22af","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|830dcd9a-7cd7-46fb-8ba5-bf767da05dd8",
    path: "/product/830dcd9a-7cd7-46fb-8ba5-bf767da05dd8",
    meta: {"ID":"830dcd9a-7cd7-46fb-8ba5-bf767da05dd8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8324fd0c-fbb0-4bfd-972e-c71f86db6451",
    path: "/product/8324fd0c-fbb0-4bfd-972e-c71f86db6451",
    meta: {"ID":"8324fd0c-fbb0-4bfd-972e-c71f86db6451","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8325afe7-70e4-4993-b649-e21344216f0d",
    path: "/product/8325afe7-70e4-4993-b649-e21344216f0d",
    meta: {"ID":"8325afe7-70e4-4993-b649-e21344216f0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|834c0db5-82e4-45fe-ae85-090fb5049325",
    path: "/product/834c0db5-82e4-45fe-ae85-090fb5049325",
    meta: {"ID":"834c0db5-82e4-45fe-ae85-090fb5049325","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|836773d4-ec89-40ce-902d-163be5ea629a",
    path: "/product/836773d4-ec89-40ce-902d-163be5ea629a",
    meta: {"ID":"836773d4-ec89-40ce-902d-163be5ea629a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|837d7057-802f-4296-a85f-24a588c1bd02",
    path: "/product/837d7057-802f-4296-a85f-24a588c1bd02",
    meta: {"ID":"837d7057-802f-4296-a85f-24a588c1bd02","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|8382a310-3d5a-48db-b201-f261cf620cca",
    path: "/product/8382a310-3d5a-48db-b201-f261cf620cca",
    meta: {"ID":"8382a310-3d5a-48db-b201-f261cf620cca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|838599e8-80cc-488c-93d4-f4b226f91795",
    path: "/product/838599e8-80cc-488c-93d4-f4b226f91795",
    meta: {"ID":"838599e8-80cc-488c-93d4-f4b226f91795","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|838aed20-ce34-4471-923b-44aa657273c6",
    path: "/product/838aed20-ce34-4471-923b-44aa657273c6",
    meta: {"ID":"838aed20-ce34-4471-923b-44aa657273c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|83a1bd43-383d-4e8b-a3f5-65ab4aaf185d",
    path: "/product/83a1bd43-383d-4e8b-a3f5-65ab4aaf185d",
    meta: {"ID":"83a1bd43-383d-4e8b-a3f5-65ab4aaf185d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|83afbbdf-7234-4c86-8882-d341ee694715",
    path: "/product/83afbbdf-7234-4c86-8882-d341ee694715",
    meta: {"ID":"83afbbdf-7234-4c86-8882-d341ee694715","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|d586fdfe-fc1e-4037-89d8-4ebed69e1496|83baba53-8ef5-4f43-91c7-fa84f27481a0",
    path: "/product/83baba53-8ef5-4f43-91c7-fa84f27481a0",
    meta: {"ID":"83baba53-8ef5-4f43-91c7-fa84f27481a0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|83c5f460-b8d1-4d2b-8276-d0fc63271add",
    path: "/product/83c5f460-b8d1-4d2b-8276-d0fc63271add",
    meta: {"ID":"83c5f460-b8d1-4d2b-8276-d0fc63271add","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|83e3e453-57b6-4fa0-80a0-df29e3c793e9",
    path: "/product/83e3e453-57b6-4fa0-80a0-df29e3c793e9",
    meta: {"ID":"83e3e453-57b6-4fa0-80a0-df29e3c793e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|8402cc7a-4614-45bf-a3e7-058627897d78",
    path: "/product/8402cc7a-4614-45bf-a3e7-058627897d78",
    meta: {"ID":"8402cc7a-4614-45bf-a3e7-058627897d78","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|84140324-9c9c-4e06-ab26-39795cf9c838",
    path: "/product/84140324-9c9c-4e06-ab26-39795cf9c838",
    meta: {"ID":"84140324-9c9c-4e06-ab26-39795cf9c838","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|841c12f4-9cf8-4bd4-8d2c-7217f8e7c29a",
    path: "/product/841c12f4-9cf8-4bd4-8d2c-7217f8e7c29a",
    meta: {"ID":"841c12f4-9cf8-4bd4-8d2c-7217f8e7c29a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a77b60ce-796c-4cfb-a81f-523c73aff39d|84375135-5961-4793-a20a-28e4e1f1d94a",
    path: "/product/84375135-5961-4793-a20a-28e4e1f1d94a",
    meta: {"ID":"84375135-5961-4793-a20a-28e4e1f1d94a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6fa548ca-4b6a-4806-83da-74148d7bfb94|8437a96d-bade-41f0-beb2-e31310335af7",
    path: "/product/8437a96d-bade-41f0-beb2-e31310335af7",
    meta: {"ID":"8437a96d-bade-41f0-beb2-e31310335af7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|843cc0df-5580-49b4-800f-57bbc047893e",
    path: "/product/843cc0df-5580-49b4-800f-57bbc047893e",
    meta: {"ID":"843cc0df-5580-49b4-800f-57bbc047893e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|845508d7-7ef9-4392-a1b0-8af3ee36e5a5",
    path: "/product/845508d7-7ef9-4392-a1b0-8af3ee36e5a5",
    meta: {"ID":"845508d7-7ef9-4392-a1b0-8af3ee36e5a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|8461fdda-d2ea-4622-9690-b3301fc2b377",
    path: "/product/8461fdda-d2ea-4622-9690-b3301fc2b377",
    meta: {"ID":"8461fdda-d2ea-4622-9690-b3301fc2b377","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|84774528-0b56-4cba-9a2f-ce99ea389d63",
    path: "/product/84774528-0b56-4cba-9a2f-ce99ea389d63",
    meta: {"ID":"84774528-0b56-4cba-9a2f-ce99ea389d63","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|848fcd94-bbfe-4b5a-a431-547477463ce2",
    path: "/product/848fcd94-bbfe-4b5a-a431-547477463ce2",
    meta: {"ID":"848fcd94-bbfe-4b5a-a431-547477463ce2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8496a1db-97a2-4c26-8a0c-cb5540515dbe",
    path: "/product/8496a1db-97a2-4c26-8a0c-cb5540515dbe",
    meta: {"ID":"8496a1db-97a2-4c26-8a0c-cb5540515dbe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|84b56c90-7166-4d78-ad49-366a64a21ed3",
    path: "/product/84b56c90-7166-4d78-ad49-366a64a21ed3",
    meta: {"ID":"84b56c90-7166-4d78-ad49-366a64a21ed3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|84be8646-0b6a-415e-b3c0-b3d5ce80f8ff",
    path: "/product/84be8646-0b6a-415e-b3c0-b3d5ce80f8ff",
    meta: {"ID":"84be8646-0b6a-415e-b3c0-b3d5ce80f8ff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|84e464af-70f6-4627-a8d9-4810a48040af",
    path: "/product/84e464af-70f6-4627-a8d9-4810a48040af",
    meta: {"ID":"84e464af-70f6-4627-a8d9-4810a48040af","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|850d76e7-1938-43af-b15e-c6a701559ff6",
    path: "/product/850d76e7-1938-43af-b15e-c6a701559ff6",
    meta: {"ID":"850d76e7-1938-43af-b15e-c6a701559ff6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|8527942b-693a-4d71-b7ad-6b4d40449dd6",
    path: "/product/8527942b-693a-4d71-b7ad-6b4d40449dd6",
    meta: {"ID":"8527942b-693a-4d71-b7ad-6b4d40449dd6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|853060bd-b516-43f2-af2a-39ff4d931fbe",
    path: "/product/853060bd-b516-43f2-af2a-39ff4d931fbe",
    meta: {"ID":"853060bd-b516-43f2-af2a-39ff4d931fbe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|853a5843-24e0-4d54-bcfb-e00b2eedb749",
    path: "/product/853a5843-24e0-4d54-bcfb-e00b2eedb749",
    meta: {"ID":"853a5843-24e0-4d54-bcfb-e00b2eedb749","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|855642cd-8e4f-4e14-ab14-6482140860b2",
    path: "/product/855642cd-8e4f-4e14-ab14-6482140860b2",
    meta: {"ID":"855642cd-8e4f-4e14-ab14-6482140860b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|85574f91-3977-4904-a2f0-8048a94cdf40",
    path: "/product/85574f91-3977-4904-a2f0-8048a94cdf40",
    meta: {"ID":"85574f91-3977-4904-a2f0-8048a94cdf40","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|855c24b6-5010-42e4-bcbd-d5b7d0372731",
    path: "/product/855c24b6-5010-42e4-bcbd-d5b7d0372731",
    meta: {"ID":"855c24b6-5010-42e4-bcbd-d5b7d0372731","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|855dbf1e-3cda-4706-939d-0e3a8f48d486",
    path: "/product/855dbf1e-3cda-4706-939d-0e3a8f48d486",
    meta: {"ID":"855dbf1e-3cda-4706-939d-0e3a8f48d486","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|857c007a-e306-484c-8dbc-204b26607af1",
    path: "/product/857c007a-e306-484c-8dbc-204b26607af1",
    meta: {"ID":"857c007a-e306-484c-8dbc-204b26607af1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|858842ac-5f7c-4b43-b159-ff877096f9b1",
    path: "/product/858842ac-5f7c-4b43-b159-ff877096f9b1",
    meta: {"ID":"858842ac-5f7c-4b43-b159-ff877096f9b1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|859585fc-10be-4b62-8ea2-918502095f1a",
    path: "/product/859585fc-10be-4b62-8ea2-918502095f1a",
    meta: {"ID":"859585fc-10be-4b62-8ea2-918502095f1a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|85c47ff4-b12c-4923-8fb2-f0fbe83f261d",
    path: "/product/85c47ff4-b12c-4923-8fb2-f0fbe83f261d",
    meta: {"ID":"85c47ff4-b12c-4923-8fb2-f0fbe83f261d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|85de573f-6dd0-4919-bce3-36e5643e65f4",
    path: "/product/85de573f-6dd0-4919-bce3-36e5643e65f4",
    meta: {"ID":"85de573f-6dd0-4919-bce3-36e5643e65f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6650365f-b288-4159-8f19-1721fee02f23|85ec7873-561e-464c-9a07-2108bd9ae609",
    path: "/product/85ec7873-561e-464c-9a07-2108bd9ae609",
    meta: {"ID":"85ec7873-561e-464c-9a07-2108bd9ae609","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|85f1e46e-4544-4f41-a88f-0d2858e37b53",
    path: "/product/85f1e46e-4544-4f41-a88f-0d2858e37b53",
    meta: {"ID":"85f1e46e-4544-4f41-a88f-0d2858e37b53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|86162be0-8100-4db4-95d1-29f8ad77275f",
    path: "/product/86162be0-8100-4db4-95d1-29f8ad77275f",
    meta: {"ID":"86162be0-8100-4db4-95d1-29f8ad77275f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8625f781-abde-4696-b827-d8f5b02a6402",
    path: "/product/8625f781-abde-4696-b827-d8f5b02a6402",
    meta: {"ID":"8625f781-abde-4696-b827-d8f5b02a6402","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|86443649-8048-4318-abce-e2dfd6a39239",
    path: "/product/86443649-8048-4318-abce-e2dfd6a39239",
    meta: {"ID":"86443649-8048-4318-abce-e2dfd6a39239","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|86453511-b6eb-456a-b12a-ce9d31925798",
    path: "/product/86453511-b6eb-456a-b12a-ce9d31925798",
    meta: {"ID":"86453511-b6eb-456a-b12a-ce9d31925798","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|864e0632-9466-4f53-b768-d59375c95636",
    path: "/product/864e0632-9466-4f53-b768-d59375c95636",
    meta: {"ID":"864e0632-9466-4f53-b768-d59375c95636","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|735f10a8-dcc4-47d5-9639-e55e0af97024|8653727a-3605-4e04-9823-f48d99078779",
    path: "/product/8653727a-3605-4e04-9823-f48d99078779",
    meta: {"ID":"8653727a-3605-4e04-9823-f48d99078779","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8657dea3-d14a-49b3-8a92-390e33017a21",
    path: "/product/8657dea3-d14a-49b3-8a92-390e33017a21",
    meta: {"ID":"8657dea3-d14a-49b3-8a92-390e33017a21","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|8667dec7-4e73-4e41-b7d4-2d00ee435167",
    path: "/product/8667dec7-4e73-4e41-b7d4-2d00ee435167",
    meta: {"ID":"8667dec7-4e73-4e41-b7d4-2d00ee435167","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|866f09cd-8cf9-465f-a12f-fe643ace5d94",
    path: "/product/866f09cd-8cf9-465f-a12f-fe643ace5d94",
    meta: {"ID":"866f09cd-8cf9-465f-a12f-fe643ace5d94","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|8671a816-914f-4917-a175-adf575756be4",
    path: "/product/8671a816-914f-4917-a175-adf575756be4",
    meta: {"ID":"8671a816-914f-4917-a175-adf575756be4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|8676942c-7173-4a30-aa24-f73c24d1dbba",
    path: "/product/8676942c-7173-4a30-aa24-f73c24d1dbba",
    meta: {"ID":"8676942c-7173-4a30-aa24-f73c24d1dbba","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|86a70909-8473-4d40-831c-6da9ff138426",
    path: "/product/86a70909-8473-4d40-831c-6da9ff138426",
    meta: {"ID":"86a70909-8473-4d40-831c-6da9ff138426","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|86bb096d-3657-45e2-a1b1-00e12b8048f8",
    path: "/product/86bb096d-3657-45e2-a1b1-00e12b8048f8",
    meta: {"ID":"86bb096d-3657-45e2-a1b1-00e12b8048f8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|86c19b97-7f69-484c-a0f1-32bdb56e09de",
    path: "/product/86c19b97-7f69-484c-a0f1-32bdb56e09de",
    meta: {"ID":"86c19b97-7f69-484c-a0f1-32bdb56e09de","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|86cc1f34-b063-446f-83f7-62a1674f6fe6",
    path: "/product/86cc1f34-b063-446f-83f7-62a1674f6fe6",
    meta: {"ID":"86cc1f34-b063-446f-83f7-62a1674f6fe6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e2a1e7d9-7ca7-4450-84e5-be47dd4e74a5|86ebc133-171c-4c81-80cd-1fba82f86c5c",
    path: "/product/86ebc133-171c-4c81-80cd-1fba82f86c5c",
    meta: {"ID":"86ebc133-171c-4c81-80cd-1fba82f86c5c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|872f7f84-6c5d-4ee6-bc23-9bc647b99c68",
    path: "/product/872f7f84-6c5d-4ee6-bc23-9bc647b99c68",
    meta: {"ID":"872f7f84-6c5d-4ee6-bc23-9bc647b99c68","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|8744f73a-aadd-49c5-97c1-c31c5c2762a6",
    path: "/product/8744f73a-aadd-49c5-97c1-c31c5c2762a6",
    meta: {"ID":"8744f73a-aadd-49c5-97c1-c31c5c2762a6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6650365f-b288-4159-8f19-1721fee02f23|874fb91d-a35b-4088-8e0d-36a3b509dbd0",
    path: "/product/874fb91d-a35b-4088-8e0d-36a3b509dbd0",
    meta: {"ID":"874fb91d-a35b-4088-8e0d-36a3b509dbd0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|877c55ac-96b1-4d4c-baa8-f4496b7cb984",
    path: "/product/877c55ac-96b1-4d4c-baa8-f4496b7cb984",
    meta: {"ID":"877c55ac-96b1-4d4c-baa8-f4496b7cb984","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|877c627d-6c33-4b0d-94be-d75d1319c9fa",
    path: "/product/877c627d-6c33-4b0d-94be-d75d1319c9fa",
    meta: {"ID":"877c627d-6c33-4b0d-94be-d75d1319c9fa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|87ff1385-9007-4408-9901-3c1e72dd06eb",
    path: "/product/87ff1385-9007-4408-9901-3c1e72dd06eb",
    meta: {"ID":"87ff1385-9007-4408-9901-3c1e72dd06eb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|887ab9b1-6683-48f3-bc9e-cd179dcbb0e7",
    path: "/product/887ab9b1-6683-48f3-bc9e-cd179dcbb0e7",
    meta: {"ID":"887ab9b1-6683-48f3-bc9e-cd179dcbb0e7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|8889a2cc-336a-4f28-ae16-ef801e224e7a",
    path: "/product/8889a2cc-336a-4f28-ae16-ef801e224e7a",
    meta: {"ID":"8889a2cc-336a-4f28-ae16-ef801e224e7a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|88964484-809d-44d6-9178-3e075aff43ac",
    path: "/product/88964484-809d-44d6-9178-3e075aff43ac",
    meta: {"ID":"88964484-809d-44d6-9178-3e075aff43ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8898add2-7a00-496e-ad47-5f0945eae4bc",
    path: "/product/8898add2-7a00-496e-ad47-5f0945eae4bc",
    meta: {"ID":"8898add2-7a00-496e-ad47-5f0945eae4bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|88b31426-ff98-4dae-8988-81479302bd41",
    path: "/product/88b31426-ff98-4dae-8988-81479302bd41",
    meta: {"ID":"88b31426-ff98-4dae-8988-81479302bd41","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|88c8225e-ef93-459b-b714-de901bb6e383",
    path: "/product/88c8225e-ef93-459b-b714-de901bb6e383",
    meta: {"ID":"88c8225e-ef93-459b-b714-de901bb6e383","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|88cdc1e7-b9b3-4ee6-b920-28b2abec1a6d",
    path: "/product/88cdc1e7-b9b3-4ee6-b920-28b2abec1a6d",
    meta: {"ID":"88cdc1e7-b9b3-4ee6-b920-28b2abec1a6d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|88e34ccf-d5b1-432f-aab8-d638263e66ef",
    path: "/product/88e34ccf-d5b1-432f-aab8-d638263e66ef",
    meta: {"ID":"88e34ccf-d5b1-432f-aab8-d638263e66ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|88fdd173-40d4-43bf-b53b-d8a1a0f8a80f",
    path: "/product/88fdd173-40d4-43bf-b53b-d8a1a0f8a80f",
    meta: {"ID":"88fdd173-40d4-43bf-b53b-d8a1a0f8a80f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|8901c95a-7508-4f67-ade3-b697914c0fb0",
    path: "/product/8901c95a-7508-4f67-ade3-b697914c0fb0",
    meta: {"ID":"8901c95a-7508-4f67-ade3-b697914c0fb0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|89043f8c-3c54-4959-8eab-6e35fa46f120",
    path: "/product/89043f8c-3c54-4959-8eab-6e35fa46f120",
    meta: {"ID":"89043f8c-3c54-4959-8eab-6e35fa46f120","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8905b6a2-b879-4be9-8bd4-a5025a4a8b27",
    path: "/product/8905b6a2-b879-4be9-8bd4-a5025a4a8b27",
    meta: {"ID":"8905b6a2-b879-4be9-8bd4-a5025a4a8b27","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8924fa73-4db7-42fd-92a0-bff3bda3b730",
    path: "/product/8924fa73-4db7-42fd-92a0-bff3bda3b730",
    meta: {"ID":"8924fa73-4db7-42fd-92a0-bff3bda3b730","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|893cab97-4acd-46f9-ab72-258fcca772ee",
    path: "/product/893cab97-4acd-46f9-ab72-258fcca772ee",
    meta: {"ID":"893cab97-4acd-46f9-ab72-258fcca772ee","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|896f1441-5c06-4ee0-a878-1a8e673ad44c",
    path: "/product/896f1441-5c06-4ee0-a878-1a8e673ad44c",
    meta: {"ID":"896f1441-5c06-4ee0-a878-1a8e673ad44c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|899cfe6b-ce4d-4069-9fde-d30499bb6004",
    path: "/product/899cfe6b-ce4d-4069-9fde-d30499bb6004",
    meta: {"ID":"899cfe6b-ce4d-4069-9fde-d30499bb6004","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|89dea5fc-841e-4754-a086-908162b08d50",
    path: "/product/89dea5fc-841e-4754-a086-908162b08d50",
    meta: {"ID":"89dea5fc-841e-4754-a086-908162b08d50","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|89e0756f-12bf-4a0f-b1aa-320ad62eaa26",
    path: "/product/89e0756f-12bf-4a0f-b1aa-320ad62eaa26",
    meta: {"ID":"89e0756f-12bf-4a0f-b1aa-320ad62eaa26","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|89fa01c4-dbcf-4f91-be3f-967dd96e929a",
    path: "/product/89fa01c4-dbcf-4f91-be3f-967dd96e929a",
    meta: {"ID":"89fa01c4-dbcf-4f91-be3f-967dd96e929a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|89fc0996-5608-4f44-8229-12c6adb8afe6",
    path: "/product/89fc0996-5608-4f44-8229-12c6adb8afe6",
    meta: {"ID":"89fc0996-5608-4f44-8229-12c6adb8afe6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|89fe7c6d-64e0-4acc-a4cf-de4e229b4b1f",
    path: "/product/89fe7c6d-64e0-4acc-a4cf-de4e229b4b1f",
    meta: {"ID":"89fe7c6d-64e0-4acc-a4cf-de4e229b4b1f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|8a3ab248-b83e-4e14-8a63-b8a4d35b087f",
    path: "/product/8a3ab248-b83e-4e14-8a63-b8a4d35b087f",
    meta: {"ID":"8a3ab248-b83e-4e14-8a63-b8a4d35b087f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|8a3b0b63-1dc4-49c6-adde-2fe8fbdc65a9",
    path: "/product/8a3b0b63-1dc4-49c6-adde-2fe8fbdc65a9",
    meta: {"ID":"8a3b0b63-1dc4-49c6-adde-2fe8fbdc65a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|8a4410f5-7dbd-4bcb-b3c9-92c31c6fefa9",
    path: "/product/8a4410f5-7dbd-4bcb-b3c9-92c31c6fefa9",
    meta: {"ID":"8a4410f5-7dbd-4bcb-b3c9-92c31c6fefa9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8a4f2cfe-ab29-428b-ae2f-18b29369463a",
    path: "/product/8a4f2cfe-ab29-428b-ae2f-18b29369463a",
    meta: {"ID":"8a4f2cfe-ab29-428b-ae2f-18b29369463a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8a626121-daf2-40c9-8b38-a82a297962af",
    path: "/product/8a626121-daf2-40c9-8b38-a82a297962af",
    meta: {"ID":"8a626121-daf2-40c9-8b38-a82a297962af","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8a7dc8bb-0284-4804-aeb2-3ea1139d09ba",
    path: "/product/8a7dc8bb-0284-4804-aeb2-3ea1139d09ba",
    meta: {"ID":"8a7dc8bb-0284-4804-aeb2-3ea1139d09ba","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|8a7f5a85-2905-475e-9030-9bfa564b805c",
    path: "/product/8a7f5a85-2905-475e-9030-9bfa564b805c",
    meta: {"ID":"8a7f5a85-2905-475e-9030-9bfa564b805c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8aac4edd-9821-4578-b8c0-58de5f844913",
    path: "/product/8aac4edd-9821-4578-b8c0-58de5f844913",
    meta: {"ID":"8aac4edd-9821-4578-b8c0-58de5f844913","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|8abb8562-1c3e-488c-8220-9847bb435109",
    path: "/product/8abb8562-1c3e-488c-8220-9847bb435109",
    meta: {"ID":"8abb8562-1c3e-488c-8220-9847bb435109","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|8ac0ed25-1a93-4da9-b361-9f7f0c4d335d",
    path: "/product/8ac0ed25-1a93-4da9-b361-9f7f0c4d335d",
    meta: {"ID":"8ac0ed25-1a93-4da9-b361-9f7f0c4d335d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8addac9f-dc5b-4929-9a99-12f31f7d98c0",
    path: "/product/8addac9f-dc5b-4929-9a99-12f31f7d98c0",
    meta: {"ID":"8addac9f-dc5b-4929-9a99-12f31f7d98c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8af0491c-a69c-4fc9-a0ca-a72485b4f4e4",
    path: "/product/8af0491c-a69c-4fc9-a0ca-a72485b4f4e4",
    meta: {"ID":"8af0491c-a69c-4fc9-a0ca-a72485b4f4e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8b07492f-f488-452d-86cc-d213b179bc0c",
    path: "/product/8b07492f-f488-452d-86cc-d213b179bc0c",
    meta: {"ID":"8b07492f-f488-452d-86cc-d213b179bc0c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8b132306-9a74-45cd-adc2-b361f113b265",
    path: "/product/8b132306-9a74-45cd-adc2-b361f113b265",
    meta: {"ID":"8b132306-9a74-45cd-adc2-b361f113b265","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|8b139ae7-5837-49a5-8e15-12d15a467ba3",
    path: "/product/8b139ae7-5837-49a5-8e15-12d15a467ba3",
    meta: {"ID":"8b139ae7-5837-49a5-8e15-12d15a467ba3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8b259c97-b8e6-433c-983a-d3e6e868923b",
    path: "/product/8b259c97-b8e6-433c-983a-d3e6e868923b",
    meta: {"ID":"8b259c97-b8e6-433c-983a-d3e6e868923b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4626112c-772c-43a4-83b9-fc5d6a1a2597|8b7a8ee0-7b4a-4827-8c4f-8bab2fb0ea2e",
    path: "/product/8b7a8ee0-7b4a-4827-8c4f-8bab2fb0ea2e",
    meta: {"ID":"8b7a8ee0-7b4a-4827-8c4f-8bab2fb0ea2e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8b9bf4fd-c389-4840-b3fb-7bcc88caff52",
    path: "/product/8b9bf4fd-c389-4840-b3fb-7bcc88caff52",
    meta: {"ID":"8b9bf4fd-c389-4840-b3fb-7bcc88caff52","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73cec509-3358-485d-8d8e-5484c8bb8f78|8bd7440e-9b30-4a73-8fa6-01e5133523f1",
    path: "/product/8bd7440e-9b30-4a73-8fa6-01e5133523f1",
    meta: {"ID":"8bd7440e-9b30-4a73-8fa6-01e5133523f1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|8c1ff16c-5e7d-4c27-bbb8-94baa46ed808",
    path: "/product/8c1ff16c-5e7d-4c27-bbb8-94baa46ed808",
    meta: {"ID":"8c1ff16c-5e7d-4c27-bbb8-94baa46ed808","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6cbf9c41-8e9c-4704-af3f-382f792c10c4|8c2dd373-420a-424f-99be-6469bb385e53",
    path: "/product/8c2dd373-420a-424f-99be-6469bb385e53",
    meta: {"ID":"8c2dd373-420a-424f-99be-6469bb385e53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|53b9bd6b-ecab-409e-b3e8-22b81c702f83|8c673600-be6d-4605-b949-4ebd4b81d78e",
    path: "/product/8c673600-be6d-4605-b949-4ebd4b81d78e",
    meta: {"ID":"8c673600-be6d-4605-b949-4ebd4b81d78e","Title":"","GroupID":"53b9bd6b-ecab-409e-b3e8-22b81c702f83","GroupTitle":"Паста и Ризотто","GroupLink":"/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|8c6eba23-c46b-4782-b19e-945f359e2767",
    path: "/product/8c6eba23-c46b-4782-b19e-945f359e2767",
    meta: {"ID":"8c6eba23-c46b-4782-b19e-945f359e2767","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8c97ebb3-9cbb-46ef-8d26-9bb5510aa73d",
    path: "/product/8c97ebb3-9cbb-46ef-8d26-9bb5510aa73d",
    meta: {"ID":"8c97ebb3-9cbb-46ef-8d26-9bb5510aa73d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8c99a35c-5534-4747-8cc9-e89e030e8411",
    path: "/product/8c99a35c-5534-4747-8cc9-e89e030e8411",
    meta: {"ID":"8c99a35c-5534-4747-8cc9-e89e030e8411","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|20377217-ff09-4072-b8a6-8c1964baed7c|8c9c15b2-6f77-4e60-ae9d-4ec519b633c2",
    path: "/product/8c9c15b2-6f77-4e60-ae9d-4ec519b633c2",
    meta: {"ID":"8c9c15b2-6f77-4e60-ae9d-4ec519b633c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|8cc9d681-8e27-4323-a0cd-f548a6230c27",
    path: "/product/8cc9d681-8e27-4323-a0cd-f548a6230c27",
    meta: {"ID":"8cc9d681-8e27-4323-a0cd-f548a6230c27","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8cdcd52e-4da7-4668-87f8-be016d246100",
    path: "/product/8cdcd52e-4da7-4668-87f8-be016d246100",
    meta: {"ID":"8cdcd52e-4da7-4668-87f8-be016d246100","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|8ce329d3-c1ae-4083-b9cd-8bd5b6949984",
    path: "/product/8ce329d3-c1ae-4083-b9cd-8bd5b6949984",
    meta: {"ID":"8ce329d3-c1ae-4083-b9cd-8bd5b6949984","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8ce3dbf9-c81f-4342-bc85-a0a8d9cf7301",
    path: "/product/8ce3dbf9-c81f-4342-bc85-a0a8d9cf7301",
    meta: {"ID":"8ce3dbf9-c81f-4342-bc85-a0a8d9cf7301","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4349f183-f876-4668-9110-f50347c57ef2|8cf011f3-2f23-4033-a842-fa2f52d0474a",
    path: "/product/8cf011f3-2f23-4033-a842-fa2f52d0474a",
    meta: {"ID":"8cf011f3-2f23-4033-a842-fa2f52d0474a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8d087ae8-92ac-4d00-a074-989e0634d51e",
    path: "/product/8d087ae8-92ac-4d00-a074-989e0634d51e",
    meta: {"ID":"8d087ae8-92ac-4d00-a074-989e0634d51e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8d4bf7d7-d7f4-40bb-88c3-9ac59cedc8bf",
    path: "/product/8d4bf7d7-d7f4-40bb-88c3-9ac59cedc8bf",
    meta: {"ID":"8d4bf7d7-d7f4-40bb-88c3-9ac59cedc8bf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|8d841c14-4207-4946-91a6-f8305c6a1234",
    path: "/product/8d841c14-4207-4946-91a6-f8305c6a1234",
    meta: {"ID":"8d841c14-4207-4946-91a6-f8305c6a1234","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|8d9c1e42-8b52-449c-b171-83534ba4ab25",
    path: "/product/8d9c1e42-8b52-449c-b171-83534ba4ab25",
    meta: {"ID":"8d9c1e42-8b52-449c-b171-83534ba4ab25","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8dca2bc3-f81b-4060-988b-75c32db9e807",
    path: "/product/8dca2bc3-f81b-4060-988b-75c32db9e807",
    meta: {"ID":"8dca2bc3-f81b-4060-988b-75c32db9e807","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8dd481ba-569d-4eea-ba8b-80fc7049b966",
    path: "/product/8dd481ba-569d-4eea-ba8b-80fc7049b966",
    meta: {"ID":"8dd481ba-569d-4eea-ba8b-80fc7049b966","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|8ddb4485-5e91-4e47-bb0c-1b3a1fa61b98",
    path: "/product/8ddb4485-5e91-4e47-bb0c-1b3a1fa61b98",
    meta: {"ID":"8ddb4485-5e91-4e47-bb0c-1b3a1fa61b98","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8de8c4e5-eb1c-486a-8a07-9a19719866a8",
    path: "/product/8de8c4e5-eb1c-486a-8a07-9a19719866a8",
    meta: {"ID":"8de8c4e5-eb1c-486a-8a07-9a19719866a8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|8df32975-4812-4c05-9276-b243f9db584e",
    path: "/product/8df32975-4812-4c05-9276-b243f9db584e",
    meta: {"ID":"8df32975-4812-4c05-9276-b243f9db584e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|8e0657aa-e7de-4a12-bd70-ab7a4639e154",
    path: "/product/8e0657aa-e7de-4a12-bd70-ab7a4639e154",
    meta: {"ID":"8e0657aa-e7de-4a12-bd70-ab7a4639e154","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|8e104494-98e4-44ef-b8fd-74b3aa1ddc66",
    path: "/product/8e104494-98e4-44ef-b8fd-74b3aa1ddc66",
    meta: {"ID":"8e104494-98e4-44ef-b8fd-74b3aa1ddc66","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|8e65b63f-18ef-497c-b073-ccc5399c7435",
    path: "/product/8e65b63f-18ef-497c-b073-ccc5399c7435",
    meta: {"ID":"8e65b63f-18ef-497c-b073-ccc5399c7435","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|8e82c681-f026-4162-a3b5-45f80a9f5189",
    path: "/product/8e82c681-f026-4162-a3b5-45f80a9f5189",
    meta: {"ID":"8e82c681-f026-4162-a3b5-45f80a9f5189","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|8e9f3042-3372-4ad5-b45e-7e173389c7ad",
    path: "/product/8e9f3042-3372-4ad5-b45e-7e173389c7ad",
    meta: {"ID":"8e9f3042-3372-4ad5-b45e-7e173389c7ad","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|8f0eb108-c3e7-449e-b11d-a9ae9285cd2d",
    path: "/product/8f0eb108-c3e7-449e-b11d-a9ae9285cd2d",
    meta: {"ID":"8f0eb108-c3e7-449e-b11d-a9ae9285cd2d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|8f271c00-6cb4-4675-9705-ab4313f36833",
    path: "/product/8f271c00-6cb4-4675-9705-ab4313f36833",
    meta: {"ID":"8f271c00-6cb4-4675-9705-ab4313f36833","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|8f2c9bfb-1671-45eb-9861-aff1d425c7fa",
    path: "/product/8f2c9bfb-1671-45eb-9861-aff1d425c7fa",
    meta: {"ID":"8f2c9bfb-1671-45eb-9861-aff1d425c7fa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|8f3aa011-1709-46bf-b301-ed88e76dea28",
    path: "/product/8f3aa011-1709-46bf-b301-ed88e76dea28",
    meta: {"ID":"8f3aa011-1709-46bf-b301-ed88e76dea28","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|8f5d441f-3f97-4a18-b69b-7081da7e7f25",
    path: "/product/8f5d441f-3f97-4a18-b69b-7081da7e7f25",
    meta: {"ID":"8f5d441f-3f97-4a18-b69b-7081da7e7f25","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|8f7dfa0c-f84c-4663-a814-ed8dd6eb9b4c",
    path: "/product/8f7dfa0c-f84c-4663-a814-ed8dd6eb9b4c",
    meta: {"ID":"8f7dfa0c-f84c-4663-a814-ed8dd6eb9b4c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|8fbb54ed-5e5b-4bad-aed5-d0b925089eb1",
    path: "/product/8fbb54ed-5e5b-4bad-aed5-d0b925089eb1",
    meta: {"ID":"8fbb54ed-5e5b-4bad-aed5-d0b925089eb1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|8fd4dbb5-e1be-4a69-8145-f0e05e117c12",
    path: "/product/8fd4dbb5-e1be-4a69-8145-f0e05e117c12",
    meta: {"ID":"8fd4dbb5-e1be-4a69-8145-f0e05e117c12","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|8fe36dd0-148c-4b7e-95a2-af4114a80987",
    path: "/product/8fe36dd0-148c-4b7e-95a2-af4114a80987",
    meta: {"ID":"8fe36dd0-148c-4b7e-95a2-af4114a80987","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|8fe62743-c8b6-4b12-be38-16a1da9d065f",
    path: "/product/8fe62743-c8b6-4b12-be38-16a1da9d065f",
    meta: {"ID":"8fe62743-c8b6-4b12-be38-16a1da9d065f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|8ff6f228-c94f-4a4d-9c94-30a44ec65151",
    path: "/product/8ff6f228-c94f-4a4d-9c94-30a44ec65151",
    meta: {"ID":"8ff6f228-c94f-4a4d-9c94-30a44ec65151","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|8ffad928-fb25-441a-a1a7-7cd04e8054f9",
    path: "/product/8ffad928-fb25-441a-a1a7-7cd04e8054f9",
    meta: {"ID":"8ffad928-fb25-441a-a1a7-7cd04e8054f9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|900d4379-26ef-435f-9820-edca288a1532",
    path: "/product/900d4379-26ef-435f-9820-edca288a1532",
    meta: {"ID":"900d4379-26ef-435f-9820-edca288a1532","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|900f0171-573a-4f41-870d-003cf0c027c8",
    path: "/product/900f0171-573a-4f41-870d-003cf0c027c8",
    meta: {"ID":"900f0171-573a-4f41-870d-003cf0c027c8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|902da93c-efc1-4381-ad1e-65fea9e5c1d7",
    path: "/product/902da93c-efc1-4381-ad1e-65fea9e5c1d7",
    meta: {"ID":"902da93c-efc1-4381-ad1e-65fea9e5c1d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|902fa953-064a-4f75-abc3-9811f118c7ca",
    path: "/product/902fa953-064a-4f75-abc3-9811f118c7ca",
    meta: {"ID":"902fa953-064a-4f75-abc3-9811f118c7ca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|904ec8bf-b2d4-4bb0-b854-13719cbc5d02",
    path: "/product/904ec8bf-b2d4-4bb0-b854-13719cbc5d02",
    meta: {"ID":"904ec8bf-b2d4-4bb0-b854-13719cbc5d02","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9050962b-83fd-4001-b42c-419a0e2262f6",
    path: "/product/9050962b-83fd-4001-b42c-419a0e2262f6",
    meta: {"ID":"9050962b-83fd-4001-b42c-419a0e2262f6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|af1ac0fd-a494-4b35-ae24-edc1fe10a7e4|9054db13-1dae-4312-b58e-b078f31a2df3",
    path: "/product/9054db13-1dae-4312-b58e-b078f31a2df3",
    meta: {"ID":"9054db13-1dae-4312-b58e-b078f31a2df3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|906f64c8-8254-46c8-9b2f-3a2764a92c55",
    path: "/product/906f64c8-8254-46c8-9b2f-3a2764a92c55",
    meta: {"ID":"906f64c8-8254-46c8-9b2f-3a2764a92c55","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|907e8aef-3641-4488-ac4f-b3eef0a98215",
    path: "/product/907e8aef-3641-4488-ac4f-b3eef0a98215",
    meta: {"ID":"907e8aef-3641-4488-ac4f-b3eef0a98215","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|90c488a0-4251-4cb7-a863-067a57f18f0b",
    path: "/product/90c488a0-4251-4cb7-a863-067a57f18f0b",
    meta: {"ID":"90c488a0-4251-4cb7-a863-067a57f18f0b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|90f38fed-cbe8-4dcd-b827-88995b7fc2c0",
    path: "/product/90f38fed-cbe8-4dcd-b827-88995b7fc2c0",
    meta: {"ID":"90f38fed-cbe8-4dcd-b827-88995b7fc2c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|90f6e0a7-60e4-4fe8-9a30-a671885b1267",
    path: "/product/90f6e0a7-60e4-4fe8-9a30-a671885b1267",
    meta: {"ID":"90f6e0a7-60e4-4fe8-9a30-a671885b1267","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9106a556-2b08-40b7-929a-790d4e2cfef3",
    path: "/product/9106a556-2b08-40b7-929a-790d4e2cfef3",
    meta: {"ID":"9106a556-2b08-40b7-929a-790d4e2cfef3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|91088dfd-5f91-4d4f-8302-66059d3ed33c",
    path: "/product/91088dfd-5f91-4d4f-8302-66059d3ed33c",
    meta: {"ID":"91088dfd-5f91-4d4f-8302-66059d3ed33c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|9119a3d2-aeee-4d58-b673-d90855a1049e",
    path: "/product/9119a3d2-aeee-4d58-b673-d90855a1049e",
    meta: {"ID":"9119a3d2-aeee-4d58-b673-d90855a1049e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|91573a67-27c2-4b59-9d20-bb7e694b0851",
    path: "/product/91573a67-27c2-4b59-9d20-bb7e694b0851",
    meta: {"ID":"91573a67-27c2-4b59-9d20-bb7e694b0851","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|9157ccd7-ab7a-4bcf-8251-c3cf5c48dcac",
    path: "/product/9157ccd7-ab7a-4bcf-8251-c3cf5c48dcac",
    meta: {"ID":"9157ccd7-ab7a-4bcf-8251-c3cf5c48dcac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|9171f71b-e1ff-4afa-a851-68f5ba06db00",
    path: "/product/9171f71b-e1ff-4afa-a851-68f5ba06db00",
    meta: {"ID":"9171f71b-e1ff-4afa-a851-68f5ba06db00","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|91b613dc-a588-4737-8d29-208793bd4c8e",
    path: "/product/91b613dc-a588-4737-8d29-208793bd4c8e",
    meta: {"ID":"91b613dc-a588-4737-8d29-208793bd4c8e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|96375b6e-e482-4ef6-b721-a0402c1fada2|91e45f4d-c1bd-4a30-ba46-bfa36dacb64c",
    path: "/product/91e45f4d-c1bd-4a30-ba46-bfa36dacb64c",
    meta: {"ID":"91e45f4d-c1bd-4a30-ba46-bfa36dacb64c","Title":"","GroupID":"96375b6e-e482-4ef6-b721-a0402c1fada2","GroupTitle":"Лимoнады","GroupLink":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|91f1fe2a-eab5-4eda-9881-9cd4ee116ec5",
    path: "/product/91f1fe2a-eab5-4eda-9881-9cd4ee116ec5",
    meta: {"ID":"91f1fe2a-eab5-4eda-9881-9cd4ee116ec5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|91fad98f-5c79-42d5-9b4e-525870699bd9",
    path: "/product/91fad98f-5c79-42d5-9b4e-525870699bd9",
    meta: {"ID":"91fad98f-5c79-42d5-9b4e-525870699bd9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|920947d2-58d0-4325-a832-1ee842b1d565",
    path: "/product/920947d2-58d0-4325-a832-1ee842b1d565",
    meta: {"ID":"920947d2-58d0-4325-a832-1ee842b1d565","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9231d768-f6ca-4b0a-9d00-3ab7b83f2e04",
    path: "/product/9231d768-f6ca-4b0a-9d00-3ab7b83f2e04",
    meta: {"ID":"9231d768-f6ca-4b0a-9d00-3ab7b83f2e04","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|924fdc93-51de-41df-a160-90413aca922a",
    path: "/product/924fdc93-51de-41df-a160-90413aca922a",
    meta: {"ID":"924fdc93-51de-41df-a160-90413aca922a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9260399f-9a07-4071-b9a6-f5654d2cfb33",
    path: "/product/9260399f-9a07-4071-b9a6-f5654d2cfb33",
    meta: {"ID":"9260399f-9a07-4071-b9a6-f5654d2cfb33","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|9280b5c2-4b15-4f38-8be7-2c746b138d1c",
    path: "/product/9280b5c2-4b15-4f38-8be7-2c746b138d1c",
    meta: {"ID":"9280b5c2-4b15-4f38-8be7-2c746b138d1c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|92939855-8355-4277-a1f3-432919f77fe8",
    path: "/product/92939855-8355-4277-a1f3-432919f77fe8",
    meta: {"ID":"92939855-8355-4277-a1f3-432919f77fe8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|92b54fb2-ea17-4871-bc5d-dc298560375d",
    path: "/product/92b54fb2-ea17-4871-bc5d-dc298560375d",
    meta: {"ID":"92b54fb2-ea17-4871-bc5d-dc298560375d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf8604ed-919e-4f96-8fa9-e0ed5a2f57dc|92d2835c-ba0b-436f-b5fa-7389e7f26371",
    path: "/product/92d2835c-ba0b-436f-b5fa-7389e7f26371",
    meta: {"ID":"92d2835c-ba0b-436f-b5fa-7389e7f26371","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|92e28ebd-a1e4-4ead-87ca-5a1cac3a3d6c",
    path: "/product/92e28ebd-a1e4-4ead-87ca-5a1cac3a3d6c",
    meta: {"ID":"92e28ebd-a1e4-4ead-87ca-5a1cac3a3d6c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|92e884a6-cf11-476c-9697-6bbe8699af41",
    path: "/product/92e884a6-cf11-476c-9697-6bbe8699af41",
    meta: {"ID":"92e884a6-cf11-476c-9697-6bbe8699af41","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|92f284ab-bc0f-4096-99e7-1b00b4ed0609",
    path: "/product/92f284ab-bc0f-4096-99e7-1b00b4ed0609",
    meta: {"ID":"92f284ab-bc0f-4096-99e7-1b00b4ed0609","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|92f3616c-e177-4adf-903a-491728216953",
    path: "/product/92f3616c-e177-4adf-903a-491728216953",
    meta: {"ID":"92f3616c-e177-4adf-903a-491728216953","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|930acdb4-b644-4560-817b-49b08cddeb70",
    path: "/product/930acdb4-b644-4560-817b-49b08cddeb70",
    meta: {"ID":"930acdb4-b644-4560-817b-49b08cddeb70","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|93127905-7c11-47c0-892d-a06c649ad527",
    path: "/product/93127905-7c11-47c0-892d-a06c649ad527",
    meta: {"ID":"93127905-7c11-47c0-892d-a06c649ad527","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|931547cb-51d1-4e0b-b10a-fe22dcbb58d1",
    path: "/product/931547cb-51d1-4e0b-b10a-fe22dcbb58d1",
    meta: {"ID":"931547cb-51d1-4e0b-b10a-fe22dcbb58d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|93380d81-9523-4e1e-ae8c-5229562679c3",
    path: "/product/93380d81-9523-4e1e-ae8c-5229562679c3",
    meta: {"ID":"93380d81-9523-4e1e-ae8c-5229562679c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|9344782b-4bde-4d3f-bce4-064d4f7d6200",
    path: "/product/9344782b-4bde-4d3f-bce4-064d4f7d6200",
    meta: {"ID":"9344782b-4bde-4d3f-bce4-064d4f7d6200","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9373fbb8-4667-4a6b-bdaa-5661b6c3d8e1",
    path: "/product/9373fbb8-4667-4a6b-bdaa-5661b6c3d8e1",
    meta: {"ID":"9373fbb8-4667-4a6b-bdaa-5661b6c3d8e1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|938f8549-3158-4308-a142-4ce979d16204",
    path: "/product/938f8549-3158-4308-a142-4ce979d16204",
    meta: {"ID":"938f8549-3158-4308-a142-4ce979d16204","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|9394ac9f-df8f-4a49-94ec-35beb72e07db",
    path: "/product/9394ac9f-df8f-4a49-94ec-35beb72e07db",
    meta: {"ID":"9394ac9f-df8f-4a49-94ec-35beb72e07db","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|93b5d2b6-b9e9-4a03-b850-5735c46610d0",
    path: "/product/93b5d2b6-b9e9-4a03-b850-5735c46610d0",
    meta: {"ID":"93b5d2b6-b9e9-4a03-b850-5735c46610d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|93ece4f5-0a11-484e-af9d-b7dc211d2de2",
    path: "/product/93ece4f5-0a11-484e-af9d-b7dc211d2de2",
    meta: {"ID":"93ece4f5-0a11-484e-af9d-b7dc211d2de2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6b95603-7a93-4283-b39d-ece699ed1469|93f4b3eb-b50f-4887-8b96-1ed348c52307",
    path: "/product/93f4b3eb-b50f-4887-8b96-1ed348c52307",
    meta: {"ID":"93f4b3eb-b50f-4887-8b96-1ed348c52307","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|93fd5914-de23-4683-bbd7-e71754087e51",
    path: "/product/93fd5914-de23-4683-bbd7-e71754087e51",
    meta: {"ID":"93fd5914-de23-4683-bbd7-e71754087e51","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|941bf8d6-36f7-4ce6-ae10-5b4e2135343b",
    path: "/product/941bf8d6-36f7-4ce6-ae10-5b4e2135343b",
    meta: {"ID":"941bf8d6-36f7-4ce6-ae10-5b4e2135343b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|42a5e113-75f4-40d2-a9ee-575b6e6c0b8d|942ce979-351f-4c65-a620-2a51ea8418a1",
    path: "/product/942ce979-351f-4c65-a620-2a51ea8418a1",
    meta: {"ID":"942ce979-351f-4c65-a620-2a51ea8418a1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|942e2a14-4f70-4fd5-93b9-93437dbcfe8a",
    path: "/product/942e2a14-4f70-4fd5-93b9-93437dbcfe8a",
    meta: {"ID":"942e2a14-4f70-4fd5-93b9-93437dbcfe8a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|9437acaa-6dff-4854-a14a-4b1f5671a4c9",
    path: "/product/9437acaa-6dff-4854-a14a-4b1f5671a4c9",
    meta: {"ID":"9437acaa-6dff-4854-a14a-4b1f5671a4c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9443c4d0-3e21-45a0-a149-886338b3f9ec",
    path: "/product/9443c4d0-3e21-45a0-a149-886338b3f9ec",
    meta: {"ID":"9443c4d0-3e21-45a0-a149-886338b3f9ec","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9455ff25-e99e-490f-a51c-5c0338ab9847",
    path: "/product/9455ff25-e99e-490f-a51c-5c0338ab9847",
    meta: {"ID":"9455ff25-e99e-490f-a51c-5c0338ab9847","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|61920873-b03c-43ac-aa78-5e5acd2bdfe1|94616d0a-3b55-42a9-9966-e0e5cff26c78",
    path: "/product/94616d0a-3b55-42a9-9966-e0e5cff26c78",
    meta: {"ID":"94616d0a-3b55-42a9-9966-e0e5cff26c78","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|946af0b8-3c06-4fcf-a331-02ed74a105c9",
    path: "/product/946af0b8-3c06-4fcf-a331-02ed74a105c9",
    meta: {"ID":"946af0b8-3c06-4fcf-a331-02ed74a105c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|94af3cd4-d364-4bbb-912c-177a32bf8bf0",
    path: "/product/94af3cd4-d364-4bbb-912c-177a32bf8bf0",
    meta: {"ID":"94af3cd4-d364-4bbb-912c-177a32bf8bf0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|94b9ab92-6c3d-4529-a870-94334da95cbe",
    path: "/product/94b9ab92-6c3d-4529-a870-94334da95cbe",
    meta: {"ID":"94b9ab92-6c3d-4529-a870-94334da95cbe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|94c88b9b-9f12-4c0d-9809-fd72fb917720",
    path: "/product/94c88b9b-9f12-4c0d-9809-fd72fb917720",
    meta: {"ID":"94c88b9b-9f12-4c0d-9809-fd72fb917720","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|95402f07-dfa9-4b70-9639-bb6c6fdbc45d",
    path: "/product/95402f07-dfa9-4b70-9639-bb6c6fdbc45d",
    meta: {"ID":"95402f07-dfa9-4b70-9639-bb6c6fdbc45d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|956045ff-be82-47e4-af55-2c78950f5c48",
    path: "/product/956045ff-be82-47e4-af55-2c78950f5c48",
    meta: {"ID":"956045ff-be82-47e4-af55-2c78950f5c48","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|95858f29-ce74-401f-b050-f9b02c7c9e25",
    path: "/product/95858f29-ce74-401f-b050-f9b02c7c9e25",
    meta: {"ID":"95858f29-ce74-401f-b050-f9b02c7c9e25","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|95941bca-a08d-46c7-aca7-f9d0aa2c8f51",
    path: "/product/95941bca-a08d-46c7-aca7-f9d0aa2c8f51",
    meta: {"ID":"95941bca-a08d-46c7-aca7-f9d0aa2c8f51","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|95a1ae49-9de9-49f5-a87f-c1ba8cd40e8c",
    path: "/product/95a1ae49-9de9-49f5-a87f-c1ba8cd40e8c",
    meta: {"ID":"95a1ae49-9de9-49f5-a87f-c1ba8cd40e8c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|95a22bbc-20e7-4df1-ba97-5ca49bc13a86",
    path: "/product/95a22bbc-20e7-4df1-ba97-5ca49bc13a86",
    meta: {"ID":"95a22bbc-20e7-4df1-ba97-5ca49bc13a86","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|95a7d7e2-9774-4077-908c-c88936465fc9",
    path: "/product/95a7d7e2-9774-4077-908c-c88936465fc9",
    meta: {"ID":"95a7d7e2-9774-4077-908c-c88936465fc9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|95ac622a-bd59-40cd-8229-b4e6fdc1f411",
    path: "/product/95ac622a-bd59-40cd-8229-b4e6fdc1f411",
    meta: {"ID":"95ac622a-bd59-40cd-8229-b4e6fdc1f411","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|95afab6b-9e33-46d1-a0e0-1447badd6f1c",
    path: "/product/95afab6b-9e33-46d1-a0e0-1447badd6f1c",
    meta: {"ID":"95afab6b-9e33-46d1-a0e0-1447badd6f1c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|95c6b188-598b-4336-85af-50171b68fe11",
    path: "/product/95c6b188-598b-4336-85af-50171b68fe11",
    meta: {"ID":"95c6b188-598b-4336-85af-50171b68fe11","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|95ed7982-3683-446a-8eea-541e9d7b3723",
    path: "/product/95ed7982-3683-446a-8eea-541e9d7b3723",
    meta: {"ID":"95ed7982-3683-446a-8eea-541e9d7b3723","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|96140c93-ffa6-47f1-9500-d9307d734754",
    path: "/product/96140c93-ffa6-47f1-9500-d9307d734754",
    meta: {"ID":"96140c93-ffa6-47f1-9500-d9307d734754","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|962ccf67-ab61-464b-bcb3-aba0ac4c855e",
    path: "/product/962ccf67-ab61-464b-bcb3-aba0ac4c855e",
    meta: {"ID":"962ccf67-ab61-464b-bcb3-aba0ac4c855e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|962f21d5-21e7-4e3d-a8aa-f0ac4a6bf889",
    path: "/product/962f21d5-21e7-4e3d-a8aa-f0ac4a6bf889",
    meta: {"ID":"962f21d5-21e7-4e3d-a8aa-f0ac4a6bf889","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9631cbc2-44df-4b5e-903f-ce6909ba9f21",
    path: "/product/9631cbc2-44df-4b5e-903f-ce6909ba9f21",
    meta: {"ID":"9631cbc2-44df-4b5e-903f-ce6909ba9f21","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|966efd13-8742-45d0-85b8-ec4e99e93339",
    path: "/product/966efd13-8742-45d0-85b8-ec4e99e93339",
    meta: {"ID":"966efd13-8742-45d0-85b8-ec4e99e93339","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|966f5a8a-3cff-46f0-b4f6-9cae970ed60a",
    path: "/product/966f5a8a-3cff-46f0-b4f6-9cae970ed60a",
    meta: {"ID":"966f5a8a-3cff-46f0-b4f6-9cae970ed60a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|967077c0-d88a-411e-b4c0-e961e6a4d1bc",
    path: "/product/967077c0-d88a-411e-b4c0-e961e6a4d1bc",
    meta: {"ID":"967077c0-d88a-411e-b4c0-e961e6a4d1bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|968247ed-bc39-44c4-9ed4-a9eba3cfa5ef",
    path: "/product/968247ed-bc39-44c4-9ed4-a9eba3cfa5ef",
    meta: {"ID":"968247ed-bc39-44c4-9ed4-a9eba3cfa5ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|96b451c2-5f13-4aa2-a40f-3081efd1393d",
    path: "/product/96b451c2-5f13-4aa2-a40f-3081efd1393d",
    meta: {"ID":"96b451c2-5f13-4aa2-a40f-3081efd1393d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|96c3c542-68db-436c-9e28-48f2495aa393",
    path: "/product/96c3c542-68db-436c-9e28-48f2495aa393",
    meta: {"ID":"96c3c542-68db-436c-9e28-48f2495aa393","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|96ca7ab4-60f2-4b4f-b86e-f4914139e137",
    path: "/product/96ca7ab4-60f2-4b4f-b86e-f4914139e137",
    meta: {"ID":"96ca7ab4-60f2-4b4f-b86e-f4914139e137","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6fa548ca-4b6a-4806-83da-74148d7bfb94|9704f9f8-7ca0-42b5-bea7-bac69dc0861a",
    path: "/product/9704f9f8-7ca0-42b5-bea7-bac69dc0861a",
    meta: {"ID":"9704f9f8-7ca0-42b5-bea7-bac69dc0861a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|970c95b2-4ef8-445c-95f7-605771c96cc5",
    path: "/product/970c95b2-4ef8-445c-95f7-605771c96cc5",
    meta: {"ID":"970c95b2-4ef8-445c-95f7-605771c96cc5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9732aa75-ddef-4024-9f80-034357e20c66",
    path: "/product/9732aa75-ddef-4024-9f80-034357e20c66",
    meta: {"ID":"9732aa75-ddef-4024-9f80-034357e20c66","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|9733009a-20ac-4cff-a911-980ea5d4b3f3",
    path: "/product/9733009a-20ac-4cff-a911-980ea5d4b3f3",
    meta: {"ID":"9733009a-20ac-4cff-a911-980ea5d4b3f3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|9752dfdf-3cce-4e15-b468-7c51beb62ec7",
    path: "/product/9752dfdf-3cce-4e15-b468-7c51beb62ec7",
    meta: {"ID":"9752dfdf-3cce-4e15-b468-7c51beb62ec7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1a496364-a63b-4683-aa31-12d5196add62|975e365d-55c3-453d-9dc4-cd3585c480ac",
    path: "/product/975e365d-55c3-453d-9dc4-cd3585c480ac",
    meta: {"ID":"975e365d-55c3-453d-9dc4-cd3585c480ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|59b73946-d0e9-47cf-82f0-0d5c22e3862b|975fae4e-1789-477c-8349-a542b17b5dbb",
    path: "/product/975fae4e-1789-477c-8349-a542b17b5dbb",
    meta: {"ID":"975fae4e-1789-477c-8349-a542b17b5dbb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|97623760-b76c-4fdc-a8bf-d6bf30d0be6b",
    path: "/product/97623760-b76c-4fdc-a8bf-d6bf30d0be6b",
    meta: {"ID":"97623760-b76c-4fdc-a8bf-d6bf30d0be6b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0686e42-5664-44b8-b850-26f555406842|9768aa90-a003-4d62-a873-9b231359acfc",
    path: "/product/9768aa90-a003-4d62-a873-9b231359acfc",
    meta: {"ID":"9768aa90-a003-4d62-a873-9b231359acfc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|97838155-ea58-4b3d-88c1-c5221510e87b",
    path: "/product/97838155-ea58-4b3d-88c1-c5221510e87b",
    meta: {"ID":"97838155-ea58-4b3d-88c1-c5221510e87b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|978cdc10-ede4-4078-a032-6ce6877c172f",
    path: "/product/978cdc10-ede4-4078-a032-6ce6877c172f",
    meta: {"ID":"978cdc10-ede4-4078-a032-6ce6877c172f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|979e926a-5932-4abb-840d-88006a2ef3cd",
    path: "/product/979e926a-5932-4abb-840d-88006a2ef3cd",
    meta: {"ID":"979e926a-5932-4abb-840d-88006a2ef3cd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|97b0e1e3-9d99-45a4-9614-b453eef14d0b",
    path: "/product/97b0e1e3-9d99-45a4-9614-b453eef14d0b",
    meta: {"ID":"97b0e1e3-9d99-45a4-9614-b453eef14d0b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|97b8a16a-7716-448a-a22d-8baa6bb27be1",
    path: "/product/97b8a16a-7716-448a-a22d-8baa6bb27be1",
    meta: {"ID":"97b8a16a-7716-448a-a22d-8baa6bb27be1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|97d78d24-fb86-4df3-91ca-93d219f03d93",
    path: "/product/97d78d24-fb86-4df3-91ca-93d219f03d93",
    meta: {"ID":"97d78d24-fb86-4df3-91ca-93d219f03d93","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|97e185d2-a9c6-4571-ae40-ea2cc563f463",
    path: "/product/97e185d2-a9c6-4571-ae40-ea2cc563f463",
    meta: {"ID":"97e185d2-a9c6-4571-ae40-ea2cc563f463","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|9841ef73-179e-4426-baba-09fbb008f330",
    path: "/product/9841ef73-179e-4426-baba-09fbb008f330",
    meta: {"ID":"9841ef73-179e-4426-baba-09fbb008f330","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9852d0c2-26c5-4b55-9fe0-32b38246ee8c",
    path: "/product/9852d0c2-26c5-4b55-9fe0-32b38246ee8c",
    meta: {"ID":"9852d0c2-26c5-4b55-9fe0-32b38246ee8c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9858fc40-047f-46a0-a54e-ab4e5d8fc72d",
    path: "/product/9858fc40-047f-46a0-a54e-ab4e5d8fc72d",
    meta: {"ID":"9858fc40-047f-46a0-a54e-ab4e5d8fc72d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9864c8a1-5725-4c8f-9250-55a99a0becbe",
    path: "/product/9864c8a1-5725-4c8f-9250-55a99a0becbe",
    meta: {"ID":"9864c8a1-5725-4c8f-9250-55a99a0becbe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9879226e-637a-4f8a-8d24-13ed18ffefe4",
    path: "/product/9879226e-637a-4f8a-8d24-13ed18ffefe4",
    meta: {"ID":"9879226e-637a-4f8a-8d24-13ed18ffefe4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|535cfdf0-8f9a-46fd-9cdc-483647c40ce4|989cb397-c3e6-422e-90a7-23444fb8acc2",
    path: "/product/989cb397-c3e6-422e-90a7-23444fb8acc2",
    meta: {"ID":"989cb397-c3e6-422e-90a7-23444fb8acc2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|98a80311-0d09-48e7-875f-7c071b63eb1d",
    path: "/product/98a80311-0d09-48e7-875f-7c071b63eb1d",
    meta: {"ID":"98a80311-0d09-48e7-875f-7c071b63eb1d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|98af6e14-a044-4996-aa2c-3c642ef9bec4",
    path: "/product/98af6e14-a044-4996-aa2c-3c642ef9bec4",
    meta: {"ID":"98af6e14-a044-4996-aa2c-3c642ef9bec4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|98ba7973-cc37-46c9-b2d9-c53333c86f55",
    path: "/product/98ba7973-cc37-46c9-b2d9-c53333c86f55",
    meta: {"ID":"98ba7973-cc37-46c9-b2d9-c53333c86f55","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|98ebf101-d5e6-44d8-91c0-f9ca159d2fdf",
    path: "/product/98ebf101-d5e6-44d8-91c0-f9ca159d2fdf",
    meta: {"ID":"98ebf101-d5e6-44d8-91c0-f9ca159d2fdf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|990463d0-7a4e-43b7-a8d3-df7255845a6e",
    path: "/product/990463d0-7a4e-43b7-a8d3-df7255845a6e",
    meta: {"ID":"990463d0-7a4e-43b7-a8d3-df7255845a6e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|994c1e4e-8858-49e3-be81-efed6fe7fadc",
    path: "/product/994c1e4e-8858-49e3-be81-efed6fe7fadc",
    meta: {"ID":"994c1e4e-8858-49e3-be81-efed6fe7fadc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|99538557-493b-441f-b742-669ab8c0de0d",
    path: "/product/99538557-493b-441f-b742-669ab8c0de0d",
    meta: {"ID":"99538557-493b-441f-b742-669ab8c0de0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|996d74ed-8240-4ad0-8937-b6a43ddc10a7",
    path: "/product/996d74ed-8240-4ad0-8937-b6a43ddc10a7",
    meta: {"ID":"996d74ed-8240-4ad0-8937-b6a43ddc10a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|99ba4a66-95a6-4bb5-83aa-20373144dc15",
    path: "/product/99ba4a66-95a6-4bb5-83aa-20373144dc15",
    meta: {"ID":"99ba4a66-95a6-4bb5-83aa-20373144dc15","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|99bb1f18-22c1-4cd3-b0b5-449b6291b406",
    path: "/product/99bb1f18-22c1-4cd3-b0b5-449b6291b406",
    meta: {"ID":"99bb1f18-22c1-4cd3-b0b5-449b6291b406","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|99ccd9f5-91ec-438f-a47b-8927fa5cb586",
    path: "/product/99ccd9f5-91ec-438f-a47b-8927fa5cb586",
    meta: {"ID":"99ccd9f5-91ec-438f-a47b-8927fa5cb586","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2d9bf239-b509-4129-9d84-16287bf039d0|99d24176-e455-4bac-9f58-cf8e2a9a6e10",
    path: "/product/99d24176-e455-4bac-9f58-cf8e2a9a6e10",
    meta: {"ID":"99d24176-e455-4bac-9f58-cf8e2a9a6e10","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|99e14b21-dbc4-4874-8321-6c6057c22d9e",
    path: "/product/99e14b21-dbc4-4874-8321-6c6057c22d9e",
    meta: {"ID":"99e14b21-dbc4-4874-8321-6c6057c22d9e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|99f27af0-0265-4aef-969b-e1832af41a7a",
    path: "/product/99f27af0-0265-4aef-969b-e1832af41a7a",
    meta: {"ID":"99f27af0-0265-4aef-969b-e1832af41a7a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|99f2fac1-20bf-48ec-abc0-a1b5ac01cc75",
    path: "/product/99f2fac1-20bf-48ec-abc0-a1b5ac01cc75",
    meta: {"ID":"99f2fac1-20bf-48ec-abc0-a1b5ac01cc75","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|99f5040c-c112-4ed6-bd4f-d289bc61906e",
    path: "/product/99f5040c-c112-4ed6-bd4f-d289bc61906e",
    meta: {"ID":"99f5040c-c112-4ed6-bd4f-d289bc61906e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7801b5bb-054e-4ce0-942d-d7831c24ac40|99f8d699-f5d3-4c83-9568-8459e1fe1b14",
    path: "/product/99f8d699-f5d3-4c83-9568-8459e1fe1b14",
    meta: {"ID":"99f8d699-f5d3-4c83-9568-8459e1fe1b14","Title":"","GroupID":"7801b5bb-054e-4ce0-942d-d7831c24ac40","GroupTitle":"Сувениры","GroupLink":"/menu/7801b5bb-054e-4ce0-942d-d7831c24ac40","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9a0fd7da-c15d-4969-a12f-5a90b43681a7",
    path: "/product/9a0fd7da-c15d-4969-a12f-5a90b43681a7",
    meta: {"ID":"9a0fd7da-c15d-4969-a12f-5a90b43681a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|af1ac0fd-a494-4b35-ae24-edc1fe10a7e4|9a346521-f691-4e86-9023-fa6680bfd01e",
    path: "/product/9a346521-f691-4e86-9023-fa6680bfd01e",
    meta: {"ID":"9a346521-f691-4e86-9023-fa6680bfd01e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9a4990f8-e428-4d68-9694-e4552a27ef25",
    path: "/product/9a4990f8-e428-4d68-9694-e4552a27ef25",
    meta: {"ID":"9a4990f8-e428-4d68-9694-e4552a27ef25","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|9a552f61-57f3-497d-bb28-f7c41c997927",
    path: "/product/9a552f61-57f3-497d-bb28-f7c41c997927",
    meta: {"ID":"9a552f61-57f3-497d-bb28-f7c41c997927","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9a68dd84-e170-448a-a6cb-7b5189b47eeb",
    path: "/product/9a68dd84-e170-448a-a6cb-7b5189b47eeb",
    meta: {"ID":"9a68dd84-e170-448a-a6cb-7b5189b47eeb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|9a88b32e-a1d4-4549-81a8-139d507e8040",
    path: "/product/9a88b32e-a1d4-4549-81a8-139d507e8040",
    meta: {"ID":"9a88b32e-a1d4-4549-81a8-139d507e8040","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9a8b01c0-bce6-490b-ade1-c010f85eb319",
    path: "/product/9a8b01c0-bce6-490b-ade1-c010f85eb319",
    meta: {"ID":"9a8b01c0-bce6-490b-ade1-c010f85eb319","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|9a998700-3b2c-440a-b128-a9e912ace3e3",
    path: "/product/9a998700-3b2c-440a-b128-a9e912ace3e3",
    meta: {"ID":"9a998700-3b2c-440a-b128-a9e912ace3e3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1519fe15-47e1-44ff-8747-6714ca96f7aa|9ab1e3bd-ced5-4985-a018-7a9979746056",
    path: "/product/9ab1e3bd-ced5-4985-a018-7a9979746056",
    meta: {"ID":"9ab1e3bd-ced5-4985-a018-7a9979746056","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2e4c4f9e-4ea3-49dc-b9e8-6726b7080501|9ab91c0c-1003-4f9b-9d01-fb2445a99958",
    path: "/product/9ab91c0c-1003-4f9b-9d01-fb2445a99958",
    meta: {"ID":"9ab91c0c-1003-4f9b-9d01-fb2445a99958","Title":"","GroupID":"2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","GroupTitle":"Детское меню","GroupLink":"/menu/2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|9ae16f74-3c24-4797-98a1-9953d2be40a1",
    path: "/product/9ae16f74-3c24-4797-98a1-9953d2be40a1",
    meta: {"ID":"9ae16f74-3c24-4797-98a1-9953d2be40a1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9af7acfb-936e-4d74-98b7-e58e2d9e940d",
    path: "/product/9af7acfb-936e-4d74-98b7-e58e2d9e940d",
    meta: {"ID":"9af7acfb-936e-4d74-98b7-e58e2d9e940d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9b030443-cb4d-4687-9b0a-766aae5da00b",
    path: "/product/9b030443-cb4d-4687-9b0a-766aae5da00b",
    meta: {"ID":"9b030443-cb4d-4687-9b0a-766aae5da00b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9b21ff5d-b91f-4c3f-9be0-75aaa9e940a6",
    path: "/product/9b21ff5d-b91f-4c3f-9be0-75aaa9e940a6",
    meta: {"ID":"9b21ff5d-b91f-4c3f-9be0-75aaa9e940a6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|9b60e718-04e6-4397-af98-a8be0700021c",
    path: "/product/9b60e718-04e6-4397-af98-a8be0700021c",
    meta: {"ID":"9b60e718-04e6-4397-af98-a8be0700021c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|9b677b5a-be1a-4099-a005-5ad70bbbb03a",
    path: "/product/9b677b5a-be1a-4099-a005-5ad70bbbb03a",
    meta: {"ID":"9b677b5a-be1a-4099-a005-5ad70bbbb03a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9b7ec1dc-dbbe-461a-9c97-394cca72f3db",
    path: "/product/9b7ec1dc-dbbe-461a-9c97-394cca72f3db",
    meta: {"ID":"9b7ec1dc-dbbe-461a-9c97-394cca72f3db","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|9b90c287-a0e0-49a4-9f46-be16b315f12b",
    path: "/product/9b90c287-a0e0-49a4-9f46-be16b315f12b",
    meta: {"ID":"9b90c287-a0e0-49a4-9f46-be16b315f12b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|9b974275-804a-41fe-84d3-1ae861810050",
    path: "/product/9b974275-804a-41fe-84d3-1ae861810050",
    meta: {"ID":"9b974275-804a-41fe-84d3-1ae861810050","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|9ba648cc-c01c-4722-a798-2af6840bb726",
    path: "/product/9ba648cc-c01c-4722-a798-2af6840bb726",
    meta: {"ID":"9ba648cc-c01c-4722-a798-2af6840bb726","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9bb0a4c8-5c13-4b61-a1ec-3605211bb3df",
    path: "/product/9bb0a4c8-5c13-4b61-a1ec-3605211bb3df",
    meta: {"ID":"9bb0a4c8-5c13-4b61-a1ec-3605211bb3df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2e4c4f9e-4ea3-49dc-b9e8-6726b7080501|9bbb8cd8-7f83-4d03-913a-2481cbded277",
    path: "/product/9bbb8cd8-7f83-4d03-913a-2481cbded277",
    meta: {"ID":"9bbb8cd8-7f83-4d03-913a-2481cbded277","Title":"","GroupID":"2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","GroupTitle":"Детское меню","GroupLink":"/menu/2e4c4f9e-4ea3-49dc-b9e8-6726b7080501","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|9bd15b12-da08-4307-887b-9044b6bb6569",
    path: "/product/9bd15b12-da08-4307-887b-9044b6bb6569",
    meta: {"ID":"9bd15b12-da08-4307-887b-9044b6bb6569","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|d586fdfe-fc1e-4037-89d8-4ebed69e1496|9bfa274f-101c-453a-bc17-fb685e3b3d1a",
    path: "/product/9bfa274f-101c-453a-bc17-fb685e3b3d1a",
    meta: {"ID":"9bfa274f-101c-453a-bc17-fb685e3b3d1a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9c5220f9-bc1d-481f-872c-6979aca63991",
    path: "/product/9c5220f9-bc1d-481f-872c-6979aca63991",
    meta: {"ID":"9c5220f9-bc1d-481f-872c-6979aca63991","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9c839f80-25e3-4e3d-8458-47c074ec7192",
    path: "/product/9c839f80-25e3-4e3d-8458-47c074ec7192",
    meta: {"ID":"9c839f80-25e3-4e3d-8458-47c074ec7192","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9d06e8b9-0b40-4931-a31e-b967dd37cbfa",
    path: "/product/9d06e8b9-0b40-4931-a31e-b967dd37cbfa",
    meta: {"ID":"9d06e8b9-0b40-4931-a31e-b967dd37cbfa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9d1449ad-7264-476c-ba2b-8ae897a4737b",
    path: "/product/9d1449ad-7264-476c-ba2b-8ae897a4737b",
    meta: {"ID":"9d1449ad-7264-476c-ba2b-8ae897a4737b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|9d3c2721-f642-4dbc-b6a8-2157c6984649",
    path: "/product/9d3c2721-f642-4dbc-b6a8-2157c6984649",
    meta: {"ID":"9d3c2721-f642-4dbc-b6a8-2157c6984649","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9d3d4681-b8fd-40f6-9a90-fd9121a9af20",
    path: "/product/9d3d4681-b8fd-40f6-9a90-fd9121a9af20",
    meta: {"ID":"9d3d4681-b8fd-40f6-9a90-fd9121a9af20","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9d4986ac-1949-4202-8034-aa9f094f429e",
    path: "/product/9d4986ac-1949-4202-8034-aa9f094f429e",
    meta: {"ID":"9d4986ac-1949-4202-8034-aa9f094f429e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|9d54fe12-091a-42b2-ab00-417bd1f2b11d",
    path: "/product/9d54fe12-091a-42b2-ab00-417bd1f2b11d",
    meta: {"ID":"9d54fe12-091a-42b2-ab00-417bd1f2b11d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9d55f9eb-f710-418d-826c-6e3c11f4455d",
    path: "/product/9d55f9eb-f710-418d-826c-6e3c11f4455d",
    meta: {"ID":"9d55f9eb-f710-418d-826c-6e3c11f4455d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|9d9cfb34-ecec-4004-bafe-0533c4c57ad3",
    path: "/product/9d9cfb34-ecec-4004-bafe-0533c4c57ad3",
    meta: {"ID":"9d9cfb34-ecec-4004-bafe-0533c4c57ad3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9da990c1-5cda-426b-9fd1-56b7af8732d1",
    path: "/product/9da990c1-5cda-426b-9fd1-56b7af8732d1",
    meta: {"ID":"9da990c1-5cda-426b-9fd1-56b7af8732d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|9dc5a4a3-e89c-42ae-8dec-8d5607688175",
    path: "/product/9dc5a4a3-e89c-42ae-8dec-8d5607688175",
    meta: {"ID":"9dc5a4a3-e89c-42ae-8dec-8d5607688175","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9dcbd627-3a88-47fd-a3a9-2fb5f4712a46",
    path: "/product/9dcbd627-3a88-47fd-a3a9-2fb5f4712a46",
    meta: {"ID":"9dcbd627-3a88-47fd-a3a9-2fb5f4712a46","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9dcfa5bf-7164-437f-b3d0-a5e7433a9bef",
    path: "/product/9dcfa5bf-7164-437f-b3d0-a5e7433a9bef",
    meta: {"ID":"9dcfa5bf-7164-437f-b3d0-a5e7433a9bef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|9dcfc31e-7aca-41be-b4e8-c84067a51388",
    path: "/product/9dcfc31e-7aca-41be-b4e8-c84067a51388",
    meta: {"ID":"9dcfc31e-7aca-41be-b4e8-c84067a51388","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9ddf4569-ab90-4615-bef3-e279f3e18aaf",
    path: "/product/9ddf4569-ab90-4615-bef3-e279f3e18aaf",
    meta: {"ID":"9ddf4569-ab90-4615-bef3-e279f3e18aaf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9df3bcca-4a4d-47ed-8468-988507473385",
    path: "/product/9df3bcca-4a4d-47ed-8468-988507473385",
    meta: {"ID":"9df3bcca-4a4d-47ed-8468-988507473385","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9dfbc1ac-fe39-4ffa-a195-e50b9302b686",
    path: "/product/9dfbc1ac-fe39-4ffa-a195-e50b9302b686",
    meta: {"ID":"9dfbc1ac-fe39-4ffa-a195-e50b9302b686","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|9e00be38-368c-40d2-b927-8136304f569d",
    path: "/product/9e00be38-368c-40d2-b927-8136304f569d",
    meta: {"ID":"9e00be38-368c-40d2-b927-8136304f569d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9e0d9f90-c7ee-4496-a287-2d7d395752c2",
    path: "/product/9e0d9f90-c7ee-4496-a287-2d7d395752c2",
    meta: {"ID":"9e0d9f90-c7ee-4496-a287-2d7d395752c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|9e0e409c-815b-42aa-8986-e3c526373b0d",
    path: "/product/9e0e409c-815b-42aa-8986-e3c526373b0d",
    meta: {"ID":"9e0e409c-815b-42aa-8986-e3c526373b0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9e2704b6-fa96-47a7-8fe0-25cc04d3988f",
    path: "/product/9e2704b6-fa96-47a7-8fe0-25cc04d3988f",
    meta: {"ID":"9e2704b6-fa96-47a7-8fe0-25cc04d3988f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|9e394cca-06fa-4d48-ab82-1317a615613e",
    path: "/product/9e394cca-06fa-4d48-ab82-1317a615613e",
    meta: {"ID":"9e394cca-06fa-4d48-ab82-1317a615613e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9e3997a8-d7ed-4b5f-b80c-3b4410085e09",
    path: "/product/9e3997a8-d7ed-4b5f-b80c-3b4410085e09",
    meta: {"ID":"9e3997a8-d7ed-4b5f-b80c-3b4410085e09","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9e5df246-ea06-4412-8816-e650a12db1f0",
    path: "/product/9e5df246-ea06-4412-8816-e650a12db1f0",
    meta: {"ID":"9e5df246-ea06-4412-8816-e650a12db1f0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9e5fbf36-a386-4dfd-91d5-37d8b5488a9c",
    path: "/product/9e5fbf36-a386-4dfd-91d5-37d8b5488a9c",
    meta: {"ID":"9e5fbf36-a386-4dfd-91d5-37d8b5488a9c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9eb795df-9e2e-4c39-820c-ea5afe760148",
    path: "/product/9eb795df-9e2e-4c39-820c-ea5afe760148",
    meta: {"ID":"9eb795df-9e2e-4c39-820c-ea5afe760148","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9ecfa9f3-28eb-4240-a22f-5b2c7d6d5132",
    path: "/product/9ecfa9f3-28eb-4240-a22f-5b2c7d6d5132",
    meta: {"ID":"9ecfa9f3-28eb-4240-a22f-5b2c7d6d5132","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9eeb361a-29c5-4d85-9081-8361f86c301f",
    path: "/product/9eeb361a-29c5-4d85-9081-8361f86c301f",
    meta: {"ID":"9eeb361a-29c5-4d85-9081-8361f86c301f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|9eefdcfe-72de-45fd-b9ea-2bcfc1d0e399",
    path: "/product/9eefdcfe-72de-45fd-b9ea-2bcfc1d0e399",
    meta: {"ID":"9eefdcfe-72de-45fd-b9ea-2bcfc1d0e399","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|9f06fcba-e1bc-498e-b454-65f82cf5c8e2",
    path: "/product/9f06fcba-e1bc-498e-b454-65f82cf5c8e2",
    meta: {"ID":"9f06fcba-e1bc-498e-b454-65f82cf5c8e2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9f1443ed-d82a-411d-85d9-05eb370f3380",
    path: "/product/9f1443ed-d82a-411d-85d9-05eb370f3380",
    meta: {"ID":"9f1443ed-d82a-411d-85d9-05eb370f3380","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9f16335a-4a58-450c-b550-db633cfd1f2d",
    path: "/product/9f16335a-4a58-450c-b550-db633cfd1f2d",
    meta: {"ID":"9f16335a-4a58-450c-b550-db633cfd1f2d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf8604ed-919e-4f96-8fa9-e0ed5a2f57dc|9f316fcb-744d-4898-938d-76a956a7da78",
    path: "/product/9f316fcb-744d-4898-938d-76a956a7da78",
    meta: {"ID":"9f316fcb-744d-4898-938d-76a956a7da78","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9f38f4c0-3678-4190-af24-8ce8dfdbb237",
    path: "/product/9f38f4c0-3678-4190-af24-8ce8dfdbb237",
    meta: {"ID":"9f38f4c0-3678-4190-af24-8ce8dfdbb237","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|9f40907e-93c2-422b-ac89-ecd38078b27f",
    path: "/product/9f40907e-93c2-422b-ac89-ecd38078b27f",
    meta: {"ID":"9f40907e-93c2-422b-ac89-ecd38078b27f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9f72522e-be75-4100-901b-18c9f2e6d8aa",
    path: "/product/9f72522e-be75-4100-901b-18c9f2e6d8aa",
    meta: {"ID":"9f72522e-be75-4100-901b-18c9f2e6d8aa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|9f72884f-d38e-43ee-9488-3d85ab139266",
    path: "/product/9f72884f-d38e-43ee-9488-3d85ab139266",
    meta: {"ID":"9f72884f-d38e-43ee-9488-3d85ab139266","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|9f840cfb-ee85-49c8-9db1-0727a5285104",
    path: "/product/9f840cfb-ee85-49c8-9db1-0727a5285104",
    meta: {"ID":"9f840cfb-ee85-49c8-9db1-0727a5285104","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|9f8d0072-463e-4839-8993-ee3e02a7477e",
    path: "/product/9f8d0072-463e-4839-8993-ee3e02a7477e",
    meta: {"ID":"9f8d0072-463e-4839-8993-ee3e02a7477e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|9fb937df-a490-4b16-abbc-2cf22ccfd016",
    path: "/product/9fb937df-a490-4b16-abbc-2cf22ccfd016",
    meta: {"ID":"9fb937df-a490-4b16-abbc-2cf22ccfd016","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|a009f9b0-fe02-4693-81c4-afba1f78031e",
    path: "/product/a009f9b0-fe02-4693-81c4-afba1f78031e",
    meta: {"ID":"a009f9b0-fe02-4693-81c4-afba1f78031e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a01d392c-0024-4547-ad2e-b33befc632c3",
    path: "/product/a01d392c-0024-4547-ad2e-b33befc632c3",
    meta: {"ID":"a01d392c-0024-4547-ad2e-b33befc632c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|a029cd5e-e584-4254-a873-3019284f9f7b",
    path: "/product/a029cd5e-e584-4254-a873-3019284f9f7b",
    meta: {"ID":"a029cd5e-e584-4254-a873-3019284f9f7b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|6650365f-b288-4159-8f19-1721fee02f23|a0592cd6-7b06-47a8-bbad-33843a35d311",
    path: "/product/a0592cd6-7b06-47a8-bbad-33843a35d311",
    meta: {"ID":"a0592cd6-7b06-47a8-bbad-33843a35d311","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|a05a79cc-fbf0-4d77-9241-8d065c1efef4",
    path: "/product/a05a79cc-fbf0-4d77-9241-8d065c1efef4",
    meta: {"ID":"a05a79cc-fbf0-4d77-9241-8d065c1efef4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|a0657e1c-11e8-4d13-8631-ead819e28f85",
    path: "/product/a0657e1c-11e8-4d13-8631-ead819e28f85",
    meta: {"ID":"a0657e1c-11e8-4d13-8631-ead819e28f85","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a079a55f-6c50-4959-96be-97798c6e6624",
    path: "/product/a079a55f-6c50-4959-96be-97798c6e6624",
    meta: {"ID":"a079a55f-6c50-4959-96be-97798c6e6624","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|a0b76a5a-a7f5-484a-9e13-7c2431f7026e",
    path: "/product/a0b76a5a-a7f5-484a-9e13-7c2431f7026e",
    meta: {"ID":"a0b76a5a-a7f5-484a-9e13-7c2431f7026e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|a0d36cfd-dc7e-4235-8e2b-1db43858ad89",
    path: "/product/a0d36cfd-dc7e-4235-8e2b-1db43858ad89",
    meta: {"ID":"a0d36cfd-dc7e-4235-8e2b-1db43858ad89","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|a111847e-5496-4bed-af93-b357f655548c",
    path: "/product/a111847e-5496-4bed-af93-b357f655548c",
    meta: {"ID":"a111847e-5496-4bed-af93-b357f655548c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a114075a-0b8d-4542-b15e-01259c5ccc1a",
    path: "/product/a114075a-0b8d-4542-b15e-01259c5ccc1a",
    meta: {"ID":"a114075a-0b8d-4542-b15e-01259c5ccc1a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a13051eb-cd0f-4236-8a56-9565c00eb2cb",
    path: "/product/a13051eb-cd0f-4236-8a56-9565c00eb2cb",
    meta: {"ID":"a13051eb-cd0f-4236-8a56-9565c00eb2cb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|a142d5a9-4e63-41d9-b108-af47f9a5df43",
    path: "/product/a142d5a9-4e63-41d9-b108-af47f9a5df43",
    meta: {"ID":"a142d5a9-4e63-41d9-b108-af47f9a5df43","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a1550c90-4e12-48ff-bc38-eb9a81618af2",
    path: "/product/a1550c90-4e12-48ff-bc38-eb9a81618af2",
    meta: {"ID":"a1550c90-4e12-48ff-bc38-eb9a81618af2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a17ffe61-0b7c-46cb-b6e8-4a66195842a0",
    path: "/product/a17ffe61-0b7c-46cb-b6e8-4a66195842a0",
    meta: {"ID":"a17ffe61-0b7c-46cb-b6e8-4a66195842a0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a181780a-a202-42ee-a537-89cdc008f7ae",
    path: "/product/a181780a-a202-42ee-a537-89cdc008f7ae",
    meta: {"ID":"a181780a-a202-42ee-a537-89cdc008f7ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|53b9bd6b-ecab-409e-b3e8-22b81c702f83|a1a1edaa-342b-4ab3-b61b-9b0ae2e86d28",
    path: "/product/a1a1edaa-342b-4ab3-b61b-9b0ae2e86d28",
    meta: {"ID":"a1a1edaa-342b-4ab3-b61b-9b0ae2e86d28","Title":"","GroupID":"53b9bd6b-ecab-409e-b3e8-22b81c702f83","GroupTitle":"Паста и Ризотто","GroupLink":"/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a1b56dca-ea40-4eaa-ba75-afb7228c2fbe",
    path: "/product/a1b56dca-ea40-4eaa-ba75-afb7228c2fbe",
    meta: {"ID":"a1b56dca-ea40-4eaa-ba75-afb7228c2fbe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|a1d8eb50-c880-4974-8e49-53f2ccf836b2",
    path: "/product/a1d8eb50-c880-4974-8e49-53f2ccf836b2",
    meta: {"ID":"a1d8eb50-c880-4974-8e49-53f2ccf836b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a1e60b07-5158-4725-84a3-274ceb9f4a4b",
    path: "/product/a1e60b07-5158-4725-84a3-274ceb9f4a4b",
    meta: {"ID":"a1e60b07-5158-4725-84a3-274ceb9f4a4b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|a1f9a71b-3b40-48f1-bbf6-67f44534c6d1",
    path: "/product/a1f9a71b-3b40-48f1-bbf6-67f44534c6d1",
    meta: {"ID":"a1f9a71b-3b40-48f1-bbf6-67f44534c6d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2d9bf239-b509-4129-9d84-16287bf039d0|a206c660-b2a0-42e5-8b41-3e31d1538280",
    path: "/product/a206c660-b2a0-42e5-8b41-3e31d1538280",
    meta: {"ID":"a206c660-b2a0-42e5-8b41-3e31d1538280","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|a2238228-0588-42ea-b2ec-5dba5f4a8b7d",
    path: "/product/a2238228-0588-42ea-b2ec-5dba5f4a8b7d",
    meta: {"ID":"a2238228-0588-42ea-b2ec-5dba5f4a8b7d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a22f98ce-7e2c-4dbb-a819-c1c051cfcc7a",
    path: "/product/a22f98ce-7e2c-4dbb-a819-c1c051cfcc7a",
    meta: {"ID":"a22f98ce-7e2c-4dbb-a819-c1c051cfcc7a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a2525ee3-cd70-4372-a744-2f36f3366a9b",
    path: "/product/a2525ee3-cd70-4372-a744-2f36f3366a9b",
    meta: {"ID":"a2525ee3-cd70-4372-a744-2f36f3366a9b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|a2699e5a-a764-47f8-98ec-18ad00df1b52",
    path: "/product/a2699e5a-a764-47f8-98ec-18ad00df1b52",
    meta: {"ID":"a2699e5a-a764-47f8-98ec-18ad00df1b52","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|a26a9fdf-2d68-4547-a4b4-d7e47b62eb72",
    path: "/product/a26a9fdf-2d68-4547-a4b4-d7e47b62eb72",
    meta: {"ID":"a26a9fdf-2d68-4547-a4b4-d7e47b62eb72","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|a27a7350-1dbc-4d32-853e-49710acdb1b9",
    path: "/product/a27a7350-1dbc-4d32-853e-49710acdb1b9",
    meta: {"ID":"a27a7350-1dbc-4d32-853e-49710acdb1b9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|535cfdf0-8f9a-46fd-9cdc-483647c40ce4|a289184b-168e-4363-9c49-db5f6fed1557",
    path: "/product/a289184b-168e-4363-9c49-db5f6fed1557",
    meta: {"ID":"a289184b-168e-4363-9c49-db5f6fed1557","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a294420e-1766-4724-b517-5e519371ee32",
    path: "/product/a294420e-1766-4724-b517-5e519371ee32",
    meta: {"ID":"a294420e-1766-4724-b517-5e519371ee32","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a2ab62b4-b9fd-4fbd-a880-ed3054f15005",
    path: "/product/a2ab62b4-b9fd-4fbd-a880-ed3054f15005",
    meta: {"ID":"a2ab62b4-b9fd-4fbd-a880-ed3054f15005","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a2abb3e2-baf6-4a7d-8dc5-ac2e5a312bbb",
    path: "/product/a2abb3e2-baf6-4a7d-8dc5-ac2e5a312bbb",
    meta: {"ID":"a2abb3e2-baf6-4a7d-8dc5-ac2e5a312bbb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7bdadf21-f387-4551-8ae2-d2b5ae64736e|a2bdcec7-b13e-408f-a7f3-2a1238a4d775",
    path: "/product/a2bdcec7-b13e-408f-a7f3-2a1238a4d775",
    meta: {"ID":"a2bdcec7-b13e-408f-a7f3-2a1238a4d775","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a2c76494-4863-4a8a-8f04-1e840956548b",
    path: "/product/a2c76494-4863-4a8a-8f04-1e840956548b",
    meta: {"ID":"a2c76494-4863-4a8a-8f04-1e840956548b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a2d04302-348d-437f-b343-a0d700fcf285",
    path: "/product/a2d04302-348d-437f-b343-a0d700fcf285",
    meta: {"ID":"a2d04302-348d-437f-b343-a0d700fcf285","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|a2d9e98e-d0d6-4ed2-8264-2a3d4f05012b",
    path: "/product/a2d9e98e-d0d6-4ed2-8264-2a3d4f05012b",
    meta: {"ID":"a2d9e98e-d0d6-4ed2-8264-2a3d4f05012b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73e4936d-d27c-44d6-85f2-bbd41d2ec26a|a3000f00-2148-4a7f-8e56-c993d5ac34e3",
    path: "/product/a3000f00-2148-4a7f-8e56-c993d5ac34e3",
    meta: {"ID":"a3000f00-2148-4a7f-8e56-c993d5ac34e3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|a392cfbc-f746-4f27-8034-e603449cc375",
    path: "/product/a392cfbc-f746-4f27-8034-e603449cc375",
    meta: {"ID":"a392cfbc-f746-4f27-8034-e603449cc375","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a3a1b158-374b-4dda-aea9-25e9aa3e7e4c",
    path: "/product/a3a1b158-374b-4dda-aea9-25e9aa3e7e4c",
    meta: {"ID":"a3a1b158-374b-4dda-aea9-25e9aa3e7e4c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a3af0d20-cbc7-45e8-be91-2ab5c5376685",
    path: "/product/a3af0d20-cbc7-45e8-be91-2ab5c5376685",
    meta: {"ID":"a3af0d20-cbc7-45e8-be91-2ab5c5376685","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|a3c63d8e-3edd-4d75-8e11-ed667c37dfbf",
    path: "/product/a3c63d8e-3edd-4d75-8e11-ed667c37dfbf",
    meta: {"ID":"a3c63d8e-3edd-4d75-8e11-ed667c37dfbf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|a3ed0e45-e697-46bd-8275-fc42c4bea593",
    path: "/product/a3ed0e45-e697-46bd-8275-fc42c4bea593",
    meta: {"ID":"a3ed0e45-e697-46bd-8275-fc42c4bea593","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a3ffc96c-de48-43b7-8494-752dda16d586",
    path: "/product/a3ffc96c-de48-43b7-8494-752dda16d586",
    meta: {"ID":"a3ffc96c-de48-43b7-8494-752dda16d586","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a40f3195-9fd8-4db1-be61-d64189d6ccd2",
    path: "/product/a40f3195-9fd8-4db1-be61-d64189d6ccd2",
    meta: {"ID":"a40f3195-9fd8-4db1-be61-d64189d6ccd2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|a42e6165-6056-45dc-ac13-50398cb87864",
    path: "/product/a42e6165-6056-45dc-ac13-50398cb87864",
    meta: {"ID":"a42e6165-6056-45dc-ac13-50398cb87864","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|a4345a66-182c-40d2-ac59-4e9d786d37e2",
    path: "/product/a4345a66-182c-40d2-ac59-4e9d786d37e2",
    meta: {"ID":"a4345a66-182c-40d2-ac59-4e9d786d37e2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|a43dde6d-da8d-4627-aa5b-3f72d1ed0d89",
    path: "/product/a43dde6d-da8d-4627-aa5b-3f72d1ed0d89",
    meta: {"ID":"a43dde6d-da8d-4627-aa5b-3f72d1ed0d89","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a43f08bb-3999-4037-94b8-ce73c013bd42",
    path: "/product/a43f08bb-3999-4037-94b8-ce73c013bd42",
    meta: {"ID":"a43f08bb-3999-4037-94b8-ce73c013bd42","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|a4583a11-ca33-48b8-99f2-938a20073da3",
    path: "/product/a4583a11-ca33-48b8-99f2-938a20073da3",
    meta: {"ID":"a4583a11-ca33-48b8-99f2-938a20073da3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|a48a1f0e-57e6-4153-add4-abad7271a283",
    path: "/product/a48a1f0e-57e6-4153-add4-abad7271a283",
    meta: {"ID":"a48a1f0e-57e6-4153-add4-abad7271a283","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|a495f65c-b071-4085-86f1-69f17601cd8c",
    path: "/product/a495f65c-b071-4085-86f1-69f17601cd8c",
    meta: {"ID":"a495f65c-b071-4085-86f1-69f17601cd8c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|a4b6fc14-9661-4d0f-9ae6-2cc2fd7e958c",
    path: "/product/a4b6fc14-9661-4d0f-9ae6-2cc2fd7e958c",
    meta: {"ID":"a4b6fc14-9661-4d0f-9ae6-2cc2fd7e958c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a4bcd295-4f43-485a-b857-61d3519bc505",
    path: "/product/a4bcd295-4f43-485a-b857-61d3519bc505",
    meta: {"ID":"a4bcd295-4f43-485a-b857-61d3519bc505","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|a4be2ff2-98f0-470d-bd79-082a62f2ec99",
    path: "/product/a4be2ff2-98f0-470d-bd79-082a62f2ec99",
    meta: {"ID":"a4be2ff2-98f0-470d-bd79-082a62f2ec99","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|a4c22b94-1343-4420-b5f8-475f141b0919",
    path: "/product/a4c22b94-1343-4420-b5f8-475f141b0919",
    meta: {"ID":"a4c22b94-1343-4420-b5f8-475f141b0919","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|a4e94f1f-4de7-47d0-9e86-8aa1e7af6f2e",
    path: "/product/a4e94f1f-4de7-47d0-9e86-8aa1e7af6f2e",
    meta: {"ID":"a4e94f1f-4de7-47d0-9e86-8aa1e7af6f2e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|53b9bd6b-ecab-409e-b3e8-22b81c702f83|a4f79d95-1085-41c4-a5a1-eb1bd4d87a48",
    path: "/product/a4f79d95-1085-41c4-a5a1-eb1bd4d87a48",
    meta: {"ID":"a4f79d95-1085-41c4-a5a1-eb1bd4d87a48","Title":"","GroupID":"53b9bd6b-ecab-409e-b3e8-22b81c702f83","GroupTitle":"Паста и Ризотто","GroupLink":"/menu/53b9bd6b-ecab-409e-b3e8-22b81c702f83","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|a520a1a2-a9d6-4581-9919-f2fddda3dce1",
    path: "/product/a520a1a2-a9d6-4581-9919-f2fddda3dce1",
    meta: {"ID":"a520a1a2-a9d6-4581-9919-f2fddda3dce1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|a5236ece-b54a-43fe-8e83-f06aec742096",
    path: "/product/a5236ece-b54a-43fe-8e83-f06aec742096",
    meta: {"ID":"a5236ece-b54a-43fe-8e83-f06aec742096","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a52a4c67-f415-425b-acc6-27f6b236a500",
    path: "/product/a52a4c67-f415-425b-acc6-27f6b236a500",
    meta: {"ID":"a52a4c67-f415-425b-acc6-27f6b236a500","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|a539918e-bbf2-4303-a362-4f63c8074554",
    path: "/product/a539918e-bbf2-4303-a362-4f63c8074554",
    meta: {"ID":"a539918e-bbf2-4303-a362-4f63c8074554","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a54e1c6c-cd45-415e-840d-0bb3e620e699",
    path: "/product/a54e1c6c-cd45-415e-840d-0bb3e620e699",
    meta: {"ID":"a54e1c6c-cd45-415e-840d-0bb3e620e699","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|a54fcb90-98ec-4ad1-b52b-3802e2ef9c59",
    path: "/product/a54fcb90-98ec-4ad1-b52b-3802e2ef9c59",
    meta: {"ID":"a54fcb90-98ec-4ad1-b52b-3802e2ef9c59","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a554bb43-fbaf-4ca3-816b-08035628b5a5",
    path: "/product/a554bb43-fbaf-4ca3-816b-08035628b5a5",
    meta: {"ID":"a554bb43-fbaf-4ca3-816b-08035628b5a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a5771a9c-6690-4098-956a-4d2f5f7a5a41",
    path: "/product/a5771a9c-6690-4098-956a-4d2f5f7a5a41",
    meta: {"ID":"a5771a9c-6690-4098-956a-4d2f5f7a5a41","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a5834c51-ba62-4923-889d-77ac72c2fc09",
    path: "/product/a5834c51-ba62-4923-889d-77ac72c2fc09",
    meta: {"ID":"a5834c51-ba62-4923-889d-77ac72c2fc09","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|a5d83d08-e1e6-47cc-8c9f-f368775b0d4f",
    path: "/product/a5d83d08-e1e6-47cc-8c9f-f368775b0d4f",
    meta: {"ID":"a5d83d08-e1e6-47cc-8c9f-f368775b0d4f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a5fa06a3-c896-4e23-b2b4-bfa7b4aeebbd",
    path: "/product/a5fa06a3-c896-4e23-b2b4-bfa7b4aeebbd",
    meta: {"ID":"a5fa06a3-c896-4e23-b2b4-bfa7b4aeebbd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|a648df86-e0a5-4d12-a2df-8e0bf7786184",
    path: "/product/a648df86-e0a5-4d12-a2df-8e0bf7786184",
    meta: {"ID":"a648df86-e0a5-4d12-a2df-8e0bf7786184","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a6493089-7d7a-4eca-ac59-c17eeb19c07a",
    path: "/product/a6493089-7d7a-4eca-ac59-c17eeb19c07a",
    meta: {"ID":"a6493089-7d7a-4eca-ac59-c17eeb19c07a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|a6a12874-61e2-48da-babc-869ac69bf368",
    path: "/product/a6a12874-61e2-48da-babc-869ac69bf368",
    meta: {"ID":"a6a12874-61e2-48da-babc-869ac69bf368","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a6c0b94a-25d7-4e62-9b00-18f3f93c8d6b",
    path: "/product/a6c0b94a-25d7-4e62-9b00-18f3f93c8d6b",
    meta: {"ID":"a6c0b94a-25d7-4e62-9b00-18f3f93c8d6b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|a6c71aa3-1776-4571-b95a-2fb99d6710aa",
    path: "/product/a6c71aa3-1776-4571-b95a-2fb99d6710aa",
    meta: {"ID":"a6c71aa3-1776-4571-b95a-2fb99d6710aa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|a6dabd8e-a367-4c6b-bb0d-d0de3038733a",
    path: "/product/a6dabd8e-a367-4c6b-bb0d-d0de3038733a",
    meta: {"ID":"a6dabd8e-a367-4c6b-bb0d-d0de3038733a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|a6ded28d-fc7f-454c-a69d-a43a3307cc28",
    path: "/product/a6ded28d-fc7f-454c-a69d-a43a3307cc28",
    meta: {"ID":"a6ded28d-fc7f-454c-a69d-a43a3307cc28","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|a6e7f046-d766-411f-b22f-275834e6fb45",
    path: "/product/a6e7f046-d766-411f-b22f-275834e6fb45",
    meta: {"ID":"a6e7f046-d766-411f-b22f-275834e6fb45","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a710fb54-e2af-4b17-bee7-0205812e3665",
    path: "/product/a710fb54-e2af-4b17-bee7-0205812e3665",
    meta: {"ID":"a710fb54-e2af-4b17-bee7-0205812e3665","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|a733595f-ae19-40b8-90a9-41caff59697c",
    path: "/product/a733595f-ae19-40b8-90a9-41caff59697c",
    meta: {"ID":"a733595f-ae19-40b8-90a9-41caff59697c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|a7356162-348d-4765-897c-5ccb9d22ded5",
    path: "/product/a7356162-348d-4765-897c-5ccb9d22ded5",
    meta: {"ID":"a7356162-348d-4765-897c-5ccb9d22ded5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|a736b92d-0707-4ef0-858e-84fa2fe0b2d3",
    path: "/product/a736b92d-0707-4ef0-858e-84fa2fe0b2d3",
    meta: {"ID":"a736b92d-0707-4ef0-858e-84fa2fe0b2d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a74dd8d4-3088-492d-a0db-ae816e951ef8",
    path: "/product/a74dd8d4-3088-492d-a0db-ae816e951ef8",
    meta: {"ID":"a74dd8d4-3088-492d-a0db-ae816e951ef8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|a74eb83e-c9d9-4dd5-a1b0-896226e8b38a",
    path: "/product/a74eb83e-c9d9-4dd5-a1b0-896226e8b38a",
    meta: {"ID":"a74eb83e-c9d9-4dd5-a1b0-896226e8b38a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|a754aaac-304d-4f3a-a590-1af578e91c7e",
    path: "/product/a754aaac-304d-4f3a-a590-1af578e91c7e",
    meta: {"ID":"a754aaac-304d-4f3a-a590-1af578e91c7e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a75bd793-774e-4bdd-8d40-dd69d046079d",
    path: "/product/a75bd793-774e-4bdd-8d40-dd69d046079d",
    meta: {"ID":"a75bd793-774e-4bdd-8d40-dd69d046079d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|a764fcff-a0bb-4740-bd41-46b8210b965c",
    path: "/product/a764fcff-a0bb-4740-bd41-46b8210b965c",
    meta: {"ID":"a764fcff-a0bb-4740-bd41-46b8210b965c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|a7694a19-fa05-466a-8ff2-529dee9fa63b",
    path: "/product/a7694a19-fa05-466a-8ff2-529dee9fa63b",
    meta: {"ID":"a7694a19-fa05-466a-8ff2-529dee9fa63b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|a777a680-6a9f-4e22-9a75-655b90662d45",
    path: "/product/a777a680-6a9f-4e22-9a75-655b90662d45",
    meta: {"ID":"a777a680-6a9f-4e22-9a75-655b90662d45","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|a78374a6-a9f9-4eed-a47a-c0c1d178b090",
    path: "/product/a78374a6-a9f9-4eed-a47a-c0c1d178b090",
    meta: {"ID":"a78374a6-a9f9-4eed-a47a-c0c1d178b090","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|a7c80720-1c0c-4096-882d-19682d996590",
    path: "/product/a7c80720-1c0c-4096-882d-19682d996590",
    meta: {"ID":"a7c80720-1c0c-4096-882d-19682d996590","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|a7fb8fb2-216b-4343-9fa9-8ffc54703400",
    path: "/product/a7fb8fb2-216b-4343-9fa9-8ffc54703400",
    meta: {"ID":"a7fb8fb2-216b-4343-9fa9-8ffc54703400","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|a80cf372-194e-45f2-a498-8a38ada89bcb",
    path: "/product/a80cf372-194e-45f2-a498-8a38ada89bcb",
    meta: {"ID":"a80cf372-194e-45f2-a498-8a38ada89bcb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|a817c85e-e5d7-4a30-941a-311148e52317",
    path: "/product/a817c85e-e5d7-4a30-941a-311148e52317",
    meta: {"ID":"a817c85e-e5d7-4a30-941a-311148e52317","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a826662e-1c8d-4976-ad4e-bee5a7f56849",
    path: "/product/a826662e-1c8d-4976-ad4e-bee5a7f56849",
    meta: {"ID":"a826662e-1c8d-4976-ad4e-bee5a7f56849","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2baa7814-309c-4d94-b87a-916c5430072b|a83105f4-3851-4493-91d0-c4a09f119eef",
    path: "/product/a83105f4-3851-4493-91d0-c4a09f119eef",
    meta: {"ID":"a83105f4-3851-4493-91d0-c4a09f119eef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|a854829a-7cbe-4d53-940f-d0c8845860d1",
    path: "/product/a854829a-7cbe-4d53-940f-d0c8845860d1",
    meta: {"ID":"a854829a-7cbe-4d53-940f-d0c8845860d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|a8963f11-8592-49ba-a2bc-b5bcc5dd1197",
    path: "/product/a8963f11-8592-49ba-a2bc-b5bcc5dd1197",
    meta: {"ID":"a8963f11-8592-49ba-a2bc-b5bcc5dd1197","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a8b16e03-d523-4992-a505-efc499c65933",
    path: "/product/a8b16e03-d523-4992-a505-efc499c65933",
    meta: {"ID":"a8b16e03-d523-4992-a505-efc499c65933","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a8c402c1-1823-46d9-ade9-decd017ec1b3",
    path: "/product/a8c402c1-1823-46d9-ade9-decd017ec1b3",
    meta: {"ID":"a8c402c1-1823-46d9-ade9-decd017ec1b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|a8f5768a-eb9d-41ec-add4-04d8ca6edc0a",
    path: "/product/a8f5768a-eb9d-41ec-add4-04d8ca6edc0a",
    meta: {"ID":"a8f5768a-eb9d-41ec-add4-04d8ca6edc0a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|a959f9e6-c9c5-4109-b121-566f2a9b453c",
    path: "/product/a959f9e6-c9c5-4109-b121-566f2a9b453c",
    meta: {"ID":"a959f9e6-c9c5-4109-b121-566f2a9b453c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|a99e2873-449f-4ebc-bb41-8b46648fcd91",
    path: "/product/a99e2873-449f-4ebc-bb41-8b46648fcd91",
    meta: {"ID":"a99e2873-449f-4ebc-bb41-8b46648fcd91","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a9dec812-1de4-466e-8d41-8041980dbb84",
    path: "/product/a9dec812-1de4-466e-8d41-8041980dbb84",
    meta: {"ID":"a9dec812-1de4-466e-8d41-8041980dbb84","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|a9ebc1d5-7444-4861-81ec-6c2b6780507b",
    path: "/product/a9ebc1d5-7444-4861-81ec-6c2b6780507b",
    meta: {"ID":"a9ebc1d5-7444-4861-81ec-6c2b6780507b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|aa025f60-eb56-4985-941a-b1b369a9a8c8",
    path: "/product/aa025f60-eb56-4985-941a-b1b369a9a8c8",
    meta: {"ID":"aa025f60-eb56-4985-941a-b1b369a9a8c8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|aa0c8743-5235-4d58-bd3d-07b958eb133a",
    path: "/product/aa0c8743-5235-4d58-bd3d-07b958eb133a",
    meta: {"ID":"aa0c8743-5235-4d58-bd3d-07b958eb133a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|aa0fed3a-d583-4837-a57f-143ab9625e5a",
    path: "/product/aa0fed3a-d583-4837-a57f-143ab9625e5a",
    meta: {"ID":"aa0fed3a-d583-4837-a57f-143ab9625e5a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|aa1565fc-b0cc-4f18-89e9-4bd2f32966ca",
    path: "/product/aa1565fc-b0cc-4f18-89e9-4bd2f32966ca",
    meta: {"ID":"aa1565fc-b0cc-4f18-89e9-4bd2f32966ca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|aa82868a-fdfc-4f66-94f9-a5f23ce4f125",
    path: "/product/aa82868a-fdfc-4f66-94f9-a5f23ce4f125",
    meta: {"ID":"aa82868a-fdfc-4f66-94f9-a5f23ce4f125","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|aabbd5c2-7e60-4f42-bef5-fc0ff546d897",
    path: "/product/aabbd5c2-7e60-4f42-bef5-fc0ff546d897",
    meta: {"ID":"aabbd5c2-7e60-4f42-bef5-fc0ff546d897","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ab119c8a-408c-489c-9730-73350f41c8b6",
    path: "/product/ab119c8a-408c-489c-9730-73350f41c8b6",
    meta: {"ID":"ab119c8a-408c-489c-9730-73350f41c8b6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ab256b43-c525-49b7-9a98-d0cb86d26824",
    path: "/product/ab256b43-c525-49b7-9a98-d0cb86d26824",
    meta: {"ID":"ab256b43-c525-49b7-9a98-d0cb86d26824","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|ab2574b6-3cbb-4bb3-80b6-61e2aa21e8c7",
    path: "/product/ab2574b6-3cbb-4bb3-80b6-61e2aa21e8c7",
    meta: {"ID":"ab2574b6-3cbb-4bb3-80b6-61e2aa21e8c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|ab6a2f65-64c6-4c58-9817-b5a351135338",
    path: "/product/ab6a2f65-64c6-4c58-9817-b5a351135338",
    meta: {"ID":"ab6a2f65-64c6-4c58-9817-b5a351135338","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|abe8899e-7f8c-49d1-94c7-43ea04ca4e54",
    path: "/product/abe8899e-7f8c-49d1-94c7-43ea04ca4e54",
    meta: {"ID":"abe8899e-7f8c-49d1-94c7-43ea04ca4e54","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|69d265a0-61df-4c8b-9631-e727b9d9fbe4|abede3db-6dd3-4eb9-afe1-9876ecb09a98",
    path: "/product/abede3db-6dd3-4eb9-afe1-9876ecb09a98",
    meta: {"ID":"abede3db-6dd3-4eb9-afe1-9876ecb09a98","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ac4c962b-2efa-4816-a738-2553d0276059",
    path: "/product/ac4c962b-2efa-4816-a738-2553d0276059",
    meta: {"ID":"ac4c962b-2efa-4816-a738-2553d0276059","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|ac8c56d8-d1f1-4086-9cc2-ab0c9bd52acf",
    path: "/product/ac8c56d8-d1f1-4086-9cc2-ab0c9bd52acf",
    meta: {"ID":"ac8c56d8-d1f1-4086-9cc2-ab0c9bd52acf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|aca298f7-1a2c-49c7-976c-346a82d5c1b0",
    path: "/product/aca298f7-1a2c-49c7-976c-346a82d5c1b0",
    meta: {"ID":"aca298f7-1a2c-49c7-976c-346a82d5c1b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|acb2ddcc-73bc-4be4-bdb9-a89731d05f1e",
    path: "/product/acb2ddcc-73bc-4be4-bdb9-a89731d05f1e",
    meta: {"ID":"acb2ddcc-73bc-4be4-bdb9-a89731d05f1e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|acbe0ad7-c65d-4cee-93c0-0ea308a7dda4",
    path: "/product/acbe0ad7-c65d-4cee-93c0-0ea308a7dda4",
    meta: {"ID":"acbe0ad7-c65d-4cee-93c0-0ea308a7dda4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|535cfdf0-8f9a-46fd-9cdc-483647c40ce4|acdab0fb-0a74-4779-be71-d6253b6730e9",
    path: "/product/acdab0fb-0a74-4779-be71-d6253b6730e9",
    meta: {"ID":"acdab0fb-0a74-4779-be71-d6253b6730e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ace5fab2-e685-48c0-8972-dccbb7141d0d",
    path: "/product/ace5fab2-e685-48c0-8972-dccbb7141d0d",
    meta: {"ID":"ace5fab2-e685-48c0-8972-dccbb7141d0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ad73b32e-b0fc-44f4-a9a2-ad880ad96b80",
    path: "/product/ad73b32e-b0fc-44f4-a9a2-ad880ad96b80",
    meta: {"ID":"ad73b32e-b0fc-44f4-a9a2-ad880ad96b80","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ad74afd4-1934-4c16-9740-70c0919c53e0",
    path: "/product/ad74afd4-1934-4c16-9740-70c0919c53e0",
    meta: {"ID":"ad74afd4-1934-4c16-9740-70c0919c53e0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|ad8b8f52-10fb-4b85-9ff4-a2e45e6bf7b6",
    path: "/product/ad8b8f52-10fb-4b85-9ff4-a2e45e6bf7b6",
    meta: {"ID":"ad8b8f52-10fb-4b85-9ff4-a2e45e6bf7b6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|ad8eb8e0-6846-417e-9b82-44f53b03e481",
    path: "/product/ad8eb8e0-6846-417e-9b82-44f53b03e481",
    meta: {"ID":"ad8eb8e0-6846-417e-9b82-44f53b03e481","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|ade7c632-b78d-4c78-83c8-d4284ea541a9",
    path: "/product/ade7c632-b78d-4c78-83c8-d4284ea541a9",
    meta: {"ID":"ade7c632-b78d-4c78-83c8-d4284ea541a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|ae07fdf7-5493-4713-8e41-1e98628927b8",
    path: "/product/ae07fdf7-5493-4713-8e41-1e98628927b8",
    meta: {"ID":"ae07fdf7-5493-4713-8e41-1e98628927b8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|ae493bb4-9231-46f8-8312-a3bd1ad26af1",
    path: "/product/ae493bb4-9231-46f8-8312-a3bd1ad26af1",
    meta: {"ID":"ae493bb4-9231-46f8-8312-a3bd1ad26af1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ae526fef-5ed4-463b-a9a0-fea727e6359a",
    path: "/product/ae526fef-5ed4-463b-a9a0-fea727e6359a",
    meta: {"ID":"ae526fef-5ed4-463b-a9a0-fea727e6359a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|ae568e97-87c1-4c35-a489-7ca8dc14a8ed",
    path: "/product/ae568e97-87c1-4c35-a489-7ca8dc14a8ed",
    meta: {"ID":"ae568e97-87c1-4c35-a489-7ca8dc14a8ed","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ae63e30e-9642-4ad2-890c-174b94a47a34",
    path: "/product/ae63e30e-9642-4ad2-890c-174b94a47a34",
    meta: {"ID":"ae63e30e-9642-4ad2-890c-174b94a47a34","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ae672485-82f9-4fa6-8c56-aca9b7a53cc7",
    path: "/product/ae672485-82f9-4fa6-8c56-aca9b7a53cc7",
    meta: {"ID":"ae672485-82f9-4fa6-8c56-aca9b7a53cc7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|ae6a8f22-cc3a-4956-b82d-26185296361f",
    path: "/product/ae6a8f22-cc3a-4956-b82d-26185296361f",
    meta: {"ID":"ae6a8f22-cc3a-4956-b82d-26185296361f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ae6aef42-336c-4304-ae9d-8e67143d86df",
    path: "/product/ae6aef42-336c-4304-ae9d-8e67143d86df",
    meta: {"ID":"ae6aef42-336c-4304-ae9d-8e67143d86df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ae8a6f2c-c355-4343-81d3-97ede52afee9",
    path: "/product/ae8a6f2c-c355-4343-81d3-97ede52afee9",
    meta: {"ID":"ae8a6f2c-c355-4343-81d3-97ede52afee9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|aedceac2-7cc2-43e4-b015-dc651e3e6428",
    path: "/product/aedceac2-7cc2-43e4-b015-dc651e3e6428",
    meta: {"ID":"aedceac2-7cc2-43e4-b015-dc651e3e6428","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|aee51df1-0321-4156-bf81-9640bd2156c9",
    path: "/product/aee51df1-0321-4156-bf81-9640bd2156c9",
    meta: {"ID":"aee51df1-0321-4156-bf81-9640bd2156c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|aef5d82a-a904-49ff-9c65-6876d25a722d",
    path: "/product/aef5d82a-a904-49ff-9c65-6876d25a722d",
    meta: {"ID":"aef5d82a-a904-49ff-9c65-6876d25a722d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|af0a7dc6-0634-4b18-b386-e8031b43147b",
    path: "/product/af0a7dc6-0634-4b18-b386-e8031b43147b",
    meta: {"ID":"af0a7dc6-0634-4b18-b386-e8031b43147b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|af123ef1-0a7f-45c4-a7ef-cf97b1f6b433",
    path: "/product/af123ef1-0a7f-45c4-a7ef-cf97b1f6b433",
    meta: {"ID":"af123ef1-0a7f-45c4-a7ef-cf97b1f6b433","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4626112c-772c-43a4-83b9-fc5d6a1a2597|af4fd98f-6c15-407b-93e8-fdf0de27b17d",
    path: "/product/af4fd98f-6c15-407b-93e8-fdf0de27b17d",
    meta: {"ID":"af4fd98f-6c15-407b-93e8-fdf0de27b17d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|af52618e-8279-4c82-9ef3-cdfb88c4d6d0",
    path: "/product/af52618e-8279-4c82-9ef3-cdfb88c4d6d0",
    meta: {"ID":"af52618e-8279-4c82-9ef3-cdfb88c4d6d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|82890741-f41f-4593-bd5b-e63c6af5f1ed|af5b0f59-8d78-4d6e-996b-9b6b751b54fe",
    path: "/product/af5b0f59-8d78-4d6e-996b-9b6b751b54fe",
    meta: {"ID":"af5b0f59-8d78-4d6e-996b-9b6b751b54fe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|af7cc6af-153e-43d4-a9de-c73e75765b8e",
    path: "/product/af7cc6af-153e-43d4-a9de-c73e75765b8e",
    meta: {"ID":"af7cc6af-153e-43d4-a9de-c73e75765b8e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73d8e7e8-a7d6-4cde-900b-feb2938a1fcb|afcbcbe3-6ad4-4793-afa8-d15dd1f5acc3",
    path: "/product/afcbcbe3-6ad4-4793-afa8-d15dd1f5acc3",
    meta: {"ID":"afcbcbe3-6ad4-4793-afa8-d15dd1f5acc3","Title":"","GroupID":"73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","GroupTitle":"Подарочные сертификаты","GroupLink":"/menu/73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|afe983e3-e39e-41e7-809f-e7adf17717f4",
    path: "/product/afe983e3-e39e-41e7-809f-e7adf17717f4",
    meta: {"ID":"afe983e3-e39e-41e7-809f-e7adf17717f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|535cfdf0-8f9a-46fd-9cdc-483647c40ce4|aff4d835-1557-41f3-8c41-589dd6c460bb",
    path: "/product/aff4d835-1557-41f3-8c41-589dd6c460bb",
    meta: {"ID":"aff4d835-1557-41f3-8c41-589dd6c460bb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b00de98d-a91e-48f2-a1b8-6ff2b0731b7a",
    path: "/product/b00de98d-a91e-48f2-a1b8-6ff2b0731b7a",
    meta: {"ID":"b00de98d-a91e-48f2-a1b8-6ff2b0731b7a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b01dcfaf-6ed9-41df-af61-fb48dc9611c8",
    path: "/product/b01dcfaf-6ed9-41df-af61-fb48dc9611c8",
    meta: {"ID":"b01dcfaf-6ed9-41df-af61-fb48dc9611c8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|b02cabbf-f026-4971-80ef-7217418eac3b",
    path: "/product/b02cabbf-f026-4971-80ef-7217418eac3b",
    meta: {"ID":"b02cabbf-f026-4971-80ef-7217418eac3b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|b031fb2e-26b1-4d71-adfc-c314ed085d16",
    path: "/product/b031fb2e-26b1-4d71-adfc-c314ed085d16",
    meta: {"ID":"b031fb2e-26b1-4d71-adfc-c314ed085d16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|b03a66ab-f345-41ae-9329-c1d267f042b3",
    path: "/product/b03a66ab-f345-41ae-9329-c1d267f042b3",
    meta: {"ID":"b03a66ab-f345-41ae-9329-c1d267f042b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b040dcb1-0981-40b8-9455-1eccf951dc95",
    path: "/product/b040dcb1-0981-40b8-9455-1eccf951dc95",
    meta: {"ID":"b040dcb1-0981-40b8-9455-1eccf951dc95","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|b05776f2-97cc-46af-83df-abdd3f73e9c9",
    path: "/product/b05776f2-97cc-46af-83df-abdd3f73e9c9",
    meta: {"ID":"b05776f2-97cc-46af-83df-abdd3f73e9c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b060e0a9-a829-490e-a90e-536ee9663ad6",
    path: "/product/b060e0a9-a829-490e-a90e-536ee9663ad6",
    meta: {"ID":"b060e0a9-a829-490e-a90e-536ee9663ad6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|b083fc3e-c6af-4ea2-b941-5f6632cc95f2",
    path: "/product/b083fc3e-c6af-4ea2-b941-5f6632cc95f2",
    meta: {"ID":"b083fc3e-c6af-4ea2-b941-5f6632cc95f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b09947cc-1835-42cf-8852-d1a248eaf40c",
    path: "/product/b09947cc-1835-42cf-8852-d1a248eaf40c",
    meta: {"ID":"b09947cc-1835-42cf-8852-d1a248eaf40c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b09c7d19-fd5c-499a-9bb2-1b304163503a",
    path: "/product/b09c7d19-fd5c-499a-9bb2-1b304163503a",
    meta: {"ID":"b09c7d19-fd5c-499a-9bb2-1b304163503a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b0b84c0b-c22b-42ee-bcef-e90086bf9269",
    path: "/product/b0b84c0b-c22b-42ee-bcef-e90086bf9269",
    meta: {"ID":"b0b84c0b-c22b-42ee-bcef-e90086bf9269","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b0de1477-42d0-4745-a3ea-e9b1de0786e7",
    path: "/product/b0de1477-42d0-4745-a3ea-e9b1de0786e7",
    meta: {"ID":"b0de1477-42d0-4745-a3ea-e9b1de0786e7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b14d18f3-98bf-48b9-acfd-a9f98c6be6e4",
    path: "/product/b14d18f3-98bf-48b9-acfd-a9f98c6be6e4",
    meta: {"ID":"b14d18f3-98bf-48b9-acfd-a9f98c6be6e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b183f55d-2a54-450d-affe-36fd1e92b863",
    path: "/product/b183f55d-2a54-450d-affe-36fd1e92b863",
    meta: {"ID":"b183f55d-2a54-450d-affe-36fd1e92b863","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b188ded1-1f45-4840-9489-773dba098474",
    path: "/product/b188ded1-1f45-4840-9489-773dba098474",
    meta: {"ID":"b188ded1-1f45-4840-9489-773dba098474","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b1c58f35-0232-495a-8f8c-1b85630e95e4",
    path: "/product/b1c58f35-0232-495a-8f8c-1b85630e95e4",
    meta: {"ID":"b1c58f35-0232-495a-8f8c-1b85630e95e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|91808147-730a-41a6-833d-850beb93d8de|b1d5defe-c86e-438f-9ede-634f095ad0f2",
    path: "/product/b1d5defe-c86e-438f-9ede-634f095ad0f2",
    meta: {"ID":"b1d5defe-c86e-438f-9ede-634f095ad0f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b1fa8bf5-2501-4ece-96d2-c7c76e32eec3",
    path: "/product/b1fa8bf5-2501-4ece-96d2-c7c76e32eec3",
    meta: {"ID":"b1fa8bf5-2501-4ece-96d2-c7c76e32eec3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b215edce-3b6a-4420-b602-5ccc214e98f4",
    path: "/product/b215edce-3b6a-4420-b602-5ccc214e98f4",
    meta: {"ID":"b215edce-3b6a-4420-b602-5ccc214e98f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|b228bdc3-96bc-4bec-ba95-25c6dd9caeaf",
    path: "/product/b228bdc3-96bc-4bec-ba95-25c6dd9caeaf",
    meta: {"ID":"b228bdc3-96bc-4bec-ba95-25c6dd9caeaf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b231a3da-e82a-45d9-b8cb-f12aaf7e58e9",
    path: "/product/b231a3da-e82a-45d9-b8cb-f12aaf7e58e9",
    meta: {"ID":"b231a3da-e82a-45d9-b8cb-f12aaf7e58e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|69d265a0-61df-4c8b-9631-e727b9d9fbe4|b2665157-f62d-466e-9787-38f3262d581d",
    path: "/product/b2665157-f62d-466e-9787-38f3262d581d",
    meta: {"ID":"b2665157-f62d-466e-9787-38f3262d581d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b28846ef-e638-4604-8475-d49dd9cee474",
    path: "/product/b28846ef-e638-4604-8475-d49dd9cee474",
    meta: {"ID":"b28846ef-e638-4604-8475-d49dd9cee474","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3c06d685-8171-454b-920a-f3a76b0a4226|b298c5c1-ce4c-4e97-8951-f2b18343c4c0",
    path: "/product/b298c5c1-ce4c-4e97-8951-f2b18343c4c0",
    meta: {"ID":"b298c5c1-ce4c-4e97-8951-f2b18343c4c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b2c20ea2-60c5-4c43-ba2d-5c9e0e8eae30",
    path: "/product/b2c20ea2-60c5-4c43-ba2d-5c9e0e8eae30",
    meta: {"ID":"b2c20ea2-60c5-4c43-ba2d-5c9e0e8eae30","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|b2dca91a-980d-457a-869a-c0610da8f569",
    path: "/product/b2dca91a-980d-457a-869a-c0610da8f569",
    meta: {"ID":"b2dca91a-980d-457a-869a-c0610da8f569","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ef0a0b3c-71b8-40c4-9f16-3c9038b9738d|b30a00f7-fa12-4f5c-806b-d5855d66984b",
    path: "/product/b30a00f7-fa12-4f5c-806b-d5855d66984b",
    meta: {"ID":"b30a00f7-fa12-4f5c-806b-d5855d66984b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|b30bbd83-9a46-4daa-bf4e-8f41b7f7d6b8",
    path: "/product/b30bbd83-9a46-4daa-bf4e-8f41b7f7d6b8",
    meta: {"ID":"b30bbd83-9a46-4daa-bf4e-8f41b7f7d6b8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b310ebcb-96aa-4ba3-b120-057b36f3f600",
    path: "/product/b310ebcb-96aa-4ba3-b120-057b36f3f600",
    meta: {"ID":"b310ebcb-96aa-4ba3-b120-057b36f3f600","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b317728d-176b-4b9c-b25b-e240c6b61079",
    path: "/product/b317728d-176b-4b9c-b25b-e240c6b61079",
    meta: {"ID":"b317728d-176b-4b9c-b25b-e240c6b61079","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b32e94c1-9db9-4e25-ad31-74f1e45eaadf",
    path: "/product/b32e94c1-9db9-4e25-ad31-74f1e45eaadf",
    meta: {"ID":"b32e94c1-9db9-4e25-ad31-74f1e45eaadf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b33fed06-a139-4121-9887-e57a37416848",
    path: "/product/b33fed06-a139-4121-9887-e57a37416848",
    meta: {"ID":"b33fed06-a139-4121-9887-e57a37416848","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5cb1bd94-d83d-4b23-9469-0ddc318e5ee6|b34ee506-2c1f-437b-a023-d9b8ce75a83f",
    path: "/product/b34ee506-2c1f-437b-a023-d9b8ce75a83f",
    meta: {"ID":"b34ee506-2c1f-437b-a023-d9b8ce75a83f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca1c41-f8fc-4cd3-9788-4fe61c89225d|b359ff52-5ffb-4707-b9f0-a7a308029a5f",
    path: "/product/b359ff52-5ffb-4707-b9f0-a7a308029a5f",
    meta: {"ID":"b359ff52-5ffb-4707-b9f0-a7a308029a5f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b37a56f8-e1ca-4b9b-bb39-3e4b2c635d9a",
    path: "/product/b37a56f8-e1ca-4b9b-bb39-3e4b2c635d9a",
    meta: {"ID":"b37a56f8-e1ca-4b9b-bb39-3e4b2c635d9a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b39555bb-1733-4b1f-90e7-bd62ab7be65e",
    path: "/product/b39555bb-1733-4b1f-90e7-bd62ab7be65e",
    meta: {"ID":"b39555bb-1733-4b1f-90e7-bd62ab7be65e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|b3a4520c-49c1-4db6-a734-1e122780c17b",
    path: "/product/b3a4520c-49c1-4db6-a734-1e122780c17b",
    meta: {"ID":"b3a4520c-49c1-4db6-a734-1e122780c17b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b3a71ef0-3cd8-4512-a084-ed2805857c97",
    path: "/product/b3a71ef0-3cd8-4512-a084-ed2805857c97",
    meta: {"ID":"b3a71ef0-3cd8-4512-a084-ed2805857c97","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|57b1574f-a331-4e33-aedc-684887d5530e|b3abb3a4-2356-44ba-a082-6774e56fdf9b",
    path: "/product/b3abb3a4-2356-44ba-a082-6774e56fdf9b",
    meta: {"ID":"b3abb3a4-2356-44ba-a082-6774e56fdf9b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b3c0681c-06af-43aa-837b-c978fa0ae7ad",
    path: "/product/b3c0681c-06af-43aa-837b-c978fa0ae7ad",
    meta: {"ID":"b3c0681c-06af-43aa-837b-c978fa0ae7ad","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|b3c3f606-ebc6-477d-8736-03fe958a71eb",
    path: "/product/b3c3f606-ebc6-477d-8736-03fe958a71eb",
    meta: {"ID":"b3c3f606-ebc6-477d-8736-03fe958a71eb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|b3d58849-8d9a-470e-a10f-b99ac8f97f42",
    path: "/product/b3d58849-8d9a-470e-a10f-b99ac8f97f42",
    meta: {"ID":"b3d58849-8d9a-470e-a10f-b99ac8f97f42","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b4046b9d-b0c8-46b4-b143-eaae038c8568",
    path: "/product/b4046b9d-b0c8-46b4-b143-eaae038c8568",
    meta: {"ID":"b4046b9d-b0c8-46b4-b143-eaae038c8568","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b40ad069-a6be-4cec-9b6d-3060e286b183",
    path: "/product/b40ad069-a6be-4cec-9b6d-3060e286b183",
    meta: {"ID":"b40ad069-a6be-4cec-9b6d-3060e286b183","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|b411debd-b7d0-4127-85dc-1e563531782a",
    path: "/product/b411debd-b7d0-4127-85dc-1e563531782a",
    meta: {"ID":"b411debd-b7d0-4127-85dc-1e563531782a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b42d0417-be12-4be8-a6a0-794044fc10c0",
    path: "/product/b42d0417-be12-4be8-a6a0-794044fc10c0",
    meta: {"ID":"b42d0417-be12-4be8-a6a0-794044fc10c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|b439d694-95e9-4d07-9fa3-17515569cb53",
    path: "/product/b439d694-95e9-4d07-9fa3-17515569cb53",
    meta: {"ID":"b439d694-95e9-4d07-9fa3-17515569cb53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b445710c-e332-48df-9e58-dcfa420c2924",
    path: "/product/b445710c-e332-48df-9e58-dcfa420c2924",
    meta: {"ID":"b445710c-e332-48df-9e58-dcfa420c2924","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b462317a-dc0f-425b-99eb-42b1c1a99cd6",
    path: "/product/b462317a-dc0f-425b-99eb-42b1c1a99cd6",
    meta: {"ID":"b462317a-dc0f-425b-99eb-42b1c1a99cd6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b46d1b9c-94a2-415c-be8c-dd0a132b6a3f",
    path: "/product/b46d1b9c-94a2-415c-be8c-dd0a132b6a3f",
    meta: {"ID":"b46d1b9c-94a2-415c-be8c-dd0a132b6a3f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|b491bd98-6374-4dfd-aad6-9625a5c1ea2d",
    path: "/product/b491bd98-6374-4dfd-aad6-9625a5c1ea2d",
    meta: {"ID":"b491bd98-6374-4dfd-aad6-9625a5c1ea2d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|b49a3291-e6c7-4821-b364-6baaed664078",
    path: "/product/b49a3291-e6c7-4821-b364-6baaed664078",
    meta: {"ID":"b49a3291-e6c7-4821-b364-6baaed664078","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|b4c86e1a-5b7a-49eb-bd1e-9111f8668ada",
    path: "/product/b4c86e1a-5b7a-49eb-bd1e-9111f8668ada",
    meta: {"ID":"b4c86e1a-5b7a-49eb-bd1e-9111f8668ada","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b4d08f22-2d28-451f-99f3-897809d1ac89",
    path: "/product/b4d08f22-2d28-451f-99f3-897809d1ac89",
    meta: {"ID":"b4d08f22-2d28-451f-99f3-897809d1ac89","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b4e74358-0a0e-4ac6-9689-3d7abb54bd28",
    path: "/product/b4e74358-0a0e-4ac6-9689-3d7abb54bd28",
    meta: {"ID":"b4e74358-0a0e-4ac6-9689-3d7abb54bd28","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|b4ee6948-5948-4fb3-9148-f123536fea94",
    path: "/product/b4ee6948-5948-4fb3-9148-f123536fea94",
    meta: {"ID":"b4ee6948-5948-4fb3-9148-f123536fea94","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b4f7f7b4-f417-412e-b75b-3223da3fc6f1",
    path: "/product/b4f7f7b4-f417-412e-b75b-3223da3fc6f1",
    meta: {"ID":"b4f7f7b4-f417-412e-b75b-3223da3fc6f1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|d586fdfe-fc1e-4037-89d8-4ebed69e1496|b50144b5-5709-4b4d-bfec-d3ab0127fe7b",
    path: "/product/b50144b5-5709-4b4d-bfec-d3ab0127fe7b",
    meta: {"ID":"b50144b5-5709-4b4d-bfec-d3ab0127fe7b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|db514044-cc74-4cb4-88c1-e0d3608fecb6|b509b7e3-4245-4a99-a8ab-0b031d648c40",
    path: "/product/b509b7e3-4245-4a99-a8ab-0b031d648c40",
    meta: {"ID":"b509b7e3-4245-4a99-a8ab-0b031d648c40","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|b51222ac-4cd4-4619-8432-71853268d545",
    path: "/product/b51222ac-4cd4-4619-8432-71853268d545",
    meta: {"ID":"b51222ac-4cd4-4619-8432-71853268d545","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b5171436-2dca-451a-b414-76d1c66ddc36",
    path: "/product/b5171436-2dca-451a-b414-76d1c66ddc36",
    meta: {"ID":"b5171436-2dca-451a-b414-76d1c66ddc36","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b5247fff-0a52-446c-800d-54919ccbdccd",
    path: "/product/b5247fff-0a52-446c-800d-54919ccbdccd",
    meta: {"ID":"b5247fff-0a52-446c-800d-54919ccbdccd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b5298b7f-5a2d-475f-a016-309c00d82ea7",
    path: "/product/b5298b7f-5a2d-475f-a016-309c00d82ea7",
    meta: {"ID":"b5298b7f-5a2d-475f-a016-309c00d82ea7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b52b1b0f-695a-48e0-867f-9c5275f6aaf2",
    path: "/product/b52b1b0f-695a-48e0-867f-9c5275f6aaf2",
    meta: {"ID":"b52b1b0f-695a-48e0-867f-9c5275f6aaf2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|b542d02b-03ee-4df1-944c-5186ffae2668",
    path: "/product/b542d02b-03ee-4df1-944c-5186ffae2668",
    meta: {"ID":"b542d02b-03ee-4df1-944c-5186ffae2668","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b54f33d6-8627-4f0c-b8f4-209f0de36cfd",
    path: "/product/b54f33d6-8627-4f0c-b8f4-209f0de36cfd",
    meta: {"ID":"b54f33d6-8627-4f0c-b8f4-209f0de36cfd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b5557a3f-4fec-439a-9a60-120bb667e09b",
    path: "/product/b5557a3f-4fec-439a-9a60-120bb667e09b",
    meta: {"ID":"b5557a3f-4fec-439a-9a60-120bb667e09b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73d8e7e8-a7d6-4cde-900b-feb2938a1fcb|b5840f34-28cf-4418-85c3-81b902607708",
    path: "/product/b5840f34-28cf-4418-85c3-81b902607708",
    meta: {"ID":"b5840f34-28cf-4418-85c3-81b902607708","Title":"","GroupID":"73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","GroupTitle":"Подарочные сертификаты","GroupLink":"/menu/73d8e7e8-a7d6-4cde-900b-feb2938a1fcb","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b66d217c-e7db-478d-b12e-58da9d910874",
    path: "/product/b66d217c-e7db-478d-b12e-58da9d910874",
    meta: {"ID":"b66d217c-e7db-478d-b12e-58da9d910874","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b68118b9-3246-4e7a-b49a-275b86e92bdf",
    path: "/product/b68118b9-3246-4e7a-b49a-275b86e92bdf",
    meta: {"ID":"b68118b9-3246-4e7a-b49a-275b86e92bdf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b6e11935-f547-4958-9b39-1e240d2d1ac7",
    path: "/product/b6e11935-f547-4958-9b39-1e240d2d1ac7",
    meta: {"ID":"b6e11935-f547-4958-9b39-1e240d2d1ac7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b6ecedfc-52e6-4a10-8502-c6dc59012ad4",
    path: "/product/b6ecedfc-52e6-4a10-8502-c6dc59012ad4",
    meta: {"ID":"b6ecedfc-52e6-4a10-8502-c6dc59012ad4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b700c1dd-5b43-498d-b965-f9ee48b0aefa",
    path: "/product/b700c1dd-5b43-498d-b965-f9ee48b0aefa",
    meta: {"ID":"b700c1dd-5b43-498d-b965-f9ee48b0aefa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b70ed7c2-a306-47ed-93e4-3b599e99ec64",
    path: "/product/b70ed7c2-a306-47ed-93e4-3b599e99ec64",
    meta: {"ID":"b70ed7c2-a306-47ed-93e4-3b599e99ec64","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b72b0264-eaf6-4bd9-b5be-29896a9f7984",
    path: "/product/b72b0264-eaf6-4bd9-b5be-29896a9f7984",
    meta: {"ID":"b72b0264-eaf6-4bd9-b5be-29896a9f7984","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|b75c19a1-cb88-48c6-a67d-0cd22c22ecd2",
    path: "/product/b75c19a1-cb88-48c6-a67d-0cd22c22ecd2",
    meta: {"ID":"b75c19a1-cb88-48c6-a67d-0cd22c22ecd2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b77bd1e4-7648-40eb-be31-5da682eac520",
    path: "/product/b77bd1e4-7648-40eb-be31-5da682eac520",
    meta: {"ID":"b77bd1e4-7648-40eb-be31-5da682eac520","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|b793e533-f31d-4933-9b96-01ce22bb2dcb",
    path: "/product/b793e533-f31d-4933-9b96-01ce22bb2dcb",
    meta: {"ID":"b793e533-f31d-4933-9b96-01ce22bb2dcb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|b7aacdc2-503f-481c-b9bd-29dec7c2b06b",
    path: "/product/b7aacdc2-503f-481c-b9bd-29dec7c2b06b",
    meta: {"ID":"b7aacdc2-503f-481c-b9bd-29dec7c2b06b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b7d65c18-b5d8-464d-8eaa-cf351e55e33a",
    path: "/product/b7d65c18-b5d8-464d-8eaa-cf351e55e33a",
    meta: {"ID":"b7d65c18-b5d8-464d-8eaa-cf351e55e33a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b7e4b51a-c52e-40ab-9448-700aa4a5aa26",
    path: "/product/b7e4b51a-c52e-40ab-9448-700aa4a5aa26",
    meta: {"ID":"b7e4b51a-c52e-40ab-9448-700aa4a5aa26","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b80b79d3-4c6b-4ebd-a210-7b738251bd5f",
    path: "/product/b80b79d3-4c6b-4ebd-a210-7b738251bd5f",
    meta: {"ID":"b80b79d3-4c6b-4ebd-a210-7b738251bd5f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|b80cb457-4e90-40f0-aae1-660117328ebf",
    path: "/product/b80cb457-4e90-40f0-aae1-660117328ebf",
    meta: {"ID":"b80cb457-4e90-40f0-aae1-660117328ebf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|b80d326b-6ead-4851-ac92-8c99b110de0b",
    path: "/product/b80d326b-6ead-4851-ac92-8c99b110de0b",
    meta: {"ID":"b80d326b-6ead-4851-ac92-8c99b110de0b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|b815f900-0bdb-4f2c-9acd-b5df1dae8efa",
    path: "/product/b815f900-0bdb-4f2c-9acd-b5df1dae8efa",
    meta: {"ID":"b815f900-0bdb-4f2c-9acd-b5df1dae8efa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b81bf25e-d433-4faa-920c-29befa0ad6db",
    path: "/product/b81bf25e-d433-4faa-920c-29befa0ad6db",
    meta: {"ID":"b81bf25e-d433-4faa-920c-29befa0ad6db","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|b82c3b03-caea-40f0-8261-eb45ea1cda33",
    path: "/product/b82c3b03-caea-40f0-8261-eb45ea1cda33",
    meta: {"ID":"b82c3b03-caea-40f0-8261-eb45ea1cda33","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b852e5eb-f027-4eea-a1b9-03e14187f6ce",
    path: "/product/b852e5eb-f027-4eea-a1b9-03e14187f6ce",
    meta: {"ID":"b852e5eb-f027-4eea-a1b9-03e14187f6ce","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b860e551-50ec-4de9-9d10-81ba99c41938",
    path: "/product/b860e551-50ec-4de9-9d10-81ba99c41938",
    meta: {"ID":"b860e551-50ec-4de9-9d10-81ba99c41938","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b87aadf5-3379-47f9-93c9-e0461bd2a6a7",
    path: "/product/b87aadf5-3379-47f9-93c9-e0461bd2a6a7",
    meta: {"ID":"b87aadf5-3379-47f9-93c9-e0461bd2a6a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b8b5f8ed-029f-4949-8ab5-c6ec2316003e",
    path: "/product/b8b5f8ed-029f-4949-8ab5-c6ec2316003e",
    meta: {"ID":"b8b5f8ed-029f-4949-8ab5-c6ec2316003e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|b8eafd0e-ab69-40ce-ab7e-ec143183f2fb",
    path: "/product/b8eafd0e-ab69-40ce-ab7e-ec143183f2fb",
    meta: {"ID":"b8eafd0e-ab69-40ce-ab7e-ec143183f2fb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b9054025-6623-48aa-ae3f-b4113473d3be",
    path: "/product/b9054025-6623-48aa-ae3f-b4113473d3be",
    meta: {"ID":"b9054025-6623-48aa-ae3f-b4113473d3be","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|b9133716-a1bd-4648-a5d1-5e49e2d6b37b",
    path: "/product/b9133716-a1bd-4648-a5d1-5e49e2d6b37b",
    meta: {"ID":"b9133716-a1bd-4648-a5d1-5e49e2d6b37b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b91726aa-4ae0-4ba6-9207-7e909e3748c4",
    path: "/product/b91726aa-4ae0-4ba6-9207-7e909e3748c4",
    meta: {"ID":"b91726aa-4ae0-4ba6-9207-7e909e3748c4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|b956a967-4b75-4753-9b2a-1b826a58b294",
    path: "/product/b956a967-4b75-4753-9b2a-1b826a58b294",
    meta: {"ID":"b956a967-4b75-4753-9b2a-1b826a58b294","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b95a60d2-2fdb-4867-a872-bc0331be748d",
    path: "/product/b95a60d2-2fdb-4867-a872-bc0331be748d",
    meta: {"ID":"b95a60d2-2fdb-4867-a872-bc0331be748d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b961f49b-dc8f-43c0-8be5-40fe9e7b9674",
    path: "/product/b961f49b-dc8f-43c0-8be5-40fe9e7b9674",
    meta: {"ID":"b961f49b-dc8f-43c0-8be5-40fe9e7b9674","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b977b966-ad32-43c4-ae1a-ff404569aa5d",
    path: "/product/b977b966-ad32-43c4-ae1a-ff404569aa5d",
    meta: {"ID":"b977b966-ad32-43c4-ae1a-ff404569aa5d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|b988110d-42b7-4e20-8087-2c396c884a2d",
    path: "/product/b988110d-42b7-4e20-8087-2c396c884a2d",
    meta: {"ID":"b988110d-42b7-4e20-8087-2c396c884a2d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|b9bc1e94-1fd7-447d-b65c-89a8436b34f9",
    path: "/product/b9bc1e94-1fd7-447d-b65c-89a8436b34f9",
    meta: {"ID":"b9bc1e94-1fd7-447d-b65c-89a8436b34f9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b9c0b819-a7fa-40d2-8f91-3c59a5cb9ced",
    path: "/product/b9c0b819-a7fa-40d2-8f91-3c59a5cb9ced",
    meta: {"ID":"b9c0b819-a7fa-40d2-8f91-3c59a5cb9ced","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b9d254c6-1f35-4af2-9975-3ef16e5f7416",
    path: "/product/b9d254c6-1f35-4af2-9975-3ef16e5f7416",
    meta: {"ID":"b9d254c6-1f35-4af2-9975-3ef16e5f7416","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|b9d2ca65-8fa4-47c2-b921-321b980b1a33",
    path: "/product/b9d2ca65-8fa4-47c2-b921-321b980b1a33",
    meta: {"ID":"b9d2ca65-8fa4-47c2-b921-321b980b1a33","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b9d420d0-621e-4f31-92a2-bf966e8b0b20",
    path: "/product/b9d420d0-621e-4f31-92a2-bf966e8b0b20",
    meta: {"ID":"b9d420d0-621e-4f31-92a2-bf966e8b0b20","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|b9e58333-d321-4d73-89ce-e7737c47ef60",
    path: "/product/b9e58333-d321-4d73-89ce-e7737c47ef60",
    meta: {"ID":"b9e58333-d321-4d73-89ce-e7737c47ef60","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|b9fed2c3-1b77-4cd1-a01c-f9e499ce07f5",
    path: "/product/b9fed2c3-1b77-4cd1-a01c-f9e499ce07f5",
    meta: {"ID":"b9fed2c3-1b77-4cd1-a01c-f9e499ce07f5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ba0f7a15-da92-400d-8852-b1af2d7d11cc",
    path: "/product/ba0f7a15-da92-400d-8852-b1af2d7d11cc",
    meta: {"ID":"ba0f7a15-da92-400d-8852-b1af2d7d11cc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|735f10a8-dcc4-47d5-9639-e55e0af97024|ba13647c-9a86-4d48-9279-64adbdd88bfe",
    path: "/product/ba13647c-9a86-4d48-9279-64adbdd88bfe",
    meta: {"ID":"ba13647c-9a86-4d48-9279-64adbdd88bfe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|ba1a2f0b-052f-4b48-8f1b-6403fc943df5",
    path: "/product/ba1a2f0b-052f-4b48-8f1b-6403fc943df5",
    meta: {"ID":"ba1a2f0b-052f-4b48-8f1b-6403fc943df5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ba2a9a41-dedc-48fa-af8c-9c058a103bed",
    path: "/product/ba2a9a41-dedc-48fa-af8c-9c058a103bed",
    meta: {"ID":"ba2a9a41-dedc-48fa-af8c-9c058a103bed","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ba347747-617a-4e20-83f5-264a84c06701",
    path: "/product/ba347747-617a-4e20-83f5-264a84c06701",
    meta: {"ID":"ba347747-617a-4e20-83f5-264a84c06701","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ba398bf0-4246-468b-91ac-97c1cead6d7e",
    path: "/product/ba398bf0-4246-468b-91ac-97c1cead6d7e",
    meta: {"ID":"ba398bf0-4246-468b-91ac-97c1cead6d7e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|ba39f75c-9c91-4463-8f27-667d297d4804",
    path: "/product/ba39f75c-9c91-4463-8f27-667d297d4804",
    meta: {"ID":"ba39f75c-9c91-4463-8f27-667d297d4804","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ba42f0eb-5a6f-4dfa-9697-0661a48e3b9f",
    path: "/product/ba42f0eb-5a6f-4dfa-9697-0661a48e3b9f",
    meta: {"ID":"ba42f0eb-5a6f-4dfa-9697-0661a48e3b9f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|df6e1e38-ff3c-4a9e-94f2-baf987c30a9b|ba44aaeb-2e14-4a7d-8629-cdffff139f2d",
    path: "/product/ba44aaeb-2e14-4a7d-8629-cdffff139f2d",
    meta: {"ID":"ba44aaeb-2e14-4a7d-8629-cdffff139f2d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|ba47e56e-c777-4ae9-8325-f151cd9768df",
    path: "/product/ba47e56e-c777-4ae9-8325-f151cd9768df",
    meta: {"ID":"ba47e56e-c777-4ae9-8325-f151cd9768df","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|ba4992ab-f417-4f2b-b6a7-2b2928724342",
    path: "/product/ba4992ab-f417-4f2b-b6a7-2b2928724342",
    meta: {"ID":"ba4992ab-f417-4f2b-b6a7-2b2928724342","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|ba9f290f-a707-4932-9fbd-b0fdc85dea55",
    path: "/product/ba9f290f-a707-4932-9fbd-b0fdc85dea55",
    meta: {"ID":"ba9f290f-a707-4932-9fbd-b0fdc85dea55","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bab09a4e-bfb1-4384-8f86-4649313fb804",
    path: "/product/bab09a4e-bfb1-4384-8f86-4649313fb804",
    meta: {"ID":"bab09a4e-bfb1-4384-8f86-4649313fb804","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|bab11273-2822-464c-9719-eed21ca64c54",
    path: "/product/bab11273-2822-464c-9719-eed21ca64c54",
    meta: {"ID":"bab11273-2822-464c-9719-eed21ca64c54","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|bab234b2-c224-473c-9352-e5bff4479c0d",
    path: "/product/bab234b2-c224-473c-9352-e5bff4479c0d",
    meta: {"ID":"bab234b2-c224-473c-9352-e5bff4479c0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|bab26e65-cf99-4730-9f6e-a649bb9c7cdb",
    path: "/product/bab26e65-cf99-4730-9f6e-a649bb9c7cdb",
    meta: {"ID":"bab26e65-cf99-4730-9f6e-a649bb9c7cdb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|baf42439-20c4-4063-bd07-a238f7a089eb",
    path: "/product/baf42439-20c4-4063-bd07-a238f7a089eb",
    meta: {"ID":"baf42439-20c4-4063-bd07-a238f7a089eb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|baf4e6c6-7b32-436d-8516-1fd1134721d0",
    path: "/product/baf4e6c6-7b32-436d-8516-1fd1134721d0",
    meta: {"ID":"baf4e6c6-7b32-436d-8516-1fd1134721d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|baf7e92f-bb90-4250-b825-5e55941d9d8d",
    path: "/product/baf7e92f-bb90-4250-b825-5e55941d9d8d",
    meta: {"ID":"baf7e92f-bb90-4250-b825-5e55941d9d8d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|bb5eac62-760e-41f3-9872-b78e01ca0c42",
    path: "/product/bb5eac62-760e-41f3-9872-b78e01ca0c42",
    meta: {"ID":"bb5eac62-760e-41f3-9872-b78e01ca0c42","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|bb6a9a82-d753-411d-a8af-d86f15d4f6b2",
    path: "/product/bb6a9a82-d753-411d-a8af-d86f15d4f6b2",
    meta: {"ID":"bb6a9a82-d753-411d-a8af-d86f15d4f6b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|bb6cf4ab-2186-4b53-bb08-659f16437e3d",
    path: "/product/bb6cf4ab-2186-4b53-bb08-659f16437e3d",
    meta: {"ID":"bb6cf4ab-2186-4b53-bb08-659f16437e3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|69d265a0-61df-4c8b-9631-e727b9d9fbe4|bb72273b-64dc-41c9-bd7d-54321b611920",
    path: "/product/bb72273b-64dc-41c9-bd7d-54321b611920",
    meta: {"ID":"bb72273b-64dc-41c9-bd7d-54321b611920","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|69d265a0-61df-4c8b-9631-e727b9d9fbe4|bb7bb381-4486-4f84-a42d-dadb19000709",
    path: "/product/bb7bb381-4486-4f84-a42d-dadb19000709",
    meta: {"ID":"bb7bb381-4486-4f84-a42d-dadb19000709","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|bb91ae45-8537-496c-abd8-74159adc5320",
    path: "/product/bb91ae45-8537-496c-abd8-74159adc5320",
    meta: {"ID":"bb91ae45-8537-496c-abd8-74159adc5320","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|bb971974-34a0-4edd-ac8d-80103c5c5e2a",
    path: "/product/bb971974-34a0-4edd-ac8d-80103c5c5e2a",
    meta: {"ID":"bb971974-34a0-4edd-ac8d-80103c5c5e2a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e2a1e7d9-7ca7-4450-84e5-be47dd4e74a5|bbb587e6-9aa6-4d53-986a-1ae7942403a9",
    path: "/product/bbb587e6-9aa6-4d53-986a-1ae7942403a9",
    meta: {"ID":"bbb587e6-9aa6-4d53-986a-1ae7942403a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|bbbd0d6e-49a1-414d-b24c-17bd7634eea0",
    path: "/product/bbbd0d6e-49a1-414d-b24c-17bd7634eea0",
    meta: {"ID":"bbbd0d6e-49a1-414d-b24c-17bd7634eea0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|bbe8a251-e2e9-4643-97d8-8780db4419a9",
    path: "/product/bbe8a251-e2e9-4643-97d8-8780db4419a9",
    meta: {"ID":"bbe8a251-e2e9-4643-97d8-8780db4419a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|bbf4b832-1046-4d3e-a367-706569fb8533",
    path: "/product/bbf4b832-1046-4d3e-a367-706569fb8533",
    meta: {"ID":"bbf4b832-1046-4d3e-a367-706569fb8533","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|bc4ec505-bf79-4d0b-af0e-b57c5126e8d7",
    path: "/product/bc4ec505-bf79-4d0b-af0e-b57c5126e8d7",
    meta: {"ID":"bc4ec505-bf79-4d0b-af0e-b57c5126e8d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|bc61125a-15d0-4924-bd20-97d92824167c",
    path: "/product/bc61125a-15d0-4924-bd20-97d92824167c",
    meta: {"ID":"bc61125a-15d0-4924-bd20-97d92824167c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|bc7d34be-c479-4f1f-98ae-346842404304",
    path: "/product/bc7d34be-c479-4f1f-98ae-346842404304",
    meta: {"ID":"bc7d34be-c479-4f1f-98ae-346842404304","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|bc88b47e-188a-40dc-b8bf-a6b10b031380",
    path: "/product/bc88b47e-188a-40dc-b8bf-a6b10b031380",
    meta: {"ID":"bc88b47e-188a-40dc-b8bf-a6b10b031380","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|bcb50ed6-8c61-4abd-9e71-b66198145c4c",
    path: "/product/bcb50ed6-8c61-4abd-9e71-b66198145c4c",
    meta: {"ID":"bcb50ed6-8c61-4abd-9e71-b66198145c4c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|bcc77285-5aa0-49c3-9ad2-0280c688de6e",
    path: "/product/bcc77285-5aa0-49c3-9ad2-0280c688de6e",
    meta: {"ID":"bcc77285-5aa0-49c3-9ad2-0280c688de6e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|bcc88e1c-a201-4566-a602-864797a14890",
    path: "/product/bcc88e1c-a201-4566-a602-864797a14890",
    meta: {"ID":"bcc88e1c-a201-4566-a602-864797a14890","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bcd1ec95-081e-400c-bbd7-7d706a08409e",
    path: "/product/bcd1ec95-081e-400c-bbd7-7d706a08409e",
    meta: {"ID":"bcd1ec95-081e-400c-bbd7-7d706a08409e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|bd190a5f-5c15-4a00-9dd5-562066ceaf58",
    path: "/product/bd190a5f-5c15-4a00-9dd5-562066ceaf58",
    meta: {"ID":"bd190a5f-5c15-4a00-9dd5-562066ceaf58","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|bd2f6ad5-ce80-45ba-a7d3-dfb35abb5dc9",
    path: "/product/bd2f6ad5-ce80-45ba-a7d3-dfb35abb5dc9",
    meta: {"ID":"bd2f6ad5-ce80-45ba-a7d3-dfb35abb5dc9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a04f5a3-7f1f-4863-9c6a-170e7e97ef1b|bd43a628-df27-43f4-9912-b54fe9e021a4",
    path: "/product/bd43a628-df27-43f4-9912-b54fe9e021a4",
    meta: {"ID":"bd43a628-df27-43f4-9912-b54fe9e021a4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|bd5a235e-2b65-4a39-b67a-b74388dd2f17",
    path: "/product/bd5a235e-2b65-4a39-b67a-b74388dd2f17",
    meta: {"ID":"bd5a235e-2b65-4a39-b67a-b74388dd2f17","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|bd793ccc-cf2f-46e3-ae85-01920edd7e94",
    path: "/product/bd793ccc-cf2f-46e3-ae85-01920edd7e94",
    meta: {"ID":"bd793ccc-cf2f-46e3-ae85-01920edd7e94","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bdb2565d-c132-4a51-bf40-45f14839d1fd",
    path: "/product/bdb2565d-c132-4a51-bf40-45f14839d1fd",
    meta: {"ID":"bdb2565d-c132-4a51-bf40-45f14839d1fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|bdc155d9-32a2-4dce-945f-fc0d6d482642",
    path: "/product/bdc155d9-32a2-4dce-945f-fc0d6d482642",
    meta: {"ID":"bdc155d9-32a2-4dce-945f-fc0d6d482642","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|bdc1c6b1-83fc-4b52-8a0e-79f1146cb267",
    path: "/product/bdc1c6b1-83fc-4b52-8a0e-79f1146cb267",
    meta: {"ID":"bdc1c6b1-83fc-4b52-8a0e-79f1146cb267","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bddf0634-9b10-426a-8ea1-36449cea8601",
    path: "/product/bddf0634-9b10-426a-8ea1-36449cea8601",
    meta: {"ID":"bddf0634-9b10-426a-8ea1-36449cea8601","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bde58c3b-ec9e-4961-8110-5f8546ffc777",
    path: "/product/bde58c3b-ec9e-4961-8110-5f8546ffc777",
    meta: {"ID":"bde58c3b-ec9e-4961-8110-5f8546ffc777","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|be1c0332-dd08-498b-a481-b7d5c4878cd4",
    path: "/product/be1c0332-dd08-498b-a481-b7d5c4878cd4",
    meta: {"ID":"be1c0332-dd08-498b-a481-b7d5c4878cd4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|be26439b-9083-4f73-b4a9-955ee566ad69",
    path: "/product/be26439b-9083-4f73-b4a9-955ee566ad69",
    meta: {"ID":"be26439b-9083-4f73-b4a9-955ee566ad69","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|be2cff79-ae3e-4533-8b04-0e4bab8a027b",
    path: "/product/be2cff79-ae3e-4533-8b04-0e4bab8a027b",
    meta: {"ID":"be2cff79-ae3e-4533-8b04-0e4bab8a027b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|be492887-efb5-412f-a2ba-509fb1b9d685",
    path: "/product/be492887-efb5-412f-a2ba-509fb1b9d685",
    meta: {"ID":"be492887-efb5-412f-a2ba-509fb1b9d685","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|be57a537-2143-4736-905b-3b07e6a3322b",
    path: "/product/be57a537-2143-4736-905b-3b07e6a3322b",
    meta: {"ID":"be57a537-2143-4736-905b-3b07e6a3322b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|be5e916b-d65b-4498-a09e-f60b13d15dad",
    path: "/product/be5e916b-d65b-4498-a09e-f60b13d15dad",
    meta: {"ID":"be5e916b-d65b-4498-a09e-f60b13d15dad","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|df6e1e38-ff3c-4a9e-94f2-baf987c30a9b|be937a88-1832-4c91-82b1-475e34522815",
    path: "/product/be937a88-1832-4c91-82b1-475e34522815",
    meta: {"ID":"be937a88-1832-4c91-82b1-475e34522815","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|beb3a698-b41e-4adc-9207-a3a45fcb5c07",
    path: "/product/beb3a698-b41e-4adc-9207-a3a45fcb5c07",
    meta: {"ID":"beb3a698-b41e-4adc-9207-a3a45fcb5c07","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|bebca674-3809-4b4c-b9a2-29d0242b8729",
    path: "/product/bebca674-3809-4b4c-b9a2-29d0242b8729",
    meta: {"ID":"bebca674-3809-4b4c-b9a2-29d0242b8729","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bf190bbb-e63a-40f3-8389-4699e12473b3",
    path: "/product/bf190bbb-e63a-40f3-8389-4699e12473b3",
    meta: {"ID":"bf190bbb-e63a-40f3-8389-4699e12473b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|bf29ef7d-f7e0-4256-9184-d2fbecadfb01",
    path: "/product/bf29ef7d-f7e0-4256-9184-d2fbecadfb01",
    meta: {"ID":"bf29ef7d-f7e0-4256-9184-d2fbecadfb01","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|bf44b998-cf76-41c5-9e37-5add0aa9ed1e",
    path: "/product/bf44b998-cf76-41c5-9e37-5add0aa9ed1e",
    meta: {"ID":"bf44b998-cf76-41c5-9e37-5add0aa9ed1e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bf4ed1e1-b8a5-4a27-abcc-820d8f627b20",
    path: "/product/bf4ed1e1-b8a5-4a27-abcc-820d8f627b20",
    meta: {"ID":"bf4ed1e1-b8a5-4a27-abcc-820d8f627b20","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7b8f0d1b-6e98-4d6a-be01-03b913fcdb44|bf63ca96-f069-48ac-818e-164a041bef9d",
    path: "/product/bf63ca96-f069-48ac-818e-164a041bef9d",
    meta: {"ID":"bf63ca96-f069-48ac-818e-164a041bef9d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|bf7526ba-5259-4a86-bdd1-d7c6eae040ef",
    path: "/product/bf7526ba-5259-4a86-bdd1-d7c6eae040ef",
    meta: {"ID":"bf7526ba-5259-4a86-bdd1-d7c6eae040ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2d9bf239-b509-4129-9d84-16287bf039d0|bf8a512f-cd93-4416-9a54-8188e321aae7",
    path: "/product/bf8a512f-cd93-4416-9a54-8188e321aae7",
    meta: {"ID":"bf8a512f-cd93-4416-9a54-8188e321aae7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|42a5e113-75f4-40d2-a9ee-575b6e6c0b8d|bfaff42b-1bdc-4961-95cb-9606eda822a1",
    path: "/product/bfaff42b-1bdc-4961-95cb-9606eda822a1",
    meta: {"ID":"bfaff42b-1bdc-4961-95cb-9606eda822a1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|bfdc27a6-1a60-41a9-8a77-abf00f264624",
    path: "/product/bfdc27a6-1a60-41a9-8a77-abf00f264624",
    meta: {"ID":"bfdc27a6-1a60-41a9-8a77-abf00f264624","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|bfddb252-a41e-402a-a1e3-fbc613b0159d",
    path: "/product/bfddb252-a41e-402a-a1e3-fbc613b0159d",
    meta: {"ID":"bfddb252-a41e-402a-a1e3-fbc613b0159d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bfdddcd0-3d45-49c4-9beb-988dea152ab6",
    path: "/product/bfdddcd0-3d45-49c4-9beb-988dea152ab6",
    meta: {"ID":"bfdddcd0-3d45-49c4-9beb-988dea152ab6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|bfecc597-04ef-49d9-84d2-a364302b8c00",
    path: "/product/bfecc597-04ef-49d9-84d2-a364302b8c00",
    meta: {"ID":"bfecc597-04ef-49d9-84d2-a364302b8c00","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c016453e-7ef2-4374-81cc-1c9e30868cdd",
    path: "/product/c016453e-7ef2-4374-81cc-1c9e30868cdd",
    meta: {"ID":"c016453e-7ef2-4374-81cc-1c9e30868cdd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c016ac0c-26fe-4a5d-b9d4-da5bd76318e2",
    path: "/product/c016ac0c-26fe-4a5d-b9d4-da5bd76318e2",
    meta: {"ID":"c016ac0c-26fe-4a5d-b9d4-da5bd76318e2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c02fdc33-0814-4226-893e-c54abf3e5d74",
    path: "/product/c02fdc33-0814-4226-893e-c54abf3e5d74",
    meta: {"ID":"c02fdc33-0814-4226-893e-c54abf3e5d74","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c066feff-4ac6-413c-8284-710b25dba393",
    path: "/product/c066feff-4ac6-413c-8284-710b25dba393",
    meta: {"ID":"c066feff-4ac6-413c-8284-710b25dba393","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|c072796a-05e0-4716-9921-8be1e73c8492",
    path: "/product/c072796a-05e0-4716-9921-8be1e73c8492",
    meta: {"ID":"c072796a-05e0-4716-9921-8be1e73c8492","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|c0824f99-25b4-4a30-a9d0-ca62ee457e53",
    path: "/product/c0824f99-25b4-4a30-a9d0-ca62ee457e53",
    meta: {"ID":"c0824f99-25b4-4a30-a9d0-ca62ee457e53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c08345c4-aa88-4714-b674-68ef0115b90e",
    path: "/product/c08345c4-aa88-4714-b674-68ef0115b90e",
    meta: {"ID":"c08345c4-aa88-4714-b674-68ef0115b90e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|c08fbff2-de95-4781-b5e4-48be5b05c435",
    path: "/product/c08fbff2-de95-4781-b5e4-48be5b05c435",
    meta: {"ID":"c08fbff2-de95-4781-b5e4-48be5b05c435","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c0b57e8c-c00f-4d87-adc2-ca3a6cc182ba",
    path: "/product/c0b57e8c-c00f-4d87-adc2-ca3a6cc182ba",
    meta: {"ID":"c0b57e8c-c00f-4d87-adc2-ca3a6cc182ba","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|c0bfd86f-5896-4a24-9b94-cb1aa3ecd9f0",
    path: "/product/c0bfd86f-5896-4a24-9b94-cb1aa3ecd9f0",
    meta: {"ID":"c0bfd86f-5896-4a24-9b94-cb1aa3ecd9f0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|c0c4a8eb-3970-4f7d-9bb3-e45182937a8c",
    path: "/product/c0c4a8eb-3970-4f7d-9bb3-e45182937a8c",
    meta: {"ID":"c0c4a8eb-3970-4f7d-9bb3-e45182937a8c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|c0cb6a9f-1320-4150-8418-a624699b4186",
    path: "/product/c0cb6a9f-1320-4150-8418-a624699b4186",
    meta: {"ID":"c0cb6a9f-1320-4150-8418-a624699b4186","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c0d02cd5-70d3-4e4f-b6ec-d3c12471d65e",
    path: "/product/c0d02cd5-70d3-4e4f-b6ec-d3c12471d65e",
    meta: {"ID":"c0d02cd5-70d3-4e4f-b6ec-d3c12471d65e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|c0d03aef-8af3-4a8d-9a19-041d2a79884e",
    path: "/product/c0d03aef-8af3-4a8d-9a19-041d2a79884e",
    meta: {"ID":"c0d03aef-8af3-4a8d-9a19-041d2a79884e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c0e885c3-f1e4-48ab-a13a-f29ac133eaf7",
    path: "/product/c0e885c3-f1e4-48ab-a13a-f29ac133eaf7",
    meta: {"ID":"c0e885c3-f1e4-48ab-a13a-f29ac133eaf7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c0fd6ff9-c419-4396-ac68-f2dc4be5110d",
    path: "/product/c0fd6ff9-c419-4396-ac68-f2dc4be5110d",
    meta: {"ID":"c0fd6ff9-c419-4396-ac68-f2dc4be5110d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c10c0c0c-9a15-40f3-ab6d-2d6aaa5ce9d1",
    path: "/product/c10c0c0c-9a15-40f3-ab6d-2d6aaa5ce9d1",
    meta: {"ID":"c10c0c0c-9a15-40f3-ab6d-2d6aaa5ce9d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|c111974e-e50d-4034-b8d5-692d32dac53a",
    path: "/product/c111974e-e50d-4034-b8d5-692d32dac53a",
    meta: {"ID":"c111974e-e50d-4034-b8d5-692d32dac53a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c120aebe-53a6-4709-8626-909306da6d0b",
    path: "/product/c120aebe-53a6-4709-8626-909306da6d0b",
    meta: {"ID":"c120aebe-53a6-4709-8626-909306da6d0b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c12a17b5-f876-4b8c-b5b0-00e3a51cd270",
    path: "/product/c12a17b5-f876-4b8c-b5b0-00e3a51cd270",
    meta: {"ID":"c12a17b5-f876-4b8c-b5b0-00e3a51cd270","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c14c038d-effc-461e-9719-60961ad49230",
    path: "/product/c14c038d-effc-461e-9719-60961ad49230",
    meta: {"ID":"c14c038d-effc-461e-9719-60961ad49230","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|c14e7a41-20d5-4789-a8ca-560f15d6bb8c",
    path: "/product/c14e7a41-20d5-4789-a8ca-560f15d6bb8c",
    meta: {"ID":"c14e7a41-20d5-4789-a8ca-560f15d6bb8c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|c1598f70-5fdf-4bd7-9879-98af92775074",
    path: "/product/c1598f70-5fdf-4bd7-9879-98af92775074",
    meta: {"ID":"c1598f70-5fdf-4bd7-9879-98af92775074","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c16d5d47-8bdc-4ff5-affa-3cebe3429be5",
    path: "/product/c16d5d47-8bdc-4ff5-affa-3cebe3429be5",
    meta: {"ID":"c16d5d47-8bdc-4ff5-affa-3cebe3429be5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c179933d-2120-4ff7-9a48-f5e782fd1eff",
    path: "/product/c179933d-2120-4ff7-9a48-f5e782fd1eff",
    meta: {"ID":"c179933d-2120-4ff7-9a48-f5e782fd1eff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|c195200e-db06-4093-81c2-9f79f8bc609d",
    path: "/product/c195200e-db06-4093-81c2-9f79f8bc609d",
    meta: {"ID":"c195200e-db06-4093-81c2-9f79f8bc609d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|c1a20bc6-e25b-402a-be42-8ce8dd467b16",
    path: "/product/c1a20bc6-e25b-402a-be42-8ce8dd467b16",
    meta: {"ID":"c1a20bc6-e25b-402a-be42-8ce8dd467b16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|c1af5936-344a-4df5-b8a2-03d0c6af035d",
    path: "/product/c1af5936-344a-4df5-b8a2-03d0c6af035d",
    meta: {"ID":"c1af5936-344a-4df5-b8a2-03d0c6af035d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c1c94c4d-ef0e-4cd8-9d08-e7d8177fe10f",
    path: "/product/c1c94c4d-ef0e-4cd8-9d08-e7d8177fe10f",
    meta: {"ID":"c1c94c4d-ef0e-4cd8-9d08-e7d8177fe10f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|c1caee1b-ae81-409f-bd2e-f3d1c0e635c6",
    path: "/product/c1caee1b-ae81-409f-bd2e-f3d1c0e635c6",
    meta: {"ID":"c1caee1b-ae81-409f-bd2e-f3d1c0e635c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c1cb0f80-4c98-4f9a-bea9-6e4eb3a0ec1b",
    path: "/product/c1cb0f80-4c98-4f9a-bea9-6e4eb3a0ec1b",
    meta: {"ID":"c1cb0f80-4c98-4f9a-bea9-6e4eb3a0ec1b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|c1cdeafe-7266-40a0-a8cb-c93e14c2f5b4",
    path: "/product/c1cdeafe-7266-40a0-a8cb-c93e14c2f5b4",
    meta: {"ID":"c1cdeafe-7266-40a0-a8cb-c93e14c2f5b4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c1db455e-d223-4e3f-8176-c9b2415d62e0",
    path: "/product/c1db455e-d223-4e3f-8176-c9b2415d62e0",
    meta: {"ID":"c1db455e-d223-4e3f-8176-c9b2415d62e0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|c1f3144d-6565-442a-bab8-4fc340ece128",
    path: "/product/c1f3144d-6565-442a-bab8-4fc340ece128",
    meta: {"ID":"c1f3144d-6565-442a-bab8-4fc340ece128","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|c23a5167-b8bd-42f6-a38a-25195ea2ebef",
    path: "/product/c23a5167-b8bd-42f6-a38a-25195ea2ebef",
    meta: {"ID":"c23a5167-b8bd-42f6-a38a-25195ea2ebef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1519fe15-47e1-44ff-8747-6714ca96f7aa|c25a5680-2673-4206-9438-4cad7b557947",
    path: "/product/c25a5680-2673-4206-9438-4cad7b557947",
    meta: {"ID":"c25a5680-2673-4206-9438-4cad7b557947","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|c270a17c-f8bf-4a20-9e97-78c0d2a79a88",
    path: "/product/c270a17c-f8bf-4a20-9e97-78c0d2a79a88",
    meta: {"ID":"c270a17c-f8bf-4a20-9e97-78c0d2a79a88","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c2725fa6-4216-4293-bcb7-235cac96ffed",
    path: "/product/c2725fa6-4216-4293-bcb7-235cac96ffed",
    meta: {"ID":"c2725fa6-4216-4293-bcb7-235cac96ffed","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c2a75da4-e177-4fcd-a2b7-c627ab160db1",
    path: "/product/c2a75da4-e177-4fcd-a2b7-c627ab160db1",
    meta: {"ID":"c2a75da4-e177-4fcd-a2b7-c627ab160db1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|c2a7dabf-d0fc-4d46-a7cc-08ba23e1b603",
    path: "/product/c2a7dabf-d0fc-4d46-a7cc-08ba23e1b603",
    meta: {"ID":"c2a7dabf-d0fc-4d46-a7cc-08ba23e1b603","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|c2c76016-a19b-44fe-ae25-bfbf799c77a3",
    path: "/product/c2c76016-a19b-44fe-ae25-bfbf799c77a3",
    meta: {"ID":"c2c76016-a19b-44fe-ae25-bfbf799c77a3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|c2c7cd60-0461-48eb-ac33-ab4e7fc22ff2",
    path: "/product/c2c7cd60-0461-48eb-ac33-ab4e7fc22ff2",
    meta: {"ID":"c2c7cd60-0461-48eb-ac33-ab4e7fc22ff2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c2f8faf6-b507-44f3-8ee7-f30d0686f345",
    path: "/product/c2f8faf6-b507-44f3-8ee7-f30d0686f345",
    meta: {"ID":"c2f8faf6-b507-44f3-8ee7-f30d0686f345","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c3385f7a-546a-49bf-8426-48765f7dd436",
    path: "/product/c3385f7a-546a-49bf-8426-48765f7dd436",
    meta: {"ID":"c3385f7a-546a-49bf-8426-48765f7dd436","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c3434dd0-5aba-459a-8cde-63d37ceac693",
    path: "/product/c3434dd0-5aba-459a-8cde-63d37ceac693",
    meta: {"ID":"c3434dd0-5aba-459a-8cde-63d37ceac693","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|69d265a0-61df-4c8b-9631-e727b9d9fbe4|c36408aa-4637-40a5-9e4b-079c3a70dddd",
    path: "/product/c36408aa-4637-40a5-9e4b-079c3a70dddd",
    meta: {"ID":"c36408aa-4637-40a5-9e4b-079c3a70dddd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c369c552-7a91-4db7-b754-50d4054d8f8b",
    path: "/product/c369c552-7a91-4db7-b754-50d4054d8f8b",
    meta: {"ID":"c369c552-7a91-4db7-b754-50d4054d8f8b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c37034cc-0d64-42d6-92dc-d81e321ae23d",
    path: "/product/c37034cc-0d64-42d6-92dc-d81e321ae23d",
    meta: {"ID":"c37034cc-0d64-42d6-92dc-d81e321ae23d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|c3809837-63bd-44f0-9273-7dd00faaae21",
    path: "/product/c3809837-63bd-44f0-9273-7dd00faaae21",
    meta: {"ID":"c3809837-63bd-44f0-9273-7dd00faaae21","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4322a75-f23f-4323-9f26-5d0e5462e09e|c380c463-2838-4beb-a0af-031411f881a5",
    path: "/product/c380c463-2838-4beb-a0af-031411f881a5",
    meta: {"ID":"c380c463-2838-4beb-a0af-031411f881a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c3940a1f-34b2-470f-9ba3-4e1ba353a1f3",
    path: "/product/c3940a1f-34b2-470f-9ba3-4e1ba353a1f3",
    meta: {"ID":"c3940a1f-34b2-470f-9ba3-4e1ba353a1f3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c39623cc-0ce2-468a-bddc-ffb18afae7fd",
    path: "/product/c39623cc-0ce2-468a-bddc-ffb18afae7fd",
    meta: {"ID":"c39623cc-0ce2-468a-bddc-ffb18afae7fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|c3980065-ada0-40af-a2e7-4aa2e735ceed",
    path: "/product/c3980065-ada0-40af-a2e7-4aa2e735ceed",
    meta: {"ID":"c3980065-ada0-40af-a2e7-4aa2e735ceed","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c39ab838-1d32-4c01-844b-c0d024c25d69",
    path: "/product/c39ab838-1d32-4c01-844b-c0d024c25d69",
    meta: {"ID":"c39ab838-1d32-4c01-844b-c0d024c25d69","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c3b5f785-9696-4927-86b7-bfb38e3a8a03",
    path: "/product/c3b5f785-9696-4927-86b7-bfb38e3a8a03",
    meta: {"ID":"c3b5f785-9696-4927-86b7-bfb38e3a8a03","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|c3b8d61e-f5c6-4a98-93d6-b3010478d229",
    path: "/product/c3b8d61e-f5c6-4a98-93d6-b3010478d229",
    meta: {"ID":"c3b8d61e-f5c6-4a98-93d6-b3010478d229","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|c3d2f54c-393c-43e6-bb82-03f3428a8f04",
    path: "/product/c3d2f54c-393c-43e6-bb82-03f3428a8f04",
    meta: {"ID":"c3d2f54c-393c-43e6-bb82-03f3428a8f04","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c3daf15d-0219-4745-a546-6c5c969f684f",
    path: "/product/c3daf15d-0219-4745-a546-6c5c969f684f",
    meta: {"ID":"c3daf15d-0219-4745-a546-6c5c969f684f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c3e50257-b258-4a6b-bd0d-d0c1bdf45aa4",
    path: "/product/c3e50257-b258-4a6b-bd0d-d0c1bdf45aa4",
    meta: {"ID":"c3e50257-b258-4a6b-bd0d-d0c1bdf45aa4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c3e91b04-a20b-46dd-9fb3-fcbd4b76770b",
    path: "/product/c3e91b04-a20b-46dd-9fb3-fcbd4b76770b",
    meta: {"ID":"c3e91b04-a20b-46dd-9fb3-fcbd4b76770b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|c3fe88ec-2198-4a03-91ea-9ef7be81f012",
    path: "/product/c3fe88ec-2198-4a03-91ea-9ef7be81f012",
    meta: {"ID":"c3fe88ec-2198-4a03-91ea-9ef7be81f012","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c4232f27-bdb1-4397-9ea5-a2326a75b710",
    path: "/product/c4232f27-bdb1-4397-9ea5-a2326a75b710",
    meta: {"ID":"c4232f27-bdb1-4397-9ea5-a2326a75b710","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c43cb7d7-3f93-42d8-8bbd-a6ff07d75807",
    path: "/product/c43cb7d7-3f93-42d8-8bbd-a6ff07d75807",
    meta: {"ID":"c43cb7d7-3f93-42d8-8bbd-a6ff07d75807","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c4413c1a-fd39-41ff-93f7-ac6ad6266673",
    path: "/product/c4413c1a-fd39-41ff-93f7-ac6ad6266673",
    meta: {"ID":"c4413c1a-fd39-41ff-93f7-ac6ad6266673","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|c449bb4e-9bf1-46e8-bf2b-4b0fadc71146",
    path: "/product/c449bb4e-9bf1-46e8-bf2b-4b0fadc71146",
    meta: {"ID":"c449bb4e-9bf1-46e8-bf2b-4b0fadc71146","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|c48f37b7-0321-4312-9a73-e0ae4e228275",
    path: "/product/c48f37b7-0321-4312-9a73-e0ae4e228275",
    meta: {"ID":"c48f37b7-0321-4312-9a73-e0ae4e228275","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4349f183-f876-4668-9110-f50347c57ef2|c4959d62-9010-4330-a6f5-1b24d7ba2dbf",
    path: "/product/c4959d62-9010-4330-a6f5-1b24d7ba2dbf",
    meta: {"ID":"c4959d62-9010-4330-a6f5-1b24d7ba2dbf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c4bb22ad-35bf-4d8b-989c-5b7606acc729",
    path: "/product/c4bb22ad-35bf-4d8b-989c-5b7606acc729",
    meta: {"ID":"c4bb22ad-35bf-4d8b-989c-5b7606acc729","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c4d1194e-3deb-414a-ba83-1889150688ae",
    path: "/product/c4d1194e-3deb-414a-ba83-1889150688ae",
    meta: {"ID":"c4d1194e-3deb-414a-ba83-1889150688ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|c4e1cffe-9ba3-4e2b-be06-d4d7408a7166",
    path: "/product/c4e1cffe-9ba3-4e2b-be06-d4d7408a7166",
    meta: {"ID":"c4e1cffe-9ba3-4e2b-be06-d4d7408a7166","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|c5099adc-3be9-463e-836d-1dddf6c18808",
    path: "/product/c5099adc-3be9-463e-836d-1dddf6c18808",
    meta: {"ID":"c5099adc-3be9-463e-836d-1dddf6c18808","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|c51adb34-6457-4535-b6b4-10404da7b3d1",
    path: "/product/c51adb34-6457-4535-b6b4-10404da7b3d1",
    meta: {"ID":"c51adb34-6457-4535-b6b4-10404da7b3d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|61920873-b03c-43ac-aa78-5e5acd2bdfe1|c5286a85-292a-454d-9dd7-6b2c8859e8a0",
    path: "/product/c5286a85-292a-454d-9dd7-6b2c8859e8a0",
    meta: {"ID":"c5286a85-292a-454d-9dd7-6b2c8859e8a0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|735f10a8-dcc4-47d5-9639-e55e0af97024|c541980d-9ab7-457f-ad0e-d48b155b2e27",
    path: "/product/c541980d-9ab7-457f-ad0e-d48b155b2e27",
    meta: {"ID":"c541980d-9ab7-457f-ad0e-d48b155b2e27","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|c567ccee-5933-40c6-8745-dbdbd4f66cc9",
    path: "/product/c567ccee-5933-40c6-8745-dbdbd4f66cc9",
    meta: {"ID":"c567ccee-5933-40c6-8745-dbdbd4f66cc9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c57c7899-6433-45c2-b0d5-d76933eb62de",
    path: "/product/c57c7899-6433-45c2-b0d5-d76933eb62de",
    meta: {"ID":"c57c7899-6433-45c2-b0d5-d76933eb62de","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|c57e8628-370f-41b6-b699-717f7d03d4f4",
    path: "/product/c57e8628-370f-41b6-b699-717f7d03d4f4",
    meta: {"ID":"c57e8628-370f-41b6-b699-717f7d03d4f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|c58f4909-4a11-427e-8657-a837acdf4a23",
    path: "/product/c58f4909-4a11-427e-8657-a837acdf4a23",
    meta: {"ID":"c58f4909-4a11-427e-8657-a837acdf4a23","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|c5917d9b-1d32-47ed-b838-0fd2b82a4dae",
    path: "/product/c5917d9b-1d32-47ed-b838-0fd2b82a4dae",
    meta: {"ID":"c5917d9b-1d32-47ed-b838-0fd2b82a4dae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c59e9334-5e99-4e6a-baa2-8ddaad9e19c7",
    path: "/product/c59e9334-5e99-4e6a-baa2-8ddaad9e19c7",
    meta: {"ID":"c59e9334-5e99-4e6a-baa2-8ddaad9e19c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|c5bb23e0-b1e0-4bae-807b-f80cfe3780e7",
    path: "/product/c5bb23e0-b1e0-4bae-807b-f80cfe3780e7",
    meta: {"ID":"c5bb23e0-b1e0-4bae-807b-f80cfe3780e7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c5c28143-7b28-4885-a5e2-858993a90d3a",
    path: "/product/c5c28143-7b28-4885-a5e2-858993a90d3a",
    meta: {"ID":"c5c28143-7b28-4885-a5e2-858993a90d3a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|c6333299-f51b-48ed-b696-ab2bdd0dbd3a",
    path: "/product/c6333299-f51b-48ed-b696-ab2bdd0dbd3a",
    meta: {"ID":"c6333299-f51b-48ed-b696-ab2bdd0dbd3a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|c6392b67-d46a-475e-ac8c-7ae592fa3592",
    path: "/product/c6392b67-d46a-475e-ac8c-7ae592fa3592",
    meta: {"ID":"c6392b67-d46a-475e-ac8c-7ae592fa3592","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c63966f8-f803-486d-b65b-ef418a001437",
    path: "/product/c63966f8-f803-486d-b65b-ef418a001437",
    meta: {"ID":"c63966f8-f803-486d-b65b-ef418a001437","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c6511b8b-c0d3-4dbc-95d3-547d6447a5d0",
    path: "/product/c6511b8b-c0d3-4dbc-95d3-547d6447a5d0",
    meta: {"ID":"c6511b8b-c0d3-4dbc-95d3-547d6447a5d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|967d3966-c5f3-487e-a085-8e9a8fe858bf|c655569c-47db-4284-890e-9fecfa483f4c",
    path: "/product/c655569c-47db-4284-890e-9fecfa483f4c",
    meta: {"ID":"c655569c-47db-4284-890e-9fecfa483f4c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|c6871c71-7d5a-4b6f-8aa2-b224176d0907",
    path: "/product/c6871c71-7d5a-4b6f-8aa2-b224176d0907",
    meta: {"ID":"c6871c71-7d5a-4b6f-8aa2-b224176d0907","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c6972c09-bd50-4cea-a0b2-0b4e03a876e2",
    path: "/product/c6972c09-bd50-4cea-a0b2-0b4e03a876e2",
    meta: {"ID":"c6972c09-bd50-4cea-a0b2-0b4e03a876e2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|c6a7af6a-d60b-46e2-a621-57cedfbc34d8",
    path: "/product/c6a7af6a-d60b-46e2-a621-57cedfbc34d8",
    meta: {"ID":"c6a7af6a-d60b-46e2-a621-57cedfbc34d8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2d9bf239-b509-4129-9d84-16287bf039d0|c6a891a4-766f-43c5-ae34-eb917127770a",
    path: "/product/c6a891a4-766f-43c5-ae34-eb917127770a",
    meta: {"ID":"c6a891a4-766f-43c5-ae34-eb917127770a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c6d7b2a0-4518-472e-837e-a26c92b61e74",
    path: "/product/c6d7b2a0-4518-472e-837e-a26c92b61e74",
    meta: {"ID":"c6d7b2a0-4518-472e-837e-a26c92b61e74","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|c718bf43-286b-4eab-af26-ac97812bfa42",
    path: "/product/c718bf43-286b-4eab-af26-ac97812bfa42",
    meta: {"ID":"c718bf43-286b-4eab-af26-ac97812bfa42","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c7374252-674c-4a31-9f29-054713166708",
    path: "/product/c7374252-674c-4a31-9f29-054713166708",
    meta: {"ID":"c7374252-674c-4a31-9f29-054713166708","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|c75ee377-1a6c-4d77-9e77-f720015533e0",
    path: "/product/c75ee377-1a6c-4d77-9e77-f720015533e0",
    meta: {"ID":"c75ee377-1a6c-4d77-9e77-f720015533e0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|c794a66c-c800-4e57-bf8d-44f523f3ccd1",
    path: "/product/c794a66c-c800-4e57-bf8d-44f523f3ccd1",
    meta: {"ID":"c794a66c-c800-4e57-bf8d-44f523f3ccd1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|c79b7619-23ec-4cb8-af86-f652e9474bb2",
    path: "/product/c79b7619-23ec-4cb8-af86-f652e9474bb2",
    meta: {"ID":"c79b7619-23ec-4cb8-af86-f652e9474bb2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|c79e10ed-f5d7-4025-b318-b5a1ee0921ef",
    path: "/product/c79e10ed-f5d7-4025-b318-b5a1ee0921ef",
    meta: {"ID":"c79e10ed-f5d7-4025-b318-b5a1ee0921ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|c7cc886b-e3e7-4fec-86b7-ff416178ac63",
    path: "/product/c7cc886b-e3e7-4fec-86b7-ff416178ac63",
    meta: {"ID":"c7cc886b-e3e7-4fec-86b7-ff416178ac63","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c7efe358-e0fd-410c-a60a-3946bc7f9e52",
    path: "/product/c7efe358-e0fd-410c-a60a-3946bc7f9e52",
    meta: {"ID":"c7efe358-e0fd-410c-a60a-3946bc7f9e52","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|61920873-b03c-43ac-aa78-5e5acd2bdfe1|c7f8b7f6-1f8f-421c-badf-d0360ccbe3b9",
    path: "/product/c7f8b7f6-1f8f-421c-badf-d0360ccbe3b9",
    meta: {"ID":"c7f8b7f6-1f8f-421c-badf-d0360ccbe3b9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|c8038eba-f10c-4af7-8a94-4868cbdb7c4d",
    path: "/product/c8038eba-f10c-4af7-8a94-4868cbdb7c4d",
    meta: {"ID":"c8038eba-f10c-4af7-8a94-4868cbdb7c4d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c81ed2ad-3570-4735-b564-aac8916731d6",
    path: "/product/c81ed2ad-3570-4735-b564-aac8916731d6",
    meta: {"ID":"c81ed2ad-3570-4735-b564-aac8916731d6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c82afb02-4986-4cc9-b727-6dc4a60dff41",
    path: "/product/c82afb02-4986-4cc9-b727-6dc4a60dff41",
    meta: {"ID":"c82afb02-4986-4cc9-b727-6dc4a60dff41","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|c84a1587-cc12-4d59-ba21-ec9f15f23ad7",
    path: "/product/c84a1587-cc12-4d59-ba21-ec9f15f23ad7",
    meta: {"ID":"c84a1587-cc12-4d59-ba21-ec9f15f23ad7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|c8767f37-bd41-45c0-b38d-7b4254cb2a29",
    path: "/product/c8767f37-bd41-45c0-b38d-7b4254cb2a29",
    meta: {"ID":"c8767f37-bd41-45c0-b38d-7b4254cb2a29","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|c87706ad-59db-4403-9abb-5e7540411659",
    path: "/product/c87706ad-59db-4403-9abb-5e7540411659",
    meta: {"ID":"c87706ad-59db-4403-9abb-5e7540411659","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|c87b0267-af29-4ff3-9dab-384a13257e67",
    path: "/product/c87b0267-af29-4ff3-9dab-384a13257e67",
    meta: {"ID":"c87b0267-af29-4ff3-9dab-384a13257e67","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|c885cedf-8b09-4cc3-a078-eb36ca8198cc",
    path: "/product/c885cedf-8b09-4cc3-a078-eb36ca8198cc",
    meta: {"ID":"c885cedf-8b09-4cc3-a078-eb36ca8198cc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|c89d0be2-d59d-4840-b0a1-42dc2495d118",
    path: "/product/c89d0be2-d59d-4840-b0a1-42dc2495d118",
    meta: {"ID":"c89d0be2-d59d-4840-b0a1-42dc2495d118","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c93d7c8d-f3dc-4faf-a0cf-0ecf6cea69f0",
    path: "/product/c93d7c8d-f3dc-4faf-a0cf-0ecf6cea69f0",
    meta: {"ID":"c93d7c8d-f3dc-4faf-a0cf-0ecf6cea69f0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c95864e2-4b22-4eb2-b379-7dc7fd3d58d4",
    path: "/product/c95864e2-4b22-4eb2-b379-7dc7fd3d58d4",
    meta: {"ID":"c95864e2-4b22-4eb2-b379-7dc7fd3d58d4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|c9b19a78-a903-41ab-99f1-5e9a42fe8939",
    path: "/product/c9b19a78-a903-41ab-99f1-5e9a42fe8939",
    meta: {"ID":"c9b19a78-a903-41ab-99f1-5e9a42fe8939","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|c9d4c3ed-55bf-4b23-9ef7-427a09b50f1f",
    path: "/product/c9d4c3ed-55bf-4b23-9ef7-427a09b50f1f",
    meta: {"ID":"c9d4c3ed-55bf-4b23-9ef7-427a09b50f1f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|c9ee69ef-9d7a-4136-aece-b63291a04e73",
    path: "/product/c9ee69ef-9d7a-4136-aece-b63291a04e73",
    meta: {"ID":"c9ee69ef-9d7a-4136-aece-b63291a04e73","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|0f33a02b-92fa-4922-9e4c-0b4cc4e13e79|ca3eb70f-200e-46f1-a12a-a9c53c4475a7",
    path: "/product/ca3eb70f-200e-46f1-a12a-a9c53c4475a7",
    meta: {"ID":"ca3eb70f-200e-46f1-a12a-a9c53c4475a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|0f33a02b-92fa-4922-9e4c-0b4cc4e13e79|ca56a147-5860-4e66-acc6-dee0860cd164",
    path: "/product/ca56a147-5860-4e66-acc6-dee0860cd164",
    meta: {"ID":"ca56a147-5860-4e66-acc6-dee0860cd164","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|ca654019-a1ab-410d-b277-daf9b907cdf1",
    path: "/product/ca654019-a1ab-410d-b277-daf9b907cdf1",
    meta: {"ID":"ca654019-a1ab-410d-b277-daf9b907cdf1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|ca7baf17-5fc8-4dd7-ac91-0aa625eb7d58",
    path: "/product/ca7baf17-5fc8-4dd7-ac91-0aa625eb7d58",
    meta: {"ID":"ca7baf17-5fc8-4dd7-ac91-0aa625eb7d58","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|cab6423c-848b-4d74-be87-884d5aefb672",
    path: "/product/cab6423c-848b-4d74-be87-884d5aefb672",
    meta: {"ID":"cab6423c-848b-4d74-be87-884d5aefb672","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|cac7ece0-3728-4184-81bb-51dc01a8e6fd",
    path: "/product/cac7ece0-3728-4184-81bb-51dc01a8e6fd",
    meta: {"ID":"cac7ece0-3728-4184-81bb-51dc01a8e6fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|cb2281f4-84cd-4b3b-8f50-d8befec7413a",
    path: "/product/cb2281f4-84cd-4b3b-8f50-d8befec7413a",
    meta: {"ID":"cb2281f4-84cd-4b3b-8f50-d8befec7413a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cb25cf47-ec06-4490-ba49-87e5f93ff0aa",
    path: "/product/cb25cf47-ec06-4490-ba49-87e5f93ff0aa",
    meta: {"ID":"cb25cf47-ec06-4490-ba49-87e5f93ff0aa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cb415437-b21b-4f64-bf30-968f2f769a77",
    path: "/product/cb415437-b21b-4f64-bf30-968f2f769a77",
    meta: {"ID":"cb415437-b21b-4f64-bf30-968f2f769a77","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|cb5a8c36-43cc-455b-aa33-af7a9e8cd56b",
    path: "/product/cb5a8c36-43cc-455b-aa33-af7a9e8cd56b",
    meta: {"ID":"cb5a8c36-43cc-455b-aa33-af7a9e8cd56b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|cba1a729-261d-4d66-b064-434c4c044f2e",
    path: "/product/cba1a729-261d-4d66-b064-434c4c044f2e",
    meta: {"ID":"cba1a729-261d-4d66-b064-434c4c044f2e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|cbafdde0-057a-42c6-be63-d671ec062d3c",
    path: "/product/cbafdde0-057a-42c6-be63-d671ec062d3c",
    meta: {"ID":"cbafdde0-057a-42c6-be63-d671ec062d3c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|cbde8770-f313-4e22-8bbd-ac96a30e35b3",
    path: "/product/cbde8770-f313-4e22-8bbd-ac96a30e35b3",
    meta: {"ID":"cbde8770-f313-4e22-8bbd-ac96a30e35b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|cbe0654a-7580-4387-93d9-8cd9f4c87e2d",
    path: "/product/cbe0654a-7580-4387-93d9-8cd9f4c87e2d",
    meta: {"ID":"cbe0654a-7580-4387-93d9-8cd9f4c87e2d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|cbf436f1-efb0-48d5-aa59-c2d2462f0735",
    path: "/product/cbf436f1-efb0-48d5-aa59-c2d2462f0735",
    meta: {"ID":"cbf436f1-efb0-48d5-aa59-c2d2462f0735","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|cc384922-990d-494d-8ccf-3fe9bb402d9f",
    path: "/product/cc384922-990d-494d-8ccf-3fe9bb402d9f",
    meta: {"ID":"cc384922-990d-494d-8ccf-3fe9bb402d9f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|cc5c5451-4214-438d-9be7-f7213e5306c6",
    path: "/product/cc5c5451-4214-438d-9be7-f7213e5306c6",
    meta: {"ID":"cc5c5451-4214-438d-9be7-f7213e5306c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cc67e444-0922-4c13-898f-62a6d4ac849e",
    path: "/product/cc67e444-0922-4c13-898f-62a6d4ac849e",
    meta: {"ID":"cc67e444-0922-4c13-898f-62a6d4ac849e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|cc88e070-d928-46ed-9a9d-824d6c102287",
    path: "/product/cc88e070-d928-46ed-9a9d-824d6c102287",
    meta: {"ID":"cc88e070-d928-46ed-9a9d-824d6c102287","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cc8a645b-b54d-4c83-8c2c-9759ef877ff3",
    path: "/product/cc8a645b-b54d-4c83-8c2c-9759ef877ff3",
    meta: {"ID":"cc8a645b-b54d-4c83-8c2c-9759ef877ff3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|cc995c1c-06d8-4011-9f65-a17f356f0b26",
    path: "/product/cc995c1c-06d8-4011-9f65-a17f356f0b26",
    meta: {"ID":"cc995c1c-06d8-4011-9f65-a17f356f0b26","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|cc99c460-00a8-44fd-a1ad-09b8ed152858",
    path: "/product/cc99c460-00a8-44fd-a1ad-09b8ed152858",
    meta: {"ID":"cc99c460-00a8-44fd-a1ad-09b8ed152858","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cca1cb6a-5343-43b6-99d0-5a28d089947a",
    path: "/product/cca1cb6a-5343-43b6-99d0-5a28d089947a",
    meta: {"ID":"cca1cb6a-5343-43b6-99d0-5a28d089947a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cccab7c1-1618-4f9e-987c-bd7d79325682",
    path: "/product/cccab7c1-1618-4f9e-987c-bd7d79325682",
    meta: {"ID":"cccab7c1-1618-4f9e-987c-bd7d79325682","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|ccd65e39-2b3d-412a-adda-28202a6fef57",
    path: "/product/ccd65e39-2b3d-412a-adda-28202a6fef57",
    meta: {"ID":"ccd65e39-2b3d-412a-adda-28202a6fef57","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|cd07fb3a-eef0-44c3-b9e8-01bb08472093",
    path: "/product/cd07fb3a-eef0-44c3-b9e8-01bb08472093",
    meta: {"ID":"cd07fb3a-eef0-44c3-b9e8-01bb08472093","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|cd35920d-d4b6-46ca-938c-b1ef0ef2f49a",
    path: "/product/cd35920d-d4b6-46ca-938c-b1ef0ef2f49a",
    meta: {"ID":"cd35920d-d4b6-46ca-938c-b1ef0ef2f49a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cd375543-0433-4a54-b7f7-df937552aec4",
    path: "/product/cd375543-0433-4a54-b7f7-df937552aec4",
    meta: {"ID":"cd375543-0433-4a54-b7f7-df937552aec4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cd3ca822-3bae-48a2-b62e-5c5e91df0d4d",
    path: "/product/cd3ca822-3bae-48a2-b62e-5c5e91df0d4d",
    meta: {"ID":"cd3ca822-3bae-48a2-b62e-5c5e91df0d4d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cd58e3ae-2efc-4b5b-884f-8a599763ea5c",
    path: "/product/cd58e3ae-2efc-4b5b-884f-8a599763ea5c",
    meta: {"ID":"cd58e3ae-2efc-4b5b-884f-8a599763ea5c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|cd85074f-c809-45a6-9716-daca6dcdef4b",
    path: "/product/cd85074f-c809-45a6-9716-daca6dcdef4b",
    meta: {"ID":"cd85074f-c809-45a6-9716-daca6dcdef4b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|cd9ae00b-d3eb-484f-95a4-eb3ae1128eae",
    path: "/product/cd9ae00b-d3eb-484f-95a4-eb3ae1128eae",
    meta: {"ID":"cd9ae00b-d3eb-484f-95a4-eb3ae1128eae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|cd9cccc9-7d0b-424b-b217-c44f1d210823",
    path: "/product/cd9cccc9-7d0b-424b-b217-c44f1d210823",
    meta: {"ID":"cd9cccc9-7d0b-424b-b217-c44f1d210823","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3c06d685-8171-454b-920a-f3a76b0a4226|cdcab075-fdc2-4446-a9ab-d0074201fb81",
    path: "/product/cdcab075-fdc2-4446-a9ab-d0074201fb81",
    meta: {"ID":"cdcab075-fdc2-4446-a9ab-d0074201fb81","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|cdd28d00-cfbe-4bf1-8f5a-9f940acbd0fd",
    path: "/product/cdd28d00-cfbe-4bf1-8f5a-9f940acbd0fd",
    meta: {"ID":"cdd28d00-cfbe-4bf1-8f5a-9f940acbd0fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|cdf36f41-13af-4452-93bf-fb6b3f971f4e",
    path: "/product/cdf36f41-13af-4452-93bf-fb6b3f971f4e",
    meta: {"ID":"cdf36f41-13af-4452-93bf-fb6b3f971f4e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a77b60ce-796c-4cfb-a81f-523c73aff39d|cdfa9c89-580a-4939-bde4-3f8cadf1dbba",
    path: "/product/cdfa9c89-580a-4939-bde4-3f8cadf1dbba",
    meta: {"ID":"cdfa9c89-580a-4939-bde4-3f8cadf1dbba","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|cdfd3ddb-c172-476d-ace2-cc91fc820d86",
    path: "/product/cdfd3ddb-c172-476d-ace2-cc91fc820d86",
    meta: {"ID":"cdfd3ddb-c172-476d-ace2-cc91fc820d86","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|ce0034c9-b12e-4671-aa82-f960ce14d679",
    path: "/product/ce0034c9-b12e-4671-aa82-f960ce14d679",
    meta: {"ID":"ce0034c9-b12e-4671-aa82-f960ce14d679","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ce11498d-19b7-434d-8c7b-f63de842aa14",
    path: "/product/ce11498d-19b7-434d-8c7b-f63de842aa14",
    meta: {"ID":"ce11498d-19b7-434d-8c7b-f63de842aa14","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ce4e31b9-81a3-4148-9898-5fb099136025",
    path: "/product/ce4e31b9-81a3-4148-9898-5fb099136025",
    meta: {"ID":"ce4e31b9-81a3-4148-9898-5fb099136025","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|703bc4c8-03f6-4fb4-b63b-06742e85e924|ce7091dc-41ad-456e-a693-6b42f5dc7ba0",
    path: "/product/ce7091dc-41ad-456e-a693-6b42f5dc7ba0",
    meta: {"ID":"ce7091dc-41ad-456e-a693-6b42f5dc7ba0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|ce8c2f92-358a-4ee4-96b8-a4305cbaf2c3",
    path: "/product/ce8c2f92-358a-4ee4-96b8-a4305cbaf2c3",
    meta: {"ID":"ce8c2f92-358a-4ee4-96b8-a4305cbaf2c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|ceab5393-7298-4390-9e94-e60907a8762d",
    path: "/product/ceab5393-7298-4390-9e94-e60907a8762d",
    meta: {"ID":"ceab5393-7298-4390-9e94-e60907a8762d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ceea1137-94a8-4735-b09e-c9a46e987614",
    path: "/product/ceea1137-94a8-4735-b09e-c9a46e987614",
    meta: {"ID":"ceea1137-94a8-4735-b09e-c9a46e987614","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ceed6c47-4817-4c12-be91-b7a3f84f2107",
    path: "/product/ceed6c47-4817-4c12-be91-b7a3f84f2107",
    meta: {"ID":"ceed6c47-4817-4c12-be91-b7a3f84f2107","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|cefb142c-095d-4d2f-9c6b-dbf19d917d51",
    path: "/product/cefb142c-095d-4d2f-9c6b-dbf19d917d51",
    meta: {"ID":"cefb142c-095d-4d2f-9c6b-dbf19d917d51","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cf060d9f-0a6e-4af2-83f8-82661ed6bc7b",
    path: "/product/cf060d9f-0a6e-4af2-83f8-82661ed6bc7b",
    meta: {"ID":"cf060d9f-0a6e-4af2-83f8-82661ed6bc7b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|33e8f98d-49a3-4d75-8076-e016770f1878|cf0702cc-5934-43e7-97fd-ac470344720d",
    path: "/product/cf0702cc-5934-43e7-97fd-ac470344720d",
    meta: {"ID":"cf0702cc-5934-43e7-97fd-ac470344720d","Title":"","GroupID":"33e8f98d-49a3-4d75-8076-e016770f1878","GroupTitle":"Дополнительно","GroupLink":"/menu/33e8f98d-49a3-4d75-8076-e016770f1878","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cf08b7d2-a893-4b17-9752-72af3018660c",
    path: "/product/cf08b7d2-a893-4b17-9752-72af3018660c",
    meta: {"ID":"cf08b7d2-a893-4b17-9752-72af3018660c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|cf34b51a-e3d3-4333-b3aa-5c7b22b1a2a5",
    path: "/product/cf34b51a-e3d3-4333-b3aa-5c7b22b1a2a5",
    meta: {"ID":"cf34b51a-e3d3-4333-b3aa-5c7b22b1a2a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cf82e140-275e-466d-9aa8-79e08f2ac759",
    path: "/product/cf82e140-275e-466d-9aa8-79e08f2ac759",
    meta: {"ID":"cf82e140-275e-466d-9aa8-79e08f2ac759","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cf938ef2-af2f-484b-bab0-204a71232663",
    path: "/product/cf938ef2-af2f-484b-bab0-204a71232663",
    meta: {"ID":"cf938ef2-af2f-484b-bab0-204a71232663","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|cfaf7d07-3d6f-4d66-bf18-7ac2dbabd2c0",
    path: "/product/cfaf7d07-3d6f-4d66-bf18-7ac2dbabd2c0",
    meta: {"ID":"cfaf7d07-3d6f-4d66-bf18-7ac2dbabd2c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a9e4d4c-f0f5-4e7b-85d8-f932bfcb421d|cfb85a82-3589-48bf-81ea-673859b00ddb",
    path: "/product/cfb85a82-3589-48bf-81ea-673859b00ddb",
    meta: {"ID":"cfb85a82-3589-48bf-81ea-673859b00ddb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cfbfb1f8-46e2-4ece-84b4-fb296bf2d191",
    path: "/product/cfbfb1f8-46e2-4ece-84b4-fb296bf2d191",
    meta: {"ID":"cfbfb1f8-46e2-4ece-84b4-fb296bf2d191","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|cfd03f51-5f75-4914-9802-8c378c1f5f1f",
    path: "/product/cfd03f51-5f75-4914-9802-8c378c1f5f1f",
    meta: {"ID":"cfd03f51-5f75-4914-9802-8c378c1f5f1f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|cfd7530e-7896-4d19-87c0-517f3de6b087",
    path: "/product/cfd7530e-7896-4d19-87c0-517f3de6b087",
    meta: {"ID":"cfd7530e-7896-4d19-87c0-517f3de6b087","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|cfde14db-cfd2-46a3-b9f9-1a15386e37e1",
    path: "/product/cfde14db-cfd2-46a3-b9f9-1a15386e37e1",
    meta: {"ID":"cfde14db-cfd2-46a3-b9f9-1a15386e37e1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|d011cd95-f62a-4284-af67-9d809b07fad3",
    path: "/product/d011cd95-f62a-4284-af67-9d809b07fad3",
    meta: {"ID":"d011cd95-f62a-4284-af67-9d809b07fad3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d017067f-f77f-47b8-8790-5f4291330d20",
    path: "/product/d017067f-f77f-47b8-8790-5f4291330d20",
    meta: {"ID":"d017067f-f77f-47b8-8790-5f4291330d20","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d0201dbf-faad-47f6-93c4-db9e76ef3824",
    path: "/product/d0201dbf-faad-47f6-93c4-db9e76ef3824",
    meta: {"ID":"d0201dbf-faad-47f6-93c4-db9e76ef3824","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a77b60ce-796c-4cfb-a81f-523c73aff39d|d03fcb0c-1508-4aa9-801e-7317d6efd919",
    path: "/product/d03fcb0c-1508-4aa9-801e-7317d6efd919",
    meta: {"ID":"d03fcb0c-1508-4aa9-801e-7317d6efd919","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d07fe7de-66e3-4e94-9a1f-b9eb78caded0",
    path: "/product/d07fe7de-66e3-4e94-9a1f-b9eb78caded0",
    meta: {"ID":"d07fe7de-66e3-4e94-9a1f-b9eb78caded0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d0951b33-82f4-4486-a0e4-99c6af7795c2",
    path: "/product/d0951b33-82f4-4486-a0e4-99c6af7795c2",
    meta: {"ID":"d0951b33-82f4-4486-a0e4-99c6af7795c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d0c9c700-6683-4531-ae3c-a6077c82feb0",
    path: "/product/d0c9c700-6683-4531-ae3c-a6077c82feb0",
    meta: {"ID":"d0c9c700-6683-4531-ae3c-a6077c82feb0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|db514044-cc74-4cb4-88c1-e0d3608fecb6|d0d606b2-9271-4646-835d-572aa69b966d",
    path: "/product/d0d606b2-9271-4646-835d-572aa69b966d",
    meta: {"ID":"d0d606b2-9271-4646-835d-572aa69b966d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|d0d86762-9179-4873-9c41-e4dd8e09e5ac",
    path: "/product/d0d86762-9179-4873-9c41-e4dd8e09e5ac",
    meta: {"ID":"d0d86762-9179-4873-9c41-e4dd8e09e5ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d0dc3014-55cc-46c0-88b2-bd4a7fcc870f",
    path: "/product/d0dc3014-55cc-46c0-88b2-bd4a7fcc870f",
    meta: {"ID":"d0dc3014-55cc-46c0-88b2-bd4a7fcc870f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d0f32ed1-6f8e-4558-8138-8fb8f1e2d682",
    path: "/product/d0f32ed1-6f8e-4558-8138-8fb8f1e2d682",
    meta: {"ID":"d0f32ed1-6f8e-4558-8138-8fb8f1e2d682","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d102a4af-53b9-4e82-9c1f-bd3ff7cd99fa",
    path: "/product/d102a4af-53b9-4e82-9c1f-bd3ff7cd99fa",
    meta: {"ID":"d102a4af-53b9-4e82-9c1f-bd3ff7cd99fa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|d11305be-ec39-429b-8106-6a7b8a3df225",
    path: "/product/d11305be-ec39-429b-8106-6a7b8a3df225",
    meta: {"ID":"d11305be-ec39-429b-8106-6a7b8a3df225","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|d11fff4c-e476-497b-9cf7-1deb0da952bd",
    path: "/product/d11fff4c-e476-497b-9cf7-1deb0da952bd",
    meta: {"ID":"d11fff4c-e476-497b-9cf7-1deb0da952bd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|db514044-cc74-4cb4-88c1-e0d3608fecb6|d1553279-01e8-4f78-98dc-42a4b670ac8d",
    path: "/product/d1553279-01e8-4f78-98dc-42a4b670ac8d",
    meta: {"ID":"d1553279-01e8-4f78-98dc-42a4b670ac8d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d15f6972-e116-4d04-ada7-4374804cfbae",
    path: "/product/d15f6972-e116-4d04-ada7-4374804cfbae",
    meta: {"ID":"d15f6972-e116-4d04-ada7-4374804cfbae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d16cac69-a26a-4153-b81c-b962af713941",
    path: "/product/d16cac69-a26a-4153-b81c-b962af713941",
    meta: {"ID":"d16cac69-a26a-4153-b81c-b962af713941","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|d18e3a2a-b40c-461b-9e8c-a0b125ee0a0a",
    path: "/product/d18e3a2a-b40c-461b-9e8c-a0b125ee0a0a",
    meta: {"ID":"d18e3a2a-b40c-461b-9e8c-a0b125ee0a0a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d19eb5d8-df46-4ee0-a8ed-e123d0dded96",
    path: "/product/d19eb5d8-df46-4ee0-a8ed-e123d0dded96",
    meta: {"ID":"d19eb5d8-df46-4ee0-a8ed-e123d0dded96","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d1ac22c4-5a80-41ff-b37c-62460bbbd851",
    path: "/product/d1ac22c4-5a80-41ff-b37c-62460bbbd851",
    meta: {"ID":"d1ac22c4-5a80-41ff-b37c-62460bbbd851","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d1bd95df-da1a-459d-b196-459568f2d8cf",
    path: "/product/d1bd95df-da1a-459d-b196-459568f2d8cf",
    meta: {"ID":"d1bd95df-da1a-459d-b196-459568f2d8cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d1f0dcde-02e6-4a28-97b1-5d0c627271f6",
    path: "/product/d1f0dcde-02e6-4a28-97b1-5d0c627271f6",
    meta: {"ID":"d1f0dcde-02e6-4a28-97b1-5d0c627271f6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ef0a0b3c-71b8-40c4-9f16-3c9038b9738d|d1f5dd00-9c83-435a-8948-dd1f02854a83",
    path: "/product/d1f5dd00-9c83-435a-8948-dd1f02854a83",
    meta: {"ID":"d1f5dd00-9c83-435a-8948-dd1f02854a83","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a04f5a3-7f1f-4863-9c6a-170e7e97ef1b|d21960db-a411-40fc-ae82-c2ec3228da4b",
    path: "/product/d21960db-a411-40fc-ae82-c2ec3228da4b",
    meta: {"ID":"d21960db-a411-40fc-ae82-c2ec3228da4b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d21d948f-d342-425d-8efb-15f68f8e096f",
    path: "/product/d21d948f-d342-425d-8efb-15f68f8e096f",
    meta: {"ID":"d21d948f-d342-425d-8efb-15f68f8e096f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d23f7d24-9857-4d55-a4b0-a9cdb17c15ff",
    path: "/product/d23f7d24-9857-4d55-a4b0-a9cdb17c15ff",
    meta: {"ID":"d23f7d24-9857-4d55-a4b0-a9cdb17c15ff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|d247c38f-5aff-4e2d-8061-7bacec6de748",
    path: "/product/d247c38f-5aff-4e2d-8061-7bacec6de748",
    meta: {"ID":"d247c38f-5aff-4e2d-8061-7bacec6de748","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d2523636-b96a-40cc-9c84-30141c02ab53",
    path: "/product/d2523636-b96a-40cc-9c84-30141c02ab53",
    meta: {"ID":"d2523636-b96a-40cc-9c84-30141c02ab53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|d26302fb-55b1-4445-8bb8-fb33e768324f",
    path: "/product/d26302fb-55b1-4445-8bb8-fb33e768324f",
    meta: {"ID":"d26302fb-55b1-4445-8bb8-fb33e768324f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|d27a75a3-408d-416a-9704-fec222c0f136",
    path: "/product/d27a75a3-408d-416a-9704-fec222c0f136",
    meta: {"ID":"d27a75a3-408d-416a-9704-fec222c0f136","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d27cb84c-dcbc-4f35-8f12-60dd71825413",
    path: "/product/d27cb84c-dcbc-4f35-8f12-60dd71825413",
    meta: {"ID":"d27cb84c-dcbc-4f35-8f12-60dd71825413","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d29b542d-8be1-42d7-9837-6a7b7dff4d5a",
    path: "/product/d29b542d-8be1-42d7-9837-6a7b7dff4d5a",
    meta: {"ID":"d29b542d-8be1-42d7-9837-6a7b7dff4d5a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|d2a56627-9d5a-4dfa-aeb8-3078acd9b0db",
    path: "/product/d2a56627-9d5a-4dfa-aeb8-3078acd9b0db",
    meta: {"ID":"d2a56627-9d5a-4dfa-aeb8-3078acd9b0db","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|d2d2219a-e13e-40b9-91c5-93b450de16da",
    path: "/product/d2d2219a-e13e-40b9-91c5-93b450de16da",
    meta: {"ID":"d2d2219a-e13e-40b9-91c5-93b450de16da","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d2f100c6-f101-410a-bd71-62645b6197f5",
    path: "/product/d2f100c6-f101-410a-bd71-62645b6197f5",
    meta: {"ID":"d2f100c6-f101-410a-bd71-62645b6197f5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d2f52570-218b-471b-9b54-026797ed7623",
    path: "/product/d2f52570-218b-471b-9b54-026797ed7623",
    meta: {"ID":"d2f52570-218b-471b-9b54-026797ed7623","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|d2f9c334-5014-44d2-aafa-bc6cde88a756",
    path: "/product/d2f9c334-5014-44d2-aafa-bc6cde88a756",
    meta: {"ID":"d2f9c334-5014-44d2-aafa-bc6cde88a756","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|d2fa7211-b88c-4ad4-be5a-d04fe1abdd1a",
    path: "/product/d2fa7211-b88c-4ad4-be5a-d04fe1abdd1a",
    meta: {"ID":"d2fa7211-b88c-4ad4-be5a-d04fe1abdd1a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|d30bd60b-a22b-41c7-9626-71d75d2eaf59",
    path: "/product/d30bd60b-a22b-41c7-9626-71d75d2eaf59",
    meta: {"ID":"d30bd60b-a22b-41c7-9626-71d75d2eaf59","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|d31179a3-6786-4a1a-aaca-3d9280d239eb",
    path: "/product/d31179a3-6786-4a1a-aaca-3d9280d239eb",
    meta: {"ID":"d31179a3-6786-4a1a-aaca-3d9280d239eb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d369b8c4-3f43-4125-8a6b-459e5b1c2397",
    path: "/product/d369b8c4-3f43-4125-8a6b-459e5b1c2397",
    meta: {"ID":"d369b8c4-3f43-4125-8a6b-459e5b1c2397","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|d378bb7d-47db-4d80-bdbe-5abb5a9a8908",
    path: "/product/d378bb7d-47db-4d80-bdbe-5abb5a9a8908",
    meta: {"ID":"d378bb7d-47db-4d80-bdbe-5abb5a9a8908","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|d385c71c-fb50-4c43-a741-2c58bdff6ae9",
    path: "/product/d385c71c-fb50-4c43-a741-2c58bdff6ae9",
    meta: {"ID":"d385c71c-fb50-4c43-a741-2c58bdff6ae9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|d38c9b2e-a507-412e-926d-1c1379116217",
    path: "/product/d38c9b2e-a507-412e-926d-1c1379116217",
    meta: {"ID":"d38c9b2e-a507-412e-926d-1c1379116217","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|d39794a2-b590-49a2-9094-149438b338b0",
    path: "/product/d39794a2-b590-49a2-9094-149438b338b0",
    meta: {"ID":"d39794a2-b590-49a2-9094-149438b338b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|d3af7d83-8741-41fa-beed-8cb9c3ea1803",
    path: "/product/d3af7d83-8741-41fa-beed-8cb9c3ea1803",
    meta: {"ID":"d3af7d83-8741-41fa-beed-8cb9c3ea1803","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d3b05ea9-089b-4c20-b0fa-5569d17eb229",
    path: "/product/d3b05ea9-089b-4c20-b0fa-5569d17eb229",
    meta: {"ID":"d3b05ea9-089b-4c20-b0fa-5569d17eb229","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|d3bed5af-5048-49c4-9b0e-319c444fc605",
    path: "/product/d3bed5af-5048-49c4-9b0e-319c444fc605",
    meta: {"ID":"d3bed5af-5048-49c4-9b0e-319c444fc605","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|d3c9e9c8-bf88-4be4-a321-12db7202e1bc",
    path: "/product/d3c9e9c8-bf88-4be4-a321-12db7202e1bc",
    meta: {"ID":"d3c9e9c8-bf88-4be4-a321-12db7202e1bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d3fc2803-7f52-4925-8a89-9bf566bad13d",
    path: "/product/d3fc2803-7f52-4925-8a89-9bf566bad13d",
    meta: {"ID":"d3fc2803-7f52-4925-8a89-9bf566bad13d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d407bb18-3d10-49fc-9671-1e2046554752",
    path: "/product/d407bb18-3d10-49fc-9671-1e2046554752",
    meta: {"ID":"d407bb18-3d10-49fc-9671-1e2046554752","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d4129c36-0a1e-43ce-9760-5751805eb09f",
    path: "/product/d4129c36-0a1e-43ce-9760-5751805eb09f",
    meta: {"ID":"d4129c36-0a1e-43ce-9760-5751805eb09f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|d4279d97-6984-4cd8-b4c2-0488ac14d27c",
    path: "/product/d4279d97-6984-4cd8-b4c2-0488ac14d27c",
    meta: {"ID":"d4279d97-6984-4cd8-b4c2-0488ac14d27c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|d43751e3-fef0-41a0-b99d-cffe18c40028",
    path: "/product/d43751e3-fef0-41a0-b99d-cffe18c40028",
    meta: {"ID":"d43751e3-fef0-41a0-b99d-cffe18c40028","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d43b86c7-c52e-42c6-a65f-c98fee5e6148",
    path: "/product/d43b86c7-c52e-42c6-a65f-c98fee5e6148",
    meta: {"ID":"d43b86c7-c52e-42c6-a65f-c98fee5e6148","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d450b355-7db8-4cf7-8c56-f495f0cd6176",
    path: "/product/d450b355-7db8-4cf7-8c56-f495f0cd6176",
    meta: {"ID":"d450b355-7db8-4cf7-8c56-f495f0cd6176","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|d452b799-768b-4169-a404-38b8257976c1",
    path: "/product/d452b799-768b-4169-a404-38b8257976c1",
    meta: {"ID":"d452b799-768b-4169-a404-38b8257976c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|d4a0c319-be44-4b04-8a8c-113d4c5fd363",
    path: "/product/d4a0c319-be44-4b04-8a8c-113d4c5fd363",
    meta: {"ID":"d4a0c319-be44-4b04-8a8c-113d4c5fd363","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d4a72a5c-2743-4643-b2a5-5fdebf0b02ae",
    path: "/product/d4a72a5c-2743-4643-b2a5-5fdebf0b02ae",
    meta: {"ID":"d4a72a5c-2743-4643-b2a5-5fdebf0b02ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d4a981e5-4488-471d-8dc8-07df25f6d361",
    path: "/product/d4a981e5-4488-471d-8dc8-07df25f6d361",
    meta: {"ID":"d4a981e5-4488-471d-8dc8-07df25f6d361","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d4b866dc-6496-47c7-9983-c389064b5e3a",
    path: "/product/d4b866dc-6496-47c7-9983-c389064b5e3a",
    meta: {"ID":"d4b866dc-6496-47c7-9983-c389064b5e3a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d4e4c7c9-71c4-44d9-97e2-826520426748",
    path: "/product/d4e4c7c9-71c4-44d9-97e2-826520426748",
    meta: {"ID":"d4e4c7c9-71c4-44d9-97e2-826520426748","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d526616d-e055-450d-aff1-2308c867176f",
    path: "/product/d526616d-e055-450d-aff1-2308c867176f",
    meta: {"ID":"d526616d-e055-450d-aff1-2308c867176f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|d52f2f4b-bdab-4837-8a53-bc37b5211c16",
    path: "/product/d52f2f4b-bdab-4837-8a53-bc37b5211c16",
    meta: {"ID":"d52f2f4b-bdab-4837-8a53-bc37b5211c16","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d536c673-5b17-46d4-bf5b-9fd253eec4e9",
    path: "/product/d536c673-5b17-46d4-bf5b-9fd253eec4e9",
    meta: {"ID":"d536c673-5b17-46d4-bf5b-9fd253eec4e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|d541a07c-5f90-44f3-a85e-78b29346a02b",
    path: "/product/d541a07c-5f90-44f3-a85e-78b29346a02b",
    meta: {"ID":"d541a07c-5f90-44f3-a85e-78b29346a02b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d5512dd7-f579-433e-9549-03c80ffb16d3",
    path: "/product/d5512dd7-f579-433e-9549-03c80ffb16d3",
    meta: {"ID":"d5512dd7-f579-433e-9549-03c80ffb16d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|d55389b0-4853-45a0-903d-c9884f7f0e75",
    path: "/product/d55389b0-4853-45a0-903d-c9884f7f0e75",
    meta: {"ID":"d55389b0-4853-45a0-903d-c9884f7f0e75","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d555a14b-3cb8-48a3-b18a-6e9b860693d1",
    path: "/product/d555a14b-3cb8-48a3-b18a-6e9b860693d1",
    meta: {"ID":"d555a14b-3cb8-48a3-b18a-6e9b860693d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d55fe277-fd37-4418-9ff5-be9d9e3a9893",
    path: "/product/d55fe277-fd37-4418-9ff5-be9d9e3a9893",
    meta: {"ID":"d55fe277-fd37-4418-9ff5-be9d9e3a9893","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d57304b0-fa1d-413d-9070-2b22dbf06b55",
    path: "/product/d57304b0-fa1d-413d-9070-2b22dbf06b55",
    meta: {"ID":"d57304b0-fa1d-413d-9070-2b22dbf06b55","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|d583ae92-53b4-446d-8d73-e18b2ddff296",
    path: "/product/d583ae92-53b4-446d-8d73-e18b2ddff296",
    meta: {"ID":"d583ae92-53b4-446d-8d73-e18b2ddff296","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|d5941622-0361-4eb6-a2fd-c3c7b84cb8b6",
    path: "/product/d5941622-0361-4eb6-a2fd-c3c7b84cb8b6",
    meta: {"ID":"d5941622-0361-4eb6-a2fd-c3c7b84cb8b6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d5a05d2a-c801-48a7-8e92-8bfa7a85f817",
    path: "/product/d5a05d2a-c801-48a7-8e92-8bfa7a85f817",
    meta: {"ID":"d5a05d2a-c801-48a7-8e92-8bfa7a85f817","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d5b99812-0e85-4033-a047-a00a4c0e4413",
    path: "/product/d5b99812-0e85-4033-a047-a00a4c0e4413",
    meta: {"ID":"d5b99812-0e85-4033-a047-a00a4c0e4413","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d5cb822a-8d19-4bba-8b43-77177123377f",
    path: "/product/d5cb822a-8d19-4bba-8b43-77177123377f",
    meta: {"ID":"d5cb822a-8d19-4bba-8b43-77177123377f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|d5d002ff-212e-472f-b061-7c499376c9a7",
    path: "/product/d5d002ff-212e-472f-b061-7c499376c9a7",
    meta: {"ID":"d5d002ff-212e-472f-b061-7c499376c9a7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|d5d25ed6-b6fd-4d52-8e9a-d1d3d0125db8",
    path: "/product/d5d25ed6-b6fd-4d52-8e9a-d1d3d0125db8",
    meta: {"ID":"d5d25ed6-b6fd-4d52-8e9a-d1d3d0125db8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d5ff88a7-1db1-4fb9-9910-bbf4b83b8abe",
    path: "/product/d5ff88a7-1db1-4fb9-9910-bbf4b83b8abe",
    meta: {"ID":"d5ff88a7-1db1-4fb9-9910-bbf4b83b8abe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|d622fe17-7590-48d1-a080-34ec15a14607",
    path: "/product/d622fe17-7590-48d1-a080-34ec15a14607",
    meta: {"ID":"d622fe17-7590-48d1-a080-34ec15a14607","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d62c6be3-5af9-43cd-b962-6ae79256fcae",
    path: "/product/d62c6be3-5af9-43cd-b962-6ae79256fcae",
    meta: {"ID":"d62c6be3-5af9-43cd-b962-6ae79256fcae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca1c41-f8fc-4cd3-9788-4fe61c89225d|d63948ab-f69e-4c0b-94db-4bd0660804ac",
    path: "/product/d63948ab-f69e-4c0b-94db-4bd0660804ac",
    meta: {"ID":"d63948ab-f69e-4c0b-94db-4bd0660804ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d63a5144-cfa9-4919-8233-c98aa7ade0be",
    path: "/product/d63a5144-cfa9-4919-8233-c98aa7ade0be",
    meta: {"ID":"d63a5144-cfa9-4919-8233-c98aa7ade0be","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d645d8ca-05e0-440d-8405-9426381020b2",
    path: "/product/d645d8ca-05e0-440d-8405-9426381020b2",
    meta: {"ID":"d645d8ca-05e0-440d-8405-9426381020b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|d655b944-e0bb-41fe-b19c-52eb9639e6aa",
    path: "/product/d655b944-e0bb-41fe-b19c-52eb9639e6aa",
    meta: {"ID":"d655b944-e0bb-41fe-b19c-52eb9639e6aa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d6570588-205f-4ea6-80b0-26b6ed94b7e5",
    path: "/product/d6570588-205f-4ea6-80b0-26b6ed94b7e5",
    meta: {"ID":"d6570588-205f-4ea6-80b0-26b6ed94b7e5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d6596083-6cef-45ef-8c70-37aa7c46c9c1",
    path: "/product/d6596083-6cef-45ef-8c70-37aa7c46c9c1",
    meta: {"ID":"d6596083-6cef-45ef-8c70-37aa7c46c9c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d68c27cf-82d3-4f0a-a78c-bc97b7735aa1",
    path: "/product/d68c27cf-82d3-4f0a-a78c-bc97b7735aa1",
    meta: {"ID":"d68c27cf-82d3-4f0a-a78c-bc97b7735aa1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|d6a1fabb-a7cc-4045-8a82-2e92f88ee2f2",
    path: "/product/d6a1fabb-a7cc-4045-8a82-2e92f88ee2f2",
    meta: {"ID":"d6a1fabb-a7cc-4045-8a82-2e92f88ee2f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|d6b5b07e-07b9-407f-875a-ff105a435efc",
    path: "/product/d6b5b07e-07b9-407f-875a-ff105a435efc",
    meta: {"ID":"d6b5b07e-07b9-407f-875a-ff105a435efc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d6baa2ba-6e22-421d-a8a9-64ac2834dd7e",
    path: "/product/d6baa2ba-6e22-421d-a8a9-64ac2834dd7e",
    meta: {"ID":"d6baa2ba-6e22-421d-a8a9-64ac2834dd7e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|13a53bf4-e276-409f-ab90-f1a95a5ac742|d6cb2ce2-7ec4-4788-8146-106b2e07267d",
    path: "/product/d6cb2ce2-7ec4-4788-8146-106b2e07267d",
    meta: {"ID":"d6cb2ce2-7ec4-4788-8146-106b2e07267d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|d6eed116-45e2-4da8-bdd6-afcd6b5083c1",
    path: "/product/d6eed116-45e2-4da8-bdd6-afcd6b5083c1",
    meta: {"ID":"d6eed116-45e2-4da8-bdd6-afcd6b5083c1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d6ffd907-4239-4248-a9ea-0f765230690f",
    path: "/product/d6ffd907-4239-4248-a9ea-0f765230690f",
    meta: {"ID":"d6ffd907-4239-4248-a9ea-0f765230690f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|d711f89d-ff77-4376-8e95-efdc21c9f843",
    path: "/product/d711f89d-ff77-4376-8e95-efdc21c9f843",
    meta: {"ID":"d711f89d-ff77-4376-8e95-efdc21c9f843","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|299ddc77-acac-4018-bc5d-6fcc38826111|d720e246-cbc2-4d4e-9283-04d905829469",
    path: "/product/d720e246-cbc2-4d4e-9283-04d905829469",
    meta: {"ID":"d720e246-cbc2-4d4e-9283-04d905829469","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d72fa784-9084-48a0-9e95-3e7f801adfbd",
    path: "/product/d72fa784-9084-48a0-9e95-3e7f801adfbd",
    meta: {"ID":"d72fa784-9084-48a0-9e95-3e7f801adfbd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d74235c6-7ad9-4f6c-b86d-34926a5299aa",
    path: "/product/d74235c6-7ad9-4f6c-b86d-34926a5299aa",
    meta: {"ID":"d74235c6-7ad9-4f6c-b86d-34926a5299aa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|82890741-f41f-4593-bd5b-e63c6af5f1ed|d74c0582-de54-4b80-821f-5b840a0bcf12",
    path: "/product/d74c0582-de54-4b80-821f-5b840a0bcf12",
    meta: {"ID":"d74c0582-de54-4b80-821f-5b840a0bcf12","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7801b5bb-054e-4ce0-942d-d7831c24ac40|d7a07649-feb3-4081-8a08-1a8f232436e0",
    path: "/product/d7a07649-feb3-4081-8a08-1a8f232436e0",
    meta: {"ID":"d7a07649-feb3-4081-8a08-1a8f232436e0","Title":"","GroupID":"7801b5bb-054e-4ce0-942d-d7831c24ac40","GroupTitle":"Сувениры","GroupLink":"/menu/7801b5bb-054e-4ce0-942d-d7831c24ac40","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d7a094f8-d5cb-4727-913d-28834eddf656",
    path: "/product/d7a094f8-d5cb-4727-913d-28834eddf656",
    meta: {"ID":"d7a094f8-d5cb-4727-913d-28834eddf656","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|d7be25b3-1cdd-4d12-89f4-682b27900444",
    path: "/product/d7be25b3-1cdd-4d12-89f4-682b27900444",
    meta: {"ID":"d7be25b3-1cdd-4d12-89f4-682b27900444","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|d7cc752c-7be9-4cbd-9cd5-a281a1b94eb0",
    path: "/product/d7cc752c-7be9-4cbd-9cd5-a281a1b94eb0",
    meta: {"ID":"d7cc752c-7be9-4cbd-9cd5-a281a1b94eb0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d7d8dead-0aea-436f-afba-04b66a2dcfbe",
    path: "/product/d7d8dead-0aea-436f-afba-04b66a2dcfbe",
    meta: {"ID":"d7d8dead-0aea-436f-afba-04b66a2dcfbe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|d7ef3f09-30c9-4184-823a-741ea7d29e4c",
    path: "/product/d7ef3f09-30c9-4184-823a-741ea7d29e4c",
    meta: {"ID":"d7ef3f09-30c9-4184-823a-741ea7d29e4c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d7f73f76-e4f4-40e5-8717-bc9376fd78a8",
    path: "/product/d7f73f76-e4f4-40e5-8717-bc9376fd78a8",
    meta: {"ID":"d7f73f76-e4f4-40e5-8717-bc9376fd78a8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|d800f22c-0d04-44ca-8bb2-bee9b4948f95",
    path: "/product/d800f22c-0d04-44ca-8bb2-bee9b4948f95",
    meta: {"ID":"d800f22c-0d04-44ca-8bb2-bee9b4948f95","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d81d5dee-de77-47d1-8a29-cd22da106163",
    path: "/product/d81d5dee-de77-47d1-8a29-cd22da106163",
    meta: {"ID":"d81d5dee-de77-47d1-8a29-cd22da106163","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|d81e2d98-c7e1-43ab-8aac-1ad1a4d60e3d",
    path: "/product/d81e2d98-c7e1-43ab-8aac-1ad1a4d60e3d",
    meta: {"ID":"d81e2d98-c7e1-43ab-8aac-1ad1a4d60e3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d85b731f-b602-4de7-853b-d172f2f9b35c",
    path: "/product/d85b731f-b602-4de7-853b-d172f2f9b35c",
    meta: {"ID":"d85b731f-b602-4de7-853b-d172f2f9b35c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d873e7b8-9ffd-4201-ad91-1f813f0f6079",
    path: "/product/d873e7b8-9ffd-4201-ad91-1f813f0f6079",
    meta: {"ID":"d873e7b8-9ffd-4201-ad91-1f813f0f6079","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|d8afe46c-794c-479d-9635-255629e4dbb4",
    path: "/product/d8afe46c-794c-479d-9635-255629e4dbb4",
    meta: {"ID":"d8afe46c-794c-479d-9635-255629e4dbb4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d8b70874-22de-4e91-af72-6b7705196e0d",
    path: "/product/d8b70874-22de-4e91-af72-6b7705196e0d",
    meta: {"ID":"d8b70874-22de-4e91-af72-6b7705196e0d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|d8c07798-94fb-4b6b-9d8b-7efa84283c93",
    path: "/product/d8c07798-94fb-4b6b-9d8b-7efa84283c93",
    meta: {"ID":"d8c07798-94fb-4b6b-9d8b-7efa84283c93","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d8e440cf-eb42-42e5-b579-974779ac80fd",
    path: "/product/d8e440cf-eb42-42e5-b579-974779ac80fd",
    meta: {"ID":"d8e440cf-eb42-42e5-b579-974779ac80fd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d8e831a1-f05a-4641-b220-5ff24eb6c9db",
    path: "/product/d8e831a1-f05a-4641-b220-5ff24eb6c9db",
    meta: {"ID":"d8e831a1-f05a-4641-b220-5ff24eb6c9db","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|d586fdfe-fc1e-4037-89d8-4ebed69e1496|d8f4ad99-0b0e-46ba-9e6d-dd481787f700",
    path: "/product/d8f4ad99-0b0e-46ba-9e6d-dd481787f700",
    meta: {"ID":"d8f4ad99-0b0e-46ba-9e6d-dd481787f700","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d8f866b2-baf8-4b9e-9127-de358809f70a",
    path: "/product/d8f866b2-baf8-4b9e-9127-de358809f70a",
    meta: {"ID":"d8f866b2-baf8-4b9e-9127-de358809f70a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|d90cd94e-c32a-452b-92f0-46b48e7c0029",
    path: "/product/d90cd94e-c32a-452b-92f0-46b48e7c0029",
    meta: {"ID":"d90cd94e-c32a-452b-92f0-46b48e7c0029","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|d913710f-8885-4288-b656-d57839f41b52",
    path: "/product/d913710f-8885-4288-b656-d57839f41b52",
    meta: {"ID":"d913710f-8885-4288-b656-d57839f41b52","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d93a913b-d0ed-410e-a78f-6453c00df26f",
    path: "/product/d93a913b-d0ed-410e-a78f-6453c00df26f",
    meta: {"ID":"d93a913b-d0ed-410e-a78f-6453c00df26f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d9533ddf-0619-43cb-b2a5-27efbc03d801",
    path: "/product/d9533ddf-0619-43cb-b2a5-27efbc03d801",
    meta: {"ID":"d9533ddf-0619-43cb-b2a5-27efbc03d801","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|d95a2042-8362-4a7c-aff9-b49051ed4da6",
    path: "/product/d95a2042-8362-4a7c-aff9-b49051ed4da6",
    meta: {"ID":"d95a2042-8362-4a7c-aff9-b49051ed4da6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|d9a9f4c3-13a5-4b0b-8e24-bcd9db213e3e",
    path: "/product/d9a9f4c3-13a5-4b0b-8e24-bcd9db213e3e",
    meta: {"ID":"d9a9f4c3-13a5-4b0b-8e24-bcd9db213e3e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d9bc5c60-e784-4ae4-a650-7db95dee086e",
    path: "/product/d9bc5c60-e784-4ae4-a650-7db95dee086e",
    meta: {"ID":"d9bc5c60-e784-4ae4-a650-7db95dee086e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|d9fbcf21-3eaf-4640-9716-963f4a42b2ec",
    path: "/product/d9fbcf21-3eaf-4640-9716-963f4a42b2ec",
    meta: {"ID":"d9fbcf21-3eaf-4640-9716-963f4a42b2ec","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|d9fbdf87-5399-44bc-aab1-ed2b8ea7533c",
    path: "/product/d9fbdf87-5399-44bc-aab1-ed2b8ea7533c",
    meta: {"ID":"d9fbdf87-5399-44bc-aab1-ed2b8ea7533c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|da2e19e4-999e-4f5b-ab2c-7ebd76bd2fc1",
    path: "/product/da2e19e4-999e-4f5b-ab2c-7ebd76bd2fc1",
    meta: {"ID":"da2e19e4-999e-4f5b-ab2c-7ebd76bd2fc1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|da349a80-3ffa-48ed-ac14-f9ecd42cb086",
    path: "/product/da349a80-3ffa-48ed-ac14-f9ecd42cb086",
    meta: {"ID":"da349a80-3ffa-48ed-ac14-f9ecd42cb086","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|da5a0c0b-170d-42a8-9adb-b068732db764",
    path: "/product/da5a0c0b-170d-42a8-9adb-b068732db764",
    meta: {"ID":"da5a0c0b-170d-42a8-9adb-b068732db764","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|da919b9a-fd3d-45ac-8903-b01f54293275",
    path: "/product/da919b9a-fd3d-45ac-8903-b01f54293275",
    meta: {"ID":"da919b9a-fd3d-45ac-8903-b01f54293275","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|da94bb76-d232-4bc9-8a72-debdbae4bee7",
    path: "/product/da94bb76-d232-4bc9-8a72-debdbae4bee7",
    meta: {"ID":"da94bb76-d232-4bc9-8a72-debdbae4bee7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|da9c9a93-9882-4840-bed4-fa954d0a2f94",
    path: "/product/da9c9a93-9882-4840-bed4-fa954d0a2f94",
    meta: {"ID":"da9c9a93-9882-4840-bed4-fa954d0a2f94","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|dac8c53a-df3a-4f8e-a174-77bb7eb3ef6d",
    path: "/product/dac8c53a-df3a-4f8e-a174-77bb7eb3ef6d",
    meta: {"ID":"dac8c53a-df3a-4f8e-a174-77bb7eb3ef6d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|daddec42-d9e1-4b54-936c-6e5ae4a0a56d",
    path: "/product/daddec42-d9e1-4b54-936c-6e5ae4a0a56d",
    meta: {"ID":"daddec42-d9e1-4b54-936c-6e5ae4a0a56d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|dae90d6e-a668-4187-8d57-1452f6a4cbd7",
    path: "/product/dae90d6e-a668-4187-8d57-1452f6a4cbd7",
    meta: {"ID":"dae90d6e-a668-4187-8d57-1452f6a4cbd7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|daf41ea6-fe25-459d-be89-f288a3c02e4d",
    path: "/product/daf41ea6-fe25-459d-be89-f288a3c02e4d",
    meta: {"ID":"daf41ea6-fe25-459d-be89-f288a3c02e4d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|db0143e4-6541-4913-8de3-9fb91a8592ac",
    path: "/product/db0143e4-6541-4913-8de3-9fb91a8592ac",
    meta: {"ID":"db0143e4-6541-4913-8de3-9fb91a8592ac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|db051d14-6cef-4a15-a110-38946b18a3cc",
    path: "/product/db051d14-6cef-4a15-a110-38946b18a3cc",
    meta: {"ID":"db051d14-6cef-4a15-a110-38946b18a3cc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a04f5a3-7f1f-4863-9c6a-170e7e97ef1b|db2646fb-dd5c-49c7-9661-a9642747e666",
    path: "/product/db2646fb-dd5c-49c7-9661-a9642747e666",
    meta: {"ID":"db2646fb-dd5c-49c7-9661-a9642747e666","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|db2a5276-196b-4469-a8bb-b8382fd8395e",
    path: "/product/db2a5276-196b-4469-a8bb-b8382fd8395e",
    meta: {"ID":"db2a5276-196b-4469-a8bb-b8382fd8395e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|db2cd70a-2da8-49a9-9773-1a3993e091e6",
    path: "/product/db2cd70a-2da8-49a9-9773-1a3993e091e6",
    meta: {"ID":"db2cd70a-2da8-49a9-9773-1a3993e091e6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|db2e89d9-04f8-4f67-80eb-964164376a74",
    path: "/product/db2e89d9-04f8-4f67-80eb-964164376a74",
    meta: {"ID":"db2e89d9-04f8-4f67-80eb-964164376a74","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c5e2a83-ebb6-41d5-8b1b-ece7327e08df|db34583b-8feb-45b5-a840-1911c13ee24e",
    path: "/product/db34583b-8feb-45b5-a840-1911c13ee24e",
    meta: {"ID":"db34583b-8feb-45b5-a840-1911c13ee24e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|68b13f59-5b5e-4f71-90ae-eadb72690750|db490f42-78ee-438e-8799-d35dfc6c7560",
    path: "/product/db490f42-78ee-438e-8799-d35dfc6c7560",
    meta: {"ID":"db490f42-78ee-438e-8799-d35dfc6c7560","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|db65214e-6e3c-4733-8f93-697ff5126ae1",
    path: "/product/db65214e-6e3c-4733-8f93-697ff5126ae1",
    meta: {"ID":"db65214e-6e3c-4733-8f93-697ff5126ae1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|db6b5b82-fccc-41b1-8ff0-b87c2aaa33bf",
    path: "/product/db6b5b82-fccc-41b1-8ff0-b87c2aaa33bf",
    meta: {"ID":"db6b5b82-fccc-41b1-8ff0-b87c2aaa33bf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|db798060-8825-474a-bd3b-4b06a0e4bc3c",
    path: "/product/db798060-8825-474a-bd3b-4b06a0e4bc3c",
    meta: {"ID":"db798060-8825-474a-bd3b-4b06a0e4bc3c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|db832b8e-8aff-4c28-ba24-a543352b91ea",
    path: "/product/db832b8e-8aff-4c28-ba24-a543352b91ea",
    meta: {"ID":"db832b8e-8aff-4c28-ba24-a543352b91ea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|db946ae3-d3b3-49f2-83f7-5850228eca34",
    path: "/product/db946ae3-d3b3-49f2-83f7-5850228eca34",
    meta: {"ID":"db946ae3-d3b3-49f2-83f7-5850228eca34","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|db950dfa-0726-4fb0-8aed-ab2e8f7c2be3",
    path: "/product/db950dfa-0726-4fb0-8aed-ab2e8f7c2be3",
    meta: {"ID":"db950dfa-0726-4fb0-8aed-ab2e8f7c2be3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|dbda1e4d-5534-44b3-9b1f-a631ef29d62d",
    path: "/product/dbda1e4d-5534-44b3-9b1f-a631ef29d62d",
    meta: {"ID":"dbda1e4d-5534-44b3-9b1f-a631ef29d62d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|dbee243a-05b7-4bc2-ba8b-5d0c580505b3",
    path: "/product/dbee243a-05b7-4bc2-ba8b-5d0c580505b3",
    meta: {"ID":"dbee243a-05b7-4bc2-ba8b-5d0c580505b3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|dbf2d4ba-327b-4044-84fc-688004044ddb",
    path: "/product/dbf2d4ba-327b-4044-84fc-688004044ddb",
    meta: {"ID":"dbf2d4ba-327b-4044-84fc-688004044ddb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|dbf68642-a87a-4b7c-ab21-752d58a9481c",
    path: "/product/dbf68642-a87a-4b7c-ab21-752d58a9481c",
    meta: {"ID":"dbf68642-a87a-4b7c-ab21-752d58a9481c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|dbff1806-4102-478e-9a48-75f781e4c3d7",
    path: "/product/dbff1806-4102-478e-9a48-75f781e4c3d7",
    meta: {"ID":"dbff1806-4102-478e-9a48-75f781e4c3d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7801b5bb-054e-4ce0-942d-d7831c24ac40|dc211972-0bac-4ea5-9a63-7dba2ec0650c",
    path: "/product/dc211972-0bac-4ea5-9a63-7dba2ec0650c",
    meta: {"ID":"dc211972-0bac-4ea5-9a63-7dba2ec0650c","Title":"","GroupID":"7801b5bb-054e-4ce0-942d-d7831c24ac40","GroupTitle":"Сувениры","GroupLink":"/menu/7801b5bb-054e-4ce0-942d-d7831c24ac40","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|dc3425ba-940c-411f-aa9d-5e374c714610",
    path: "/product/dc3425ba-940c-411f-aa9d-5e374c714610",
    meta: {"ID":"dc3425ba-940c-411f-aa9d-5e374c714610","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|dc4d9361-61a4-45c2-b008-c8aa7e768e5e",
    path: "/product/dc4d9361-61a4-45c2-b008-c8aa7e768e5e",
    meta: {"ID":"dc4d9361-61a4-45c2-b008-c8aa7e768e5e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|dc68d54f-b724-42c8-bc8c-fcea23743048",
    path: "/product/dc68d54f-b724-42c8-bc8c-fcea23743048",
    meta: {"ID":"dc68d54f-b724-42c8-bc8c-fcea23743048","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|dc6b15ce-a606-419b-a5bf-5ecde28f65f4",
    path: "/product/dc6b15ce-a606-419b-a5bf-5ecde28f65f4",
    meta: {"ID":"dc6b15ce-a606-419b-a5bf-5ecde28f65f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|dc76dd9c-9866-43e7-954a-6bfd5a101a15",
    path: "/product/dc76dd9c-9866-43e7-954a-6bfd5a101a15",
    meta: {"ID":"dc76dd9c-9866-43e7-954a-6bfd5a101a15","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|dc9d5988-0784-4c63-afbc-64fdcca84432",
    path: "/product/dc9d5988-0784-4c63-afbc-64fdcca84432",
    meta: {"ID":"dc9d5988-0784-4c63-afbc-64fdcca84432","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|dcb7af53-3811-46b7-87f2-61be9a63d5f0",
    path: "/product/dcb7af53-3811-46b7-87f2-61be9a63d5f0",
    meta: {"ID":"dcb7af53-3811-46b7-87f2-61be9a63d5f0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|dcbb1c33-55fa-40ef-8e69-fbe792f60500",
    path: "/product/dcbb1c33-55fa-40ef-8e69-fbe792f60500",
    meta: {"ID":"dcbb1c33-55fa-40ef-8e69-fbe792f60500","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|dce98414-2e38-4c32-94c8-40621cd766d0",
    path: "/product/dce98414-2e38-4c32-94c8-40621cd766d0",
    meta: {"ID":"dce98414-2e38-4c32-94c8-40621cd766d0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|dd06e3df-11bd-416d-a82e-104a3e9efbae",
    path: "/product/dd06e3df-11bd-416d-a82e-104a3e9efbae",
    meta: {"ID":"dd06e3df-11bd-416d-a82e-104a3e9efbae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|dd1ab10a-a7e7-4b09-bd41-deeb59f1e49d",
    path: "/product/dd1ab10a-a7e7-4b09-bd41-deeb59f1e49d",
    meta: {"ID":"dd1ab10a-a7e7-4b09-bd41-deeb59f1e49d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|dd4ac30f-96f4-4704-886d-33c3f71f624f",
    path: "/product/dd4ac30f-96f4-4704-886d-33c3f71f624f",
    meta: {"ID":"dd4ac30f-96f4-4704-886d-33c3f71f624f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|dd608cec-b5ac-44af-b343-a2390327f20d",
    path: "/product/dd608cec-b5ac-44af-b343-a2390327f20d",
    meta: {"ID":"dd608cec-b5ac-44af-b343-a2390327f20d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|dd8437b5-2f64-49fa-b9d8-449aaacc9373",
    path: "/product/dd8437b5-2f64-49fa-b9d8-449aaacc9373",
    meta: {"ID":"dd8437b5-2f64-49fa-b9d8-449aaacc9373","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|dda7553b-f42e-498b-98cd-55894bee5c58",
    path: "/product/dda7553b-f42e-498b-98cd-55894bee5c58",
    meta: {"ID":"dda7553b-f42e-498b-98cd-55894bee5c58","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|654b3dbf-24ce-4acd-8343-54dbb128223f|ddc9039e-3b9d-4cff-abd8-260123e817f0",
    path: "/product/ddc9039e-3b9d-4cff-abd8-260123e817f0",
    meta: {"ID":"ddc9039e-3b9d-4cff-abd8-260123e817f0","Title":"","GroupID":"654b3dbf-24ce-4acd-8343-54dbb128223f","GroupTitle":"Супы","GroupLink":"/menu/654b3dbf-24ce-4acd-8343-54dbb128223f","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4349f183-f876-4668-9110-f50347c57ef2|ddc9b149-a0d3-472b-a14a-d9f0658e0960",
    path: "/product/ddc9b149-a0d3-472b-a14a-d9f0658e0960",
    meta: {"ID":"ddc9b149-a0d3-472b-a14a-d9f0658e0960","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|ddcbba8a-25a8-4072-87fa-4166f2ecf723",
    path: "/product/ddcbba8a-25a8-4072-87fa-4166f2ecf723",
    meta: {"ID":"ddcbba8a-25a8-4072-87fa-4166f2ecf723","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|dddaca8f-468c-470d-a287-618cc8c5b9a9",
    path: "/product/dddaca8f-468c-470d-a287-618cc8c5b9a9",
    meta: {"ID":"dddaca8f-468c-470d-a287-618cc8c5b9a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|de084f26-4031-483f-9c92-b7d4e40654c7",
    path: "/product/de084f26-4031-483f-9c92-b7d4e40654c7",
    meta: {"ID":"de084f26-4031-483f-9c92-b7d4e40654c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|de08fbe5-a9b1-4fc8-8324-ae258aec8eea",
    path: "/product/de08fbe5-a9b1-4fc8-8324-ae258aec8eea",
    meta: {"ID":"de08fbe5-a9b1-4fc8-8324-ae258aec8eea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|de16e00c-298c-4424-bcdf-880fa8e1335b",
    path: "/product/de16e00c-298c-4424-bcdf-880fa8e1335b",
    meta: {"ID":"de16e00c-298c-4424-bcdf-880fa8e1335b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|de4415fa-7ffd-43fe-b5b3-e7319e295c1d",
    path: "/product/de4415fa-7ffd-43fe-b5b3-e7319e295c1d",
    meta: {"ID":"de4415fa-7ffd-43fe-b5b3-e7319e295c1d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|de52ed3e-bd9c-4e87-acbe-e8e539e62e2c",
    path: "/product/de52ed3e-bd9c-4e87-acbe-e8e539e62e2c",
    meta: {"ID":"de52ed3e-bd9c-4e87-acbe-e8e539e62e2c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|de5707c1-a026-419e-a771-b4d97cc19aa5",
    path: "/product/de5707c1-a026-419e-a771-b4d97cc19aa5",
    meta: {"ID":"de5707c1-a026-419e-a771-b4d97cc19aa5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|de84e16e-ac96-4a8c-8d07-d27911edfe03",
    path: "/product/de84e16e-ac96-4a8c-8d07-d27911edfe03",
    meta: {"ID":"de84e16e-ac96-4a8c-8d07-d27911edfe03","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|de8850d3-1815-4fae-b322-a4c646e2263b",
    path: "/product/de8850d3-1815-4fae-b322-a4c646e2263b",
    meta: {"ID":"de8850d3-1815-4fae-b322-a4c646e2263b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|de8a2f81-7e48-439b-a397-c43656b9ae23",
    path: "/product/de8a2f81-7e48-439b-a397-c43656b9ae23",
    meta: {"ID":"de8a2f81-7e48-439b-a397-c43656b9ae23","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|de9e62dd-f9a1-4c21-ad90-2b6087cd41d7",
    path: "/product/de9e62dd-f9a1-4c21-ad90-2b6087cd41d7",
    meta: {"ID":"de9e62dd-f9a1-4c21-ad90-2b6087cd41d7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|deabfd97-6f2e-4041-86cf-13df0d4cc4f3",
    path: "/product/deabfd97-6f2e-4041-86cf-13df0d4cc4f3",
    meta: {"ID":"deabfd97-6f2e-4041-86cf-13df0d4cc4f3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e2a1e7d9-7ca7-4450-84e5-be47dd4e74a5|debca66d-7afc-4714-8d4f-a0f3362680bc",
    path: "/product/debca66d-7afc-4714-8d4f-a0f3362680bc",
    meta: {"ID":"debca66d-7afc-4714-8d4f-a0f3362680bc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|def98679-1d3e-4bc1-a45a-9c462b8bc62f",
    path: "/product/def98679-1d3e-4bc1-a45a-9c462b8bc62f",
    meta: {"ID":"def98679-1d3e-4bc1-a45a-9c462b8bc62f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|df48e1c9-c64d-4fca-a117-8dad0bb523b1",
    path: "/product/df48e1c9-c64d-4fca-a117-8dad0bb523b1",
    meta: {"ID":"df48e1c9-c64d-4fca-a117-8dad0bb523b1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2baa7814-309c-4d94-b87a-916c5430072b|df6317ff-6092-49aa-9618-85e3fae1b5c9",
    path: "/product/df6317ff-6092-49aa-9618-85e3fae1b5c9",
    meta: {"ID":"df6317ff-6092-49aa-9618-85e3fae1b5c9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|df672ff5-1078-4d3a-b52e-6f17a4773677",
    path: "/product/df672ff5-1078-4d3a-b52e-6f17a4773677",
    meta: {"ID":"df672ff5-1078-4d3a-b52e-6f17a4773677","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|df9c4d1e-9ace-460d-82b2-2963fcab75c6",
    path: "/product/df9c4d1e-9ace-460d-82b2-2963fcab75c6",
    meta: {"ID":"df9c4d1e-9ace-460d-82b2-2963fcab75c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|dfaa46c7-1087-446d-9f9e-396d62d88da8",
    path: "/product/dfaa46c7-1087-446d-9f9e-396d62d88da8",
    meta: {"ID":"dfaa46c7-1087-446d-9f9e-396d62d88da8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|dfcdaddd-07e1-426d-ab20-67437065c206",
    path: "/product/dfcdaddd-07e1-426d-ab20-67437065c206",
    meta: {"ID":"dfcdaddd-07e1-426d-ab20-67437065c206","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|dfe4b2d0-4f93-4d3c-823c-1660cb95b2b8",
    path: "/product/dfe4b2d0-4f93-4d3c-823c-1660cb95b2b8",
    meta: {"ID":"dfe4b2d0-4f93-4d3c-823c-1660cb95b2b8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|dfe6dbd9-2f75-4b41-bd88-0f027d7d1681",
    path: "/product/dfe6dbd9-2f75-4b41-bd88-0f027d7d1681",
    meta: {"ID":"dfe6dbd9-2f75-4b41-bd88-0f027d7d1681","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|dff1b03e-2cbd-40c0-92a6-48ad9e5642f2",
    path: "/product/dff1b03e-2cbd-40c0-92a6-48ad9e5642f2",
    meta: {"ID":"dff1b03e-2cbd-40c0-92a6-48ad9e5642f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e000b282-6471-4f94-a61e-aa4bea1ec82b",
    path: "/product/e000b282-6471-4f94-a61e-aa4bea1ec82b",
    meta: {"ID":"e000b282-6471-4f94-a61e-aa4bea1ec82b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e021baea-3be9-4b0f-a2d5-3dcccc564e33",
    path: "/product/e021baea-3be9-4b0f-a2d5-3dcccc564e33",
    meta: {"ID":"e021baea-3be9-4b0f-a2d5-3dcccc564e33","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e03286f7-b7c9-42b8-89f6-bf4fcd06eef7",
    path: "/product/e03286f7-b7c9-42b8-89f6-bf4fcd06eef7",
    meta: {"ID":"e03286f7-b7c9-42b8-89f6-bf4fcd06eef7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|e0401e43-ea73-4588-bfe1-e0d3001488ea",
    path: "/product/e0401e43-ea73-4588-bfe1-e0d3001488ea",
    meta: {"ID":"e0401e43-ea73-4588-bfe1-e0d3001488ea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e06a7136-c6cd-420f-ac2d-feff417d5dd7",
    path: "/product/e06a7136-c6cd-420f-ac2d-feff417d5dd7",
    meta: {"ID":"e06a7136-c6cd-420f-ac2d-feff417d5dd7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e08734a5-257c-4433-ad19-80c8864ca072",
    path: "/product/e08734a5-257c-4433-ad19-80c8864ca072",
    meta: {"ID":"e08734a5-257c-4433-ad19-80c8864ca072","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e087ab8e-76ac-4a95-bbdf-b435d13bc233",
    path: "/product/e087ab8e-76ac-4a95-bbdf-b435d13bc233",
    meta: {"ID":"e087ab8e-76ac-4a95-bbdf-b435d13bc233","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|e0b05e5a-2ded-4b00-88c0-ac8201492843",
    path: "/product/e0b05e5a-2ded-4b00-88c0-ac8201492843",
    meta: {"ID":"e0b05e5a-2ded-4b00-88c0-ac8201492843","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e0d2f695-dda3-4ea7-a436-9ebf520ed440",
    path: "/product/e0d2f695-dda3-4ea7-a436-9ebf520ed440",
    meta: {"ID":"e0d2f695-dda3-4ea7-a436-9ebf520ed440","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e0d80356-677a-4276-bab2-d1e0631ed1e9",
    path: "/product/e0d80356-677a-4276-bab2-d1e0631ed1e9",
    meta: {"ID":"e0d80356-677a-4276-bab2-d1e0631ed1e9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|e0eb1455-ece9-4f32-8e8f-a0a23cd5d564",
    path: "/product/e0eb1455-ece9-4f32-8e8f-a0a23cd5d564",
    meta: {"ID":"e0eb1455-ece9-4f32-8e8f-a0a23cd5d564","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|e1042cbd-cb27-4652-8c4c-76de262cf801",
    path: "/product/e1042cbd-cb27-4652-8c4c-76de262cf801",
    meta: {"ID":"e1042cbd-cb27-4652-8c4c-76de262cf801","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e108fd24-d92f-4b32-89dd-520212ca0baf",
    path: "/product/e108fd24-d92f-4b32-89dd-520212ca0baf",
    meta: {"ID":"e108fd24-d92f-4b32-89dd-520212ca0baf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|e11f1ac7-2e65-48e9-8659-8e4174fbf2f2",
    path: "/product/e11f1ac7-2e65-48e9-8659-8e4174fbf2f2",
    meta: {"ID":"e11f1ac7-2e65-48e9-8659-8e4174fbf2f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e1246b1f-c8c8-495a-8468-a88e9053d279",
    path: "/product/e1246b1f-c8c8-495a-8468-a88e9053d279",
    meta: {"ID":"e1246b1f-c8c8-495a-8468-a88e9053d279","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e12dc9f3-11f2-4f59-8b55-e61cf1a0ed79",
    path: "/product/e12dc9f3-11f2-4f59-8b55-e61cf1a0ed79",
    meta: {"ID":"e12dc9f3-11f2-4f59-8b55-e61cf1a0ed79","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|0f33a02b-92fa-4922-9e4c-0b4cc4e13e79|e13053e7-473c-426b-b443-92c9f4dbe1a6",
    path: "/product/e13053e7-473c-426b-b443-92c9f4dbe1a6",
    meta: {"ID":"e13053e7-473c-426b-b443-92c9f4dbe1a6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e14c39a1-2e01-443c-b1d2-13acf5152b00",
    path: "/product/e14c39a1-2e01-443c-b1d2-13acf5152b00",
    meta: {"ID":"e14c39a1-2e01-443c-b1d2-13acf5152b00","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e158a14c-0ee6-424a-8e46-8020365d5556",
    path: "/product/e158a14c-0ee6-424a-8e46-8020365d5556",
    meta: {"ID":"e158a14c-0ee6-424a-8e46-8020365d5556","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|e1787653-66e5-462b-82cc-0ea4875c204b",
    path: "/product/e1787653-66e5-462b-82cc-0ea4875c204b",
    meta: {"ID":"e1787653-66e5-462b-82cc-0ea4875c204b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e1a3f4f8-f445-4e6a-ba28-f721bae585fc",
    path: "/product/e1a3f4f8-f445-4e6a-ba28-f721bae585fc",
    meta: {"ID":"e1a3f4f8-f445-4e6a-ba28-f721bae585fc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|e1ab105b-37ba-403d-8963-dbe0b5bd1784",
    path: "/product/e1ab105b-37ba-403d-8963-dbe0b5bd1784",
    meta: {"ID":"e1ab105b-37ba-403d-8963-dbe0b5bd1784","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e1bc5b43-3ecd-4520-9d62-4cccdbd30885",
    path: "/product/e1bc5b43-3ecd-4520-9d62-4cccdbd30885",
    meta: {"ID":"e1bc5b43-3ecd-4520-9d62-4cccdbd30885","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2baa7814-309c-4d94-b87a-916c5430072b|e1ca93c1-2bd4-40a2-b3c4-609649226867",
    path: "/product/e1ca93c1-2bd4-40a2-b3c4-609649226867",
    meta: {"ID":"e1ca93c1-2bd4-40a2-b3c4-609649226867","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|e1d0b2ba-5936-49ef-ab85-45bf24823aca",
    path: "/product/e1d0b2ba-5936-49ef-ab85-45bf24823aca",
    meta: {"ID":"e1d0b2ba-5936-49ef-ab85-45bf24823aca","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e21d34b9-614c-4182-9342-63568a820745",
    path: "/product/e21d34b9-614c-4182-9342-63568a820745",
    meta: {"ID":"e21d34b9-614c-4182-9342-63568a820745","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|e22150e8-efd2-4a68-9d1e-594113677dfc",
    path: "/product/e22150e8-efd2-4a68-9d1e-594113677dfc",
    meta: {"ID":"e22150e8-efd2-4a68-9d1e-594113677dfc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e230bd37-f9a0-4b05-9028-bfa0ceff8202",
    path: "/product/e230bd37-f9a0-4b05-9028-bfa0ceff8202",
    meta: {"ID":"e230bd37-f9a0-4b05-9028-bfa0ceff8202","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|e23c6259-666b-4395-bf7f-399c524652e1",
    path: "/product/e23c6259-666b-4395-bf7f-399c524652e1",
    meta: {"ID":"e23c6259-666b-4395-bf7f-399c524652e1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e246acf8-222d-4722-9738-a1892a150313",
    path: "/product/e246acf8-222d-4722-9738-a1892a150313",
    meta: {"ID":"e246acf8-222d-4722-9738-a1892a150313","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|e256e5c0-f99b-4b06-9481-5280c55978d4",
    path: "/product/e256e5c0-f99b-4b06-9481-5280c55978d4",
    meta: {"ID":"e256e5c0-f99b-4b06-9481-5280c55978d4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|e275ff5d-0332-416d-9fc7-ac88735c73c4",
    path: "/product/e275ff5d-0332-416d-9fc7-ac88735c73c4",
    meta: {"ID":"e275ff5d-0332-416d-9fc7-ac88735c73c4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e2791fdc-9943-488f-b804-c626cd4077dc",
    path: "/product/e2791fdc-9943-488f-b804-c626cd4077dc",
    meta: {"ID":"e2791fdc-9943-488f-b804-c626cd4077dc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e2815503-6a54-4b55-90b8-cce6eb27041d",
    path: "/product/e2815503-6a54-4b55-90b8-cce6eb27041d",
    meta: {"ID":"e2815503-6a54-4b55-90b8-cce6eb27041d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e2bd9d3a-b139-4925-8f58-7fbeac305661",
    path: "/product/e2bd9d3a-b139-4925-8f58-7fbeac305661",
    meta: {"ID":"e2bd9d3a-b139-4925-8f58-7fbeac305661","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e2c19820-7c6e-43e1-849e-82d1a9445598",
    path: "/product/e2c19820-7c6e-43e1-849e-82d1a9445598",
    meta: {"ID":"e2c19820-7c6e-43e1-849e-82d1a9445598","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e2cc2f08-3e40-43e9-8698-384924ccdd8b",
    path: "/product/e2cc2f08-3e40-43e9-8698-384924ccdd8b",
    meta: {"ID":"e2cc2f08-3e40-43e9-8698-384924ccdd8b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|e2db2a4c-0bc4-4828-873f-e75e2f2675cd",
    path: "/product/e2db2a4c-0bc4-4828-873f-e75e2f2675cd",
    meta: {"ID":"e2db2a4c-0bc4-4828-873f-e75e2f2675cd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e2fdeb34-e966-46a6-8c07-7266384cdf4d",
    path: "/product/e2fdeb34-e966-46a6-8c07-7266384cdf4d",
    meta: {"ID":"e2fdeb34-e966-46a6-8c07-7266384cdf4d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|e3090548-73be-4a3d-8022-a0a384fb8b1e",
    path: "/product/e3090548-73be-4a3d-8022-a0a384fb8b1e",
    meta: {"ID":"e3090548-73be-4a3d-8022-a0a384fb8b1e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e3254e58-859b-42e7-9554-16a023d1dbdd",
    path: "/product/e3254e58-859b-42e7-9554-16a023d1dbdd",
    meta: {"ID":"e3254e58-859b-42e7-9554-16a023d1dbdd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4349f183-f876-4668-9110-f50347c57ef2|e32efd7f-7b14-403c-8cfa-aa268e32ec00",
    path: "/product/e32efd7f-7b14-403c-8cfa-aa268e32ec00",
    meta: {"ID":"e32efd7f-7b14-403c-8cfa-aa268e32ec00","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e350f50d-0fa7-4346-b51b-00b8737bcef5",
    path: "/product/e350f50d-0fa7-4346-b51b-00b8737bcef5",
    meta: {"ID":"e350f50d-0fa7-4346-b51b-00b8737bcef5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|e3641f20-4115-4475-aa87-eb29f234e247",
    path: "/product/e3641f20-4115-4475-aa87-eb29f234e247",
    meta: {"ID":"e3641f20-4115-4475-aa87-eb29f234e247","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e3756518-3e09-41c5-9f32-b2041097ed4f",
    path: "/product/e3756518-3e09-41c5-9f32-b2041097ed4f",
    meta: {"ID":"e3756518-3e09-41c5-9f32-b2041097ed4f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e3953323-b40a-4130-8792-0bfc0f9bbf96",
    path: "/product/e3953323-b40a-4130-8792-0bfc0f9bbf96",
    meta: {"ID":"e3953323-b40a-4130-8792-0bfc0f9bbf96","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e39c5c9d-a7b2-4ccd-a248-ac8c2cb95fe6",
    path: "/product/e39c5c9d-a7b2-4ccd-a248-ac8c2cb95fe6",
    meta: {"ID":"e39c5c9d-a7b2-4ccd-a248-ac8c2cb95fe6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|e3bfa744-6594-477b-8318-5f391ff57b62",
    path: "/product/e3bfa744-6594-477b-8318-5f391ff57b62",
    meta: {"ID":"e3bfa744-6594-477b-8318-5f391ff57b62","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|e3c26bd1-f572-42c5-ad21-c84cc4be6fb2",
    path: "/product/e3c26bd1-f572-42c5-ad21-c84cc4be6fb2",
    meta: {"ID":"e3c26bd1-f572-42c5-ad21-c84cc4be6fb2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|e422004e-c1d3-4284-8594-b09ef842d5c3",
    path: "/product/e422004e-c1d3-4284-8594-b09ef842d5c3",
    meta: {"ID":"e422004e-c1d3-4284-8594-b09ef842d5c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|e422476a-ac1c-4f92-b5be-4631a365135f",
    path: "/product/e422476a-ac1c-4f92-b5be-4631a365135f",
    meta: {"ID":"e422476a-ac1c-4f92-b5be-4631a365135f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e42548a8-06fe-40aa-b412-d96a6fb5fbe8",
    path: "/product/e42548a8-06fe-40aa-b412-d96a6fb5fbe8",
    meta: {"ID":"e42548a8-06fe-40aa-b412-d96a6fb5fbe8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ba182546-2138-476d-9205-c7b6a94fb1d2|e44d3c6f-ed28-4ede-856c-d2e70aed9d4d",
    path: "/product/e44d3c6f-ed28-4ede-856c-d2e70aed9d4d",
    meta: {"ID":"e44d3c6f-ed28-4ede-856c-d2e70aed9d4d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e469254b-75f6-4e6a-a55f-63fe5c76589d",
    path: "/product/e469254b-75f6-4e6a-a55f-63fe5c76589d",
    meta: {"ID":"e469254b-75f6-4e6a-a55f-63fe5c76589d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2baa7814-309c-4d94-b87a-916c5430072b|e4a38f6c-09b3-41b5-bb71-10cf9b06cc7f",
    path: "/product/e4a38f6c-09b3-41b5-bb71-10cf9b06cc7f",
    meta: {"ID":"e4a38f6c-09b3-41b5-bb71-10cf9b06cc7f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|e4a6ff74-52f2-4d6e-9120-416c5e9b9759",
    path: "/product/e4a6ff74-52f2-4d6e-9120-416c5e9b9759",
    meta: {"ID":"e4a6ff74-52f2-4d6e-9120-416c5e9b9759","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|e4a70f1c-2459-47d0-8351-650170552298",
    path: "/product/e4a70f1c-2459-47d0-8351-650170552298",
    meta: {"ID":"e4a70f1c-2459-47d0-8351-650170552298","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e4c57b84-ddf5-45c1-8644-56f144ef9bf8",
    path: "/product/e4c57b84-ddf5-45c1-8644-56f144ef9bf8",
    meta: {"ID":"e4c57b84-ddf5-45c1-8644-56f144ef9bf8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e4c648d7-85fb-4f1d-bb42-f9042a6a959b",
    path: "/product/e4c648d7-85fb-4f1d-bb42-f9042a6a959b",
    meta: {"ID":"e4c648d7-85fb-4f1d-bb42-f9042a6a959b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e52089b7-5a94-4434-8220-cee43294a78d",
    path: "/product/e52089b7-5a94-4434-8220-cee43294a78d",
    meta: {"ID":"e52089b7-5a94-4434-8220-cee43294a78d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e52fcaeb-4e08-46ff-a885-e09651fb5f27",
    path: "/product/e52fcaeb-4e08-46ff-a885-e09651fb5f27",
    meta: {"ID":"e52fcaeb-4e08-46ff-a885-e09651fb5f27","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e53321ea-2979-4b80-82e9-b29eb05680cf",
    path: "/product/e53321ea-2979-4b80-82e9-b29eb05680cf",
    meta: {"ID":"e53321ea-2979-4b80-82e9-b29eb05680cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e54407a8-82b3-4e16-af31-593068c4a687",
    path: "/product/e54407a8-82b3-4e16-af31-593068c4a687",
    meta: {"ID":"e54407a8-82b3-4e16-af31-593068c4a687","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e544fb13-21cc-4773-b158-23c5528d3b77",
    path: "/product/e544fb13-21cc-4773-b158-23c5528d3b77",
    meta: {"ID":"e544fb13-21cc-4773-b158-23c5528d3b77","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|e5511ee5-7b3c-4041-ba92-5582414f2e6e",
    path: "/product/e5511ee5-7b3c-4041-ba92-5582414f2e6e",
    meta: {"ID":"e5511ee5-7b3c-4041-ba92-5582414f2e6e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e55acdbf-3268-4d89-9973-53830f7d485e",
    path: "/product/e55acdbf-3268-4d89-9973-53830f7d485e",
    meta: {"ID":"e55acdbf-3268-4d89-9973-53830f7d485e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e56542c5-f547-4994-a695-467e35a4bbe0",
    path: "/product/e56542c5-f547-4994-a695-467e35a4bbe0",
    meta: {"ID":"e56542c5-f547-4994-a695-467e35a4bbe0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|e56a2bd1-b1af-4d18-ad35-ef2bcf0e4060",
    path: "/product/e56a2bd1-b1af-4d18-ad35-ef2bcf0e4060",
    meta: {"ID":"e56a2bd1-b1af-4d18-ad35-ef2bcf0e4060","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e56b3d1d-6095-4e14-8634-9ac1acb71ea9",
    path: "/product/e56b3d1d-6095-4e14-8634-9ac1acb71ea9",
    meta: {"ID":"e56b3d1d-6095-4e14-8634-9ac1acb71ea9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e579c513-cb40-4141-8b7d-5628c5b09a45",
    path: "/product/e579c513-cb40-4141-8b7d-5628c5b09a45",
    meta: {"ID":"e579c513-cb40-4141-8b7d-5628c5b09a45","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|35ffc5db-5f3c-44a5-bdfa-0922bfd20da6|e58f29ef-9204-443d-b091-29f25179aec9",
    path: "/product/e58f29ef-9204-443d-b091-29f25179aec9",
    meta: {"ID":"e58f29ef-9204-443d-b091-29f25179aec9","Title":"","GroupID":"35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","GroupTitle":"Поке и вок","GroupLink":"/menu/35ffc5db-5f3c-44a5-bdfa-0922bfd20da6","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|59b73946-d0e9-47cf-82f0-0d5c22e3862b|e59af966-2fdf-47b1-bf5c-d17240493e02",
    path: "/product/e59af966-2fdf-47b1-bf5c-d17240493e02",
    meta: {"ID":"e59af966-2fdf-47b1-bf5c-d17240493e02","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e5ab2cdd-7ef0-49cc-b3e2-e2e91373fc08",
    path: "/product/e5ab2cdd-7ef0-49cc-b3e2-e2e91373fc08",
    meta: {"ID":"e5ab2cdd-7ef0-49cc-b3e2-e2e91373fc08","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e5ad370b-6605-4994-93e8-4d46e178da3d",
    path: "/product/e5ad370b-6605-4994-93e8-4d46e178da3d",
    meta: {"ID":"e5ad370b-6605-4994-93e8-4d46e178da3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a04f5a3-7f1f-4863-9c6a-170e7e97ef1b|e5bd1304-d676-4f9f-8df1-ef83001513b1",
    path: "/product/e5bd1304-d676-4f9f-8df1-ef83001513b1",
    meta: {"ID":"e5bd1304-d676-4f9f-8df1-ef83001513b1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|e5bfdcbe-3ca6-49fe-b165-83670f67098f",
    path: "/product/e5bfdcbe-3ca6-49fe-b165-83670f67098f",
    meta: {"ID":"e5bfdcbe-3ca6-49fe-b165-83670f67098f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e5cfc0b2-5a64-4d48-beb5-5b221e5a96d5",
    path: "/product/e5cfc0b2-5a64-4d48-beb5-5b221e5a96d5",
    meta: {"ID":"e5cfc0b2-5a64-4d48-beb5-5b221e5a96d5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e6002611-a7ee-4327-bbd4-b9f845a3d681",
    path: "/product/e6002611-a7ee-4327-bbd4-b9f845a3d681",
    meta: {"ID":"e6002611-a7ee-4327-bbd4-b9f845a3d681","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e6012da9-7b3e-49aa-a69d-33df0354c01f",
    path: "/product/e6012da9-7b3e-49aa-a69d-33df0354c01f",
    meta: {"ID":"e6012da9-7b3e-49aa-a69d-33df0354c01f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1519fe15-47e1-44ff-8747-6714ca96f7aa|e6015e4d-a371-419a-a82f-7356d71a700a",
    path: "/product/e6015e4d-a371-419a-a82f-7356d71a700a",
    meta: {"ID":"e6015e4d-a371-419a-a82f-7356d71a700a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|e60fc831-2875-427e-aed0-0226b9c8b6ad",
    path: "/product/e60fc831-2875-427e-aed0-0226b9c8b6ad",
    meta: {"ID":"e60fc831-2875-427e-aed0-0226b9c8b6ad","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e2a1e7d9-7ca7-4450-84e5-be47dd4e74a5|e618d00c-0980-46da-9e74-00276156095e",
    path: "/product/e618d00c-0980-46da-9e74-00276156095e",
    meta: {"ID":"e618d00c-0980-46da-9e74-00276156095e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|e64cb6a3-0328-4fab-a83e-bc287f118366",
    path: "/product/e64cb6a3-0328-4fab-a83e-bc287f118366",
    meta: {"ID":"e64cb6a3-0328-4fab-a83e-bc287f118366","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|e66ffbaa-a68d-4b9c-bc2e-a50b238a2062",
    path: "/product/e66ffbaa-a68d-4b9c-bc2e-a50b238a2062",
    meta: {"ID":"e66ffbaa-a68d-4b9c-bc2e-a50b238a2062","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|e675b3dc-5235-4c9f-9adf-e993b8991c30",
    path: "/product/e675b3dc-5235-4c9f-9adf-e993b8991c30",
    meta: {"ID":"e675b3dc-5235-4c9f-9adf-e993b8991c30","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e684fe2c-0335-46c3-a3e2-8710af66109f",
    path: "/product/e684fe2c-0335-46c3-a3e2-8710af66109f",
    meta: {"ID":"e684fe2c-0335-46c3-a3e2-8710af66109f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e6983770-9efb-452a-99fa-ae6236f72571",
    path: "/product/e6983770-9efb-452a-99fa-ae6236f72571",
    meta: {"ID":"e6983770-9efb-452a-99fa-ae6236f72571","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4626112c-772c-43a4-83b9-fc5d6a1a2597|e69f5b39-007c-4a39-8846-3b53c9ea1d3d",
    path: "/product/e69f5b39-007c-4a39-8846-3b53c9ea1d3d",
    meta: {"ID":"e69f5b39-007c-4a39-8846-3b53c9ea1d3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|73cec509-3358-485d-8d8e-5484c8bb8f78|e6b13e8e-9b8d-433e-ae5c-6df5b194a0b7",
    path: "/product/e6b13e8e-9b8d-433e-ae5c-6df5b194a0b7",
    meta: {"ID":"e6b13e8e-9b8d-433e-ae5c-6df5b194a0b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|e6dc7209-384f-4eef-8165-90615252d8f8",
    path: "/product/e6dc7209-384f-4eef-8165-90615252d8f8",
    meta: {"ID":"e6dc7209-384f-4eef-8165-90615252d8f8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e6e708d5-57a6-4509-80d7-6ddf3a3c7075",
    path: "/product/e6e708d5-57a6-4509-80d7-6ddf3a3c7075",
    meta: {"ID":"e6e708d5-57a6-4509-80d7-6ddf3a3c7075","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|e6e7ebd9-abc7-4c2b-a2d9-971dab66180a",
    path: "/product/e6e7ebd9-abc7-4c2b-a2d9-971dab66180a",
    meta: {"ID":"e6e7ebd9-abc7-4c2b-a2d9-971dab66180a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|b6b95603-7a93-4283-b39d-ece699ed1469|e6ea98d3-9cb5-4bbb-b56b-cba4ce06cd12",
    path: "/product/e6ea98d3-9cb5-4bbb-b56b-cba4ce06cd12",
    meta: {"ID":"e6ea98d3-9cb5-4bbb-b56b-cba4ce06cd12","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e71090c9-723c-48b3-8876-d83c3d186894",
    path: "/product/e71090c9-723c-48b3-8876-d83c3d186894",
    meta: {"ID":"e71090c9-723c-48b3-8876-d83c3d186894","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e71119cd-05c8-4690-bee4-191066526fc2",
    path: "/product/e71119cd-05c8-4690-bee4-191066526fc2",
    meta: {"ID":"e71119cd-05c8-4690-bee4-191066526fc2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|e7138525-9753-40a4-b662-ad9fe7aa07f5",
    path: "/product/e7138525-9753-40a4-b662-ad9fe7aa07f5",
    meta: {"ID":"e7138525-9753-40a4-b662-ad9fe7aa07f5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e738ab8e-c4e8-48c0-8132-d2793b346d43",
    path: "/product/e738ab8e-c4e8-48c0-8132-d2793b346d43",
    meta: {"ID":"e738ab8e-c4e8-48c0-8132-d2793b346d43","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e73bd6a5-aa75-4cf1-8d5f-dbf237486232",
    path: "/product/e73bd6a5-aa75-4cf1-8d5f-dbf237486232",
    meta: {"ID":"e73bd6a5-aa75-4cf1-8d5f-dbf237486232","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e74f4973-c312-47de-bce1-0605c0d1add2",
    path: "/product/e74f4973-c312-47de-bce1-0605c0d1add2",
    meta: {"ID":"e74f4973-c312-47de-bce1-0605c0d1add2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e76d14dc-0950-4cff-8041-d36b0fe91e07",
    path: "/product/e76d14dc-0950-4cff-8041-d36b0fe91e07",
    meta: {"ID":"e76d14dc-0950-4cff-8041-d36b0fe91e07","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1a496364-a63b-4683-aa31-12d5196add62|e7781765-0ab0-4db1-9b87-92adab1b988c",
    path: "/product/e7781765-0ab0-4db1-9b87-92adab1b988c",
    meta: {"ID":"e7781765-0ab0-4db1-9b87-92adab1b988c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e792505c-b1cb-4997-95a3-a28689e9e3ff",
    path: "/product/e792505c-b1cb-4997-95a3-a28689e9e3ff",
    meta: {"ID":"e792505c-b1cb-4997-95a3-a28689e9e3ff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e79581d1-be3c-4745-882c-bc106bc9210d",
    path: "/product/e79581d1-be3c-4745-882c-bc106bc9210d",
    meta: {"ID":"e79581d1-be3c-4745-882c-bc106bc9210d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c20c30ec-cf97-4ef6-bc06-9e04305c93ad|e7c35c05-0676-44ae-91fd-71e05d5ee157",
    path: "/product/e7c35c05-0676-44ae-91fd-71e05d5ee157",
    meta: {"ID":"e7c35c05-0676-44ae-91fd-71e05d5ee157","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e7e03ede-eccf-4e71-8970-969c98263a59",
    path: "/product/e7e03ede-eccf-4e71-8970-969c98263a59",
    meta: {"ID":"e7e03ede-eccf-4e71-8970-969c98263a59","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e7ec984b-e133-4d68-9c5c-c06583fe7347",
    path: "/product/e7ec984b-e133-4d68-9c5c-c06583fe7347",
    meta: {"ID":"e7ec984b-e133-4d68-9c5c-c06583fe7347","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|e7f08d92-64a5-479e-b6bb-b3111985e51d",
    path: "/product/e7f08d92-64a5-479e-b6bb-b3111985e51d",
    meta: {"ID":"e7f08d92-64a5-479e-b6bb-b3111985e51d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e85cbf3d-8183-47f7-b1ca-151dcd11ac3b",
    path: "/product/e85cbf3d-8183-47f7-b1ca-151dcd11ac3b",
    meta: {"ID":"e85cbf3d-8183-47f7-b1ca-151dcd11ac3b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e863cbf9-bd8e-475b-8097-0059d1624b2b",
    path: "/product/e863cbf9-bd8e-475b-8097-0059d1624b2b",
    meta: {"ID":"e863cbf9-bd8e-475b-8097-0059d1624b2b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|e8807713-97c9-41bc-a852-eccf9204f125",
    path: "/product/e8807713-97c9-41bc-a852-eccf9204f125",
    meta: {"ID":"e8807713-97c9-41bc-a852-eccf9204f125","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e88bd2cb-dcb6-48e5-b391-e2ae442c690e",
    path: "/product/e88bd2cb-dcb6-48e5-b391-e2ae442c690e",
    meta: {"ID":"e88bd2cb-dcb6-48e5-b391-e2ae442c690e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|e891e6e4-6654-4d2f-b1e8-8712c370dba1",
    path: "/product/e891e6e4-6654-4d2f-b1e8-8712c370dba1",
    meta: {"ID":"e891e6e4-6654-4d2f-b1e8-8712c370dba1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e8ae7e21-2c53-48f4-8cce-28ffd3be0e06",
    path: "/product/e8ae7e21-2c53-48f4-8cce-28ffd3be0e06",
    meta: {"ID":"e8ae7e21-2c53-48f4-8cce-28ffd3be0e06","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e8e4a851-50bc-4b94-8f4c-3e71276e4b37",
    path: "/product/e8e4a851-50bc-4b94-8f4c-3e71276e4b37",
    meta: {"ID":"e8e4a851-50bc-4b94-8f4c-3e71276e4b37","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|e93e76f2-947c-4e7f-8321-18a0b3ba725b",
    path: "/product/e93e76f2-947c-4e7f-8321-18a0b3ba725b",
    meta: {"ID":"e93e76f2-947c-4e7f-8321-18a0b3ba725b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|e943ac04-35bb-4b9b-95b0-3a929d7c27c7",
    path: "/product/e943ac04-35bb-4b9b-95b0-3a929d7c27c7",
    meta: {"ID":"e943ac04-35bb-4b9b-95b0-3a929d7c27c7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|e9705dd7-58a3-4ecb-9e09-028b81b686b0",
    path: "/product/e9705dd7-58a3-4ecb-9e09-028b81b686b0",
    meta: {"ID":"e9705dd7-58a3-4ecb-9e09-028b81b686b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|252a1939-043c-4d69-9c75-1639d8e9e258|e9b32884-0f39-46d7-9f2a-fab21862b112",
    path: "/product/e9b32884-0f39-46d7-9f2a-fab21862b112",
    meta: {"ID":"e9b32884-0f39-46d7-9f2a-fab21862b112","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|e9b8447f-77e8-410f-916b-dd600a2095c3",
    path: "/product/e9b8447f-77e8-410f-916b-dd600a2095c3",
    meta: {"ID":"e9b8447f-77e8-410f-916b-dd600a2095c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|e9d71da6-04ad-4f78-b1b1-2afb6c37896e",
    path: "/product/e9d71da6-04ad-4f78-b1b1-2afb6c37896e",
    meta: {"ID":"e9d71da6-04ad-4f78-b1b1-2afb6c37896e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|e9d95018-6979-4a61-b40d-ece10f6202ff",
    path: "/product/e9d95018-6979-4a61-b40d-ece10f6202ff",
    meta: {"ID":"e9d95018-6979-4a61-b40d-ece10f6202ff","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|e9f5751c-4e23-4ac6-b9dd-b8a692c9ab92",
    path: "/product/e9f5751c-4e23-4ac6-b9dd-b8a692c9ab92",
    meta: {"ID":"e9f5751c-4e23-4ac6-b9dd-b8a692c9ab92","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ea217cd3-4fb4-49a4-9692-d8241b914f1a",
    path: "/product/ea217cd3-4fb4-49a4-9692-d8241b914f1a",
    meta: {"ID":"ea217cd3-4fb4-49a4-9692-d8241b914f1a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ea440bfe-3ff9-42c4-8dbc-6a5550d8042d",
    path: "/product/ea440bfe-3ff9-42c4-8dbc-6a5550d8042d",
    meta: {"ID":"ea440bfe-3ff9-42c4-8dbc-6a5550d8042d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ea4446f5-fc6b-4c04-9e7c-81f19eb9a98a",
    path: "/product/ea4446f5-fc6b-4c04-9e7c-81f19eb9a98a",
    meta: {"ID":"ea4446f5-fc6b-4c04-9e7c-81f19eb9a98a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ea487272-4814-4f10-9c7c-587cc7df83be",
    path: "/product/ea487272-4814-4f10-9c7c-587cc7df83be",
    meta: {"ID":"ea487272-4814-4f10-9c7c-587cc7df83be","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|ea48bbc9-e434-4bf2-8402-59c796c83f8b",
    path: "/product/ea48bbc9-e434-4bf2-8402-59c796c83f8b",
    meta: {"ID":"ea48bbc9-e434-4bf2-8402-59c796c83f8b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|ea53dee8-c26c-4338-a2d8-abf434af56c3",
    path: "/product/ea53dee8-c26c-4338-a2d8-abf434af56c3",
    meta: {"ID":"ea53dee8-c26c-4338-a2d8-abf434af56c3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|db514044-cc74-4cb4-88c1-e0d3608fecb6|ea5e5248-7ab1-4883-afb4-61b7b6e946a9",
    path: "/product/ea5e5248-7ab1-4883-afb4-61b7b6e946a9",
    meta: {"ID":"ea5e5248-7ab1-4883-afb4-61b7b6e946a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|ea60ea10-06dd-4a3c-b010-e1f6ee496a71",
    path: "/product/ea60ea10-06dd-4a3c-b010-e1f6ee496a71",
    meta: {"ID":"ea60ea10-06dd-4a3c-b010-e1f6ee496a71","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ea72d6d6-778a-4ce5-ba19-9dd5abb2de32",
    path: "/product/ea72d6d6-778a-4ce5-ba19-9dd5abb2de32",
    meta: {"ID":"ea72d6d6-778a-4ce5-ba19-9dd5abb2de32","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ea8d90a6-737c-45e7-906b-6dd7fd38ad72",
    path: "/product/ea8d90a6-737c-45e7-906b-6dd7fd38ad72",
    meta: {"ID":"ea8d90a6-737c-45e7-906b-6dd7fd38ad72","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ea971231-e9ae-4513-9742-b244f5152a32",
    path: "/product/ea971231-e9ae-4513-9742-b244f5152a32",
    meta: {"ID":"ea971231-e9ae-4513-9742-b244f5152a32","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|ea9e9400-2cd2-4534-9ccb-6469757fee4c",
    path: "/product/ea9e9400-2cd2-4534-9ccb-6469757fee4c",
    meta: {"ID":"ea9e9400-2cd2-4534-9ccb-6469757fee4c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|eaaec3c6-e977-4072-a75b-ba710b58dfdb",
    path: "/product/eaaec3c6-e977-4072-a75b-ba710b58dfdb",
    meta: {"ID":"eaaec3c6-e977-4072-a75b-ba710b58dfdb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|eae4e861-7a7c-48ea-afb0-b4db7fefbf28",
    path: "/product/eae4e861-7a7c-48ea-afb0-b4db7fefbf28",
    meta: {"ID":"eae4e861-7a7c-48ea-afb0-b4db7fefbf28","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|eae9b34b-4fdc-41a1-a07b-e8ccdad1b92e",
    path: "/product/eae9b34b-4fdc-41a1-a07b-e8ccdad1b92e",
    meta: {"ID":"eae9b34b-4fdc-41a1-a07b-e8ccdad1b92e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|eb003faf-302d-41b5-b5e2-fae893110734",
    path: "/product/eb003faf-302d-41b5-b5e2-fae893110734",
    meta: {"ID":"eb003faf-302d-41b5-b5e2-fae893110734","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|eb2e2408-2d22-4a44-ab2a-7e81cedf2d05",
    path: "/product/eb2e2408-2d22-4a44-ab2a-7e81cedf2d05",
    meta: {"ID":"eb2e2408-2d22-4a44-ab2a-7e81cedf2d05","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4b50fd5a-6591-495c-a3f9-500246d426ba|eb494455-d773-4013-8b8c-419a4d2a5b0c",
    path: "/product/eb494455-d773-4013-8b8c-419a4d2a5b0c",
    meta: {"ID":"eb494455-d773-4013-8b8c-419a4d2a5b0c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|eb691b2b-d5ce-43cb-bc5c-58a84cceab09",
    path: "/product/eb691b2b-d5ce-43cb-bc5c-58a84cceab09",
    meta: {"ID":"eb691b2b-d5ce-43cb-bc5c-58a84cceab09","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3146f25e-799d-4845-9af2-b7d61bb720fd|eb8571f8-4309-4e81-be19-812299698fb6",
    path: "/product/eb8571f8-4309-4e81-be19-812299698fb6",
    meta: {"ID":"eb8571f8-4309-4e81-be19-812299698fb6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|eb86f75d-c544-4a55-acb5-34474ad7caab",
    path: "/product/eb86f75d-c544-4a55-acb5-34474ad7caab",
    meta: {"ID":"eb86f75d-c544-4a55-acb5-34474ad7caab","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|eb889dd9-96ec-4bf2-9b6b-0a37fcc63251",
    path: "/product/eb889dd9-96ec-4bf2-9b6b-0a37fcc63251",
    meta: {"ID":"eb889dd9-96ec-4bf2-9b6b-0a37fcc63251","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ebaf6ba3-0b94-468c-9f01-d3ff3be15c9c",
    path: "/product/ebaf6ba3-0b94-468c-9f01-d3ff3be15c9c",
    meta: {"ID":"ebaf6ba3-0b94-468c-9f01-d3ff3be15c9c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ebb4cb0c-a4f3-4b00-9bf8-c8271abe2859",
    path: "/product/ebb4cb0c-a4f3-4b00-9bf8-c8271abe2859",
    meta: {"ID":"ebb4cb0c-a4f3-4b00-9bf8-c8271abe2859","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|ebc1021a-3cb6-4944-87df-280f11e82899",
    path: "/product/ebc1021a-3cb6-4944-87df-280f11e82899",
    meta: {"ID":"ebc1021a-3cb6-4944-87df-280f11e82899","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9c537249-f7f5-449d-91d4-c696645e49a8|ebcf15f5-f8b7-4dda-9e42-7f94af038d64",
    path: "/product/ebcf15f5-f8b7-4dda-9e42-7f94af038d64",
    meta: {"ID":"ebcf15f5-f8b7-4dda-9e42-7f94af038d64","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|ebf88340-640c-4f63-a398-7b968a7ece8b",
    path: "/product/ebf88340-640c-4f63-a398-7b968a7ece8b",
    meta: {"ID":"ebf88340-640c-4f63-a398-7b968a7ece8b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ebfdc000-3211-475d-b8d9-d18bb068bd55",
    path: "/product/ebfdc000-3211-475d-b8d9-d18bb068bd55",
    meta: {"ID":"ebfdc000-3211-475d-b8d9-d18bb068bd55","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|ec0be67f-7820-492c-86d8-0427c21d49d8",
    path: "/product/ec0be67f-7820-492c-86d8-0427c21d49d8",
    meta: {"ID":"ec0be67f-7820-492c-86d8-0427c21d49d8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|ec1f0352-b06f-48a0-9f53-b12bc8a7f714",
    path: "/product/ec1f0352-b06f-48a0-9f53-b12bc8a7f714",
    meta: {"ID":"ec1f0352-b06f-48a0-9f53-b12bc8a7f714","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ec2f6558-33b2-4e0c-a00c-952b237ca8b2",
    path: "/product/ec2f6558-33b2-4e0c-a00c-952b237ca8b2",
    meta: {"ID":"ec2f6558-33b2-4e0c-a00c-952b237ca8b2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ec3133cf-6389-4d58-b1d9-0ba199839282",
    path: "/product/ec3133cf-6389-4d58-b1d9-0ba199839282",
    meta: {"ID":"ec3133cf-6389-4d58-b1d9-0ba199839282","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ec69d411-41ec-46fa-bb52-8e40713050f1",
    path: "/product/ec69d411-41ec-46fa-bb52-8e40713050f1",
    meta: {"ID":"ec69d411-41ec-46fa-bb52-8e40713050f1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|ec6c3c4a-ec46-4e2b-97cd-c23a5b95fe15",
    path: "/product/ec6c3c4a-ec46-4e2b-97cd-c23a5b95fe15",
    meta: {"ID":"ec6c3c4a-ec46-4e2b-97cd-c23a5b95fe15","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|eca482fe-88c5-472c-bd71-c07d5065597a",
    path: "/product/eca482fe-88c5-472c-bd71-c07d5065597a",
    meta: {"ID":"eca482fe-88c5-472c-bd71-c07d5065597a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|60e80d18-ecc4-43b8-9536-34928835c255|eccae11c-c2ba-47a7-88f2-1e6023a7da93",
    path: "/product/eccae11c-c2ba-47a7-88f2-1e6023a7da93",
    meta: {"ID":"eccae11c-c2ba-47a7-88f2-1e6023a7da93","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1519fe15-47e1-44ff-8747-6714ca96f7aa|ecd22a7b-4728-4c4b-9f69-d8f241c5bec1",
    path: "/product/ecd22a7b-4728-4c4b-9f69-d8f241c5bec1",
    meta: {"ID":"ecd22a7b-4728-4c4b-9f69-d8f241c5bec1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|ecfb66e2-5b59-4719-963e-7f4370b0d5e4",
    path: "/product/ecfb66e2-5b59-4719-963e-7f4370b0d5e4",
    meta: {"ID":"ecfb66e2-5b59-4719-963e-7f4370b0d5e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ed2641bf-00ac-49b5-8d76-721ac0becffb",
    path: "/product/ed2641bf-00ac-49b5-8d76-721ac0becffb",
    meta: {"ID":"ed2641bf-00ac-49b5-8d76-721ac0becffb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|ed28f1e4-18aa-4e2e-94c4-44669b6dd54e",
    path: "/product/ed28f1e4-18aa-4e2e-94c4-44669b6dd54e",
    meta: {"ID":"ed28f1e4-18aa-4e2e-94c4-44669b6dd54e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ed3143a9-2454-456f-b3f4-342e0a510e9e",
    path: "/product/ed3143a9-2454-456f-b3f4-342e0a510e9e",
    meta: {"ID":"ed3143a9-2454-456f-b3f4-342e0a510e9e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ed3f6a24-b02b-4518-abe6-3ed35d7e9040",
    path: "/product/ed3f6a24-b02b-4518-abe6-3ed35d7e9040",
    meta: {"ID":"ed3f6a24-b02b-4518-abe6-3ed35d7e9040","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|ed5bfc1e-6392-4ac5-abb9-1b029b60f377",
    path: "/product/ed5bfc1e-6392-4ac5-abb9-1b029b60f377",
    meta: {"ID":"ed5bfc1e-6392-4ac5-abb9-1b029b60f377","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ed6f9b7c-3723-4dd0-a44c-75b8e7b7619c",
    path: "/product/ed6f9b7c-3723-4dd0-a44c-75b8e7b7619c",
    meta: {"ID":"ed6f9b7c-3723-4dd0-a44c-75b8e7b7619c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|ed75959d-8150-49d6-a6c6-b9381aff3a11",
    path: "/product/ed75959d-8150-49d6-a6c6-b9381aff3a11",
    meta: {"ID":"ed75959d-8150-49d6-a6c6-b9381aff3a11","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ed8382d2-6ba3-4f6b-8042-c9e5e7d39785",
    path: "/product/ed8382d2-6ba3-4f6b-8042-c9e5e7d39785",
    meta: {"ID":"ed8382d2-6ba3-4f6b-8042-c9e5e7d39785","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ede5834a-8998-4140-a106-c6931d5cbbab",
    path: "/product/ede5834a-8998-4140-a106-c6931d5cbbab",
    meta: {"ID":"ede5834a-8998-4140-a106-c6931d5cbbab","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|ede9b5ed-72ed-4111-8311-706e7875413b",
    path: "/product/ede9b5ed-72ed-4111-8311-706e7875413b",
    meta: {"ID":"ede9b5ed-72ed-4111-8311-706e7875413b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|edf96eea-13b1-4c53-bf87-eb171fdabfaf",
    path: "/product/edf96eea-13b1-4c53-bf87-eb171fdabfaf",
    meta: {"ID":"edf96eea-13b1-4c53-bf87-eb171fdabfaf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ee06e4f7-e968-4a55-96a0-e1f5688d0a6e",
    path: "/product/ee06e4f7-e968-4a55-96a0-e1f5688d0a6e",
    meta: {"ID":"ee06e4f7-e968-4a55-96a0-e1f5688d0a6e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3b6c5f2f-9186-4aa2-ba70-4eed44752a47|ee1c7ea4-1e25-48da-af7f-de2bea1ca4c5",
    path: "/product/ee1c7ea4-1e25-48da-af7f-de2bea1ca4c5",
    meta: {"ID":"ee1c7ea4-1e25-48da-af7f-de2bea1ca4c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|ee2c9c83-3609-433d-a204-1aac4cb422f0",
    path: "/product/ee2c9c83-3609-433d-a204-1aac4cb422f0",
    meta: {"ID":"ee2c9c83-3609-433d-a204-1aac4cb422f0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ee376663-7aad-421e-a9f7-a6860a225dcb",
    path: "/product/ee376663-7aad-421e-a9f7-a6860a225dcb",
    meta: {"ID":"ee376663-7aad-421e-a9f7-a6860a225dcb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|ee5f7ded-38f3-407c-9e68-22bfd8d5e01d",
    path: "/product/ee5f7ded-38f3-407c-9e68-22bfd8d5e01d",
    meta: {"ID":"ee5f7ded-38f3-407c-9e68-22bfd8d5e01d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ee7039df-cd7b-45af-a1e7-e517b0854e43",
    path: "/product/ee7039df-cd7b-45af-a1e7-e517b0854e43",
    meta: {"ID":"ee7039df-cd7b-45af-a1e7-e517b0854e43","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|ee866da7-3090-44da-9087-1eae29d9656c",
    path: "/product/ee866da7-3090-44da-9087-1eae29d9656c",
    meta: {"ID":"ee866da7-3090-44da-9087-1eae29d9656c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|ee9036b2-54f0-47d7-b3bd-9ba716cf19d4",
    path: "/product/ee9036b2-54f0-47d7-b3bd-9ba716cf19d4",
    meta: {"ID":"ee9036b2-54f0-47d7-b3bd-9ba716cf19d4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|ee94ba1d-bed1-4ed1-a554-01fd3ac55030",
    path: "/product/ee94ba1d-bed1-4ed1-a554-01fd3ac55030",
    meta: {"ID":"ee94ba1d-bed1-4ed1-a554-01fd3ac55030","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|eeba716b-b15e-465f-9b76-1de3451979d6",
    path: "/product/eeba716b-b15e-465f-9b76-1de3451979d6",
    meta: {"ID":"eeba716b-b15e-465f-9b76-1de3451979d6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|eebe0b6c-a0a9-4cbc-aed2-a21981360355",
    path: "/product/eebe0b6c-a0a9-4cbc-aed2-a21981360355",
    meta: {"ID":"eebe0b6c-a0a9-4cbc-aed2-a21981360355","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|eec67bd6-6418-44c0-a347-b0f68059197b",
    path: "/product/eec67bd6-6418-44c0-a347-b0f68059197b",
    meta: {"ID":"eec67bd6-6418-44c0-a347-b0f68059197b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|59b73946-d0e9-47cf-82f0-0d5c22e3862b|eed15c0e-5482-4517-9931-dcda793a347b",
    path: "/product/eed15c0e-5482-4517-9931-dcda793a347b",
    meta: {"ID":"eed15c0e-5482-4517-9931-dcda793a347b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|eee5c30d-fa4f-4ca6-90dc-8b0291752f48",
    path: "/product/eee5c30d-fa4f-4ca6-90dc-8b0291752f48",
    meta: {"ID":"eee5c30d-fa4f-4ca6-90dc-8b0291752f48","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|eeefbc1b-f510-47ae-ac33-c8c2d5ee79c5",
    path: "/product/eeefbc1b-f510-47ae-ac33-c8c2d5ee79c5",
    meta: {"ID":"eeefbc1b-f510-47ae-ac33-c8c2d5ee79c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|ef090b9c-b78a-4a81-acee-70e2a7e8f33e",
    path: "/product/ef090b9c-b78a-4a81-acee-70e2a7e8f33e",
    meta: {"ID":"ef090b9c-b78a-4a81-acee-70e2a7e8f33e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ef0af118-46d4-4bb0-b2d5-ae47aafa1003",
    path: "/product/ef0af118-46d4-4bb0-b2d5-ae47aafa1003",
    meta: {"ID":"ef0af118-46d4-4bb0-b2d5-ae47aafa1003","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ef22c05f-7a3f-48de-b4bb-da2da9f16bce",
    path: "/product/ef22c05f-7a3f-48de-b4bb-da2da9f16bce",
    meta: {"ID":"ef22c05f-7a3f-48de-b4bb-da2da9f16bce","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ef488486-ccb9-4c46-930f-d6c696da6575",
    path: "/product/ef488486-ccb9-4c46-930f-d6c696da6575",
    meta: {"ID":"ef488486-ccb9-4c46-930f-d6c696da6575","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|ef68640f-3998-4cb2-83b2-7aa26c2561a9",
    path: "/product/ef68640f-3998-4cb2-83b2-7aa26c2561a9",
    meta: {"ID":"ef68640f-3998-4cb2-83b2-7aa26c2561a9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|ef6bc1f8-cd6a-4560-a2cd-24d738d64d28",
    path: "/product/ef6bc1f8-cd6a-4560-a2cd-24d738d64d28",
    meta: {"ID":"ef6bc1f8-cd6a-4560-a2cd-24d738d64d28","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ef8da901-7d9d-45ec-8690-eaff72ec85ec",
    path: "/product/ef8da901-7d9d-45ec-8690-eaff72ec85ec",
    meta: {"ID":"ef8da901-7d9d-45ec-8690-eaff72ec85ec","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|efa79cd3-7c75-44fe-a357-041c20a7fe41",
    path: "/product/efa79cd3-7c75-44fe-a357-041c20a7fe41",
    meta: {"ID":"efa79cd3-7c75-44fe-a357-041c20a7fe41","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|efb5ec6d-7e5d-4272-b543-90897c725bb2",
    path: "/product/efb5ec6d-7e5d-4272-b543-90897c725bb2",
    meta: {"ID":"efb5ec6d-7e5d-4272-b543-90897c725bb2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|efb75d4d-9673-491a-9f59-33f046118f97",
    path: "/product/efb75d4d-9673-491a-9f59-33f046118f97",
    meta: {"ID":"efb75d4d-9673-491a-9f59-33f046118f97","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|efd21878-7a69-4aac-96be-34947d7690eb",
    path: "/product/efd21878-7a69-4aac-96be-34947d7690eb",
    meta: {"ID":"efd21878-7a69-4aac-96be-34947d7690eb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|efec1460-1bf8-4fd5-9ace-0ef6d1d361be",
    path: "/product/efec1460-1bf8-4fd5-9ace-0ef6d1d361be",
    meta: {"ID":"efec1460-1bf8-4fd5-9ace-0ef6d1d361be","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|f02929b7-35c9-431c-9da8-0b0e0e4dc714",
    path: "/product/f02929b7-35c9-431c-9da8-0b0e0e4dc714",
    meta: {"ID":"f02929b7-35c9-431c-9da8-0b0e0e4dc714","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f0520d33-cebb-43d4-83a3-888dc06ad5f3",
    path: "/product/f0520d33-cebb-43d4-83a3-888dc06ad5f3",
    meta: {"ID":"f0520d33-cebb-43d4-83a3-888dc06ad5f3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f0580b4c-f508-4776-8c42-4a111efa23dd",
    path: "/product/f0580b4c-f508-4776-8c42-4a111efa23dd",
    meta: {"ID":"f0580b4c-f508-4776-8c42-4a111efa23dd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|f06ee5db-f28e-4508-8aa2-ad0ecd6ad17c",
    path: "/product/f06ee5db-f28e-4508-8aa2-ad0ecd6ad17c",
    meta: {"ID":"f06ee5db-f28e-4508-8aa2-ad0ecd6ad17c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|f09454fb-683b-40b4-a66b-7bc202e31fef",
    path: "/product/f09454fb-683b-40b4-a66b-7bc202e31fef",
    meta: {"ID":"f09454fb-683b-40b4-a66b-7bc202e31fef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f09fc19f-54da-4e04-bdc7-380f9afb0785",
    path: "/product/f09fc19f-54da-4e04-bdc7-380f9afb0785",
    meta: {"ID":"f09fc19f-54da-4e04-bdc7-380f9afb0785","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f0a13173-adc7-4b55-8f2e-8c2548c93e39",
    path: "/product/f0a13173-adc7-4b55-8f2e-8c2548c93e39",
    meta: {"ID":"f0a13173-adc7-4b55-8f2e-8c2548c93e39","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|f0b9227f-4c1a-46df-9dc3-ffe980fef063",
    path: "/product/f0b9227f-4c1a-46df-9dc3-ffe980fef063",
    meta: {"ID":"f0b9227f-4c1a-46df-9dc3-ffe980fef063","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f0cc6aa9-db62-40a3-b2bd-9b915fe5fb3e",
    path: "/product/f0cc6aa9-db62-40a3-b2bd-9b915fe5fb3e",
    meta: {"ID":"f0cc6aa9-db62-40a3-b2bd-9b915fe5fb3e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f0d1085e-eb59-45c5-917c-f7a79a4d1d3d",
    path: "/product/f0d1085e-eb59-45c5-917c-f7a79a4d1d3d",
    meta: {"ID":"f0d1085e-eb59-45c5-917c-f7a79a4d1d3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|f0d38d4b-4e5e-41c9-8c2e-08767a402e04",
    path: "/product/f0d38d4b-4e5e-41c9-8c2e-08767a402e04",
    meta: {"ID":"f0d38d4b-4e5e-41c9-8c2e-08767a402e04","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|f0e72f84-3749-48e0-988c-dab34ae13ef2",
    path: "/product/f0e72f84-3749-48e0-988c-dab34ae13ef2",
    meta: {"ID":"f0e72f84-3749-48e0-988c-dab34ae13ef2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f0f47bec-3583-4fba-903d-bb79cc7a84e6",
    path: "/product/f0f47bec-3583-4fba-903d-bb79cc7a84e6",
    meta: {"ID":"f0f47bec-3583-4fba-903d-bb79cc7a84e6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|f11f5e5b-753e-4d1b-86ef-91bfa3b79205",
    path: "/product/f11f5e5b-753e-4d1b-86ef-91bfa3b79205",
    meta: {"ID":"f11f5e5b-753e-4d1b-86ef-91bfa3b79205","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f12b5986-6823-400b-be28-cfe43b8a7fb7",
    path: "/product/f12b5986-6823-400b-be28-cfe43b8a7fb7",
    meta: {"ID":"f12b5986-6823-400b-be28-cfe43b8a7fb7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|f12fe175-3536-4cac-bac0-72bde960cd84",
    path: "/product/f12fe175-3536-4cac-bac0-72bde960cd84",
    meta: {"ID":"f12fe175-3536-4cac-bac0-72bde960cd84","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5cb1bd94-d83d-4b23-9469-0ddc318e5ee6|f139e6ff-5db4-4e01-9184-0d1d7d769179",
    path: "/product/f139e6ff-5db4-4e01-9184-0d1d7d769179",
    meta: {"ID":"f139e6ff-5db4-4e01-9184-0d1d7d769179","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|f1460a41-ea18-40ac-a934-b8664ca5e384",
    path: "/product/f1460a41-ea18-40ac-a934-b8664ca5e384",
    meta: {"ID":"f1460a41-ea18-40ac-a934-b8664ca5e384","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f14b2a06-7f07-4fec-8613-297d788b6efc",
    path: "/product/f14b2a06-7f07-4fec-8613-297d788b6efc",
    meta: {"ID":"f14b2a06-7f07-4fec-8613-297d788b6efc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f16105e7-b605-4587-91d6-2f70b111ea7b",
    path: "/product/f16105e7-b605-4587-91d6-2f70b111ea7b",
    meta: {"ID":"f16105e7-b605-4587-91d6-2f70b111ea7b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f1845996-10ca-4e69-9f02-5af4221b16e7",
    path: "/product/f1845996-10ca-4e69-9f02-5af4221b16e7",
    meta: {"ID":"f1845996-10ca-4e69-9f02-5af4221b16e7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f18f607f-5e4a-4ec5-a7a2-3e458f928e97",
    path: "/product/f18f607f-5e4a-4ec5-a7a2-3e458f928e97",
    meta: {"ID":"f18f607f-5e4a-4ec5-a7a2-3e458f928e97","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1a496364-a63b-4683-aa31-12d5196add62|f1d6249e-9d8a-4844-bbfc-c35c16ad7e46",
    path: "/product/f1d6249e-9d8a-4844-bbfc-c35c16ad7e46",
    meta: {"ID":"f1d6249e-9d8a-4844-bbfc-c35c16ad7e46","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|1cca1c41-f8fc-4cd3-9788-4fe61c89225d|f1e1d03d-b347-43f8-b695-bc25818aebf8",
    path: "/product/f1e1d03d-b347-43f8-b695-bc25818aebf8",
    meta: {"ID":"f1e1d03d-b347-43f8-b695-bc25818aebf8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f1e9dc6a-6382-4ea8-8379-86da67e38f61",
    path: "/product/f1e9dc6a-6382-4ea8-8379-86da67e38f61",
    meta: {"ID":"f1e9dc6a-6382-4ea8-8379-86da67e38f61","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f201ff25-c15f-44a0-879c-8f74c21e42cf",
    path: "/product/f201ff25-c15f-44a0-879c-8f74c21e42cf",
    meta: {"ID":"f201ff25-c15f-44a0-879c-8f74c21e42cf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f214fb1c-9da8-4901-899b-e595554de277",
    path: "/product/f214fb1c-9da8-4901-899b-e595554de277",
    meta: {"ID":"f214fb1c-9da8-4901-899b-e595554de277","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|f21aaca6-8a00-42ea-8cd0-99146a04b401",
    path: "/product/f21aaca6-8a00-42ea-8cd0-99146a04b401",
    meta: {"ID":"f21aaca6-8a00-42ea-8cd0-99146a04b401","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|f2767075-43a6-4668-8eb5-769f487c8c6e",
    path: "/product/f2767075-43a6-4668-8eb5-769f487c8c6e",
    meta: {"ID":"f2767075-43a6-4668-8eb5-769f487c8c6e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|82890741-f41f-4593-bd5b-e63c6af5f1ed|f27cb909-d92a-4263-adef-7748e16d877f",
    path: "/product/f27cb909-d92a-4263-adef-7748e16d877f",
    meta: {"ID":"f27cb909-d92a-4263-adef-7748e16d877f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f2a9792e-cb5c-412b-90fa-a660be5bc3ae",
    path: "/product/f2a9792e-cb5c-412b-90fa-a660be5bc3ae",
    meta: {"ID":"f2a9792e-cb5c-412b-90fa-a660be5bc3ae","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|f2d110f4-cfb0-4f4a-b65f-b9f5ad791837",
    path: "/product/f2d110f4-cfb0-4f4a-b65f-b9f5ad791837",
    meta: {"ID":"f2d110f4-cfb0-4f4a-b65f-b9f5ad791837","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4089a20c-7c4e-410d-9a00-8bbac0d71cc8|f2d12558-d232-4ec5-a5ef-f42c03e6093c",
    path: "/product/f2d12558-d232-4ec5-a5ef-f42c03e6093c",
    meta: {"ID":"f2d12558-d232-4ec5-a5ef-f42c03e6093c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2bfc1f98-58bf-4d86-b145-3d50c983a4d3|f2dd1a7c-1572-4fc0-b28d-f478e179556e",
    path: "/product/f2dd1a7c-1572-4fc0-b28d-f478e179556e",
    meta: {"ID":"f2dd1a7c-1572-4fc0-b28d-f478e179556e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f3048df8-da2c-4b5f-aa68-53ca18b89b4f",
    path: "/product/f3048df8-da2c-4b5f-aa68-53ca18b89b4f",
    meta: {"ID":"f3048df8-da2c-4b5f-aa68-53ca18b89b4f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f3088111-06cd-4468-b713-5ce17d237d49",
    path: "/product/f3088111-06cd-4468-b713-5ce17d237d49",
    meta: {"ID":"f3088111-06cd-4468-b713-5ce17d237d49","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f3133457-5045-4866-96c6-9fc2a8fa41e6",
    path: "/product/f3133457-5045-4866-96c6-9fc2a8fa41e6",
    meta: {"ID":"f3133457-5045-4866-96c6-9fc2a8fa41e6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|f33422c1-b6aa-44b6-b187-46797c243b3c",
    path: "/product/f33422c1-b6aa-44b6-b187-46797c243b3c",
    meta: {"ID":"f33422c1-b6aa-44b6-b187-46797c243b3c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c20c30ec-cf97-4ef6-bc06-9e04305c93ad|f3407ae1-b8e9-49be-809e-0ebe1a150466",
    path: "/product/f3407ae1-b8e9-49be-809e-0ebe1a150466",
    meta: {"ID":"f3407ae1-b8e9-49be-809e-0ebe1a150466","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f34c7dcb-cc24-4e56-8f09-c7569e06ce7a",
    path: "/product/f34c7dcb-cc24-4e56-8f09-c7569e06ce7a",
    meta: {"ID":"f34c7dcb-cc24-4e56-8f09-c7569e06ce7a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f35adcd8-f972-47aa-842e-07fe0f96ae34",
    path: "/product/f35adcd8-f972-47aa-842e-07fe0f96ae34",
    meta: {"ID":"f35adcd8-f972-47aa-842e-07fe0f96ae34","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e62dff22-f0ff-4360-af25-9ebe5e9af09b|f367a6ce-cf8f-4677-a6e0-47a550219754",
    path: "/product/f367a6ce-cf8f-4677-a6e0-47a550219754",
    meta: {"ID":"f367a6ce-cf8f-4677-a6e0-47a550219754","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f368f928-7460-4dc5-b472-87ec2e8be95b",
    path: "/product/f368f928-7460-4dc5-b472-87ec2e8be95b",
    meta: {"ID":"f368f928-7460-4dc5-b472-87ec2e8be95b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f37e9a8e-5abe-4343-b10f-c9e9ce14cb53",
    path: "/product/f37e9a8e-5abe-4343-b10f-c9e9ce14cb53",
    meta: {"ID":"f37e9a8e-5abe-4343-b10f-c9e9ce14cb53","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f38c76fc-d47e-4748-8f45-6abd403d57f2",
    path: "/product/f38c76fc-d47e-4748-8f45-6abd403d57f2",
    meta: {"ID":"f38c76fc-d47e-4748-8f45-6abd403d57f2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f3a05f61-83e3-47d4-b5f7-62f3f82724d8",
    path: "/product/f3a05f61-83e3-47d4-b5f7-62f3f82724d8",
    meta: {"ID":"f3a05f61-83e3-47d4-b5f7-62f3f82724d8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f3a0f7b2-1d6e-4900-8c8d-7e8d8142c78c",
    path: "/product/f3a0f7b2-1d6e-4900-8c8d-7e8d8142c78c",
    meta: {"ID":"f3a0f7b2-1d6e-4900-8c8d-7e8d8142c78c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f3ca899f-ef42-4e2f-9b79-a9503694a724",
    path: "/product/f3ca899f-ef42-4e2f-9b79-a9503694a724",
    meta: {"ID":"f3ca899f-ef42-4e2f-9b79-a9503694a724","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7801b5bb-054e-4ce0-942d-d7831c24ac40|f3cb64ec-cd13-4018-99a4-59276679fae5",
    path: "/product/f3cb64ec-cd13-4018-99a4-59276679fae5",
    meta: {"ID":"f3cb64ec-cd13-4018-99a4-59276679fae5","Title":"","GroupID":"7801b5bb-054e-4ce0-942d-d7831c24ac40","GroupTitle":"Сувениры","GroupLink":"/menu/7801b5bb-054e-4ce0-942d-d7831c24ac40","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|f3cfe6db-6980-4b43-b4b6-741ab10d9e62",
    path: "/product/f3cfe6db-6980-4b43-b4b6-741ab10d9e62",
    meta: {"ID":"f3cfe6db-6980-4b43-b4b6-741ab10d9e62","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|f3fcc297-a2f7-4d99-8440-e98728ea60a8",
    path: "/product/f3fcc297-a2f7-4d99-8440-e98728ea60a8",
    meta: {"ID":"f3fcc297-a2f7-4d99-8440-e98728ea60a8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f402598a-346d-4a28-9f93-8fea0c61b7f0",
    path: "/product/f402598a-346d-4a28-9f93-8fea0c61b7f0",
    meta: {"ID":"f402598a-346d-4a28-9f93-8fea0c61b7f0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f41b24db-7fec-473c-b8d2-63a4f195455a",
    path: "/product/f41b24db-7fec-473c-b8d2-63a4f195455a",
    meta: {"ID":"f41b24db-7fec-473c-b8d2-63a4f195455a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f42183e3-0480-4bfd-885b-af992e76d40d",
    path: "/product/f42183e3-0480-4bfd-885b-af992e76d40d",
    meta: {"ID":"f42183e3-0480-4bfd-885b-af992e76d40d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|f4369421-b843-45fc-9f0e-a4ca11af3c0f",
    path: "/product/f4369421-b843-45fc-9f0e-a4ca11af3c0f",
    meta: {"ID":"f4369421-b843-45fc-9f0e-a4ca11af3c0f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f449fc33-b635-44a7-a75a-4a12988279ed",
    path: "/product/f449fc33-b635-44a7-a75a-4a12988279ed",
    meta: {"ID":"f449fc33-b635-44a7-a75a-4a12988279ed","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f4533533-30e6-4912-aa44-ba5d01046742",
    path: "/product/f4533533-30e6-4912-aa44-ba5d01046742",
    meta: {"ID":"f4533533-30e6-4912-aa44-ba5d01046742","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|f45e3387-655f-4e2b-9de2-061a58b7d7b5",
    path: "/product/f45e3387-655f-4e2b-9de2-061a58b7d7b5",
    meta: {"ID":"f45e3387-655f-4e2b-9de2-061a58b7d7b5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f475de5e-a3ea-472d-a344-9206f7cf67bd",
    path: "/product/f475de5e-a3ea-472d-a344-9206f7cf67bd",
    meta: {"ID":"f475de5e-a3ea-472d-a344-9206f7cf67bd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f48858a7-a2fa-4e44-853a-881c09cafa81",
    path: "/product/f48858a7-a2fa-4e44-853a-881c09cafa81",
    meta: {"ID":"f48858a7-a2fa-4e44-853a-881c09cafa81","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4089a20c-7c4e-410d-9a00-8bbac0d71cc8|f4910b3e-2606-4d8f-af11-b960f09b90b7",
    path: "/product/f4910b3e-2606-4d8f-af11-b960f09b90b7",
    meta: {"ID":"f4910b3e-2606-4d8f-af11-b960f09b90b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|f49b1856-8caa-466d-a9b9-d6049cce4ad8",
    path: "/product/f49b1856-8caa-466d-a9b9-d6049cce4ad8",
    meta: {"ID":"f49b1856-8caa-466d-a9b9-d6049cce4ad8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f4b1fc95-78ad-4e87-9cbb-1848b5490951",
    path: "/product/f4b1fc95-78ad-4e87-9cbb-1848b5490951",
    meta: {"ID":"f4b1fc95-78ad-4e87-9cbb-1848b5490951","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f4e87203-f243-4808-8e3a-def28d25622d",
    path: "/product/f4e87203-f243-4808-8e3a-def28d25622d",
    meta: {"ID":"f4e87203-f243-4808-8e3a-def28d25622d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|f4ec2061-2a1b-42d7-bfeb-10c05b163703",
    path: "/product/f4ec2061-2a1b-42d7-bfeb-10c05b163703",
    meta: {"ID":"f4ec2061-2a1b-42d7-bfeb-10c05b163703","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f4eda642-96f0-4f3d-99fa-519d1f17b57b",
    path: "/product/f4eda642-96f0-4f3d-99fa-519d1f17b57b",
    meta: {"ID":"f4eda642-96f0-4f3d-99fa-519d1f17b57b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f4f932c7-2194-40ee-9d7c-164c5b6613d8",
    path: "/product/f4f932c7-2194-40ee-9d7c-164c5b6613d8",
    meta: {"ID":"f4f932c7-2194-40ee-9d7c-164c5b6613d8","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f5163117-9188-4c16-af22-e8a7ce746e2c",
    path: "/product/f5163117-9188-4c16-af22-e8a7ce746e2c",
    meta: {"ID":"f5163117-9188-4c16-af22-e8a7ce746e2c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f52bca66-b8ce-4c50-b1fd-63b8ff759552",
    path: "/product/f52bca66-b8ce-4c50-b1fd-63b8ff759552",
    meta: {"ID":"f52bca66-b8ce-4c50-b1fd-63b8ff759552","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f5317dcd-1d6e-4bb9-bda1-5ab7f5abc7c5",
    path: "/product/f5317dcd-1d6e-4bb9-bda1-5ab7f5abc7c5",
    meta: {"ID":"f5317dcd-1d6e-4bb9-bda1-5ab7f5abc7c5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f532e4d4-84e2-41db-9ca4-039bbcbe82da",
    path: "/product/f532e4d4-84e2-41db-9ca4-039bbcbe82da",
    meta: {"ID":"f532e4d4-84e2-41db-9ca4-039bbcbe82da","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|f535a57d-ea0a-4f6e-a2f4-d3b32edcd7ba",
    path: "/product/f535a57d-ea0a-4f6e-a2f4-d3b32edcd7ba",
    meta: {"ID":"f535a57d-ea0a-4f6e-a2f4-d3b32edcd7ba","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|f53de191-0d7c-425e-8cf4-6cced69f7dab",
    path: "/product/f53de191-0d7c-425e-8cf4-6cced69f7dab",
    meta: {"ID":"f53de191-0d7c-425e-8cf4-6cced69f7dab","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f5715c76-2d6b-41f5-8aa7-f887d605cbfe",
    path: "/product/f5715c76-2d6b-41f5-8aa7-f887d605cbfe",
    meta: {"ID":"f5715c76-2d6b-41f5-8aa7-f887d605cbfe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f5841623-dda1-4f21-be34-142fd6b63467",
    path: "/product/f5841623-dda1-4f21-be34-142fd6b63467",
    meta: {"ID":"f5841623-dda1-4f21-be34-142fd6b63467","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|f586dc9e-0c1e-496f-8783-509d97cf4dbd",
    path: "/product/f586dc9e-0c1e-496f-8783-509d97cf4dbd",
    meta: {"ID":"f586dc9e-0c1e-496f-8783-509d97cf4dbd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a44e5f09-3d43-467a-82a1-f1788946110c|f590ad02-fdb9-4709-901b-e8b5743b5d07",
    path: "/product/f590ad02-fdb9-4709-901b-e8b5743b5d07",
    meta: {"ID":"f590ad02-fdb9-4709-901b-e8b5743b5d07","Title":"","GroupID":"a44e5f09-3d43-467a-82a1-f1788946110c","GroupTitle":"Вода","GroupLink":"/menu/a44e5f09-3d43-467a-82a1-f1788946110c","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|f593a5fc-557d-429e-b403-7d6c3f4364a5",
    path: "/product/f593a5fc-557d-429e-b403-7d6c3f4364a5",
    meta: {"ID":"f593a5fc-557d-429e-b403-7d6c3f4364a5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f5a05298-58b5-41c4-83ee-f7f810cc9f05",
    path: "/product/f5a05298-58b5-41c4-83ee-f7f810cc9f05",
    meta: {"ID":"f5a05298-58b5-41c4-83ee-f7f810cc9f05","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|af1ac0fd-a494-4b35-ae24-edc1fe10a7e4|f5e02351-74b2-47aa-8b8b-e8d88a9caf3d",
    path: "/product/f5e02351-74b2-47aa-8b8b-e8d88a9caf3d",
    meta: {"ID":"f5e02351-74b2-47aa-8b8b-e8d88a9caf3d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f6146292-5baf-4793-bf6a-f648101ee19c",
    path: "/product/f6146292-5baf-4793-bf6a-f648101ee19c",
    meta: {"ID":"f6146292-5baf-4793-bf6a-f648101ee19c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|f61d8edf-b017-4532-aae4-842b51b76a18",
    path: "/product/f61d8edf-b017-4532-aae4-842b51b76a18",
    meta: {"ID":"f61d8edf-b017-4532-aae4-842b51b76a18","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|f61f6844-9adf-44ae-a356-ea590abdec08",
    path: "/product/f61f6844-9adf-44ae-a356-ea590abdec08",
    meta: {"ID":"f61f6844-9adf-44ae-a356-ea590abdec08","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f6466892-4b73-41cb-9d4c-2d9bb8768002",
    path: "/product/f6466892-4b73-41cb-9d4c-2d9bb8768002",
    meta: {"ID":"f6466892-4b73-41cb-9d4c-2d9bb8768002","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|f646f933-4bd2-4901-b011-2a6f561aae9d",
    path: "/product/f646f933-4bd2-4901-b011-2a6f561aae9d",
    meta: {"ID":"f646f933-4bd2-4901-b011-2a6f561aae9d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|f6683079-0439-450b-9757-08c0439691d3",
    path: "/product/f6683079-0439-450b-9757-08c0439691d3",
    meta: {"ID":"f6683079-0439-450b-9757-08c0439691d3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f66e4b39-5376-4969-951d-50865b927614",
    path: "/product/f66e4b39-5376-4969-951d-50865b927614",
    meta: {"ID":"f66e4b39-5376-4969-951d-50865b927614","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|f6842535-5fcc-481e-a136-6e17f3273ad1",
    path: "/product/f6842535-5fcc-481e-a136-6e17f3273ad1",
    meta: {"ID":"f6842535-5fcc-481e-a136-6e17f3273ad1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|f6a2eab0-aa8a-4b24-8138-e643d712bfbf",
    path: "/product/f6a2eab0-aa8a-4b24-8138-e643d712bfbf",
    meta: {"ID":"f6a2eab0-aa8a-4b24-8138-e643d712bfbf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f6b46f47-ca62-454e-bce0-7bdc639567fa",
    path: "/product/f6b46f47-ca62-454e-bce0-7bdc639567fa",
    meta: {"ID":"f6b46f47-ca62-454e-bce0-7bdc639567fa","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|df6e1e38-ff3c-4a9e-94f2-baf987c30a9b|f6b74707-9cd5-4fec-aa34-58aa4862cf01",
    path: "/product/f6b74707-9cd5-4fec-aa34-58aa4862cf01",
    meta: {"ID":"f6b74707-9cd5-4fec-aa34-58aa4862cf01","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|f6cffc86-9aa4-4a02-bc98-9b0785491cb2",
    path: "/product/f6cffc86-9aa4-4a02-bc98-9b0785491cb2",
    meta: {"ID":"f6cffc86-9aa4-4a02-bc98-9b0785491cb2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f6e16395-cd94-41d2-995e-677e490e1638",
    path: "/product/f6e16395-cd94-41d2-995e-677e490e1638",
    meta: {"ID":"f6e16395-cd94-41d2-995e-677e490e1638","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f6fe0b76-e59e-4754-a414-192cf35f978e",
    path: "/product/f6fe0b76-e59e-4754-a414-192cf35f978e",
    meta: {"ID":"f6fe0b76-e59e-4754-a414-192cf35f978e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|4226240f-1be1-401e-992a-c8ef72d6cfaa|f74a257b-22a3-4e8e-87a7-3658f3ddf1c2",
    path: "/product/f74a257b-22a3-4e8e-87a7-3658f3ddf1c2",
    meta: {"ID":"f74a257b-22a3-4e8e-87a7-3658f3ddf1c2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f74c4ade-d61a-493f-b478-56bd2019582a",
    path: "/product/f74c4ade-d61a-493f-b478-56bd2019582a",
    meta: {"ID":"f74c4ade-d61a-493f-b478-56bd2019582a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f76df6ad-74f2-4103-b5b8-926a52c4b9cd",
    path: "/product/f76df6ad-74f2-4103-b5b8-926a52c4b9cd",
    meta: {"ID":"f76df6ad-74f2-4103-b5b8-926a52c4b9cd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|047acbf4-1da5-47fd-a8e9-e7d2f7db97a8|f7b60fd9-aaec-4b56-9fed-93c69eb23aaf",
    path: "/product/f7b60fd9-aaec-4b56-9fed-93c69eb23aaf",
    meta: {"ID":"f7b60fd9-aaec-4b56-9fed-93c69eb23aaf","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|f7c97f99-a35a-4a55-8ac6-8041c4823997",
    path: "/product/f7c97f99-a35a-4a55-8ac6-8041c4823997",
    meta: {"ID":"f7c97f99-a35a-4a55-8ac6-8041c4823997","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|f7cb6305-128f-4c05-aac1-949401997772",
    path: "/product/f7cb6305-128f-4c05-aac1-949401997772",
    meta: {"ID":"f7cb6305-128f-4c05-aac1-949401997772","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f7d85a63-5943-41ca-a146-2e5a3b63c12c",
    path: "/product/f7d85a63-5943-41ca-a146-2e5a3b63c12c",
    meta: {"ID":"f7d85a63-5943-41ca-a146-2e5a3b63c12c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f7db9798-7e92-4840-b0a4-e4f35c8a0c02",
    path: "/product/f7db9798-7e92-4840-b0a4-e4f35c8a0c02",
    meta: {"ID":"f7db9798-7e92-4840-b0a4-e4f35c8a0c02","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|f7f7d8d2-6a27-4c3e-af0f-d368d7d0e549",
    path: "/product/f7f7d8d2-6a27-4c3e-af0f-d368d7d0e549",
    meta: {"ID":"f7f7d8d2-6a27-4c3e-af0f-d368d7d0e549","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f80a0833-91c9-4975-afa5-5d49ec879cdc",
    path: "/product/f80a0833-91c9-4975-afa5-5d49ec879cdc",
    meta: {"ID":"f80a0833-91c9-4975-afa5-5d49ec879cdc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|f8235185-7bcc-4bc1-a985-55b219b5d46f",
    path: "/product/f8235185-7bcc-4bc1-a985-55b219b5d46f",
    meta: {"ID":"f8235185-7bcc-4bc1-a985-55b219b5d46f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|f8402379-18e9-4480-9b49-98a7938eec5f",
    path: "/product/f8402379-18e9-4480-9b49-98a7938eec5f",
    meta: {"ID":"f8402379-18e9-4480-9b49-98a7938eec5f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f854814f-0e69-4c4d-84ce-7140e27efedc",
    path: "/product/f854814f-0e69-4c4d-84ce-7140e27efedc",
    meta: {"ID":"f854814f-0e69-4c4d-84ce-7140e27efedc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|857a8c38-4143-47c1-9952-cd00d68027a6|f86256d6-e2f1-4902-9d33-0c1bedb93fd1",
    path: "/product/f86256d6-e2f1-4902-9d33-0c1bedb93fd1",
    meta: {"ID":"f86256d6-e2f1-4902-9d33-0c1bedb93fd1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f4ccaad8-3cd6-48db-8492-c913529e47ff|f864ba65-ad02-470e-93cd-2d10a6e2e01e",
    path: "/product/f864ba65-ad02-470e-93cd-2d10a6e2e01e",
    meta: {"ID":"f864ba65-ad02-470e-93cd-2d10a6e2e01e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f868b7a2-a537-4e3e-9c22-3b88d04fc0d5",
    path: "/product/f868b7a2-a537-4e3e-9c22-3b88d04fc0d5",
    meta: {"ID":"f868b7a2-a537-4e3e-9c22-3b88d04fc0d5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f87795dd-9863-44fb-b7e2-0a24e46410fe",
    path: "/product/f87795dd-9863-44fb-b7e2-0a24e46410fe",
    meta: {"ID":"f87795dd-9863-44fb-b7e2-0a24e46410fe","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c7bff659-b4f2-41b7-b291-f8f1f592ce4e|f87f38cb-6352-412b-afed-c12243b0a3b9",
    path: "/product/f87f38cb-6352-412b-afed-c12243b0a3b9",
    meta: {"ID":"f87f38cb-6352-412b-afed-c12243b0a3b9","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f88500b7-736f-4071-b7de-be53ba3ff2cc",
    path: "/product/f88500b7-736f-4071-b7de-be53ba3ff2cc",
    meta: {"ID":"f88500b7-736f-4071-b7de-be53ba3ff2cc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|f8981213-3a1f-431d-a4e0-0a056900b95e",
    path: "/product/f8981213-3a1f-431d-a4e0-0a056900b95e",
    meta: {"ID":"f8981213-3a1f-431d-a4e0-0a056900b95e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|ade4edd8-c5f5-42e4-9c65-8cb5b2a8b6a0|f89812e8-abad-44bd-80c6-58754b870a48",
    path: "/product/f89812e8-abad-44bd-80c6-58754b870a48",
    meta: {"ID":"f89812e8-abad-44bd-80c6-58754b870a48","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|812d462b-6593-45fc-ab6f-1aedcd8f380a|f8ab8386-a479-49a1-89b2-70b368c82de7",
    path: "/product/f8ab8386-a479-49a1-89b2-70b368c82de7",
    meta: {"ID":"f8ab8386-a479-49a1-89b2-70b368c82de7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|f8b0076d-ddc2-4781-8cbd-eced51c0f4d6",
    path: "/product/f8b0076d-ddc2-4781-8cbd-eced51c0f4d6",
    meta: {"ID":"f8b0076d-ddc2-4781-8cbd-eced51c0f4d6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f8b4aa91-25b2-4891-8ebe-4bc68a09a5ea",
    path: "/product/f8b4aa91-25b2-4891-8ebe-4bc68a09a5ea",
    meta: {"ID":"f8b4aa91-25b2-4891-8ebe-4bc68a09a5ea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f8d358c4-8219-46f8-8456-954956592ad5",
    path: "/product/f8d358c4-8219-46f8-8456-954956592ad5",
    meta: {"ID":"f8d358c4-8219-46f8-8456-954956592ad5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f8e0569d-f1ca-4ba5-8087-1f5adfb31879",
    path: "/product/f8e0569d-f1ca-4ba5-8087-1f5adfb31879",
    meta: {"ID":"f8e0569d-f1ca-4ba5-8087-1f5adfb31879","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f8e8a489-979b-43c0-887b-a17bd5dd30c6",
    path: "/product/f8e8a489-979b-43c0-887b-a17bd5dd30c6",
    meta: {"ID":"f8e8a489-979b-43c0-887b-a17bd5dd30c6","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f8fe58ce-ce6f-486c-9b7e-e70e03f806d1",
    path: "/product/f8fe58ce-ce6f-486c-9b7e-e70e03f806d1",
    meta: {"ID":"f8fe58ce-ce6f-486c-9b7e-e70e03f806d1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|9469f2d9-9933-4705-999d-21ae823de7e6|f9123cb7-8cfe-41af-a377-9792562d0a42",
    path: "/product/f9123cb7-8cfe-41af-a377-9792562d0a42",
    meta: {"ID":"f9123cb7-8cfe-41af-a377-9792562d0a42","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f914fc30-5cd0-479b-89b8-c6a5c4122a32",
    path: "/product/f914fc30-5cd0-479b-89b8-c6a5c4122a32",
    meta: {"ID":"f914fc30-5cd0-479b-89b8-c6a5c4122a32","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f9331cbc-f1e5-4c5f-9deb-ed8a25101116",
    path: "/product/f9331cbc-f1e5-4c5f-9deb-ed8a25101116",
    meta: {"ID":"f9331cbc-f1e5-4c5f-9deb-ed8a25101116","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f955ae29-c517-4673-82c1-2839e94211b1",
    path: "/product/f955ae29-c517-4673-82c1-2839e94211b1",
    meta: {"ID":"f955ae29-c517-4673-82c1-2839e94211b1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f959f050-9f56-48cc-8c75-4bb0ba0a39f4",
    path: "/product/f959f050-9f56-48cc-8c75-4bb0ba0a39f4",
    meta: {"ID":"f959f050-9f56-48cc-8c75-4bb0ba0a39f4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|f9654301-253e-4235-b67d-a18168471344",
    path: "/product/f9654301-253e-4235-b67d-a18168471344",
    meta: {"ID":"f9654301-253e-4235-b67d-a18168471344","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|f968032c-9865-4fff-95ce-81ab945b550b",
    path: "/product/f968032c-9865-4fff-95ce-81ab945b550b",
    meta: {"ID":"f968032c-9865-4fff-95ce-81ab945b550b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|f97710f9-4977-434a-93dd-157395a77dc2",
    path: "/product/f97710f9-4977-434a-93dd-157395a77dc2",
    meta: {"ID":"f97710f9-4977-434a-93dd-157395a77dc2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|66359842-140f-4ebd-943e-78ba09687d02|f991239d-613b-4d59-bfe0-0c488da24fec",
    path: "/product/f991239d-613b-4d59-bfe0-0c488da24fec",
    meta: {"ID":"f991239d-613b-4d59-bfe0-0c488da24fec","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f99943e2-8225-475f-84c2-5b17d46b403c",
    path: "/product/f99943e2-8225-475f-84c2-5b17d46b403c",
    meta: {"ID":"f99943e2-8225-475f-84c2-5b17d46b403c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|f99c8bb8-0f51-4f22-a605-b139cecc376e",
    path: "/product/f99c8bb8-0f51-4f22-a605-b139cecc376e",
    meta: {"ID":"f99c8bb8-0f51-4f22-a605-b139cecc376e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|f99d57e9-aac4-44e5-bbfb-324410468456",
    path: "/product/f99d57e9-aac4-44e5-bbfb-324410468456",
    meta: {"ID":"f99d57e9-aac4-44e5-bbfb-324410468456","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f9d6f24e-84e0-41dd-8ead-ec82ecdb146f",
    path: "/product/f9d6f24e-84e0-41dd-8ead-ec82ecdb146f",
    meta: {"ID":"f9d6f24e-84e0-41dd-8ead-ec82ecdb146f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|f9fef4d1-b94d-4ed1-8b24-911ea11ce05b",
    path: "/product/f9fef4d1-b94d-4ed1-8b24-911ea11ce05b",
    meta: {"ID":"f9fef4d1-b94d-4ed1-8b24-911ea11ce05b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fa0d74fa-2d4b-45e5-9d14-ffdce7e7e4bb",
    path: "/product/fa0d74fa-2d4b-45e5-9d14-ffdce7e7e4bb",
    meta: {"ID":"fa0d74fa-2d4b-45e5-9d14-ffdce7e7e4bb","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|fa2e8137-1b01-4887-8a03-241153105776",
    path: "/product/fa2e8137-1b01-4887-8a03-241153105776",
    meta: {"ID":"fa2e8137-1b01-4887-8a03-241153105776","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|fa36b798-3c50-4d03-a52b-76ab6cd2c0b1",
    path: "/product/fa36b798-3c50-4d03-a52b-76ab6cd2c0b1",
    meta: {"ID":"fa36b798-3c50-4d03-a52b-76ab6cd2c0b1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fa4b13fa-4d7a-4f78-9e14-23525c6bb2c0",
    path: "/product/fa4b13fa-4d7a-4f78-9e14-23525c6bb2c0",
    meta: {"ID":"fa4b13fa-4d7a-4f78-9e14-23525c6bb2c0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5d6b7fc5-ea97-44da-bc03-ffeea41438a1|fa7eafbb-b7bd-4f3b-a7ce-d996842b082e",
    path: "/product/fa7eafbb-b7bd-4f3b-a7ce-d996842b082e",
    meta: {"ID":"fa7eafbb-b7bd-4f3b-a7ce-d996842b082e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|fa877346-daab-47e3-8a9f-8170ddffe95b",
    path: "/product/fa877346-daab-47e3-8a9f-8170ddffe95b",
    meta: {"ID":"fa877346-daab-47e3-8a9f-8170ddffe95b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fa920cac-c8df-4db8-a671-6469c866c10e",
    path: "/product/fa920cac-c8df-4db8-a671-6469c866c10e",
    meta: {"ID":"fa920cac-c8df-4db8-a671-6469c866c10e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e4322a75-f23f-4323-9f26-5d0e5462e09e|fad6e13c-545c-486e-af7b-ed3e4e087060",
    path: "/product/fad6e13c-545c-486e-af7b-ed3e4e087060",
    meta: {"ID":"fad6e13c-545c-486e-af7b-ed3e4e087060","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fad9c311-f6a3-4f36-92cc-01c192a6ede7",
    path: "/product/fad9c311-f6a3-4f36-92cc-01c192a6ede7",
    meta: {"ID":"fad9c311-f6a3-4f36-92cc-01c192a6ede7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|faeb4f68-4edb-440c-8b2f-12ca68b7c40e",
    path: "/product/faeb4f68-4edb-440c-8b2f-12ca68b7c40e",
    meta: {"ID":"faeb4f68-4edb-440c-8b2f-12ca68b7c40e","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|c20c30ec-cf97-4ef6-bc06-9e04305c93ad|fb0202a6-0f91-4f1d-8658-42bab208a71a",
    path: "/product/fb0202a6-0f91-4f1d-8658-42bab208a71a",
    meta: {"ID":"fb0202a6-0f91-4f1d-8658-42bab208a71a","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|fb1f4a7b-41ae-47f2-8423-f2530b629391",
    path: "/product/fb1f4a7b-41ae-47f2-8423-f2530b629391",
    meta: {"ID":"fb1f4a7b-41ae-47f2-8423-f2530b629391","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7bdadf21-f387-4551-8ae2-d2b5ae64736e|fb278854-e4d2-4ecd-9fd7-0d590622da60",
    path: "/product/fb278854-e4d2-4ecd-9fd7-0d590622da60",
    meta: {"ID":"fb278854-e4d2-4ecd-9fd7-0d590622da60","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|fb38e8d6-fce4-4285-9b09-ef05438d64b7",
    path: "/product/fb38e8d6-fce4-4285-9b09-ef05438d64b7",
    meta: {"ID":"fb38e8d6-fce4-4285-9b09-ef05438d64b7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|fb4626f3-879e-4648-84f5-76b5b10e532c",
    path: "/product/fb4626f3-879e-4648-84f5-76b5b10e532c",
    meta: {"ID":"fb4626f3-879e-4648-84f5-76b5b10e532c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|cd46ef6d-cd47-4366-b750-cf81fe633bba|fb67cfa3-3bb5-459b-b78a-48fe0a69c1b0",
    path: "/product/fb67cfa3-3bb5-459b-b78a-48fe0a69c1b0",
    meta: {"ID":"fb67cfa3-3bb5-459b-b78a-48fe0a69c1b0","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fb6959a7-8562-4ddf-a794-48197f52540b",
    path: "/product/fb6959a7-8562-4ddf-a794-48197f52540b",
    meta: {"ID":"fb6959a7-8562-4ddf-a794-48197f52540b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fb7bfbf0-6df9-465b-9ce0-87a614094b48",
    path: "/product/fb7bfbf0-6df9-465b-9ce0-87a614094b48",
    meta: {"ID":"fb7bfbf0-6df9-465b-9ce0-87a614094b48","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fb8df494-d368-4000-86c4-92e155d4c988",
    path: "/product/fb8df494-d368-4000-86c4-92e155d4c988",
    meta: {"ID":"fb8df494-d368-4000-86c4-92e155d4c988","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|fb8f19ed-6a09-4f40-be50-d0d1b400422c",
    path: "/product/fb8f19ed-6a09-4f40-be50-d0d1b400422c",
    meta: {"ID":"fb8f19ed-6a09-4f40-be50-d0d1b400422c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fb9bc21e-c92e-471d-b9f5-0b411cba134f",
    path: "/product/fb9bc21e-c92e-471d-b9f5-0b411cba134f",
    meta: {"ID":"fb9bc21e-c92e-471d-b9f5-0b411cba134f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|103e3ad5-e859-438d-80b6-e39bbf915fd1|fb9c91c2-1f1a-40f5-b079-7dcd38628683",
    path: "/product/fb9c91c2-1f1a-40f5-b079-7dcd38628683",
    meta: {"ID":"fb9c91c2-1f1a-40f5-b079-7dcd38628683","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fba2e307-f24f-4b9d-8f52-bed1ffa444ea",
    path: "/product/fba2e307-f24f-4b9d-8f52-bed1ffa444ea",
    meta: {"ID":"fba2e307-f24f-4b9d-8f52-bed1ffa444ea","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|2eb6da7e-3951-455d-8370-7c1a0d8ed4a7|fbbd3e47-44f2-41e7-8400-e7f2515db28c",
    path: "/product/fbbd3e47-44f2-41e7-8400-e7f2515db28c",
    meta: {"ID":"fbbd3e47-44f2-41e7-8400-e7f2515db28c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|fbfa18de-e377-4d81-bff0-2c455fea4c27",
    path: "/product/fbfa18de-e377-4d81-bff0-2c455fea4c27",
    meta: {"ID":"fbfa18de-e377-4d81-bff0-2c455fea4c27","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e1e8f31e-b33c-4565-a9c4-e408e050f016|fc07e55d-d856-4004-8413-c66f36efdc91",
    path: "/product/fc07e55d-d856-4004-8413-c66f36efdc91",
    meta: {"ID":"fc07e55d-d856-4004-8413-c66f36efdc91","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|59b73946-d0e9-47cf-82f0-0d5c22e3862b|fc7c5723-2439-488d-967f-bb3afa60716b",
    path: "/product/fc7c5723-2439-488d-967f-bb3afa60716b",
    meta: {"ID":"fc7c5723-2439-488d-967f-bb3afa60716b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7c6436b9-6559-45d2-9cd4-f11a706d5aee|fca32429-c8ab-4f91-8c12-40fb635663e7",
    path: "/product/fca32429-c8ab-4f91-8c12-40fb635663e7",
    meta: {"ID":"fca32429-c8ab-4f91-8c12-40fb635663e7","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|fcb1be3f-a58e-45a9-8f16-851219a3d444",
    path: "/product/fcb1be3f-a58e-45a9-8f16-851219a3d444",
    meta: {"ID":"fcb1be3f-a58e-45a9-8f16-851219a3d444","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|fcb5464f-878f-4d16-9160-033fb9a0404b",
    path: "/product/fcb5464f-878f-4d16-9160-033fb9a0404b",
    meta: {"ID":"fcb5464f-878f-4d16-9160-033fb9a0404b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|fccc08b3-a73c-4e8f-b3df-6aa8702f33e1",
    path: "/product/fccc08b3-a73c-4e8f-b3df-6aa8702f33e1",
    meta: {"ID":"fccc08b3-a73c-4e8f-b3df-6aa8702f33e1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|a6979b6e-80a4-4a5e-926a-851c914a46bf|fce51f97-cebe-4c40-adc9-be1758cc5fd3",
    path: "/product/fce51f97-cebe-4c40-adc9-be1758cc5fd3",
    meta: {"ID":"fce51f97-cebe-4c40-adc9-be1758cc5fd3","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|fcebff45-9341-4093-a35c-dad3a63b3d1f",
    path: "/product/fcebff45-9341-4093-a35c-dad3a63b3d1f",
    meta: {"ID":"fcebff45-9341-4093-a35c-dad3a63b3d1f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|fcf5a24e-b078-44b1-a978-ca06a2e07fbd",
    path: "/product/fcf5a24e-b078-44b1-a978-ca06a2e07fbd",
    meta: {"ID":"fcf5a24e-b078-44b1-a978-ca06a2e07fbd","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fcfb0bd3-9506-44c3-a640-09391e7759e5",
    path: "/product/fcfb0bd3-9506-44c3-a640-09391e7759e5",
    meta: {"ID":"fcfb0bd3-9506-44c3-a640-09391e7759e5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|fd1c3cd7-c428-4207-b580-08fc11df6e15",
    path: "/product/fd1c3cd7-c428-4207-b580-08fc11df6e15",
    meta: {"ID":"fd1c3cd7-c428-4207-b580-08fc11df6e15","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|fd21bd80-00c3-49a5-a75c-740910d9d28c",
    path: "/product/fd21bd80-00c3-49a5-a75c-740910d9d28c",
    meta: {"ID":"fd21bd80-00c3-49a5-a75c-740910d9d28c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|fd303b62-a305-4fd6-8101-89444451e468",
    path: "/product/fd303b62-a305-4fd6-8101-89444451e468",
    meta: {"ID":"fd303b62-a305-4fd6-8101-89444451e468","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3c06d685-8171-454b-920a-f3a76b0a4226|fd4d4e17-d30f-4c4c-b204-e22bc3480dac",
    path: "/product/fd4d4e17-d30f-4c4c-b204-e22bc3480dac",
    meta: {"ID":"fd4d4e17-d30f-4c4c-b204-e22bc3480dac","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|fd5c6c9d-8d4d-4ff0-bd30-830e046db366",
    path: "/product/fd5c6c9d-8d4d-4ff0-bd30-830e046db366",
    meta: {"ID":"fd5c6c9d-8d4d-4ff0-bd30-830e046db366","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fd9349d9-bba6-48fb-9f43-a93b4e148d34",
    path: "/product/fd9349d9-bba6-48fb-9f43-a93b4e148d34",
    meta: {"ID":"fd9349d9-bba6-48fb-9f43-a93b4e148d34","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|33e8f98d-49a3-4d75-8076-e016770f1878|fda8452c-a0c9-4ea6-b89d-f37cfac021df",
    path: "/product/fda8452c-a0c9-4ea6-b89d-f37cfac021df",
    meta: {"ID":"fda8452c-a0c9-4ea6-b89d-f37cfac021df","Title":"","GroupID":"33e8f98d-49a3-4d75-8076-e016770f1878","GroupTitle":"Дополнительно","GroupLink":"/menu/33e8f98d-49a3-4d75-8076-e016770f1878","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fdee3066-f140-4d2f-a781-ed97abd39d72",
    path: "/product/fdee3066-f140-4d2f-a781-ed97abd39d72",
    meta: {"ID":"fdee3066-f140-4d2f-a781-ed97abd39d72","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|df6e1e38-ff3c-4a9e-94f2-baf987c30a9b|fe45afc5-88d3-4804-9f6b-9700fc8fb519",
    path: "/product/fe45afc5-88d3-4804-9f6b-9700fc8fb519",
    meta: {"ID":"fe45afc5-88d3-4804-9f6b-9700fc8fb519","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|fe492cfc-fb16-4eff-9a7f-333f7facf4a2",
    path: "/product/fe492cfc-fb16-4eff-9a7f-333f7facf4a2",
    meta: {"ID":"fe492cfc-fb16-4eff-9a7f-333f7facf4a2","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fe6b79f6-a596-49e9-8a39-acc8fe6030be",
    path: "/product/fe6b79f6-a596-49e9-8a39-acc8fe6030be",
    meta: {"ID":"fe6b79f6-a596-49e9-8a39-acc8fe6030be","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|5a9614d4-a627-4f5c-902e-fb7165a994f6|febf84a0-6ad8-4b1e-aeda-593928257869",
    path: "/product/febf84a0-6ad8-4b1e-aeda-593928257869",
    meta: {"ID":"febf84a0-6ad8-4b1e-aeda-593928257869","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|535cfdf0-8f9a-46fd-9cdc-483647c40ce4|fed093bc-492d-4d74-a12b-570bccf4690f",
    path: "/product/fed093bc-492d-4d74-a12b-570bccf4690f",
    meta: {"ID":"fed093bc-492d-4d74-a12b-570bccf4690f","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|fed74eab-b160-49af-a0b6-45123882fb9d",
    path: "/product/fed74eab-b160-49af-a0b6-45123882fb9d",
    meta: {"ID":"fed74eab-b160-49af-a0b6-45123882fb9d","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|feeabf78-f4ec-430b-ac0c-e591da3202f5",
    path: "/product/feeabf78-f4ec-430b-ac0c-e591da3202f5",
    meta: {"ID":"feeabf78-f4ec-430b-ac0c-e591da3202f5","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|fefd003a-be0d-4ba5-8a05-ca7cd3945f77",
    path: "/product/fefd003a-be0d-4ba5-8a05-ca7cd3945f77",
    meta: {"ID":"fefd003a-be0d-4ba5-8a05-ca7cd3945f77","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f7b45e69-6e72-4c4c-b445-685afaa3561d|ff04c07f-85c6-4bcb-84e5-495addc6cb6b",
    path: "/product/ff04c07f-85c6-4bcb-84e5-495addc6cb6b",
    meta: {"ID":"ff04c07f-85c6-4bcb-84e5-495addc6cb6b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|e096b679-4d2b-4640-a67c-84d914bba2e7|ff0ff388-3b39-4af1-8450-9a69a1b9ac45",
    path: "/product/ff0ff388-3b39-4af1-8450-9a69a1b9ac45",
    meta: {"ID":"ff0ff388-3b39-4af1-8450-9a69a1b9ac45","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|ff4966a2-27c5-4263-bb5a-b76b63ae89ef",
    path: "/product/ff4966a2-27c5-4263-bb5a-b76b63ae89ef",
    meta: {"ID":"ff4966a2-27c5-4263-bb5a-b76b63ae89ef","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|f9386ec0-20e5-4ef4-9666-79ef14c2ccd7|ff4c596c-0c08-49c5-81f7-b0752a91cdd1",
    path: "/product/ff4c596c-0c08-49c5-81f7-b0752a91cdd1",
    meta: {"ID":"ff4c596c-0c08-49c5-81f7-b0752a91cdd1","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|3610717c-d75d-418e-b9cf-4287f011fecc|ff63b127-ded2-458f-a5f7-9d11896fd8e4",
    path: "/product/ff63b127-ded2-458f-a5f7-9d11896fd8e4",
    meta: {"ID":"ff63b127-ded2-458f-a5f7-9d11896fd8e4","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|04d26d3a-b92c-4816-8e9a-e72bcd74a9be|ff7be966-c056-4069-a628-e82def960444",
    path: "/product/ff7be966-c056-4069-a628-e82def960444",
    meta: {"ID":"ff7be966-c056-4069-a628-e82def960444","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7232e3bc-f826-4337-a86e-cae9f4e51982|ffa5eb0a-ba34-44e5-9e6c-7afcabd8d38b",
    path: "/product/ffa5eb0a-ba34-44e5-9e6c-7afcabd8d38b",
    meta: {"ID":"ffa5eb0a-ba34-44e5-9e6c-7afcabd8d38b","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|babeb65e-6ace-4232-ab16-79ab90b077be|ffb413f0-96d4-4d02-a009-888409af2f29",
    path: "/product/ffb413f0-96d4-4d02-a009-888409af2f29",
    meta: {"ID":"ffb413f0-96d4-4d02-a009-888409af2f29","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|27316be5-1ea1-427e-b36d-1c6a4a7c2ca9|ffe5361b-84f1-426e-8c4b-3ecef42118dc",
    path: "/product/ffe5361b-84f1-426e-8c4b-3ecef42118dc",
    meta: {"ID":"ffe5361b-84f1-426e-8c4b-3ecef42118dc","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "product|7903c504-9532-4161-9984-4659d836f6c7|fff8acb4-8f31-436a-98d4-6afecb2f635c",
    path: "/product/fff8acb4-8f31-436a-98d4-6afecb2f635c",
    meta: {"ID":"fff8acb4-8f31-436a-98d4-6afecb2f635c","Title":"","GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/menu/product/[id].vue")
  },
  {
    name: "legal-pay-rules",
    path: "/rules",
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/legal.vue")
  },
  {
    name: "legal-personal-data",
    path: "/personaldata",
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/legal.vue")
  },
  {
    name: "legal-agreement",
    path: "/privacy",
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/legal.vue")
  },
  {
    name: "legal-points",
    path: "/points",
    component: () => import("D:/deploy/UploadHidden/20241115-T903.266/arora-front/satellite/9b5c70bd-53c5-4993-9540-95f473628308/pages/legal.vue")
  }
]