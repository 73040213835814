<template>
  <svg
    class="v-icon-chevron-right"
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ArrowRight">
      <path
        id="Arrow"
        d="M4 11L7.50807 6.49798C7.79564 6.12893 7.78889 5.60981 7.49181 5.24837L4 1"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>

<style lang="scss">
.v-icon-chevron-right {
  fill: none;
}
</style>
