<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="qr-code">
      <path
        id="Vector"
        d="M5.83333 2.5H3.33333C2.8731 2.5 2.5 2.8731 2.5 3.33333V5.83333C2.5 6.29357 2.8731 6.66667 3.33333 6.66667H5.83333C6.29357 6.66667 6.66667 6.29357 6.66667 5.83333V3.33333C6.66667 2.8731 6.29357 2.5 5.83333 2.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_2"
        d="M16.6663 2.5H14.1663C13.7061 2.5 13.333 2.8731 13.333 3.33333V5.83333C13.333 6.29357 13.7061 6.66667 14.1663 6.66667H16.6663C17.1266 6.66667 17.4997 6.29357 17.4997 5.83333V3.33333C17.4997 2.8731 17.1266 2.5 16.6663 2.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_3"
        d="M5.83333 13.3335H3.33333C2.8731 13.3335 2.5 13.7066 2.5 14.1668V16.6668C2.5 17.1271 2.8731 17.5002 3.33333 17.5002H5.83333C6.29357 17.5002 6.66667 17.1271 6.66667 16.6668V14.1668C6.66667 13.7066 6.29357 13.3335 5.83333 13.3335Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_4"
        d="M17.4997 13.3335H14.9997C14.5576 13.3335 14.1337 13.5091 13.8212 13.8217C13.5086 14.1342 13.333 14.5581 13.333 15.0002V17.5002"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_5"
        d="M17.5 17.5V17.5083"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_6"
        d="M9.99967 5.8335V8.3335C9.99967 8.77552 9.82408 9.19945 9.51152 9.51201C9.19896 9.82457 8.77504 10.0002 8.33301 10.0002H5.83301"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_7"
        d="M2.5 10H2.50833"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_8"
        d="M10 2.5H10.0083"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_9"
        d="M10 13.3335V13.3418"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_10"
        d="M13.333 10H14.1663"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_11"
        d="M17.5 10V10.0083"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector_12"
        d="M10 17.4998V16.6665"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
</template>
