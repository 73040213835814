import type { ImageInfo } from '~types/common'

import { useCommon } from '@arora/common'

type imageInfoComposable = {
  imageExists: (image: ImageInfo | null | undefined) => boolean
  isImageInfoVideoContent: (media: ImageInfo) => boolean
}
export default function useImageInfo(): imageInfoComposable {
  const { stringIsNullOrWhitespace } = useCommon()

  function imageExists(image: ImageInfo | null | undefined): boolean {
    if (!image) return false
    return !stringIsNullOrWhitespace(image.Path) && image.Path !== '//' && image.Path !== '/'
  }

  function isImageInfoVideoContent(media: ImageInfo): boolean {
    return media.Path.endsWith('mp4') || media.Path.endsWith('3gp')
  }

  return {
    imageExists,
    isImageInfoVideoContent
  }
}
