<i18n>
ru:
  points: Б
ua:
  points: Б
us:
  points: points
</i18n>

<template>
  <span
    class="v-currency-wrapper v-d-flex"
    :class="{
      'v-strikethrough': strikethrough,
      'v-strikethrough-default': strikethrough && !appConfig.VueSettingsPreRun.CurrencyStrikethroughDiag,
      'v-strikethrough-diagonal': strikethrough && appConfig.VueSettingsPreRun.CurrencyStrikethroughDiag
    }"
    :data-test-id="dataTestId && `${dataTestId}-currency`"
  >
    <span
      v-if="showCurrencyBeforeDigits && !showPoints"
      class="v-currency-symbol__left"
      v-html="restaurantStore.CurrencySymbol"
    />
    <span
      v-if="strikethrough"
      class="v-amount"
      :data-test-id="dataTestId && `${dataTestId}-amount`"
      v-html="numeric(amount ?? 0, 'currency')"
    />
    <span
      v-else
      class="v-amount"
      :data-test-id="dataTestId && `${dataTestId}-amount`"
    >
      <ClientOnly>
        <template #fallback>
          {{ amount ?? 0 }}
        </template>
        <common-tweened-number
          :num="amount ?? 0"
          type="currency"
        />
      </ClientOnly>
    </span>
    <span
      v-if="!showCurrencyBeforeDigits && !showPoints"
      class="v-currency-symbol__right"
      v-html="restaurantStore.CurrencySymbol"
    />
    <span
      v-if="showPoints"
      class="v-currency"
      v-html="translate('currency.points')"
    />
  </span>
</template>

<script setup lang="ts">
import type { AutoTest } from '@arora/common'

withDefaults(
  defineProps<
    AutoTest & {
      strikethrough?: boolean
      amount?: number | null
      showPoints?: boolean
    }
  >(),
  {
    strikethrough: false,
    amount: null,
    showPoints: false
  }
)

const { translate, numeric } = useI18nSanitized()

const appConfig = useAppConfig()
const restaurantStore = useRestaurantStore()

const showCurrencyBeforeDigits = computed<boolean>(
  () => restaurantStore.CurrencySettings?.CurrencyBeforeAmount ?? false
)
</script>

<style lang="scss">
.v-strikethrough {
  opacity: 0.5;
}

.v-strikethrough-default {
  position: relative;
}

.v-strikethrough-default:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
}

.v-strikethrough-diagonal {
  position: relative;
}

.v-strikethrough-diagonal:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;

  transform: rotate(-10deg);
}

.v-amount {
  cursor: inherit;
  vertical-align: middle;
  font-variant-numeric: tabular-nums;
}

.v-currency {
  vertical-align: middle;
  padding-left: 3px;

  .v-currency__fa {
    font-size: 90%;
  }
}

.v-currency-symbol {
  &__right {
    margin-left: 0.125rem;
  }
  &__left {
    margin-right: 0.125rem;
  }
}

.v-currency-points {
  font-size: inherit;
}
</style>
