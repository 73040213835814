<template>
  <template v-if="appConfig.VueSettingsPreRun.IconsLayout === 'Lhun'">
    <svg
      class="v-lhun"
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 21.3846L27 19C27 11.8203 21.1797 6 14 6C6.8203 6 1 11.8203 0.999997 19L0.999997 21.3846C0.999997 21.7245 1.27551 22 1.61538 22H9.04762H26.3846C26.7245 22 27 21.7245 27 21.3846Z"
        fill="#5B5B5B"
      />
      <path
        d="M2.5 27H25.5C26.3284 27 27 26.3284 27 25.5C27 24.6716 26.3284 24 25.5 24H25H2.5C1.67157 24 1 24.6716 1 25.5C1 26.3284 1.67157 27 2.5 27Z"
        fill="#5B5B5B"
      />
      <circle
        cx="14"
        cy="5"
        fill="#5B5B5B"
        r="3"
      />
    </svg>
  </template>
  <template v-else-if="appConfig.VueSettingsPreRun.IconsLayout === 'Poros'">
    <svg
      class="v-poros"
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.80952 23H25V19C25 12.9249 20.0751 8 14 8V8C7.92487 8 3 12.9249 3 19V23H5.09524"
        stroke="#F4F4F4"
        stroke-linecap="round"
        stroke-width="1.5"
      />
      <path
        d="M4 23H2.5C1.67157 23 1 23.6716 1 24.5V24.5C1 25.3284 1.67157 26 2.5 26H25.5C26.3284 26 27 25.3284 27 24.5V24.5C27 23.6716 26.3284 23 25.5 23H25"
        stroke="#F4F4F4"
        stroke-linecap="round"
        stroke-width="1.5"
      />
      <circle
        cx="14"
        cy="5"
        r="2.25"
        stroke="#F4F4F4"
        stroke-linecap="round"
        stroke-width="1.5"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
