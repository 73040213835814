<template>
  <template v-if="locale === 'en'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <path
        class="appstrore-st0"
        d="M37.4,22.2c0-1.9,1.1-3.7,2.7-4.8C39,15.9,37.3,15,35.5,15c-1.9-0.2-3.8,1.1-4.8,1.1c-1,0-2.5-1.1-4.1-1.1
	c-2.1,0.1-4.1,1.3-5.1,3.1c-2.2,3.8-0.6,9.5,1.6,12.6c1.1,1.5,2.3,3.2,3.9,3.2c1.6-0.1,2.2-1,4.1-1s2.5,1,4.1,1
	c1.7,0,2.8-1.5,3.8-3.1c0.8-1.1,1.3-2.3,1.7-3.5C38.7,26.4,37.4,24.4,37.4,22.2z"
      />
      <path
        class="appstrore-st0"
        d="M34.2,12.9c0.9-1.1,1.4-2.6,1.3-4c-1.4,0.1-2.7,0.8-3.7,1.9c-0.9,1.1-1.4,2.4-1.3,3.9
	C31.9,14.7,33.3,14,34.2,12.9z"
      />
      <path
        class="appstrore-st0"
        d="M57.5,30.1h-5.4l-1.3,3.8h-2.3l5.1-14.3H56l5.2,14.3h-2.3L57.5,30.1z M52.6,28.3h4.3L54.8,22l0,0L52.6,28.3z"
      />
      <path
        class="appstrore-st0"
        d="M72.3,28.7c0,3.2-1.7,5.3-4.3,5.3c-1.4,0.1-2.6-0.7-3.3-1.9l0,0v5.2h-2.2V23.5h2.1v1.7l0,0
	c0.7-1.2,2-1.9,3.3-1.8C70.5,23.4,72.3,25.5,72.3,28.7z M70,28.7c0-2.1-1.1-3.4-2.7-3.4s-2.7,1.4-2.7,3.4s1.1,3.4,2.7,3.4
	S70,30.8,70,28.7z"
      />
      <path
        class="appstrore-st0"
        d="M83.7,28.7c0,3.2-1.7,5.3-4.3,5.3c-1.4,0.1-2.6-0.7-3.3-1.9l0,0v5.2h-2.2V23.5H76v1.7l0,0
	c0.7-1.2,2-1.9,3.3-1.8C82,23.4,83.7,25.5,83.7,28.7z M81.5,28.7c0-2.1-1.1-3.4-2.7-3.4s-2.7,1.4-2.7,3.4s1.1,3.4,2.7,3.4
	S81.5,30.8,81.5,28.7z"
      />
      <path
        class="appstrore-st0"
        d="M91.3,29.9c0.2,1.4,1.5,2.3,3.4,2.3s3.1-0.9,3.1-2.2s-0.8-1.8-2.6-2.2l-1.8-0.4c-2.6-0.6-3.8-1.9-3.8-3.8
	c0-2.5,2.1-4.1,5.2-4.1s5.1,1.7,5.2,4.1h-2.2c-0.1-1.4-1.3-2.3-3-2.3s-2.9,0.9-2.9,2.1c0,1,0.7,1.6,2.6,2.1l1.6,0.4
	c2.9,0.7,4.1,1.9,4.1,3.9c0,2.7-2.1,4.3-5.5,4.3c-3.2,0-5.3-1.6-5.4-4.2L91.3,29.9z"
      />
      <path
        class="appstrore-st0"
        d="M104.6,21.1v2.5h2v1.7h-2V31c0,0.9,0.4,1.3,1.3,1.3c0.2,0,0.5,0,0.7,0v1.6c-0.4,0.1-0.8,0.1-1.1,0.1
	c-2.1,0-2.9-0.8-2.9-2.8v-5.9H101v-1.7h1.5v-2.5H104.6z"
      />
      <path
        class="appstrore-st0"
        d="M107.7,28.7c0-3.3,1.9-5.3,4.9-5.3c3,0,4.9,2.1,4.9,5.3s-1.9,5.3-4.9,5.3C109.6,34,107.7,32,107.7,28.7z
	 M115.4,28.7c0-2.3-1-3.6-2.8-3.6c-1.7,0-2.8,1.3-2.8,3.6s1,3.6,2.8,3.6C114.4,32.3,115.4,31,115.4,28.7z"
      />
      <path
        class="appstrore-st0"
        d="M119.4,23.5h2v1.7l0,0c0.3-1.2,1.4-1.9,2.6-1.9c0.2,0,0.5,0,0.7,0.1v2c-0.3-0.1-0.6-0.1-1-0.1
	c-1.2-0.1-2.2,0.9-2.2,2.1c0,0.1,0,0.2,0,0.3v6.2h-2.1V23.5z"
      />
      <path
        class="appstrore-st0"
        d="M134.5,30.9c-0.3,1.9-2.1,3.2-4.5,3.2c-3,0-4.9-2-4.9-5.3s1.9-5.4,4.8-5.4s4.7,2,4.7,5.1v0.7h-7.3v0.1
	c-0.1,1.5,1,2.8,2.5,2.9c0.1,0,0.2,0,0.3,0c1,0.1,2-0.5,2.4-1.5H134.5z M127.3,27.8h5.2c0.1-1.4-1-2.6-2.4-2.6c-0.1,0-0.1,0-0.2,0
	C128.5,25.1,127.3,26.3,127.3,27.8C127.3,27.7,127.3,27.7,127.3,27.8z"
      />
      <path
        class="appstrore-st0"
        d="M52.4,8.9c1.7-0.1,3.1,1.1,3.2,2.8c0,0.2,0,0.4,0,0.7c0,2.2-1.1,3.4-3.2,3.4h-2.5V8.9H52.4z M50.9,14.8h1.3
	c1.2,0.1,2.2-0.8,2.3-2c0-0.2,0-0.3,0-0.5c0.2-1.2-0.7-2.3-1.8-2.4c-0.1,0-0.3,0-0.5,0h-1.3V14.8z"
      />
      <path
        class="appstrore-st0"
        d="M56.8,13.2c-0.1-1.3,0.9-2.5,2.3-2.6s2.5,0.9,2.6,2.3c0,0.1,0,0.2,0,0.3c0.1,1.3-0.9,2.5-2.3,2.6
	c-1.3,0.1-2.5-0.9-2.6-2.3C56.8,13.4,56.8,13.3,56.8,13.2z M60.6,13.2c0-1.1-0.5-1.8-1.4-1.8c-0.9,0-1.4,0.7-1.4,1.8
	s0.5,1.7,1.4,1.7C60.1,14.9,60.6,14.3,60.6,13.2z"
      />
      <path
        class="appstrore-st0"
        d="M68.1,15.8h-1.1L66,12h-0.1l-1.1,3.8h-1l-1.4-5.2h1l0.9,3.9h0.1l1-3.9h1l1.1,3.9h0.1l0.9-3.9h1L68.1,15.8z"
      />
      <path
        class="appstrore-st0"
        d="M70.7,10.6h1v0.9h0.1c0.3-0.6,0.9-1,1.5-0.9c0.9-0.1,1.7,0.6,1.8,1.6c0,0.1,0,0.2,0,0.4v3.3h-1v-3.2
	c0-0.8-0.4-1.2-1.1-1.2c-0.6-0.1-1.2,0.4-1.2,1c0,0.1,0,0.2,0,0.3v3h-1L70.7,10.6z"
      />
      <path
        class="appstrore-st0"
        d="M76.8,8.6h1v7.2h-1V8.6z"
      />
      <path
        class="appstrore-st0"
        d="M79.2,13.2c-0.1-1.3,0.9-2.5,2.3-2.6c1.3-0.1,2.5,0.9,2.6,2.3c0,0.1,0,0.2,0,0.3c0.1,1.3-0.9,2.5-2.3,2.6
	c-1.3,0.1-2.5-0.9-2.6-2.3C79.2,13.4,79.2,13.3,79.2,13.2z M83,13.2c0-1.1-0.5-1.8-1.4-1.8c-0.9,0-1.4,0.7-1.4,1.8s0.5,1.7,1.4,1.7
	S83,14.3,83,13.2L83,13.2z"
      />
      <path
        class="appstrore-st0"
        d="M85.2,14.3c0-0.9,0.7-1.5,1.9-1.5l1.4-0.1v-0.4c0-0.6-0.4-0.9-1.1-0.9s-1,0.2-1.1,0.6h-1
	c0.1-0.9,0.9-1.5,2.1-1.5c1.2,0,2.1,0.7,2.1,1.7v3.5h-1V15h-0.1c-0.3,0.5-0.9,0.8-1.6,0.8c-0.9,0.1-1.6-0.6-1.7-1.4
	C85.2,14.4,85.2,14.4,85.2,14.3z M88.5,13.9v-0.4l-1.3,0.1c-0.7,0-1,0.3-1,0.7s0.4,0.7,1,0.7C87.8,15.1,88.4,14.6,88.5,13.9
	C88.5,13.9,88.5,13.9,88.5,13.9z"
      />
      <path
        class="appstrore-st0"
        d="M90.8,13.2c0-1.6,0.8-2.7,2.1-2.7c0.7,0,1.3,0.3,1.6,0.9h0.1V8.6h1v7.2h-1v-0.8h-0.1c-0.3,0.6-1,0.9-1.6,0.9
	C91.7,15.9,90.8,14.8,90.8,13.2z M91.9,13.2c0,1.1,0.5,1.8,1.4,1.8c0.9,0,1.4-0.7,1.4-1.8s-0.5-1.7-1.4-1.7
	C92.4,11.5,91.9,12.1,91.9,13.2L91.9,13.2z"
      />
      <path
        class="appstrore-st0"
        d="M99.9,13.2c-0.1-1.3,0.9-2.5,2.3-2.6c1.3-0.1,2.5,0.9,2.6,2.3c0,0.1,0,0.2,0,0.3c0.1,1.3-0.9,2.5-2.3,2.6
	c-1.3,0.1-2.5-0.9-2.6-2.3C99.9,13.4,99.9,13.3,99.9,13.2z M103.7,13.2c0-1.1-0.5-1.8-1.4-1.8s-1.4,0.7-1.4,1.8s0.5,1.7,1.4,1.7
	S103.7,14.3,103.7,13.2z"
      />
      <path
        class="appstrore-st0"
        d="M106.1,10.6h1v0.9h0.1c0.3-0.6,0.9-1,1.6-0.9c0.9-0.1,1.7,0.6,1.8,1.6c0,0.1,0,0.2,0,0.4v3.3h-1v-3.2
	c0-0.8-0.4-1.2-1.1-1.2c-0.6-0.1-1.2,0.4-1.2,1.1c0,0.1,0,0.2,0,0.3v3h-1L106.1,10.6z"
      />
      <path
        class="appstrore-st0"
        d="M116.3,9.3v1.3h1.1v0.9h-1.1v2.7c0,0.5,0.2,0.8,0.7,0.8c0.1,0,0.3,0,0.4,0v0.8c-0.2,0-0.4,0.1-0.6,0.1
	c-1.1,0-1.6-0.4-1.6-1.4v-2.9h-0.8v-0.9h0.8V9.3H116.3z"
      />
      <path
        class="appstrore-st0"
        d="M118.8,8.6h1v2.9h0.1c0.3-0.6,0.9-1,1.6-0.9c0.9-0.1,1.7,0.7,1.8,1.6c0,0.1,0,0.2,0,0.3v3.3h-1v-3.1
	c0-0.8-0.4-1.3-1.1-1.3c-0.7-0.1-1.3,0.4-1.3,1.1c0,0.1,0,0.1,0,0.2v3h-1L118.8,8.6z"
      />
      <path
        class="appstrore-st0"
        d="M129.2,14.4c-0.3,1-1.3,1.6-2.3,1.5c-1.3,0.1-2.3-0.9-2.4-2.2c0-0.2,0-0.3,0-0.5c-0.2-1.3,0.8-2.5,2.1-2.7
	c0.1,0,0.2,0,0.3,0c1.4,0,2.3,1,2.3,2.6v0.4h-3.7v0.1c-0.1,0.7,0.5,1.4,1.2,1.5c0,0,0.1,0,0.1,0c0.5,0.1,1-0.2,1.2-0.6L129.2,14.4z
	 M125.6,12.7h2.6c0.1-0.7-0.5-1.3-1.2-1.3c0,0-0.1,0-0.1,0C126.2,11.4,125.6,11.9,125.6,12.7C125.6,12.7,125.6,12.7,125.6,12.7z"
      />
    </svg>
  </template>
  <template v-else-if="locale === 'uk'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <path
        class="appstrore-st0"
        d="M149.3,0H5.7C2.6,0,0,2.6,0,5.7v34.4c0,3.2,2.6,5.7,5.7,5.7h143.5c3.2,0,5.7-2.6,5.7-5.7V5.7
	C155,2.6,152.4,0,149.3,0 M149.3,0.9c2.7,0,4.8,2.2,4.8,4.8v34.4c0,2.7-2.2,4.8-4.8,4.8H5.7c-2.7,0-4.8-2.2-4.8-4.8l0,0V5.7
	c0-2.7,2.2-4.8,4.8-4.8l0,0H149.3"
      />
      <path
        class="appstrore-st0"
        d="M54,10c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.3-0.4,0.5-0.7,0.7c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.3,0.4
	c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2,0.1,0.3,0.1,0.5c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.5,0.4-0.8,0.5
	c-0.4,0.1-0.8,0.2-1.1,0.2c-0.2,0-0.5,0-0.7-0.1c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.5-0.4l0.4-0.6
	c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.2,0.4,0.2c0.2,0.1,0.3,0.2,0.5,0.2
	c0.2,0.1,0.5,0.1,0.7,0.1c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.4-0.1-0.6-0.1h-1V11h0.9c0.3,0,0.7-0.1,1-0.2c0.2-0.2,0.4-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.6
	c-0.3-0.2-0.6-0.3-0.9-0.2c-0.2,0-0.4,0-0.6,0.1l-0.3,0.2c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2-0.1
	c-0.1,0-0.1-0.1-0.1-0.1l-0.3-0.6c0.3-0.2,0.6-0.4,1-0.5c0.4-0.1,0.8-0.2,1.3-0.2c0.3,0,0.7,0,1,0.1c0.3,0.1,0.5,0.2,0.8,0.4
	C53.8,9,54,9.4,54,10z"
      />
      <path
        class="appstrore-st0"
        d="M58.6,15.2c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.2L58,14.6c-0.1,0.1-0.2,0.2-0.4,0.3
	c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.1-0.5-0.3
	c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0.1-0.4,0.1-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.3-0.2,0.6-0.3,0.9-0.4
	c0.5-0.1,1-0.2,1.4-0.2V12c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.6-0.2c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1L56,11.4
	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2l-0.2-0.4c0.6-0.5,1.3-0.8,2.1-0.8c0.3,0,0.5,0,0.8,0.1
	c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.5,0.1,0.8v3.2L58.6,15.2z M56.8,14.5c0.2,0,0.5,0,0.7-0.1
	c0.2-0.1,0.4-0.2,0.5-0.4v-0.8c-0.3,0-0.6,0-0.9,0.1c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
	c0,0.2,0.1,0.3,0.2,0.5C56.4,14.5,56.6,14.5,56.8,14.5L56.8,14.5z"
      />
      <path
        class="appstrore-st0"
        d="M60.5,10.2h2c0.3,0,0.6,0,0.8,0.1c0.2,0,0.4,0.1,0.6,0.3c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0.1,0.3,0.1,0.5
	c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.2-0.5,0.2c0.2,0,0.3,0.1,0.4,0.1
	c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.2,0.3-0.4,0.5
	c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.7,0.1h-2.1V10.2z M61.6,12.3h0.8c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.3-0.1
	c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.2-0.1
	c-0.1,0-0.3-0.1-0.4-0.1h-0.8L61.6,12.3z M61.6,13.1v1.3h0.9c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.2,0-0.3
	c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4,0L61.6,13.1z"
      />
      <path
        class="appstrore-st0"
        d="M68.9,15.2c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.3l-0.1-0.4L68,14.9c-0.1,0.1-0.2,0.1-0.4,0.2
	c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.3c-0.1-0.1-0.2-0.3-0.3-0.4
	c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0.1-0.4,0.1-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.3-0.2,0.6-0.3,0.9-0.4c0.5-0.1,1-0.2,1.4-0.2V12
	c0-0.3-0.1-0.5-0.2-0.7c-0.2-0.2-0.4-0.3-0.6-0.2c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1l-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1
	c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2l-0.2-0.3c0.6-0.5,1.3-0.8,2.1-0.8c0.3,0,0.5,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.4
	c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.1,0.5,0.1,0.8v3.2L68.9,15.2z M67.1,14.5c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.5-0.4v-0.8
	c-0.3,0-0.6,0-0.9,0.1c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.2,0.1,0.3,0.2,0.5
	C66.7,14.4,66.9,14.5,67.1,14.5L67.1,14.5z"
      />
      <path
        class="appstrore-st0"
        d="M75.1,10.2v5h-1.1V13h-2v2.2h-1.1v-5H72v1.9h2v-1.9H75.1z"
      />
      <path
        class="appstrore-st0"
        d="M76,10.2h4.8v0.9H79v4.1h-1.2v-4.1H76V10.2z"
      />
      <path
        class="appstrore-st0"
        d="M85,15.2c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.2l-0.1-0.4c-0.1,0.1-0.2,0.2-0.4,0.3
	c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.1c-0.2,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.1-0.5-0.3
	c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2,0.1-0.4,0.1-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.3-0.2,0.6-0.3,0.9-0.4
	c0.5-0.1,1-0.2,1.4-0.2V12c0-0.3-0.1-0.5-0.2-0.7C84,11.1,83.7,11,83.5,11c-0.2,0-0.3,0-0.5,0.1c-0.1,0-0.2,0.1-0.3,0.1l-0.3,0.1
	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2l-0.3-0.3c0.6-0.5,1.3-0.8,2.1-0.8c0.3,0,0.5,0,0.8,0.1
	c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.1,0.5,0.1,0.8v3.2L85,15.2z M83.2,14.5c0.2,0,0.4,0,0.6-0.1
	c0.2-0.1,0.4-0.2,0.5-0.4v-0.8c-0.3,0-0.6,0-0.9,0.1c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
	c0,0.2,0.1,0.3,0.2,0.5C82.8,14.4,83,14.5,83.2,14.5L83.2,14.5z"
      />
      <path
        class="appstrore-st0"
        d="M88.8,12c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1h0.3v-2.1h1.1v2.1h0.3c0.1,0,0.2,0,0.3-0.1
	c0.1-0.1,0.2-0.1,0.2-0.2l0.8-1.4c0-0.1,0.1-0.2,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1H94l-1.1,1.9c-0.1,0.1-0.1,0.2-0.2,0.3
	c-0.1,0.1-0.2,0.2-0.3,0.2c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.4l1.1,2.1h-0.8c-0.2,0-0.3,0-0.5-0.1
	c-0.1-0.1-0.2-0.2-0.3-0.3l-0.7-1.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.4-0.1h-0.4v2.2h-1.1v-2.2h-0.4
	c-0.1,0-0.2,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.3,0.3L88,14.8c0,0.1-0.1,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.5,0.1h-0.8l1.1-2.1
	c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.4-0.2c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3l-1.1-1.9h0.9
	c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.2,0.2L88.8,12z"
      />
      <path
        class="appstrore-st0"
        d="M95,15.2v-5h1.1v2.9c0,0.1,0,0.2,0,0.2v0.3c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0.1-0.1,0.1-0.1l0.2-0.3l0.3-0.4
	l0.4-0.5l1-1.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1h0.7v5h-1.2v-2.9c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3
	c0,0.1-0.1,0.1-0.1,0.2l-0.1,0.2c0,0,0,0-0.1,0.1l-0.2,0.3l-0.3,0.4c-0.1,0.2-0.2,0.3-0.4,0.5c-0.3,0.4-0.6,0.9-1,1.4
	c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1L95,15.2z"
      />
      <path
        class="appstrore-st0"
        d="M100.4,10.2h4.8v0.9h-1.8v4.1h-1.1v-4.1h-1.8V10.2z"
      />
      <path
        class="appstrore-st0"
        d="M106.1,15.2v-5h1.1v2.9c0,0.1,0,0.2,0,0.2v0.3c0.1-0.1,0.1-0.3,0.2-0.4l0.1-0.1l0.2-0.3l0.3-0.4l0.4-0.5
	c0.3-0.4,0.6-0.8,1-1.4c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.1,0.3-0.1h0.7v5h-1.1v-3.2v-0.3l-0.1,0.2l-0.1,0.2c0,0,0,0-0.1,0.1
	c-0.1,0.1-0.1,0.2-0.2,0.3l-0.3,0.4l-0.4,0.5l-1,1.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1L106.1,15.2z"
      />
      <path
        class="appstrore-st0"
        d="M114.2,10.8c0.2-0.2,0.4-0.3,0.7-0.4c0.3-0.1,0.7-0.2,1-0.2c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3
	c0.2,0.1,0.3,0.3,0.4,0.4c0.1,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
	c-0.1,0.1-0.2,0.2-0.4,0.3c0.3,0.1,0.5,0.2,0.7,0.5c0.2,0.2,0.3,0.5,0.3,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.4-0.4,0.5
	c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.7,0.1h-0.5c-0.2,0-0.3-0.1-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
	c-0.2-0.1-0.3-0.2-0.4-0.4l0.3-0.4c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0.1l0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1
	c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.5-0.1,0.7-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.1-0.5-0.2-0.8-0.2h-0.6v-0.8
	h0.6c0.3,0,0.5,0,0.7-0.2c0.2-0.1,0.3-0.3,0.3-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.3,0-0.4,0.1
	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.1-0.1-0.1-0.1L114.2,10.8z"
      />
      <path
        class="appstrore-st0"
        d="M37.4,22.1c0-1.9,1-3.8,2.7-4.8c-1.1-1.5-2.8-2.4-4.6-2.5c-1.9-0.2-3.8,1.1-4.8,1.1c-1,0-2.5-1.1-4.1-1.1
	c-2.1,0.1-4.1,1.3-5.1,3.1c-2.2,3.8-0.6,9.5,1.6,12.6c1.1,1.5,2.3,3.2,3.9,3.2c1.6-0.1,2.2-1,4.1-1s2.5,1,4.1,1
	c1.7,0,2.8-1.5,3.8-3.1c0.8-1.1,1.4-2.3,1.7-3.5C38.7,26.3,37.4,24.3,37.4,22.1L37.4,22.1z"
      />
      <path
        class="appstrore-st0"
        d="M34.2,12.8c0.9-1.1,1.4-2.6,1.3-4c-1.4,0.2-2.7,0.8-3.7,1.9c-0.9,1.1-1.4,2.4-1.3,3.9
	C31.9,14.6,33.3,13.9,34.2,12.8z"
      />
      <path
        class="appstrore-st0"
        d="M52.6,28.2h4.3l-2.1-6.2l0,0L52.6,28.2L52.6,28.2z M57.5,30h-5.4l-1.3,3.9h-2.3l5.1-14.3H56l5.2,14.3h-2.3
	L57.5,30z"
      />
      <path
        class="appstrore-st0"
        d="M70,28.6c0-2.1-1.1-3.4-2.7-3.4s-2.7,1.4-2.7,3.4s1.1,3.4,2.7,3.4S70,30.7,70,28.6z M72.2,28.6
	c0,3.2-1.7,5.3-4.3,5.3c-1.3,0.1-2.6-0.6-3.2-1.8l0,0v5.1h-2.2V23.4h2.1v1.7l0,0c0.7-1.2,2-1.9,3.3-1.8
	C70.5,23.3,72.2,25.4,72.2,28.6L72.2,28.6z"
      />
      <path
        class="appstrore-st0"
        d="M81.5,28.6c0-2.1-1.1-3.4-2.7-3.4s-2.7,1.4-2.7,3.4s1.1,3.4,2.7,3.4S81.5,30.7,81.5,28.6z M83.7,28.6
	c0,3.2-1.7,5.3-4.3,5.3c-1.3,0.1-2.6-0.6-3.3-1.8l0,0v5.1h-2.2V23.4H76v1.7l0,0c0.7-1.2,2-1.9,3.3-1.8
	C81.9,23.3,83.7,25.4,83.7,28.6L83.7,28.6z"
      />
      <path
        class="appstrore-st0"
        d="M91.3,29.9c0.2,1.4,1.5,2.3,3.4,2.3s3.1-0.9,3.1-2.2s-0.8-1.8-2.6-2.2l-1.8-0.4c-2.6-0.6-3.8-1.8-3.8-3.8
	c0-2.5,2.1-4.2,5.2-4.2s5.1,1.7,5.2,4.2h-2.2c-0.1-1.4-1.3-2.3-3-2.3s-2.9,0.9-2.9,2.1c0,1,0.7,1.6,2.6,2.1l1.6,0.4
	c2.9,0.7,4.1,1.9,4.1,4c0,2.7-2.1,4.3-5.5,4.3c-3.2,0-5.3-1.6-5.4-4.2L91.3,29.9z"
      />
      <path
        class="appstrore-st0"
        d="M104.6,21v2.5h2v1.7h-2v5.7c0,0.9,0.4,1.3,1.3,1.3c0.2,0,0.5,0,0.7,0v1.7c-0.4,0.1-0.8,0.1-1.1,0.1
	c-2.1,0-2.9-0.8-2.9-2.8v-6H101v-1.7h1.5V21H104.6z"
      />
      <path
        class="appstrore-st0"
        d="M115.4,28.6c0-2.3-1-3.6-2.8-3.6c-1.7,0-2.8,1.3-2.8,3.6s1,3.6,2.8,3.6C114.4,32.2,115.4,30.9,115.4,28.6z
	 M107.7,28.6c0-3.3,1.9-5.3,4.9-5.3c3,0,4.9,2.1,4.9,5.3s-1.9,5.3-4.9,5.3C109.6,34,107.7,31.9,107.7,28.6z"
      />
      <path
        class="appstrore-st0"
        d="M119.4,23.4h2v1.8l0,0c0.3-1.2,1.4-1.9,2.6-1.9c0.2,0,0.5,0,0.7,0.1v2c-0.3-0.1-0.6-0.1-1-0.1
	c-1.2,0-2.2,0.9-2.2,2.1c0,0.1,0,0.2,0,0.3v6.2h-2.1V23.4z"
      />
      <path
        class="appstrore-st0"
        d="M127.3,27.7h5.2c0.1-1.4-1-2.6-2.4-2.6c-0.1,0-0.1,0-0.2,0C128.5,25,127.3,26.2,127.3,27.7
	C127.3,27.7,127.3,27.7,127.3,27.7z M134.5,30.8c-0.3,1.9-2.1,3.2-4.5,3.2c-3,0-4.9-2-4.9-5.3s1.9-5.4,4.8-5.4s4.7,2,4.7,5.1v0.7
	h-7.3v0.1c-0.1,1.5,1,2.8,2.5,2.9c0.1,0,0.2,0,0.3,0c1,0.1,2-0.5,2.4-1.5L134.5,30.8z"
      />
    </svg>
  </template>
  <template v-else-if="locale === 'ru'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.3,45.9H5.7c-3.2,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155,43.4,152.4,45.9,149.3,45.9"
      />
      <path
        class="appstrore-st0"
        d="M37.4,22.1c0-1.9,1-3.8,2.7-4.8c-1.1-1.5-2.8-2.4-4.6-2.5c-1.9-0.2-3.8,1.1-4.8,1.1c-1,0-2.5-1.1-4.1-1.1
	c-2.1,0.1-4.1,1.3-5.1,3.1c-2.2,3.8-0.6,9.5,1.6,12.6c1.1,1.5,2.3,3.2,3.9,3.2c1.6-0.1,2.2-1,4.1-1s2.5,1,4.1,1
	c1.7,0,2.8-1.5,3.8-3.1c0.8-1.1,1.4-2.3,1.7-3.5C38.7,26.3,37.4,24.3,37.4,22.1L37.4,22.1z"
      />
      <path
        class="appstrore-st0"
        d="M34.2,12.8c0.9-1.1,1.4-2.6,1.3-4c-1.4,0.2-2.7,0.8-3.7,1.9c-0.9,1.1-1.4,2.4-1.3,3.9
	C31.9,14.6,33.3,13.9,34.2,12.8z"
      />
      <path
        class="appstrore-st0"
        d="M52.6,28.2h4.3l-2.1-6.2l0,0L52.6,28.2L52.6,28.2z M57.5,30h-5.4l-1.3,3.9h-2.3l5.1-14.3H56l5.2,14.3h-2.3
	L57.5,30z"
      />
      <path
        class="appstrore-st0"
        d="M70,28.6c0-2.1-1.1-3.4-2.7-3.4s-2.7,1.4-2.7,3.4s1.1,3.4,2.7,3.4S70,30.7,70,28.6z M72.2,28.6
	c0,3.2-1.7,5.3-4.3,5.3c-1.3,0.1-2.6-0.6-3.2-1.8l0,0v5.1h-2.2V23.4h2.1v1.7l0,0c0.7-1.2,2-1.9,3.3-1.8
	C70.5,23.3,72.2,25.4,72.2,28.6L72.2,28.6z"
      />
      <path
        class="appstrore-st0"
        d="M81.5,28.6c0-2.1-1.1-3.4-2.7-3.4s-2.7,1.4-2.7,3.4s1.1,3.4,2.7,3.4S81.5,30.7,81.5,28.6z M83.7,28.6
	c0,3.2-1.7,5.3-4.3,5.3c-1.3,0.1-2.6-0.6-3.3-1.8l0,0v5.1h-2.2V23.4H76v1.7l0,0c0.7-1.2,2-1.9,3.3-1.8
	C81.9,23.3,83.7,25.4,83.7,28.6L83.7,28.6z"
      />
      <path
        class="appstrore-st0"
        d="M91.3,29.9c0.2,1.4,1.5,2.3,3.4,2.3s3.1-0.9,3.1-2.2s-0.8-1.8-2.6-2.2l-1.8-0.4c-2.6-0.6-3.8-1.8-3.8-3.8
	c0-2.5,2.1-4.2,5.2-4.2s5.1,1.7,5.2,4.2h-2.2c-0.1-1.4-1.3-2.3-3-2.3s-2.9,0.9-2.9,2.1c0,1,0.7,1.6,2.6,2.1l1.6,0.4
	c2.9,0.7,4.1,1.9,4.1,4c0,2.7-2.1,4.3-5.5,4.3c-3.2,0-5.3-1.6-5.4-4.2L91.3,29.9z"
      />
      <path
        class="appstrore-st0"
        d="M104.6,21v2.5h2v1.7h-2v5.7c0,0.9,0.4,1.3,1.3,1.3c0.2,0,0.5,0,0.7,0v1.7c-0.4,0.1-0.8,0.1-1.1,0.1
	c-2.1,0-2.9-0.8-2.9-2.8v-6H101v-1.7h1.5V21H104.6z"
      />
      <path
        class="appstrore-st0"
        d="M115.4,28.6c0-2.3-1-3.6-2.8-3.6c-1.7,0-2.8,1.3-2.8,3.6s1,3.6,2.8,3.6C114.4,32.2,115.4,30.9,115.4,28.6z
	 M107.7,28.6c0-3.3,1.9-5.3,4.9-5.3c3,0,4.9,2.1,4.9,5.3s-1.9,5.3-4.9,5.3C109.6,34,107.7,31.9,107.7,28.6z"
      />
      <path
        class="appstrore-st0"
        d="M119.4,23.4h2v1.8l0,0c0.3-1.2,1.4-1.9,2.6-1.9c0.2,0,0.5,0,0.7,0.1v2c-0.3-0.1-0.6-0.1-1-0.1
	c-1.2,0-2.2,0.9-2.2,2.1c0,0.1,0,0.2,0,0.3v6.2h-2.1V23.4z"
      />
      <path
        class="appstrore-st0"
        d="M127.3,27.7h5.2c0.1-1.4-1-2.6-2.4-2.6c-0.1,0-0.1,0-0.2,0C128.5,25,127.3,26.2,127.3,27.7
	C127.3,27.7,127.3,27.7,127.3,27.7z M134.5,30.8c-0.3,1.9-2.1,3.2-4.5,3.2c-3,0-4.9-2-4.9-5.3s1.9-5.4,4.8-5.4s4.7,2,4.7,5.1v0.7
	h-7.3v0.1c-0.1,1.5,1,2.8,2.5,2.9c0.1,0,0.2,0,0.3,0c1,0.1,2-0.5,2.4-1.5L134.5,30.8z"
      />
      <path
        class="appstrore-st0"
        d="M51.1,11.7H52c0.6,0.1,1.2-0.4,1.3-1c0,0,0-0.1,0-0.1c0-0.6-0.5-1-1.3-1s-1.3,0.4-1.4,1.1h-1
	c0.1-1.2,1.1-2.1,2.3-2c0.1,0,0.1,0,0.2,0c1.4,0,2.3,0.8,2.3,1.8c0,0.8-0.5,1.4-1.3,1.6v0.1c0.9,0,1.6,0.8,1.5,1.6
	c-0.1,1.3-1.3,2.2-2.5,2c0,0,0,0,0,0c-1.5,0-2.5-0.8-2.6-2h1c0.1,0.7,0.7,1.1,1.5,1.1c0.9,0,1.5-0.5,1.5-1.1S53,12.6,52,12.6h-0.9
	L51.1,11.7L51.1,11.7z"
      />
      <path
        class="appstrore-st0"
        d="M59.1,13.8v-0.4l-1.3,0.1c-0.7,0-1,0.3-1,0.7c0,0.4,0.4,0.7,1,0.7C58.5,15,59,14.5,59.1,13.8
	C59.1,13.8,59.1,13.8,59.1,13.8L59.1,13.8z M55.8,14.2c0-0.9,0.7-1.5,1.9-1.6l1.4,0v-0.4c0-0.5-0.4-0.8-1.1-0.8s-1,0.2-1.1,0.6h-1
	c0.1-0.9,0.9-1.5,2.1-1.5s2,0.6,2,1.7v3.5h-1v-0.8h-0.1c-0.3,0.5-0.9,0.8-1.5,0.8c-0.9,0.1-1.6-0.5-1.7-1.4
	C55.8,14.3,55.8,14.2,55.8,14.2L55.8,14.2z"
      />
      <polygon
        class="appstrore-st0"
        points="62.8,11.4 62.8,15.7 61.8,15.7 61.8,10.5 65.2,10.5 65.2,11.4 62.8,11.4 "
      />
      <path
        class="appstrore-st0"
        d="M70.4,13.1c0-1.1-0.5-1.8-1.4-1.8s-1.4,0.7-1.4,1.8s0.5,1.8,1.4,1.8C69.8,14.9,70.4,14.2,70.4,13.1z M71.4,13.1
	c0,1.6-0.8,2.7-2.1,2.7c-0.6,0-1.2-0.3-1.5-0.8h-0.1v2.5h-1.1v-6.9h1v0.8h0.1c0.3-0.6,0.9-1,1.6-0.9C70.6,10.4,71.4,11.5,71.4,13.1
	L71.4,13.1z"
      />
      <path
        class="appstrore-st0"
        d="M72.8,17.5v-0.9h0.3c0.4,0,0.9-0.3,0.9-0.7l0.1-0.3l-1.9-5.2h1.1l1.2,4.1h0.1l1.3-4.1h1.1l-1.9,5.4
	c-0.4,1.3-0.9,1.7-2,1.7L72.8,17.5L72.8,17.5z"
      />
      <path
        class="appstrore-st0"
        d="M77.9,14.2h1c0.1,0.5,0.5,0.7,1.1,0.7s1.1-0.3,1.1-0.8c0-0.5-0.4-0.7-1.1-0.7h-0.8v-0.8H80
	c0.6,0,0.9-0.2,0.9-0.7s-0.3-0.7-0.9-0.7s-1,0.2-1,0.7h-1c0.2-1,1.1-1.6,2-1.5c1.2,0,1.9,0.6,1.9,1.4c0,0.5-0.3,0.9-0.8,1.1V13
	c0.6,0.1,1,0.6,1,1.1c0,1-0.8,1.6-2.1,1.6S77.9,15.3,77.9,14.2L77.9,14.2z"
      />
      <polygon
        class="appstrore-st0"
        points="83.6,15.7 83.6,10.5 84.6,10.5 84.6,14.1 84.7,14.1 87.1,10.5 88.1,10.5 88.1,15.7 87.1,15.7
	87.1,12.1 87,12.1 84.6,15.7 83.6,15.7 "
      />
      <polygon
        class="appstrore-st0"
        points="93.7,11.4 92.1,11.4 92.1,15.7 91,15.7 91,11.4 89.4,11.4 89.4,10.5 93.7,10.5 93.7,11.4 "
      />
      <path
        class="appstrore-st0"
        d="M95.6,12.6h2.6c0.1-0.7-0.5-1.3-1.1-1.3c0,0-0.1,0-0.1,0C96.2,11.3,95.6,11.9,95.6,12.6
	C95.6,12.6,95.6,12.6,95.6,12.6z M99.2,14.3c-0.3,1-1.2,1.6-2.2,1.5c-1.3,0.1-2.3-0.9-2.4-2.2c0-0.2,0-0.3,0-0.5
	c-0.2-1.3,0.8-2.5,2.1-2.7c0.1,0,0.2,0,0.3,0c1.4,0,2.3,1,2.3,2.6v0.4h-3.6v0.1c-0.1,0.8,0.5,1.4,1.2,1.5c0,0,0.1,0,0.1,0
	c0.5,0.1,1-0.2,1.2-0.6H99.2z"
      />
      <path
        class="appstrore-st0"
        d="M104.5,13.4v1.5h1.2c0.6,0,0.9-0.3,0.9-0.8s-0.4-0.8-1.1-0.8L104.5,13.4z M104.5,11.3v1.3h1
	c0.6,0,0.9-0.2,0.9-0.7c0-0.4-0.3-0.7-0.8-0.7H104.5z M103.5,10.5h2.3c1,0,1.6,0.5,1.6,1.3c0,0.5-0.4,1-0.9,1.1V13
	c0.6,0,1.1,0.5,1.1,1.1c0,0.9-0.7,1.5-1.8,1.5h-2.3L103.5,10.5L103.5,10.5z"
      />
    </svg>
  </template>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
</script>

<style lang="scss" scoped>
.appstrore-st0 {
  fill: #ffffff;
}
</style>
