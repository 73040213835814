<template>
  <div class="v-arora-tabs v-mb-xs">
    <ClientOnly fallback-tag="div">
      <arora-swiper-slider
        enable-scrollbar
        :items="tabs"
        :space-between="spaceBetween"
        max-items="auto"
        pagination-type="none"
        v-model:active-index="activeIndex"
      >
        <template #item="tab: TabsSettings<T>">
          <template v-if="withLinks">
            <arora-nuxt-link
              class-name="v-arora-tabs--single"
              :data-test-id="dataTestId"
              :href="tab.Link ?? ''"
              :label="tab.Name"
              :prefetch="prefetchLinks"
              active-class="v-arora-tabs--single__selected"
            />
          </template>
          <template v-else>
            <arora-button
              ignore-settings
              :class-name="`v-arora-tabs--single ${tab.ID === selected ? 'v-arora-tabs--single__selected' : ''}`"
              :data-test-id="dataTestId"
              :label="tab.Name"
              @click="() => select(tab.ID)"
            />
          </template>
        </template>
      </arora-swiper-slider>
      <template
        v-if="withLinks"
        #fallback
      >
        <div class="v-d-flex v-flex-row-no-wrap v-overflow-hidden">
          <div
            v-for="tab in tabs"
            :key="tab.ID"
            :style="`margin-right: ${spaceBetween}px`"
          >
            <NuxtLink
              :aria-label="tab.Name"
              :class="`v-arora-tabs--single ${tab.ID === selected ? 'v-arora-tabs--single__selected' : ''}`"
              :data-test-id="dataTestId"
              :prefetch="prefetchLinks"
              :to="tab.Link ?? ''"
            >
              {{ tab.Name }}
            </NuxtLink>
          </div>
        </div>
      </template>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts" generic="T extends GUID | number">
import type { TabsSettings } from '~types/common'

import type { AutoTest, GUID } from '@arora/common'

const { tabs, selected, canBeUnselected } = defineProps<
  AutoTest & {
    selected: T | null | undefined
    tabs: TabsSettings<T>[]
    spaceBetween: number
    canBeUnselected?: boolean
    prefetchLinks?: boolean
    withLinks?: boolean
  }
>()

const emit = defineEmits(['update:selected'])

const activeIndex = ref<number>(0)

function select(id: T): void {
  emit('update:selected', id)
}

watch(
  () => tabs,
  (newValue, oldValue) => {
    if (newValue.length !== oldValue.length && !canBeUnselected) {
      emit('update:selected', tabs[0].ID)
      activeIndex.value = 0
    }
    activeIndex.value = selected ? tabs.map((tab: TabsSettings<T>) => tab.ID).indexOf(selected) : 0
  }
)

onMounted(() => {
  if (!selected && !canBeUnselected) {
    emit('update:selected', tabs[0].ID)
    activeIndex.value = 0
  }
  activeIndex.value = selected ? tabs.map((tab: TabsSettings<T>) => tab.ID).indexOf(selected) : 0
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-arora-tabs {
  white-space: nowrap;
  .swiper {
    min-height: 3rem;
  }

  .v-swiper .swiper-wrapper {
    padding-bottom: 0;

    .swiper-slide {
      width: fit-content;
    }
  }

  .v-arora-tabs--single,
  a.v-arora-tabs--single {
    @include mixins.trans;

    background: transparent;
    color: variables.$BodyTextColorLight;
    border: none;
    border-radius: 0;

    user-select: none;

    outline: none;
    box-shadow: none;
    cursor: pointer;
    padding: 1rem 0 0.5rem 0.65rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;

    @include mixins.sm {
      font-size: 1.2rem;
    }

    &:focus,
    &:active,
    &:visited {
      outline: none;
      box-shadow: none;
      border-radius: 0;
    }

    &:first-child {
      padding-left: 0;
      margin-left: 0;
    }

    &__selected {
      color: variables.$SecondaryBackgroundColor;
      font-weight: 600;
    }
  }
}
</style>
