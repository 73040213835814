<template>
  <section class="v-groups-main__HelmsDeep">
    <common-cards-flex-mesh
      class-name="v-group-helms-deep-card"
      :items="menuStore.GroupsMainPage"
      :max-items="appConfig.VueSettingsPreRun.MenuMainGroupsInRow"
    >
      <template #item="item: Group">
        <arora-nuxt-link
          class-name="v-group-helms-deep v-position-relative"
          :href="item.Link"
          :label="sanitize(item.Name)"
        >
          <div
            v-if="isSmall && imageExists(item.ImageForMobile)"
            class="v-group-helms-deep--image"
          >
            <arora-nuxt-image
              :alt="sanitize(item.Name)"
              :image="item.ImageForMobile"
            />
          </div>
          <div
            v-else-if="imageExists(item.Image)"
            class="v-group-helms-deep--image"
          >
            <arora-nuxt-image
              :alt="item.Name"
              :image="item.Image"
            />
          </div>
          <div
            v-else
            class="v-group-helms-deep--image v-group-helms-deep--image__placeholder"
          >
            <icon-menu-placeholder />
          </div>
          <div
            class="v-group-helms-deep--title v-position-absolute"
            v-html="sanitize(item.Name)"
          />
        </arora-nuxt-link>
      </template>
    </common-cards-flex-mesh>
  </section>
</template>

<script setup lang="ts">
import type { Group } from '~types/menuStore'

import { useWindowSize } from '@arora/common'

const menuStore = useMenuStore()

const appConfig = useAppConfig()
const { sanitize } = useI18nSanitized()
const { isSmall } = useWindowSize()
const { imageExists } = useImageInfo()
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

$gap: 2rem;

.v-groups-main__HelmsDeep {
  .v-group-helms-deep-card {
    @include mixins.trans;

    &:hover {
      scale: 1.1;
    }

    .v-group-helms-deep {
      width: 100%;

      &--image {
        height: fit-content;
        margin-bottom: -4px;

        &__placeholder {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;

          background: variables.$BodyTextColorLight;
          color: variables.$BodyTextColor;
          border-radius: variables.$BorderRadius;
          opacity: 0.5;
          width: 100%;

          svg {
            width: 160px;
            height: auto;
          }
        }

        .v-img-fluid {
          height: auto;
          width: 100%;
          border-radius: variables.$BorderRadius;
        }
      }

      &--title {
        left: 20px;
        bottom: 20px;
        color: variables.$BodyBackground;
        background: variables.$BodyTextColor;
        padding: 6px 12px;
        border-radius: variables.$BorderRadiusInput;
        font-size: 1.425rem;
        font-weight: 600;
        width: min-content;
      }
    }
  }
}
</style>
