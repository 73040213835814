<template>
  {{ numeric(tweenedNumber, type) }}
</template>

<script setup lang="ts">
import type { NumericType } from '~types/common'

import gsap from 'gsap'

const { num, type = 'int' } = defineProps<{
  num: number
  type?: NumericType
}>()

const { numeric } = useI18nSanitized()
const tweenedNumber = ref<number>(0)

onMounted(() => {
  tweenedNumber.value = num
})

watch(
  () => num,
  (newValue) => {
    gsap.to(tweenedNumber, { duration: 0.3, value: newValue })
  }
)
</script>
