<template>
  <header id="Umbar">
    <header-parts-additional-logos-block />
    <div
      v-if="
        (appConfig.VueSettingsPreRun.HeaderEnableExternalLinks && externalLinksHeader.length > 0) ||
        headerEasterEggAvailable ||
        (appConfig.VueSettingsPreRun.HeaderEnableSocial && appConfig.SocialNetworksPreRun.length > 0)
      "
      class="v-header v-container"
    >
      <div
        v-if="
          (appConfig.VueSettingsPreRun.HeaderEnableExternalLinks && externalLinksHeader.length > 0) ||
          (appConfig.VueSettingsPreRun.HeaderEnableSocial && appConfig.SocialNetworksPreRun.length > 0)
        "
        class="v-row v-header-custom-links v-align-items-baseline"
      >
        <nav
          :class="[
            appConfig.VueSettingsPreRun.HeaderEnableSocial && appConfig.SocialNetworksPreRun.length > 0
              ? 'v-col-7 v-col-lg-6 v-col-xl-7 v-col-xxl-8'
              : 'v-col-12'
          ]"
        >
          <common-overflow-elements
            :options="externalLinksHeader"
            :selected="undefined"
            v-model:shown-options-amount="shownOptionsAmount"
          >
            <template #itemOverflow="option: ExternalLink">
              <arora-nuxt-link
                :external-link="!option.Url.startsWith('/')"
                :href="option.Url"
                :label="sanitize(option.Name)"
                active-class="v-font-weight-600"
              />
            </template>
            <template #item="{ option, index }">
              <div
                :key="`v-links-header-${index}`"
                class="v-header-custom-links--single v-mr-sm"
                :class="{
                  'v-d-flex': index === maybeRandomIndex && headerEasterEggAvailable
                }"
              >
                <arora-nuxt-link
                  :external-link="!option.Url.startsWith('/')"
                  :href="option.Url"
                  :label="sanitize(option.Name)"
                  active-class="v-font-weight-600"
                />

                <header-parts-easter-egg
                  v-if="index === maybeRandomIndex && headerEasterEggAvailable"
                  class="v-ml-sm"
                />
              </div>
            </template>
          </common-overflow-elements>
        </nav>
        <div
          v-if="
            appConfig.VueSettingsPreRun.HeaderEnableSocial && appConfig.SocialNetworksPreRun.length > 0
          "
          class="v-header-umbar-social v-col-5 v-col-lg-6 v-col-xl-5 v-col-xxl-4 v-d-flex v-flex-row v-flex-end v-align-items-center"
        >
          <common-social-networks
            :enable-border="false"
            :text="translate('headerPage.titleSocialNetworksLinks')"
          />
        </div>
      </div>
      <header-parts-easter-egg v-else-if="headerEasterEggAvailable" />
    </div>

    <div class="v-header v-container">
      <div class="v-row v-align-items-center v-py-sm">
        <div class="v-col-3 v-col-md-2 v-header-logo">
          <arora-nuxt-link
            :href="appConfig.VueSettingsPreRun.Links.MainLink"
            :label="translate('headerPage.homePage')"
          >
            <arora-nuxt-image
              preload
              :alt="translate('headerPage.homePage')"
              :image="appConfig.VueSettingsPreRun.HeaderLogo"
            />
          </arora-nuxt-link>
        </div>
        <div
          :class="[
            appConfig.VueSettingsPreRun.AverageTimeDisplayInHeader
              ? 'v-col-4 v-col-md-3'
              : 'v-col-7 v-col-md-6'
          ]"
        >
          <address-selector
            v-if="
              appConfig.RestaurantSettingsPreRun.OneStageCart &&
              (appConfig.RestaurantSettingsPreRun.AddressSelectOptions
                .ShowAddressSelectBeforeProductAdded ||
                appConfig.RestaurantSettingsPreRun.AddressSelectOptions.ShowAddressSelectAtEntrance)
            "
            :external-links-contacts="externalLinksContacts"
          />
          <address-city-selector
            v-else
            :external-links-contacts="externalLinksContacts"
          />
        </div>
        <div
          v-if="appConfig.VueSettingsPreRun.AverageTimeDisplayInHeader && !isSmall"
          class="v-col-3"
        >
          <header-parts-average-time />
        </div>
        <div class="v-col-5 v-col-md-4 v-d-flex v-flex-column">
          <div
            v-if="
              appConfig.VueSettingsPreRun.HeaderEnableAppQRCode &&
              !isExtraSmall &&
              !isAppleOrAndroidDevice
            "
            class="v-w-100 v-d-flex v-flex-row v-justify-content-end"
          >
            <div class="v-mr-sm v-mb-xs">
              <header-parts-qr-code-button />
            </div>
            <div class="v-d-flex v-flex-row-no-wrap v-flex-end v-align-items-center">
              <account-login-button-page />
            </div>
          </div>
          <div
            v-else
            class="v-d-flex v-flex-row-no-wrap v-flex-end v-align-items-center v-flex-100"
          >
            <account-login-button-page />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import type { ExternalLink } from '~types/settingsVariants'

import { useCommon, useWindowSize } from '@arora/common'

defineProps<{
  headerEasterEggAvailable: boolean
  externalLinksHeader: ExternalLink[]
  externalLinksContacts: ExternalLink[]
}>()
const appConfig = useAppConfig()
const { translate, sanitize } = useI18nSanitized()
const { isExtraSmall, isSmall } = useWindowSize()
const { getRandomInt, isAppleOrAndroidDevice } = useCommon()

const maybeRandomIndex = ref<number>(0)
const shownOptionsAmount = ref<number>(0)

watch(
  () => shownOptionsAmount.value,
  (newValue: number, oldValue: number) => {
    if (newValue !== oldValue) {
      maybeRandomIndex.value = appConfig.VueSettingsPreRun.HeaderEasterEggRandomLocation
        ? getRandomInt(shownOptionsAmount.value)
        : Math.round(shownOptionsAmount.value / 2)
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

#Umbar {
  .v-header-easter-egg {
    width: 40px;
    max-height: 40px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .v-header-umbar-social {
    .v-social-text-about {
      margin-bottom: 0;
    }

    .v-social-network-icon {
      width: 24px;
      height: 24px;
      color: variables.$SecondaryBackgroundColor;
      box-shadow: variables.$InputShadow;

      @include mixins.trans;

      &:hover {
        background: variables.$SecondaryBackgroundColor;
        color: variables.$SecondaryColor;
      }
    }
  }

  .v-header-custom-links {
    padding: 0.75rem 0;

    .v-element {
      margin-right: 0.25rem;
    }

    &--single {
      white-space: nowrap;
      color: variables.$BodyTextColorLight;
      align-items: center;
      text-decoration: none;

      .v-link {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
