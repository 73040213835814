<i18n>
ru:
  points: 'Ваши баллы: '
ua:
  points: 'Ваші бали: '
us:
  points: 'Your points: '
</i18n>

<template>
  <div class="v-d-flex v-login-button-icon__wold v-align-items-center v-h-100">
    <icon-account-user-alt
      class="v-login-button-icon__wold--user v-mr-xs v-pointer"
      @click="personalCabinetClick"
    />
    <div class="v-d-flex v-flex-column v-align-items-start">
      <template v-if="isLoggedIn">
        <arora-button
          class-name="v-btn v-btn-link v-btn-text v-no-underline v-login-button-icon__wold"
          ignore-settings
          :label="translate('loginButtonPage.profile')"
          @click="personalCabinetClick"
        >
          <span
            v-if="
              appConfig.VueSettingsPreRun.LoginShowUserNameInButton &&
              !stringIsNullOrWhitespace(userNameShort)
            "
            class="v-login-button-text"
            v-html="clean(userNameShort)"
          />
          <span
            v-else
            class="v-login-button-text"
            v-html="translate('loginButtonPage.profile')"
          />
        </arora-button>
        <arora-button
          v-if="appConfig.RestaurantSettingsPreRun.BonusSystemEnabled"
          class-name="v-btn v-btn-link v-btn-text v-opacity-75 v-no-underline v-login-button-icon__wold v-login-button-icon__wold--points v-d-flex v-align-items-center"
          ignore-settings
          :label="translate('loginButtonWold.points')"
          @click="pointsClick"
        >
          <span
            class="v-login-button-text v-mr-xxs"
            v-html="translate('loginButtonWold.points')"
          />
          <account-points-balance balance-only />
        </arora-button>
      </template>
      <arora-button
        v-else
        class-name="v-btn v-btn-link v-btn-text v-no-underline"
        ignore-settings
        :label="translate('loginButtonPage.login')"
        @click="showPopup"
      >
        <span
          class="v-login-button-text"
          v-html="translate('loginButtonPage.login')"
        />
      </arora-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCommon } from '@arora/common'
import { storeToRefs } from 'pinia'

const { showPopup } = defineProps<{
  showPopup: () => Promise<void>
}>()

const accountStore = useAccountStore()
const appConfig = useAppConfig()

const { isLoggedIn } = storeToRefs(accountStore)

const { stringIsNullOrWhitespace } = useCommon()
const { translate, clean } = useI18nSanitized()
const { userNameShort } = useAccount()

function personalCabinetClick(): void {
  if (isLoggedIn.value) {
    navigateTo(appConfig.VueSettingsPreRun.Links.PersonalCabinetLink)
  } else {
    showPopup()
  }
}

function pointsClick(): void {
  navigateTo(
    appConfig.VueSettingsPreRun.HeaderRedirectToPointsLink
      ? appConfig.VueSettingsPreRun.Links.PointsLink
      : null
  )
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-login-button-icon__wold {
  .v-login-button-text {
    @include mixins.trans;
  }

  &:hover {
    .v-login-button-text {
      color: variables.$SecondaryBackgroundColor;
    }

    .v-login-button-icon__wold--user {
      circle {
        stroke: variables.$SecondaryBackgroundColor;
      }

      path {
        stroke: variables.$SecondaryBackgroundColor;
      }
    }
  }

  &--points {
    .v-currency-wrapper {
      font-weight: 600;
      color: variables.$SecondaryBackgroundColor;
    }
  }
  &--user {
    height: 30px;
    width: 30px;

    circle {
      @include mixins.trans;
      stroke: variables.$HeaderColor;
    }

    path {
      @include mixins.trans;
      stroke: variables.$HeaderColor;
    }
  }
}
</style>
