type instanceComposable = {
  fromPopup: ComputedRef<boolean>
  getParentRect: () => DOMRect | undefined
}

export default function useInstance(): instanceComposable {
  const fromPopup = computed<boolean>(() => {
    if (import.meta.client) return document.body.classList.contains('v-open-overflow')

    return false
  })

  function getParentRect(): DOMRect | undefined {
    const instance = getCurrentInstance()
    if (instance) {
      let vnodeElement = instance.parent?.vnode.el
      while ((vnodeElement?.width ?? 0) <= 0 && vnodeElement?.parentElement) {
        vnodeElement = vnodeElement.parentElement
      }

      return vnodeElement?.getBoundingClientRect()
    }

    return undefined
  }

  return {
    fromPopup,
    getParentRect
  }
}
