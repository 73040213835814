import { AuthOperationsErrorCode } from '~api/consts'

import { type GUID, type ServerError, useCommon } from '@arora/common'

type authComposable = {
  userNameShort: ComputedRef<string>
  handleAuthError: (error: ServerError) => Promise<string | null>
  repeatOrder: (orderId: GUID, needToClosePopup?: boolean) => Promise<void>
}

export default function useAccount(): authComposable {
  const restaurantStore = useRestaurantStore()
  const accountStore = useAccountStore()
  const clientStore = useClientStore()
  const popupStore = usePopupStore()
  const { translate } = useI18nSanitized()
  const { stringIsNullOrWhitespace } = useCommon()
  const { reload } = useUrl()
  const appConfig = useAppConfig()

  const userNameShort = computed<string>(() => {
    let result = ''
    if (!accountStore.Profile?.data) return result

    if (
      !stringIsNullOrWhitespace(accountStore.Profile.data.Name) &&
      appConfig.VueSettingsPreRun.CabinetShowName
    )
      result +=
        accountStore.Profile.data.Name.length >= 11
          ? `${accountStore.Profile.data.Name.slice(0, 10)}...`
          : accountStore.Profile.data.Name

    if (
      !stringIsNullOrWhitespace(accountStore.Profile.data.Surname) &&
      appConfig.VueSettingsPreRun.CabinetShowSurname
    )
      result += ` ${accountStore.Profile.data.Surname[0]}.`

    return result
  })

  async function handleAuthError(error: ServerError): Promise<string | null> {
    switch (error.code) {
      case AuthOperationsErrorCode.Unknown:
        return translate('accountManagementPage.unknownError')
      case AuthOperationsErrorCode.VerificationFailed:
        return translate('accountManagementPage.verificationFailed')
      case AuthOperationsErrorCode.AlreadyRegistered:
        return translate('accountManagementPage.alreadyRegisteredErr')
      case AuthOperationsErrorCode.InvalidMainContact:
        return translate('accountManagementPage.invalidMainContact')
      case AuthOperationsErrorCode.ClientWasNotFound:
        return translate('accountManagementPage.clientWasNotFound')
      case AuthOperationsErrorCode.NoPermission:
        return translate('accountManagementPage.noPermission')
      case AuthOperationsErrorCode.PasswordDoesNotMatchPattern:
        return translate('accountManagementPage.passwordDoesNotMatchPattern')
      case AuthOperationsErrorCode.PasswordsDoesNotMatch:
        return translate('accountManagementPage.passwordsDoesNotMatch')
      case AuthOperationsErrorCode.PasswordIsInvalid:
        return translate('accountManagementPage.passwordIsInvalid')
      case AuthOperationsErrorCode.CaptchaWasNotVerified:
        return translate('accountManagementPage.captchaValidationError')
      case AuthOperationsErrorCode.AlreadyLoggedIn:
        //is it possible to get such error?
        reload()
        break
      case AuthOperationsErrorCode.CaptchaNeedRefresh:
        return translate('accountManagementPage.captchaSiteReloadError')
      case AuthOperationsErrorCode.RequiredFieldsWasNotFilledIn:
        return translate('accountManagementPage.requiredFieldsWasNotFilledIn')
      case AuthOperationsErrorCode.CaptchaV3Failed:
        await restaurantStore.setGoogleCaptchaV2(true)

        return translate('accountManagementPage.captchaValidationError')
      case AuthOperationsErrorCode.ThirdPartyError:
        return error.message ?? ''
      default:
        return null
    }

    return null
  }
  async function repeatOrder(orderId: GUID, needToClosePopup = false): Promise<void> {
    if (needToClosePopup) {
      await popupStore.closePopup()
    }
    await nextTick()
    try {
      const repeatOrderReport = await clientStore.repeatOrder(orderId)
      if (repeatOrderReport) {
        await clientStore.loadClientState()
        if (repeatOrderReport.AllItemsHasBeenAdded) {
          await popupStore.showSuccess(translate('orderRepeatPopup.success'))
        } else {
          await popupStore.showWarning(translate('orderRepeatPopup.notAll'))
        }
      } else {
        await popupStore.showError(translate('orderRepeatPopup.errorUnknown'))
      }
    } catch (_error) {
      await popupStore.showError(translate('orderRepeatPopup.errorUnknown'))
    }
  }
  return {
    userNameShort,
    handleAuthError,
    repeatOrder
  }
}
