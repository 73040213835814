import { DateTime, Settings } from 'luxon'

type dateTimeComposable = {
  defaultDateByYear: (year: number, utcOffset: number) => DateTime
  fromJsDate: (jsDate: Date, utcOffset: number) => DateTime
  fromMillisInZone: (millis: number, utcOffset: number) => DateTime
  isDateToday: (millis: number, utcOffset: number) => boolean
  makeReadableTimeFromMs: (timeInMs: number, onlyMinutes?: boolean) => string
  setDefaultLocaleInApp: (locale: string) => void
  setZone: (dateTime: DateTime, utcOffset: number) => DateTime
  timezoneFromOffset: (utcOffset: number) => string
}

export default function useDateTime(): dateTimeComposable {
  const minuteInMs = 1000 * 60
  const hourInMs = minuteInMs * 60
  function makeReadableTimeFromMs(timeMs: number, onlyMinutes = false): string {
    const { translate } = useI18nSanitized()

    let timeMsEditable = timeMs

    let hours = 0
    let minutes = 0

    while (timeMsEditable >= hourInMs) {
      hours++
      timeMsEditable -= hourInMs
    }

    while (timeMsEditable >= minuteInMs) {
      minutes++
      timeMsEditable -= minuteInMs
    }

    const seconds = Math.round(timeMsEditable / 1000)

    const result: string[] = []

    if (onlyMinutes) {
      minutes = hours * 60 + minutes
    } else if (hours > 0) {
      result.push(translate('time.hours', { number: hours }))
    }

    if (minutes > 0) {
      result.push(translate('time.minutes', { number: minutes }))
    }

    if (seconds > 0) {
      result.push(translate('time.seconds', { number: seconds }))
    }

    return result.join(' ')
  }

  /**
   *
   * @param {Number} utcOffset
   */
  const timezoneFromOffset = (utcOffset: number): string =>
    `UTC${utcOffset >= 0 ? '+' : '−'}${Math.abs(utcOffset)}`

  /**
   *
   * @param {DateTime} dateTime
   * @param {Number} utcOffset
   */
  function setZone(dateTime: DateTime, utcOffset: number): DateTime {
    const tz = timezoneFromOffset(utcOffset)

    return dateTime.setZone(tz)
  }

  /**
   *
   * @param {Number} millis
   * @param {Number} utcOffset
   */
  function fromMillisInZone(millis: number, utcOffset: number): DateTime {
    const tz = timezoneFromOffset(utcOffset)

    return DateTime.fromMillis(millis, { zone: tz })
  }

  function isDateToday(millis: number, utcOffset: number): boolean {
    const tz = timezoneFromOffset(utcOffset)
    const date = DateTime.fromMillis(millis, { zone: tz })

    return date.toISODate() === DateTime.local().toISODate()
  }

  function fromJsDate(jsDate: Date, utcOffset: number): DateTime {
    const tz = timezoneFromOffset(utcOffset)
    const result = DateTime.fromJSDate(new Date(jsDate), { zone: tz })

    if (jsDate.getUTCHours() === 0) {
      return result
    }

    return result.plus({ hours: 24 - jsDate.getUTCHours() })
  }

  function defaultDateByYear(year: number, utcOffset: number): DateTime {
    const tz = timezoneFromOffset(utcOffset)

    return DateTime.fromISO(`${year}-01-01T00:00:00.000`, { zone: tz })
  }

  function setDefaultLocaleInApp(locale: string): void {
    Settings.defaultLocale = locale
  }

  return {
    defaultDateByYear,
    fromJsDate,
    fromMillisInZone,
    isDateToday,
    makeReadableTimeFromMs,
    setDefaultLocaleInApp,
    setZone,
    timezoneFromOffset
  }
}
