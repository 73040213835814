<template>
  <menu-subgroups-dol-guldur v-if="appConfig.VueSettingsPreRun.MenuSubgroupsLayout === 'DolGuldur'" />
  <menu-subgroups-dol-amroth
    v-else-if="appConfig.VueSettingsPreRun.MenuSubgroupsLayout === 'DolAmroth'"
  />
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
</script>
