<template>
  <template v-if="locale === 'en'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M149.4,45.9H5.7c-3.1,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.6c3.2,0,5.7,2.6,5.7,5.7v34.4
	C155.1,43.3,152.5,45.9,149.4,45.9"
      />
      <g>
        <g>
          <path
            class="rustore-st0"
            d="M67.5,14L67.5,14l-2.8-0.1v0.7v0.1h1.8c0,0.3-0.2,0.7-0.3,0.9c-0.3,0.3-0.8,0.6-1.4,0.6s-1-0.2-1.4-0.6
			c-0.3-0.3-0.6-0.8-0.6-1.5c0-0.6,0.2-1,0.6-1.5c0.3-0.3,0.8-0.6,1.4-0.6s1,0.2,1.4,0.6l0.1,0.1l0.6-0.6l0,0V12
			c-0.2-0.2-0.5-0.5-0.8-0.6s-0.7-0.2-1.1-0.2c-0.8,0-1.5,0.2-2,0.8c-0.6,0.6-0.8,1.3-0.8,2.1c0,0.8,0.2,1.5,0.8,2.1s1.3,0.8,2,0.8
			c0.8,0,1.5-0.2,2-0.8l0,0l0,0l0,0l0,0c0.5-0.5,0.7-1,0.7-1.8C67.5,14.2,67.5,14.1,67.5,14z"
          />
          <polygon
            class="rustore-st0"
            points="68.3,16.9 71.5,16.9 71.5,16.1 71.5,16.1 69.2,16.1 69.2,14.6 71.3,14.6 71.3,13.8 71.3,13.8
			69.2,13.8 69.2,12.2 71.5,12.2 71.5,12.1 71.5,11.5 71.5,11.4 68.3,11.4 		"
          />
          <polygon
            class="rustore-st0"
            points="72.1,12.1 72.1,12.2 73.6,12.2 73.6,16.9 74.4,16.9 74.4,16.9 74.4,16.8 74.4,12.2 75.9,12.2
			75.9,11.5 75.9,11.4 72.1,11.4 		"
          />
          <polygon
            class="rustore-st0"
            points="78.3,11.4 78.3,16.9 78.4,16.9 79.1,16.9 79.2,16.9 79.2,11.4 78.4,11.4 		"
          />
          <polygon
            class="rustore-st0"
            points="79.9,12.1 79.9,12.2 81.3,12.2 81.3,16.9 82.1,16.9 82.2,16.9 82.2,16.8 82.2,12.2 83.7,12.2
			83.7,11.5 83.7,11.4 79.9,11.4 		"
          />
          <path
            class="rustore-st0"
            d="M88.5,11.2c-0.8,0-1.5,0.2-2,0.8c-0.6,0.6-0.8,1.3-0.8,2.1c0,0.8,0.2,1.5,0.8,2.1l0,0c0.6,0.6,1.1,0.8,2,0.8
			c0.8,0,1.5-0.2,2-0.8c0.6-0.6,0.8-1.3,0.8-2.1c0-0.8-0.2-1.5-0.8-2C89.9,11.6,89.3,11.2,88.5,11.2z M86.6,14.1
			c0-0.6,0.2-1,0.6-1.5c0.3-0.3,0.8-0.6,1.4-0.6s1,0.2,1.4,0.6l0,0c0.3,0.3,0.6,0.8,0.6,1.5c0,0.6-0.2,1-0.6,1.5
			c-0.3,0.3-0.8,0.6-1.4,0.6s-1-0.2-1.4-0.6l0,0C86.8,15.1,86.6,14.8,86.6,14.1z"
          />
          <path
            class="rustore-st0"
            d="M86.5,16.2L86.5,16.2L86.5,16.2z"
          />
          <path
            class="rustore-st0"
            d="M86.5,12.1L86.5,12.1L86.5,12.1z"
          />
          <polygon
            class="rustore-st0"
            points="95.5,11.4 95.4,11.4 95.4,14.6 95.4,15.2 93,11.4 92.1,11.4 92.1,11.4 92.1,16.9 92.1,16.9
			92.8,16.9 92.9,16.9 92.9,13.5 92.9,12.8 95.4,16.9 96.2,16.9 96.3,16.9 96.3,11.4 		"
          />
        </g>
        <path
          class="rustore-st1"
          d="M25.5,36c2.2,2.2,5.6,2.2,12.6,2.2h1.3c7,0,10.4,0,12.6-2.2c2.1-2.2,2.1-5.6,2.1-12.5v-1.1
		c0-6.9,0-10.3-2.2-12.4c-2.2-2.2-5.6-2.2-12.6-2.2H38c-7,0-10.4,0-12.6,2.2c-2.1,2.1-2.1,5.5-2.1,12.4v1.1
		C23.3,30.4,23.3,33.8,25.5,36z"
        />
        <path
          class="rustore-st2"
          d="M47.1,28L45,27.5c-0.2-0.1-0.5-0.3-0.5-0.6l-0.2-7.6c-0.1-1-0.8-1.8-1.6-2.1h-0.1v0.1c0.2,0.1,0.7,0.7,0.7,1.5
		v9.9c0,0.9-0.9,1.6-1.8,1.4l-2.1-0.6c-0.2-0.1-0.3-0.3-0.5-0.6l-0.2-7.6c-0.1-1-0.8-1.8-1.6-2.1H37v0.1c0.2,0.1,0.7,0.7,0.7,1.5V29
		l0,0v1.6c0,0.9-0.9,1.6-1.8,1.4l-6-1.3c-0.8-0.2-1.4-0.9-1.4-1.7v-9.9c0-0.9,0.9-1.6,1.8-1.4l3.8,0.9v-1.8c0-0.9,0.9-1.6,1.8-1.4
		l3.8,0.9v-1.8c0-0.9,0.9-1.6,1.8-1.4l6,1.5c0.8,0.2,1.4,0.9,1.4,1.7v9.9C48.9,27.4,48,28.2,47.1,28z"
        />
        <path
          class="rustore-st0"
          d="M72.1,35.7h-2.3L66,30.5h-2v5.2h-2V22.2h4.8c0.9,0,1.7,0.2,2.5,0.6c0.7,0.3,1.3,0.8,1.7,1.5
		c0.5,0.6,0.6,1.4,0.6,2.2c0,1-0.3,2-0.9,2.6c-0.7,0.7-1.5,1.1-2.5,1.4L72.1,35.7z M64.1,23.9v4.7H67c0.8,0,1.5-0.2,2-0.7
		c0.6-0.5,0.8-1,0.8-1.7s-0.2-1.3-0.8-1.7c-0.5-0.5-1.1-0.7-2-0.7L64.1,23.9L64.1,23.9z"
        />
        <path
          class="rustore-st0"
          d="M77.4,35.9c-0.7,0-1.4-0.2-2-0.6s-1-0.8-1.4-1.5c-0.3-0.6-0.5-1.4-0.5-2.1v-6h2v5.9c0,0.8,0.2,1.4,0.7,1.8
		c0.5,0.5,1,0.7,1.6,0.7c0.7,0,1.3-0.2,1.7-0.7c0.5-0.5,0.7-1.1,0.7-1.8v-5.9h2v9.9h-1.4l-0.5-1.5C80,34.7,79.5,35,79,35.4
		C78.6,35.8,78.1,35.9,77.4,35.9z"
        />
        <path
          class="rustore-st0"
          d="M89.3,35.9C88,35.9,87,35.6,86,35c-0.9-0.7-1.5-1.5-1.8-2.6l2-0.3c0.3,0.7,0.8,1.3,1.4,1.6s1.1,0.5,2,0.5
		c0.8,0,1.4-0.1,1.8-0.5c0.5-0.3,0.7-0.8,0.7-1.4c0-0.5-0.1-0.8-0.5-1.1c-0.2-0.3-0.6-0.6-1-0.7s-0.9-0.3-1.6-0.6
		c-0.9-0.2-1.6-0.6-2.2-0.8c-0.6-0.2-1-0.7-1.4-1.1c-0.3-0.6-0.6-1.1-0.6-2c0-0.8,0.2-1.4,0.6-2c0.3-0.6,0.9-1,1.6-1.4
		c0.7-0.3,1.4-0.5,2.3-0.5c1.1,0,2.2,0.3,3,0.9s1.4,1.4,1.7,2.4l-1.8,0.3c-0.2-0.6-0.6-1-1.1-1.4c-0.5-0.3-1-0.5-1.7-0.5
		s-1.3,0.1-1.7,0.5C87.2,24.5,87,25,87,25.6c0,0.5,0.1,0.8,0.3,1c0.2,0.3,0.6,0.6,1,0.7c0.5,0.2,0.9,0.3,1.6,0.6
		c0.8,0.2,1.5,0.6,2.1,0.9c0.6,0.2,1,0.7,1.5,1.3c0.3,0.6,0.6,1.1,0.6,2c0,0.8-0.2,1.5-0.6,2.1c-0.5,0.6-0.9,1-1.7,1.4
		C90.9,35.8,90.2,35.9,89.3,35.9z"
        />
        <path
          class="rustore-st0"
          d="M102.1,34l-0.2,1.6h-1.7c-1,0-1.8-0.2-2.4-0.8c-0.6-0.5-0.8-1.3-0.8-2.2v-5.3h-1.7v-1.6H97l0.6-2.9h1.3v2.9
		h3.1v1.7h-3.1v5.2c0,0.9,0.5,1.4,1.3,1.4H102.1z"
        />
        <path
          class="rustore-st0"
          d="M108.4,35.9c-1,0-2-0.2-2.8-0.7s-1.4-1-1.8-1.8c-0.5-0.8-0.7-1.7-0.7-2.6c0-1,0.2-1.8,0.7-2.6
		c0.5-0.8,1-1.4,1.8-1.8c0.8-0.5,1.7-0.7,2.8-0.7c1,0,2,0.2,2.8,0.7s1.4,1,1.8,1.8c0.5,0.8,0.7,1.7,0.7,2.6c0,1-0.2,1.8-0.7,2.6
		s-1,1.4-1.8,1.8C110.3,35.7,109.4,35.9,108.4,35.9z M108.4,34.2c1,0,1.8-0.3,2.4-0.9s0.9-1.5,0.9-2.5s-0.3-1.8-0.9-2.5
		s-1.4-0.9-2.4-0.9s-1.8,0.3-2.4,0.9s-0.9,1.5-0.9,2.5s0.3,1.8,0.9,2.5C106.5,33.8,107.4,34.2,108.4,34.2z"
        />
        <path
          class="rustore-st0"
          d="M115.7,35.7v-9.9h1.4l0.5,1.4c0.3-0.5,0.7-0.8,1.1-1s0.9-0.3,1.6-0.3h0.5l0.1,1.8h-1c-0.7,0-1.3,0.2-1.6,0.6
		c-0.3,0.3-0.6,0.9-0.6,1.6v5.9h-2V35.7z"
        />
        <path
          class="rustore-st0"
          d="M131.7,30.7c0,0.2,0,0.5,0,0.6h-8.3c0.1,0.9,0.5,1.6,1,2.1c0.6,0.6,1.4,0.8,2.3,0.8c0.7,0,1.1-0.1,1.7-0.3
		c0.5-0.2,0.9-0.6,1.1-1l1.8,0.3c-0.3,0.9-1,1.6-1.8,2.1s-1.8,0.7-2.9,0.7c-1,0-1.8-0.2-2.6-0.7c-0.8-0.5-1.4-1-1.8-1.8
		c-0.5-0.8-0.7-1.6-0.7-2.6s0.2-1.8,0.7-2.6s1-1.4,1.8-1.8c0.8-0.5,1.7-0.7,2.6-0.7c1,0,1.8,0.2,2.6,0.7s1.4,1,1.8,1.8
		C131.5,28.9,131.7,29.7,131.7,30.7z M126.5,27.2c-0.8,0-1.4,0.2-2,0.7s-0.9,1-1,1.8h6.2c-0.1-0.7-0.6-1.4-1-1.8
		C128,27.5,127.3,27.2,126.5,27.2z"
        />
      </g>
    </svg>
  </template>

  <template v-else-if="locale === 'uk'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <path
        d="M148.7,45.9H5.7c-3.1,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h142.9c3.2,0,5.7,2.6,5.7,5.7v34.4
	C154.3,43.3,151.8,45.9,148.7,45.9"
      />
      <g>
        <g>
          <path
            class="rustore-st0"
            d="M64.9,14.1c0.5-0.2,0.7-0.7,0.7-1.1s-0.1-0.8-0.5-1.1l0,0c-0.3-0.3-0.7-0.5-1.3-0.5c-0.5,0-0.8,0.1-1,0.3l0,0
			c-0.2,0.2-0.5,0.5-0.6,0.8v0.1l0.7,0.3H63v-0.1c0.1-0.5,0.5-0.6,0.8-0.6c0.2,0,0.5,0.1,0.6,0.2l0,0c0.1,0.1,0.2,0.2,0.2,0.5
			c0,0.2-0.1,0.5-0.2,0.6s-0.3,0.2-0.6,0.2h-0.3h-0.1v0.8v0.1H64c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.2,0.3,0.3,0.6
			c0,0.2-0.1,0.5-0.2,0.6c-0.2,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.5-0.1-0.6-0.2l0,0c-0.2-0.1-0.3-0.3-0.3-0.6v-0.1l-0.7,0.3h-0.1v0.1
			c0.1,0.5,0.3,0.8,0.7,1c0.3,0.2,0.7,0.3,1,0.3c0.5,0,0.9-0.1,1.3-0.5c0.3-0.3,0.6-0.7,0.6-1.3c0-0.3-0.1-0.6-0.2-0.8
			C65.3,14.4,65.1,14.2,64.9,14.1z"
          />
          <path
            class="rustore-st0"
            d="M65.1,11.8L65.1,11.8L65.1,11.8z"
          />
          <path
            class="rustore-st0"
            d="M68.2,11.4L68.2,11.4L66,17h0.1h0.8H67l0.6-1.5h2.2l0.6,1.5h0.8l0,0L69,11.4H68.2z M69.3,14.7h-1.6l0.8-2.2
			L69.3,14.7z"
          />
          <path
            class="rustore-st0"
            d="M74.9,11.8L74.9,11.8L74.9,11.8z"
          />
          <path
            class="rustore-st0"
            d="M74.7,14c0.2-0.1,0.3-0.2,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.7c0-0.5-0.1-0.8-0.5-1.1c-0.3-0.3-0.7-0.5-1.1-0.5
			h-2.1v5.6h0.1h2.1c0.5,0,0.9-0.1,1.3-0.5l0,0c0.3-0.3,0.6-0.7,0.6-1.1c0-0.3-0.1-0.6-0.2-0.8C75.1,14.4,75,14.1,74.7,14z
			 M74.3,12.4c0.1,0.1,0.2,0.3,0.2,0.6s-0.1,0.3-0.2,0.6c-0.1,0.1-0.3,0.2-0.6,0.2h-1.1v-1.5h1.3C73.9,12.2,74.2,12.3,74.3,12.4z
			 M74.4,15.8L74.4,15.8c-0.1,0.1-0.3,0.2-0.6,0.2h-1.4v-1.5h1.3c0.2,0,0.5,0.1,0.6,0.2c0.1,0.2,0.2,0.3,0.2,0.6
			C74.6,15.5,74.6,15.7,74.4,15.8z"
          />
          <path
            class="rustore-st0"
            d="M78,11.4L78,11.4L75.8,17h0.1h0.8h0.1l0.6-1.5h2.2l0.6,1.5H81h0.1l-2.2-5.6H78z M79.1,14.7h-1.6l0.8-2.2
			L79.1,14.7z"
          />
          <polygon
            class="rustore-st0"
            points="84.8,11.4 84.8,13.7 82.3,13.7 82.3,11.4 81.5,11.4 81.4,11.4 81.4,17 81.5,17 82.2,17 82.3,17
			82.3,14.5 84.8,14.5 84.8,17 85.7,17 85.8,17 85.8,11.4 85,11.4 		"
          />
          <polygon
            class="rustore-st0"
            points="90.2,11.4 90.2,11.4 86.3,11.4 86.3,12.1 86.3,12.2 87.8,12.2 87.8,17 88.6,17 88.8,17 88.8,16.9
			88.8,12.2 90.2,12.2 		"
          />
          <path
            class="rustore-st0"
            d="M92.1,11.4L92.1,11.4L89.9,17H90h0.8h0.1l0.6-1.5h2.2l0.6,1.5h0.8h0.1L93,11.4H92.1z M93.2,14.7h-1.6l0.8-2.2
			L93.2,14.7z"
          />
          <polygon
            class="rustore-st0"
            points="102.2,11.5 102.2,11.4 102.2,11.4 101.3,11.4 101.3,11.4 99.2,13.9 99.2,11.4 98.4,11.4 98.4,11.4
			98.4,13.9 96.3,11.4 95.4,11.4 95.4,11.4 95.4,11.5 95.4,11.5 97.6,14.2 95.3,16.9 95.3,16.9 95.3,17 96.3,17 96.3,17 98.4,14.6
			98.4,17 99.2,17 99.2,17 99.2,14.6 101.3,17 102.2,17 102.3,17 102.3,16.9 102.3,16.9 100,14.2 		"
          />
          <polygon
            class="rustore-st0"
            points="106.3,11.4 106.2,11.4 103.7,15.4 103.7,14.6 103.7,14.6 103.7,11.4 102.9,11.4 102.8,11.4 102.8,17
			103.7,17 103.7,17 106.3,12.7 106.3,13.4 106.3,13.4 106.3,17 107.1,17 107.2,17 107.2,11.4 107.1,11.4 		"
          />
          <polygon
            class="rustore-st0"
            points="107.8,12.1 107.8,12.2 109.3,12.2 109.3,17 110.1,17 110.2,17 110.2,16.9 110.2,12.2 111.7,12.2
			111.7,11.4 111.7,11.4 107.8,11.4 		"
          />
          <polygon
            class="rustore-st0"
            points="115.8,11.4 115.7,11.4 113.2,15.4 113.2,14.6 113.2,14.6 113.2,11.4 112.4,11.4 112.4,11.4 112.4,17
			113.2,17 113.2,17 116,12.7 115.8,13.4 115.8,13.4 115.8,17 116.7,17 116.8,17 116.8,11.4 116.7,11.4 		"
          />
          <path
            class="rustore-st0"
            d="M122.5,14.6c-0.1-0.2-0.3-0.3-0.6-0.5c0.5-0.2,0.7-0.7,0.7-1.1s-0.1-0.8-0.5-1.1l0,0
			c-0.3-0.3-0.7-0.5-1.3-0.5c-0.5,0-0.8,0.1-1,0.3l0,0c-0.2,0.2-0.5,0.5-0.6,0.8v0.1l0.7,0.3h0.1v-0.1c0.1-0.5,0.5-0.6,0.8-0.6
			c0.2,0,0.5,0.1,0.6,0.2l0,0c0.1,0.1,0.2,0.2,0.2,0.5c0,0.2-0.1,0.5-0.2,0.6l0,0c-0.1,0.1-0.3,0.2-0.6,0.2h-0.3h-0.1v0.8v0.1h0.6
			c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.2,0.3,0.3,0.6c0,0.2-0.1,0.5-0.2,0.6c-0.2,0.1-0.5,0.2-0.7,0.2s-0.5-0.1-0.6-0.2l0,0
			c-0.2-0.1-0.3-0.3-0.3-0.6v-0.1l-0.7,0.3h-0.1v0.1c0.1,0.5,0.3,0.8,0.7,1c0.3,0.2,0.7,0.3,1,0.3c0.5,0,0.9-0.1,1.3-0.5
			c0.3-0.3,0.6-0.7,0.6-1.3C122.7,15,122.7,14.8,122.5,14.6z"
          />
        </g>
        <path
          class="rustore-st1"
          d="M25.5,36.1c2.2,2.2,5.6,2.2,12.6,2.2h1.3c7,0,10.4,0,12.6-2.2c2.1-2.2,2.1-5.6,2.1-12.5v-1.1
		c0-6.9,0-10.3-2.2-12.4c-2.2-2.2-5.6-2.2-12.6-2.2H38c-7,0-10.4,0-12.6,2.2c-2.1,2.1-2.1,5.5-2.1,12.4v1
		C23.3,30.4,23.3,33.9,25.5,36.1z"
        />
        <path
          class="rustore-st2"
          d="M47.1,28L45,27.6c-0.2-0.1-0.5-0.3-0.5-0.6l-0.2-7.6c-0.1-1-0.8-1.8-1.6-2.1h-0.1v0.2c0.2,0.1,0.7,0.7,0.7,1.5
		v9.9c0,0.9-0.9,1.6-1.8,1.4l-2.1-0.6c-0.2-0.1-0.3-0.3-0.5-0.6l-0.2-7.6c-0.1-1-0.8-1.8-1.6-2.1H37v0.1c0.2,0.1,0.7,0.7,0.7,1.5
		v8.3l0,0v1.6c0,0.9-0.9,1.6-1.8,1.4l-6-1.3c-0.8-0.2-1.4-0.9-1.4-1.7v-9.9c0-0.9,0.9-1.6,1.8-1.4l3.8,0.9v-1.8
		c0-0.9,0.9-1.6,1.8-1.4l3.8,0.9v-1.8c0-0.9,0.9-1.6,1.8-1.4l6,1.5c0.8,0.2,1.4,0.9,1.4,1.7v9.9C48.9,27.4,48,28.2,47.1,28z"
        />
        <path
          class="rustore-st0"
          d="M72.1,35.7h-2.3L66,30.5h-2v5.2h-2V22.3h4.8c0.9,0,1.7,0.2,2.5,0.6c0.7,0.3,1.3,0.8,1.7,1.5
		c0.5,0.6,0.6,1.4,0.6,2.2c0,1-0.3,2-0.9,2.6c-0.7,0.7-1.5,1.1-2.5,1.4L72.1,35.7z M64.1,24v4.7H67c0.8,0,1.5-0.2,2-0.7
		c0.6-0.5,0.8-1,0.8-1.7S69.6,25,69,24.6c-0.5-0.5-1.1-0.7-2-0.7L64.1,24L64.1,24z"
        />
        <path
          class="rustore-st0"
          d="M77.4,35.9c-0.7,0-1.4-0.2-2-0.6s-1-0.8-1.4-1.5c-0.3-0.6-0.5-1.4-0.5-2.1v-6h2v5.9c0,0.8,0.2,1.4,0.7,1.8
		c0.5,0.5,1,0.7,1.6,0.7c0.7,0,1.3-0.2,1.7-0.7c0.5-0.5,0.7-1.1,0.7-1.8v-5.9h2v9.9h-1.4l-0.5-1.5C80,34.7,79.5,35,79,35.4
		C78.6,35.8,78.1,35.9,77.4,35.9z"
        />
        <path
          class="rustore-st0"
          d="M89.3,35.9C88,35.9,87,35.6,86,35c-0.9-0.7-1.5-1.5-1.8-2.6l2-0.3c0.3,0.7,0.8,1.3,1.4,1.6s1.1,0.5,2,0.5
		c0.8,0,1.4-0.1,1.8-0.5c0.5-0.3,0.7-0.8,0.7-1.4c0-0.5-0.1-0.8-0.5-1.1c-0.2-0.3-0.6-0.6-1-0.7s-0.9-0.3-1.6-0.6
		c-0.9-0.2-1.6-0.6-2.2-0.8c-0.6-0.2-1-0.7-1.4-1.1c-0.3-0.6-0.6-1.1-0.6-2s0.2-1.4,0.6-2c0.3-0.6,0.9-1,1.6-1.4
		c0.7-0.3,1.4-0.5,2.3-0.5c1.1,0,2.2,0.3,3,0.9s1.4,1.4,1.7,2.4l-1.8,0.3c-0.2-0.6-0.6-1-1.1-1.4c-0.5-0.3-1-0.5-1.7-0.5
		s-1.3,0.1-1.7,0.5c-0.5,0.3-0.7,0.8-0.7,1.4c0,0.5,0.1,0.8,0.3,1c0.2,0.3,0.6,0.6,1,0.7c0.5,0.2,0.9,0.3,1.6,0.6
		c0.8,0.2,1.5,0.6,2.1,0.9c0.6,0.2,1,0.7,1.5,1.3c0.3,0.6,0.6,1.1,0.6,2c0,0.8-0.2,1.5-0.6,2.1c-0.5,0.6-0.9,1-1.7,1.4
		C90.9,35.8,90.2,35.9,89.3,35.9z"
        />
        <path
          class="rustore-st0"
          d="M102.1,34.1l-0.2,1.6h-1.7c-1,0-1.8-0.2-2.4-0.8c-0.6-0.5-0.8-1.3-0.8-2.2v-5.3h-1.7v-1.6H97l0.6-2.9h1.3v2.9
		h3.1v1.7h-3.1v5.2c0,0.9,0.5,1.4,1.3,1.4H102.1z"
        />
        <path
          class="rustore-st0"
          d="M108.4,35.9c-1,0-2-0.2-2.8-0.7s-1.4-1-1.8-1.8s-0.7-1.7-0.7-2.6c0-1,0.2-1.8,0.7-2.6c0.5-0.8,1-1.4,1.8-1.8
		c0.8-0.5,1.7-0.7,2.8-0.7c1,0,2,0.2,2.8,0.7s1.4,1,1.8,1.8c0.5,0.8,0.7,1.7,0.7,2.6c0,1-0.2,1.8-0.7,2.6s-1,1.4-1.8,1.8
		C110.3,35.7,109.4,35.9,108.4,35.9z M108.4,34.2c1,0,1.8-0.3,2.4-0.9s0.9-1.5,0.9-2.5s-0.3-1.8-0.9-2.5s-1.4-0.9-2.4-0.9
		s-1.8,0.3-2.4,0.9s-0.9,1.5-0.9,2.5s0.3,1.8,0.9,2.5C106.5,33.9,107.4,34.2,108.4,34.2z"
        />
        <path
          class="rustore-st0"
          d="M115.7,35.7v-9.9h1.4l0.5,1.4c0.3-0.5,0.7-0.8,1.1-1c0.5-0.2,0.9-0.3,1.6-0.3h0.5l0.1,1.8h-1
		c-0.7,0-1.3,0.2-1.6,0.6c-0.3,0.3-0.6,0.9-0.6,1.6v5.9h-2V35.7z"
        />
        <path
          class="rustore-st0"
          d="M131.7,30.8c0,0.2,0,0.5,0,0.6h-8.3c0.1,0.9,0.5,1.6,1,2.1c0.6,0.6,1.4,0.8,2.3,0.8c0.7,0,1.1-0.1,1.7-0.3
		c0.5-0.2,0.9-0.6,1.1-1l1.8,0.3c-0.3,0.9-1,1.6-1.8,2.1s-1.8,0.7-2.9,0.7c-1,0-1.8-0.2-2.6-0.7c-0.8-0.5-1.4-1-1.8-1.8
		s-0.7-1.6-0.7-2.6c0-1,0.2-1.8,0.7-2.6c0.5-0.8,1-1.4,1.8-1.8c0.8-0.5,1.7-0.7,2.6-0.7c1,0,1.8,0.2,2.6,0.7s1.4,1,1.8,1.8
		C131.5,28.9,131.7,29.7,131.7,30.8z M126.5,27.3c-0.8,0-1.4,0.2-2,0.7s-0.9,1-1,1.8h6.2c-0.1-0.7-0.6-1.4-1-1.8
		C128,27.6,127.3,27.3,126.5,27.3z"
        />
      </g>
    </svg>
  </template>

  <template v-else-if="locale === 'ru'">
    <svg
      id="Слой_1"
      viewBox="0 0 155 45.9"
      x="0px"
      xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
    >
      <g>
        <g>
          <path
            d="M149.3,45.9H5.7c-3.1,0-5.7-2.6-5.7-5.7V5.7C0,2.6,2.6,0,5.7,0h143.5c3.2,0,5.7,2.6,5.7,5.7v34.4
			C155,43.3,152.4,45.9,149.3,45.9"
          />
        </g>
        <g>
          <path
            class="rustore-st1"
            d="M25.5,36.1c2.2,2.2,5.6,2.2,12.6,2.2h1.3c7,0,10.4,0,12.6-2.2c2.1-2.2,2.1-5.6,2.1-12.5v-1.1
			c0-6.9,0-10.3-2.2-12.4c-2.2-2.2-5.6-2.2-12.6-2.2H38c-7,0-10.4,0-12.6,2.2c-2.1,2.1-2.1,5.5-2.1,12.4v1
			C23.3,30.4,23.3,33.9,25.5,36.1z"
          />
          <path
            class="rustore-st2"
            d="M47.1,28L45,27.6c-0.2-0.1-0.5-0.3-0.5-0.6l-0.2-7.6c-0.1-1-0.8-1.8-1.6-2.1h-0.1v0.2
			c0.2,0.1,0.7,0.7,0.7,1.5v9.9c0,0.9-0.9,1.6-1.8,1.4l-2.1-0.6c-0.2-0.1-0.3-0.3-0.5-0.6l-0.2-7.6c-0.1-1-0.8-1.8-1.6-2.1H37v0.1
			c0.2,0.1,0.7,0.7,0.7,1.5v8.3l0,0v1.6c0,0.9-0.9,1.6-1.8,1.4l-6-1.3c-0.8-0.2-1.4-0.9-1.4-1.7v-9.9c0-0.9,0.9-1.6,1.8-1.4l3.8,0.9
			v-1.8c0-0.9,0.9-1.6,1.8-1.4l3.8,0.9v-1.8c0-0.9,0.9-1.6,1.8-1.4l6,1.5c0.8,0.2,1.4,0.9,1.4,1.7v9.9C48.9,27.4,48,28.2,47.1,28z"
          />
          <path
            class="rustore-st0"
            d="M72.1,35.7h-2.3L66,30.5h-2v5.2h-2V22.3h4.8c0.9,0,1.7,0.2,2.5,0.6c0.7,0.3,1.3,0.8,1.7,1.5
			c0.5,0.6,0.6,1.4,0.6,2.2c0,1-0.3,2-0.9,2.6c-0.7,0.7-1.5,1.1-2.5,1.4L72.1,35.7z M64.1,24v4.7H67c0.8,0,1.5-0.2,2-0.7
			c0.6-0.5,0.8-1,0.8-1.7S69.6,25,69,24.6c-0.5-0.5-1.1-0.7-2-0.7L64.1,24L64.1,24z"
          />
          <path
            class="rustore-st0"
            d="M77.4,35.9c-0.7,0-1.4-0.2-2-0.6s-1-0.8-1.4-1.5c-0.3-0.6-0.5-1.4-0.5-2.1v-6h2v5.9c0,0.8,0.2,1.4,0.7,1.8
			c0.5,0.5,1,0.7,1.6,0.7c0.7,0,1.3-0.2,1.7-0.7c0.5-0.5,0.7-1.1,0.7-1.8v-5.9h2v9.9h-1.4l-0.5-1.5C80,34.7,79.5,35,79,35.4
			C78.6,35.8,78.1,35.9,77.4,35.9z"
          />
          <path
            class="rustore-st0"
            d="M89.3,35.9C88,35.9,87,35.6,86,35c-0.9-0.7-1.5-1.5-1.8-2.6l2-0.3c0.3,0.7,0.8,1.3,1.4,1.6s1.1,0.5,2,0.5
			c0.8,0,1.4-0.1,1.8-0.5c0.5-0.3,0.7-0.8,0.7-1.4c0-0.5-0.1-0.8-0.5-1.1c-0.2-0.3-0.6-0.6-1-0.7s-0.9-0.3-1.6-0.6
			c-0.9-0.2-1.6-0.6-2.2-0.8c-0.6-0.2-1-0.7-1.4-1.1c-0.3-0.6-0.6-1.1-0.6-2s0.2-1.4,0.6-2c0.3-0.6,0.9-1,1.6-1.4
			c0.7-0.3,1.4-0.5,2.3-0.5c1.1,0,2.2,0.3,3,0.9s1.4,1.4,1.7,2.4l-1.8,0.3c-0.2-0.6-0.6-1-1.1-1.4c-0.5-0.3-1-0.5-1.7-0.5
			s-1.3,0.1-1.7,0.5c-0.5,0.3-0.7,0.8-0.7,1.4c0,0.5,0.1,0.8,0.3,1c0.2,0.3,0.6,0.6,1,0.7c0.5,0.2,0.9,0.3,1.6,0.6
			c0.8,0.2,1.5,0.6,2.1,0.9c0.6,0.2,1,0.7,1.5,1.3c0.3,0.6,0.6,1.1,0.6,2c0,0.8-0.2,1.5-0.6,2.1c-0.5,0.6-0.9,1-1.7,1.4
			C90.9,35.8,90.2,35.9,89.3,35.9z"
          />
          <path
            class="rustore-st0"
            d="M102.1,34.1l-0.2,1.6h-1.7c-1,0-1.8-0.2-2.4-0.8c-0.6-0.5-0.8-1.3-0.8-2.2v-5.3h-1.7v-1.6H97l0.6-2.9h1.3v2.9
			h3.1v1.7h-3.1v5.2c0,0.9,0.5,1.4,1.3,1.4H102.1z"
          />
          <path
            class="rustore-st0"
            d="M108.4,35.9c-1,0-2-0.2-2.8-0.7s-1.4-1-1.8-1.8s-0.7-1.7-0.7-2.6c0-1,0.2-1.8,0.7-2.6c0.5-0.8,1-1.4,1.8-1.8
			c0.8-0.5,1.7-0.7,2.8-0.7c1,0,2,0.2,2.8,0.7s1.4,1,1.8,1.8c0.5,0.8,0.7,1.7,0.7,2.6c0,1-0.2,1.8-0.7,2.6s-1,1.4-1.8,1.8
			C110.3,35.7,109.4,35.9,108.4,35.9z M108.4,34.2c1,0,1.8-0.3,2.4-0.9s0.9-1.5,0.9-2.5s-0.3-1.8-0.9-2.5s-1.4-0.9-2.4-0.9
			s-1.8,0.3-2.4,0.9s-0.9,1.5-0.9,2.5s0.3,1.8,0.9,2.5C106.5,33.9,107.4,34.2,108.4,34.2z"
          />
          <path
            class="rustore-st0"
            d="M115.7,35.7v-9.9h1.4l0.5,1.4c0.3-0.5,0.7-0.8,1.1-1c0.5-0.2,0.9-0.3,1.6-0.3h0.5l0.1,1.8h-1
			c-0.7,0-1.3,0.2-1.6,0.6c-0.3,0.3-0.6,0.9-0.6,1.6v5.9h-2V35.7z"
          />
          <path
            class="rustore-st0"
            d="M131.7,30.8c0,0.2,0,0.5,0,0.6h-8.3c0.1,0.9,0.5,1.6,1,2.1c0.6,0.6,1.4,0.8,2.3,0.8c0.7,0,1.1-0.1,1.7-0.3
			c0.5-0.2,0.9-0.6,1.1-1l1.8,0.3c-0.3,0.9-1,1.6-1.8,2.1s-1.8,0.7-2.9,0.7c-1,0-1.8-0.2-2.6-0.7c-0.8-0.5-1.4-1-1.8-1.8
			s-0.7-1.6-0.7-2.6c0-1,0.2-1.8,0.7-2.6c0.5-0.8,1-1.4,1.8-1.8c0.8-0.5,1.7-0.7,2.6-0.7c1,0,1.8,0.2,2.6,0.7s1.4,1,1.8,1.8
			C131.5,28.9,131.7,29.7,131.7,30.8z M126.5,27.3c-0.8,0-1.4,0.2-2,0.7s-0.9,1-1,1.8h6.2c-0.1-0.7-0.6-1.4-1-1.8
			C128,27.6,127.3,27.3,126.5,27.3z"
          />
          <g>
            <path
              class="rustore-st0"
              d="M66.9,11.5h-3.7v2.6l0,0l0,0c0,0.9-0.2,1.5-0.6,1.8h-0.5H62v2h0.7h0.1v-0.1v-1h3.8v1v0.1h0.7h0.1v-2h-0.6
				v-4.4H66.9z M64.1,14.2L64.1,14.2v-2h2V16h-2.4C64,15.6,64.1,15,64.1,14.2z"
            />
            <path
              class="rustore-st0"
              d="M68.8,16.3L68.8,16.3L68.8,16.3z"
            />
            <path
              class="rustore-st0"
              d="M70.7,11.4c-0.8,0-1.5,0.2-2,0.8l0,0c-0.6,0.6-0.8,1.3-0.8,2.1c0,0.8,0.2,1.5,0.8,2.1c0.6,0.6,1.1,0.8,2,0.8
				c0.8,0,1.5-0.2,2-0.8l-0.1-0.1l0.1,0.1c0.6-0.6,0.8-1.3,0.8-2.1s-0.2-1.5-0.8-2C72.2,11.7,71.5,11.4,70.7,11.4z M68.9,14.2
				c0-0.6,0.2-1,0.6-1.5c0.3-0.3,0.8-0.6,1.4-0.6s1,0.2,1.4,0.6l0,0l0,0c0.3,0.3,0.6,0.8,0.6,1.5c0,0.6-0.2,1-0.6,1.5
				c-0.3,0.3-0.8,0.6-1.4,0.6s-1-0.2-1.4-0.6l0,0C69,15.3,68.9,14.8,68.9,14.2z"
            />
            <path
              class="rustore-st0"
              d="M78.5,15.5L78.5,15.5c-0.5,0.6-1,0.8-1.6,0.8s-1-0.2-1.4-0.6l0,0c-0.3-0.3-0.6-0.8-0.6-1.5
				c0-0.6,0.2-1,0.6-1.5l0,0c0.3-0.3,0.8-0.6,1.4-0.6s1,0.2,1.4,0.7l0.1,0.1l0.6-0.6l0.1-0.1v-0.1c-0.5-0.6-1.1-0.9-2-0.9
				c-0.8,0-1.5,0.2-2,0.8c-0.6,0.6-0.8,1.3-0.8,2.1s0.2,1.5,0.8,2.1c0.6,0.6,1.3,0.8,2,0.8c0.5,0,0.8-0.1,1.3-0.2
				c0.3-0.2,0.7-0.5,0.9-0.7V16L78.5,15.5L78.5,15.5z"
            />
            <polygon
              class="rustore-st0"
              points="79.5,12.3 79.5,12.4 80.9,12.4 80.9,17 81.6,17 81.7,17 81.7,16.9 81.7,12.4 83.2,12.4 83.2,11.6
				83.2,11.5 79.5,11.5 			"
            />
            <path
              class="rustore-st0"
              d="M85.9,16.1L85.9,16.1L85.9,16.1z"
            />
            <path
              class="rustore-st0"
              d="M87.8,11.5H87h-0.1l-1.3,2.9l-1.2-2.9h-0.8h-0.1l1.8,4l-0.2,0.3C85,15.9,85,16,84.9,16.1
				c-0.1,0-0.1,0.1-0.3,0.1c-0.1,0-0.2,0-0.3-0.1H84l-0.2,0.7v0.1h0.1c0.2,0.1,0.3,0.1,0.6,0.1c0.6,0,1-0.3,1.3-1L87.8,11.5
				L87.8,11.5z"
            />
            <polygon
              class="rustore-st0"
              points="88.5,17 88.5,17 89.2,17 89.3,17 89.3,12.4 91.6,12.4 91.6,17 92.3,17 92.4,17 92.4,11.5 88.5,11.5
							"
            />
            <polygon
              class="rustore-st0"
              points="96.8,11.5 96.8,13.8 94.4,13.8 94.4,11.5 93.6,11.5 93.5,11.5 93.5,17 93.6,17 94.3,17 94.4,17
				94.4,14.6 96.8,14.6 96.8,17 97.6,17 97.6,17 97.6,11.5 96.9,11.5 			"
            />
            <path
              class="rustore-st0"
              d="M101.2,11.4c-0.8,0-1.5,0.2-2,0.8l0,0c-0.6,0.6-0.8,1.3-0.8,2.1c0,0.8,0.2,1.5,0.8,2.1l0,0
				c0.6,0.6,1.1,0.8,2,0.8c0.8,0,1.5-0.2,2-0.8c0.6-0.6,0.8-1.3,0.8-2.1s-0.2-1.5-0.8-2C102.5,11.7,102,11.4,101.2,11.4z M99.8,12.7
				L99.8,12.7c0.3-0.3,0.8-0.6,1.4-0.6s1,0.2,1.4,0.6l0,0c0.3,0.3,0.6,0.8,0.6,1.5c0,0.6-0.2,1-0.6,1.5c-0.3,0.3-0.8,0.6-1.4,0.6
				s-1-0.2-1.4-0.6l0,0c-0.3-0.3-0.6-0.8-0.6-1.5C99.2,13.7,99.4,13.2,99.8,12.7z"
            />
            <path
              class="rustore-st0"
              d="M110,14.6L110,14.6L110,14.6c-0.1-0.2-0.3-0.3-0.6-0.5c0.2-0.1,0.3-0.2,0.5-0.3l0,0c0.1-0.2,0.2-0.5,0.2-0.7
				c0-0.5-0.1-0.8-0.5-1l0,0c-0.3-0.2-0.7-0.5-1.1-0.5h-2V17h0.1h2c0.5,0,0.8-0.1,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1
				C110.3,15,110.2,14.8,110,14.6z M109.3,13.1c0,0.2-0.1,0.3-0.2,0.5l0,0c-0.1,0.1-0.3,0.2-0.6,0.2h-1.1v-1.4h1.1
				c0.2,0,0.3,0.1,0.6,0.2l0,0l0,0C109.2,12.7,109.3,12.9,109.3,13.1z M109.2,16L109.2,16c-0.1,0.1-0.3,0.2-0.6,0.2h-1.3v-1.6h1.3
				c0.2,0,0.5,0.1,0.6,0.2l0,0c0.1,0.1,0.2,0.3,0.2,0.6C109.4,15.6,109.3,15.7,109.2,16z"
            />
            <path
              class="rustore-st0"
              d="M109.6,11.9L109.6,11.9L109.6,11.9z"
            />
          </g>
        </g>
      </g>
    </svg>
  </template>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { locale } = useI18n()
</script>

<style lang="scss" scoped>
.rustore-st0 {
  fill: #ffffff;
}

.rustore-st1 {
  fill: #0077ff;
}

.rustore-st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
</style>
