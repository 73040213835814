<template>
  <svg
    style="enable-background: new 0 0 600 600"
    viewBox="0 0 600 600"
    x="0px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g class="a">
      <!--	нижняя булка-->
      <path
        class="burger-st0 fadeInDown"
        d="M595,463.2c-2-12-11.7-18.1-23.7-19.7c0,0,0,0,0,0c-1.2,0-2.5-0.1-3.7-0.1h-533c-0.6,0.1-1.2,0-1.9,0h-3.5
	c-14.4,1.2-25.5,12.7-25.7,27.3c-0.1,7.5-0.3,15,0.1,22.5c0.8,16.1,5.7,30.9,15.2,44c16.4,23.4,39,36.3,67.5,36.4
	c142.4,0.3,284.8,0.1,427.2,0.2c4.6,0,9.1-0.4,13.5-1.3C582.6,560.2,603.3,512.8,595,463.2z"
      />
      <!--	нижняя булка-->
      <!--	котлета-->
      <path
        class="burger-st1 fadeInDown14"
        d="M540.4,443.4h-482c-9.6,0-17.4-7.8-17.4-17.4v-26.5c0-9.6,7.8-17.4,17.4-17.4h482c9.6,0,17.4,7.8,17.4,17.4V426
	C557.8,435.6,550,443.4,540.4,443.4z"
      />
      <!--	котлета-->
      <!--	помидор-->
      <path
        class="burger-st2 fadeInDown22"
        d="M540.4,349.4h-482c-9.6,0-17.4-7.8-17.4-17.4v-21c0-9.6,7.8-17.4,17.4-17.4h482c9.6,0,17.4,7.8,17.4,17.4v21
	C557.8,341.6,550,349.4,540.4,349.4z"
      />
      <!--	помидор-->
      <!--салат-->
      <path
        class="burger-st3 fadeInDown26"
        d="M575.3,292.4c0-0.1-4.5-8.3-9.4-17.4c-7.6-14-23-22.9-39.9-22.9H73c-16.9,0-32.3,8.8-39.9,22.9
	c-4.9,9-9.4,17.3-9.4,17.4c-11.2,20.6,25.3,25.5,37,18.8c4.8-2.7,8.4-7.1,13-10c15.1-9.4,31.9-3.9,44.7,6.8c0,0,42.1,41.9,81.2,3.3
	c13.1-12.9,29-19.8,46.6-11.1c16,7.9,28.1,23.5,45,29.3c11.7,4,24.7,0.5,34.3-7c6.5-5.1,10-13,17.1-17.2c18.5-11.1,41.2-9.1,56.6,6
	c39.1,38.6,81.2-3.3,81.2-3.3c12.8-10.7,29.6-16.1,44.7-6.8c4.6,2.9,8.3,7.3,13,10C550,317.8,586.4,313,575.3,292.4z"
      />
      <!--салат-->
      <!--сыр-->
      <path
        class="burger-st4 fadeInDown18"
        d="M23.6,379c0,0,4.4-6,9.3-12.6c7.9-10.7,20.4-17,33.6-17h218.4h29h218.4c13.3,0,25.7,6.3,33.6,17
	c4.8,6.6,9.3,12.6,9.3,12.6c11.2,15.2-25.3,18.7-37,13.8c-4.8-2-8.4-5.2-13-7.4c-15.1-6.9-31.9-2.9-44.7,5c0,0-42.1,30.8-81.2,2.4
	c-13.1-9.5-29-14.6-46.6-8.1c-16,5.8-28.1,17.3-45,21.6c-11.7,3-24.7,0.4-34.3-5.2c-6.5-3.7-10-9.5-17.1-12.7
	c-18.5-8.1-41.2-6.7-56.6,4.4c-39.1,28.4-81.2-2.4-81.2-2.4c-12.8-7.8-29.6-11.9-44.7-5c-4.6,2.1-8.3,5.3-13,7.4
	C48.9,397.8,12.4,394.2,23.6,379z"
      />
      <!--сыр-->
      <!--	верхняя булка-->
      <g class="fadeInDown30">
        <path
          class="burger-st0"
          d="M600,230.4c-0.6-17.6-3.6-34.4-10.2-50.6c-12.2-29.5-31-54.3-55.1-75.1c-26.4-22.7-56.3-39.6-88.9-51.7
	c-3.3-2.4-7.3-2.8-10.9-4.2C376.1,26.9,315.2,23,253.6,28.5c-41.7,3.7-81.6,15.4-119.7,32.9c-39.3,18-74,42.2-100.2,77.2
	c-18.9,25.2-31.7,53-33.5,85.1C0.1,225,0,226.4,0,227.8c-0.2,16.1,7.8,24.2,23.9,24.3c2.7,0,5.5,0,8.2,0.1v0h541.1
	c1.9,0,3.9,0,5.8-0.1C589.3,251.8,600.4,243.3,600,230.4z"
        />

        <path
          class="burger-st5"
          d="M66,200c-6.2,7.4-8.5,15.6-5.2,18.4c3.3,2.8,11.1-0.9,17.2-8.3c6.2-7.4,8.5-15.6,5.2-18.4
		C79.9,188.9,72.2,192.6,66,200z"
        />
        <path
          class="burger-st5"
          d="M293.2,198.2c-8.3,4.8-13.3,11.8-11.1,15.5c2.2,3.8,10.7,2.9,19-1.9c8.3-4.8,13.3-11.8,11.1-15.5
		C310.1,192.6,301.6,193.4,293.2,198.2z"
        />
        <path
          class="burger-st5"
          d="M187.5,191.5c-4.3-0.9-9.3,6-11.3,15.4c-2,9.4-0.2,17.8,4.1,18.7c4.3,0.9,9.3-6,11.3-15.4
		C193.6,200.8,191.8,192.4,187.5,191.5z"
        />
        <path
          class="burger-st5"
          d="M122.3,167c-3.3,2.9-0.8,11.1,5.6,18.3c6.4,7.2,14.1,10.7,17.4,7.9c3.3-2.9,0.8-11.1-5.6-18.3
		C133.4,167.7,125.6,164.2,122.3,167z"
        />
        <path
          class="burger-st5"
          d="M406.2,140.5c-6.2,7.4-8.5,15.6-5.2,18.4c3.3,2.8,11.1-0.9,17.2-8.3c6.2-7.4,8.5-15.6,5.2-18.4
		C420.1,129.5,412.4,133.2,406.2,140.5z"
        />
        <path
          class="burger-st5"
          d="M462.5,107.6c-3.3,2.9-0.8,11.1,5.6,18.3c6.4,7.2,14.1,10.7,17.4,7.9c3.3-2.9,0.8-11.1-5.6-18.3
		C473.6,108.2,465.8,104.7,462.5,107.6z"
        />
        <path
          class="burger-st5"
          d="M386.9,74.1c-4.3-0.9-9.3,6-11.3,15.4c-2,9.4-0.2,17.8,4.1,18.7c4.3,0.9,9.3-6,11.3-15.4
		C393,83.4,391.2,75,386.9,74.1z"
        />
        <path
          class="burger-st5"
          d="M321.8,49.7c-3.3,2.9-0.8,11.1,5.6,18.3c6.4,7.2,14.1,10.7,17.4,7.9c3.3-2.9,0.8-11.1-5.6-18.3
		C332.8,50.3,325,46.8,321.8,49.7z"
        />
        <path
          class="burger-st5"
          d="M248.2,177.1c-8.9-3.5-17.5-3.1-19.1,0.9c-1.6,4,4.3,10.2,13.3,13.8c8.9,3.5,17.5,3.1,19.1-0.9
		C263,186.8,257.1,180.7,248.2,177.1z"
        />
        <path
          class="burger-st5"
          d="M540.2,196.4c-2.2-3.8-10.7-2.9-19,1.9c-8.3,4.8-13.3,11.8-11.1,15.5c2.2,3.8,10.7,2.9,19-1.9
		C537.4,207.1,542.4,200.1,540.2,196.4z"
        />
        <path
          class="burger-st5"
          d="M107.1,120.6c-2.2-3.8-10.7-2.9-19,1.9c-8.3,4.8-13.3,11.8-11.1,15.5c2.2,3.8,10.7,2.9,19-1.9
		C104.3,131.4,109.3,124.4,107.1,120.6z"
        />

        <ellipse
          class="burger-st5"
          cx="411.8"
          cy="208.5"
          rx="17.4"
          ry="7.9"
          transform="matrix(0.2083 -0.9781 0.9781 0.2083 122.0887 567.8773)"
        />

        <ellipse
          class="burger-st5"
          cx="178.2"
          cy="76.8"
          rx="17.4"
          ry="7.9"
          transform="matrix(0.2083 -0.9781 0.9781 0.2083 65.9935 235.1482)"
        />

        <ellipse
          class="burger-st5"
          cx="305.7"
          cy="139.2"
          rx="17.4"
          ry="7.9"
          transform="matrix(0.2083 -0.9781 0.9781 0.2083 105.8806 409.1877)"
        />
        <path
          class="burger-st5"
          d="M350.3,167c-3.3,2.9-0.8,11.1,5.6,18.3c6.4,7.2,14.1,10.7,17.4,7.9c3.3-2.9,0.8-11.1-5.6-18.3
		C361.3,167.7,353.5,164.2,350.3,167z"
        />
        <path
          class="burger-st5"
          d="M202.6,133.6c-8.3,4.8-13.3,11.8-11.1,15.5c2.2,3.8,10.7,2.9,19-1.9c8.3-4.8,13.3-11.8,11.1-15.5
		C219.4,127.9,210.9,128.8,202.6,133.6z"
        />
        <path
          class="burger-st5"
          d="M244,84.2c-3.3,2.9-0.8,11.1,5.6,18.3c6.4,7.2,14.1,10.7,17.4,7.9c3.3-2.9,0.8-11.1-5.6-18.3
		C255.1,84.8,247.3,81.3,244,84.2z"
        />
        <path
          class="burger-st5"
          d="M476.1,177.1c-8.9-3.5-17.5-3.1-19.1,0.9c-1.6,4,4.3,10.2,13.3,13.8c8.9,3.5,17.5,3.1,19.1-0.9
		C491,186.8,485,180.7,476.1,177.1z"
        />
      </g>
      <!--	верхняя булка-->
    </g>
  </svg>
</template>

<style lang="scss">
.burger-st0 {
  fill: #f7971c;
}
.burger-st1 {
  fill: #7a3518;
}
.burger-st2 {
  fill: #e6332c;
}
.burger-st3 {
  fill: #a7c843;
}
.burger-st4 {
  fill: #ffd03c;
}
.burger-st5 {
  fill: #ffffff;
}

.fadeInDown30 {
  -webkit-animation-name: fadeInDown26;
  animation-name: fadeInDown26;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.fadeInDown26 {
  -webkit-animation-name: fadeInDown26;
  animation-name: fadeInDown26;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.fadeInDown22 {
  -webkit-animation-name: fadeInDown22;
  animation-name: fadeInDown22;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.fadeInDown18 {
  -webkit-animation-name: fadeInDown18;
  animation-name: fadeInDown18;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.fadeInDown14 {
  -webkit-animation-name: fadeInDown14;
  animation-name: 14;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  30%,
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown14 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -200%, 0);
    transform: translate3d(0, -200%, 0);
  }
  30%,
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown18 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -300%, 0);
    transform: translate3d(0, -300%, 0);
  }
  30%,
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown22 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -400%, 0);
    transform: translate3d(0, -400%, 0);
  }
  30%,
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown26 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -500%, 0);
    transform: translate3d(0, -500%, 0);
  }
  30%,
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.a {
  -webkit-animation-name: asd;
  animation-name: asd;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transform-origin: center bottom;
  animation-iteration-count: infinite;
}

@keyframes asd {
  0% {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.8);
  }
  50%,
  100% {
    transform: scaleY(1);
  }
}
</style>
